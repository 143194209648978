/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { watch } from 'redux-easy/lib/redux-easy';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from "react-router";
import MenuIcon from '@material-ui/icons/Menu';
import MobileMenu from '../../navigation/MobileMenu';
import { buildMenu } from '../utils';
import * as Styled from './MainNav.styles';
import { enumLogHomeAction } from '../../../../services/enums'

function MainNav(props) {
  const history = useHistory();

  const [menuItems, setMenuItems] = useState([]);
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 920 });

  const loadData = () => {
    setMenuItems(buildMenu({ ...props, history }));
  };

  useEffect(() => {
    if (props.user) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  function toggleMenu() {
    setMobileMenuVisibility((prevState) => !prevState);
  }

  return (
    <Styled.MainNav>
      <div className="inner-wrapper">
        <div onClick={() => { history.push('/'); }}>
          <span onClick={props.logHomeAction.bind(this, { user_action: enumLogHomeAction.linkHome })}>
            <Styled.Logo
              alt="Meu Bolso em Dia - Febraban"
            />
          </span>
        </div>
        <nav className="desktop-menu">
          {menuItems.map((item, index) => {
            if (item.href) {
              return (
                <a onClick={props.logHomeAction.bind(this, { user_action: item.logActionId })}
                  key={item.name}
                  target={item.preventBlank ? '_self' : '_blank'}
                  href={item.href}
                  rel="noopener noreferrer"
                >
                  {item.name}
                </a>
              );
            }

            return (
              <button
                key={item.name}
                onClick={item.onClick}
                className={item.customClass?.join(', ')}
              >
                {item.name}
              </button>
            );
          })}
        </nav>
      </div>
      <MenuIcon className="menu-icon" onClick={toggleMenu} />
      {isMobile && (
        <MobileMenu toggleView={toggleMenu} isClosed={mobileMenuVisibility} items={menuItems} logHomeAction={props.logHomeAction} />
      )}
    </Styled.MainNav>
  );
}

export default watch(MainNav, { user: null });
