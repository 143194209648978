import React, { Component } from 'react';
import './progresso.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Breadcrumbs, Grid, Hidden, CircularProgress } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';

import api from '../../../services/api';

import AchivementsList from '../../components/progresso/AchivementsList';
import CurrentLevel from '../../components/sidebar/CurrentLevel';
import Gauge from '../../components/gauge/index';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import Levels from '../../components/progresso/Levels';
import NextBenefits from '../../components/progresso/NextBenefits';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import CurrencyBalance from '../../components/progresso/CurrencyBalance';
import SmallProfileGauge from '../../components/profileGauge/SmallProfileGauge';
import { enumTypeUserStatuses } from '../../../services/enums';

import AlertErrors from '../../components/alertErrors/alerts';
import { getNextIndexAssessmentDateText } from '../../../services/util';
import ProfileModal from '../profile-modal/ProfileModal';

const Globals = require("../../../Globals.json");

class Progresso extends Component {

  state = {
    coins: [],
    xp: [],
    certificates: [],
    trophies: [],
    unlockKeys: [],
    isLoading: false,
    items: [],
    alertError: '',
    isfb: 0,
    nextIndexAssessmentDateText: '',
    firstIndexMustBeFilled: false,
    profileModalOpened: false,
  }

  async loadData () {

    /*
      1 - Moeda
      2 - XP
      3 - Certificado
      4 - Conquista
      5 - Chave de Desbloqueio
    */

    this.setState({
      firstIndexMustBeFilled: this.props.user.profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled,
      nextIndexAssessmentDateText: await getNextIndexAssessmentDateText(),
      isLoading: true
    });

    try {
      const response = await api.get(Globals.api.getUserAssets);
      this.setState({
        coins: response.data.filter((i) => i.type_asset_id === 1),
        xp: response.data.filter((i) => i.type_asset_id === 2),
        certificates: response.data.filter((i) => i.type_asset_id === 3)[0] || [],
        trophies: response.data.filter((i) => i.type_asset_id === 4) || [],
        unlockKeys: response.data.filter((i) => i.type_asset_id === 5),
        isLoading: false,
      });

    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  closeWelcomeModal = () => {
    this.setState({ profileModalOpened: false })
  }

  async loadIndiceData () {

    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });
      if (response?.data?.container?.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadData();
    this.loadIndiceData()
  }

  render () {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="progress_breadCrumb">
          <Link color="inherit" to={Globals.paths.progresso} className="progress_breadCrumbLink">
            Progresso
            </Link>
        </Breadcrumbs>

        <Helmet>
          <title>Progresso | Meu bolso em dia</title>
        </Helmet>
        <Grid container>
          <AlertErrors error={this.state.alertError} />
          <Grid item xs={12} sm={8} md={9} className="progressClassContainer">

            <FeedLine>
              <FeedItem noPadding borderRadius>
                <Levels xp={this.state.xp} coins={this.props.coins} userProfile={this.props.userProfile} isLoading={this.state.isLoading} />
              </FeedItem>
            </FeedLine>

            <Grid item className="progressContentBenefits">
              <Grid item xs={12} className="progressTitleBar">
                Benefícios de cada nível
              </Grid>
            </Grid >

            <div className="containerNextBenefits">
              <NextBenefits xp={this.state.xp} />
            </div>
            <br />

            <Grid className="progress_containerCorrency" >
              <CurrencyBalance text="" />
            </Grid>

            <FeedLine>
              <FeedItem title={"Suas Conquistas"} subtitle={"Cumpra os objetivos, some pontos e suba de nível."}>
                <div className="progressCardContent">
                  <AchivementsList trophies={this.state.trophies} />
                </div>
              </FeedItem>
            </FeedLine>

            <FeedLine>
              <FeedItem expandHeight title={"Seus Certificados"} subtitle={"Finalize cursos para ganhar certificados."}>
                <div className="progressCardContentCertificate">
                  <AchivementsList certificates={this.state.trophies} />
                </div>
              </FeedItem>
            </FeedLine>

          </Grid>

          <Hidden xsDown>
            <Grid item sm={4} md={3} >


              <div className="home-saude-financeira">
                <ToolbarItem tooltip={this.state.nextIndexAssessmentDateText}
                  title="Índice de Saúde Financeira" color="#05CCAE">
                  {
                    this.state.firstIndexMustBeFilled ? (
                      <div className="indiceContainer">
                        <Grid container>
                          <Grid xs={12} item style={{ padding: '20px', textAlign: 'center', color: '#595959' }}>Você ainda não preencheu o seu Índice.
                        </Grid>
                        </Grid>
                      </div>
                    ) :
                      <>
                        {
                          <div className="positionGauge">
                            <SmallProfileGauge id="home"></SmallProfileGauge>
                            <span role="button" onClick={() => this.setState({ profileModalOpened: true })}>mais informações</span>
                          </div>
                        }
                      </>
                  }
                </ToolbarItem>
              </div>
            </Grid>
          </Hidden>

        </Grid >
        {(this.state.profileModalOpened) &&
          <ProfileModal closeWelcomeModal={this.closeWelcomeModal} open={this.state.profileModalOpened}></ProfileModal>
        }
      </>

    )
  }

}

export default watch((Progresso), { user: '' });;

