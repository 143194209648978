import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles"

export const Initiative = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 44.27%, #E5E5E5 100%);  
  padding: 90px 0;
  
  .inner-wrapper {
    align-items: center;
    display: flex;
    margin: auto;
    max-width: 1440px;
    position: relative;
    flex-direction: column;

    .grid-container {
      display: grid;      
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      gap: 10px;
      margin: 0 auto;
      width: 100%;
      padding: 0 250px;

      @media(min-width: 601px) {
        margin-top: 40px;
      }

      @media(max-width: 1059px) {
        padding: 0 40px;
      }
    }

    .grid-item {
        display: flex;
        text-align: center;
        padding: 10px;
        align-items: center;
        justify-content: center;
        height: 182px;

        @media(max-width: 540px) {
          max-height: 124px;
        }
    }

    .grid-item img {
        max-width: 100%;
        height: auto;

        &.image-01 {
          max-width: 90%;
        }

        &.image-02 {
          max-width: 65%;
        }
    }

    @media(max-width: 449px) {
      .grid-item img {
        &.image-01 {
          max-width: 70%;
        }

        &.image-02 {
          max-width: 55%;
        }
      }
    }

    .featured {
      &__shield {
        z-index: 1;
        position: absolute;

        &--small {
          width: 60px;
          top: 260px;
          left: 130px;
        }
        
        &--medium {
          width: 120px;
          top: 100px;
          left: 0;
        }

        &--big {
          width: 300px;
          top: -75px;
          left: 1140px;
        }

        @media(max-width: 1059px) {
          display: none;
        }
      }
    }
  }

  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 50px;
  }
`);