import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { withRouter } from "react-router-dom";

import { isAuthenticated, isAdmin } from "../../services/auth";

import AlertErrors from '../../logged_in/components/alertErrors/alerts';
import api from "../../services/api";
const Globals = require("../../Globals.json");

const ConfirmEmail = (props) => {

  const [response, setResponse] = useState('')
  const [redirect, setRedirect] = useState('')
  const [alertError, setAlertError] = useState('');

  const { token } = props.match.params

  useEffect(() => {
    const verifyEmail = async () => {

      //Força logout do usuário atual, caso logado.
      //logout();

      try {
        const res = await api.post(`${Globals.api.checkEmail}/${token}`);


        if (isAuthenticated()) {
          const hasAdminAccess = await isAdmin();

          if(hasAdminAccess){
            setRedirect(Globals.paths.backofficeHome);
          }
          else{
            setRedirect(Globals.paths.home)
          }
        }
        else {
          
          const userRoles = res.data.Roles;      
          const permission = 'admin.access';

          if (userRoles) {
            const hasAdminAccess = userRoles.find(r => r.Permissions.find(p => p.name === permission) != null);
            if (hasAdminAccess) {
              setRedirect(Globals.paths.backofficeLogin);
            }
            else {
              setRedirect(Globals.paths.check)
            }

          }          
        }

        setResponse('Email confirmado com sucesso!');
      }
      catch (error) {
        setResponse('Link inválido ou expirado!')
        setRedirect(Globals.paths.root)
        if (error?.response !== undefined) {
          setAlertError(error.response.status)
          setTimeout(() => {
            setAlertError('')
          }, 3000)
        }
      }
    }
    verifyEmail()
  }, [])

  return (
    <div style={{ textAlign: 'center' }}>
      <AlertErrors error={alertError} />
      <h1>{response && response}</h1>

      { redirect !== '' &&
        <Route path={Globals.paths.confirmEmail}>
          <Redirect to={redirect} />
        </Route>
      }
    </div>


  );
}


export default withRouter(ConfirmEmail);
