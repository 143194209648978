import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import AreaChartDashboard from '../../Components/Charts/AreaChartDashboard';
import './EvolutiveChart.scss';

export default function EvolutiveChart({ data }) {
  const [evolutivePeriod, setEvolutivePeriod] = useState('monthly')
  const [dataFormated, setDataFormated] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [drillUp, setDrillUp] = useState(false);

  const loadData = async () => {
    let period;
    let groups;

    if (evolutivePeriod === 'monthly') {
      period = 'yearAndMonth';
      setDrillUp(false);
    } else if (evolutivePeriod === 'weekly') {
      period = 'week';
      setDrillUp(true);
    } else if (evolutivePeriod === 'daily') {
      period = 'date';
      setDrillUp(true);
    }

    groups = data?.reduce((groups, item) => {
      const group = (groups[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups[item[period]] = group;
      }

      return groups;
    }, {});

    const dataFormated = await formatData(groups);

    setDataFormated(dataFormated);

  };

  const formatData = async (groups) => {
    let result = [];
    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              value: accumulator.value + currentValue.value,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });
          result.push(barColumn);
        } else if (groups[item].length === 1) {
          const value = groups[item][0];
          result.push({
            name: item,
            value: value.value,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          })
        }
      })
    };

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item

        result.push(groups[item][0])
      })
    }

    return result;
  };

  const handleDrillUp = () => {
    if (evolutivePeriod === 'daily') {
      setEvolutivePeriod('weekly');
      setSelectedPeriod(selectedWeek)
    } else if (evolutivePeriod === 'weekly') {
      setEvolutivePeriod('monthly');
    }
  };


  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();

  }, [evolutivePeriod, selectedPeriod, selectedMonth]);

  return (
    <div className='evolution-chart-container-ca'>
      <div className='header'>
        <div>
          Evolutivo de tempo médio - Mensal / Semanal / Diário
        </div>
        <div className='btn-container'>
          {drillUp && <button type='button' onClick={() => handleDrillUp()}> &lt;- </button>}
        </div>
      </div>
      <div className='chart-container'>
        <ResponsiveContainer width={'99%'} height={'100%'}>
          <AreaChartDashboard data={dataFormated}
            dataKey={'value'}
            name={'Tempo médio'}
            fillColor={'#DAF439'}
            needTimeConversion={true}
            legend={false}
            toolTipName={'Período'}
            evolutivePeriod={setEvolutivePeriod}
            evolutiveWeekSelected={setSelectedWeek}
            evolutiveMonthSelected={setSelectedMonth}
            angle={-45} />
        </ResponsiveContainer>
      </div>
    </div>
  )
}
