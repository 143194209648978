import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseRounded } from '@material-ui/icons';
import '../../../../backoffice.scss';
import './expensesMatrix.scss';
import TabPanel from '../../../../shared/components/TabPanel';
import PaginatedTable from '../components/PaginatedTable/PaginatedTable';
import Dialogs from '../../../../services/dialogs';

import api from "../../../../services/api";
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");


const ExpensesMatrix = () => {

  const fieldsTable = [{ title: 'Nome', fieldName: 'name' }, { title: 'Qtd. de itens', fieldName: '__meta__.total_items' }, { title: 'Qtd. de itens customizados', fieldName: '__meta__.total_items_users' }, { title: 'Data de criação', fieldName: 'created_at', type: 'date' }]

  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fixedExpensesData, setFixedExpensesData] = useState([]);
  const [variableExpensesData, setVariableExpensesData] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [querySearch, setQuerySearch] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  let history = useHistory();

  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const navigateTo = (path) => {
    history.push(path);
  }
  const handleDeleteMatrix = (path) => {
    Dialogs.show(
      <div>EXCLUIR</div>,
      'Tem certeza que gostaria de EXCLUIR a matriz?',
      [
        {
          label: 'Cancelar',
        },
        {
          label: "EXCLUIR",
          onClick: () => deleteMatrix(path),
        },
      ]
    )
  }
  const deleteMatrix = async (path) => {
    setIsLoading(true)
    try {
      await api.delete(path)
      .then(() => {
        setSeverity('success')
        setAlertMessage('A Matriz foi excluida com sucesso')
        handleOpenMessage()
        loadData()
      })
    } catch (error) {
      setSeverity('error')
      setAlertMessage(typeof(error?.response?.data) === 'string' ? error.response.data : 'Não foi possível excluir a Matriz')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }
  }

  const getFixedExpensesData = async (page = 1) => {
    
    try {
      await api.get(`${Globals.api.typeExpensesMatrices}?type_expense_id=1&per_page=10&page=${page}&${querySearch}`)
        .then((res) => {
          setFixedExpensesData(res.data)
        })
    } catch (error) {
      console.log('erro getFixedExpensesData')
    }
    

  }

  const getVariableExpensesData = async (page = 1) => {
    
    try {
      await api.get(`${Globals.api.typeExpensesMatrices}?type_expense_id=2&per_page=10&page=${page}&${querySearch}`)
        .then((res) => {
          setVariableExpensesData(res.data)
        })
    } catch (error) {
      console.log('erro getVariableExpensesData')
    }
    
  }

  const loadData = async (index = 0) => {
    setIsLoading(true)

    if (index === 0) {
      await getVariableExpensesData();
      await getFixedExpensesData();
    }
    if (index === 1) {
      await getFixedExpensesData();
    }
    if (index === 2) {
      await getVariableExpensesData();
    }

    setIsLoading(false);
  }
  const clear = () => {
    setFilterBy('');
    setQuerySearch('');
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch]);

  const handleChangeTab = (e, value) => {
    setCurrentTab(value)
    clear();
  };

  const generateTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleSearch = () => {
    setQuerySearch(`&filter_by=${filterBy}`)
  }

  const actions = [{ name: 'Editar', onClick: navigateTo, path: Globals.paths.backofficeEditExpensesMatrix.replace(':matrixId', '') }, { name: 'Excluir', onClick: handleDeleteMatrix, path: Globals.api.typeExpensesMatricesDetail.replace(':matrixId', '') }];

  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Matrizes de Despesas</span>
          </div>
          <Grid item container >
            <AppBar position="static" className="center" color="secondary">
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                aria-label="Configurações de usuário"
                centered
              >
                <Tab label="Despesas fixas" {...generateTabProps(0)} />
                <Tab label="Despesas variáveis" {...generateTabProps(1)} />
              </Tabs>
            </AppBar>
            <div className="filter-container-financial-photography">
              Buscar
              <div className="searchbar">
                <div className="filter-elements">
                  <input
                    className="filter-element"
                    placeholder="Digite um nome"
                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                  />

                  <button
                    className="filter-element-clear"
                    onClick={() => clear()}
                  >
                    <CloseRounded />
                  </button>


                  <button
                    className="filter-element-search"
                    onClick={() => handleSearch()}
                  >
                    {'Buscar'}
                  </button>
                </div>


                <div className="button-right-add">
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    to={Globals.paths.backofficeEditExpensesMatrix.replace(':matrixId', 'new')}
                  >
                    {'nova matriz'}
                  </Button>
                </div>
              </div>

            </div>
            <TabPanel value={currentTab} cleared index={0}>
              <PaginatedTable
                titleFields={fieldsTable} data={fixedExpensesData} isLoading={isLoading} pagNavigation={getFixedExpensesData} actions={actions} />
            </TabPanel>
            <TabPanel value={currentTab} cleared index={1}>
              <PaginatedTable
                titleFields={fieldsTable} data={variableExpensesData} isLoading={isLoading} pagNavigation={getVariableExpensesData} actions={actions} givenPath={Globals.paths.backofficeEditExpensesMatrix.replace(':matrixId', '')} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ExpensesMatrix;
