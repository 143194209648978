import React, { Component } from 'react';
import './avatars.scss';

import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { watch } from 'redux-easy/lib/redux-easy';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import plus from '../../../assets/avatar/plus_circle_outline.svg';
import AlertErrors from '../../components/alertErrors/alerts';

import api from '../../../services/api';

import Globals from '../../../Globals.json';

class Avatars extends Component {

  state = {
    avatars: [],
    imagePreviewUrl: plus,
    base64TextString: '',
    open: false,
    thumbnail: this.props.user.profile.user_avatar,
    preThumbnail: '',
    alertError: '',
    access: null,
    success: false
  }

  // Pega o resultado
  _handleReaderLoaded = (readerEvt) => {
    // Pega o valor convertido em bas64
    let binaryString = readerEvt.target.result
    this.setState({ base64TextString: binaryString })
  }

  // Pega os dados da imagem
  onChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      this.setState({ file: e.target.files[0].type })

      if (file) {
        const reader = new FileReader();

        // Envia o resultado como parametro para outra função
        reader.onload = this._handleReaderLoaded.bind(this)

        reader.readAsDataURL(file);
      }
    }
  }

  onFileSubmit = async (e) => {
    e.preventDefault()
    if (this.state.base64TextString) {
      try {
        const response = await api.post("api/v1/avatar/upload_avatar_user", { "image": this.state.base64TextString });
        dispatchSet("user.profile.user_avatar", response?.data?.url);
        this.setState({ success: true, base64TextString: '', imagePreviewUrl: response?.data?.url });
        setTimeout(() => {
          this.setState({ success: false })
        }, 3000)
      } catch (error) {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      }
    }
  }

  photoUpload = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
      reader.readAsDataURL(file);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
    this.setState({
      active: activeP,
    })
  }

  loadData = async () => {
    try {
      const response = await api.get(Globals.api.getAvatar)
      console.log("Avatares", response.data)
      this.setState({
        avatars: response?.data?.avatares,
        access: response?.data?.usuario_pode_acessar
      })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  postData = async (avatar, closeModal) => {

    try {
      await api.post(Globals.api.setAvatar, { "avatar": this.state.preThumbnail })
      console.log('this.state.pre', this.state.preThumbnail)
      if (closeModal) {
        dispatchSet("user.profile.user_avatar", this.state.preThumbnail);
        this.setState({ imagePreviewUrl: this.state.preThumbnail, open: false, success: true });
      }

      setTimeout(() => {
        this.setState({ success: false })
      }, 3000)
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount = () => {
    this.loadData();
  }

  render () {
    return (
      <div className={this.state.access ? "avatarContainer" : "avatarContainer notAccess"}>
        <AlertErrors error={this.state.alertError} />
        {this.state.success &&
          <Alert className={`avatar_alert`} severity="success">Avatar alterado com sucesso!</Alert>
        }
        <form onSubmit={(e) => this.onFileSubmit(e)} onChange={(e) => this.onChange(e)}>
          <content>
            <label htmlFor="photo-upload" className="avatar_custom_file_upload"
              style={this.state.imagePreviewUrl === plus ? { cursor: "pointer" } : { cursor: "normal" }}>
              <div className="avatar_access">

                {this.state.thumbnail && this.state.imagePreviewUrl === plus ?
                  <img src={this.props.user.profile.user_avatar} alt="Imagem Avatar" className="imagePreview" /> :
                  this.state.imagePreviewUrl !== plus ?
                    <img src={this.state.imagePreviewUrl} alt="Imagem Avatar" className="imagePreview" /> :
                    <>
                      <img src={this.state.imagePreviewUrl} alt="Imagem Avatar" className="avatar_iconPlus" />
                      <input type="file" name="avatar" id="file" accept="image/*" onChange={this.photoUpload} src={this.state.imagePreviewUrl} />
                    </>
                }

                {this.state.thumbnail || this.state.imagePreviewUrl !== plus ?
                  <section>
                    <button>Editar
                    <input type="file" name="avatar" id="file" accept="image/*" onChange={this.photoUpload} src={this.state.imagePreviewUrl} style={{width:"100%", height:"100%", margin:"0px", top:"0px"}}/>
                    </button>
                    {!!this.state.base64TextString &&
                      <button type="submit" >Salvar</button>
                    }
                  </section> : ""
                }

              </div>
            </label>
          </content>
        </form>
        {this.state.access &&
          <>
            {
              this.state.avatars.map((item, index) => (
                <div key={index}>
                  <div className="contentAvatar" onClick={() => this.setState({ preThumbnail: item.thumbnail, open: true })}>
                    <img src={item.thumbnail} alt="Avatar" />
                  </div>
                  {/* <div className="contentAvatarDisabled">
                    <div className="avatar_disabled">
                      <img src={item.thumbnail} alt="Avatar" />
                    </div>
                  </div> */}
                </div>
              ))
            }
          </>
        }
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          maxWidth={"xs"}
          className="modal"
        >
          <DialogContent className="form">
            <div className="header2">
              <button type="button" className="close"
                onClick={() => this.setState({ open: false })}>&times;
             </button>
            </div>

            <div className="share">
              <img src={this.state.preThumbnail} alt="Avatar" />

              <span>Você deseja adicionar esse avatar?</span>

              <div className="positionBtn">
                <Button onClick={() => this.postData(this.state.thumbnail, true)}>Sim</Button>
                <Button onClick={() => this.setState({ open: false })} >Não</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div >
    )
  }

}

export default watch(Avatars, { user: '' });
