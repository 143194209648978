import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';
import { Route } from 'react-router-dom';
import * as Styled from './LandingPage.styles';
import MainNav from './MainNav';
import Video from './Video';
import Faq from './Faq';
import OurNumbers from './OurNumbers';
import PrivacyPolicy from './PrivacyPolicy';
import MainJourney from './MainJourney';
import Journey from './Journey';
import Footer from './Footer';
import Debts from './Debts';
import Initiative from './Initiative';
import Partners from './Partners';
import DialogSelector from '../register_login/DialogSelector';
import RegisterStepper from '../RegisterStepper';
import { isAuthenticated } from '../../../services/auth';
import Globals from '../../../Globals.json';
import PublicCourseDialog from './PublicCourse/PublicCourseDialog';
import PublicTrail from './PublicTrail';
import { Helmet } from "react-helmet";
import { enumLogHomeAction } from '../../../services/enums'

function LandingPage({
  isValidatingIndebtProfile,
  isForwardToBlog,
  openModalPass,
  openModalLogin,
  emailVerified,
  faq,
  ourNumbers,
  privacyPolicy,
  ...props
}) {
  const PUBLIC_COURSE_ID = (process.env.REACT_APP_PUBLIC_COURSE_ID || '1182');

  const [dialogOpen, setDialogOpen] = useState(null);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [validatingIndebtProfile, setValidatingIndebtProfile] = useState(false);
  const [forwardToBlog, setForwardToBlog] = useState(false);
  const [courseDialogOpen, setCourseDialogOpen] = useState(false);
  const [userHomeAction, setUserHomeAction] = useState(0);
  const [userHomeActionExtraData, setUserHomeActionExtraData] = useState(null);

  useEffect(() => {
    if (isValidatingIndebtProfile) {
      setValidatingIndebtProfile(!!isValidatingIndebtProfile);
    }

    if (emailVerified) {
      setDialogOpen('login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalPass]);

  const openRegisterDialog = () => {
    //!isAuthenticated() ? setRegisterOpen(true) : props.history.push('/home');
    //para evitar que quando o caminho não é a raiz ocorra erro de history não existir
    !isAuthenticated() ? setRegisterOpen(true) : (props.history ? props.history.push('/home') : window.location.href = Globals.paths.home);
  };

  const closeRegister = () => {
    setRegisterOpen(false);
  };

  const openForwardToBlog = () => {
    setForwardToBlog(true);
  };

  const openTermsDialog = useCallback(() => {
    setDialogOpen('termsOfService');
  }, [setDialogOpen]);

  const openVideoDialog = useCallback(() => {
    setDialogOpen('video');
  }, [setDialogOpen]);

  const closeDialog = useCallback(() => {
    setDialogOpen(null);
  }, [setDialogOpen]);

  const openLoginDialog = useCallback(() => {
    setDialogOpen('login');
  }, [setDialogOpen]);

  const openChangePasswordDialog = useCallback(() => {
    setDialogOpen('changePassword');
    setRegisterOpen(false);
  }, [setDialogOpen]);

  const openValidatingIndebtProfile = useCallback(() => {
    setValidatingIndebtProfile(true);
  }, [setValidatingIndebtProfile]);

  const openCourseDialog = () => {
    !isAuthenticated() ? setCourseDialogOpen(true) : window.location.href = `${Globals.paths.courses}/${PUBLIC_COURSE_ID}`;
  };

  const closeCourseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setCourseDialogOpen(false);
    }
  };

  const getDialog = () => {
    switch (dialogOpen) {
      case 'login':
        return openLoginDialog;
      case 'changePassword':
        return openChangePasswordDialog;
      default:
        return null;
    }
  };

  const logHomeAction = (data) => {
    let extra_data = null;
    if (data?.extra_data) {
      try {
        extra_data = JSON.stringify(data.extra_data);
      }
      catch (e) {
        console.log(e);
      }
    }
    setUserHomeActionExtraData(extra_data);
    setUserHomeAction(data?.user_action ?? 0);
  };

  React.useEffect(async () => {
    if (userHomeAction > 0) {

      const postData = {
        user_action: userHomeAction,
        user_ip: '127.0.0.1',
        extra_data: userHomeActionExtraData
      };

      let user_ip = '127.0.0.1';
      try {
        const responseIpify = await fetch('https://api64.ipify.org?format=json');
        const dataIpify = await responseIpify.json();
        user_ip = dataIpify.ip;
      }
      catch {
        try {
          const responseGeolocation = await fetch('https://geolocation-db.com/json/');
          const dataGeolocation = await responseGeolocation.json();
          user_ip = dataGeolocation.IPv4;
        }
        catch {
          // se deu erro na redundância não faz nada
        }
      }
      postData.user_ip = user_ip;

      axios.post(process.env.REACT_APP_API + Globals.api.logHomeAction, postData)
        .catch((error) => {
          // se deu erro no retorno do log não faz nada
        });
    }

  }, [userHomeAction]);

  return (
    <>
      <Helmet>
        <script src="/scripts/pixelHomeProgramatica.js"></script>
      </Helmet>
      <Styled.LandingPage>
        <MainNav
          {...props}
          openLoginDialog={openLoginDialog}
          openRegisterDialog={openRegisterDialog}
          openForwardToBlog={openForwardToBlog}
          openValidatingIndebtProfile={openValidatingIndebtProfile}
          logHomeAction={logHomeAction}
        />
        {
          ourNumbers ? (
            <OurNumbers openRegisterDialog={openRegisterDialog} logHomeAction={logHomeAction} enumLogHomeAction={enumLogHomeAction} />
          ) : faq ? (
            <Faq openRegisterDialog={openRegisterDialog} logHomeAction={logHomeAction} enumLogHomeAction={enumLogHomeAction} />
          ) : privacyPolicy ? (
            <PrivacyPolicy logHomeAction={logHomeAction} enumLogHomeAction={enumLogHomeAction} />
          ) : (
            <>
              <PublicTrail openCourseDialog={openCourseDialog} logHomeAction={logHomeAction.bind(this, { user_action: enumLogHomeAction.homeCursoPublico })} />
              <Video openVideoDialog={openVideoDialog} logHomeAction={logHomeAction.bind(this, { user_action: enumLogHomeAction.homeVideo })} />
              <MainJourney openRegisterDialog={openRegisterDialog} logHomeAction={logHomeAction.bind(this, { user_action: enumLogHomeAction.homeJornadaPersonalizada })} />
              <Journey openRegisterDialog={openRegisterDialog} openCourseDialog={openCourseDialog} logHomeAction={logHomeAction} />
              <Debts openRegisterDialog={openRegisterDialog} logHomeAction={logHomeAction.bind(this, { user_action: enumLogHomeAction.homeLiberteDividas })} />
              <Initiative />
              <Partners />
            </>
          )
        }
        <Footer openRegisterDialog={openRegisterDialog} />
      </Styled.LandingPage>

      <DialogSelector
        openLoginDialog={openLoginDialog}
        dialogOpen={dialogOpen}
        emailVerified={!!emailVerified}
        onClose={closeDialog}
        openTermsDialog={openTermsDialog}
        openRegisterDialog={openRegisterDialog}
        openChangePasswordDialog={openChangePasswordDialog}
      />

      {openModalPass ? (
        <Route exact path={Globals.paths.changePassword}>
          <RegisterStepper startStep={openChangePasswordDialog} />
        </Route>
      ) : null}

      {openModalLogin ? (
        <Route exact path={Globals.paths.check}>
          {getDialog()}
        </Route>
      ) : null}

      {registerOpen ? (
        <RegisterStepper
          open={registerOpen}
          startStep={validatingIndebtProfile ? 2 : 1}
          closeFunction={closeRegister}
        />
      ) : (
        validatingIndebtProfile && (
          <RegisterStepper
            open={validatingIndebtProfile}
            startStep={validatingIndebtProfile ? 2 : 1}
            closeFunction={closeRegister}
          />
        )
      )}

      {(!!isForwardToBlog || forwardToBlog) && (
        <RegisterStepper open={true} startStep={4} closeFunction={closeRegister} />
      )}

      {courseDialogOpen ? (
        <PublicCourseDialog
          onClose={closeCourseDialog}
          courseId={PUBLIC_COURSE_ID}
          onRegister={openRegisterDialog}
          logHomeAction={logHomeAction}
        />
      ) : null}

      {/* Tag do pixel para rastrear a conversão */}
      <img src="https://xads.go2cloud.org/aff_l?offer_id=967" width="1" height="1" style={{ position: 'absolute', left: -9999, top: -9999 }} />
    </>
  );
}

export default LandingPage;
