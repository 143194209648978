
import React from 'react';
import './styles/courseArticleList.scss';

import { Skeleton } from '@material-ui/lab';
import { TurnedInNot } from '@material-ui/icons';
import { Check } from '@material-ui/icons';
import { ReactComponent as FavoritesIcon } from '../../../assets/icon/header-icons/icon-favorite-stroke-neutre.svg';
import { Grid, Hidden, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';

import Button from '../button/Button';

import Pagination from "../../components/pagination/Pagination";
import SwipeableViews from "react-swipeable-views";

const Globals = require("../../../Globals.json");

class CourseArticleList extends React.Component {

  state = {
    isLoading: false,
    courses: [],
    artigos: [],
    categories: [],
    gridCols: 3,
    alertError: '',
    activeCategory: null,
    index: 0,
  }

  article_addFavorites = (id) => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: id,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadData(loading)
      }).catch(error => {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  removeFavorites = (id) => {
    const postData = {
      type_action_id: 26,
      data_modules_detail_id: id,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadData(loading)
        console.log(response.data)
      }).catch(error => {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  async loadData (loading) {
    try {
      if (loading) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: true });
      }

      const typeModuleId = [10, 31];

      const response = await api.post(Globals.api.getModules, { type_module_id: typeModuleId });

      this.setState({ artigos: response?.data, isLoading: false });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadData();
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render () {

    const { index } = this.state;

    return (
      <>
        <Hidden xsDown>
          <AlertErrors error={this.state.alertError} />
          {this.state.isLoading ?
            <Grid>
              <Box pt={0.5} style={{ display: 'flex', flexDirection: "column" }}>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                  <Skeleton variant="rect" width={230} height={170} />
                  <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
                </div>
                <br />
                <div style={{ display: 'flex', flexDirection: "row" }}>
                  <Skeleton variant="rect" width={230} height={170} />
                  <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
                </div>
                <br />
                <div style={{ display: 'flex', flexDirection: "row" }}>
                  <Skeleton variant="rect" width={230} height={170} />
                  <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
                </div>
                <br />
              </Box>
            </Grid> :
            <div>
              <Grid container direction="column">
                <>
                  {this.state.artigos.map((item, index) => (
                    <div key={index}>
                      <Grid container className="article_itemContainer">
                        <div className="article_image">
                          <img className="article_image" src={item.thumbnail} alt={item.name} />
                          <div className="button_save">
                            <button className="article_addFavorite"
                              onClick={() => { item.is_favorite !== null ? this.removeFavorites(item.id) : this.article_addFavorites(item.id) }}>
                              {item.is_favorite !== null ?
                                <FavoritesIcon style={{ stroke: "#05CCAE", width:"25px", marginTop:"-5px"}} /> : <FavoritesIcon style={{ stroke: "#FFF", width:"25px", marginTop:"-5px"}} />
                              }
                            </button>
                          </div>
                          {item.user_module_data && item.user_module_data.finishedAt && <Check className="readed-article" />}
                          
                        </div>

                        <div className="article_infoContainer">
                          <div className="article_InfoTitle">{item.name}</div>
                        </div>
                        <div className="article_courseButtonContainer">
                          <Button minHeight="36px" fontSize="12px" width="90%" to={Globals.paths.article.replace(":articleId", item.id)}>
                            SAIBA MAIS
                          </Button>
                        </div>
                        <div className="article_borderRight">
                        </div>
                      </Grid>
                    </div>
                  ))}
                </>
              </Grid>
            </div>
          }
        </Hidden>

        <Hidden smUp>
          <AlertErrors error={this.state.alertError} />
          {this.state.isLoading ?
            <Grid>
              <Box pt={0.5}>
                <Skeleton variant="rect" width={"100%"} height={170} />
              </Box>
            </Grid> :
            <div style={this.props.favorite ? { display: 'flex', width: "100%" } : { display: "" }}>
              <>
                <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
                  {this.state.artigos.map((item, index) => (
                    <div key={index}>
                      <>
                        <div className="article_itemContainer right">
                          <div className="article_image right" >
                            <img className="article_image right" src={item.thumbnail} alt={item.name} />
                            <div className="button_save">
                              <button className="article_addFavorite"
                                onClick={() => { item.is_favorite !== null ? this.removeFavorites(item.id) : this.article_addFavorites(item.id) }}>
                                {item.is_favorite !== null ?
                                  <FavoritesIcon style={{ stroke: "#05CCAE", width:"25px", marginTop:"-5px"}} /> : <FavoritesIcon style={{ stroke: "#FFF", width:"25px", marginTop:"-5px"}} />
                                }
                              </button>
                            </div>
                            {item.user_module_data && item.user_module_data.finishedAt && <Check className="readed-article" />}
                          </div>
                          <div className="article_container">

                            <div className="article_infoContainer right">
                              <div className="article_toolTitle">{item.name}</div>
                            </div>
                            <div className="article_courseButtonContainer right">
                              <Button width="50%" type="invert" to={Globals.paths.article.replace(":articleId", item.id)}>
                                SAIBA MAIS
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  ))}
                </SwipeableViews>
                <Pagination
                  dots={this.state.artigos.length}
                  index={index}
                  onChangeIndex={this.handleChangeIndex}
                />
              </>
            </div>
          }
        </Hidden>
      </>
    )
  }
}

export default CourseArticleList;
