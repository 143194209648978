import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import ComposedChartDashboard from '../../Components/Charts/ComposedChartDashboard';
import AreaChartDashboard from '../../Components/Charts/AreaChartDashboard';
import BarChartDashboard from '../../Components/Charts/BarChartDashboard';
import './EvolutionCharts.scss';

export default function EvolutionCharts({ data }) {
  const [isLoadingGa, setIsLoadingGa] = useState(false);
  const [isLoadingDb, setIsLoadingDb] = useState(false);

  const [gaData, setGaData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [chartType, setChartType] = useState('sessionVsBounceRate');
  const [evolutivePeriod, setEvolutivePeriod] = useState('monthly');
  const [drillUp, setDrillUp] = useState(false);

  const loadData = async () => {
    let period, groups, groupsDb;

    setIsLoadingGa(true);
    setIsLoadingDb(true);

    if (evolutivePeriod === 'monthly') {
      period = 'yearAndMonth';
      setDrillUp(false);
    } else if (evolutivePeriod === 'weekly') {
      period = 'week';
      setDrillUp(true);
    } else if (evolutivePeriod === 'daily') {
      period = 'date';
      setDrillUp(true);
    }

    groups = data?.googleAnalyticsData.reduce((groups, item) => {
      const group = (groups[item[period]] || []);

      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups[item[period]] = group;
      }

      return groups;
    }, {});

    const gaDataFormated = await formatGaData(groups);
    setGaData(gaDataFormated);

    groupsDb = data?.databaseData.reduce((groupsDb, item) => {
      const group = (groupsDb[item[period]] || []);

      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groupsDb[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groupsDb[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groupsDb[item[period]] = group;
      }

      return groupsDb;
    }, {});

    const dbDataFormated = await formatRegistrationData(groupsDb);
    setRegistrationData(dbDataFormated);

    setIsLoadingGa(false);
    setIsLoadingDb(false);
  };

  const formatGaData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              session: accumulator.session + currentValue.session,
              bounceRate: accumulator.bounceRate + currentValue.bounceRate,
              users: accumulator.users + currentValue.users,
              newUsers: accumulator.newUsers + currentValue.newUsers,
              pageViews: accumulator.pageViews + currentValue.pageViews,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          barColumn.bounceRate = (barColumn.bounceRate / groups[item].length) * 100;
          result.push(barColumn);
        } else if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            session: value.session,
            bounceRate: value.bounceRate * 100,
            users: value.users,
            newUsers: value.newUsers,
            pageViews: value.pageViews,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      })
    };

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        const value = groups[item][0];
        value['name'] = item;

        result.push({
          name: item,
          session: value.session,
          bounceRate: value.bounceRate * 100,
          users: value.users,
          newUsers: value.newUsers,
          pageViews: value.pageViews,
          periodToEvaluate: evolutivePeriod,
          week: value.week,
          day: value.date
        });
      })
    }

    return result;
  };

  const formatRegistrationData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              users: accumulator.users + currentValue.users,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          result.push(barColumn);
        } else if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            users: value.users,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          })
        }
      })
    }

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        result.push(groups[item][0]);
      });
    }

    return result;
  };

  const handleOnclickButton = (chart) => {
    setChartType(chart);
    setEvolutivePeriod('monthly');
  };

  const handleDrillUp = () => {
    if (evolutivePeriod === 'daily') {
      setEvolutivePeriod('weekly');
      setSelectedPeriod(selectedWeek);
    } else if (evolutivePeriod === 'weekly') {
      setEvolutivePeriod('monthly');
    }
  };

  const getNameAndPeriodOfChart = () => {
    let evatuatedBy = 'mês';
    let graphName = 'Sessões vs % Taxa de rejeição';

    if (evolutivePeriod === 'monthly') {
      evatuatedBy = 'mês';
    } else if (evolutivePeriod === 'weekly') {
      evatuatedBy = 'semana';
    } else if (evolutivePeriod === 'daily') {
      evatuatedBy = 'dia';
    }

    if (chartType === 'sessionVsBounceRate') {
      graphName = 'Sessões vs % Taxade rejeição';
    } else if (chartType === 'visits') {
      graphName = 'Visitantes e novos visitantes';
    } else if (chartType === 'pageView') {
      graphName = 'Visualiações de páginas';
    } else if (chartType === 'signingUps') {
      graphName = 'Cadastros';
    }

    return graphName + ' por ' + evatuatedBy;
  };

  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();

  }, [evolutivePeriod, selectedPeriod, selectedMonth]);

  return (
    <div className='evolution-charts-container'>
      <div className='header'>
        <div>
          Evolutivo mensal / semanal / diário
        </div>
        <div className='btn-container'>
          {drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;-</button>}
          <button type='button' onClick={() => handleOnclickButton('sessionVsBounceRate')}>Sessões vs % Taxa de rejeição</button>
          <button type='button' onClick={() => handleOnclickButton('visits')}>Visitantes / Novos</button>
          <button type='button' onClick={() => handleOnclickButton('pageView')}>Visualizações de páginas</button>
          <button type='button' onClick={() => handleOnclickButton('signingUps')}>Cadastros</button>
        </div>
      </div>
      <div>
        {getNameAndPeriodOfChart()}
      </div>
      <div className='chart-container'>
        {(isLoadingGa && chartType !== 'signingUps') && <CircularProgress color="secondary" className={'loading'} />}
        {(!isLoadingGa && chartType === 'sessionVsBounceRate') && (
          <ResponsiveContainer width={'99%'} height={200}>
            <ComposedChartDashboard data={gaData}
              barNameDatakey={[{ name: 'Sessão', datakey: 'session', fill: "#05CCAE" }]}
              lineDatakey={'bounceRate'}
              lineName={'Taxa de rejeição'}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              chartMargin={{ top: 0, right: 20, bottom: 20, left: 0 }}
              angle={-45} />
          </ResponsiveContainer>
        )}
        {(!isLoadingGa && chartType === 'visits') && (
          <ResponsiveContainer width={'99%'} height={200}>
            <BarChartDashboard data={gaData}
              nameAndDataKey={[{ name: 'Visitas', dataKey: 'users', fill: '#05CCAE' },
              { name: 'Visitas únicas', dataKey: 'newUsers', fill: '#008F7A' }]}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              showBarLabels={true}
              toolTipName={'Período'}
              chartMargin={{ top: 0, right: 20, bottom: 20, left: 0, }}
              angle={-45} />
          </ResponsiveContainer>
        )}
        {(!isLoadingGa && chartType === 'pageView') && (
          <ResponsiveContainer width={'99%'} height={200}>
            <AreaChartDashboard data={gaData}
              fillColor={'#F0F8FF'}
              name={'Páginas visualizadas'}
              dataKey={'pageViews'}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              chartMargin={{ top: 0, right: 30, bottom: 20, left: 15, }}
              angle={-45} />
          </ResponsiveContainer>
        )}
        {(isLoadingDb && chartType === 'signingUps') && <CircularProgress color="secondary" className={'loading'}></CircularProgress>}
        {(!isLoadingDb && chartType === 'signingUps') && (
          <ResponsiveContainer width={'99%'} height={200}>
            <AreaChartDashboard data={registrationData}
              fillColor={'#DAF439'}
              name={'Cadastros'}
              dataKey={'users'}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              chartMargin={{ top: 0, right: 30, bottom: 20, left: 15, }}
              isKformatting={true}
              angle={-45} />
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}