import React, { useEffect, useState } from 'react';
import '../../../../../../backoffice.scss';
import ModuleListing from '../../../components/ModuleListing/ModuleListing';
import api from "../../../../../../services/api";

const Globals = require("../../../../../../Globals.json");

export default function AchievementsMatrix() {

  const [achievements, setAchievementss] = useState([]);

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData() {
    await api.get(Globals.app_api.achievementMatrices)
      .then((res) => {
        setAchievementss(parserToModuleListing(res.data.content.achievement_groups_list))
      })

  }

  function parserToModuleListing(response) {
    return response.map((item) => {
      const isActive = item.is_active ? 1 : 0
      return {
        id: item.id,
        name: item.name,
        type_module_status_id: isActive,
      }
    })
  }

  return (
    <ModuleListing
      title="Grupo de Conquistas"
      buttonLabel="Novo Grupo"
      filterPlaceHolder="Filtre por nome"
      cellNameLabel ="Nome"
      fullUrl={Globals.paths.backofficeAchievementMatrixEdit}
      urlReplacePattern=":achievement_id"
      customList={achievements}
      hideDescription
      hideThumbnail/>
  )

}
