import React, { Component } from 'react';
import { Button, CircularProgress, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './expensesSelect.scss';
import api from '../../../../services/api';
import Globals from '../../../../Globals.json';

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatWithOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale';


import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
class ExpensesSelect extends Component {

    step = 3;

    state = {
        loadedDataPhotograph: null,
        loadedDataPhotographicsInfo: null,
        selectedDate: this.props.state.data.selectedDate,
        photographIdReference: this.props.state.data.photographIdReference,
        loadedData: null,
        isLoading: true,
        selectedId: null,
        expensesType: this.props.state.data.expensesType,
        expensesTypeName: this.props.state.data.expensesType === 1 ? 'fixed' : 'variable',
        matrixName: ''
    }

    getProgressWidth() {
        const w = this.step * 20 + '%';
        return w === '100%' ? '101%' : w;
    }

    componentDidMount = () => {
        this.props.changeWidth('md')
        this.loadData()
    }


    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    loadData = async () => {
        this.setState({
            isLoading: true,
        })

        try {

            const res = await api.get(Globals.api.typeExpensesMatrixComboboxUser, { params: { type_expense_id: this.props.state.data.expensesType, show_inactives: 1 } })
            this.setState({
                loadedData: res.data,
            })

            const res3 = await api.get(Globals.api.userFinancialPhotographCombobox)

            this.setState({
                loadedDataPhotograph: res3.data,
            })

            let res4 = null

            if (this.state.photographIdReference) {
                res4 = await api.get(Globals.api.listUserExpenseItem, { params: { cad_user_financial_photograph_id: this.state.photographIdReference } })
            } else {
                res4 = await api.get(Globals.api.listUserExpenseItem, { params: { cad_user_financial_photograph_id: res3.data[0].id } })
            }

            this.setState({
                loadedDataPhotographicsInfo: res4.data,
            })

        } catch (error) {
            console.log('erro', error)
        }

        this.setState({
            isLoading: false,
        })
    }

    changeDate = async (date) => {
        this.setState({
            isLoadingData: true,
        })
        this.setState({ selectedDate: date })
        const formatedDate = format(date, 'MMMM yyyy', { locale: ptBR }).toLocaleUpperCase()

        const foundMonth = this.state.loadedDataPhotograph.find(el => el.periodo === formatedDate)
        if (foundMonth) {
            await this.loadCardsInfo(foundMonth.id)
        } else {
            this.setState({
                loadedDataPhotographicsInfo: null,
            })
        }
        this.setState({
            isLoadingData: false,
        })
        this.handleSelectItem(null)
    }

    handleSelectItem = (value, name) => {
        this.setState({ selectedId: value, matrixName: name })
    }

    isIncluded = (value) => {
        const typeSelected = this.state.expensesTypeName
        if (!!this.state.loadedDataPhotographicsInfo?.[typeSelected] && !!this.state.loadedDataPhotographicsInfo[typeSelected]?.matrizes) {
            if (Object.keys(this.state.loadedDataPhotographicsInfo[typeSelected]?.matrizes).includes(value)) {
                return true
            }
        }
        return false
    }

    numberToCurrency = (value) => {
        const currency = new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            style: 'currency',
        }).format(value);
        return currency
    }

    loadCardsInfo = async (value) => {

        try {

            const res4 = await api.get(Globals.api.listUserExpenseItem, { params: { cad_user_financial_photograph_id: value } })
            this.setState({
                loadedDataPhotographicsInfo: res4.data,
            })


        } catch (error) {
            console.log(error.response)
        }
        this.setState({
            isLoadingData: false,
        })
    }

    render() {
        return (

            <Grid className='expenses-select'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <h2>Cadastro de despesas {this.state.expensesType === 1 ? 'fixas' : 'variáveis'} </h2>
                    {/* <div className="datepicker-container">
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <DatePicker
                                format="MMMM yyyy"
                                okLabel="Selecionar"
                                cancelLabel="Cancelar"
                                inputVariant="outlined"
                                size="small"
                                openTo={"month"}
                                value={this.state.selectedDate}
                                onChange={(newValue) => this.changeDate(newValue)}
                                views={["year", "month"]}
                            />

                        </MuiPickersUtilsProvider>
                        <ArrowDropDown />
                    </div> */}
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <DialogContent className="dialog-content" >

                    <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                        <h6>Escolha uma matriz como guia de cadastro.</h6>
                        {/* <p><span>Valor total: </span> {!!this.state.loadedDataPhotographicsInfo ? this.numberToCurrency(this.state.loadedDataPhotographicsInfo[this.state.expensesTypeName].valor_total) : 'R$ 0,00'}</p> */}
                        <Grid container item xs={12} direction="row" className={'card-container'} justify="flex-start" >
                            {this.state.isLoading ? (
                                <Grid item xs={12} className="loading-area">

                                    <CircularProgress />

                                </Grid>) :
                                (<>
                                    {this.state.loadedData?.length > 0 &&
                                        this.state.loadedData?.map((item) => {
                                            if (item.type_status_id === 1 || this.isIncluded(item.id)) {
                                                return (
                                                    <Grid key={item.id}
                                                        container
                                                        item
                                                        xs={4}
                                                        direction="column"
                                                        justify="flex-start"
                                                        className={this.state.selectedId === item.id ? 'card selected' : 'card'}
                                                        onClick={() => this.handleSelectItem(item.id, item.name)}>
                                                        {!!this.state.loadedDataPhotographicsInfo?.[this.state.expensesTypeName]?.matrizes[`${item.id}`] &&
                                                            <h5>{this.numberToCurrency(this.state.loadedDataPhotographicsInfo[this.state.expensesTypeName].matrizes[`${item.id}`].valor_total_matrix)}</h5>}
                                                        <p>{item.name}</p>
                                                    </Grid>
                                                )
                                            } else {
                                                return ''
                                            }

                                        })
                                    }
                                </>)
                            }
                        </Grid>

                    </Grid>


                </DialogContent >
                <DialogActions className="dialog-footer space-between">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        color="primary"
                        variant="outlined"
                        onClick={() => { this.props.goBack() }}
                        style={{ borderRadius: "0px", width: "120px" }}

                    >
                        voltar
                    </Button>
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.showContent({ to: 'expenses-select-items', from: this.props.page, data: { expensesMatrixId: this.state.selectedId, selectedDate: this.state.selectedDate, matrixName: this.state.matrixName } })}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                        disabled={!this.state.selectedId}
                    >
                        continuar
                    </Button>
                </DialogActions>
            </Grid >
        )
    }
}

export default watch(withRouter(ExpensesSelect), { user: '' });