
import React from 'react';
import './styles/courseList.scss';

import api from '../../../services/api';

import Button from '../button/Button';
import AlertErrors from '../../components/alertErrors/alerts';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as FavoritesIcon } from '../../../assets/icon/header-icons/icon-favorite-stroke-neutre.svg';
import { Grid, Box } from '@material-ui/core';
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const Globals = require("../../../Globals.json");

class CourseList extends React.Component {

  state = {
    isLoading: false,
    courses: [],
    categories: [],
    gridCols: 3,
    alertError: '',
    activeCategory: null,
  }

  addFavorites = (id) => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: id,
      type_module_id: 2,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadDataAllCourses(loading)
      }).catch(error => {
        console.log(error)
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  removeFavorites = (id) => {
    const postData = {
      type_action_id: 26,
      data_modules_detail_id: id,
      type_module_id: 2,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadDataAllCourses(loading)
        console.log(response.data)
      }).catch(error => {
        console.log(error)
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  async loadDataAllCourses (loading) {
    try {
      if (loading) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: true });
      }
      
      let response = ''
      if(this.props.endpoint) {
        response = await api.post(this.props.endpoint, { module_type: 2 });
      } else {
        response = await api.post(Globals.api.getModules, { type_module_id: 2 });
      }

  
      const courses = this.props.sliceCourse ? response?.data.slice(0, 6) : response.data;
      const ongoingCourses = [];
      const categories = [];

      courses.forEach((item) => {
        if (item.user_module_data.hasOwnProperty("startedAt") && !item.user_module_data.hasOwnProperty("finishedCourseAt")) {
          ongoingCourses.push(item);
        }

        if (item.data.hasOwnProperty("categories") && Array.isArray(item.data.categories)) {
          item.data.categories.forEach(itemCat => {
            if (categories.indexOf(itemCat) === -1) {
              categories.push(itemCat);
            }
          })
        }
      })

      const sortedCoursesByProgress = courses.sort((a, b) => a.progress < b.progress ? -1 : 1);

      this.setState({ courses: sortedCoursesByProgress, categories, ongoingCourses });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000)
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount () {
    this.loadDataAllCourses()
  }

  componentDidUpdate (oldProps) {

    if (this.props.courses !== oldProps.courses) {
      this.setState({ courses: this.props.courses });
    }

    if (this.props.categories !== oldProps.categories) {

      this.setState({ categories: this.props.categories });
    }
  }

  getFilteredCourses () {

    if (this.state.activeCategory === null) {
      return this.state.courses;
    }
    else {

      const filteredCourses = this.state.courses.filter(i => {
        if (i.data.categories && i.data.categories.indexOf(this.state.activeCategory) > -1) {
          return true;
        }
        else {
          return false;
        }
      })

      return filteredCourses;

    }

  }

  goToCourse = (course) => {
    dispatchSet('courses.selectedCourse', course);
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", course.id));
  }

  render () {
    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ?
          <Grid>
            <Box pt={0.5} style={{ display: 'flex', flexDirection: "column" }}>
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <Skeleton variant="rect" width={230} height={170} />
                <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <Skeleton variant="rect" width={230} height={170} />
                <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <Skeleton variant="rect" width={230} height={170} />
                <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
              </div>
              <br />
            </Box>
          </Grid> :
          <div>
            {this.props.showCategories &&
              <div className="cotent_actions_btn">
                <button className={this.state.activeCategory === null ?
                  "course_list_btn_desc_class_active"
                  : "course_list_btn_desc_class"
                }
                  onClick={() => this.setState({ activeCategory: null })}>
                  TODOS</button>

                {
                  this.state.categories.map((item, index) => (

                    <button key={index} className={this.state.activeCategory === item ?
                      "course_list_btn_desc_class_active"
                      : "course_list_btn_desc_class"
                    }
                      onClick={() => this.setState({ activeCategory: item })}>
                      {item.toUpperCase()}</button>
                  ))
                }
              </div>
            }
            <br />
            <Grid>

              {this.getFilteredCourses().map((item, index) => (
                <div key={index}>
                  <div className="course_list_courseItemContainer">
                    <div className="course_list_courseImage" >
                      <img className="course_list_imageCourse" src={item.thumbnail} alt={item.name} />
                      <div className="button_save">
                        <button className="course_list_addFavorite"
                          onClick={() => { item.is_favorite !== null ? this.removeFavorites(item.id) : this.addFavorites(item.id) }}>
                          {item.is_favorite !== null ?
                            <FavoritesIcon style={{ stroke: "#05CCAE", width:"25px", marginTop:"-5px"}} /> : <FavoritesIcon style={{ stroke: "#FFF", width:"25px", marginTop:"-5px"}} />
                          }
                        </button>

                        {getCoinsFromRewards(item.data.rewards) > 0 &&
                          <div className="course_list_contentCoins">
                            <div className="course_list_coins">{getCoinsTextFromRewards(item.data.rewards)}</div>
                          </div>
                        }

                      </div>
                    </div>

                    <div className="course_list_courseInfoContainer">
                      <div className="course_list_courseInfoTitle">{item.name}</div>
                    </div>
                    <div className="course_list_buttonContainer">
                      <Button minWidth="128px" minHeight="36px" fontSize="12px" onClick={() => this.goToCourse(item)}
                      >
                        {item.user_module_data.finishedCourseAt ? 'Ver novamente' :
                          item.user_module_data.startedAt ? "Continuar" : "Começar"}

                      </Button>
                    </div>
                    <div className="course_list_borderRight">
                    </div>
                  </div>
                </div>
              ))}
            </Grid>

          </div>
        }
      </>
    )
  }

}

export default CourseList;
