import React from 'react';
import RegisterStep3Card from './RegisterStep3Card';
import { withStyles, Grid, Button, CircularProgress, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Autocomplete from '@material-ui/lab/Autocomplete';
import AlertErrors from '../../logged_in/components/alertErrors/alerts';
import api from '../../services/api';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { getStatus } from "../../services/auth";
import './registerStepper3.scss';
import { enumTypeUserStatuses } from "../../services/enums";
import { dispatch, dispatchSet } from 'redux-easy/lib/redux-easy';

const styles = theme => ({

  root: {
    flexGrow: 1,
    overflow: "auto",
    padding: "25px 65px 20px 65px",
    "@media (max-width: 700px)": {
      padding: "20px",
      overflow: "auto",
    }
  },
  step_button: {
    marginLeft: "5px",
    marginRight: "5px",

    '@media (max-width: 599px)': {
      marginBottom: "10px",
      width: "100%",
      justifyContent: "space-between",
    }
  },
  description:
  {
    fontSize: "20px",
    color: "#595959",
    textAlign: "center",
    marginBottom: "20px",
    "@media (max-width: 700px)": {
      fontSize: "13px"
    }
  },
  loading:
  {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "240px",

  },
  containerBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: "-2px",
    "@media (max-width: 700px)": {
      padding: "2px !important"
    }
  },
  containerTextField: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: "-7px",
    "@media (max-width: 700px)": {
      flexDirection: "column"
    }
  },
  cardItem: {
    overflow: "auto",
    marginBottom: "5px",
    "@media (max-width: 700px)": {
      marginBottom: "0",
      padding: "2px !important",
    }
  },
  cardsContainer: {
    overflow: "auto",
    maxHeight: "240px",
    "@media (max-width: 700px)": {
      height: "240px"
    }
  },

});

const Globals = require("../../Globals.json");

class RegisterStep3 extends React.Component {

  state = {
    isLoading: false,
    isProcessing: false,
    interests: [],
    categories: [],
    alertError: '',
    classes: [],
    selectedInterests: [],
    selectedCategory: 0,
    step: 1,
  }

  onSelectCard = (cardId) => {

    console.log("Selecionado " + cardId);
    this.setState({ selectedCardId: cardId })

  };

  toggleCategory = (id) => {
    this.setState({ selectedCategory: id }, this.checkStep);
    this.scrollNext();
  }

  toggleInterest = (interestId) => {
    if (this.state.selectedInterests.indexOf(interestId) === -1) {
      this.addInterest(interestId);
    }
    else {
      this.removeInterest(interestId);
    }
  }

  checkStep = () => {
    let step = 1;

    const interestsFilled = !!this.state.selectedInterests.length;

    if (this.state.selectedCategory) {
      step++;
    }

    if (interestsFilled) {
      step++;
    }

    this.setState({ step });
  }

  addInterest = (interestId) => {
    const arr = this.state.selectedInterests;
    arr.push(interestId);
    this.setState({ selectedInterests: arr }, this.checkStep);
  }

  removeInterest = (interestId) => {
    const arr = this.state.selectedInterests;
    arr.splice(arr.indexOf(interestId), 1);
    this.setState({ selectedInterests: arr }, this.checkStep);
  }

  scrollNext = () => {

    const el = document.getElementById('select-interests-description')
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
      inline: 'center',
    })

  };

  async loadData() {

    try {

      this.setState({ isLoading: true });

      const responseInterests = await api.get(Globals.api.interests, {});
      const responseCategories = await api.get(Globals.api.interestCategories);
      //const responseIndexQuestions = await api.get(Globals.api.indexFirstQuestions, {});

      this.setState({
        isLoading: false,
        interests: responseInterests.data,
        categories: responseCategories.data.data,
      });

    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async saveInterests() {

    this.setState({ isLoading: true });

    this.setState({ isProcessing: true });

    const payload = { interests: this.state.selectedInterests, category: this.state.selectedCategory };

    try {

      const response = await api.post(Globals.api.validateIndebtProfile, payload);
      const selfData = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", selfData.data);

      if (response?.data?.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled) {
        this.props.nextStep();
      }
      else if (response?.data?.type_users_status_id === enumTypeUserStatuses.forwardToBlog) {
        this.props.history.push(Globals.paths.forwardToBlog);
      }

      this.setState({ isLoading: false });
      this.setState({ isProcessing: false });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    } finally {
      this.setState({ isLoading: false });
    }
  }

  setOtherCategoryId(itemId) {
    this.setState({ selectedCategoryText: '', selectedCategoryId: itemId, selectedCategorySet: true });
  }

  setOtherCategoryText(text) {
    this.setState({ selectedCategoryText: text, selectedCategoryId: -1, selectedCategorySet: true });
  }

  checkVisible(elm, threshold, mode, vh) {
    threshold = threshold || 0;
    mode = mode || 'visible';

    var rect = elm.getBoundingClientRect();
    var viewHeight = window.innerHeight || document.documentElement.clientHeight;
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }

  handleScroll = (e) => {
    const container = e.target;
    let els = document.getElementsByClassName('question-item');
    const s = 240;

    for (let i = 0; i < els.length; i++) {
      let c = els[i];
      const visible = this.checkVisible(c, s, 'visible');
      const hasClass = c.className.indexOf('active') !== -1;
      if (visible && !hasClass) {
        c.classList.add('active')
      }
      else if (!visible && hasClass) {
        c.classList.remove('active');
      }
    }

    els = document.getElementsByClassName('interests-holder');

    for (let i = 0; i < els.length; i++) {
      let c = els[i];
      const visible = this.checkVisible(c, s, 'visible');
      const hasClass = c.className.indexOf('active') !== -1;
      if (visible && !hasClass) {
        c.classList.add('active')
      }
      else if (!visible && hasClass) {
        c.classList.remove('active');
      }
    }

    els = document.getElementsByClassName('categories-holder');

    for (let i = 0; i < els.length; i++) {
      let c = els[i];
      const visible = this.checkVisible(c, s, 'visible');
      const hasClass = c.className.indexOf('active') !== -1;
      if (visible && !hasClass) {
        c.classList.add('active')
      }
      else if (!visible && hasClass) {
        c.classList.remove('active');
      }
    }

  }

  componentDidMount() {
    this.loadData();
    document.getElementById('#registerStepper3').addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.getElementById('#registerStepper3').removeEventListener('scroll', this.handleScroll);
  }


  getProgressWidth() {
    const w = this.state.step * 50 + '%';
    return w === '100%' || w === '150%' ? '101%' : w;
  }

  decreaseStep() {
    this.setState({ step: this.state.step - 1 });
  }

  increaseStep() {
    this.setState({ step: this.state.step + 1 });
  }

  selectQuestionOption(question, option) {

    let block = this.state.indexQuestionBlock;

    const finded = block.questions.find(item => { return question.qid === item.qid; });
    finded.value = option.id;

    this.setState(block, this.checkStep);
  }

  render() {

    const { classes } = this.props;
    const { prevStep } = this.props;

    return (
      <Grid container>
        <Grid container className="progress-floater">
          <div className="progress-container">
            <div className="progress" style={{ width: this.getProgressWidth() }}></div>
          </div>
          <Grid container spacing={0} className="progress-label-container">
            <Grid item xs={12} className="progress-label">
              Passo {this.state.step === 3 ? 2 : this.state.step } de 2
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.root + ' registerStepper3'} id="#registerStepper3">
          <AlertErrors side error={this.state.alertError} />
          <Grid container spacing={2}>
            {
              this.state.isLoading ?
                (
                  <Grid item xs={12} className={classes.loading}>
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  </Grid>
                )
                :
                (
                  <>

                    <Grid container className="categories-holder active">
                      <Grid item xs={12} className="select-categories-description">
                        Para personalizar o seu planejamento, clique na opção abaixo que mais retrata seu momento atual:
                      </Grid>

                      <Grid container className="categories-container">
                        {this.state.categories.map((item, index) => (
                          <Grid key={`categorie_${item.id}`} item xs={6} md={4} onClick={() => this.toggleCategory(item.id)}
                            className={`categorie noselect ${this.state.selectedCategory === item.id ? 'selected' : ''}`}>
                            <div className="categorie-img" style={{ background: `url(/images/mock/cat${item.id}.jpg)` }}>
                              <span>{item.type}</span>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>



                    <Grid container className="interests-holder">
                      <Grid item xs={12} className="select-interests-description" id="select-interests-description">
                        Clique no painel abaixo nas opções que mais representam conquistas financeiras para você:
                      </Grid>

                      <Grid container className="interests-container">
                        {this.state.interests.map((item, index) => (
                          <Grid key={`interest_${item.id}`} item xs={6} md={4} onClick={() => this.toggleInterest(item.id)}
                            className={`interest noselect ${this.state.selectedInterests.indexOf(item.id) !== -1 ? 'selected' : ''}`}>
                            <div className="interest-img" style={{ background: item.thumbnail !== null ? `url(${item.thumbnail})` : '#717171' }}>
                              <span>{item.type}</span>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '20px' }} >
                      <Button
                        disabled={this.state.selectedInterests.length === 0 || this.state.step !== 3}
                        variant="contained"
                        color="primary"
                        className={`${classes.step_button} g-rs3-confirm`}
                        onClick={() => { this.saveInterests() }}
                        style={{ borderRadius: "0px" }}
                      >CONFIRMAR
                      </Button>
                    </Grid>
                  </>
                )
            }

          </Grid>
        </div>
      </Grid>
    )
  }

}

export default withStyles(styles)(withRouter(RegisterStep3));

