import React from 'react';
import './styles/favorite.scss';

import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { TurnedInNot } from '@material-ui/icons';
import { watch } from 'redux-easy/lib/redux-easy';
import { Hidden, Grid, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import SwipeableViews from "react-swipeable-views";
import Pagination from "../pagination/Pagination";

import api from '../../../services/api';
import Socket from '../socket/Socket';

const Globals = require("../../../Globals.json");
class Favorite extends React.Component {

  state = {
    courses: [],
    artigos: [],
    index: 0,
    favorites: 0,
    userId: -1,
    alertError: ''
  }

  loadDataAllCourses (loading) {
    if (loading) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
    try {
      api.post(Globals.api.getModules, { type_module_id: 2 })
        .then((response) => {
          this.setState({ courses: response.data, isLoading: false });
        })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadDataAllArticles (loading) {
    if (loading) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
    try {
      const response = await api.post(Globals.api.getModules, { type_module_id: 10 });

      this.setState({ artigos: response.data, isLoading: false });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }


  async favotites () {
    try {
      const response = await api.get(Globals.api.favorites);
      this.setState({ favorites: response.data.total });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.setState({ userId: this.props.user.profile.id });
    this.loadDataAllCourses()
    this.loadDataAllArticles()
    this.favotites()
  }

  reload = () => {
    var loading = true;
    this.loadDataAllCourses(loading)
    this.loadDataAllArticles(loading)
    this.favotites()
  }

  favoritesChanged = () => {
    this.reload();
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render () {
    const { index } = this.state;
    return (
      <>
        <Socket
          channel={"notifications"}
          user={this.props.user}
          event="favoritesChanged"
          onUpdate={(e) => this.favoritesChanged()}
        />

        {/* <Pusher
          channel={"notifications." + this.state.userId}
          event="favoritesChanged"
          onUpdate={(e) => this.favoritesChanged()}
        /> */}
        <AlertErrors side error={this.state.alertError} />

        <Hidden xsDown>
          <div style={{ padding: "0px 10px 0px 10px" }}>
            {this.state.isLoading ?
              <Grid style={{ marginLeft: "20px" }}>
                <Box pt={0.5} >
                  <Skeleton variant="rect" width={"20vw"} height={100} />
                  <br />
                  <Skeleton variant="rect" width={"20vw"} height={100} />
                  <br />
                  <Skeleton variant="rect" width={"20vw"} height={100} />
                  <br />
                </Box>
              </Grid> :
              <>
                {this.state.favorites === 0 ? <span style={{ fontSize: "14px", marginLeft: "9px", color: "#202020" }}>Nenhum favorito adicionado...</span> :
                  <>
                    {this.state.courses.map(item => (
                      <div key={item.id}>
                        {item.is_favorite !== null &&
                          <Link to={Globals.paths.courseDetail.replace(":courseId", item.id)} className="fav_cardFavorit">
                            <TurnedInNot className="fav_iconFavorit" />
                            <p className="fav_textTitle">{item.name}</p>
                          </Link>
                        }
                      </div>
                    ))}
                    {this.state.artigos.map(item => (
                      <div key={item.id}>
                        {item.is_favorite !== null &&
                          <Link to={Globals.paths.article.replace(":articleId", item.id)} className="fav_cardFavorit">
                            <TurnedInNot className="fav_iconFavorit" />
                            <p className="fav_textTitle">{item.name}</p>
                          </Link>
                        }
                      </div>
                    ))}
                  </>}
              </>
            }
          </div>
        </Hidden>

        <Hidden smUp>
          <AlertErrors error={this.state.alertError} />
          <div className="fav_pagination">
            <div className="fav_container">
              <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }} >
                {this.state.courses.map((item, index) => (
                  <div key={item.id}>
                    {item.is_favorite === 1 &&
                      <Link to={Globals.paths.courseDetail.replace(":courseId", item.id)} className="fav_cardFavorit">
                        <TurnedInNot className="fav_iconFavorit" />
                        <p className="fav_textTitle">{item.name}</p>
                      </Link>
                    }
                  </div>
                ))}
              </SwipeableViews>
            </div>
            <Pagination
              dots={this.state.courses.length}
              index={index}
              onChangeIndex={this.handleChangeIndex}
            />
          </div>
        </Hidden>
      </>

    )
  }
}

export default watch((Favorite), { user: '' });
