import { Dialog, DialogContent, Switch } from '@material-ui/core';
import React, { Component } from 'react'
import './ProfileRemoveModal.scss'

import Close from '../../../assets/user/excluir_usuario.svg'

import Button from '../../components/button/Button';


import api from '../../../services/api';
import Globals from '../../../Globals.json';

export class ProfileRemoveModal extends Component {
    state = {
        success: false,
        confirm_exclusion: false
    }

    handleDeleteUser = async () => {
        await api.post(Globals.api.selfRemoveAccount, { confirm_exclusion: this.state.confirm_exclusion })
            .then(response => {
                if (response?.data?.error == 0) {
                    this.setState({ success: true });
                    return window.location.href = Globals.paths.root;
                }
            }).catch(error => {
                this.setState({ failed: true });
                console.log(error)
            })
    }

    handleConfirmChange = () => {
        this.setState({ confirm_exclusion: !this.state.confirm_exclusion });
    };

    handleOnClose = () => {
        this.setState({ confirm_exclusion: false });
        this.props.onClose();
    };

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleOnClose}
                maxWidth={"xs"}
                className="modal"
            >
                <DialogContent className="modal_self_remove_profile">

                    <div className="modal_delete_user_header">
                        <button type="button" className="close"
                            onClick={this.handleOnClose}>&times;
                        </button>
                    </div>

                    <div className="modal_self_remove_profile">
                        <img src={Close} alt="Excluir" />
                    </div>

                    <section>
                        <span>
                            Você tem certeza que quer excluir sua conta permanentemente?
                        </span>

                        <p>
                            Ao confirmar, o seu Índice de Saúde Financeira, seus cursos, seu progresso pessoal e todos os seus dados serão removidos de forma permanente. Tem certeza de que deseja continuar?
                        </p>
                    </section>

                    <span className="optin-item-label">Estou ciente e desejo remover:</span>

                    <Switch
                        checked={this.state.confirm_exclusion}
                        onChange={() => {
                            this.handleConfirmChange();
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                    <div className='button-flex-remove-profile'>
                        <Button icon="none" type="green" textAlign={'center'} onClick={this.handleOnClose}>Cancelar</Button>
                        {!this.state.confirm_exclusion ?
                            <Button icon='none' type="disabled" disabled>Confirmar</Button> :
                            <Button icon='none' type="danger" onClick={() => this.handleDeleteUser()}>Confirmar</Button>
                        }                        
                    </div>


                </DialogContent>
            </Dialog>
        )
    }
}

export default ProfileRemoveModal;