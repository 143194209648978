import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles"

export const Partners = withTheme(styled('section')`
  background: #fff;
  padding: 90px 0;
  
  .inner-wrapper {
    align-items: center;
    display: flex;
    margin: auto;
    max-width: 1440px;
    position: relative;
    flex-direction: column;

    .grid-container {
      display: grid;      
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      gap: 10px;
      margin: 0 auto;
      width: 100%;
      padding: 0 250px;

      @media(max-width: 1059px) {
        padding: 0 40px;
      }
    }

    .grid-item {
        display: flex;
        text-align: center;
        padding: 10px;
        align-items: center;
        justify-content: center;
        height: 130px;

        @media(max-width: 540px) {
          max-height: 100px;
        }
    }

    .grid-item img {
        max-width: 100%;
        height: auto;

        &.image-01 {
          max-width: 70%;
        }

        &.image-10 {
          max-width: 70%;
        }

        &.image-11 {
          max-width: 70%;
        }

        &.image-14 {
          max-width: 70%;
        }

        &.image-15 {
          max-width: 85%;
        }

        &.image-16 {
          max-width: 65%;
        }

        &.image-17 {
          max-width: 55%;
        }

        &.image-21 {
          max-width: 60%;
        }

        &.image-22 {
          max-width: 45%;
        }

        &.image-23 {
          max-width: 50%;
        }

        &.image-24 {
          max-width: 60%;
        }

        &.image-25 {
          max-width: 60%;
        }
    }

    @media(max-width: 449px) {
      .grid-item img {
        &.image-01 {
          max-width: 50%;
        }

        &.image-10 {
          max-width: 50%;
        }

        &.image-11 {
          max-width: 50%;
        }

        &.image-14 {
          max-width: 50%;
        }

        &.image-15 {
          max-width: 65%;
        }

        &.image-16 {
          max-width: 45%;
        }

        &.image-17 {
          max-width: 35%;
        }

        &.image-21 {
          max-width: 40%;
        }

        &.image-22 {
          max-width: 25%;
        }

        &.image-23 {
          max-width: 30%;
        }

        &.image-24 {
          max-width: 40%;
        }

        &.image-25 {
          max-width: 40%;
        }
      }
    }
  }

  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 50px;
  }
`);