import React from "react"
// import { arc } from "d3-shape"
// import { scaleLinear } from "d3-scale"
import { format } from "d3-format"
import './style.scss'

const Gauge = ({ value, min = 0, max = 1000, label, units, }) => {

  //transform="rotate(100 95 90)"

  // rotacao vai de -80 a 100
  const rot = (180 / 1000 * value) - 80;

  const getOpacity = (index) => {
    const ref = value / 1000 * 8 + 1;
    return index <= ref ? 1 : .1;
  }

  // const backgroundArc = arc()
  //   .innerRadius(0.65)
  //   .outerRadius(1)
  //   .startAngle(-Math.PI / 2)
  //   .endAngle(Math.PI / 2)
  //   .cornerRadius(1)
  //   ()

  // const percentScale = scaleLinear()
  //   .domain([min, max])
  //   .range([0, 1])
  // const percent = percentScale(value)

  // const angleScale = scaleLinear()
  //   .domain([0, 1])
  //   .range([-Math.PI / 2, Math.PI / 2])
  //   .clamp(true)

  // const angle = angleScale(percent)

  // const filledArc = arc()
  //   .innerRadius(0.65)
  //   .outerRadius(1)
  //   .startAngle(-Math.PI / 2)
  //   .endAngle(angle)
  //   .cornerRadius(1)
  //   ()

  // const colorScale = scaleLinear()
  //   .domain([0, 1])
  //   .range(["#dbdbe7", "#4834d4"])

  // const gradientSteps = colorScale.ticks(10)
  //   .map(value => colorScale(value))

  // const markerLocation = getCoordsOnArc(
  //   angle,
  //   1 - ((1 - 0.65) / 2),
  // )

  return (
    <div className="Gaugr__container">
      {/* <svg style={{overflow: "visible"}}
        width="9em"
        viewBox={[
          -1, -1,
          2, 1,
        ].join(" ")}>
        <defs>
          <linearGradient
            id="Gauge__gradient"
            gradientUnits="userSpaceOnUse"
            x1="-1"
            x2="1"
            y2="0">
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${
                  index
                  / (gradientSteps.length - 1)
                }`}
              />
            ))}
          </linearGradient>
        </defs>
        <path
          d={backgroundArc}
          fill="#dbdbe7"
        />
        <path
          d={filledArc}
          fill="url(#Gauge__gradient)"
        />
        <line
          y1="-1"
          y2="-0.65"
          stroke="white"
          strokeWidth="0.027"
        />
        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.2"
          stroke="#2c3e50"
          strokeWidth="0.01"
          fill={colorScale(percent)}
        />
        <path
          d="M0.136364 0.0290102C0.158279 -0.0096701 0.219156 -0.00967009 0.241071 0.0290102C0.297078 0.120023 0.375 0.263367 0.375 0.324801C0.375 0.422639 0.292208 0.5 0.1875 0.5C0.0852272 0.5 -1.8346e-08 0.422639 -9.79274e-09 0.324801C0.00243506 0.263367 0.0803571 0.120023 0.136364 0.0290102ZM0.1875 0.381684C0.221591 0.381684 0.248377 0.356655 0.248377 0.324801C0.248377 0.292947 0.221591 0.267918 0.1875 0.267918C0.153409 0.267918 0.126623 0.292947 0.126623 0.324801C0.126623 0.356655 0.155844 0.381684 0.1875 0.381684Z"
          transform={`rotate(${
            angle * (180 / Math.PI)
          }) translate(-0.2, -0.33)`}
          fill="#6a6a85"
        />

      </svg> */}
      <svg width="189" height="94" viewBox="0 0 189 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity={getOpacity(1)} fillRule="evenodd" clipRule="evenodd" d="M42.4236 43.4854L27.1097 28.1475C19.0066 36.385 12.4042 46.1053 7.74316 56.8663L27.6837 65.3424C31.2487 57.1626 36.2709 49.7673 42.4236 43.4854Z" fill="#FF4821" />
        <path opacity={getOpacity(2)} fillRule="evenodd" clipRule="evenodd" d="M66.3974 27.2319L58.2817 7.11719C46.9382 11.8123 36.7104 18.6593 28.1035 27.1523L43.4174 42.4903C49.9854 36.038 57.7702 30.8257 66.3974 27.2319Z" fill="#EF9201" />
        <path opacity={getOpacity(3)} fillRule="evenodd" clipRule="evenodd" d="M27.1338 66.6372L7.19254 58.1611C2.64038 69.1171 0.0920409 81.1159 0 93.701H21.657C21.7476 84.1229 23.6861 74.9888 27.1338 66.6372Z" fill="#E32901" />
        <path opacity={getOpacity(4)} fillRule="evenodd" clipRule="evenodd" d="M145.096 42.4907L160.41 27.1527C152.186 19.0369 142.48 12.4242 131.736 7.75586L123.273 27.7277C131.44 31.2983 138.824 36.3283 145.096 42.4907Z" fill="#59AAFF" />
        <path opacity={getOpacity(5)} fillRule="evenodd" clipRule="evenodd" d="M121.981 27.1763L130.444 7.20381C119.505 2.64452 107.525 0.0921852 94.96 0V21.691C104.523 21.7817 113.643 23.7233 121.981 27.1763Z" fill="#007DFF" />
        <path opacity={getOpacity(6)} fillRule="evenodd" clipRule="evenodd" d="M93.5545 21.691V0C81.566 0.087963 70.1094 2.4123 59.583 6.58526L67.6988 26.7013C75.719 23.5431 84.4362 21.7775 93.5545 21.691Z" fill="#FFAA25" />
        <path opacity={getOpacity(7)} fillRule="evenodd" clipRule="evenodd" d="M161.324 66.5014L181.408 58.3729C176.72 47.0116 169.883 36.7678 161.404 28.1475L146.09 43.4854C152.532 50.0629 157.736 57.8606 161.324 66.5014Z" fill="#008F62" />
        <path opacity={getOpacity(8)} fillRule="evenodd" clipRule="evenodd" d="M166.857 93.7015H188.514C188.426 81.6942 186.106 70.2196 181.939 59.6768L161.854 67.8052C165.008 75.838 166.771 84.5689 166.857 93.7015Z" fill="#008F62" />

        <g transform={`rotate(${rot} 95 90)`} className="seta" filter="url(#filter0_d)">
          <path fillRule="evenodd" clipRule="evenodd" d="M78.8275 25.3995C78.8064 25.3223 78.8035 25.2397 78.8214 25.1584L80.4806 17.6133C80.5772 17.174 81.1573 17.0717 81.3984 17.4515L85.538 23.974C85.5827 24.0444 85.6082 24.1229 85.6148 24.2027C85.6606 24.2639 85.6932 24.3366 85.7074 24.4171L93.9803 71.335C93.9928 71.4057 93.9899 71.4756 93.9739 71.5411C94.034 71.7223 94.0818 71.9104 94.116 72.1044C94.4733 74.1311 93.2198 76.0461 91.3162 76.3818C89.4126 76.7174 87.5797 75.3466 87.2223 73.32C87.1881 73.1259 87.1687 72.9328 87.1631 72.7421C87.1258 72.6859 87.0991 72.6213 87.0867 72.5506L78.8138 25.6326C78.7996 25.5521 78.8053 25.4727 78.8275 25.3995Z" fill="#202020" />
        </g>

        <path fillRule="evenodd" clipRule="evenodd" d="M120.4 93.6027C120.4 78.9428 108.491 67.0586 93.8002 67.0586C79.1094 67.0586 67.2002 78.9428 67.2002 93.6027" fill="#E6E6E6" />
        <defs>
          <filter id="filter0_d" x="69.4385" y="8.61719" width="33.3245" height="76.3721" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>


      <div className="Gauge__metric">
        {format(",")(value)}
      </div>

      {!!label && (
        <div className="Gauge__label">
          { label}
        </div>
      )}

      {!!units && (
        <div>
          { units}
        </div>
      )}
      {/* 
      <div className="Gauge__share">
        <div className="Gauge__flex">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.6493 0H23.3105C22.809 0 22.4023 0.406633 22.4023 0.908203C22.4023 1.40977 22.809 1.81641 23.3105 1.81641H27.6493C28.4953 1.81641 29.1836 2.5047 29.1836 3.35066V7.68945C29.1836 8.19102 29.5902 8.59766 30.0918 8.59766C30.5934 8.59766 31 8.19102 31 7.68945V3.35066C31 1.50314 29.4969 0 27.6493 0Z" fill="#787777"/>
            <path d="M0.908203 8.59766C1.40977 8.59766 1.81641 8.19102 1.81641 7.68945V3.35066C1.81641 2.5047 2.5047 1.81641 3.35072 1.81641H7.68945C8.19102 1.81641 8.59766 1.40977 8.59766 0.908203C8.59766 0.406633 8.19102 0 7.68945 0H3.35072C1.50314 0 0 1.50314 0 3.35066V7.68945C0 8.19102 0.406633 8.59766 0.908203 8.59766Z" fill="#787777"/>
            <path d="M7.68945 29.1836H3.35072C2.5047 29.1836 1.81641 28.4953 1.81641 27.6493V23.3105C1.81641 22.809 1.40977 22.4023 0.908203 22.4023C0.406633 22.4023 0 22.809 0 23.3105V27.6493C0 29.4969 1.50314 31 3.35072 31H7.68945C8.19102 31 8.59766 30.5934 8.59766 30.0918C8.59766 29.5902 8.19102 29.1836 7.68945 29.1836Z" fill="#787777"/>
            <path d="M30.0918 22.4023C29.5902 22.4023 29.1836 22.809 29.1836 23.3105V27.6493C29.1836 28.4954 28.4953 29.1836 27.6493 29.1836H23.3105C22.809 29.1836 22.4023 29.5902 22.4023 30.0918C22.4023 30.5934 22.809 31 23.3105 31H27.6493C29.4969 31 31 29.4969 31 27.6493V23.3105C31 22.809 30.5934 22.4023 30.0918 22.4023Z" fill="#787777"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.0645 16.4688V26.1562C27.0645 26.6578 26.6578 27.0645 26.1562 27.0645H16.4688C15.9672 27.0645 15.5605 26.6578 15.5605 26.1562V16.4688C15.5605 15.9672 15.9672 15.5605 16.4688 15.5605H26.1562C26.6578 15.5605 27.0645 15.9672 27.0645 16.4688ZM17.377 25.248H25.248V17.377H17.377V25.248Z" fill="#787777"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M25.6719 13.502H18.8906C18.3891 13.502 17.9824 13.0953 17.9824 12.5938V5.8125C17.9824 5.31093 18.3891 4.9043 18.8906 4.9043H25.6719C26.1734 4.9043 26.5801 5.31093 26.5801 5.8125V12.5938C26.5801 13.0953 26.1734 13.502 25.6719 13.502ZM24.7637 6.7207H19.7988V11.6855H24.7637V6.7207Z" fill="#787777"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.84375 3.93555H13.5625C14.0641 3.93555 14.4707 4.34218 14.4707 4.84375V13.5625C14.4707 14.0641 14.0641 14.4707 13.5625 14.4707H4.84375C4.34218 14.4707 3.93555 14.0641 3.93555 13.5625V4.84375C3.93555 4.34218 4.34218 3.93555 4.84375 3.93555ZM5.75195 12.6543H12.6543V5.75195H5.75195V12.6543Z" fill="#787777"/>
            <path d="M12.5938 25.248H5.75195V17.4375C5.75195 16.9359 5.34532 16.5293 4.84375 16.5293C4.34218 16.5293 3.93555 16.9359 3.93555 17.4375V26.1562C3.93555 26.6578 4.34218 27.0645 4.84375 27.0645H12.5938C13.0953 27.0645 13.502 26.6578 13.502 26.1562C13.502 25.6547 13.0953 25.248 12.5938 25.248Z" fill="#787777"/>
            <path d="M9.62695 22.2812V17.4375C9.62695 16.9359 9.22032 16.5293 8.71875 16.5293C8.21718 16.5293 7.81055 16.9359 7.81055 17.4375V22.2812C7.81055 22.7828 8.21718 23.1895 8.71875 23.1895C9.22032 23.1895 9.62695 22.7828 9.62695 22.2812Z" fill="#787777"/>
            <path d="M12.5938 16.5293C12.0922 16.5293 11.6855 16.9359 11.6855 17.4375V22.2812C11.6855 22.7828 12.0922 23.1895 12.5938 23.1895C13.0953 23.1895 13.502 22.7828 13.502 22.2812V17.4375C13.502 16.9359 13.0953 16.5293 12.5938 16.5293Z" fill="#787777"/>
            <path d="M20.3438 22.2207H22.2812C22.7828 22.2207 23.1895 21.8141 23.1895 21.3125C23.1895 20.8109 22.7828 20.4043 22.2812 20.4043H20.3438C19.8422 20.4043 19.4355 20.8109 19.4355 21.3125C19.4355 21.8141 19.8422 22.2207 20.3438 22.2207Z" fill="#787777"/>
            <path d="M9.20312 10.5957C9.7047 10.5957 10.1113 10.1891 10.1113 9.6875V8.71875C10.1113 8.21718 9.7047 7.81055 9.20312 7.81055C8.70155 7.81055 8.29492 8.21718 8.29492 8.71875V9.6875C8.29492 10.1891 8.70155 10.5957 9.20312 10.5957Z" fill="#787777"/>
          </svg>

          <div className="Gauge__text">
            Compartilhe seu Índice de Saúde Financeira
          </div>
        </div>
        <Button width="150px">Baixar Índice</Button>
      </div> */}
    </div>
  )
}

// const getCoordsOnArc = (angle, offset = 10) => [
//   Math.cos(angle - (Math.PI / 2)) * offset,
//   Math.sin(angle - (Math.PI / 2)) * offset,
// ]

export default Gauge
