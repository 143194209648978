import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';

const renderCustomizedLabel = ({ x, y, name, value }) => {
  return (
    <text x={x} y={y} fill="#fff" textAnchor="start" dominantBaseline="central">
      {Number(value).toLocaleString('pt-BR', { maximumFractionDigits: 2 })}%
    </text>
  );
};

export default function PieChartDashboard({ data }) {
  const colors = ['#DAF439', '#05CCAE', '#B0B0B0'];

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <ResponsiveContainer width="98%" height="99%">
          <PieChart width={400} height={400}>
            <Legend layout="horizontal"
              verticalAlign="top"
              align="left"
              iconType="circle" />
            <Pie
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={50}
              fill="#8884d8"
              dataKey="percentage"
              label={renderCustomizedLabel}>
              {
                data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      }
    </>
  );
}