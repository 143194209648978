import React, { useEffect, useState } from 'react';
import '../../../../../../backoffice.scss';
import ModuleListing from '../../../components/ModuleListing/ModuleListing';
import api from "../../../../../../services/api";

const Globals = require("../../../../../../Globals.json");

export default function ExpenseOptions() {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData() {
    await api.get(Globals.app_api.expenseItems)
      .then((res) => {
        setOptions(parserToModuleListing(res.data.content.expense_items_list))
      })

  }

  function parserToModuleListing(response) {
    return response.map((item) => {
      const isActive = item.is_active ?  1 : 0
      return {
        id: item.id,
        name: item.name,
        description:item.group.name + (item.group.type == 'fixed' ? " (Fixa)" : " (Variável)"),
        type_module_status_id: isActive,
      }
    })
  }

  return (
    <ModuleListing
      title="Despesas"
      buttonLabel="Adicionar nova despesa"
      cellNameLabel ="Nome"
      cellDescriptionLabel= "Grupo"
      fullUrl={Globals.paths.backofficeExpenseMatrixOptionsEdit}
      urlReplacePattern=":item_id"
      customList={options}
      hideThumbnail
   />
  )

}
