import React, { Component } from 'react';
import './incentive.scss';

import { Helmet } from 'react-helmet';
import { dispatch, watch } from 'redux-easy/lib/redux-easy';
import {
  Grid,
  CircularProgress,
  Hidden,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination'
import api from '../../../services/api';
import { NavLink } from 'react-router-dom';


import AlertErrors from '../../components/alertErrors/alerts';
import Favorite from '../../components/sidebar/Favorite';
import LastCertificate from '../../components/sidebar/LastCertificate';
import LastTrophy from '../../components/sidebar/LastTrophy';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

import SmallProfileGauge from '../../components/profileGauge/SmallProfileGauge';
import ProfileModal from '../profile-modal/ProfileModal';
import TabPanel from '../../../shared/components/TabPanel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IncentiveModal from './IncentiveModal/IncentiveModal';

import IconGift from '../../../assets/icon/new-icons/presente.svg'



const Globals = require('../../../Globals.json');

class Incentive extends Component {


  state = {
    currentTab: 0,
    userLevel: 0,
    isLoading: false,
    firstLoadData: false,
    loading: false,
    openAlert: false,
    successMessage: '',
    tenants: '',
    incentiveModalOpened: false,
    clickedTenant: null,
    redeemedItens: [],
    lastPage: 1,
    page: 1,
    perPage: 12,
    total: 1,
    profileModalOpened: false,
  };
  closeWelcomeModal = () => {
    this.setState({ profileModalOpened: false });
  };
  async loadData() {
    if (this.props.user) {
      this.setState({ isLoading: true });

      const userProfile = this.props.user.profile;
      try {

        const activeTenants = (await api.get(Globals.api.getActiveTenants)).data;

        this.setState({
          user_avatar: userProfile.user_avatar,
          coins: this.props.user.profile.progress.total_moedas,
          activeTenants: activeTenants,
          isLoading: false,
          firstLoadData: false
        });
      } catch (error) {
        console.log('erro loaddata')
      }

    } else {
      dispatch('updateUserProfile');
    }
  }

  async paginate(page = 1) {
    try {
      const redeemedItens = (await api.get(`${Globals.api.redeemedItens}?per_page=${this.state.perPage}&page=${page}`)).data;

      console.log(redeemedItens)
      this.setState({
        redeemedItens: redeemedItens.data,
        lastPage: redeemedItens.lastPage,
        total: redeemedItens.total,
      });
    } catch (error) {
      console.log(error)
    }
  }

  handlePage = (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.paginate(page)
  }

  componentDidMount() {
    this.setState({ firstLoadData: true });
    this.loadData();
    this.paginate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.loadData();
    }
  }

  closeIncentiveModal = () => {
    this.setState({ incentiveModalOpened: false });
    this.paginate();
  };

  showSuccessAlert = (message) => {
    this.setState({ openAlert: true, successMessage: message });
    setTimeout(() => {
      return this.setState({ openAlert: false, successMessage: '' });
    }, 4500);
  };


  handleClickedTenant = (tenant) => {
    console.log(tenant)
    // return
    this.setState({
      incentiveModalOpened: true,
      clickedTenant: tenant
    });
  };

  handleChangeTab = (e, value) => {
    this.setState({ currentTab: value });
  };


  generateTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };



  render() {
    const quantity = this.props.user.profile.total_certificado;
    const quantityTrophies = this.props.user.profile.total_trofeis;


    return (
      <>
        <Grid container>
          <AlertErrors error={this.state.alertError} />
          <Helmet>
            <title>Incentivo | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className="incentive-class-container">
            <Grid container>
              <div className="header-container">
                <img src={IconGift} alt={'presente'} />
                <div>
                  <h2>Saldo de Pontos</h2>
                  <p>Utilize pontos para desbloquear vouchers, ferramentas, incentivos ou conteúdos.</p>
                </div>
                <p className='points'>{this.state.coins}</p>
              </div>
              <Grid className="tab-container" container>
                <Grid item container justify="center" alignItems="center">
                  <AppBar position="static" className="center" color="primary">
                    <Tabs
                      value={this.state.currentTab}
                      onChange={this.handleChangeTab}
                      aria-label="Configurações de usuário"
                      centered
                    >
                      <Tab label="Instituições" {...this.generateTabProps(0)} />
                      <Tab label="Meus resgates" {...this.generateTabProps(1)} />

                    </Tabs>
                  </AppBar>

                  <TabPanel value={this.state.currentTab} index={0}>
                    {this.state.firstLoadData ? (
                      <Grid container item xs={12} justify="center" alignItems="center">
                        <CircularProgress className="loading-container" size={40} />
                      </Grid>
                    ) : (
                      <>
                        <Grid container className="relationship-container">

                          <Grid container className="connected-container">
                            <Grid
                              container
                              item
                              xs={12}
                              className="default-title"
                            >
                              Instituições
                            </Grid>
                            <Grid container item xs={12} className="tab-description">
                              Cada instituição tem sua loja exclusiva. Selecione uma das instituições para ver os incentivos.
                            </Grid>
                            <Grid direction="column" container className="cards-container">
                              {this.state.activeTenants && this.state.activeTenants.length > 0 ? (
                                <>
                                  {this.state.activeTenants.map(tenant => (
                                    <>
                                      <Card key={tenant.id}
                                        className="card-container"
                                        onClick={() => this.handleClickedTenant(tenant)}
                                      >
                                        <CardContent>

                                          <img src={tenant.logo_url} alt={tenant.name} />
                                          <div className="bank-name">{tenant.name}</div>
                                          <div className={'status'}>Ver incentivo</div>

                                        </CardContent>
                                        <div className={'green-bar'} />
                                      </Card>
                                    </>
                                  ))}
                                  <div className="no-institution">
                                    <p>Verifique as demais instituições participantes e ative o relacionamento</p>
                                    <NavLink
                                      to={{
                                        pathname: Globals.paths.profile,
                                        state: { fromIncentive: true }
                                      }}
                                    >
                                      Ver instituições
                                    </NavLink>
                                  </div>
                                </>
                              ) : (
                                <div className="no-institution">
                                  <p>Verifique as instituições participantes e ative o relacionamento</p>
                                  <NavLink
                                    to={{
                                      pathname: Globals.paths.profile,
                                      state: { fromIncentive: true }
                                    }}
                                  >
                                    Ver instituições
                                  </NavLink>
                                </div>
                              )}

                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={this.state.currentTab} index={1}>
                    {this.state.firstLoadData ? (
                      <Grid container item xs={12} justify="center" alignItems="center">
                        <CircularProgress size={40} />
                      </Grid>
                    ) : (
                      <>
                        <Grid container justify="center" className="ordered-container">
                          <Grid
                            container
                            item
                            xs={12}
                            className="default-title"
                          >
                            Meus Resgates
                          </Grid>
                          <Grid container item xs={12} className="tab-description">
                            Veja o histórico de todos os resgastes realizados.
                          </Grid>
                          <Grid container className="cards-container">
                            {this.state.redeemedItens && this.state.redeemedItens.length > 0 ? (
                              this.state.redeemedItens.map(item => (
                                <>
                                  <Card key={item.id}
                                    className="card-container"
                                  >
                                    <CardContent>
                                      <div className="image-container">
                                        <img src={item.item_image_url} alt={item.item_name} />
                                      </div>

                                      <div className="description">{item.item_name}</div>
                                      <div className="point-cost">{`${item.item_points_needed_for_redemption} pontos`}</div>
                                      <div className={'redeem'}>
                                        <p>resgatado</p>
                                      </div>

                                    </CardContent>

                                  </Card>
                                </>
                              ))
                            ) : (
                              <div className="description">Ainda não foram realizados resgastes de incentivos.</div>
                            )}

                          </Grid>
                          <Grid container justify="center" >
                            <Pagination count={this.state.lastPage} color="primary" variant="outlined" shape="rounded" onChange={(e, page) => this.handlePage(page)} />
                          </Grid>

                        </Grid>
                      </>
                    )}
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid item container sm={4} md={3} direction="column">
              <div className="home-saude-financeira">
                <ToolbarItem
                  tooltip={this.state.nextIndexAssessmentDateText}
                  title="Índice de Saúde Financeira"
                  color="#05CCAE"
                >
                  {this.state.firstIndexMustBeFilled ? (
                    <div className="indiceContainer">
                      <Grid container>
                        <Grid
                          xs={12}
                          item
                          style={{ padding: '20px', textAlign: 'center', color: '#595959' }}
                        >
                          Você ainda não preencheu o seu Índice.
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <>
                      {
                        <div className="positionGauge">
                          <SmallProfileGauge id="home"></SmallProfileGauge>
                          <span
                            role="button"
                            onClick={() => this.setState({ profileModalOpened: true })}
                          >
                            mais informações
                          </span>
                        </div>
                      }
                    </>
                  )}
                </ToolbarItem>
              </div>

              <ToolbarItem
                title="Saldo de Pontos"
                color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de Pontos ao ser completada. Junte os pontos e os troque por benefícios e conteúdos exclusivos da Plataforma."
              >
                <PointsBalance coins={this.state.coins}></PointsBalance>
              </ToolbarItem>

              <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                {/* <div style={{ marginLeft: "10px", width: "100%", overflow: "auto", maxHeight: "200px" }}> */}
                <Favorite />
                {/* </div> */}
              </ToolbarItem>

              <ToolbarItem title="Troféus" color="#05CCAE">
                <LastTrophy quantity={quantityTrophies} />
              </ToolbarItem>

              <ToolbarItem title="Certificados" color="#05CCAE">
                <LastCertificate quantity={quantity} />
              </ToolbarItem>
            </Grid>
          </Hidden>

          {this.state.profileModalOpened && (
            <ProfileModal
              closeWelcomeModal={this.closeWelcomeModal}
              open={this.state.profileModalOpened}
            ></ProfileModal>
          )}
          {this.state.incentiveModalOpened && (
            <IncentiveModal
              loadDataParent={() => this.loadData()}
              clickedTenant={this.state.clickedTenant}
              closeIncentiveModal={this.closeIncentiveModal}
              open={this.state.incentiveModalOpened}
            ></IncentiveModal>
          )}
        </Grid>
      </>
    );
  }
}

export default watch(Incentive, { user: '' });
