import React, { useState, useEffect } from 'react';
import ComposedChartDashboard from '../../Components/Charts/ComposedChartDashboard';
import BarChartDashboard from '../../Components/Charts/BarChartDashboard';
import { ResponsiveContainer } from 'recharts';
import './EvolutionCharts.scss'

export default function EvolutionCharts({ data }) {
  const [chartType, setChartType] = useState('userAdesion');
  const [evolutivePeriod, setEvolutivePeriod] = useState('monthly')
  const [usersData, setUsersData] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [drillUp, setDrillUp] = useState(false);

  const loadData = async () => {
    let period;
    let groups;
    let groups2;

    if (evolutivePeriod === 'monthly') {
      period = 'yearAndMonth';
      setDrillUp(false);
    } else if (evolutivePeriod === 'weekly') {
      period = 'week';
      setDrillUp(true);
    } else if (evolutivePeriod === 'daily') {
      period = 'date';
      setDrillUp(true);
    }

    groups = data?.users?.reduce((groups, item) => {
      const group = (groups[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups[item[period]] = group;
      }

      return groups;
    }, {});

    const dataFormated = await formatUsersData(groups);

    dataFormated.map(item => {
      if (item.totalComplete > 0) {
        item['percentage'] = (item.totalJoined / item.totalComplete) * 100
      } else {
        item['percentage'] = 0
      }
    });

    setUsersData(dataFormated);

    groups2 = data?.points?.reduce((groups2, item) => {
      const group = (groups2[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups2[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups2[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups2[item[period]] = group;
      }

      return groups2;
    }, {});

    const dataFormated2 = await formatPointsData(groups2)
    setPointsData(dataFormated2);

  }

  const formatUsersData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              totalJoined: accumulator.totalJoined + currentValue.totalJoined,
              totalComplete: accumulator.totalComplete + currentValue.totalComplete,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            }
          })
          result.push(barColumn);
        }

        if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            totalJoined: value.totalJoined,
            totalComplete: value.totalComplete,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      });
    }

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        result.push(groups[item][0]);
      })
    }

    return result;
  };

  const formatPointsData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              totalReceived: accumulator.totalReceived + currentValue.totalReceived,
              totalRedeemed: accumulator.totalRedeemed + currentValue.totalRedeemed,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          result.push(barColumn);
        }
        if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            totalReceived: value.totalReceived,
            totalRedeemed: value.totalRedeemed,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      });
    }

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        result.push(groups[item][0]);
      });
    }

    return result;
  };

  const handleDrillUp = () => {
    if (evolutivePeriod === 'daily') {
      setEvolutivePeriod('weekly');
      setSelectedPeriod(selectedWeek);
    } else if (evolutivePeriod === 'weekly') {
      setEvolutivePeriod('monthly');
    }
  };

  const userAdesionClick = () => {
    setEvolutivePeriod('monthly');
    setChartType('userAdesion');
  };

  const rescPointsClick = () => {
    setEvolutivePeriod('monthly');
    setChartType('rescPoints');
  };

  const getNameAndPeriodOfChart = () => {
    let evatuatedBy = 'mês';
    let graphName = 'Usuários que aderiram e % usuários que aderiram';

    if (evolutivePeriod === 'monthly') {
      evatuatedBy = 'mês';
    } else if (evolutivePeriod === 'weekly') {
      evatuatedBy = 'semana';
    } else if (evolutivePeriod === 'daily') {
      evatuatedBy = 'dia';
    }

    if (chartType === 'userAdesion') {
      graphName = 'Usuários que aderiram e % usuários que aderiram';
    } else if (chartType === 'rescPoints') {
      graphName = 'Pontuação recebida e pontuação resgatada';
    }

    return graphName + ' por ' + evatuatedBy;
  };


  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();

  }, [evolutivePeriod, selectedPeriod, selectedMonth]);

  return (
    <div className='evolution-chart-container-ip'>
      <div className='header'>
        <div>
          Evolutivo Mensal / Semanal / Diário
        </div>
        <div className='btn-container'>
          {drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;-</button>}
          <button type='button' onClick={() => userAdesionClick()}>Usuários que aderiram</button>
          <button type='button' onClick={() => rescPointsClick()}> Pontos resgatados</button>
        </div>
      </div>
      <div>
        {getNameAndPeriodOfChart()}
      </div>
      <div className='chart-container'>
        {chartType === 'userAdesion' && (
          <ResponsiveContainer width={'99%'} height={252}>
            <ComposedChartDashboard data={usersData}
              barNameDatakey={[{ name: 'Usuários que aderiram', datakey: 'totalJoined', fill: "#05CCAE" }]}
              lineDatakey={'percentage'}
              lineName={'% Usuários que aderiram'}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              chartMargin={{ top: 0, right: 20, bottom: 20, left: 0 }}
              chartHeight={252}
              angle={-45}
            />
          </ResponsiveContainer>
        )}
        {chartType === 'rescPoints' && (
          <ResponsiveContainer width={'99%'} height={252}>
            <BarChartDashboard data={pointsData}
              nameAndDataKey={[{ name: 'Pontuação recebida', dataKey: 'totalReceived', fill: '#05CCAE' },
              { name: 'Pontuação Resgatada', dataKey: 'totalRedeemed', fill: '#008F7A' }]}
              toolTipName={'Período'}
              showBarLabels={true}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              chartMargin={{ top: 0, right: 20, bottom: 20, left: 0, }}
              chartHeight={252}
              angle={-45} />
          </ResponsiveContainer>
        )
        }
      </div>
    </div>
  )
}