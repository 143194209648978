import React, { useState, useEffect } from 'react';
import BarChartDashboard from '../../Components/Charts/BarChartDashboard';
import { ResponsiveContainer } from 'recharts';

export default function PersonaEvaluation({ data }) {
  const [personaSelected, setPersonaSelected] = useState();
  const [stateSelected, setStateSelected] = useState();
  const [adesionEvaluation, setAdesionEvaluation] = useState('persona');
  const [dataToDisplay, setDataToDisplay] = useState(data)
  const [drillUp, setDrillUp] = useState(false);

  const getData = () => {
    if (adesionEvaluation === 'persona') {
      setDataToDisplay(data);
      setDrillUp(false);
    } else if (adesionEvaluation === 'state') {
      setDrillUp(true);
      const persona = data.find(item => item.name === personaSelected);
      setDataToDisplay(persona.items);
    } else if (adesionEvaluation === 'income') {
      const state = dataToDisplay.find(item => item.name === stateSelected);
      setDataToDisplay(state.items);
    }
  };

  const handleDrillUp = () => {
    if (adesionEvaluation === 'income') {
      setAdesionEvaluation('state');
    } else if (adesionEvaluation === 'state') {
      setAdesionEvaluation('persona');
    }
  };

  const getChart = () => {
    if (adesionEvaluation === 'persona') {
      return <ResponsiveContainer width={'99%'} height={'100%'}>
        <BarChartDashboard data={dataToDisplay}
          nameAndDataKey={[{ dataKey: 'total' }]}
          showBarLabels={true}
          legend={false}
          chartWidth={0}
          toolTip={false}
          setAdesionEvaluation={setAdesionEvaluation}
          adesionEvaluation={adesionEvaluation}
          personaSelected={setPersonaSelected}
          stateSelected={setStateSelected}
          chartHeight={244}
          colors={['#FF6E5A', '#FF6E5A', '#DAF439', '#05CCAE', '#DAF439', '#DAF439', '#05CCAE']}
        />
      </ResponsiveContainer>
    }
    if (adesionEvaluation !== 'persona') {
      return <ResponsiveContainer width={'99%'} height={'100%'}>
        <BarChartDashboard data={dataToDisplay}
          nameAndDataKey={[{ name: '', dataKey: 'total', fill: '#DAF439' }]}
          showBarLabels={true}
          legend={false}
          toolTip={false}
          setAdesionEvaluation={setAdesionEvaluation}
          adesionEvaluation={adesionEvaluation}
          personaSelected={setPersonaSelected}
          stateSelected={setStateSelected}
          chartHeight={240}
        />
      </ResponsiveContainer>
    }
  }

  useEffect(() => {
    getData();

  }, [adesionEvaluation]);

  return (
    <>
      <div className="chart-title">Aderiram por Persona / Estado / Renda{drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;-</button>}</div>
      <div className="scroll-container">
        {getChart()}
      </div>
    </>
  )
}