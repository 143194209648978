import React, { Component } from 'react';
import { Button, CircularProgress, DialogActions, DialogContent, Grid, IconButton } from '@material-ui/core';
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import api from '../../../../services/api';
import Globals from '../../../../Globals.json';
import CloseIcon from '@material-ui/icons/Close';
import './indexForm.scss';

class IndexForm extends Component {

  state = {
    started: false,
    isLoading: true,
    indexData: {},
    currentBlock: 0,
    scrollDone: false
  }

  loadData = async () => {
    try {
      const data = await api.get(Globals.api.firstIndex);
      this.setState({ indexData: data.data });
    } catch (err) {
      //alert (err?.response?.data?.error?.msg ?? err?.response?.data?.msg ?? err?.response?.data ?? err?.message);
      console.log('error: ', err?.response?.data?.error?.msg ?? err?.response?.data ?? err?.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  checkVisible(elm, threshold, mode) {
    threshold = threshold || 0;
    mode = mode || 'visible';

    var rect = elm.getBoundingClientRect();
    var viewHeight = window.innerHeight || document.documentElement.clientHeight;
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }

  getProgressWidth() {
    if (!this.state.isLoading) {
      const r = 1 / this.state.indexData.blocks.length * 100;
      const w = (this.state.currentBlock + 1) * r + '%';

      return w === '99.99999999999999%' ? '101%' : w;
    }

    return 0;
  }

  handleScroll = (e) => {
    const container = e.target;
    let el = document.getElementsByClassName('question-item');

    for (let i = 0; i < el.length; i++) {
      let c = el[i];
      const visible = this.checkVisible(c, 340, 'visible');
      const hasClass = c.className.indexOf('active') !== -1;

      if (visible && !hasClass) {
        c.classList.add('active')
      }
      else if (!visible && hasClass) {
        c.classList.remove('active');
      }
    }

    if (this.isLastPage()) {
      if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
        this.setState({ scrollDone: true });
      }
    }
  }

  componentDidMount() {
    this.loadData();
    this.props.setCloseConfig(true);

    setTimeout(() => {
      const e = document.getElementById('indexForm');

      if (e) {
        e.addEventListener('scroll', this.handleScroll);
      }
    }, 10);
  }

  componentDidUpdate() {
    setTimeout(() => {
      const e = document.getElementById('indexForm');

      if (e) {
        e.removeEventListener('scroll', this.handleScroll);
        e.addEventListener('scroll', this.handleScroll);
      }
    }, 10);
  }

  componentWillUnmount() {
    setTimeout(() => {
      const e = document.getElementById('indexForm');

      if (e) {
        e.removeEventListener('scroll', this.handleScroll);
      }
    }, 10);
  }

  scrollTop = () => {
    setTimeout(() => {
      document.getElementById('indexForm').scrollTo(0, 0);
    }, 10);
  }

  nextBlock = () => {
    let currentBlock = this.state.currentBlock;

    if (currentBlock < this.state.indexData.blocks.length - 1) {
      currentBlock++;

      this.setState({ currentBlock });
      this.scrollTop();
    }
  }

  previousBlock = () => {
    let currentBlock = this.state.currentBlock;

    if (currentBlock > 0) {
      currentBlock--;

      this.setState({ currentBlock });
      this.scrollTop();
    }
  }

  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  }

  isLastPage = () => {
    return this.state.currentBlock === this.state.indexData.blocks.length - 1;
  }

  canGoNext = () => {
    let canGoNext = false;

    if (!this.isLastPage()) {
      canGoNext = true;
      let block = this.state.indexData.blocks[this.state.currentBlock];

      block.questions.forEach((question) => {
        if (question.type !== 'mapAmount') {
          if (!question.value || !question.value != 0) {
            canGoNext = false;
          }
        }
      });
    }

    return canGoNext;
  }

  indexValid = () => {
    let valid = false;
    const scrollDone = this.state.scrollDone;

    if (!this.state.isLoading) {
      valid = true;

      this.state.indexData.blocks.forEach((block) => {
        block.questions.forEach((question) => {
          if (question.type !== 'mapAmount') {
            if (!question.value || !question.value != 0) {
              valid = false;
            }
          }
        });
      });
    }

    return valid && scrollDone;
  }

  selectQuestionOption(question, option) {
    const indexData = this.state.indexData;

    if (question.type === 'mapAmount') {
      option.selected = !option.selected;
    }
    else {
      question.value = option.id;
    }

    this.setState({ indexData });
    this.scrollNext(question.qid);
  }

  scrollNext = (id) => {
    if (id !== 'bf3') {
      let el = document.getElementById('el' + id);

      if (el) {
        let parent = el.nextElementSibling?.nextElementSibling?.nextElementSibling;

        if (!parent) {
          parent = document.getElementById('bottom-btn-container');
        }

        if (parent) {
          parent.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
            inline: 'center',
          });
        }
      }
    }
  };

  saveIndex = async () => {
    this.setState({ isLoading: true });

    try {
      if (this.props.revalidate) {
        await api.post(Globals.api.revalidateIndex, { data: this.state.indexData });

        await api.get(Globals.api.userObjectives).
          then(response => {
            if (response?.data !== 'Erro ao buscar objetivos para o usuario') {
              dispatchSet("objectives.steps", response.data.data.steps)
            }
          });
      }

      if (!this.props.revalidate) {
        await api.post(Globals.api.firstIndex, { data: this.state.indexData });
      }

      const selfData = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", selfData.data);
      this.props.disableIndex();
      this.props.goBack(true);
    } catch (err) {
      alert (err?.response?.data?.error?.msg ?? err?.response?.data?.msg ?? err?.response?.data ?? err?.message);
      console.log('error: ', err?.response?.data?.error?.msg ?? err?.response?.data ?? err?.message);
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    let html = [];
    html.push(
      <React.Fragment key={'dialog-header'}>
        {!this.state.isLoading &&
          (<Grid container className="progress-floater">
            <div className="progress-container">
              <div className="progress" style={{ width: this.getProgressWidth() }}></div>
            </div>
            <Grid container spacing={0} className="progress-label-container">
              <Grid item xs={12} className="progress-label">
                Passo {this.state.currentBlock + 1} de {this.state.indexData.blocks.length}
              </Grid>
            </Grid>
            {!this.props.revalidate && 
              (<IconButton color="secondary" style={{ color: '#004178' }} aria-label="close" className="close-button" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>)
            }
          </Grid>)
        }

      </React.Fragment>
    );

    if (!this.state.isLoading) {
      let currentBlockContent = this.state.indexData.blocks[this.state.currentBlock];

      html.push(
        <DialogContent key={'index-form'} className="welcome-body index-form" id="indexForm" dividers >
          {
            (<>
              <Grid container id="top-btn-container" justifyContent="space-between" style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`g-pm-index-${this.state.currentBlock === 0 ? 'disabled' : (+this.state.currentBlock - 2)}`}
                  disabled={this.state.currentBlock === 0}
                  onClick={() => { this.previousBlock(); }}
                  style={{ borderRadius: "0px", color: "white" }}
                >
                  Anterior
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`g-pm-index-${this.canGoNext() ? this.state.currentBlock + 2 : 'disabled'}  ${this.isLastPage() ? 'hidden' : ''}`}
                  disabled={!this.canGoNext()}
                  onClick={() => { this.nextBlock(); }}
                  style={{ borderRadius: "0px", color: "white" }}
                >
                  Próximo
                </Button>
              </Grid>
              {currentBlockContent.question && currentBlockContent.question.trim() !== '' && (
                <>
                  <Grid item xs={12} className="questions-description">
                    {currentBlockContent.question}
                  </Grid>
                  <div className="question-spacer half"></div>
                </>
              )}
              <Grid container className="questions-container">
                {
                  currentBlockContent.questions.map((question, index) => (
                    !question.blocked && (
                      question.type === 'mapAmount' ?
                        <React.Fragment key={`question_${question.qid}`} >
                          <div key={`${question.qid}_container_start`} className="question-spacer"></div>
                          <Grid item xs={12} key={question.qid} className={`question-item ${index === 0 ? 'active' : ''} ${question.blocked ? 'blocked' : ''}`} id={`el${question.qid}`}>
                            <span className="question-title">“{question.question}”</span>
                            <Grid container className="question-options">
                              {
                                question.options.map((option) => (
                                  <Grid item xs={12} sm={2} md={2} key={`${question.qid}_${option.id}`} className={`question-option`} onClick={() => !question.blocked && this.selectQuestionOption(question, option)}>
                                    <div className={`question-option-container ${option.selected ? 'active' : ''}`}>
                                      <div className="question-option-indicator"></div>
                                      <div className="question-option-label">
                                        {option.text}
                                      </div>
                                    </div>
                                  </Grid>
                                ))
                              }
                            </Grid>
                          </Grid>
                          <div key={`${question.qid}_container_end`} className="question-spacer"></div>
                        </React.Fragment>
                        :
                        <React.Fragment key={`question_${question.qid}`} >
                          <div key={`${question.qid}_container_start`} className="question-spacer"></div>
                          <Grid item xs={12} key={question.qid} className={`question-item ${index === 0 ? 'active' : ''} ${question.blocked ? 'blocked' : ''}`} id={`el${question.qid}`}>
                            <span className={`question-item ${index === 0 ? 'question-title first-question-item-page' : 'question-title'} ${question.blocked ? 'blocked' : ''}`}>“{question.question}”</span>
                            <Grid container className="question-options">
                              {
                                question.options.map((option) => (
                                  <Grid item xs={12} sm={question.options.length > 5 ? 3 : 2} md={question.options.length > 5 ? 3 : 2} key={`${question.qid}_${option.id}`} className={`question-option`} onClick={() => !question.blocked && this.selectQuestionOption(question, option)}>
                                    <div className={`question-option-container ${option.id === question.value ? 'active' : ''}`}>
                                      <div className="question-option-indicator"></div>
                                      <div className="question-option-label">
                                        {option.text}
                                      </div>
                                    </div>
                                  </Grid>
                                ))
                              }
                            </Grid>
                          </Grid>
                          <div key={`${question.qid}_container_end`} className="question-spacer"></div>
                        </React.Fragment>)
                  ))
                }

              </Grid>
              <Grid container id="bottom-btn-container" justifyContent="space-between" style={{ marginBottom: '20px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={this.state.currentBlock === 0}
                  className={`g-pm-index-${this.state.currentBlock === 0 ? 'disabled' : (+this.state.currentBlock - 2)}`}
                  onClick={() => { this.previousBlock(); }}
                  style={{ borderRadius: "0px", color: "white" }}
                >
                  Anterior
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`g-pm-index-${this.canGoNext() ? this.state.currentBlock + 2 : 'disabled'} ${this.isLastPage() ? 'hidden' : ''}`}
                  disabled={!this.canGoNext()}
                  onClick={() => { this.nextBlock(); }}
                  style={{ borderRadius: "0px", color: "white" }}
                >
                  Próximo
                </Button>
              </Grid>

            </>)
          }
        </DialogContent >);
    } else {
      html.push(
        <DialogContent key={'index-form-loading'} className="welcome-body index-form" id="indexForm" dividers >
          <Grid item xs={12} className="loading">
            <div className="loading">
              <CircularProgress />
            </div>
          </Grid>
        </DialogContent >
      )
    }

    html.push(
      <DialogActions key={'index-form-footer'} className="dialog-footer">
        <Button
          variant="contained"
          color="primary"
          id="g-pm-know-my-profile"
          className={`g-pm-know-my-profile${this.indexValid() ? '' : '-disabled'}`}
          disabled={!this.indexValid()}
          onClick={() => { this.saveIndex(); }}
          style={{ borderRadius: "0px", color: "white" }}
        >
          {this.indexValid() ? `${this.props.revalidate ? 'RE' : ''}DESCOBRIR MEU PERFIL` : 'PREENCHA TODAS AS PERGUNTAS'}
        </Button>
      </DialogActions>
    );

    return html;
  }
}

export default watch(withRouter(IndexForm), { user: '', });