import React, { useEffect } from 'react';
import './styles/pointsBalance.scss';

import { Link } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { useSelector } from "react-redux";

import IconGift from '../../../assets/icon/new-icons/presente.svg';


const Globals = require("../../../Globals.json");
export default function PointsBalance (props) {


  const profile = useSelector(state => state.user.profile);
  const coins = profile.progress ? profile.progress.total_moedas : 0;


  return (
    <>
      <Grid item xs={8}>
        <Grid container className="points_bala_pL">
          <ul className="points_bala_ul_study">
            <li>
              <label className="points_bala_text_value_level">
                {coins}
              </label>
              <div className="points_bala_containerText">
                <span className="points_bala_msg">
                  {/* Utilize seu saldo para beneficios. */}
                  {props.level > 1 &&
                    <Link to={Globals.paths.progresso} style={{ color: "#005E50", textDecoration: "none" }}>Veja mais</Link>
                  }
                </span>
              </div>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Box>
            <img className="points_bala_icon_study" alt="study" src={IconGift}></img>
          </Box>
        </Grid>
      </Grid>
    </>
  );

}
