import React, { PureComponent, useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, Tooltip, Legend, Text } from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, timeConversion, isKformatting } = this.props;
    let valueToReturn;

    if (timeConversion) {
      if (value) {
        valueToReturn = new Date(value * 1000).toISOString().slice(11, 19);
      } else {
        valueToReturn = '00:00:00';
      }
    } else {
      valueToReturn = value
    }

    return (
      <svg>
        <foreignObject x={x} y={y - 20} width="65" height="20" >
          <div style={{ color: '#fff', fontSize: '8px' }}>
            {isKformatting && valueToReturn >= 1000 ? ((valueToReturn / 1000).toFixed(1) + ' K') : valueToReturn?.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}
          </div>
        </foreignObject>
      </svg>
    );
  }
};

export default function AreaChartDashboard({
  data,
  fillColor,
  name, dataKey,
  needTimeConversion = false,
  toolTipName = null,
  chartHeight = 200,
  evolutivePeriod = null,
  evolutiveMonthSelected = null,
  evolutiveWeekSelected = null,
  isKformatting = false,
  legend = true,
  chartMargin = {
    top: 20,
    right: 30,
    bottom: 20,
    left: 15,
  },
  angle = 360 }) {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  });

  const CustomTooltip = ({ active, payload, label, timeConversion }) => {
    let valueToReturn;

    if (timeConversion) {
      if (payload[0]?.value) {
        valueToReturn = new Date(payload[0]?.value * 1000).toISOString().slice(11, 19);
      } else {
        valueToReturn = '00:00:00';
      }
    } else {
      valueToReturn = payload[0]?.value
    }
    if (active) {
      return (
        <div className="custom-tooltip-dash">
          <div>{toolTipName}: {label}</div>
          <div>{payload[0]?.name ?? ''}: {valueToReturn?.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}</div>
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    let dyValue = angle !== 360 ? 16 : 6;
    let angleF = angle;
    let fontSizeF = '10';

    if (screenSize.width < 524) {
      fontSizeF = '8';
    }

    if (screenSize.width < 450) {
      angleF = -45;
      dyValue = 16;
    }

    if (payload && payload.value) {
      return <Text
        x={x}
        y={y}
        dy={dyValue}
        width={75}
        fontSize={fontSizeF}
        angle={angleF}
        fill={'#fff'}
        textAnchor="middle"
        verticalAnchor="start">
        {payload.value}</Text>
    }
    else {
      return null;
    }
  };

  const getWidth = () => {
    if (data?.length <= 6) {
      return 700;
    } else if (data?.length > 6 && data?.length <= 18) {
      return 1200;
    } else if (data?.length > 18 && data?.length <= 24) {
      return 1300;
    } else if (data?.length >= 25) {
      return 1400;
    }
  };

  const getEvolutiveInfo = (data) => {
    if (evolutivePeriod) {
      if (data.payload.periodToEvaluate === 'monthly') {
        evolutivePeriod('weekly');
        evolutiveMonthSelected(data.payload.name)
      } else if (data.payload.periodToEvaluate === 'weekly') {
        evolutivePeriod('daily');
        evolutiveWeekSelected(data.payload.name);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <AreaChart
          width={getWidth()}
          height={chartHeight}
          data={data}
          margin={chartMargin}
        >
          <Tooltip content={<CustomTooltip timeConversion={needTimeConversion} />} shared={true} />
          {legend && <Legend verticalAlign="top" height={36} />}
          <XAxis dataKey="name"
            scale="auto"
            tickLine={false}
            axisLine={false}
            tick={<CustomizedAxisTick />}
            interval={0} />
          <Area dataKey={dataKey}
            isAnimationActive={false}
            name={name}
            fill={fillColor}
            fillOpacity={0.3}
            stroke={fillColor}
            dot={{ stroke: fillColor, fill: fillColor }}
            padding={{ left: 5 }}
            label={<CustomizedLabel timeConversion={needTimeConversion} isKformatting={isKformatting} />} activeDot={{ onClick: (data) => { getEvolutiveInfo(data) } }} />
        </AreaChart>
      }
    </>
  )
}