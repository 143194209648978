import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import '../../../../../../backoffice.scss';
import Dialogs from '../../../../../../services/dialogs';


import api from "../../../../../../services/api";
import Button from '../../../../button/Button';

const Globals = require("../../../../../../Globals.json");

const EditAchievementItem = () => {

  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [id, setId] = useState('');

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');

  const [statusToggle, setStatusToggle] = useState(true);
  const [highlight, setHighlight] = useState(false);

  const [achievement, setAchievement] = useState(0);
  const [errorAchievement, setErrorAchievement] = useState(false);
  const [helperTextAchievement, setHelperTextAchievement] = useState('');
  const [comboBoxData, setComboBoxData] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const loadData = async (id) => {
    try {
      await api.get(Globals.app_api.achievementItem.replace(':item_id', id))
        .then((res) => {
          const data = res.data.content.achievement_item
          setData(data)
          setName(data.name)
          setAchievement(data.group_id)
          setHighlight(data.is_highlight)
          setStatusToggle(data.is_active)
          setId(data.id)
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false);
  }

  const loadGroup = async () => {
    try {
      await api.get(Globals.app_api.achievementMatrices)
        .then((res) => {
          setComboBoxData(res.data.content.achievement_groups_list)
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false);

  }


  useEffect(() => {
    setIsLoading(true);
    if (params.item_id !== 'new') {
      loadData(params.item_id)
      setEditMode(true)
    }
    loadGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setHelperTextName('');
    setErrorName(false);
  };

  const success = (mensagem) => {
    Dialogs.show(
      <div>SUCESSO</div>,
      mensagem,
      [
        {
          label: "OK",
          onClick: () => history.push(Globals.paths.backofficeAchievementMatrixOptions),
        },
      ]
    )
  }

  const saveData = async (postData) => {
    setIsLoading(true);
    await api.post(Globals.app_api.achievementItems, postData)
      .then((res) => {
        success('A conquista foi salva com sucesso',)
      })
      .catch((e) => {
        console.log(e)
      })
  };

  const editData = async (postData) => {

    await api.put(`${Globals.app_api.achievementItem.replace(':item_id', postData.id)}`, postData)
      .then((res) => {
        success('A conquista foi alterada com sucesso',)
      })
      .catch((e) => {
        console.log(e)
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = false;

    const postData = {
      id: id,
      is_active: statusToggle,
      group_id: achievement,
      is_highlight: highlight,
      name: name,
    }

    if (!postData.name || postData.name.trim().length === 0) {
      setHelperTextName('Informe uma descrição');
      setErrorName(true);
      error = true;
    }

    if (!postData.group_id) {
      setHelperTextAchievement('Selecione um grupo');
      setErrorAchievement(true);
      error = true;
    }

    if (!error) {
      editMode ? editData(postData) : saveData(postData)
    }

  }

  const handleToggleStatus = () => {
    console.log(statusToggle)
    statusToggle ? setStatusToggle(false) : setStatusToggle(true)
  }

  const handleQuestionMatrix = (event) => {
    setAchievement(event.target.value)
    setErrorAchievement(false);
    setHelperTextAchievement('');
  };

  const handleBack = (event) => {
    event.preventDefault();
    if (editMode) {
      const postData = {
        id: id,
        is_active: statusToggle,
        group_id: achievement,
        is_highlight: highlight,
        name: name,
      }

      const oldData = {
        id: data.id,
        is_active: data.is_active,
        group_id: data.group_id,
        is_highlight: data.is_highlight,
        name: data.name,
      }
      console.log(postData, oldData)
      if (JSON.stringify(postData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações nessa conquista, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeAchievementMatrixOptions),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeAchievementMatrixOptions)
      }

    } else {
      history.push(Globals.paths.backofficeAchievementMatrixOptions)
    }
  }

  return (
    <>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode ? data.name : 'Nova Conquista'}</span>
          </div>

          <form className="form-matrix" onSubmit={handleSubmit}>

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Nome</FormLabel>


              <TextField
                value={name}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Nome"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => handleNameChange(e)}
              />
              <FormHelperText>{helperTextName}</FormHelperText>
            </FormControl>

            <FormControl
              size='small'
              error={errorAchievement}
              component="fieldset"
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Grupo</FormLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={achievement}
                variant="outlined"
                fullWidth
                displayEmpty
                onChange={handleQuestionMatrix}
              >
                <MenuItem value={0}>
                  <em>Selecione</em>
                </MenuItem>
                {comboBoxData.length > 0 &&
                  // eslint-disable-next-line array-callback-return
                  comboBoxData.map((item) => {
                    if (item.is_active) {
                      return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                    }
                  })
                }

              </Select>
              <FormHelperText>{helperTextAchievement}</FormHelperText>
            </FormControl>


            <Grid item xs={6}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  aria-label="quiz"
                  name="quiz"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={statusToggle}
                        onChange={handleToggleStatus}
                      />
                    }
                    label={'Ativa'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">Destaque</FormLabel>
                <Checkbox
                  checked={highlight}
                  onChange={() => setHighlight(!highlight)}
                  name="checked"
                  color="primary"
                />
              </FormControl>


            </Grid>

            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default EditAchievementItem;
