import React, { useEffect, useState } from 'react';
import './userPerfil.scss';

import {
  Grid, Table, TableBody, TableCell, TableContainer, CircularProgress,
  TableRow, TableHead, TableSortLabel, TextField, FormControl, Select,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../../button/Button';

import api from "../../../../services/api";
const Globals = require("../../../../Globals.json");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: '#202020', fontWeight: '700', fontSize: '16px' }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    minHeight: 400,
    position: 'relative',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [perPage, setPerPage] = useState(1)
  const [lastPage, setLastPage] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [searchDescription, setSearchDescription] = useState('')
  const [headCells] = useState([
    { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
  ])
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPerPage(newPage);
    getRolesPerPage(newPage, rowsPerPage)
  };

  const handleChangeRowsPerPage = (e) => {
    const pageIndex = pagination;
    setRowsPerPage(e.target.value);
    console.log('aveee', e.target.value)
    getRolesPerPage(pageIndex, e.target.value)
  };

  const filterBy = () => {
    setPagination(1)
    setPerPage(1)
    getRolesPerPage(1, rowsPerPage)
  }

  const getRolesPerPage = (page, rows) => {
    setIsLoading(true)
    api.get(Globals.api.getRoles, {
      params: {
        page: page,
        per_page: rows,
        filter_by: {
          "description": searchDescription
        }
      }
    }).then(response => {
      const data = response?.data?.data;
      const total = response?.data?.total;
      setRows(data)
      setTotal(total)
      setLastPage(response?.data?.lastPage)
      setPagination(page)
      setRowsPerPage(rows)
      setIsLoading(false)
      console.log('aaaa', response?.data)

    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    getRolesPerPage(pagination, rowsPerPage)
  }, [])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid className="content" style={{ marginBottom: '70px' }}>
      <Grid className="box">
        <div className="table_user_contentTexts">
          <span className="table_user_textList">Lista de Perfis</span>
        </div>


        <div className="user_positionSearch2">
          <TextField
            label="Buscar pela descrição"
            variant="outlined"
            className="user_textField"
            size="small"
            value={searchDescription}
            onChange={(e) => setSearchDescription(e.target.value)}
          />

          <Button type='darkBlue' height="40px" onClick={filterBy}>Buscar</Button>
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              headCells={headCells}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {isLoading ?

              <div className="user_table_loading">
                <CircularProgress />
              </div>
              :
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        <TableCell align="center" className="user_table_cell" >
                          <Link style={{ color: '#202020', textDecoration: 'none', fontSize: 15 }}
                            to={Globals.paths.backofficeRolesEdit.replace(':roleId', row.id)}>{row.name}</Link>
                        </TableCell>
                        <TableCell align="center" className="user_table_cell" className="user_table_cell_link" >
                          <span style={{ fontSize: 15 }}>{row.description}</span>
                        </TableCell>

                        <TableCell align="center" className="user_table_cell" >
                          <span style={{ fontSize: 15 }}>{row.created_at.substring(0, 10).split('-').reverse().join('/')}</span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <div className="user_footer_table">
          <span>Linhas por página:
          <FormControl>
              <Select
                value={rowsPerPage}
                onChange={(e) => handleChangeRowsPerPage(e)}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </span>

          <Pagination count={lastPage} page={perPage} onChange={handleChangePage} />

          <div className="user_exibitionTable">
            <span>Exibindo 1 - {rowsPerPage} de {total} </span>
          </div>
        </div>
      </ Grid>
    </ Grid>
  );
}
