import React from 'react';
import './profile.scss';

import Button from '../../../../components/button/Button';
import NavigationAppBar from '../../AppBar/NavigationAppBar';

import { withRouter } from 'react-router-dom';

import api from '../../../../../services/api';

const Globals = require("../../../../../Globals.json");
class Profile extends React.Component {

  state = {
    name: '',
    description: '',
    permissions: [],
    permissionsList: [],
    loading: false,
    msg: '',
    erro: false
  }

  handleDeleteUser() {
    console.log('Deletando user...')
  }

  clearState = () => {
    this.setState({
      name: '',
      description: '',
      permissions: [],
      permissionsList: [],
      loading: false
    })
  }

  getSelection(id) {
    this.setState({ menuActive: id })
  }

  handleSave = async () => {
    if(this.state.name !== '' && this.state.permissions !== ''){
    const postData =
    {
      "name": this.state.name,
      "description": this.state.name,
      "permissions": this.state.permissions
    }

    await api.post(Globals.api.sendRoles, postData)
    .then(response => {
      console.log('response', response.data)
      this.clearState();
      this.handleSearch();
      this.props.history.replace("/admin")
    }).catch(err => {
      console.log(err)
    })}
    this.setState({ msg: 'É necessário prencher o perfil e suas permissões.'})
  }

  handleCancel() {
    console.log('Cancelando alterações user...')
    this.clearState();
  }

  handleSearch = async () => {
    this.setState({ loading: true })
    console.log('buscando...')
    await api.get(Globals.api.getPermissions, {
      params: {
        page: 1,
        per_page: 25
      }
    })
      .then(response => {
        this.setState({ permissionsList: response?.data?.data, loading: false })
        console.log('response search', response.data.data)
      }).catch(err => {
        this.setState({loading: false, erro: true})
        console.log(err)
      })
  }

  handleChangeRadio(id,e) {
    if(e.target.checked === true){
      let dados = [...this.state.permissions, parseInt(id)]
      this.setState({ permissions: dados })
    }
    else{
      let dados2 = this.state.permissions;
      dados2.splice(dados2.indexOf(id), 1);
      this.setState({ permissions: dados2 });
    }
  }

  componentDidMount() {
    this.handleSearch()
  }
  render() {

    return (
      <>
        <NavigationAppBar text="Editar User" />
        <div className="containerEditUser">
          {this.state.loading === true ? 'Carregando' : this.state.erro === true ? 'Usuário não possui permisão de acesso.' :

            <div className="ContentContainerProfile">

              <div className="profileHeader">
                <div>Cadastro de Perfil</div>
              </div>

              <div className="profileBox1">
                Nome do Perfil
              <input
                  variant="outlined"
                  value={this.state.name}
                  margin="dense"
                  placeholder="Digite o nome do perfil"
                  autoFocus
                  type="text"
                  autoComplete="off"
                  onChange={e => this.setState({ name: e.target.value })}
                  className="profileInput"
                />
              </div>

              <div className="profileBox1">
                Permissão do Perfil


                <div className="pContaierCheckBox">

                  {this.state.permissionsList.map((item, index) => (
                    <div key={item.id}>
                      <input
                        type="checkbox"
                        id={item.id}
                        name={item.name}
                        onChange={e => this.handleChangeRadio(item.id, e)}
                      />
                      {item.description}
                    </div>
                  ))}

                </div>

              </div>

              <div className="editContainerBtnsP">
                <Button
                  icon="none"
                  width="150px"
                  type="ligthDark"
                  height="36px"
                  textAlign="center"
                  right="20px"
                  to={Globals.paths.backofficeHome}>
                  cancelar
                </Button>

                <Button
                  width="150px"
                  type="darkBlue"
                  textAlign="center"
                  onClick={() => this.handleSave()}
                  >
                  cadastrar
                </Button>
              </div>
              <span className="msgProfile">{this.state.msg}</span>
            </div>}
        </div>
      </>
    )
  }
}
export default withRouter(Profile);
