import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import { getDashboardEndDate, getDashboardStartDate } from '../../../../../services/util';
import BarChartVerticalDashboard from '../Components/Charts/BarChartVerticalDashboard';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PersonaEvaluation from './PersonaEvaluation/PersonaEvaluation';
import EvolutionCharts from './EvolutionCharts/EvolutionCharts';
import IncentiveChart from './IncentiveChart/IncentiveChart';
import IndexCard from '../Components/IndexCard/IndexCard';
import { ClearAll, Refresh } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { ResponsiveContainer } from 'recharts';
import { ptBR } from 'date-fns/locale';
import './IncentivePlan.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const IncentivePlan = ({ data, isLoading, filters, setFilters, alert }) => {
  const classes = useStyles();
  const [bank, setBank] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [gender, setGender] = useState('');
  const [education, setEducation] = useState('');
  const [income, setIncome] = useState('');

  const loadDropdown = (options) => {
    let optionsList = [];
    optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>);

    if (options) {
      options.map((item, index) => (
        optionsList.push(<option key={index} value={item}>{item}</option>)
      ))
    }

    return optionsList;
  };

  const handleFilterChange = (e, element) => {
    if (element === 'startDate' || element === 'endDate') {
      setFilters(prevState => ({ ...prevState, [element]: e }));
    } else {
      setFilters(prevState => ({ ...prevState, [element]: e.target.value }));
    }
  };

  const setUpFilters = () => {
    setBank(filters.bank);
    setAgeRange(filters.ageRange);
    setGender(filters.gender);
    setEducation(filters.education);
    setIncome(filters.income)
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate(),
      bank: '',
      ageRange: '',
      gender: '',
      education: '',
      income: ''
    }));
  };

  const randomColor = () => {
    return "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
  }

  const showSkeleton = () => {
    return <div className="dash-skeleton-container">
      <div className="grid-row-2">
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="grid-row-4">
        <div className="grid-col-1 min-width-455 min-height-318">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-455 min-height-318">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-700 min-height-318">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="grid-row-4">
        <div className="grid-col-1 min-width-350 min-height-299">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-350 min-height-299">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-350 min-height-299">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-731 min-height-299">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
    </div>;
  };

  const showContent = () => {
    return <div className="dash-content-container">
      <div className="dash-content-container">
        <div className="grid-row">
          {data?.indexData?.map((item, index) =>
            <div className="grid-col-1 min-width-190 min-height-130">
              <IndexCard key={'indexCard_' + index} index={item} />
            </div>
          )}
        </div>
        <div className="grid-row">
          <div className="grid-col-1 min-width-455 min-height-318">
            <div className='dash-cards-container'>
              <div>Usuários com cadastro completo por faixa de pontos</div>
              <div className='points-vertical-bar-chart-ip'>
                <ResponsiveContainer width={'99%'} height={'100%'}>
                  <BarChartVerticalDashboard data={data?.completeRegistrationsByPointRange}
                    nameAndDataKey={[{ name: 'Faixa de pontos', datakey: 'value', fillColor: '#D8D8D8' }]}
                    showLegend={false}
                    yWidth={120}
                    barSize={30}
                    ylabel={'name'}
                    chartWidth={'100%'}
                    chartHeigth={256}
                    chartMargin={{ left: 0, right: 35 }} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-col-1 min-width-455 min-height-318">
            <div className='dash-cards-container'>
              <div>Usuários que aderiram por faixa de pontos</div>
              <div className='points-vertical-bar-chart-ip'>
                <ResponsiveContainer width={'99%'} height={'100%'}>
                  <BarChartVerticalDashboard data={data?.usersWhoJoinedByPointRange}
                    nameAndDataKey={[{ name: 'Faixa de pontos', datakey: 'value', fillColor: '#D8D8D8' }]}
                    showLegend={false}
                    yWidth={120}
                    barSize={30}
                    ylabel={'name'}
                    chartWidth={'100%'}
                    chartHeigth={256}
                    chartMargin={{ left: 0, right: 35 }} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-col-2 min-width-700 min-height-318">
            <EvolutionCharts data={{ users: data?.usersWhoJoinedEvolutionData, points: data?.pointsEvolutionData }} />
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-1 min-width-350 min-height-299 max-height-299">
            <div className='dash-cards-container'>
              <div>Usuários que aderiram por banco</div>
              <div className='vertical-bar-chart-ip'>
                <ResponsiveContainer width={'99%'} height={'100%'}>
                  <BarChartVerticalDashboard data={data?.usersWhoJoinedByBank}
                    nameAndDataKey={[{ name: 'Adesão por banco', datakey: 'value', fillColor: '#DAF439' }]}
                    showLegend={false}
                    yWidth={100}
                    ylabel={'name'}
                    chartWidth={'100%'}
                    chartMargin={{ left: 0, right: 35 }} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-col-1 min-width-350 min-height-299 max-height-299">
            <div className='dash-cards-container'>
              <div>Usuários com resgates por banco</div>
              <div className='vertical-bar-chart-ip'>
                <ResponsiveContainer width={'99%'} height={'100%'}>
                  <BarChartVerticalDashboard data={data?.usersWithPointsRedeemedByBank}
                    nameAndDataKey={[{ name: 'Resgate por banco', datakey: 'value', fillColor: '#DAF439' }]}
                    showLegend={false}
                    yWidth={100}
                    ylabel={'name'}
                    chartWidth={'100%'}
                    chartMargin={{ left: 0, right: 35 }} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-col-1 min-width-350 min-height-299 max-height-299">
            <div className='dash-cards-container'>
              <div>Pontos por itens resgatados</div>
              <div className='rescued-points-container'>
                {data?.pointsRedeemedByType?.map((item, index) => {
                  if (index === 0)
                    return <IncentiveChart key={index} data={item} color={'#1e90ff'} className="min-height-55" />
                  else if (index === 1)
                    return <IncentiveChart key={index} data={item} color={'#0000FF'} className="min-height-55" />
                  else
                    return <IncentiveChart key={index} data={item} color={randomColor()} className="min-height-55" />
                })}
              </div>
            </div>
          </div>
          <div className="grid-col-2 min-width-731 min-height-299 max-height-299">
            <div className='dash-cards-container bar-chart-ip'>
              <PersonaEvaluation data={data?.usersWhoJoinedByPersonaAndStateAndIncome} />
            </div>
          </div>
        </div>
      </div>
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>}
    </div>;
  };

  useEffect(() => {
    if (!isLoading) {
      setUpFilters();
    }

  }, [isLoading]);

  return (
    <div className="incentive-plan">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container">
          <span className="filter-title">Banco:</span>
          <select value={bank} onChange={(e) => handleFilterChange(e, 'bank')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.banks)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Idade:</span>
          <select value={ageRange} onChange={(e) => handleFilterChange(e, 'ageRange')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.ageRanges)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Gênero:</span>
          <select value={gender} onChange={(e) => handleFilterChange(e, 'gender')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.genders)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Escol.:</span>
          <select value={education} onChange={(e) => handleFilterChange(e, 'education')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.educations)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Renda:</span>
          <select value={income} onChange={(e) => handleFilterChange(e, 'income')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.incomes)
            }
          </select>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default IncentivePlan;