import React, { PureComponent, useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  Cell,
  LabelList,
  Text
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, width, value } = this.props;
    return (
      <text
        x={x + width / 2}
        y={y}
        dy={-4}
        fontSize='8'
        fill={'#fff'}
        textAnchor="middle">{value.toLocaleString('pt-BR', { maximumFractionDigits: 2 })}</text>


    );
  }
};

export default function BarChartDashboard({
  data,
  nameAndDataKey,
  showBarLabels = false,
  colors = null,
  evolutivePeriod = null,
  evolutiveMonthSelected = null,
  evolutiveWeekSelected = null,
  adesionEvaluation = null,
  setAdesionEvaluation = null,
  personaSelected = null,
  stateSelected = null,
  width = null,
  chartWidth = null,
  chartHeight = 200,
  legend = true,
  toolTip = true,
  toolTipName = null,
  chartMargin = {
    top: 20,
    right: 0,
    bottom: 20,
    left: 0,
  },
  angle = 360 }) {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip-dash">
          <div>{toolTipName}: {label}</div>
          <div>{payload[0]?.name ?? ''}: {payload[0]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}</div>
          {nameAndDataKey.length > 1 && <div>{payload[1]?.name ?? ''}: {payload[1]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}</div>}
        </div>
      );
    }
    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    let dyValue = angle !== 360 ? 16 : 6
    let angleF = angle
    let fontSizeF = '10';

    if (screenSize.width < 524) {
      fontSizeF = '8'
    }

    if (screenSize.width < 450) {
      angleF = -45;
      dyValue = 16
    }

    if (payload && payload.value) {
      return <Text
        x={x}
        y={y}
        dy={dyValue}
        width={75}
        fontSize={fontSizeF}
        angle={angleF}
        fill={'#fff'}
        textAnchor="middle"
        verticalAnchor="start">
        {payload.value}</Text>
    }
    else {
      return null
    }

  };


  const getWidth = () => {
    if (data?.length <= 6) {
      return 700
    } else if (data?.length > 6 && data?.length <= 18) {
      return 1200
    } else if (data?.length > 18 && data?.length <= 24) {
      return 1300
    } else if (data?.length >= 25) {
      return 1400
    }
  };

  const handleClick = (data) => {
    if (evolutivePeriod) {
      getEvolutiveInfo(data)
    } else {
      getAdesionInfo(data)
    }
  };

  const getAdesionInfo = (data) => {
    if (adesionEvaluation === 'persona') {
      setAdesionEvaluation('state')
      personaSelected(data.name)
    } else if (adesionEvaluation === 'state') {
      setAdesionEvaluation('income')
      stateSelected(data.name)
    }
  };

  const getEvolutiveInfo = (data) => {
    if (evolutivePeriod) {
      if (data.payload.periodToEvaluate === 'monthly') {
        evolutivePeriod('weekly');
        evolutiveMonthSelected(data.name)
      } else if (data.payload.periodToEvaluate === 'weekly') {
        evolutivePeriod('daily');
        evolutiveWeekSelected(data.name);
      }
    }
  };

  const formatLabelList = (props) => {
    return props + '%';
  }

  const getBars = () => {
    let toReturn = [];

    nameAndDataKey.map((item, index) => {
      if (item.fill) {
        toReturn.push(
          <Bar key={index} isAnimationActive={false} name={item.name} dataKey={item.dataKey} fill={item.fill} onClick={(data) => { handleClick(data) }}>
            {showBarLabels &&
              <LabelList dataKey={item.datakey}
                position="top"
                fill="#fff"
                offset={15}
                content={<CustomizedLabel />}
                formatter={formatLabelList} />}
          </Bar>
        )
      } else {
        toReturn.push(
          <Bar key={index} isAnimationActive={false} name={item.name} dataKey={item.dataKey} onClick={(data) => { handleClick(data) }}>
            {showBarLabels &&
              <LabelList dataKey={item.datakey}
                position="top"
                fill="#fff"
                offset={15}
                content={<CustomizedLabel />}
                formatter={formatLabelList} />}
            {
              data?.map((entry, i) => (
                <Cell key={`cell-${i}`} fill={colors[i % 20]} />
              ))
            }
          </Bar>
        )
      }
    })

    return toReturn;
  };

  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <BarChart
          width={chartWidth === 0 ? width : getWidth()}
          height={chartHeight}
          data={data}
          maxBarSize={70}
          margin={chartMargin}
        >
          {toolTip && <Tooltip content={<CustomTooltip />} shared={true} />}
          {legend && <Legend verticalAlign="top"
            height={36}
            iconType={'circle'}
            iconSize={10} />}

          <XAxis dataKey="name"
            scale="auto"
            tickLine={false}
            axisLine={false}
            tick={<CustomizedAxisTick />}
            interval={0} />
          {getBars()}
        </BarChart>
      }
    </>
  );
}