import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import { formatNumber, getDashboardEndDate, getDashboardStartDate, getFilterEndDate, getFilterStartDate } from '../../../../../services/util';
import BarChartVerticalDashboard from '../Components/Charts/BarChartVerticalDashboard';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import BarChartDashboard from '../Components/Charts/BarChartDashboard';
import { ClearAll, Refresh } from '@material-ui/icons';
import RegionalMap from './RegionalMap/RegionalMap';
import { Alert, Skeleton } from '@material-ui/lab';
import { ResponsiveContainer } from 'recharts';
import { Xwrapper } from 'react-xarrows';
import TreeMap from './TreeMap/TreeMap';
import { ptBR } from 'date-fns/locale';
import './ProfileAnalysis.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const ProfileAnalysis = ({ data, isLoading, filters, setFilters, dataTree, isTreeLoading, treeFilters, setTreeFilters, alert }) => {
  const classes = useStyles();
  const [gender, setGender] = useState('');
  const [region, setRegion] = useState('');
  const [state, setState] = useState('');
  const [persona, setPersona] = useState('');
  const [macroSegmentation, setMacroSegmentation] = useState('');

  const [normalizedPersonas, setNormalizedPersonas] = useState(null);
  const [totalPersona, setTotalPersona] = useState(0);
  const [totalPersonaPercentage, setTotalPersonaPercentage] = useState(0);
  const [totalPersonaIndex, setTotalPersonaIndex] = useState(0);

  const [formatedData, setFormatedData] = useState([]);

  const loadDropdown = (options) => {
    let optionsList = [];
    optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>);

    if (options) {
      options.map((item, index) => (
        optionsList.push(<option key={index} value={item}>{item}</option>)
      ))
    }

    return optionsList;
  };

  const handleFilterChange = (e, element) => {
    if (element === 'startDate' || element === 'endDate') {
      setFilters(prevState => ({ ...prevState, [element]: e }));
      setTreeFilters(prevState => ({ ...prevState, [element]: element === 'startDate' ? getFilterStartDate(e) : getFilterEndDate(e) }));
    } else {
      setFilters(prevState => ({ ...prevState, [element]: e.target.value }));
      setTreeFilters(prevState => ({ ...prevState, [element]: e.target.value }));
    }

    clearTreeFilters(false);
  };

  const setUpFilters = () => {
    setGender(filters.gender);
    setRegion(filters.region);
    setState(filters.state);
    setPersona(filters.persona);
    setMacroSegmentation(filters.macroSegmentation);
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate(),
      gender: '',
      region: '',
      state: '',
      persona: '',
      macroSegmentation: ''
    }));

    clearTreeFilters(true);
  };

  const clearTreeFilters = (clearAll) => {
    if (clearAll) {
      setTreeFilters(prevState => ({
        ...prevState,
        startDate: getDashboardStartDate(),
        endDate: getDashboardEndDate(),
        gender: [],
        region: [],
        state: [],
        persona: [],
        macroSegmentation: [],
        filterTwo: '',
        filterThree: '',
        ageRange: '',
        income: '',
        genderTreeFilter: '',
        regionTreeFilter: '',
        personaTreeFilter: '',
        selectedTreePersonaIndex: '',
        selectedTreeFilterTwoIndex: ''
      }));
    } else {
      setTreeFilters(prevState => ({
        ...prevState,
        filterTwo: '',
        filterThree: '',
        ageRange: '',
        income: '',
        genderTreeFilter: '',
        regionTreeFilter: '',
        personaTreeFilter: '',
        selectedTreePersonaIndex: '',
        selectedTreeFilterTwoIndex: ''
      }));
    }
  };

  const setUpData = () => {
    let regionalMap = [];

    if (data.personaData) {
      const clone = data.personaData.slice();
      const totals = clone.shift();
      setNormalizedPersonas(clone);

      setTotalPersona(totals?.total);
      setTotalPersonaPercentage(totals?.percentage);
      setTotalPersonaIndex(totals?.index_average);
    } else {
      setNormalizedPersonas(null);
    }

    if (data.regionalData) {
      const groups = data.regionalData.reduce((groups, item) => {
        const group = (groups[item['state']] || []);
        group.push(item);
        groups[item['state']] = group;

        return groups;
      }, {});

      regionalMap = [
        { state: 'São Paulo', coordinates: [-69.6396, -20.5558], data: groups['São Paulo'] },
        { state: 'Rio Grande do Sul', coordinates: [-73.2090, -28.0368], data: groups['Rio Grande do Sul'] },
        { state: 'Paraná', coordinates: [-73.0215, -23.251], data: groups['Paraná'] },
        { state: 'Santa Catarina', coordinates: [-71.3653, -25.9286], data: groups['Santa Catarina'] },
        { state: 'Rio de Janeiro', coordinates: [-63.1729, -21.9068], data: groups['Rio de Janeiro'] },
        { state: 'Espírito Santo', coordinates: [-60.3089, -18.1834], data: groups['Espírito Santo'] },
        { state: 'Minas Gerais', coordinates: [-65.7908, -17.9302], data: groups['Minas Gerais'] },
        { state: 'Goiás', coordinates: [-72.3653, -15.7050], data: groups['Goiás'] },
        { state: 'Distrito Federal', coordinates: [-68.8919, -14.7975], data: groups['Distrito Federal'] },
        { state: 'Mato Grosso do Sul', coordinates: [-76.7852, -18.7722], data: groups['Mato Grosso do Sul'] },
        { state: 'Mato Grosso', coordinates: [-77.9211, -12.6819], data: groups['Mato Grosso'] },
        { state: 'Acre', coordinates: [-90.8120, -9.0238], data: groups['Acre'] },
        { state: 'Rondônia', coordinates: [-83.5806, -10.5057], data: groups['Rondônia'] },
        { state: 'Amazonas', coordinates: [-87.8561, -3.4168], data: groups['Amazonas'] },
        { state: 'Roraima', coordinates: [-82.7585, 5.1316], data: groups['Roraima'] },
        { state: 'Amapá', coordinates: [-73.0215, 2.7441], data: groups['Amapá'] },
        { state: 'Pará', coordinates: [-75.7028, -4.2071], data: groups['Pará'] },
        { state: 'Tocantins', coordinates: [-69.7191, -9.4099], data: groups['Tocantins'] },
        { state: 'Maranhão', coordinates: [-66.6007, -4.0804], data: groups['Maranhão'] },
        { state: 'Piauí', coordinates: [-63.6007, -6.0804], data: groups['Piauí'] },
        { state: 'Ceará', coordinates: [-60.3206, -2.4984], data: groups['Ceará'] },
        { state: 'Rio Grande do Norte', coordinates: [-55.9541, -3.4026], data: groups['Rio Grande do Norte'] },
        { state: 'Paraíba', coordinates: [-55.7820, -5.2400], data: groups['Paraíba'] },
        { state: 'Pernambuco', coordinates: [-59.7820, -7.2400], data: groups['Pernambuco'] },
        { state: 'Alagoas', coordinates: [-55.7820, -7.8713], data: groups['Alagoas'] },
        { state: 'Sergipe', coordinates: [-58.4681, -9.6739], data: groups['Sergipe'] },
        { state: 'Bahia', coordinates: [-63.2809, -10.4099], data: groups['Bahia'] },
      ]

      setFormatedData(regionalMap);
    };
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));

    setTreeFilters(prevState => ({ ...prevState }));
  };

  const showSkeleton = () => {
    return <div className="dash-skeleton-container">
      <div className="grid-row-1">
        <div className="grid-col-3 min-width-600 min-height-340">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-520 min-height-340">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-520 min-height-340">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="grid-row-2">
        <div className="grid-col-3 min-width-600 min-height-410">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-520 min-height-410">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-4 min-width-600 min-height-410">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
    </div>;
  };

  const showContent = () => {
    return <>
      <div className="dash-content-container">
        <div className="grid-row">
          <div className="grid-col-3 min-width-600 min-height-340">
            <div className='dash-cards-container'>
              <div>Persona</div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChartDashboard data={normalizedPersonas}
                  nameAndDataKey={[{ dataKey: 'total' }]}
                  showBarLabels={true}
                  legend={false}
                  chartWidth={0}
                  chartHeight={300}
                  toolTip={false}
                  colors={[
                    '#05CCAE',
                    '#05CCAE',
                    '#DAF439',
                    '#DAF439',
                    '#DAF439',
                    '#FF6E5A',
                    '#FF6E5A'
                  ]} />
              </ResponsiveContainer>
            </div>
          </div>
          <div className="grid-col-2 min-width-520 min-height-340">
            <div className='dash-cards-container'>
              <div>Análise detalhada por Persona</div>
              <table className='table-dash'>
                <thead>
                  <tr>
                    <th >Persona</th>
                    <th >Quantidade</th>
                    <th >%</th>
                    <th >Indíce - Média</th>
                  </tr>
                </thead>
                <tbody>
                  {normalizedPersonas?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td> {item.name}</td>
                        <td>{formatNumber(item.total)}</td>
                        <td style={{ backgroundColor: item.percentageBackgroundColor }}>{item.percentage}</td>
                        <td>{formatNumber(item.index_average)}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td>{formatNumber(totalPersona)}</td>
                    <td>{formatNumber(totalPersonaPercentage)}</td>
                    <td>{formatNumber(totalPersonaIndex)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="grid-col-2 min-width-520 min-height-340">
            <div className='dash-cards-container'>
              <div>Faixa de Renda</div>
              <div className='vertical-bar-chart-pa'>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChartVerticalDashboard data={data?.incomeData}
                    ylabel={'name'}
                    yWidth={200}
                    showLegend={false}
                    chartWidth={'100%'}
                    chartMargin={{ left: 0, right: 35 }}
                    nameAndDataKey={[{ name: 'Faixa de Renda', datakey: 'total', fillColor: '#C8C8C8' }]}
                  />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-3 min-width-600 min-height-410">
            <div className='dash-cards-container'>
              <RegionalMap data={formatedData} />
            </div>
          </div>

          <div className="grid-col-2 min-width-520 min-height-410">
            <div className='dash-cards-container'>
              <div>Média do Índice por Gênero</div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChartDashboard data={data?.mediaIndexGenderData}
                  nameAndDataKey={[{ dataKey: 'index_average' }]}
                  showBarLabels={true}
                  legend={false}
                  chartWidth={0}
                  chartHeight={400}
                  toolTip={false}
                  colors={[
                    '#ed589d',
                    '#6c63f0',
                    '#edc600'
                  ]} />
              </ResponsiveContainer>
            </div>
          </div>

          <div className="grid-col-4 min-width-600 min-height-410">
            <div className='dash-cards-container'>
              <div>Análise de Persona</div>
              <Xwrapper>
                <TreeMap data={dataTree} isLoading={isTreeLoading} filters={treeFilters} setFilters={setTreeFilters} />
              </Xwrapper>
            </div>
          </div>
        </div>
      </div>
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>}
    </>;
  };

  useEffect(() => {
    if (!isLoading) {
      setUpData();
      setUpFilters();
    }
  }, [isLoading]);

  return (
    <div className="profile-analysis">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container">
          <span className="filter-title">Gênero:</span>
          <select value={gender} onChange={(e) => handleFilterChange(e, 'gender')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.genders)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Região:</span>
          <select value={region} onChange={(e) => handleFilterChange(e, 'region')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.regions)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Estado:</span>
          <select value={state} onChange={(e) => handleFilterChange(e, 'state')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.states)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Persona:</span>
          <select value={persona} onChange={(e) => handleFilterChange(e, 'persona')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.personas)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Macroseg.:</span>
          <select value={macroSegmentation} onChange={(e) => handleFilterChange(e, 'macroSegmentation')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.macroSegmentations)
            }
          </select>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default ProfileAnalysis;