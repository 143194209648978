import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import { getDashboardEndDate, getDashboardStartDate } from '../../../../../services/util';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import BarChartDashboard from '../Components/Charts/BarChartDashboard';
import IndexCard from '../Components/IndexCard/IndexCard';
import { ClearAll, Refresh } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { ResponsiveContainer } from 'recharts';
import { ptBR } from 'date-fns/locale';
import './Consolidated.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const Consolidated = ({ data, isLoading, filters, setFilters, alert }) => {
  const classes = useStyles();

  const handleFilterChange = (e, element) => {
    setFilters(prevState => ({ ...prevState, [element]: e }));
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate()
    }));
  };

  const showSkeleton = () => {
    return <div className="dash-skeleton-container">
      <div className="grid-row-2">
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-1 min-width-190 min-height-130">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="grid-row-4">
        <div className="grid-col-1 min-width-410 min-height-290">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-410 min-height-290">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-2 min-width-410 min-height-290">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="grid-row-4">
        <div className="grid-col-3 min-width-500 min-height-325">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
        <div className="grid-col-4 min-width-700 min-height-325">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
    </div>;
  };

  const showContent = () => {
    return <>
      <div className="dash-content-container">
        <div className="grid-row">
          {data?.indexData?.map((item, index) =>
            <div className="grid-col-1 min-width-190 min-height-130">
              <IndexCard key={'indexCard_' + index} index={item} />
            </div>
          )}
        </div>
        <div className="grid-row">
          <div className="grid-col-1 min-width-410 min-height-290">
            <div className='dash-cards-container'>
              <div>Faixa de saúde financeira</div>
              {!data || data.length < 1 ? <div className='no-data-info'>Sem dados disponíveis</div> :
                <table className='financial-health-table'>
                  <thead>
                    <tr>
                      <th>Faixa de saúde financeira</th>
                      <th>Cadastros</th>
                      <th>Percentual</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.financialHealthListData?.map((item, index) =>
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{(item.total)}</td>
                        <td>{item.percentage}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
            </div>
          </div>
          <div className="grid-col-2 min-width-410 min-height-290">
            <div className='dash-cards-container'>
              <div>Faixa de saúde financeira</div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChartDashboard data={data?.financialHealthChartData}
                  nameAndDataKey={[{ name: 'cadastros', dataKey: 'total', fill: '#05CCAE' }]}
                  showBarLabels={true}
                  toolTip={false}
                  legend={false}
                  chartWidth={0}
                  chartHeight={250} />
              </ResponsiveContainer>
            </div>
          </div>
          <div className="grid-col-2 min-width-410 min-height-290">
            <div className='dash-cards-container'>
              <div>Estatísticas do curso público</div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChartDashboard data={data?.publicCourseTrack}
                  nameAndDataKey={[{ name: 'cadastros', dataKey: 'total', fill: '#05CCAE' }]}
                  showBarLabels={true}
                  toolTip={false}
                  legend={false}
                  chartWidth={0}
                  chartHeight={250} 
                  angle={-15}/>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-3 min-width-500 min-height-325">
            <div className='dash-cards-container'>
              <div>Faixa de saúde financeira - Progresso dos usuários ativos</div>
              {!data || data.length < 1 ? <div className='no-data-info'>Sem dados disponíveis</div> :
                <table className='financial-health-table'>
                  <thead>
                    <tr>
                      <th>Faixa de Saúde Financeira</th>
                      <th>0</th>
                      <th>Até 25</th>
                      <th>De 25 a 50</th>
                      <th>De 51 a 75</th>
                      <th>De 76 a 100</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.financialHealthTrackByCompleteRegistrationsData?.map((item, index) =>
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{Number(item.range01).toLocaleString('pt-BR')}</td>
                        <td>{Number(item.range02).toLocaleString('pt-BR')}</td>
                        <td>{Number(item.range03).toLocaleString('pt-BR')}</td>
                        <td>{Number(item.range04).toLocaleString('pt-BR')}</td>
                        <td>{Number(item.range05).toLocaleString('pt-BR')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
            </div>
          </div>
          <div className="grid-col-4 min-width-700 min-height-325">
            <div className='dash-cards-container'>
              <div>Cursos por região</div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChartDashboard data={data?.coursesByRegionData}
                  nameAndDataKey={[{ name: 'Cursos Por região', dataKey: 'totalCourses', fill: '#05CCAE' },
                  { name: 'Cursos por região / concluídos', dataKey: 'totalFinishedCourses', fill: '#d8d8d8' }]}
                  showBarLabels={true}
                  toolTip={false}
                  chartWidth={0}
                  chartHeight={283} />
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>}
    </>;
  };

  return (
    <div className="consolidated">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default Consolidated;