import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import { getDashboardEndDate, getDashboardStartDate } from '../../../../../services/util';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IndexCard from '../Components/IndexCard/IndexCard';
import EvolutiveChart from './Evolutive/EvolutiveChart';
import { ClearAll, Refresh } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import './CoursesAnalysis.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const CoursesAnalysis = ({ data, isLoading, filters, setFilters, alert }) => {
  const classes = useStyles();
  const [region, setRegion] = useState('');
  const [state, setState] = useState('');

  const loadDropdown = (options) => {
    let optionsList = [];
    optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>);

    if (options) {
      options.map((item, index) => (
        optionsList.push(<option key={index} value={item}>{item}</option>)
      ))
    }

    return optionsList;
  };

  const handleFilterChange = (e, element) => {
    if (element === 'startDate' || element === 'endDate') {
      setFilters(prevState => ({ ...prevState, [element]: e }));
    } else {
      setFilters(prevState => ({ ...prevState, [element]: e.target.value }));
    }
  };

  const setUpFilters = () => {
    setRegion(filters.region);
    setState(filters.state);
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate(),
      region: '',
      state: ''
    }));
  };

  const showSkeleton = () => {
    return <div className="dash-skeleton-container">
      <div className="grid-row-1">
        <div className="grid-col-1 index-grid-row">
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
          </div>
        </div>
        <div className="grid-col-3 min-width-700">
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-700 min-height-249">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
          </div>
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-700 min-height-249">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
          </div>
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-700 min-height-249">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  const showContent = () => {
    return <>
      <div className="dash-content-container">
        <div className="grid-row">
          <div className="grid-col-1 index-grid-row">
            <div className="grid-row">
              {data?.indexData?.map((item, index) =>
                <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
                  <IndexCard key={'indexCard_' + index} index={item} />
                </div>
              )}
            </div>
          </div>
          <div className="grid-col-3 min-width-700">
            <div className="grid-row">
              <div className="grid-col-1 min-width-700 height-249">
                <div className='dash-cards-container'>
                  <div>Análise detalhada por curso - Google Analytics</div>
                  <div className="tableFixHead" style={{ height: '100%' }}>
                    <table className='table-dash'>
                      <thead>
                        <tr>
                          <th>Nome do curso</th>
                          <th>Visitantes</th>
                          <th>%</th>
                          <th>Sessões</th>
                          <th>Visualizações de páginas</th>
                          <th>Taxa de rejeição</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.gaDetailedData?.map((item, index) =>
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.visitors}</td>
                            <td style={{ backgroundColor: item.percentageBackgroundColor }}>{item.percentage}</td>
                            <td>{item.sessions}</td>
                            <td>{item.pageViews}</td>
                            <td>
                              <div className='bounce-rate'>
                                {item.bounceRate}%
                                <FiberManualRecordIcon style={{ fontSize: '15px', color: item.bounceRateColor }} />
                              </div></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-col-1 min-width-700 height-249">
                <div className='dash-cards-container'>
                  <EvolutiveChart data={data?.gaEvolutionData} />
                </div>
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-col-1 min-width-700 height-249">
                <div className='dash-cards-container'>
                  <div>Análise detalhada por curso - Desempenho</div>
                  <div className='tableFixHead' style={{ height: '100%' }}>
                    <table className='table-dash'>
                      <thead>
                        <tr>
                          <th>Nome do curso</th>
                          <th>Quantidade</th>
                          <th>%</th>
                          <th>Média nota prova</th>
                          <th>Média progresso</th>
                          <th>Média Indice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.detailedData?.map((item, index) =>
                          <tr key={index}>
                            <td className='course-name'>{item.name}</td>
                            <td>{item.total || '--'}</td>
                            <td style={{ backgroundColor: item.percentageBackgroundColor }}>{item.percentage || '--'}</td>
                            <td>{item.average_score || '--'}</td>
                            <td>{item.progress || '--'}</td>
                            <td>{item.average_index || '--'}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>
      }
    </>;
  };

  useEffect(() => {
    if (!isLoading) {
      setUpFilters();
    }
  }, [isLoading]);

  return (
    <div className="courses-analysis">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container">
          <span className="filter-title">Região:</span>
          <select value={region} onChange={(e) => handleFilterChange(e, 'region')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.regions)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Estado:</span>
          <select value={state} onChange={(e) => handleFilterChange(e, 'state')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.states)
            }
          </select>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default CoursesAnalysis;