import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Hidden,
} from '@material-ui/core';
import { CheckCircleOutline, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { dispatch, dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import Globals from '../../../Globals.json';
import QuestionarioIndice from '../../../QuestionarioIndice.json';
import api from '../../../services/api';
import { enumBehaviorQuestTypes, enumQuestStatus, enumTypeModules } from '../../../services/enums';
import AlertErrors from '../../components/alertErrors/alerts';
import Pagination from '../../components/pagination/Pagination';
import Button from '../button/Button';
import EditorJsRenderer from '../EditorJSRenderer';
import InitialModalQuiz from '../quiz/InitialModalQuiz';
import MissionCustom from './MissionCustom';
import './styles/missionList.scss';

class MissionList extends React.Component {
  state = {
    missions: [],
    filteredMissions: [],
    missionsToClaim: [],
    reward: [],
    amountPerson: [],
    index: 0,
    toClaimIndex: 0,
    openMission: false,
    missionPerson: false,
    missionPersonData: {},
    menuActive: 2,
    open: false,
    loading: false,
    alertError: '',
    selectedId: undefined,
  };

  loadData = () => {
    try {
      this.getAllMissions();
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  async getAllMissions() {
    this.setState({ loading: true });

    try {
      await api
        .get(Globals.api.mission)
        .then((response) => {
          const toClaim = response.data.filter((f) => {
            return Number.parseInt(f.data.status) === enumQuestStatus.completed && !f.data.redeemed;
          });

          console.log(this.props?.match?.params);

          let type = this.props?.match?.params?.type;

          if (type) {
            if (typeof enumQuestStatus[type] != 'undefined') {
              type = enumQuestStatus[type];
              type = type === 3 ? 4 : type;
            } else {
              type = enumQuestStatus.ongoing;
            }
          } else {
            type = enumQuestStatus.ongoing;
          }

          this.setState({ missionsToClaim: toClaim, missions: response.data }, () => {
            this.filterMissions(type);
            this.goToSelectedMission();
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } catch (error) {
      console.log(error);
    }
  }

  goToSelectedMission = () => {
    if (this.state.selectedId) {
      setTimeout(() => {
        let el = document.getElementsByClassName('mission-' + this.state.selectedId)[0];
        if (el) {
          let parent = el.parentElement.parentNode.parentNode.parentNode.parentNode;
          parent.classList.add('pulse');
          el.click();
          parent.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
          });
        }
      }, 500);
    }
  };

  filterMissions(type) {
    this.setState({ menuActive: type });

    let filtered = [];

    switch (type) {
      case 1: {
        filtered = this.state.missions.filter((f) => {
          return Number.parseInt(f.data.status) === enumQuestStatus.available;
        });
        break;
      }
      case 2:
        filtered = this.state.missions.filter((f) => {
          return Number.parseInt(f.data.status) === enumQuestStatus.ongoing;
        });
        break;
      case 3:
        break;
      case 4:
        filtered = this.state.missions.filter((f) => {
          return Number.parseInt(f.data.status) === enumQuestStatus.completed && f.data.redeemed;
        });
        break;
      case 5:
        filtered = this.state.missions.sort(function (a, b) {
          if (a.data.status > b.data.status) {
            return 1;
          }
          if (a.data.status < b.data.status) {
            return -1;
          }
          return 0;
        });
        break;
      default:
        return;
    }

    this.setState({ filteredMissions: filtered });
  }

  acceptMission = async (item) => {
    this.setState({ openMission: false });
    try {
      const resp = await api.post(Globals.api.missionAccept, { questUserId: item.id });
      const response = resp.data;
      if (!response.error) {
        if (response.object) {
          this.updateLocalMissions(item, response);
        }
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  claimRewards = async (item) => {
    try {
      const postData = {
        questUserId: item.id,
      };

      const resp = await api.post(Globals.api.missionClaim, postData);
      const response = resp.data;

      if (!response.error) {
        setTimeout(() => {
          dispatch('updateUserProfile');
        }, 1000);

        this.missionsChanged();
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  missionsChanged = () => {
    this.loadData();
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  handleChangeToClaimIndex = (toClaimIndex) => {
    this.setState({
      toClaimIndex,
    });
  };

  backToHome = () => {
    this.props.history.push('/home');

    setTimeout(() => {
      return dispatchSet('tour.show', true);
    }, 1000);
  };

  doAction = (item) => {
    if (item.DataModulesDetails.data?.hasAction) {
      if (
        Number.parseInt(item.DataModulesDetails.data.behaviourType) ===
        enumBehaviorQuestTypes.tutorial
      ) {
        this.backToHome();
      } else {
        this.props.history.push(Globals.paths[item.DataModulesDetails.data.linkToAction]);
      }
    }
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openMission = (data) => {
    this.setState({ openMission: true, missionPersonData: data });
  };

  componentDidMount() {
    this.setState({ userId: this.props.user.profile.id });
    let id = this.props?.match?.params?.id;
    if (id) {
      console.log(id);
      this.setState({ selectedId: id });
    }
    this.loadData();
  }

  updateLocalMissions(item, response) {
    const currentType = this.state.menuActive;
    const missions = this.state.missions;

    const index = missions.indexOf(item);

    missions[index] = response.object;

    this.setState({ missions }, () => {
      this.filterMissions(currentType);
    });
  }

  getNoMissionText() {
    switch (this.state.menuActive) {
      case enumQuestStatus.available:
        return 'Você já aceitou todas as missões disponíveis, continue usando a plataforma para liberar mais missões';
      case enumQuestStatus.ongoing:
        return 'Você não possuí missões em andamento.';
      case enumQuestStatus.completed:
        return 'Você ainda não completou nenhuma missão.';
      case enumQuestStatus.completedAndRedeemed:
        return 'Você ainda não completou nenhuma missão.';
      default:
        return '';
    }
  }

  render() {
    const { index, loading, toClaimIndex } = this.state;

    return (
      <>
        {loading ? (
          <>
            <AlertErrors error={this.state.alertError} />
            <p className="loader">Carregando suas missões...</p>
          </>
        ) : (
          <div style={{ marginBottom: '60px' }}>
            <AlertErrors error={this.state.alertError} />

            {!!this.state.missionsToClaim.length && (
              <>
                <div className="feed_item_title">RESGATAR</div>
                <Hidden xsDown>
                  <Container className="mb-30">
                    {this.state.missionsToClaim.map((item, index) => (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        key={`${index}_${item.id}`}
                      >
                        {item.DataModulesDetails !== null && (
                          <div
                            className={`mission_list_cardQuest ${
                              item.type_module_id === enumTypeModules.customQuest && 'green'
                            }`}
                          >
                            <div className="mission_list_positionFavorite">
                              <label>
                                {item.type_module_id === enumTypeModules.customQuest
                                  ? 'Missão Personalizada'
                                  : 'Missão'}
                              </label>
                            </div>
                            <div className="mission_list_header">
                              {item.DataModulesDetails.data.rewards.map((reward) => (
                                <>
                                  {Number.parseInt(reward.dataAssetDetailId) === 5 && (
                                    <div className={'mission_list_points'}>
                                      {`${reward.amount} Pontos`}
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>

                            <div className="mission_list_contentText">
                              <p className="mission_list_title">{item.DataModulesDetails.name}</p>

                              <div className="mission_list_contentProgress">
                                <progress
                                  value={item.data.progressPercent}
                                  max={100}
                                  className="mission_list_progress"
                                />
                              </div>

                              <div className="mission_list_positionLink">
                                <Button
                                  className="mission_list_link g-m-claim"
                                  minWidth="100px"
                                  fontSize="12px"
                                  onClick={() => this.claimRewards(item)}
                                >
                                  Resgatar
                                </Button>
                              </div>
                              <div className="miss_container_accordion mission-description">
                                <Accordion className="miss_list_accordion">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    className={`mission-${item.data_modules_detail_id}`}
                                  >
                                    <div className="miss_title_accordion">Descrição da missão</div>
                                  </AccordionSummary>
                                  <AccordionDetails className="miss_details_accordion">
                                    {item.DataModulesDetails.data.content ? (
                                      <Grid container item xs={12} className="rendered-content">
                                        <EditorJsRenderer
                                          data={item.DataModulesDetails.data.content}
                                        />
                                      </Grid>
                                    ) : (
                                      item.DataModulesDetails.description
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    ))}
                  </Container>
                </Hidden>
                <Hidden smUp>
                  <Container className="mb-30">
                    <SwipeableViews
                      index={toClaimIndex}
                      onChangeIndex={this.handleChangeToClaimIndex}
                      slideStyle={{ overflow: 'visible' }}
                    >
                      {this.state.missionsToClaim.map((item, index) => (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="flex-start"
                          key={`${index}_${item.id}`}
                        >
                          {item.DataModulesDetails !== null && (
                            <div
                              className={`mission_list_cardQuest ${
                                item.type_module_id === enumTypeModules.customQuest && 'green'
                              }`}
                            >
                              <div className="mission_list_positionFavorite">
                                <label>
                                  {item.type_module_id === enumTypeModules.customQuest
                                    ? 'Missão Personalizada'
                                    : 'Missão'}
                                </label>
                              </div>
                              {/* { enumTypeModules } */}
                              <div className="mission_list_header">
                                {item.DataModulesDetails.data.rewards.map((reward) => (
                                  <>
                                    {Number.parseInt(reward.dataAssetDetailId) === 5 && (
                                      <div className={'mission_list_points'}>
                                        {`${reward.amount} Pontos`}
                                      </div>
                                    )}
                                  </>
                                ))}
                              </div>

                              <div className="mission_list_contentText">
                                <p className="mission_list_title">{item.DataModulesDetails.name}</p>

                                <div className="mission_list_contentProgress">
                                  <progress
                                    value={item.data.progressPercent}
                                    max={100}
                                    className="mission_list_progress"
                                  />
                                </div>

                                <div className="mission_list_positionLink">
                                  <Button
                                    className="mission_list_link g-m-claim"
                                    minWidth="100px"
                                    fontSize="12px"
                                    onClick={() => this.claimRewards(item)}
                                  >
                                    Resgatar
                                  </Button>
                                </div>
                                <div className="miss_container_accordion mission-description">
                                  <Accordion className="miss_list_accordion">
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                      aria-controls="panel1a-content"
                                      className={`mission-${item.data_modules_detail_id}`}
                                    >
                                      <div className="miss_title_accordion">
                                        Descrição da missão
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails className="miss_details_accordion">
                                      {item.DataModulesDetails.description}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          )}
                        </Grid>
                      ))}
                    </SwipeableViews>
                    <Pagination
                      dots={this.state.missionsToClaim.length}
                      index={toClaimIndex}
                      onChangeIndex={this.handleChangeToClaimIndex}
                    />
                  </Container>
                </Hidden>
              </>
            )}

            <div className="feed_item_title">MISSÕES</div>

            <Hidden xsDown>
              <div className="mission_list_menu">
                <button
                  type="button"
                  onClick={() => this.filterMissions(2)}
                  className={`mission_list_btnActive ${this.state.menuActive === 2 && 'active'}`}
                >
                  EM ANDAMENTO
                </button>
                <button
                  type="button"
                  onClick={() => this.filterMissions(4)}
                  className={`mission_list_btnActive ${this.state.menuActive === 4 && 'active'}`}
                >
                  CONCLUÍDAS
                </button>
                <button
                  type="button"
                  onClick={() => this.filterMissions(5)}
                  className={`mission_list_btnActive ${this.state.menuActive === 5 && 'active'}`}
                >
                  TODAS
                </button>
              </div>
              <Container>
                {!this.state.filteredMissions.length ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '33px' }}>
                    <span className="mission_list_msg">{this.getNoMissionText()}</span>
                  </div>
                ) : (
                  this.state.filteredMissions.map((item, index) => (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      key={`${index}-${this.state.menuActive}-${item.id}`}
                    >
                      {item.DataModulesDetails !== null && (
                        <div
                          className={`mission_list_cardQuest ${
                            item.type_module_id === enumTypeModules.customQuest && 'green'
                          }`}
                        >
                          <div className="mission_list_positionFavorite">
                            <label>
                              {item.type_module_id === enumTypeModules.customQuest
                                ? 'Missão Personalizada'
                                : 'Missão'}
                            </label>
                          </div>
                          <div className="mission_list_header">
                            {item.DataModulesDetails.data.rewards.map((reward) => (
                              <>
                                {reward.dataAssetDetailId === 5 && (
                                  <div className={'mission_list_points'}>
                                    {`${reward.amount} Pontos`}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>

                          <div className="mission_list_contentText">
                            <p className="mission_list_title">{item.DataModulesDetails.name}</p>

                            <div className="mission_list_contentProgress">
                              <progress
                                value={item.data.progressPercent}
                                max={100}
                                className="mission_list_progress"
                              />
                            </div>

                            <div className="mission_list_positionLink">
                              {item.data.status === enumQuestStatus.available && (
                                <Button
                                  className="mission_list_link g-m-accept"
                                  minWidth="100px"
                                  fontSize="12px"
                                  type="green"
                                  onClick={() => this.acceptMission(item)}
                                >
                                  Aceitar
                                </Button>
                              )}
                              {item.data.status === enumQuestStatus.ongoing && (
                                <>
                                  {!!item.DataModulesDetails.data.hasAction && (
                                    <Button
                                      className="mission_list_link g-m-go-to-objective"
                                      minWidth="100px"
                                      fontSize="12px"
                                      type={'primary'}
                                      onClick={() => this.doAction(item)}
                                    >
                                      Ir para o objetivo
                                    </Button>
                                  )}
                                  {!item.DataModulesDetails.data.hasAction && (
                                    <Button
                                      className="mission_list_link"
                                      minWidth="100px"
                                      fontSize="12px"
                                      type={'disabled'}
                                      disabled="disabled"
                                    >
                                      Aguardando conclusão
                                    </Button>
                                  )}
                                </>
                              )}
                              {item.data.status === enumQuestStatus.completed &&
                                item.data.redeemed && (
                                  <div className="mission_list_conclued">
                                    <CheckCircleOutline
                                      className="mission_list_link icon"
                                      style={{ color: 'var(--green)' }}
                                    />
                                    <small style={{ color: 'var(--green)' }}>Concluído</small>
                                  </div>
                                )}
                              {item.data.status === enumQuestStatus.completed &&
                                !item.data.redeemed && (
                                  <Button
                                    className="mission_list_link g-m-claim"
                                    minWidth="100px"
                                    fontSize="12px"
                                    onClick={() => this.claimRewards(item)}
                                  >
                                    Resgatar
                                  </Button>
                                )}
                            </div>
                            <div className="miss_container_accordion mission-description">
                              <Accordion className={`miss_list_accordion`}>
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1a-content"
                                  className={`mission-${item.data_modules_detail_id}`}
                                >
                                  <div className="miss_title_accordion">Descrição da missão</div>
                                </AccordionSummary>
                                <AccordionDetails className="miss_details_accordion">
                                  {item.DataModulesDetails.data.content ? (
                                    <Grid container item xs={12} className="rendered-content">
                                      <EditorJsRenderer
                                        data={item.DataModulesDetails.data.content}
                                      />
                                    </Grid>
                                  ) : (
                                    item.DataModulesDetails.description
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  ))
                )}
              </Container>
            </Hidden>

            <Hidden smUp>
              <div className="mission_list_menu">
                <button
                  type="button"
                  onClick={() => this.filterMissions(1)}
                  className={`mission_list_btnActive ${this.state.menuActive === 1 && 'active'}`}
                >
                  NÃO ACEITAS
                </button>
                <button
                  type="button"
                  onClick={() => this.filterMissions(2)}
                  className={`mission_list_btnActive ${this.state.menuActive === 2 && 'active'}`}
                >
                  ACEITAS
                </button>
                <button
                  type="button"
                  onClick={() => this.filterMissions(4)}
                  className={`mission_list_btnActive ${this.state.menuActive === 4 && 'active'}`}
                >
                  CONCLUÍDAS
                </button>
                <button
                  type="button"
                  onClick={() => this.filterMissions(5)}
                  className={`mission_list_btnActive ${this.state.menuActive === 5 && 'active'}`}
                >
                  TODAS
                </button>
              </div>
              {!this.state.filteredMissions.length ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '33px' }}>
                  <span className="mission_list_msg">Você não possui missões</span>
                </div>
              ) : (
                <>
                  <br />
                  <SwipeableViews
                    index={index}
                    onChangeIndex={this.handleChangeIndex}
                    slideStyle={{ overflow: 'visible' }}
                  >
                    {this.state.filteredMissions.map((item, index) => (
                      <div key={`${index}_${item.id}`}>
                        {item.DataModulesDetails !== null && (
                          <div
                            className={`mission_list_cardQuest ${
                              item.type_module_id === 30 && 'green'
                            }`}
                          >
                            <div className="mission_list_positionFavorite">
                              <label>
                                {item.type_module_id === 30 ? 'Missão Personalizada' : 'Missão'}
                              </label>
                            </div>

                            <div className="mission_list_header">
                              <div
                                className={`mission_list_points ${
                                  item.type_module_id === 30 && 'green'
                                }`}
                              >
                                {item.DataModulesDetails.data.rewards.map((reward) => (
                                  <>
                                    {Number.parseInt(reward.dataAssetDetailId) === 5 && (
                                      <div className={'mission_list_points'}>
                                        {`${reward.amount} Pontos`}
                                      </div>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>

                            <div className="mission_list_contentText">
                              <p className="mission_list_title">{item.DataModulesDetails.name}</p>

                              <div className="mission_list_contentProgress">
                                <progress
                                  value={item.data.progressPercent}
                                  max={100}
                                  className="mission_list_progress"
                                />
                              </div>

                              <div className="mission_list_positionLink"></div>

                              <div className="miss_container_accordion mission-description">
                                <Accordion
                                  expanded={item.id === this.state.selectedId}
                                  className="miss_list_accordion"
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    className={`mission-${item.data_modules_detail_id}`}
                                  >
                                    <div className="miss_title_accordion">Descrição da missão</div>
                                  </AccordionSummary>
                                  <AccordionDetails className="miss_details_accordion">
                                    {item.DataModulesDetails.description}
                                  </AccordionDetails>
                                </Accordion>
                              </div>

                              <div>
                                {Number.parseInt(item.data.status) ===
                                  enumQuestStatus.available && (
                                  <Button
                                    className="mission_list_link g-m-accept"
                                    minWidth="100px"
                                    fontSize="12px"
                                    type="green"
                                    onClick={() => this.acceptMission(item)}
                                  >
                                    Aceitar
                                  </Button>
                                )}
                                {Number.parseInt(item.data.status) === enumQuestStatus.ongoing && (
                                  <>
                                    {!!item.DataModulesDetails.data.hasAction && (
                                      <Button
                                        className="mission_list_link g-m-go-to-objective"
                                        minWidth="100px"
                                        fontSize="12px"
                                        type={'primary'}
                                        onClick={() => this.doAction(item)}
                                      >
                                        Ir para o objetivo
                                      </Button>
                                    )}
                                    {!item.DataModulesDetails.data.hasAction && (
                                      <Button
                                        className="mission_list_link"
                                        minWidth="100px"
                                        fontSize="12px"
                                        type={'disabled'}
                                        disabled="disabled"
                                      >
                                        Aguardando conclusão
                                      </Button>
                                    )}
                                  </>
                                )}
                                {Number.parseInt(item.data.status) === enumQuestStatus.completed &&
                                  item.data.redeemed && (
                                    <div className="mission_list_conclued">
                                      <CheckCircleOutline
                                        className="mission_list_link icon"
                                        style={{ color: 'var(--green)' }}
                                      />
                                      <small style={{ color: 'var(--green)' }}>Concluído</small>
                                    </div>
                                  )}
                                {Number.parseInt(item.data.status) === enumQuestStatus.completed &&
                                  !item.data.redeemed && (
                                    <Button
                                      className="mission_list_link g-m-claim"
                                      minWidth="100px"
                                      fontSize="12px"
                                      onClick={() => this.claimRewards(item)}
                                    >
                                      Resgatar
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </SwipeableViews>
                </>
              )}
              <Pagination
                dots={this.state.filteredMissions.length}
                index={index}
                onChangeIndex={this.handleChangeIndex}
              />
            </Hidden>
            {this.state.open && (
              <InitialModalQuiz
                onClose={this.closeModal}
                open={this.state.open}
                quizData={QuestionarioIndice}
              />
            )}
            {this.state.openMission && (
              <MissionCustom
                onCloseMission={(data) => this.acceptMission(data)}
                openMission={this.state.openMission}
                data={this.state.missionPersonData}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default watch(withRouter(MissionList), { user: '' });
