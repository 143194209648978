import React, { Component } from 'react';
import { Button, Container, CircularProgress, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography, AppBar, Tabs, Tab, FormControl, MenuItem, Select, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TabPanel from '../../../../shared/components/TabPanel';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './expensesPanelSelect.scss';
import api from '../../../../services/api';
import Globals from '../../../../Globals.json';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatWithOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import CustomizedTables from '../components/customizedTables/CustomizedTables'
import Dialogs from '../../../../services/dialogs';


class ExpensesPanelSelect extends Component {

  state = {
    loadedDataFixed: null,
    loadedDataVariable: null,
    loadedDataPhotographics: [],
    loadedDataPhotographicsInfo: null,
    selectedPhotographicId: this.props.state.selectedPhotographicId,
    isLoading: false,
    isLoadingData: true,
    selectedCard: null,
    expensesType: this.props.state.data.expensesType ? this.props.state.data.expensesType : null,
    tabValue: 0,
    selectedDate: this.props.selectedDate || new Date(),
    tableData: [],
    typeSelected: 'fixed',
    selectedCardName: '',
    alertMessage: '',
    openAlert: false,
    alertSeverity: 'success'
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue,
      selectedCard: null,
      typeSelected: newValue === 0 ? 'fixed' : 'variable'
    })
  };

  generateTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  handleChangeIndex = (index) => {
    this.setState({
      tabValue: index,
    })
  };

  componentDidMount = () => {
    this.props.changeWidth('md')
    this.loadData()
    if (this.props.state.data.showMessage) {
      this.setState({
        alertMessage: 'Sua despesa foi salva com sucesso',
        alertSeverity: 'success',
        openAlert: true
      })
    }
  }

  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  }

  loadData = async () => {
    this.setState({
      isLoadingData: true,
    })

    try {
      const res = await api.get(Globals.api.typeExpensesMatrixComboboxUser, { params: { type_expense_id: 1, show_inactives: 1 } })
      this.setState({
        loadedDataFixed: res.data,
      })

      const res2 = await api.get(Globals.api.typeExpensesMatrixComboboxUser, { params: { type_expense_id: 2, show_inactives: 1 } })
      this.setState({
        loadedDataVariable: res2.data,
      })

      const res3 = await api.get(Globals.api.userFinancialPhotographCombobox)
      this.setState({
        loadedDataPhotographics: res3.data,
      })

      let periodo = null
      let id = null

      if (this.state.selectedPhotographicId) {
        const foundPhotograph = res3.data.find(item => item.id === this.state.selectedPhotographicId)
        periodo = foundPhotograph.data
        id = this.state.selectedPhotographicId
      } else {
        periodo = res3.data[0].data
        id = res3.data[0].id
      }

      const res4 = await api.get(Globals.api.listUserExpenseItem, { params: { cad_user_financial_photograph_id: id } })
      this.setState({
        loadedDataPhotographicsInfo: res4.data,
        selectedDate: periodo,
        selectedPhotographicId: id
      })


    } catch (error) {
      console.log(error.response)
    }
    this.setState({
      isLoadingData: false,
    })
  }

  loadCardsInfo = async (value) => {

    try {
      const res4 = await api.get(Globals.api.listUserExpenseItem, { params: { cad_user_financial_photograph_id: value } })
      this.setState({
        loadedDataPhotographicsInfo: res4.data,
      })

    } catch (error) {
      console.log(error.response)
    }
    this.setState({
      isLoadingData: false,
    })
  }

  changeDate = async (event) => {
    this.setState({
      isLoadingData: true,
    })

    await this.loadCardsInfo(event.target.value)

    const foundPhotograph = this.state.loadedDataPhotographics.find(item => item.id === event.target.value)

    this.setState({
      isLoadingData: false,
      selectedPhotographicId: event.target.value,
      selectedDate: foundPhotograph.data
    })

    this.handleSelectItem(this.state.selectedCard, this.state.selectedCardName)
  }

  handleSelectItem = (id, name = '') => {
    const typeSelected = this.state.typeSelected
    if (!!this.state.loadedDataPhotographicsInfo[typeSelected]?.matrizes[`${id}`]) {
      this.setState({ tableData: this.state.loadedDataPhotographicsInfo[typeSelected].matrizes[`${id}`].itens })
    } else {
      this.setState({ tableData: [] })
    }
    this.setState({
      selectedCard: id,
      selectedCardName: name,
    })
  }

  numberToCurrency = (value) => {
    const currency = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(value);
    return currency
  }

  isIncluded = (value) => {
    const typeSelected = this.state.typeSelected
    if (this.state.loadedDataPhotographicsInfo && this.state.loadedDataPhotographicsInfo[typeSelected]?.matrizes) {
      if (Object.keys(this.state.loadedDataPhotographicsInfo[typeSelected]?.matrizes).includes(value)) {
        return true
      }
    }
    return false
  }

  newExpense = () => {
    console.log(this.state.selectedCardName)

    if (this.state.selectedCard) {
      this.props.showContent({ to: 'expenses-select-items', from: this.props.page, data: { expensesType: this.state.typeSelected === 'fixed' ? 1 : 2, selectedDate: this.state.selectedDate, expensesMatrixId: this.state.selectedCard, action: 'new', matrixName: this.state.selectedCardName } })
    } else {
      this.props.showContent({ to: 'expenses-select', from: this.props.page, data: { expensesType: this.state.typeSelected === 'fixed' ? 1 : 2, selectedDate: this.state.selectedDate, photographIdReference: this.state.selectedPhotographicId, action: 'new', matrixName: this.state.selectedCardName } })
    }
  }

  handleDelete = async (id) => {

    Dialogs.show('Confirmação de Exclusão', 'Você tem certeza de que deseja excluir a despesa?', [
      { label: 'Cancelar' },
      {
        label: 'Sim',
        onClick: () => this.doDelete(id),
      },
    ]);

  }

  doDelete = async (id) => {
    try {
      const res = await api.delete(Globals.api.userExpenseItem + `/${id}`)
      this.setState({
        alertMessage: 'Sua despesa foi excluida com sucesso',
        alertSeverity: 'success',
        openAlert: true
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alertMessage: 'Não foi possível excluir sua despesa',
        alertSeverity: 'error',
        openAlert: true
      })
    }

    await this.loadData()
    this.handleSelectItem(this.state.selectedCard)
  }

  handleEdit = (item) => {
    console.log(item)
    this.props.showContent({ to: 'expenses-select-items', from: this.props.page, data: { expensesType: this.state.typeSelected === 'fixed' ? 1 : 2, selectedDate: this.state.selectedDate, expensesMatrixId: this.state.selectedCard, action: 'edit', item: item, matrixName: this.state.selectedCardName } })
  }

  render() {
    return (

      <Grid className='expenses-select-panel'>
        <MuiDialogTitle disableTypography className="dialog-header">
          {this.state.isLoadingData ?
            (<h2><CircularProgress size={24} style={{ color: "white" }} /> </h2>)
            :
            (<>
              <h2>Suas despesas -</h2>
              <FormControl>
                <Select
                  id={'month-select'}
                  value={this.state.selectedPhotographicId}
                  onChange={this.changeDate}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  variant={'outlined'}
                  labelId="month-select"

                >
                  {this.state.loadedDataPhotographics?.length > 0 &&
                    this.state.loadedDataPhotographics.map((item) =>
                      (<MenuItem key={item.id} value={item.id}>{item.periodo}</MenuItem>))
                  }
                </Select>

              </FormControl>
            </>)
          }

          <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <Grid container item xs={12} className="actions" >

          <AppBar position="static">
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleChangeTab}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="full width tabs example"
              centered
            >
              <Tab label="Despesa fixa" {...this.generateTabProps(0)} />
              <Tab label="Despesa variável" {...this.generateTabProps(1)} />

            </Tabs>
          </AppBar>

        </Grid>
        <DialogContent className="dialog-content" >
          {this.state.selectedCard &&
            <Grid container item xs={12} className="actions-buttons" justify="space-between" alignItems="center">
              <Button
                id="g-pm-start-test"
                className={`g-pm-start-test`}
                color="primary"
                variant="outlined"
                onClick={() => this.handleSelectItem(null)}
                style={{ borderRadius: "0px", height: "40px" }}
              >
                <ArrowLeft style={{ marginRight: "4px", marginTop: "-3px" }} fontSize="small" /> {'voltar'}
              </Button>
              <h2>{this.state.selectedCardName}</h2>
              <Button
                id="g-pm-start-test"
                className={`g-pm-start-test`}
                color="secondary"
                variant="contained"
                onClick={this.newExpense}
                style={{ borderRadius: "0px", color: "white", width: "165px", height: "40px" }}
              >
                <ControlPointIcon style={{ marginRight: "4px", marginTop: "-3px" }} fontSize="small" /> {'nova despesa'}
              </Button>
            </Grid>
          }
          <Grid item xs={12} className="classDetailContainer">
            <Snackbar
              open={this.state.openAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={2000}
              onClose={() =>
                setTimeout(() => {
                  this.setState({ openAlert: false });
                }, 2000)
              }
            >
              <Alert
                onClose={() => {
                  this.setState({ openAlert: false });
                }}
                severity={this.state.alertSeverity}
              >
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          </Grid>

          {this.state.isLoadingData ?
            (<Grid item xs={12} className="loading-area">

              <CircularProgress />

            </Grid>
            ) :
            <>
              <TabPanel value={this.state.tabValue} index={0} >
                {this.state.selectedCard ? (
                  <CustomizedTables data={this.state.tableData} delete={this.handleDelete} edit={this.handleEdit}></CustomizedTables>
                ) : (
                  <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">

                    <Grid container item xs={12} direction="row" className={'card-container'} justify="flex-start" >
                      {this.state.loadedDataFixed?.length > 0 &&
                        this.state.loadedDataFixed?.map((item) => {
                          if (item.type_status_id === 1 || this.isIncluded(item.id)) {
                            return (
                              <Grid key={item.id}
                                container
                                item
                                xs={4}
                                direction="column"
                                justify="flex-start"
                                className={'card'}
                                onClick={() => this.handleSelectItem(item.id, item.name)}>
                                <h5>
                                  {!this.state.isLoadingData &&
                                    !!this.state.loadedDataPhotographicsInfo.fixed?.matrizes[`${item.id}`] ?
                                    this.numberToCurrency(this.state.loadedDataPhotographicsInfo.fixed.matrizes[`${item.id}`].valor_total_matrix) : 'R$ 0,00'}
                                </h5>
                                <p>{item.name}</p>
                              </Grid>
                            )
                          } else {
                            return ''
                          }

                        })
                      }
                    </Grid>

                  </Grid>

                )}
              </TabPanel>

              <TabPanel value={this.state.tabValue} index={1} >
                {this.state.selectedCard ? (
                  <CustomizedTables data={this.state.tableData} delete={this.handleDelete} edit={this.handleEdit}></CustomizedTables>
                ) : (
                  <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">

                    <Grid container item xs={12} direction="row" className={'card-container'} justify="flex-start" >
                      {this.state.loadedDataVariable?.length &&
                        this.state.loadedDataVariable.map((item) => {
                          if (item.type_status_id === 1 || this.isIncluded(item.id)) {
                            return (
                              <Grid key={item.id}
                                container
                                item
                                xs={4}
                                direction="column"
                                justify="flex-start"
                                className={'card'}
                                onClick={() => this.handleSelectItem(item.id, item.name)}>
                                <h5>
                                  {!this.state.isLoadingData &&
                                    !!this.state.loadedDataPhotographicsInfo.variable?.matrizes[`${item.id}`] ?
                                    this.numberToCurrency(this.state.loadedDataPhotographicsInfo.variable.matrizes[`${item.id}`].valor_total_matrix) : 'R$ 0,00'}
                                </h5>
                                <p>{item.name}</p>

                              </Grid>
                            )
                          } else {
                            return ''
                          }

                        })
                      }
                    </Grid>

                  </Grid>

                )}
              </TabPanel>
            </>
          }
        </DialogContent >
      </Grid >
    )
  }
}

export default watch(withRouter(ExpensesPanelSelect), { user: '' });