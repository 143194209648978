import React, { Fragment } from 'react';
import moment from 'moment';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
} from '@material-ui/core';

import '../../../../../backoffice.scss';
import './paginatedTable.scss';
import MenuActions from './MenuActions';

const PaginatedTable = (props) => {
  const resultPage = props.data.page
  const resultLastPage = props.data.lastPage

  const getValue = (rowObj, itemArr, value) => {

    for (let key in Object.keys(rowObj)) {
      const t = Object.keys(rowObj)[key];

      if (itemArr.length === 1) {
        if (t === itemArr[0]) {
          value = rowObj[t]
        }
      } else {
        if (t === itemArr[0]) {
          const newArr = itemArr.slice(1, itemArr.length)
          value = getValue(rowObj[t], newArr, value)
        }
      }

    }
    return value
  }

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
  }

  const getRowValue = (row, item) => {
    const fieldNameArr = item.fieldName.split('.');
    let value = getValue(row, fieldNameArr);
    if (item.type === 'date') {
      value = formatDate(value)
    } else if (item.type === 'active') {
      value = value ? 'Ativo' : 'Inativo'
    } else if (item.type === 'canRelate') {
      value = value ? 'Sim' : 'Não'
    } else if ((item.type === 'link')) {
      value = <a href={value} target="_blank" rel="noopener noreferrer">Link</a>
    }
    return value
  }

  const getPages = () => {

    const buttons = [];
    const displayLimit = 2;

    if (resultPage && resultLastPage) {
      if (resultPage > 1) {
        buttons.push({ label: "Primeira", pgNum: 1 })
      } else {
        buttons.push({ label: "Primeira", pgNum: 1, visible: false })
      }
      if (resultPage > 1) {
        buttons.push({ label: "Anterior", pgNum: resultPage - 1 })
      } else {
        buttons.push({ label: "Anterior", pgNum: resultPage - 1, visible: false })
      }
      if (resultPage - displayLimit > 1) {
        buttons.push({ label: "...", pgNum: resultPage - displayLimit - 1 })
      }
      for (let i = 1; i <= resultLastPage; i++)
        if (Math.abs(resultPage - i) <= displayLimit) {
          buttons.push({ label: i, pgNum: i, current: resultPage == i })
        }
      if (resultPage + displayLimit < resultLastPage) {
        buttons.push({ label: "...", pgNum: resultPage + displayLimit + 1 })
      }
    }
    if (resultPage < resultLastPage) {
      buttons.push({ label: "Próxima", pgNum: resultPage + 1 })
    } else {
      buttons.push({ label: "Próxima", pgNum: resultPage + 1, visible: false })
    }
    if (resultPage < resultLastPage) {
      buttons.push({ label: "Última", pgNum: resultLastPage })
    } else {
      buttons.push({ label: "Última", pgNum: resultLastPage, visible: false })
    }
    return buttons;
  }


  return (
    <>

      {!props.data.data && props.isLoading ?
        <TableContainer className='paginated-table'>
          <Table
            aria-label="enhanced table"
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                  <div className="table_user_showResult">
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        :

        <>
          {!props.data.data ? (
            <TableContainer className='paginated-table'>
              <Table
                aria-label="enhanced table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                      <div className="table_user_showResult">
                        <span>Não foi possível encontrar nenhum registro.</span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {props.data.data &&
                <>
                  <TableContainer className='paginated-table'>
                    <Table
                      aria-label="enhanced table"
                    >
                      <TableHead>

                        <TableRow>
                          {props.actions &&
                            <TableCell
                              align='center'
                            >
                              <span style={{ color: '#202020', fontWeight: '700', fontSize: '16px' }}>{'Ações'}</span>
                            </TableCell>
                          }
                          {props.titleFields && props.titleFields.length > 0 && props.titleFields.map((headCell, index) => (
                            <TableCell
                              key={index}
                              align='center'
                            >
                              <span style={{ color: '#202020', fontWeight: '700', fontSize: '16px' }}>{headCell.title}</span>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>


                      <TableBody>
                        {props.isLoading ?
                          <TableRow>
                            <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                              <div className="table_user_showResult">
                                <CircularProgress />
                              </div>
                            </TableCell>
                          </TableRow>
                          :

                          <>
                            {props.data.data.length === 0 &&
                              <TableRow>
                                <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                                  <div className="table_user_showResult">
                                    <span>Não foi possível encontrar nenhum registro.</span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            }
                            {props.data.data.length > 0 &&
                              <>
                                {props.data.data.map((row, index) => {
                                  console.log('rows: ', row)
                                  return (
                                    <TableRow
                                      hover
                                      key={index}
                                    >
                                      {props.actions && props.actions.length > 0 &&
                                        <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >

                                          <MenuActions id={row.id} actions={props.actions} />

                                        </TableCell>
                                      }

                                      {props.titleFields.map((item, index) => (

                                        <TableCell
                                          key={index}
                                          align='center'
                                        >
                                          {item.fieldName === 'insentiveCut' &&
                                            <Tooltip title={row?.incentiveFull} disableHoverListener={item.type !== 'toolTip'}>
                                              <span>{getRowValue(row, item)}</span>
                                            </Tooltip>
                                          }
                                         {item.fieldName === 'descriptionCut' &&
                                            <Tooltip title={row?.descriptionFull} disableHoverListener={item.type !== 'toolTip'}>
                                              <span>{getRowValue(row, item)}</span>
                                            </Tooltip>
                                          }
                                          {(item.fieldName !== 'descriptionCut' && item.fieldName !== 'insentiveCut') &&
                                           <span>{getRowValue(row, item)}</span>
                                          }


                                        </TableCell>


                                      ))}

                                    </TableRow>
                                  )
                                })}
                              </>

                            }
                          </>

                        }
                      </TableBody>

                    </Table>

                  </TableContainer>

                </>
              }
            </>

          )}

          <div className="pagination-container">

            {getPages().map((item, index) => (
              <Fragment key={index}>
                {item.current &&
                  <button style={{ background: "none", border: "none", padding: "0px", outline: "none", visibility: item.visible === false ? 'hidden' : 'visible' }} >
                    <a className="pagination-button-current">
                      {item.label}
                    </a>
                  </button>
                }
                {!item.current &&
                  <button style={{ background: "none", border: "none", padding: "0px", outline: "none", visibility: item.visible === false ? 'hidden' : 'visible' }} onClick={() => props.pagNavigation(item.pgNum)} >
                    <a className="pagination-button">
                      {item.label}
                    </a>
                  </button>
                }
              </Fragment>
            ))}
          </div>
        </>
      }
    </>
  )
}

export default PaginatedTable;