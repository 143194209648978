import React from 'react';
import './photograph.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Typography, Grid, Hidden, Select, MenuItem, FormControl, CircularProgress, Tooltip, withStyles, Paper, Box, Dialog, DialogContent, IconButton, Divider } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import ListtoolFinances from '../../components/tool/ListToolFinances';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import PhotographModalExpenses from '../../Pages/photograph-modal/PhotographModalExpenses';
import PhotographModalIncomes from '../../Pages/incomes-modal/PhotographModalIncomes';
import Button from '../../components/button/Button';

import expensesImg from '../../../../src/assets/image/despesas.png';
import incomeImg from '../../../../src/assets/image/receitas.png';
import tutorial01 from '../../../../src/assets/image/tutorial01.png';
import tutorial02 from '../../../../src/assets/image/tutorial02.png';
import tutorial03 from '../../../../src/assets/image/tutorial03.png';
import tutorial04 from '../../../../src/assets/image/tutorial04.png';
import api from '../../../services/api';
import moment from 'moment';
import { format, formatWithOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale';
import { Doughnut, Chart } from 'react-chartjs-2';

const Globals = require("../../../Globals.json");

class Photograph extends React.Component {

  state = {
    classTextBtn: 0,
    profileModalExpensesOpened: false,
    profileModalIncomesOpened: false,
    loadedDataPhotograph: null,
    selectedPhotographicId: '',
    isLoadingData: true,
    selectedDate: new Date(),
    loadedDataPhotographicsInfo: null,
    dataChartExpenses: [],
    dataChartIncomes: [],
    optionsExpenses: {},
    optionsIncomes: {},
    isWelcomeIncomes: false,
    isWelcomeExpenses: false,
    showModalTutorial: false

  }

  closeWelcomeModal = () => {
    this.setState({
      profileModalExpensesOpened: false,
      profileModalIncomesOpened: false
    });

    this.loadData()
  };

  componentDidMount = () => {
    this.loadData()

    // this.registerChartPlugin()

  }

  registerChartPlugin = () => {
    Chart.plugins.register({
      beforeRender: function (chart) {
        if (chart.config.options.showAllTooltips) {
          // create an array of tooltips
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function (dataset, i) {
            chart.getDatasetMeta(i).data.forEach(function (sector, j) {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });

          // turn off normal tooltips
          chart.options.tooltips.enabled = false;
        }
      },
      afterDraw: function (chart, easing) {
        if (chart.config.options.showAllTooltips) {
          // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
          if (!chart.allTooltipsOnce) {
            if (easing !== 1)
              return;
            chart.allTooltipsOnce = true;
          }

          // turn on tooltips
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
            tooltip.initialize();
            tooltip.update();
            // we don't actually need this since we are not animating tooltips
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
        }
      }
    });
  }

  setOptionsChart = () => {
    // Doughnut.reset();
    const optionsIncomes = {

      // showAllTooltips: true,
      legend: {
        position: 'bottom',
        align: 'start',

        labels: {
          fontColor: '#202020',
          fontFamily: 'elza',
          fontSize: 12,
          boxWidth: 12,
          padding: 16,
        }

      },

      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']];
          },
          label: function (tooltipItem, data) {

            const dataset = data['datasets'][0];
            const index = Object.keys(dataset["_meta"])
            const percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][index]['total']) * 100)
            const percentString = '(' + percent + '%)';
            const currency = new Intl.NumberFormat('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }).format(data['datasets'][0]['data'][tooltipItem['index']]);
            return currency + ' ' + percentString;
          },
          // afterLabel: function(tooltipItem, data) {
          //   var dataset = data['datasets'][0];
          //   var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
          //   return '(' + percent + '%)';
          // }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderColor: '#000000',
        borderWidth: 0,
        titleFontSize: 14,
        titleFontColor: '#005E50',
        titleFontFamily: 'elza',
        titleAlign: 'center',
        bodyFontColor: '#000',
        bodyFontSize: 12,
        bodyFontFamily: 'elza',
        displayColors: false,
        // caretSize: 5,
        // caretPadding: -50,
        // xPadding: 6,
        position: 'nearest'
      },



    }
    const optionsExpenses = {

      // showAllTooltips: false,

      legend: {
        position: 'bottom',
        align: 'start',


        labels: {
          fontColor: '#202020',
          fontFamily: 'elza',
          fontSize: 12,
          boxWidth: 12,
          padding: 16,
        }

      },

      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']];
          },
          label: function (tooltipItem, data) {
            const dataset = data['datasets'][0];
            const index = Object.keys(dataset["_meta"])
            const percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][index]['total']) * 100)
            const percentString = '(' + percent + '%)';
            const currency = new Intl.NumberFormat('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }).format(data['datasets'][0]['data'][tooltipItem['index']]);
            return currency + ' ' + percentString;
          },
          // afterLabel: function(tooltipItem, data) {
          //   var dataset = data['datasets'][0];
          //   var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][1]['total']) * 100)
          //   return '(' + percent + '%)';
          // }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderColor: '#000000',
        borderWidth: 0,
        titleFontSize: 14,
        titleFontColor: '#05CCAE',
        titleFontFamily: 'elza',
        titleAlign: 'center',
        bodyFontColor: '#000',
        bodyFontSize: 12,
        bodyFontFamily: 'elza',
        displayColors: false,
        // caretSize: 5,
        // caretPadding: -50,
        // xPadding: 6,
        position: 'nearest'
      }

    }

    this.setState({ optionsIncomes: optionsIncomes, optionsExpenses: optionsExpenses });
  }



  loadData = async () => {
    this.setState({
      isLoadingData: true,
    })

    try {

      const resPhotographics = await api.get(Globals.api.userFinancialPhotographCombobox)
      this.setState({
        loadedDataPhotograph: resPhotographics.data,
      })

      if (resPhotographics.data?.length <= 0) {
        return
      }

      const actualDate = format(new Date(), 'MMMM yyyy', { locale: ptBR }).toLocaleUpperCase()

      let res4 = null
      let expensesSelectedPhotographicId = null
      const foundMonth = this.state.loadedDataPhotograph.find(el => el.periodo === actualDate)
      if (foundMonth) {
        res4 = await api.get(Globals.api.expensesIncomesValues, { params: { cad_user_financial_photograph_id: foundMonth.id } })
        expensesSelectedPhotographicId = foundMonth.id

      } else {
        res4 = await api.get(Globals.api.expensesIncomesValues, { params: { cad_user_financial_photograph_id: resPhotographics.data[0].id } })
        expensesSelectedPhotographicId = resPhotographics.data[0].id

      }

      this.populateChart(res4.data)

      this.setState({
        loadedDataPhotographicsInfo: res4.data,
        selectedPhotographicId: expensesSelectedPhotographicId
      })

    } catch (error) {
      console.log('error', error)
    }
    this.setState({
      isLoadingData: false,
    })
  }

  changeDate = async (event) => {
    this.setState({
      isLoadingData: true,
    })

    await this.loadCardsInfo(event.target.value)

    this.setState({
      isLoadingData: false,
      selectedPhotographicId: event.target.value
    })
  }

  loadCardsInfo = async (value) => {

    try {
      const res4 = await api.get(Globals.api.expensesIncomesValues, { params: { cad_user_financial_photograph_id: value } })
      this.setState({
        loadedDataPhotographicsInfo: res4.data,
      })
      this.populateChart(res4.data)

    } catch (error) {
      console.log(error.response)
      this.setState({
        loadedDataPhotographicsInfo: null,
      })
    }
    this.setState({
      isLoadingData: false,
    })


  }
  numberToCurrency = (value) => {
    const currency = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(value);
    return currency
  }

  isPositive = (value) => {
    const stringValue = value.toString()
    if (stringValue[0] === '-') {
      return false
    } else {
      return true
    }
  }

  populateChart = (data) => {
    console.log(data)

    if (data.expenses.matrices) {
      const expensesLabels = data.expenses.matrices.map(item => item.name)
      const expensesValues = data.expenses.matrices.map(item => item.value)

      const expenses = {
        labels: expensesLabels,
        datasets: [
          {
            label: '# of Votes',
            data: expensesValues,
            backgroundColor: [
              // '#f8ebe9',
              // '#f9d0c3',
              // '#F6B29D',
              '#f97b53',
              '#fa6435',
              '#ef5e31',
              '#e1572c',
              '#d35028',
              '#ba4420',
              '#D08589',
              '#A45E78',
              '#723D66',
              '#3E2250',
            ],
            borderColor: [
              // '#f8ebe9',
              // '#f9d0c3',
              // '#F6B29D',
              '#f97b53',
              '#fa6435',
              '#ef5e31',
              '#e1572c',
              '#d35028',
              '#ba4420',
              '#D08589',
              '#A45E78',
              '#723D66',
              '#3E2250',

            ],
            borderWidth: 1,
          },
        ],
      };

      this.setState({
        dataChartExpenses: expenses,
      })
    }

    if (data.incomes.types) {
      const incomesLabels = data.incomes.types.map(item => item.name)
      const incomesValues = data.incomes.types.map(item => item.value)

      const incomes = {
        labels: incomesLabels,
        datasets: [
          {
            label: '# of Votes',
            data: incomesValues,
            backgroundColor: [
              // '#e2f2f0',
              // '#b8e0d9',
              // '#8cccc1',
              // '#63b8a9',
              '#05CCAE',
              // '#3d9886',
              // '#388b79',
              '#337b6a',
              '#2e6b5b',
              '#234f41',
              '#00818A',
              '#005977',
              '#00335C',
              '#000D38',
            ],
            borderColor: [
              // '#e2f2f0',
              // '#b8e0d9',
              // '#8cccc1',
              // '#63b8a9',
              '#05CCAE',
              // '#3d9886',
              // '#388b79',
              '#337b6a',
              '#2e6b5b',
              '#234f41',
              '#00818A',
              '#005977',
              '#00335C',
              '#000D38',
            ],
            borderWidth: 1,
          },
        ],
      };

      this.setOptionsChart()

      this.setState({
        dataChartIncomes: incomes,
      })
    }

  }
  openIncomes = async () => {
    if (this.state.loadedDataPhotographicsInfo?.incomes.need_tutorial === 1) {
      await this.setState({ isWelcomeIncomes: true })
    } else {
      await this.setState({ isWelcomeIncomes: false })
    }

    this.setState({ profileModalIncomesOpened: true })


  }

  openExpenses = async () => {
    if (this.state.loadedDataPhotographicsInfo?.expenses.need_tutorial === 1) {
      await this.setState({ isWelcomeExpenses: true })
    } else {
      await this.setState({ isWelcomeExpenses: false })
    }
    this.setState({ profileModalExpensesOpened: true })
  }
  openTutorial = () => {
    this.setState({ showModalTutorial: true })
  }
  closeTutorial = () => {
    this.setState({ showModalTutorial: false })
  }



  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="tool_finances_breadCrumb">
          <Link color="inherit" to={Globals.paths.tools} className="tool_finances_breadCrumbLink">
            Ferramentas financeiras
          </Link>
          <Link color="inherit" to={Globals.paths.photograph} className="tool_finances_breadCrumbLink">
            Fotografia Financeira
          </Link>

        </Breadcrumbs>


        <Helmet>
          <title>Ferramentas | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          {/* sm={8} md={9} */}
          <Grid item xs={12} sm={8} md={9} className="classContainertools">

            <Grid item container className="toolsItem">
              <Grid item xs={12} className="toolsTitleBar">
                <Grid item xs={12} className="toolsTitle" >
                  <h1>Sua fotografia financeira</h1>
                  <Tooltip
                    title={'Clique para abrir o tutorial de como utilizar a ferramenta fotografia financeira.'}
                    id={'photograph-tooltip'}
                  >
                    <button className="tutorial" onClick={this.openTutorial}>
                      <h1>(?)</h1>
                    </button>
                  </Tooltip>
                </Grid>


                {this.state.isLoadingData && this.state.loadedDataPhotograph?.length > 0 ? <CircularProgress size={24} /> :
                  (<>{this.state.loadedDataPhotograph?.length > 0 &&
                    <Grid className='select-date'>
                      <FormControl>
                        <Select
                          id={'month-select'}
                          value={this.state.selectedPhotographicId}
                          onChange={this.changeDate}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          variant={'outlined'}
                          labelId="month-select"
                        >
                          {this.state.loadedDataPhotograph?.length > 0 &&
                            this.state.loadedDataPhotograph.map((item) =>
                              (<MenuItem key={item.id} value={item.id}>{item.periodo}</MenuItem>))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  }</>)}


              </Grid>
              <Grid container spacing={2} className="card-container">
                <Grid item className="info" xs={12}>
                  <p>Aqui você pode fazer a sua <span>Fotografia Financeira</span> que, nada mais é, do que um jeito prático e fácil de visualizar as suas receitas e das suas despesas. Esse método é usado pelos profissionais de educação financeira como o primeiro passo para as pessoas possam se organizar. Você pode listar e criar classificações para os seus gastos e as entradas, separadas por tipo (fixo ou variável) e com as datas em que esses valores entram ou saem do seu orçamento, e assim acompanhar de forma visual como se comporta o seu fluxo financeiro.</p>

                  <p>Tudo aquilo que você fazia em papel, agora pode ser feito e visualizado nesta ferramenta.</p>

                  <p>No curso "<Link to={Globals.paths.courses + "/1182"}>
                    Mapeie sua vida financeira
                  </Link>" você encontra uma explicação completa de como utilizar essa técnica para se organizar!</p>
                </Grid>

                <Grid item className="card" lg={6}>
                  <Grid className="content income">
                    <div className="info">
                      {this.state.loadedDataPhotographicsInfo && this.state.loadedDataPhotographicsInfo.incomes.ratio_from_last_month !== 0 &&
                        <div className={this.isPositive(this.state.loadedDataPhotographicsInfo.incomes.ratio_from_last_month) ? 'percent green' : 'percent red'}>
                          <h6>{Math.floor(this.state.loadedDataPhotographicsInfo.incomes.ratio_from_last_month)}% {this.isPositive(this.state.loadedDataPhotographicsInfo.incomes.ratio_from_last_month) ? 'a mais' : 'a menos'} em relação ao mês anterior</h6>
                        </div>
                      }
                      <h3>Suas receitas</h3>
                      <h2>{this.state.loadedDataPhotographicsInfo ? this.numberToCurrency(this.state.loadedDataPhotographicsInfo?.incomes.total_value) : 'R$ 0,00'}</h2>
                      <p>{this.state.loadedDataPhotograph?.length > 0 ?
                        (
                          <>
                            <span onClick={() => this.openIncomes()}>Clique aqui</span> para cadastrar novas, editar ou excluir suas receitas.
                          </>
                        ) : (
                          <>
                            <span onClick={() => this.openIncomes()}>Registre aqui</span> todas as suas receitas fixas e variáveis.
                          </>
                        )}

                      </p>
                    </div>
                    <div className="img">
                      <img src={incomeImg} alt="icone da receita" />
                    </div>
                    <div className="bottom-bar income" />
                  </Grid>


                  <Grid className={this.state.loadedDataPhotographicsInfo?.incomes.total_value ? 'chart flex' : 'chart none'}>
                    <h3>Detalhes Receitas</h3>
                    <Grid className="container-chart">
                      <Grid container item direction="column" justify="center" alignItems="center" className="p20 chart-box">
                        <Doughnut data={this.state.dataChartIncomes} options={this.state.optionsIncomes} height={220} width={220} id='incomesChart' />
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item className="card" lg={6}>
                  <Grid className="content expense">
                    <div className="info">
                      {this.state.loadedDataPhotographicsInfo && this.state.loadedDataPhotographicsInfo.expenses.ratio_from_last_month !== 0 &&
                        <div className={this.isPositive(this.state.loadedDataPhotographicsInfo.expenses.ratio_from_last_month) ? 'percent red' : 'percent green'}>
                          <h6>{Math.floor(this.state.loadedDataPhotographicsInfo.expenses.ratio_from_last_month)}% {this.isPositive(this.state.loadedDataPhotographicsInfo.expenses.ratio_from_last_month) ? 'a mais' : 'a menos'} em relação ao mês anterior</h6>
                        </div>
                      }

                      <h3>Suas despesas</h3>
                      <h2>{this.state.loadedDataPhotographicsInfo ? this.numberToCurrency(this.state.loadedDataPhotographicsInfo?.expenses.total_value) : 'R$ 0,00'}</h2>
                      <p>{this.state.loadedDataPhotograph?.length > 0 ?
                        (
                          <>
                            <span onClick={() => this.openExpenses()}>Clique aqui</span> para cadastrar novas, editar ou excluir suas despesas.
                          </>
                        ) : (
                          <>
                            <span onClick={() => this.openExpenses()} >Registre aqui</span> todas as suas despesas fixas e variáveis.
                          </>
                        )}

                      </p>

                    </div>
                    <div className="img">
                      <img src={expensesImg} alt="icone da receita" />
                    </div>
                    <div className="bottom-bar expense" />

                  </Grid>

                  <Grid className={this.state.loadedDataPhotographicsInfo?.expenses.total_value ? 'chart flex' : 'chart none'}>
                    <h3>Detalhes Despesas</h3>
                    <Grid className="container-chart">
                      <Grid container item direction="column" justify="center" alignItems="center" className="p20 chart-box">
                        <Doughnut data={this.state.dataChartExpenses} options={this.state.optionsExpenses} height={220} width={220} id='expensesChart' />
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>



              {/* <Hidden xsDown>
                <div className="containerTools">
                  <ListtoolFinances />
                </div>
              </Hidden>

              <Hidden smUp>
                <div className="containerTools">
                  <ListtoolFinances />
                </div>
              </Hidden>
 */}

            </Grid >
          </Grid>


          <Hidden xsDown>
            <Grid item sm={4} md={3}>
              {/* <FeedLine>
            <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                <Favorite />
              </ToolbarItem>
            </FeedLine> */}

              <ToolbarItem tooltip="
               O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
               e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
               vantagens exclusivas para os usuários."  title="Seu Nível" color="#05CCAE">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>
            </Grid>
          </Hidden>

        </Grid >
        {
          this.state.profileModalExpensesOpened && (
            <PhotographModalExpenses
              closeWelcomeModal={this.closeWelcomeModal}
              open={this.state.profileModalExpensesOpened}
              selectedPhotographicId={this.state.selectedPhotographicId}
              isWelcome={this.state.isWelcomeExpenses}
            ></PhotographModalExpenses>
          )
        }
        {
          this.state.profileModalIncomesOpened && (
            <PhotographModalIncomes
              closeWelcomeModal={this.closeWelcomeModal}
              open={this.state.profileModalIncomesOpened}
              selectedPhotographicId={this.state.selectedPhotographicId}
              isWelcome={this.state.isWelcomeIncomes}
            ></PhotographModalIncomes>
          )
        }
        <Dialog disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={'md'} className="modal-info-tutorial" open={this.state.showModalTutorial}>

          <MuiDialogTitle disableTypography className="dialog-header">
            <h2>Tutorial fotografia financeira</h2>
            <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.closeTutorial}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>

          <DialogContent className="dialog-content">
            <Grid container className="tutorial-container">

              <h1>
                Como cadastrar receitas e despesas
              </h1>
              <h2>
                Vamos lá?
              </h2>
              <ol>
                <li>
                  <h2>O primeiro passo é o cadastro das suas receitas (o dinheiro que entra).</h2>
                  <h2>Basta clicar no botão <span>Começar</span> da primeira tela.</h2>
                  <div className="img">
                    <img src={tutorial01} alt="imagem tutorial primeira tela receita" />
                  </div>
                </li>
                <li>
                  <h2>Daí basta escolher o tipo (<span>Receitas Fixas</span> ou <span>Receitas Variáveis</span>) e preencher de acordo.
                  </h2>
                  <div className="img">
                    <img src={tutorial02} alt="imagem tutorial segunda tela receita" />
                  </div>
                </li>
                <li>
                  <h2>Depois de cadastrar as receitas, o passo seguinte é o cadastro das suas despesas (o dinheiro que sai).
                  </h2>
                  <h2><span>Lembre-se:</span> cadastrar as despesas é essencial para o controle da sua vida financeira. Dessa forma, você classifica as suas prioridades, além de não ser pego de surpresa por ter se esquecido de alguma conta.
                  </h2>
                  <div className="img">
                    <img src={tutorial03} alt="imagem tutorial primeira tela despesas" />
                  </div>
                </li>
                <li>
                  <h2>Basta escolher as <span>Despesas Fixas</span> e as <span>Despesas Variáveis</span>, e preencher de acordo.
                  </h2>
                  <div className="img">
                    <img src={tutorial04} alt="imagem tutorial segunda tela despesas" />
                  </div>
                </li>
                <li>
                  <h2>Se errar, é só corrigir. E se lembrar de outras despesas e receitas, use os botões de <span>Voltar</span> e <span>Continuar</span> para chegar à tela desejada.
                  </h2>
                </li>
                <li>
                  <h2>Entendeu? Se quiser saber melhor como  funciona esse método, utilizado pelos planejadores financeiros, com o curso "<Link to={Globals.paths.courses + "/1182"}>
                    Mapeie sua vida financeira
                  </Link>". Nele você encontra informações detalhadas de como montar a sua fotografia financeira e como utilizá-la para se organizar melhor.
                  </h2>
                </li>

              </ol>
            </Grid>


          </DialogContent>
          <Divider />
          <MuiDialogActions className={'dialog-footer'}>
            <Button
              type={'full-solid'}
              icon="none"
              textAlign={'center'}
              onClick={this.closeTutorial}
            >
              sair
            </Button>
          </MuiDialogActions>
        </Dialog>
      </>

    )
  }

}

export default watch((Photograph), { user: '' });
