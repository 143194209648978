import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as MiniLogo } from '../../../../assets/logo/mini-logo.svg';
import * as Styled from './MobileMenu.styles';

function MobileMenu({ items, isClosed, toggleView, logHomeAction }) {
  const isMobile = useMediaQuery({ maxWidth: 920 });
  const [showNavBar, setShowNavBar] = useState(false);

  function handleScroll() {
    setShowNavBar(window.scrollY > 100);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isMobile) {
    return null;
  }

  return (
    <Styled.Menu isClosed={isClosed} showBar={showNavBar}>
      <div className="nav-bar">
        <MiniLogo className="nav-bar__logo" />
        {!isClosed ? (
          <CloseIcon
            className="nav-bar__action-btn nav-bar__action-btn--close"
            onClick={toggleView}
          />
        ) : (
          <MenuIcon
            className="nav-bar__action-btn nav-bar__action-btn--open"
            onClick={toggleView}
          />
        )}
      </div>
      <nav>
        <CloseIcon className="toggle-menu" onClick={toggleView} />
        {items.map((item) => {
          if (item.href) {
            return (
              <a onClick={logHomeAction.bind(this, { user_action: item.logActionId })}
                key={item.name} href={item.href} rel="noopener noreferrer">
                {item.name}
              </a>
            );
          }

          return (
            <button key={item.name} onClick={item.onClick} className={item.customClass?.join(', ')}>
              {item.name}
            </button>
          );
        })}
      </nav>
    </Styled.Menu>
  );
}

export default MobileMenu;
