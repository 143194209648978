import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { isAuthenticated, logout, login } from '../../services/auth';
import AlertErrors from '../../logged_in/components/alertErrors/alerts';
import api from '../../services/api';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { enumTypeUserStatuses } from '../../services/enums';
const Globals = require('../../Globals.json');

const MigrateFromIndex = (props) => {
  const [response, setResponse] = useState('');
  const [redirect, setRedirect] = useState('');
  const [alertError, setAlertError] = useState('');

  const { token } = props.match.params;

  console.log('alo');

  useEffect(() => {
    console.log('alo');

    const verify = async () => {
      //Força logout do usuário atual, caso logado.
      //logout();

      try {
        if (isAuthenticated()) {
          logout();
        }

        const res = await api.post(Globals.api.validate_user_integration_token, {
          token: decodeURIComponent(token),
        });

        console.log(res);

        if (res.data.status === 'success') {
          login(res.data.data.token);
          const response = await api.get(Globals.api.selfInfo);
          dispatchSet('user.profile', response.data);

          const status = response.data.type_users_status_id;

          setResponse('Dados migrados com sucesso!');

          switch (status) {
            case enumTypeUserStatuses.active: {
              setRedirect(Globals.paths.home);
              break;
            }
            case enumTypeUserStatuses.firstIndexMustBeFilled: {
              setRedirect(Globals.paths.home);
              break;
            }
            case enumTypeUserStatuses.forwardToBlog: {
              setRedirect(Globals.paths.forwardToBlog);
              break;
            }
            case enumTypeUserStatuses.notVerified: {
              setRedirect(Globals.paths.confirmEmail);
              break;
            }
            case enumTypeUserStatuses.validatingIndebtProfile: {
              setRedirect(Globals.paths.validatingIndebtProfile);
              break;
            }
            default: {
              break;
            }
          }
        }
        
      } catch (error) {
        setResponse('Link inválido ou expirado!');
        //setRedirect(Globals.paths.root);
        if (error?.response !== undefined) {
          setAlertError(error.response.status);
          setTimeout(() => {
            setAlertError('');
          }, 3000);
        }
      }
    };

    verify();
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <AlertErrors error={alertError} />
      <h1>{response && response}</h1>
      {redirect && <Redirect to={redirect} /> }
    </div>
  );
};

export default withRouter(MigrateFromIndex);
