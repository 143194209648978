export const enumQuestStatus = {
  available: 1,
  ongoing: 2,
  completed: 3,
  completedAndRedeemed: 4,
};

export const enumTypeModules = {
  quest: 20,
  customQuest: 30,
  questline: 34,
  miniQuiz: 33,
  quiz: 1,
  course: 2,
  article: 10,
  articleBlog: 31,
};

export const enumBehaviorQuestTypes = {
  tutorial: 0,
  profileCompleted: 1,
  articleById: 2,
  articleAmount: 3,
  assetById: 4,
  assetAmount: 5,
  courseById: 6,
  courseAmount: 7,
  classById: 8,
  classAmount: 9,
  utilityAmount: 10,
  utilityById: 11,
  quizById: 12,
  quizAmount: 13,
  selfDeclared: 14,
  calculateIndexAmount: 15,
};

export const enumTypeUserStatuses = {
  notVerified: 0,
  active: 1,
  inactive: 2,
  excluded: 3,
  firstIndexMustBeFilled: 4,
  validatingIndebtProfile: 6,
  forwardToBlog: 7,
};

let ContentSize;
(function (ContentSize) {
  ContentSize[(ContentSize['Small'] = 0)] = 'Small';
  ContentSize[(ContentSize['Medium'] = 1)] = 'Medium';
  ContentSize[(ContentSize['Big'] = 2)] = 'Big';
  ContentSize[(ContentSize['Auto'] = 3)] = 'Auto';
})(ContentSize || (ContentSize = {}));

let BackgroundType;
(function (BackgroundType) {
  BackgroundType[(BackgroundType['Video'] = 0)] = 'Video';
  BackgroundType[(BackgroundType['Image'] = 1)] = 'Image';
  BackgroundType[(BackgroundType['Color'] = 2)] = 'Color';
})(BackgroundType || (BackgroundType = {}));

export const enumContentSize = ContentSize;
export const enumBackgroundType = BackgroundType;

export const enumLogHomeAction = {
  homeCursoPublico: 1,
  homeVideo: 2,
  homeJornadaPersonalizada: 3,
  homeDescubraIndice: 4,
  homePontoPartida: 5,
  homeControleFinancas: 6,
  homeAlcancarObjetivos: 7,
  homeLiberteDividas: 8,
  linkConhecaFebraban: 9,
  linkNossosNumeros: 10,
  linkBlog: 11,
  linkFaq: 12,
  linkCadastrar: 13,
  linkEntrar: 14,
  linkHome: 15,
  cursoPublicoQueroMeInscrever: 16,
  nossosNumerosConhecaPlataforma: 17,
  faqDescubraIndice: 18,
  faqConhecaMais: 19,
  faqServicosPlataforma: 20,
  faqComeceJornada: 21,
  faqIncentivos: 22,
  faqPlataformaOfereceCadastre: 23,
  faqPlataformaOfereceBlog: 24,
  cursoPublicoConcluirAula1: 1001,
  cursoPublicoConcluirAula2: 1002,
  cursoPublicoConcluirAula3: 1003,
  cursoPublicoConcluirAula4: 1004,
  cursoPublicoConcluirAula5: 1005,
  cursoPublicoConcluirAula6: 1006,
  cursoPublicoConcluirAula7: 1007,
  cursoPublicoConcluirAula8: 1008,
  cursoPublicoClickAula1: 1101,
  cursoPublicoClickAula2: 1102,
  cursoPublicoClickAula3: 1103,
  cursoPublicoClickAula4: 1104,
  cursoPublicoClickAula5: 1105,
  cursoPublicoClickAula6: 1106,
  cursoPublicoClickAula7: 1107,
  cursoPublicoClickAula8: 1108,
  cursoPublicoCopyAula1: 1201,
  cursoPublicoCopyAula2: 1202,
  cursoPublicoCopyAula3: 1203,
  cursoPublicoCopyAula4: 1204,
  cursoPublicoCopyAula5: 1205,
  cursoPublicoCopyAula6: 1206,
  cursoPublicoCopyAula7: 1207,
  cursoPublicoCopyAula8: 1208
};
