import React from 'react';
import './IncentiveChart.scss';
import Tooltip from '@material-ui/core/Tooltip';

export default function IncentiveChart({ data, color, height }) {
  const tooltip = () => {
    return <React.Fragment>
      <div className='tooltip-container'>
        <div><span>Item do resgate: </span>{data.name}</div>
        <div><span>Pontuação resgatada: </span>{data.value}</div>
      </div>
    </React.Fragment>
  }

  return (
    <Tooltip title={tooltip()}>
      <div className='incentive-chart-container' style={{ backgroundColor: color, height: height + '%' }}>
        <div>{data.name}</div>
        <div>{data.value}</div>
      </div>
    </Tooltip>
  )
}