import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter} from 'react-router';
import "./certificate.scss"

import ImgCertificate from '../../../assets/image/certificado.jpg';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Typography, Grid, Modal, CircularProgress } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import "jspdf/dist/polyfills.es.js";
import moment from 'moment'

import Button from '../../components/button/Button';

import api from '../../../services/api';
import CertificateComponent from '../../components/certificate/CertificateComponent';

const Globals = require("../../../Globals.json");

class Certificate extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    certificates: [],
    isLoading: true,
    certificate: null,
    certificateName: "Gestão Financeira para Iniciantes",
    certificateDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    certificateThumbnail: "https://paginadoestado.com.br/wp-content/uploads/infocoweb/2020/05/10/thumbnail-for-63637.jpg",
    courseName: "",
    message: 'Preparando o download do seu PDF...',
    alertError: '',
    isLoadingPdf: false,
  }

  
  handlePrint() {
    window.print();
  }

  async loadCourseData() {

    this.setState({ isLoading: true });
    try {
      const postData = {
        "container_id": this.props.courseId,
      };

      const response = await api.post(Globals.api.getModules, postData);

      console.log(response);

      const { container, children } = response.data;
      const certificate = children.find(e => e.type_module_id === Globals.types.certificate);

      if (certificate) {
        this.setState({
          certificate
        });
      }

      this.setState({
        courseName: container.name,
        isLoading: false,
      });

    } catch (error) {
      console.error(error.response.status)
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  componentDidMount() {
    this.loadCourseData();
  }

  // abre pagina com parametro pdf
  gerarPdf = () => {

    this.setState({ isLoadingPdf: true });

    setTimeout(async () => {

      const img = document.getElementById('certificate-image-' + this.props.courseId);
      if (img) {
        const pdf = new jsPDF("landscape");

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        pdf.addImage(img, 'jpeg', 0, 0, width, height);

        await pdf.save("certificado.pdf");

        setTimeout(() => {
          this.setState({ isLoadingPdf: false });
        }, 500);
      }
    }, 500);


    /*setTimeout(() => {
      html2canvas(document.querySelector("#divToPrint")).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF("landscape");
        pdf.addImage(imgData, 'PNG', 0, 0, 295, 215);
        pdf.save("certificado.pdf");

        if (canvas) {
          this.setState({ message: "PDF baixado com sucesso!" })
        }
      });
      this.setState({ message: "Baixando pdf, por favor aguarde..." })
    }, 1500);*/
  }

  render() {
    const { isPDF } = this.state
    const { match, location, history } = this.props;
    let info = {}
    info = this.props.user.profile.desempenho

    console.log(this.props.user.profile);

    if (this.state.isLoading) {
      return (<div></div>)
    }

    if (!this.state.certificate) {
      return (<div>Não há um certificado disponível para este curso.</div>)
    }
    let id = this.state.certificate.id

    return (
      <div className="certificate-content-container">
        <Breadcrumbs aria-label="breadcrumb" className="certificate_BreadCrumb">
          <Link color="inherit" to={Globals.paths.courses} className="certificate_BreadCrumbLink">
            Cursos
          </Link>
          <Link color="inherit" to={Globals.paths.courseDetail.replace(":courseId", this.props.courseId)} className="certificate_BreadCrumbLink">
            {this.state.courseName}
          </Link>
          <Typography color="textPrimary" className="certificate_BreadCrumbLabel">Certificado</Typography>
        </Breadcrumbs>

        <Helmet>
          <title>Certificado | Meu bolso em dia</title>
        </Helmet>
        <AlertErrors error={this.state.alertError} />

        <Grid container className="certificate-container p20">
          <Grid item container xs={12} className="containerGrid">
            <Grid item container xs={12} justify="flex-end">
              {!isPDF && (
                <Button type="darkBlue" width="130px" onClick={() => this.gerarPdf()}>GERAR PDF</Button>
              )}
            </Grid>

            <Grid item container justify="center">
              <CertificateComponent courseId={this.props.courseId}></CertificateComponent>
            </Grid>
          </Grid>
        </Grid>

        {this.state.isLoadingPdf && (
          <Modal open={true} className="expanded_modal">
            <CircularProgress />
          </Modal>
        )}

        <Grid container className="certificate-container p20">
          <Grid item container xs={12} className="containerGrid">
            <Grid item container xs={12} justify="flex-end">  
              <Button type="darkBlue" width="130px" icon="none" height="36px" onClick={() => history.push(Globals.paths.courses)}>VOLTAR</Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default watch(Certificate, { user: '' });
