import React, { useEffect, useState } from 'react';
import '../../../../../../backoffice.scss';
import ModuleListing from '../../../components/ModuleListing/ModuleListing';
import api from "../../../../../../services/api";

const Globals = require("../../../../../../Globals.json");

const type = {
  fixed: 'Fixa',
  variable: 'Variável'
}

export default function ExpensesMatrix() {

  const [expense, setexpenses] = useState([]);

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData() {
    await api.get(Globals.app_api.expenseMatrices)
      .then((res) => {
        setexpenses(parserToModuleListing(res.data.content.expense_groups_list))
      })
  }

  function parserToModuleListing(response) {
    return response.map((item) => {
      const isActive = item.is_active ? 1 : 0
      
      return {
        id: item.id,
        name: item.name,
        description: type[item.type],
        type_module_status_id: isActive,
      }
    })
  }

  return (
    <ModuleListing
      title="Grupo de despesas"
      buttonLabel="Novo grupo"
      filterPlaceHolder="Filtre por Nome"
      cellNameLabel="Nome"
      cellDescriptionLabel="Tipo"
      fullUrl={Globals.paths.backofficeExpenseMatrixEdit}
      urlReplacePattern=":expense_id"
      customList={expense}
      hideThumbnail />
  )

}
