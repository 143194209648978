import { Grid } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import Logo from '../../../assets/logo/mini-logo.svg';
import MenuProfile from './menuProfile';
import './styles/menuTop.scss';

const Globals = require('../../../Globals.json');

class MenuTop extends React.Component {
  state = {
    userLevel: 0,
    prevScrollpos: window.pageYOffset,
    visible: false,
  };

  componentDidMount() {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(oldProps) {
    if (this.props.user !== oldProps.user) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 304) {
      this.setState({ visible: true });
    }
    if (currentScrollPos < 304) {
      this.setState({ visible: false });
    }

    this.setState({
      prevScrollpos: currentScrollPos,
    });
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        className={`navbar ${this.state.visible && 'fixed'} menu-top-principal`}
      >
        {this.state.visible === true && (
          <img src={Logo} alt="Meu bolso em dia" className="menu_logo" />
        )}

        <NavLink
          to={Globals.paths.home}
          activeClassName={`menuTop_activeMenuItem ${this.state.visible && 'fixed'}`}
          className={`menuTop_menuItem ${this.state.visible && 'fixed'}`}
        >
          SEU ESPAÇO
        </NavLink>

        {this.state.userLevel >= 2 ? (
          <NavLink
            to={Globals.paths.courses}
            activeClassName={`menuTop_activeMenuItem ${this.state.visible && 'fixed'}`}
            className={`menuTop_menuItem ${this.state.visible && 'fixed'} home-menu-cursos`}
          >
            CURSOS E CONTEÚDOS
          </NavLink>
        ) : (
          <div className={`menuTop_menuItemD ${this.state.visible && 'fixed'} home-menu-cursos`}>
            <span>CURSOS E CONTEÚDOS</span>
          </div>
        )}
        {this.state.userLevel >= 2 ? (
          <NavLink
            to={Globals.paths.quiz}
            activeClassName={`menuTop_activeMenuItem ${this.state.visible && 'fixed'}`}
            className={`menuTop_menuItem ${this.state.visible && 'fixed'} home-menu-quiz`}
          >
            QUIZ
          </NavLink>
        ) : (
          <div className={`menuTop_menuItemD ${this.state.visible && 'fixed'} home-menu-quiz`}>
            <span>QUIZ</span>
          </div>
        )}
        {this.state.userLevel >= 0 ? (
          <NavLink
            to={Globals.paths.tools}
            activeClassName={`menuTop_activeMenuItem this.state.visible && "fixed"}`}
            className={`menuTop_menuItem ${this.state.visible && 'fixed'} home-menu-ferramentas`}
          >
            FERRAMENTAS FINANCEIRAS
          </NavLink>
        ) : (
          <div
            className={`menuTop_menuItemD ${this.state.visible && 'fixed'} home-menu-ferramentas`}
          >
            <span>FERRAMENTAS FINANCEIRAS</span>
          </div>
        )}
        <NavLink
          to={Globals.paths.missions.replace(':type?', '')}
          activeClassName={`menuTop_activeMenuItem ${this.state.visible && 'fixed'}`}
          className={`menuTop_menuItem ${this.state.visible && 'fixed'}`}
        >
          MISSÕES
        </NavLink>

        {this.state.userLevel >= 2 ? (
          <NavLink
            to={Globals.paths.progresso}
            activeClassName={`menuTop_activeMenuItem ${this.state.visible && 'fixed'}`}
            className={`menuTop_menuItem ${this.state.visible && 'fixed'}`}
          >
            PROGRESSO
          </NavLink>
        ) : (
          <div className={`menuTop_menuItemD ${this.state.visible && 'fixed'} home-menu-progresso`}>
            <span>PROGRESSO</span>
          </div>
        )}
        
         {this.state.userLevel >= 2 ? (
          <NavLink
            to={{
              pathname: Globals.paths.incentive,
              state: { fromIncentive: true }
            }}
            activeClassName={`menuTop_activeMenuItem this.state.visible && "fixed"}`}
            className={`menuTop_menuItem ${this.state.visible && 'fixed'} home-menu-ferramentas`}
          >
            INCENTIVO
          </NavLink>
        ) : (
          <div
            className={`menuTop_menuItemD ${this.state.visible && 'fixed'} home-menu-ferramentas`}
          >
            <span>INCENTIVO</span>
          </div>
        )}

        <a
          target="_blank"
          href="https://meubolsoemdia.com.br/"
          rel="noopener noreferrer"
          className={`menuTop_menuItem ${this.state.visible && 'none'}`}
        >
          BLOG
        </a>

        <div className="menuTop_profile">
          {this.state.visible === true && (
            <MenuProfile
              coins={this.props.coins}
              lvl={this.props.lvl}
              isfb={this.props.user.profile.isfb}
              handleContactForm={this.props.handleContactForm}
            />
          )}
        </div>
      </Grid>
    );
  }
}

export default watch(MenuTop, { user: '' });
