import { dispatch } from 'redux-easy/lib/redux-easy';
import { isAuthenticated } from '../../../../services/auth';
import { enumTypeUserStatuses } from '../../../../services/enums';
import { useHistory } from "react-router";
import { enumLogHomeAction } from '../../../../services/enums'

export default function buildMenu({
  history,
  user,
  openRegisterDialog,
  openLoginDialog,
  openValidatingIndebtProfile,
  openForwardToBlog,
  logHomeAction,
}) {
  const myMenu = [{
    name: 'Conheça a Febraban',
    logActionId: enumLogHomeAction.linkConhecaFebraban,
    href: 'https://portal.febraban.org.br/',
  },
  {
    name: "Nossos Números",
    onClick: () => {
      logHomeAction({ user_action: enumLogHomeAction.linkNossosNumeros });
      history.push('/nossos-numeros');
    },
  },
  {
    name: 'Blog',
    logActionId: enumLogHomeAction.linkBlog,
    href: 'https://meubolsoemdia.com.br/',
  }];
  myMenu.push({
    name: "FAQ",
    onClick: () => {
      logHomeAction({ user_action: enumLogHomeAction.linkFaq });
      history.push('/faq');
    },
  });
  if (!isAuthenticated()) {
    myMenu.push({
      name: 'Cadastrar',
      onClick: () => {
        logHomeAction({ user_action: enumLogHomeAction.linkCadastrar });
        openRegisterDialog()
      },
    });
    myMenu.push({
      name: 'Entrar',
      onClick: () => {
        logHomeAction({ user_action: enumLogHomeAction.linkEntrar });
        openLoginDialog()
      },
      customClass: ['button'],
    });
  } else {
    if (user.profile?.type_users_status_id) {
      const userProfile = user.profile;

      if (userProfile.type_users_status_id === enumTypeUserStatuses.validatingIndebtProfile) {
        openValidatingIndebtProfile();
      }

      myMenu.push({
        name:
          userProfile.type_users_status_id !== enumTypeUserStatuses.active
            ? 'Olá, ' + userProfile.username
            : 'Acessar meu espaço',
        onClick: () => {
          if (userProfile.type_users_status_id === enumTypeUserStatuses.forwardToBlog) {
            openForwardToBlog();
          } else if (
            userProfile.type_users_status_id === enumTypeUserStatuses.active ||
            userProfile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled
          ) {
            history.push('/home');
          }
        },
        customClass: ['button'],
      });
    } else {
      dispatch('updateUserProfile');
    }
  }

  return myMenu;
}
