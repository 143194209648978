import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import '../../../../../../backoffice.scss';
import Dialogs from '../../../../../../services/dialogs';


import api from "../../../../../../services/api";
import Button from '../../../../button/Button';

const Globals = require("../../../../../../Globals.json");

const TYPE_CONST = {
  fixed: 'fixed',
  variable: 'variable'
}
  
const EditExpenseMatrix = () => {
  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [id, setId] = useState('');

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');

  const [expenseType, setExpenseType] = useState(TYPE_CONST.fixed);
  const [errorExpenseType, setErrorExpenseType] = useState(false);
  const [helperTextExpenseType, setHelperTextExpenseType] = useState('');

  const [statusToggle, setStatusToggle] = useState(true);

  const [editMode, setEditMode] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const loadData = async (id) => {
    try {
      await api.get(Globals.app_api.expenseDetail.replace(':expense_id', id))
        .then((res) => {
          const data = res.data.content.expense_group
          setData(data)
          setName(data.name)
          setExpenseType(TYPE_CONST[data.type])
           setStatusToggle(data.is_active)
          setId(data.id)
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false)
  }


  useEffect(() => {
    setIsLoading(true);
    if (params.expense_id !== 'new') {
      loadData(params.expense_id)
      setEditMode(true)
    }
    !editMode && setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setHelperTextName('');
    setErrorName(false);
  };

  const success = () => {
    Dialogs.show(
      <div>SUCESSO</div>,
      'A despesa foi salva com sucesso',
      [
        {
          label: "OK",
          onClick: () => history.push(Globals.paths.backofficeExpenseMatrices),
        },
      ]
    )
  }

  const saveData = async (postData) => {
    setIsLoading(true);
     await api.post(Globals.app_api.expenseMatrices, postData)
      .then((res) => {
        success()
      })
      .catch((e) => {
        console.log(e)
      }) 
  };

  const editData = async (postData) => {

    await api.put(`${Globals.app_api.expenseDetail.replace(':expense_id', postData.id)}`, postData)
      .then((res) => {
        success()
      })
      .catch((e) => {
        console.log(e)
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = false;

    const postData = {
      id: id,
      name: name,
      is_active: statusToggle,
      type: expenseType,
    }

    if (!postData.name || postData.name.trim().length === 0) {
      setHelperTextName('Informe um título');
      setErrorName(true);
      error = true;
    }

    if (expenseType != TYPE_CONST.fixed && expenseType != TYPE_CONST.variable) {
      setHelperTextExpenseType('Selecione uma opção.');
      setErrorExpenseType(true);
      error = true;
    }

    if (!error) {
      editMode ? editData(postData) : saveData(postData)
    }

  }

  const handleToggleStatus = () => {
    statusToggle ? setStatusToggle(false) : setStatusToggle(true)
  }

  const handleRadioChange = (event) => {
    setExpenseType(parseInt(event.target.value));
    setHelperTextExpenseType('');
    setErrorExpenseType(false);
  };

  const handleBack = (event) => {
    event.preventDefault();
    if (editMode) {

      const postData = {
        id: id,
        name: name,
        is_active: statusToggle,
        type: expenseType
      }

      const oldData = {
        id: data.id,
        name: data.name,
        is_active: data.is_active,
        type: data.type,
      }
      if (JSON.stringify(postData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações nessa despesa, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeExpenseMatrices),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeExpenseMatrices)
      }

    } else {
      history.push(Globals.paths.backofficeExpenseMatrices)
    }
  }


  return (
    <>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode ? data.description : 'Nova Matriz de Despesas'}</span>
          </div>

          <form className="form-matrix" onSubmit={handleSubmit}>

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Título</FormLabel>


              <TextField
                value={name}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Título"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => handleNameChange(e)}
              />
              <FormHelperText>{helperTextName}</FormHelperText>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"  
                  error={errorExpenseType}
                  variant="standard"
                >
                  <FormLabel component="legend">Tipo de despesa</FormLabel>
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                    value={expenseType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel value={TYPE_CONST.fixed} control={<Radio />} label="Despesa fixa" />
                    <FormControlLabel value={TYPE_CONST.variable} control={<Radio />} label="Despesa variável" />
                  </RadioGroup>
                  <FormHelperText>{helperTextExpenseType}</FormHelperText>

                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={statusToggle}
                          onChange={handleToggleStatus}
                        />
                      }
                      label={'Ativa'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default EditExpenseMatrix;
