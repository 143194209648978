import axios from "axios";
import { getToken} from "./auth";
export const TOKEN_KEY = "@fbb-Token";

const api = axios.create({
  // baseURL: "http://localhost:3333/"
  // baseURL: "https://fbb-api.hyst.dev/"
  // baseURL: "https://pefpi.meubolsoemdia.com.br/"
  // baseURL: "https://pefapipi.meubolsoemdia.com.br"
  baseURL: `${process.env.REACT_APP_API}`
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.toJSON().message === 'Network Error') {
    if (error?.response) {
      error.response.status = "Não foi possível se conectar ao servidor, por favor, tente novamente mais tarde.";
    }
    else {
      error.response = { status: "Não foi possível se conectar ao servidor, por favor, tente novamente mais tarde." };
    }
  }

  return Promise.reject(error);
});

export default api;