import React from 'react';
import './styles/courseRecommended.scss';

import { Hidden, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';
import Button from '../button/Button';

import SwipeableViews from "react-swipeable-views";
import Pagination from "../../components/pagination/Pagination";

import { Skeleton } from '@material-ui/lab';
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const Globals = require("../../../Globals.json");

const customStyle = {
  root: {
    // padding: '0px 270px 0px 0px',
    // width: "402px"
  },
  slideContainer: {
    // padding: '0px 140px 0px 0px',
  },
};

class CourseRecommended extends React.Component {
  state = {
    isLoading: false,
    courses: [],
    gridCols: 3,
    alertError: '',
    index: 0,
  }

  async loadData () {
    try {
      this.setState({ isLoading: true });

      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";
      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      this.setState({
        courses: response.data.sort((a, b) => a.progress < b.progress ? -1 : 1).slice(0, 3),
        isLoading: false
      });

    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  addFavorites = (id) => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: id,
      type_module_id: 2,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error)
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })

  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  componentDidMount () {
    this.loadData();
  }

  goToCourse = (courseId) => {
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", courseId));
  }

  render () {

    return (
      <>
        {this.state.isLoading ?
          <>
            <div className="course_reco_card_recomendados" style={{ background: "none", border: "none" }} >
              <Box pt={0.5}>
                <Skeleton variant="rect" width={"300px"} height={"280px"} />
                <Skeleton />
              </Box>
            </div>
            <div className="course_reco_card_recomendados" style={{ background: "none", border: "none" }} >
              <Box pt={0.5}>
                <Skeleton variant="rect" width={"300px"} height={"280px"} />
                <Skeleton />
              </Box>
            </div>
            <div className="course_reco_card_recomendados" style={{ background: "none", border: "none" }} >
              <Box pt={0.5}>
                <Skeleton variant="rect" width={"300px"} height={"280px"} />
                <Skeleton />
              </Box>
            </div>
          </> :
          <>
            <AlertErrors error={this.state.alertError} noneWidth />
            <Hidden xsDown>
              {this.state.courses.map((item) => (
                <div className="course_reco_card_recomendados" key={item.id}>
                  <div className="course_reco_background_img"
                    style={{ backgroundImage: `url(${item.thumbnail})` }}>
                  </div>
                  {getCoinsFromRewards(item.data.rewards) > 0 &&
                    <div className="course_reco_border_back">
                      <div className="course_reco_box_back">
                        {getCoinsTextFromRewards(item.data.rewards)}
                      </div>
                    </div>
                  }

                  <div className="course_reco_container_description">
                    <span className="course_reco_text_obj">
                      {/* {this.props.text_description} */}
                      {item.name}
                    </span>
                  </div>

                  <div className="course_reco_contentDetails">
                    {/* <AccessTime className="course_reco_icons" />
                    <span className="course_reco_textDetails">{this.props.hour}</span>
                    <SignalCellularAlt className="course_reco_icons" />
                    <span className="course_reco_textDetails">{this.props.nivel}</span> */}
                    {/* {("relevance" in item) &&
                      <span className="course_reco_textDetailsRelevance">R: {item.relevance}</span>
                    } */}
                  </div>
                  <div className="course_reco_positionBtn">
                    <Button icon="none" onClick={() => this.goToCourse(item.id)}>
                      {item.user_module_data.finishedCourseAt ? 'Ver novamente' :
                        item.user_module_data.startedAt ? "Continuar" : "Começar"}
                    </Button>
                  </div>
                </div>
              ))}
            </Hidden>
            <Hidden smUp>
              {this.state.courses.map((item, index) => (
                <div key={item.id}>
                  <SwipeableViews style={customStyle.root} onChangeIndex={this.handleChangeIndex} slideStyle={customStyle.slideContainer}>

                    <div className="course_reco_card_recomendados">
                      <div>
                        {/* style={{ backgroundImage: `url(${item.thumbnail})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} */}
                        <img src={item.thumbnail} alt="Imagem do Curso" className="course_reco_background_img" />
                        {/* <div className="button_save">
                          <button className="course_reco_button_icon">
                            <TurnedInNot />
                          </button>
                        </div> */}
                      </div>
                      <div className="course_reco_contentCollumn">
                        <div className="course_reco_container_description">
                          <p className="course_reco_text_obj">
                            {item.name}
                          </p>
                        </div>

                        <div className="course_reco_positionBtn">
                          <Button icon="none" onClick={() => this.goToCourse(item.id)}>
                            {item.user_module_data.finishedCourseAt ? 'Ver novamente' :
                              item.user_module_data.startedAt ? "Continuar" : "Começar"}
                          </Button>
                        </div>
                      </div>

                    </div>
                  </SwipeableViews>

                  <Pagination
                    dots={this.state.courses.length}
                    index={index}
                    onChangeIndex={this.handleChangeIndex}
                  />
                </div>
              ))}
            </Hidden>
          </>
        }
      </>
    )
  }
}

export default CourseRecommended;