import React, { PureComponent } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  LabelList
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    let offset;
    let fireOffset;
    const { x, y, width, height, value, length, labelPosition } = this.props;

    if (!labelPosition) {
      fireOffset = value?.toString().length < 8;
    } else {
      fireOffset = value?.toString().length < 5;
    }

    offset = fireOffset ? -45 : 5;

    if (length === 1 && value?.toString().length <= 3) {
      offset = -10
    }

    return (
      <text x={x + width} y={y + height / 2} fill={"#fff"} textAnchor="end" fontSize={10}>
        {value.toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
      </text>
    );
  }
};

export default function BarChartVerticalDashboard({
  data,
  nameAndDataKey,
  ylabel,
  showLegend,
  yWidth = 60,
  chartWidth,
  width = null,
  chartHeigth = null,
  barSize = null,
  labelInside = false,
  setRegionSelected = null,
  dataToEvaluation = null,
  setDataToEvaliation = null,
  chartMargin = { left: 20, right: 20 } }) {

  const handleClick = (data) => {
    if (dataToEvaluation === 'region') {
      setRegionSelected(data.name);
      setDataToEvaliation('state');
    }
  };

  const calculateHeight = () => {
    if (data.length <= 3) {
      return 190;
    } else {
      return 300;
    }
  };

  const renderLabel = (props) => {
    return props.toLocaleString('pt-BR', { maximumFractionDigits: 2 });
  };

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <BarChart
          data={data}
          width={chartWidth && width}
          height={chartHeigth || calculateHeight()}
          margin={chartMargin}
          maxBarSize={50}
          layout="vertical"
        >
          {showLegend && (<Legend verticalAlign="top" height={36} />)}
          <XAxis hide axisLine={false} type="number" />
          <YAxis
            dataKey={ylabel}
            type="category"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#fff', fontSize: 10 }}
            interval={0}
            width={yWidth}
          />
          {nameAndDataKey.map((item, index) =>
            <Bar key={index}
              name={item.name}
              dataKey={item.datakey}
              barSize={barSize}
              fill={item.fillColor || '#05CCAE'}
              onClick={(data) => { handleClick(data) }}>
              <LabelList
                dataKey={item.datakey}
                position={labelInside ? "insideRight" : "right"}
                style={{ fill: "#fff", fontSize: "10px" }}
                formatter={renderLabel} />
            </Bar>
          )}
        </BarChart>}
    </>
  )
}