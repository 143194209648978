import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  FormControl,
  Snackbar,
  Select,
  InputLabel,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseRounded } from '@material-ui/icons';
import '../../../../backoffice.scss';
import './tenant.scss';
import TabPanel from '../../../../shared/components/TabPanel';
import PaginatedTable from '../components/PaginatedTable/PaginatedTable';
import Dialogs from '../../../../services/dialogs';

import api from "../../../../services/api";
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");


const Tenants = () => {

  const fieldsTable = [{ title: 'Nome', fieldName: 'name' }, { title: 'Status', fieldName: 'active', type: 'active' }, {title: 'Usuário pode se relacionar', fieldName: 'user_can_relate', type: 'canRelate'}, {title: 'Logotipo', fieldName: 'logo_url', type: 'link'},  { title: 'Data de criação', fieldName: 'created_at', type: 'date' }]

  const [isLoading, setIsLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [activeOption, setActiveOption] = useState(2);

  let history = useHistory();

  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const navigateTo = (path) => {
    history.push(path);
  }

  const navigateToEdit = (path) => {
    history.push(path, {
      editMode: true,
    });
  }

  const getTenants = async (page = 1, clear = false) => {
    let query = ''
    if(!clear) {
      if (activeOption !== 2) {
        query = (`&active=${activeOption}&name=${filterBy}`)
      } else {
        query = (`&name=${filterBy}`)
      }
    }
   
    try {
      await api.get(`${Globals.api.backofficeTenants}?page=${page}&per_page=${10}${query}`)
        .then((res) => {
          setTenants(res.data)
        })
    } catch (error) {
      console.log('erro get tenants')
    }

  }

  const loadData = async () => {
    setIsLoading(true)
    await getTenants();
    setIsLoading(false);
  }

  const clear = () => {
    setFilterBy('');
    setActiveOption(2);
    getTenants( 1, true)
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {

    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption]);


  const handleSearch = () => {
   loadData()
  }

  const handleChangeStatus = (event) => {
    setActiveOption(parseInt(event.target.value));
  }

  const actions = [{ name: 'Visualizar', onClick: navigateTo, path: Globals.paths.backofficeTenantDetails.replace(':tenantId', '') }, { name: 'Editar', onClick: navigateToEdit, path: Globals.paths.backofficeTenantDetails.replace(':tenantId', '') }];

  return (
    <div className='tenants-list'>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Instituições Financeiras</span>
          </div>
          <Grid item container >
            <div className="filter-container-tenants">
              Buscar
              <div className="searchbar">
                <div className="filter-elements">
                  <input
                    className="filter-element"
                    placeholder="Digite um nome"
                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                  />
                  <button
                    className="filter-element-clear"
                    onClick={() => clear()}
                  >
                    <CloseRounded />
                  </button>


                  {/* <Grid item xs={12} sm={6} lg={3}>
                    <select value={activeOption} onChange={(e) => setActiveOption(e.target.value)}>
                      <option value={2}>Todos</option>
                      <option value={1}>Ativo</option>
                      <option value={0}>Inativo</option>

                    </select>
                  </Grid> */}
                  <button
                    className="filter-element-search"
                    onClick={() => handleSearch()}
                  >
                    {'Buscar'}
                  </button>
                </div>

                <div className="button-right-add">
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    to={Globals.paths.backofficeTenantDetails.replace(':tenantId', 'novo')}
                  >
                    {'Cadastrar Instituição'}
                  </Button>
                </div>
              </div>
              <div className='radio-button-container'>
                <div className='status-label'>Status: </div>
                <FormControl>
                 
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={activeOption}
                    onChange={handleChangeStatus}
                  >
                    
                    <FormControlLabel value={2} control={<Radio />} label="Todos" />
                    <FormControlLabel value={1} control={<Radio />} label="Ativo" />
                    <FormControlLabel value={0} control={<Radio />} label="Inativo" />

                  </RadioGroup>
                </FormControl>
              </div>
            </div>



            <PaginatedTable titleFields={fieldsTable} data={tenants} isLoading={isLoading} pagNavigation={getTenants} actions={actions} />

          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Tenants;
