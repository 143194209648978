import React from 'react';
import './styles.scss';

import Button from '../../../../components/button/Button';
import DialogUser from './DialogUser';
import Trash from '../../../../../assets/icon/delete.png';

import { FormControl, Grid, MenuItem, Select, TextField, Tooltip, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Conteudo from '../Conteudo/Conteudo';
import DeleteUserModal from '../../components/DeleteUserModal';
import MailResetUser from '../../components/MailResetUser';
import Perfil from '../Perfil/Perfil';
import Privacity from '../Privacity/Privacity';

import Icognita from '../../../../../assets/IcognitaGray.svg';

import api from '../../../../../services/api';
import { Alert } from '@material-ui/lab';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { getState } from 'redux-easy/lib/redux-easy';

const Globals = require("../../../../../Globals.json");

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF',
    color: '#202020',
    maxWidth: 220,
    fontSize: "14px",
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class EditUsers extends React.Component {

  state = {
    menuActive: 0,
    userActive: '',
    username: '',
    userPhone: '',
    userCpf: '',
    userEmail: '',
    userAddress: '',
    userCep: '',
    userState: '',
    userCity: '',
    userGender: '',
    userBirthdate: '',
    userIncome: '',
    userEducation: '',
    UserEst: '',
    userPass: '',
    getRoles: [],
    getUsers: [],
    userRepeatPass: '',
    open: false,
    openStatusModal: false,
    status: [],
    data: {},
    roles: [],
    users: [],
    account_status: '',
    desempenho: [],
    failed: false,
    success: false,
  }

  getSelection(id) {
    this.setState({ menuActive: id })
  }

  getRoles = () => {
    api.get(Globals.api.getRoles, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    })
      .then(response => {
        this.setState({ roles: response?.data?.data })
      }).catch(error => {
      })
  }

  getUsers = () => {
    api.get(Globals.api.getGroup, {
      params: {
        "per_page": 1000,
      }
    })
      .then(response => {
        this.setState({ users: response?.data?.data })
      }).catch(error => {
      })
  }

  getStatus = () => {
    api.get(Globals.api.getStatus, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    })
      .then(response => {
        this.setState({ status: response?.data?.data })
      }).catch(error => {
      })
  }

  handleSave = () => {
    const userId = this.props.match.params.userId
    api.put(Globals.api.usersPage + `/${userId}`, {
      "Roles": this.state.getRoles,
      "TypeUsersGroup": this.state.getUsers,
      "TypeUsersStatus": this.state.userActive
    })
      .then(response => {
        this.setState({ success: true, successMessage: 'Usuário alterado com sucesso!' });
        setTimeout(() => {
          this.setState({ success: false, successMessage: '' });
        }, 3000)
      }).catch(error => {
        this.setState({ failed: true, errorMessage: 'Erro ao alterar os dados!' });
        setTimeout(() => {
          this.setState({ failed: false, errorMessage: '' });
        }, 3000)
      })
  }

  handleCancel() {
    
  }

  loadData = async () => {
    const userId = this.props.match.params.userId
    await api.get(Globals.api.usersPage + `/${userId}`)
      .then(response => {
        const data = response.data

        this.setState({
          username: data.username,
          userEmail: data.email,
          userActive: typeof data.TypeUsersStatus === "undefined" ? '' : data.TypeUsersStatus.id,
          userPhone: typeof data.CadUserPhone[0] === "undefined" ? '' : data.CadUserPhone[0].number,
          userCpf: typeof data.CadUserDetail[0] === "undefined" ? '' : data.CadUserDetail[0].cpf_cnpj,
          userImage: typeof data.CadUserDetail[0] === "undefined" ? '' : data.CadUserDetail[0].profile_image,
          userAddress: !data.CadUserAddressesOne ? '' : data.CadUserAddressesOne.landmark,
          userCep: !data.CadUserAddressesOne ? '' : data.CadUserAddressesOne.zip_code,
          userState: this.getState(data.CadUserAddressesOne),
          userCity: this.getCity(data.CadUserAddressesOne),
          userGender: typeof data.CadUserDetail[0] === "undefined" ? '' : data.CadUserDetail[0].type_gender_id,
          userBirthdate: typeof data.CadUserDetail[0] === "undefined" || !data.CadUserDetail[0].birth_date ? '' : data.CadUserDetail[0].birth_date.replace('T00:00:00.000Z', 'T03:00:00.000Z'),
          userEducation: this.getEducation(data.CadUserDetail),
          userIncome: this.getIncome(data.CadUserDetail),
          getRoles: typeof data.Roles[0] === "undefined" ? [] : data.Roles.map((item) => item.id),
          getUsers: typeof data.TypeUsersGroup[0] === "undefined" ? [] : data.TypeUsersGroup.map((item) => item.id),
        })
      }).catch(err => {
        console.log(err)
      })
  }

  getState(data){
    if(data && data.CadIbgeState){
      return data.CadIbgeState.state;
    }

    return "";
  }

  getCity(data){
    if(data && data.CadIbgeCounty){
      return data.CadIbgeCounty.county;
    }

    return "";
  }

  getIncome(data){
    if(data[0]){
      if(data[0].TypeIncome){
        return data[0].TypeIncome.description;
      }
    }
    return "";
  }

  getEducation(data){
    if(data[0]){
      if(data[0].TypeEducation){
        return data[0].TypeEducation.description;
      }
    }
    return "";
  }

  formatDate(date){
    if(date)
      return moment(date).format('DD/MM/YYYY');
    return "";
  }

  getGenderText(typeGender) {

    switch (typeGender) {
      case 1:
        return "Masculino";
      case 2:
        return "Feminino";
      case 3:
        return "Prefiro não informar";
      default:
        return "";
    }
  }

  componentDidMount() {
    this.getRoles()
    this.getUsers()
    this.getStatus()
    this.loadData()
  }

  InputMaskCep(props) {
    const { inputRef, ...other } = props;

    const cepMaskFormat = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    return (

      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={cepMaskFormat}
        placeholderChar={'_'}
        guide
        showMask
      />
    );
  }

  render() {

    return (
      <>
        {this.state.success &&
          <Alert className="alertEditUser" severity="success">{this.state.successMessage}</Alert>
        }
        {this.state.failed &&
          <Alert className="alertEditUser" severity="error">{this.state.errorMessage}</Alert>
        }

        <DialogUser open={this.state.openStatusModal} onClose={() => this.setState({ openStatusModal: false })} />

        <div className="containerEditUser">
          <div className="menuEditUser">
            <button type="button" onClick={() => this.getSelection(0)}
              className={`mission_list_btnActive1 ${this.state.menuActive === 0 && "active"}`}
            >Dados Cadastrais
            </button>

            <button type="button" onClick={() => this.getSelection(1)}
              className={`mission_list_btnActive1 ${this.state.menuActive === 1 && "active"}`}
            >Perfil
            </button>

            {/* <button type="button" onClick={() => this.getSelection(2)}
              className={`mission_list_btnActive1 ${this.state.menuActive === 2 && "active"}`}
            >Navegação
            </button> */}

            <button type="button" onClick={() => this.getSelection(3)}
              className={`mission_list_btnActive1 ${this.state.menuActive === 3 && "active"}`}
            >Conteúdo
            </button>

            <button type="button" onClick={() => this.getSelection(4)}
              className={`mission_list_btnActive1 ${this.state.menuActive === 4 && "active"}`}
            >Privacidade
            </button>
          </div>

          <div className="formEditBo">
            <DeleteUserModal
              open={this.state.open}
              userId={this.props.match.params.userId}
              onClose={() => this.setState({ open: false })}
            />
            <MailResetUser
              open={this.state.openMail}
              userId={this.props.match.params.userId}
              onClose={() => this.setState({ openMail: false })}
            />
            <div className="deleteHeader">
              <img src={Trash} alt="Delete" onClick={() => this.setState({ open: true })} />
            </div>

            {this.state.menuActive === 0 &&
              <>

                <div className="editHeaderForm">
                  <div className="displayName">
                    <strong>Dados Cadastrais:</strong>
                    <span>{this.state.username}</span>
                  </div>

                  <div className="sideBo">
                    <div className="activeHeader">
                      <label>Status</label>
                      <FormControl variant="outlined" style={{ minWidth: 120, heigth: 30 }}>
                        <Select
                          value={this.state.userActive}
                          onChange={e => this.setState({ userActive: e.target.value, openStatusModal: true })}
                        >
                          {this.state.status.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="editContent">

                  <div className="editContainer1">

                    <div className="editSideItem1Img">
                      {this.state.userImage &&
                        <img src={this.state.userImage} alt="Foto do usuário" />
                      }
                    </div>

                    <div className="editSideItem1">
                      Perfil de Acesso
                      <Select
                        multiple
                        value={this.state.getRoles}
                        onChange={(e) => this.setState({ getRoles: e.target.value })}
                      >
                        {this.state.roles.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    {/* <div className="editSideItem1">
                      Tipo de Usuário
                      <Select
                        multiple
                        value={this.state.getUsers || ''}
                        onChange={(e) => this.setState({ getUsers: e.target.value })}
                      >
                        {this.state.users.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </div> */}
                  </div>

                </div>

                <Grid container>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="Nome"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      name="username"
                      disabled={true}
                      value={this.state.username}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="E-mail"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      disabled={true}
                      value={this.state.userEmail}
                    />
                  </Grid>

                  <Grid container item xs={12} md={6}>
                    <FormControl variant="outlined" size="small" fullWidth margin="normal">
                      <TextField
                        value={this.getGenderText(this.state.userGender)}
                        variant="outlined"
                        size="small"
                        label="Gênero"
                        disabled={true}
                        name="gender"
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <FormControl variant="outlined" size="small" fullWidth margin="normal">
                      <TextField
                        value={this.formatDate(this.state.userBirthdate)}
                        variant="outlined"
                        size="small"
                        label="Data de Nascimento"
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="Renda Aproximada"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      value={this.state.userIncome}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="Escolaridade"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      value={this.state.userEducation}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="Telefone"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      disabled={true}
                      value={this.state.userPhone}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="CPF"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      disabled={true}
                      value={this.state.userCpf}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <FormControl variant="outlined" size="small" fullWidth margin="normal">
                      <TextField
                        value={this.state.userState}
                        variant="outlined"
                        size="small"
                        label="Estado"
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        value={this.state.userCity}
                        variant="outlined"
                        size="small"
                        label="Cidade"
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="CEP"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      value={this.state.userCep}
                      disabled={true}
                      InputProps={{
                        inputComponent: this.InputMaskCep,
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      label="Endereço"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      value={this.state.userAddress}
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                <div className="editContainerBtns">
                  <div className="edit_buttonReset">
                    <Button
                      icon="none"
                      width="150px"
                      type="primary"
                      height="36px"
                      textAlign="center !important"
                      right="20px"
                      onClick={() => this.setState({ openMail: true })}
                    >
                      resetar senha
                    </Button>
                    <HtmlTooltip
                      title={
                        <div style={{ padding: 10 }}>
                          <label style={{ color: '#005E50', fontWeight: '700' }}>Resetar senha</label>
                          <p>
                            Ao clicar no botão Resetar senha um e-mail será enviado para o usuário
                            com um link temporário onde ele poderá cadastrar uma nova senha.
                          </p>
                        </div>
                      } >
                      <img src={Icognita} alt="Icone Compartilhar" />
                    </HtmlTooltip>
                  </div>
                  <div className="edit_cancel">
                    <Button
                      icon="none"
                      width="150px"
                      type="primary"
                      height="36px"
                      textAlign="center"
                      right="20px"
                      to={Globals.paths.backofficeUsers}>
                      cancelar
                    </Button>

                    <Button
                      width="150px"
                      height="36px"
                      icon="none"
                      type="darkBlue"
                      textAlign="center"
                      onClick={() => this.handleSave()}>
                      salvar
                    </Button>
                  </div>
                </div>
              </>
            }

            {this.state.menuActive === 1 &&
              <>
                <Perfil
                  pro={this.state.userActive}
                  username={this.state.username}
                />
              </>
            }
            {/* {this.state.menuActive === 2 &&
              <>
                <Navigation username={this.state.username} />
              </>
            } */}
            {this.state.menuActive === 3 &&
              <>
                <Conteudo username={this.state.username} />
              </>
            }
            {this.state.menuActive === 4 &&
              <>
                <Privacity username={this.state.username} />
              </>
            }
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(EditUsers);
