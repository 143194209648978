import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RevealJS, Slide, P } from '@gregcello/revealjs-react';
import RevealHighlight from 'reveal.js/plugin/highlight/highlight';
import Button from '../../components/button/Button';
import { Grid } from '@material-ui/core';
import { enumContentSize, enumBackgroundType } from '../../../services/enums';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './stories.scss';

let revealInstance = null;

const Storie = (props) => {
  const { storie, showCopyButtons, onCopy, inDialog } = props;
  const slides = storie.slides || [];
  let currentContent = slides[0] && slides[0].textContent ? slides[0].textContent : '';
  let fullContent = [];

  slides.forEach((slide) => {
    if (slide.textContent) fullContent.push(slide.textContent);
  });

  useEffect(() => {
    return function cleanup() {
      if (revealInstance) {
        revealInstance.off('slidechanged', slideChanged);
      }
    };
    // eslint-disable-next-line
  }, []);

  const getFontSize = (size) => {
    switch (size) {
      case enumContentSize.Medium: {
        return '68px';
      }
      case enumContentSize.Big: {
        return '88px';
      }
      default: {
        return '48px';
      }
    }
  };
  // eslint-disable-next-line
  const slideChanged = (e) => {
    const currentSlide = slides[e.indexh];
    currentContent = currentSlide && currentSlide.textContent ? currentSlide.textContent : '';
    if (currentSlide) {
      controlDarkMode(currentSlide.darkMode);
    }

  };
  const controlDarkMode = (darkMode) => {
    const id = `storie-holder-${storie.id}`;
    const element = document.getElementById(id);

    if (element) {
      element.classList.remove('dark-mode');

      if (darkMode) {
        element.classList.add('dark-mode');
      }
    }
  }

  const init = () => {
    const currentSlide = slides[0];
    if (currentSlide) {
      controlDarkMode(currentSlide.darkMode);
    }
  }

  const mountSlideContent = (contents, id) => {
    return contents.map((content, i) => {
      return (
        <P
          style={{
            color: content.color,
            fontSize: content.size === enumContentSize.Auto ? 'auto' : getFontSize(content.size),
          }}
          key={`slide-content-${id}-${i}`}
          fitText={content.size === enumContentSize.Auto}
        >
          {content.text}
        </P>
      );
    });
  };

  return (
    <Grid container item xs={12} className="storie-container">
      {showCopyButtons && (
        <Grid container item className="mb20 mt20" justifyContent="center" alignItems="center">
          <CopyToClipboard
            text={fullContent.join('\n')}
            onCopy={() => {
              if (onCopy) onCopy();
            }}
          >
            <Button icon={<FileCopyOutlined style={{ marginLeft: '10px' }} />} type="darkBlue">
              Copiar o conteúdo completo
            </Button>
          </CopyToClipboard>
        </Grid>
      )}
      <div className={`storie ${inDialog ? 'in-dialog' : ''}`} id={`storie-holder-${storie.id}`}>
        <RevealJS
          touch={true}
          onDeckReady={(e) => {
            init()
            revealInstance = e;
            revealInstance.off('slidechanged', slideChanged);
            e.on('slidechanged', slideChanged);
          }}
          overview={false}
          pause={false}
          center={false}
          controlsTutorial={false}
          plugins={[RevealHighlight]}
        >
          {slides.map((slide, index) => {
            switch (+slide.backgroundType) {
              case enumBackgroundType.Video: {
                return (

                  <Slide
                    className={'abobrinha'}
                    key={`slide-${storie.id}}-${index}`}
                    center={false}
                    refIndex={index}
                    disableLayout={true}
                    backgroundVideo={slide.background}
                    autoplay={true}
                    backgroundVideoLoop={true}
                    backgroundVideoMuted={true}
                    id={'dark-mode'}
                  >
                    {mountSlideContent(slide.contents, storie.id)}
                  </Slide>

                );
              }
              case enumBackgroundType.Image: {
                return (
                  <Slide
                    className={'abobrinha'}
                    key={`slide-${storie.id}}-${index}`}
                    refIndex={index}
                    backgroundImage={slide.background}
                    id={'dark-mode'}
                  >
                    {mountSlideContent(slide.contents, storie.id)}
                  </Slide>

                );
              }
              default: {
                return (

                  <Slide
                    className={'abobrinha'}
                    key={`slide-${storie.id}}-${index}`}
                    refIndex={index}
                    backgroundColor={slide.background}
                    id={'dark-mode'}
                  >
                    {mountSlideContent(slide.contents, storie.id)}
                  </Slide>

                );
              }
            }
          })}
        </RevealJS>
      </div>
    </Grid>
  );
};

Storie.defaultProps = {
  showCopyButtons: false,
};

Storie.propTypes = {
  storie: PropTypes.object.isRequired,
  showCopyButtons: PropTypes.bool,
  onCopy: PropTypes.func,
};

function isEqual(prevProps, currentProps) {
  return prevProps.storie.slides[0].background === currentProps.storie.slides[0].background;
}

export default memo(Storie, isEqual);
