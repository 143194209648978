import React, { useState } from 'react';

import Output from 'hyst-editorjs-react-renderer-v2';

export default function EditorJsRenderer({ data }) {
  const config = {
    paragraph: {
      forceTargetBlank: true,
    },
  };

  const style = {
    embed: {
      video: {
        height: 'auto',
        maxHeight: '400px',
      },
      figure: {
        justifyContent: 'center',
        // height: '166px',
      },
      figcaption: {
        borderRadius: '5px',
      },
    },
    image: {
      figure: {
        alignItems: 'flex-start',
        maxHeight: 'auto',
        // height: '166px',
      },
      figcaption: {
        top: 'auto',
        right: 'auto',
        bottom: '0px',
        left: '0px',
        backgroundColor: '#00000066',
      },
      img: {
        maxWidth: '650px',
        maxHeight: 'auto',
      },
    },
    paragraph: {
      fontFamily: "elza, trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
      fontSize: '0.875rem;',
    },
    list: {
      fontFamily: "elza, trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
      fontSize: '0.875rem;',
      container: {
        fontFamily: "elza, trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
        fontSize: '0.875rem;',
      },
      listItem: {
        fontFamily: "elza, trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
        fontSize: '0.875rem;',
      },
    },
    table: {
      th:{
          backgroundColor:"#005E50",
          color:"#fff",
          borderRadius:'0px',
          border: '#495057 1px solid'
      }
    }
  };

  return <Output data={data} style={style} config={config} />;
}
