import React from 'react';
import Axios from 'axios'
import { withRouter } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import Chart from '../../../../../logged_out/components/report/Chart/Chart';
import { Grid, Box } from '@material-ui/core';
import './perfil.scss';
import api from '../../../../../services/api';

const Globals = require("../../../../../Globals.json");
class Perfil extends React.Component {

  async hamdleSearch() {

    this.setState({ isLoading: true })

    const userId = this.props.match.params.userId
    await api.get(`${Globals.api.userInfoReport}/${userId}`)
      .then(response => {
        this.setState({
          name: response?.data?.username,
          desempenho: response?.data?.desempenho,
          dataChart: response?.data?.desempenho.evolucao_grafico,
          status: response?.data?.account_status,
          trophies: response?.data?.total_trofeis,
          interests: response?.data?.RefUsersInterests,
          isLoading: false
        })
        this.setState({ isLoading: false });
      }).catch(error => {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  state = {
    name: '',
    desempenho: [],
    dataChart: [],
    status: '',
    trophies: '',
    interests: [],
    isLoading: true,
  }
  componentDidMount() {
    this.hamdleSearch()
  }

  render() {

    return (
      <div className="perfil_user_container">
        <div className="editHeaderForm">
          <div className="displayName">
            <strong>Perfil do Usuário:</strong>
            <span>{this.props.username}</span>
          </div>
        </div>

        {this.state.isLoading ?
          <Grid>
            <Box pt={0.5} className="skeletonProfile">
              <div>
                <Skeleton variant="rect" width={"47%"} height={230} />
                <Skeleton variant="rect" width={"53%"} height={230} className="skeletonItem" />
              </div>
              <Skeleton variant="rect" width={"96%"} height={250} className="skeletonItem" />
            </Box>
          </Grid> :

          <div className="editContent">
            <div className="box1User">

              <div className="box1UserLeft">

                <div>
                  Status:
                <span className="textUser active">
                    {this.state.status === 'active' ? 'Ativo' : this.state.status}
                  </span>
                </div>

                <div>
                  Objetivo:
                 <span className="textUser">
                    {this.state.interests.length > 0 ? this.state.interests[0].TypeInterest.type : 'Usuario não possui'}
                  </span>
                </div>
              </div>

              <div className="box1UserRight">
                <div className="itensBo">
                  <div className="itemTitleBo">Desempenho geral:</div>
                  <div> {this.state.desempenho.cursos_concluidos} <span> {this.state.desempenho.cursos_concluidos !== 1 ? 'Cursos Concluídos' : 'Curso Concluído'}</span>  </div>
                  <div> {this.state.desempenho.cursos_em_andamento} <span> {this.state.desempenho.cursos_em_andamento !== 1 ? 'Cursos em andamento' : 'Curso em andamento'}</span>  </div>
                  <div> {this.state.desempenho.artigos_lidos} <span>{this.state.desempenho.artigos_lidos !== 1 ? 'Artigos lidos' : 'Artigo lido'}</span>  </div>
                  <div> {this.state.desempenho.missoes_concluidas} <span>{this.state.desempenho.missoes_concluidas !== 1 ? 'Missões Concluídas' : 'Missão Concluída'}</span>  </div>
                  <div> {this.state.desempenho.missoes_em_andamento} <span>{this.state.desempenho.missoes_em_andamento !== 1 ? 'Missões em andamento' : 'Missão em andamento'}</span>  </div>
                  <div> {this.state.desempenho.quiz_concluidos} <span>{this.state.desempenho.quiz_concluidos !== 1 ? 'Quiz Concluídos' : 'Quiz Concluído'}</span>  </div>
                  <div> {this.state.desempenho.mini_quiz_concluidos} <span>{this.state.desempenho.mini_quiz_concluidos !== 1 ? 'Mini Quiz Concluídos' : 'Mini Quiz Concluído'}</span>  </div>
                  {/* <div> {this.state.desempenho.questionarios_respondidos} <span>{this.state.desempenho.questionarios_respondidos > 1 ? 'Questionários respondidos' : 'Questionário respondido'}</span>  </div> */}
                  <div> {this.state.trophies}  <span> {this.state.trophies !== 1 ? 'Troféus Conquistados' : 'Troféu Conquistado'}</span>  </div>
                </div>
              </div>

            </div>
            
            <div className="box2User">
            <div className="titleGraphic">Engajamento:</div>
              <div>
                {this.state.dataChart.length <= 0 ?
                  <span style={{ textAlign: "center", fontWeight: '800', fontSize: "16px", color: "#005E50" }}>
                    Usuário não possui infomações para gerar o gráfico.</span> :
                  <Chart
                    data={this.state.dataChart}
                    width={840}
                    height={280}
                  />
                }
              </div>
            </div>
          </div>}
      </div>
    )
  }
}
export default withRouter(Perfil);
