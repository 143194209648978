import React from 'react';
import { useMediaQuery } from 'react-responsive';
import MainText from '../MainText';
import * as Styled from './Video.styles';

function Video({ openVideoDialog, logHomeAction }) {
  const isIpadPro = useMediaQuery({ minWidth: 960, maxWidth: 1024 });

  return (
    <Styled.Video>
      <div className="inner-wrapper">
        <div>
          <MainText size={isIpadPro ? 700 : 1000} style={{ textAlign: 'center' }}>
            <h2>Quer saber como sair das dívidas e equilibrar sua saúde financeira?</h2>
            <p>
              Aqui na Plataforma Meu Bolso em Dia você encontra cursos, conteúdos e trilhas personalizadas para desenrolar suas dívidas e até fazer sobrar um dinheirinho no final do mês.
            </p>
            {/* <Button onAction={openRegisterDialog}>Comece já</Button> */}
          </MainText>
        </div>
        <figure className="featured">
          <span onClick={logHomeAction}>
            <div className="featured__image" onClick={openVideoDialog}>
              <img
                className="featured__image"
                src="./images/video/foto_video_play.jpg"
                alt="abrir video"
              />
            </div>
          </span>
        </figure>
      </div>
    </Styled.Video>
  );
}

export default Video;
