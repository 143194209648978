import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Select,
  MenuItem,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router';
import '../../../../backoffice.scss';
import './expensesMatrixItems.scss';
import Dialogs from '../../../../services/dialogs';


import api from "../../../../services/api";
import { Link } from 'react-router-dom';
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");

const ExpensesMatrixItemsDetail = () => {
  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [statusToggle, setStatusToggle] = useState(true);
  const [comboboxData, setComboboxData] = useState([]);
  const [totalItemsUsers, setTotalItemsUsers] = useState(0);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [editMode, setEditMode] = useState(false);

  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');
  const [errorDescription, setErrorDescription] = useState(false);
  const [helperTextDescription, setHelperTextDescription] = useState('');
  const [errorExpenseType, setErrorExpenseType] = useState(false);
  const [helperTextExpenseType, setHelperTextExpenseType] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [helperTextStatus, setHelperTextStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loadData = async (id) => {
    try {
      await api.get(Globals.api.typeExpensesMatricesItemsDetail.replace(':matrixItemId', id))
        .then((res) => {
          if (res.status === 200) {
            setData(res.data)
            setName(res.data.name)
            setDescription(res.data.description)
            setExpenseType(res.data.type_expenses_matrix_id)
            res.data.type_status_id === 1 ? setStatusToggle(true) : setStatusToggle(false)
            setId(res.data.id)
            setTotalItemsUsers(res.data.__meta__.total_items_users)
          } else {
            history.push(Globals.paths.backofficeEditExpensesMatrixItems.replace(':matrixItemId', 'new'))
          }
        })
    } catch (error) {
      history.goBack()
    }
  }
  const loadDataCombobox = async () => {
    try {
      await api.get(`${Globals.api.typeExpensesMatricesCombobox}?show_inactives=1`)
        .then((res) => {
          setComboboxData(res.data)
        })
    } catch (error) {
      console.log('erro getcombobox')
    }
  }

  useEffect(() => {
    setIsLoading(true);
    loadDataCombobox();
    if (params.matrixItemId !== 'new') {
      loadData(params.matrixItemId)
      setEditMode(true)
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpensesMatrix = (event) => {
    const value = parseInt(event.target.value, 10)
    setExpenseType(isNaN(value) ? '' : value);
    setHelperTextExpenseType('');
    setErrorExpenseType(false);
  };
  const handleExpensesMatrixClick = (event) => {
    if (totalItemsUsers > 0) {
      setHelperTextExpenseType('Não é possivel alterar a matriz de despesa pois já existem usuários utilizando este item.');
      setErrorExpenseType(true);
    }
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
    setHelperTextName('');
    setErrorName(false);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setHelperTextDescription('');
    setErrorDescription(false);
  };
  const success = () => {
    Dialogs.show(
      <div>SUCESSO</div>,
      'O item foi salvo com sucesso',
      [
        {
          label: "OK",
          onClick: () => history.push(Globals.paths.backofficeExpensesMatrixItems),
        },
      ]
    )
  }

  const saveData = async (postData) => {
    setIsLoading(true);
    try {
      if (postData.id === parseInt(params.matrixItemId, 10)) {
        await api.put(`${Globals.api.typeExpensesMatricesItems}/${postData.id}`, postData)
          .then((res) => {
            success()
          })
      } else {
        await api.post(Globals.api.typeExpensesMatricesItems, postData)
          .then((res) => {
            success()
          })
      }

    } catch (error) {
      setSeverity('error')
      setAlertMessage(typeof (error?.response?.data) === 'string' ? error.response.data : 'Não foi possível excluir o Item da Matriz de despesa')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorExpenseType(false)
    let error = false;
    const statusValue = statusToggle ? 1 : 2;
    const postData = {
      id: id,
      name: name,
      description: description,
      type_expenses_matrix_id: expenseType,
      type_status_id: statusValue,
    }

    if (!expenseType) {
      setHelperTextExpenseType('Selecione uma opção.');
      setErrorExpenseType(true);
      error = true;
    }

    if (!postData.name || postData.name.trim().length === 0) {
      setHelperTextName('Informe um nome');
      setErrorName(true);
      error = true;
    }
    // if (!postData.description || postData.description.length <= 0) {
    //   setHelperTextDescription('Informe uma descrição');
    //   setErrorDescription(true);
    //   error = true;
    // }

    if (!error) {
      saveData(postData)
    }

  }

  const handleToggleStatus = () => {
    statusToggle ? setStatusToggle(false) : setStatusToggle(true)
  }

  const handleBack = (event) => {
    event.preventDefault();
    if (editMode) {

      const statusValue = statusToggle ? 1 : 2;
      const postData = {
        id: id,
        name: name,
        description: description,
        type_expenses_matrix_id: expenseType,
        type_status_id: statusValue,
      }

      const oldData = {
        id: data.id,
        name: data.name,
        description: data.description,
        type_expenses_matrix_id: data.type_expenses_matrix_id,
        type_status_id: data.type_status_id,
      }
      if (JSON.stringify(postData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações no seu Item da Matriz de despesas, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeExpensesMatrixItems),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeExpensesMatrixItems)
      }

    } else {
      history.push(Globals.paths.backofficeExpensesMatrixItems)
    }
  }


  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode ? data.name : 'Novo item da matriz'}</span>
          </div>

          <form className="form-matrix" onSubmit={handleSubmit}>

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Nome</FormLabel>


              <TextField
                value={name}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Nome"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => handleNameChange(e)}
              />
              <FormHelperText>{helperTextName}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorDescription}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Descrição</FormLabel>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxrows={4}
                value={description}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Descrição"
                autoComplete="off"
                type="text"
                onChange={(e) => handleDescriptionChange(e)}
              />
              <FormHelperText>{helperTextDescription}</FormHelperText>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  size='small'
                  component="fieldset"
                  error={errorExpenseType}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">Matriz de despesa</FormLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={expenseType}
                    variant="outlined"
                    fullWidth
                    displayEmpty
                    disabled={totalItemsUsers > 0}
                    onClick={handleExpensesMatrixClick}
                    onChange={handleExpensesMatrix}
                  >
                    <MenuItem value={''}>
                      <em>Selecione</em>
                    </MenuItem>
                    {comboboxData.length > 0 &&
                      // eslint-disable-next-line array-callback-return
                      comboboxData.map((item) => {
                        if(item.type_status_id === 1 || item.id === data.type_expenses_matrix_id) {
                          return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                        }
                      })
                    }

                  </Select>
                  <FormHelperText>{helperTextExpenseType}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={errorStatus}
                  variant="standard"
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={statusToggle}
                          onChange={handleToggleStatus}
                        />
                      }
                      label={'Ativa'}
                    />
                  </RadioGroup>
                  <FormHelperText>{helperTextStatus}</FormHelperText>

                </FormControl>
              </Grid>

            </Grid>

            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default ExpensesMatrixItemsDetail;
