import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import ComposedChartDashboard from '../../Components/Charts/ComposedChartDashboard';
import './EvolutionCharts.scss';

export default function EvolutionCharts({ data }) {
  const [chartType, setChartType] = useState('users');
  const [evolutivePeriod, setEvolutivePeriod] = useState('monthly')
  const [courseData, setCourseData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [drillUp, setDrillUp] = useState(false);

  const loadData = async () => {
    let period;
    let groups;
    let groupsUsers;

    if (evolutivePeriod === 'monthly') {
      period = 'yearAndMonth';
      setDrillUp(false);
    } else if (evolutivePeriod === 'weekly') {
      period = 'week';
      setDrillUp(true);
    } else if (evolutivePeriod === 'daily') {
      period = 'date';
      setDrillUp(true);
    }

    groupsUsers = data?.users?.reduce((groupsUsers, item) => {
      const group = (groupsUsers[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groupsUsers[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groupsUsers[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groupsUsers[item[period]] = group;;
      }
      return groupsUsers;
    }, {});

    const usersDataFormated = await formatUsersData(groupsUsers);
    setUserData(usersDataFormated);

    groups = data?.course?.reduce((groups, item) => {
      const group = (groups[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups[item[period]] = group;
      }

      return groups;
    }, {});

    const courseDataFormated = await formatCourseData(groups);
    setCourseData(courseDataFormated);
  };

  const formatCourseData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              total: accumulator.total + currentValue.total,
              totalFinished: accumulator.totalFinished + currentValue.totalFinished,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          if (barColumn.total > 0) {
            barColumn['percentage'] = (barColumn.totalFinished / barColumn.total) * 100;
          } else {
            barColumn['percentage'] = 0;
          }

          result.push(barColumn);
        } else if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            total: value.total,
            totalFinished: value.totalFinished,
            percentage: value.total > 0 ? (value.totalFinished / value.total) * 100 : 0,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      })
    };

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        groups[item][0]['percentage'] = (groups[item][0].total > 0 ? (groups[item][0].totalFinished / groups[item][0].total) * 100 : 0);
        result.push(groups[item][0]);
      })
    }

    return result;
  };

  const formatUsersData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              total: accumulator.total + currentValue.total,
              totalComplete: accumulator.totalComplete + currentValue.totalComplete,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          barColumn['percentage'] = barColumn.total > 0 ? (barColumn.totalComplete / barColumn.total) * 100 : 0;
          result.push(barColumn);

        } else if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            total: value.total,
            totalComplete: value.totalComplete,
            percentage: value.total > 0 ? (value.totalComplete / value.total) * 100 : 0,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      })
    };

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        groups[item][0]['percentage'] = groups[item][0].total > 0 ? (groups[item][0].totalComplete / groups[item][0].total) * 100 : 0;
        result.push(groups[item][0]);
      })
    }

    return result;
  };

  const handleOnclickButton = (chart) => {
    setChartType(chart);
    setEvolutivePeriod('monthly');
  };

  const handleDrillUp = () => {
    if (evolutivePeriod === 'daily') {
      setEvolutivePeriod('weekly');
      setSelectedPeriod(selectedWeek)
    } else if (evolutivePeriod === 'weekly') {
      setEvolutivePeriod('monthly');
    }
  };

  const getNameAndPeriodOfChart = () => {
    let graphName = 'Usuários, Cadastro completo e  % Cadastro completo';
    let evatuatedBy = 'mês';

    if (evolutivePeriod === 'monthly') {
      evatuatedBy = 'mês';
    } else if (evolutivePeriod === 'weekly') {
      evatuatedBy = 'semana';
    } else if (evolutivePeriod === 'daily') {
      evatuatedBy = 'dia';
    }

    if (chartType === 'courses') {
      graphName = 'Cursos, Cursos concluidos e % Cursos concluidos';
    } else if (chartType === 'users') {
      graphName = 'Usuários, Cadastro completo e  % Cadastro completo';
    }

    return graphName + ' por ' + evatuatedBy;
  };

  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();

  }, [evolutivePeriod, selectedPeriod, selectedMonth]);

  return (
    <div className='evolution-chart-container-egmt'>
      <div className='header'>
        <div>
          Evolutivo Mensal / Semanal / Diário
        </div>
        <div className='btn-container'>
          {drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;- </button>}
          <button type='button' onClick={() => handleOnclickButton('users')}>Cadastro completo</button>
          <button type='button' onClick={() => handleOnclickButton('courses')}>Cursos</button>
        </div>
      </div>
      <div>
        {getNameAndPeriodOfChart()}
      </div>
      <div className='chart-container'>
        {chartType === 'users' && (
          <ResponsiveContainer width={'99%'} height={'100%'}>
            <ComposedChartDashboard data={userData}
              barNameDatakey={[{ name: 'Usuários', datakey: 'total', fill: "#05CCAE" },
              { name: 'Cadastro completo', datakey: 'totalComplete', fill: '#d8d8d8' }]}
              lineDatakey={'percentage'}
              lineName={'% Cadastro completo'}
              lineColor={'#DAF439'}
              needBarLabel={true}
              chartHeight={180}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              angle={-45} />
          </ResponsiveContainer>
        )}
        {chartType === 'courses' && (
          <ResponsiveContainer width={'99%'} height={'100%'}>
            <ComposedChartDashboard data={courseData}
              barNameDatakey={[{ name: 'Cursos', datakey: 'total', fill: "#05CCAE" },
              { name: 'Cursos concluídos', datakey: 'totalFinished', fill: '#d8d8d8' }]}
              lineDatakey={'percentage'}
              lineName={'% Cursos concluídos'}
              lineColor={'#DAF439'}
              needBarLabel={true}
              chartHeight={180}
              toolTipName={'Período'}
              evolutivePeriod={setEvolutivePeriod}
              evolutiveWeekSelected={setSelectedWeek}
              evolutiveMonthSelected={setSelectedMonth}
              angle={-45} />
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}