import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router';
import '../../../../../../backoffice.scss';
import Dialogs from '../../../../../../services/dialogs';


import api from "../../../../../../services/api";
import Button from '../../../../button/Button';

const Globals = require("../../../../../../Globals.json");

const ObjectivesMatrixDetail = () => {
  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [id, setId] = useState('');

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');

  const [order, setOrder] = useState('');
  const [errorOrder, setErrorOrder] = useState(false);
  const [helperTextOrder, setHelperTextOrder] = useState('');

  const [statusToggle, setStatusToggle] = useState(true);

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [helperTextStatus, setHelperTextStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loadData = async (id) => {
    try {
      await api.get(Globals.app_api.discoveryQuestionDetail.replace(':question_id', id))
        .then((res) => {
          const data = res.data.content.question
          setData(data)
          setName(data.description)
          setOrder(data.display_order)
          setStatusToggle(data.is_active)
          setId(data.id)
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false);
  }


  useEffect(() => {
    setIsLoading(true);
    console.log(params)
    if (params.question_id !== 'new') {
      loadData(params.question_id)
      setEditMode(true)
    }
    !editMode && setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setHelperTextName('');
    setErrorName(false);
  };
  const handleOrderChange = (event) => {
    setOrder(parseInt(event.target.value));
    setHelperTextOrder('');
    setErrorOrder(false);
  };

  const success = () => {
    Dialogs.show(
      <div>SUCESSO</div>,
      'A Questão foi salva com sucesso',
      [
        {
          label: "OK",
          onClick: () => history.push(Globals.paths.backofficeQuestionMatrices),
        },
      ]
    )
  }

  const saveData = async (postData) => {
    setIsLoading(true);
    await api.post(Globals.app_api.discoveryQuestionMatrices, postData)
      .then((res) => {
        success()
      })
      .catch((e) => {
        console.log(e)
      })
  };

  const editData = async (postData) => {

    await api.put(`${Globals.app_api.discoveryQuestionDetail.replace(':question_id', postData.id)}`, postData)
      .then((res) => {
        success()
      })
      .catch((e) => {
        console.log(e)
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = false;

    const postData = {
      id: id,
      description: name,
      is_active: statusToggle,
      display_order: order,
    }

    if (!postData.description || postData.description.trim().length === 0) {
      setHelperTextName('Informe uma descrição');
      setErrorName(true);
      error = true;
    }

    if (!postData.display_order) {
      setHelperTextOrder('Informe uma ordem');
      setErrorOrder(true);
      error = true;
    }

    if (!error) {
      editMode ? editData(postData) : saveData(postData)
    }

  }

  const handleToggleStatus = () => {
    statusToggle ? setStatusToggle(false) : setStatusToggle(true)
  }

  const handleBack = (event) => {
    event.preventDefault();
    if (editMode) {

      const postData = {
        id: id,
        description: name,
        is_active: statusToggle,
        display_order: order
      }

      const oldData = {
        id: data.id,
        description: data.description,
        is_active: data.is_active,
        display_order: data.display_order
      }

      if (JSON.stringify(postData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações nessa questão, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeQuestionMatrices),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeQuestionMatrices)
      }

    } else {
      history.push(Globals.paths.backofficeQuestionMatrices)
    }
  }


  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode ? data.description : 'Novo Grupo de Questão'}</span>
          </div>

          <form className="form-matrix" onSubmit={handleSubmit}>

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Descrição</FormLabel>


              <TextField
                value={name}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Descrição"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => handleNameChange(e)}
              />
              <FormHelperText>{helperTextName}</FormHelperText>
            </FormControl>

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Ordem</FormLabel>


              <TextField
                value={order}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Ordem"
                autoComplete="off"
                type="number"
                onChange={(e) => handleOrderChange(e)}
              />
              <FormHelperText>{helperTextOrder}</FormHelperText>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={errorStatus}
                  variant="standard"
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={statusToggle}
                          onChange={handleToggleStatus}
                        />
                      }
                      label={'Ativa'}
                    />
                  </RadioGroup>
                  <FormHelperText>{helperTextStatus}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default ObjectivesMatrixDetail;
