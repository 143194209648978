import React, { Component } from 'react';
import api from '../../../../../services/api';
import "./FileUploader.scss";

const Globals = require("../../../../../Globals.json");

const getRnd = () => {
    return Math.floor( Math.random() * 999999999999 ).toString(16);
}
export class FileUploader extends Component {

    state = {
        type: this.props.mediaType ?? 'image',
        imageUrl: null,
        fileName: null,
        message: '',
        notImage: false,
        changed: false,
        isUploading: false,
        uploadPercent: 0,
        fileUploadName: 'file-upload-' + getRnd(),
        fileDragName: 'file-drag-' + getRnd(),
        formName: 'file-upload-form-' + getRnd(),
    }

    // File Upload
    //
    init = () => {

        console.log("Upload Initialised");

        let fileSelect = document.getElementById(this.state.fileUploadName),
            fileDrag = document.getElementById(this.state.fileDragName);

        fileSelect.addEventListener('change', this.fileSelectHandler, false);

        // File Drop
        fileDrag.addEventListener('dragover', this.fileDragHover, false);
        fileDrag.addEventListener('dragleave', this.fileDragHover, false);
        fileDrag.addEventListener('drop', this.fileSelectHandler, false);
    }

    fileDragHover = (e) => {
        let fileDrag = document.getElementById(this.state.fileDragName);
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    fileSelectHandler = (e) => {

        this.output();

        // Fetch FileList object
        let files = e.target.files || e.dataTransfer.files;

        // Cancel event and hover styling
        this.fileDragHover(e);

        // Process all File objects
        for (let i = 0, f; f = files[i]; i++) {
            this.parseFile(f);
            this.uploadFile(f);
        }
    }

    // Output
    output = (msg) => {
        // Response
        this.setState({ message: msg });
    }

    parseFile = (file) => {

        let imageName = file.name;

        let isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
            this.setState({ imageUrl: URL.createObjectURL(file), notImage: false });
        }
        else {
            this.setState({ imageUrl: null, notImage: true });
            document.getElementById(this.state.formName).reset();
        }
    }

    updateFileProgress = (e) => {
        if (e.lengthComputable) {
            console.log("progress...", e.loaded, e.total);
            this.setState({ uploadPercent: e.loaded });
        }
    }

    uploadFile = (file) => {

        const fileInput = document.getElementById(this.state.fileUploadName);
        let fileSizeLimit = this.props.maxFileSize ?? 15; // In MB

        // Check if file is less than x MB
        if (file.size > (fileSizeLimit * 1024 * 1024)) {
            this.output(`Arquivo excede o limite de ${fileSizeLimit}MB`)
            document.getElementById( this.state.formName ).reset();
            this.updateUrl(null);
            return;
        }

        const formData = new FormData();

        formData.append("image", fileInput.files[0]);

        if (this.props.folder) {
            formData.append("folder", this.props.folder);
        }

        this.setState({ isUploading: true });

        api.post(Globals.api.uploadImg, formData, {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            }
        }).then(
            response => {
                console.log("FILEUPLOADER_RESP::", response.data);
                this.updateUrl(response?.data?.url);
            }
        ).catch(() => {
                this.output(`Ocorreu um erro no upload do arquivo`)
            }
        ).finally(() => {
            this.setState({ isUploading: false });
        });

        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.imageUrl !== this.props.imageUrl) {
            if (this.props.imageUrl) {
                this.setState({ imageUrl: this.props.imageUrl });
            }
        }
    }

    componentDidMount = () => {

        this.setState({ imageUrl: this.props.imageUrl });

        if (window.File && window.FileList && window.FileReader) {
            // Check for the letious File API support.
            this.init();

        } else {
            document.getElementById(this.state.fileDragName).style.display = 'none';
        }
    }

    updateUrl = (url) => {
        this.setState({ imageUrl: url });
        if (this.props.onChange) this.props.onChange(url);
    }

    render() {

        return (
            <div className="uploader">
                <form id={this.state.formName} >
                    <input id={this.state.fileUploadName} type="file" name="fileUpload" accept="image/*" />

                    <label htmlFor={this.state.fileUploadName} id={this.state.fileDragName}>

                        {this.state.imageUrl &&
                            <>
                                <img className='file-image' src={this.state.imageUrl} alt="Preview" style={{ opacity: this.state.isUploading ? .3 : 1, transition: "all 1s" }} />
                                <div className="file-image-subtitle">Clique para selecionar ou solte um arquivo aqui.</div>
                            </>
                        }

                        {!this.state.imageUrl &&
                            <div id="start">
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <div>Clique para selecionar <br />ou solte um arquivo aqui.</div>
                                {this.state.notImage && <div id="notimage">O arquivo selecionado não é uma imagem válida.</div>}
                                <span className="btn btn-primary">Selecionar...</span>
                            </div>
                        }

                        <div id="response">
                            {this.state.message && <div id="messages" style={{ color: "#900" }}>{this.state.message}</div>}
                        </div>

                    </label>
                </form>
            </div>

        )
    }
}

export default FileUploader;