import styled, { css } from 'styled-components';
import { withTheme } from '@material-ui/core';

export const Menu = withTheme(styled('div')`
  background-color: rgba(0,0,0,0);
  height: 100vh;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  transition: all .5s ease-in-out;

  ${({ isClosed }) => !isClosed ? css`
    background-color: rgba(0,0,0,0.3);
    pointer-events: all;
  ` : null}

  .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 70px;
    background: ${({ theme }) => theme.palette.secondary.main};
    width: 100%;
    top: -70px;
    transition: all .3s ease-in-out;
    padding: 0 25px;
    pointer-events: none;
    z-index: 1000;

    ${({ showBar }) => showBar ? css`
      top: 0;
      pointer-events: all;
    ` : null }

    &__logo {
      width: 30px;
    }

    &__action-btn {
      border: 1px solid #202020;
      border-radius: 3px;
      color: #202020;
      cursor: pointer;
      height: 35px;
      padding: 5px;
      width: 35px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.palette.secondary.main};
    height: 100%;
    padding: 30px;
    position: absolute;
    left: 100%;
    top: 0;
    transition: all .3s ease-in-out;
    min-width: 50vw;

    ${({ isClosed }) => !isClosed ? css`
      transform: translateX(-100%);
  ` : null}

    .toggle-menu {
      align-self: flex-end;
      border: 1px solid #202020;
      border-radius: 3px;
      color: #202020;
      cursor: pointer;
      height: 35px;
      margin-bottom: 50px;
      margin-right: 8px;
      padding: 5px;
      width: 35px;
      z-index: 2;
    }

    a, button:not(.toggleMenu) {
      background-color: transparent;
      border: none !important;
      box-sizing: border-box;
      color: #202020;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      padding: 8px 13px;
      text-decoration: none;
      text-transform: uppercase;
      text-align: right;
      white-space: nowrap;

      &.button {
        color: var(--alternativeBlue);
        border: 1px solid var(--green);
      }
    }
  }
`);