
import React from 'react';
import './courses.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { Grid, Hidden, Breadcrumbs, Box } from '@material-ui/core';

import api from '../../../services/api';

import ArticleList from '../../components/course/CourseArticList';
import AlertErrors from '../../components/alertErrors/alerts';
import CourseList from '../../components/course/CourseList';
import CourseListResp from '../../components/course/CourseListResp';
import CourseRecoResponsive from '../../components/course/CourseRecoResponsive';
import CourseRecommended from '../../components/course/CourseRecommended';
import Favorite from '../../components/sidebar/Favorite';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import ProgressCourses from '../../components/sidebar/ProgressCourses';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

const Globals = require("../../../Globals.json");
class Courses extends React.Component {

  state = {
    categories: [],
    courses: [],
    recomendedCourses: [],
    ongoingCourses: [],
    isLoading: false,
    showMessage: true,
    alertError: '',
  }

  async loadDataRecommended () {
    try {

      this.setState({ isLoading: true });

      const response = await api.post(Globals.api.recommendedModule, { module_type: 2 });
      const recomendedCourses = response.data;

      this.setState({ recomendedCourses });
      this.setState({ isLoading: false })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  async loadDataAllCourses () {
    try {
      this.setState({ isLoading: true });
      const response = await api.post(Globals.api.getModules, { type_module_id: 2 });
      const courses = response.data;
      const ongoingCourses = [];
      const categories = [];

      courses
        .forEach((item) => {
          if (item.user_module_data.hasOwnProperty("startedAt") && !item.user_module_data.hasOwnProperty("finishedCourseAt")) {
            ongoingCourses.push(item);
          }

          if (item.data.hasOwnProperty("categories") && Array.isArray(item.data.categories)) {
            item.data.categories.forEach(itemCat => {
              if (categories.indexOf(itemCat) === -1) {
                categories.push(itemCat);
              }
            })
          }
        });
      
      const sortedCoursesByProgress = courses.sort((a, b) => a.progress < b.progress ? -1 : 1);

      this.setState({ courses: sortedCoursesByProgress, categories, ongoingCourses });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  goToCourse = (courseId) => {
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", courseId));
  }

  componentDidMount () {
    this.loadDataRecommended();
    this.loadDataAllCourses();
  }

  render () {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className='courses_breadCrumb'>
          <Link color="inherit" to={Globals.paths.courses} className='courses_breadCrumbLink'>
            Cursos
          </Link>
        </Breadcrumbs>
        <Grid container>
          <AlertErrors error={this.state.alertError} />

          <Helmet>
            <title>Curso | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className='classContainer' >
            <Hidden smUp>
              <FeedLine>
                <FeedItem title="Cursos recomendados" overflowHidden={true} >
                  <CourseRecoResponsive history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "2" }} />
                </FeedItem>
              </FeedLine>
            </Hidden>

            <Hidden xsDown>
              <Grid item className='courseItem'>
                <Grid item xs={12} className='course_TitleBar'>
                  Cursos recomendados para você
              </Grid>
              </Grid >

              <div className='container_recomendados'>
                <CourseRecommended history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "2" }} />
                <div className='coutseSpacing'>.</div>
              </div>
              <br />
            </Hidden>

            <Grid container >
              <Hidden xsDown>
                <FeedItem title={"Cursos"} borderRadius>
                  <CourseList home={false} history={this.props.history} showCategories categories={this.state.categories} />
                </FeedItem>
              </Hidden>

              <Hidden smUp>
                <FeedLine >
                  <FeedItem title="Cursos">
                    <CourseListResp history={this.props.history} categories={this.state.categories} courses={this.state.courses} />
                  </FeedItem>
                </FeedLine>
              </Hidden>

            </Grid>

            <Grid container id="artigos">

              <FeedItem title={"Artigos"} borderRadius>
                <ArticleList />
              </FeedItem>

            </Grid>

          </Grid>

          <Hidden xsDown>
            <Grid item sm={4} md={3} >


              <ToolbarItem title="Cursos em andamento" color="#FFF" overflow="overflow">
                {this.state.isLoading ?
                  <Grid>
                    <Box pt={0.5} style={{ padding: "15px" }}>
                      <Skeleton variant="rect" width={"21vw"} height={100} />
                      <Skeleton width="60%" />
                      <Skeleton variant="rect" width={"21vw"} height={100} />
                      <Skeleton width="60%" />
                      <Skeleton variant="rect" width={"21vw"} height={100} />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid> :
                  <>
                    {this.state.ongoingCourses.length === 0 ?
                      <span className='msg'>Nenhum curso em Andamento...</span>
                      :
                      this.state.ongoingCourses.map((item, index) => (
                        <div key={index}>
                          <ProgressCourses title={item.name} img={item.thumbnail} on={() => this.goToCourse(item.id)} />
                        </div>
                      ))
                    }
                  </>
                }
              </ToolbarItem>

              <FeedLine >
                {/* <FeedItem title="Meus favoritos"> */}
                <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                  <Favorite />
                </ToolbarItem>
              </FeedLine>
            </Grid>
          </Hidden>

        </Grid >
      </>
    )
  }

}

export default Courses;

