import React, { useState } from "react";
import { FormControl, MenuItem, InputLabel, Select, TextField } from "@material-ui/core";
import { useParams } from "react-router";
import "../../../../backoffice.scss";
import "./EditWorkSheets.scss";
import ModuleEditing from "../../../components/backoffice/components/ModuleEditing/ModuleEditing";
import WorksheetUploader from "../../../components/backoffice/components/FileUploader/WorksheetUploader";
import FileUploader from "../../../components/backoffice/components/FileUploader/FileUploader";
import Dialogs from "../../../../services/dialogs";



import Globals from "../../../../Globals.json";


export default function EditWorkSheets() {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [type, setType] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const [order, setOrder] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);

  const params = useParams();

  console.log("PARAMS::", params);

  const onReceiveData = async (container, children, parent) => {
    const { subtitle, type, order, imgUrl, fileUrl } = container.data;

    setSubtitle(subtitle);
    setType(type);
    setOrder(order);
    setFileUrl(fileUrl);
    setFileName(container.name);

    setBreadcrumbs([
      {
        label: "Planilhas",
        link: Globals.paths.backofficeWorksheets,
      },
      {
        label: container.name
      }
    ]);

  }

  const onValidate = (itemStatus) => {

    if (!(type !== null)) {
      Dialogs.show("O Tipo de aquivo é Obrigatório", "É necessário colocar o tipo de arquivo");
      return false;
    };

    if (!order) {
      Dialogs.show("Ordem é Obrigatório", "É necessário colocar uma ordem");
      return false;
    };

    if (!fileUrl) {
      Dialogs.show("Arquivo é Obrigatório", "É necessário que um arquivo seja selecionado.");
      return false;
    };

    return true;
  }

  const onBeforeSaveData = (postData) => {
    postData.data.subtitle = subtitle;
    postData.data.type = type;
    postData.data.order = order;
    postData.data.fileUrl = fileUrl;
  }

  const customDataFields = <div className="quiz-edit-container">

    <FormControl fullWidth>
      <InputLabel id="type-file">Tipo</InputLabel>
      <Select
        labelId="type-file"
        label="Tipo"
        value={type}
        style={{ width: "100%" }}
        onChange={(e) => setType(Number(e.target.value))}
      >
        <MenuItem value={0}>Planilha</MenuItem>
        <MenuItem value={1}>Lista</MenuItem>
      </Select>
    </FormControl>
    
    <TextField
      key="txtOrder"
      label="Ordem"
      type="number"
      value={order}
      onChange={(e) => setOrder(e.target.value)}
    />

    <FormControl className="worksheet-container">
      <label>Arquivo</label>
      <WorksheetUploader
        onChange={(fileConfig) => setFileUrl(fileConfig.url)}
        url={Globals.api.uploadFiles}
        fileName={fileName}
        fileUrl={fileUrl}
        mediaType="worksheet"
        folder="worksheet"
      />
    </FormControl>

  </div>;

  return (

    <ModuleEditing
      isNew={params.worksheetId === "new"}
      moduleId={params.worksheetId}
      typeModuleId={39}
      ThumbnailFieldLabel="imagem"
      title="Detalhe do Novo item"
      checkButtonName="Item ativo"
      thumbnailFolder="worksheet"
      onBeforeSaveData={onBeforeSaveData}
      onReceiveData={onReceiveData}
      onValidate={onValidate}
      customDataFields={customDataFields}
      hideRewards
      hideInfluences
      breadcrumbs={breadcrumbs}
    />

  );
}

