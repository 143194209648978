
import React from 'react';

import { Link } from 'react-router-dom';
import { withStyles, Grid } from '@material-ui/core';
import { Box, Typography, Tooltip, Zoom } from '@material-ui/core';

import AlertErrors from '../../../logged_in/components/alertErrors/alerts';
import Button from '@material-ui/core/Button';
import VisibilityPasswordTextField from '../../../shared/components/VisibilityPasswordTextField';

import Logo from '../../../../src/assets/logo/logo-blue.svg';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import axios from 'axios';

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    height: "80px",
  },
  passReqText:
  {
    paddingLeft: "5px",
    whiteSpace: "nowrap",
  },
  form: {
    backgroundColor: "#fff",
    height: "508px",
    width: "451px",
    display: "flex",
    flexDirection: "column",
    padding: "35px",
    position: "relative",
    marginTop: "120px",
  },
  container: {
    display: "flex",
    height: "calc(100vh - 100px)",
    justifyContent: "center",
    backgroundColor: "#595959",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#005E50",
  },
  headerEmail: {
    fontSize: "16px",
    color: "#05CCAE",
  },
  inputs: {
    marginTop: "8px",
    width: "60%",
    height: "40px",
    border: "1px solid #595959",
    paddingLeft: "5px",
    fontSize: "14px"
  },
  logo: {
    width: "285px",    
  },
  textsForm: {
    fontSize: "16px",
    color: "#202020",
    marginBottom: "8px",
  },
  textsFormSmall: {
    fontSize: "12px",
    color: "#666",
    marginTop: "-8px",
    marginBottom: "8px",
  },
  containerButton: {
    marginTop: "40px",
  },
  borderGreen: {
    width: "20%",
    height: "10px",
    position: "absolute",
    backgroundColor: "#05CCAE",
    bottom: 0,
    right: 0,
  },
  header2:
  {
    backgroundColor: "#05CCAE",
    height: "120px",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0px 5vw 0px 5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  styleButton: {
    float: "right",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid var(--primary)",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "bold",
    paddingLeft: "20px",
    transition: "color .3s ease-out",
    width: "40%",
    height: "40px",
    cursor: "pointer",
    borderRadius: "0px",
    paddingTop: "7px",
    backgroundColor: "var(--primary)",
    "&:hover": {
      color: "#FFF",
      border: "1px solid var(--primaryLight)",
    }
  },
  arrowIcon: {
    width: "40px",
    height: "40px",
    padding: "0px",
    marginRight: "-5px",
    marginTop: "-5px"
  },
  textHeader: {
    fontSize: "20px",
    color: "#202020",
    fontWeight: "700",
    marginTop: '10px'
  },
  link: {
    fontWeight: 700,
    textDecoration: "none",
    color: "var(--primary)",
    "&:hover": {
      color: "var(--secondary)"
    }
  }
});

const Globals = require("../../../Globals.json");

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#595959',
    marginLeft: '60px',
    paddingLeft: "10px",
    paddingRight: "15px",
    paddingBottom: "10px",
    boxShadow: "2px 5px 15px #00000055",
  },
  arrow: {
    height: "45px",
    color: "#eee",
  },
}))(Tooltip);

class AppResetPassword extends React.Component {
  state = {
    password: "",
    passwordConfirmation: "",
    token: "",
    message: "",
    messageErro: "",
    pass: '',
    passScore: 0,
    passReq_min8: false,
    passReq_upper: false,
    passReq_lower: false,
    passReq_number: false,
    passReq_specialChar: false,
    confirmPass: '',
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    fieldsValidation: {},
    alertError: '',
    passWordUsedMsg: '',
  }

  getParameter(theParameter) {
    var params = window.location.search.substr(1).split('&');

    for (var i = 0; i < params.length; i++) {
      var p = params[i].split('=');
      return p[1]
    }
    return false;
  }

  componentDidMount() {

    this.getParameter();
    var tokenParam = this.getParameter('token')
    this.setState({ token: tokenParam })
    console.log(tokenParam)

  }
  removeValidation(key) {
    const obj = { ...this.state.fieldsValidation };
    delete obj[key];
    this.setState({ fieldsValidation: obj });
  }

  updatePassStatus(pass) {
    this.setState(
      {
        passReq_min8: pass.length >= 8,
        passReq_upper: /[A-Z]/.test(pass),
        passReq_lower: /[a-z]/.test(pass),
        passReq_number: /\d/.test(pass),
        passReq_specialChar: /\W/.test(pass),
      }
    )
  }

  getPasswordHelperText() {
    let color = "#f00";
    let label = "Fraca";

    if (this.state.passScore >= 60) {
      color = "#c70";
      label = "Média";
    }

    if (this.state.passScore >= 80) {
      color = "#0c0";
      label = "Forte";
    }

    return <span style={{ color: color }}>Força da senha: {label}</span>
  }


  validateForm() {

    let ret = true;

    const fieldsValidation = {};

    console.log(this.state.name);


    if (this.state.password === '') {
      fieldsValidation.pass = "Por favor digite uma senha";
      ret &= false;
    }
    else if (!this.state.passReq_min8 ||
      !this.state.passReq_upper ||
      !this.state.passReq_lower ||
      !this.state.passReq_number ||
      !this.state.passReq_specialChar) {
      fieldsValidation.pass = "Por favor, preencha todos os requisitos de senha";
      ret &= false;
    }

    if (this.state.password !== this.state.passwordConfirmation) {
      fieldsValidation.confirmPass = "A confirmação da senha está diferente da senha informada";
      ret &= false;
    }

    this.setState({ fieldsValidation: fieldsValidation });

    console.log(ret, fieldsValidation);

    return ret;

  }

  resetPassword = async () => {
    this.setState({ passWordUsedMsg: '' })

    try {
      console.log('dados antes de mandar', this.state.token, this.state.password, this.state.passwordConfirmation)

      await axios.post(process.env.REACT_APP_API + Globals.api.appUpdatePasswordToken + `${this.state.token}`, {
        new_password: `${this.state.password}`,
        confirm_password: `${this.state.passwordConfirmation}`,
      }).then(res => {
        console.log(res.data)
        console.log('alterado, dados enviados:')
        this.setState({ message: 'Senha alterada com sucesso!' });
      })
    } catch (error) {
      if (error.response && error.response['status'] && error.response.data['error'][0]) {
        switch (error.response['status']) {
          case 400:
          case 401:
          case 422:
            if (error.response.data['error'][0]) {
              this.setState({ passWordUsedMsg: error.response.data['error'].join('\n').toString() });
            }

            break;
        }
      } else {
        this.setState({ passWordUsedMsg: 'Erro no servidor' });
      }

      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  };

  render() {


    const { classes } = this.props;

    return (
      <React.Fragment>
        <AlertErrors error={this.state.alertError} />
        <Grid item container className={classes.header2}>
          <img src={Logo} alt="Logo Meu Bolso em Dia" className={classes.logo} />
          <span className={classes.textHeader}>meubolsoemdia.com.br</span>
        </Grid>
        <div className={classes.container}>
          <form className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              this.resetPassword();
            }}
          >
            <div className={classes.header}>
              <span className={classes.headerTitle} >Redefinir senha</span>
              {/* <span className={classes.headerEmail} >Seu email: </span> */}
            </div>
            <span className={classes.textsForm}>Senha nova</span>
            <span className={classes.textsFormSmall}>Digite ao menos 8 caracteres, sendo: 1 letra maiúscula, 1 letra minúscula, 1 caractere especial e 1 número</span>

            <HtmlTooltip placement="right" arrow={true}
              disableHoverListener
              title={
                <>
                  <Typography color="inherit">Requisitos da Senha</Typography>
                  <Grid container direction="column">
                    <Box style={{ color: this.state.passReq_min8 ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.passReq}>
                      {this.state.passReq_min8 ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className={classes.passReqText}>Mínimo de 8 caracteres</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_upper ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.passReq}>
                      {this.state.passReq_upper ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className={classes.passReqText}>Pelo menos 1 letra maiúscula</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_lower ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.passReq}>
                      {this.state.passReq_lower ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className={classes.passReqText}>Pelo menos 1 letra minúscula</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_number ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.passReq}>
                      {this.state.passReq_number ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className={classes.passReqText}>Pelo menos 1 número</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_specialChar ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.passReq}>
                      {this.state.passReq_specialChar ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className={classes.passReqText}>Pelo menos 1 caractere especial</Box>
                    </Box>
                  </Grid>
                </>
              }
            >
              <VisibilityPasswordTextField
                className={classes.inputField}
                size="small"
                variant="outlined"
                placeholder="Digite sua nova senha"
                required
                fullWidth
                error={this.state.fieldsValidation.pass}
                helperText={this.state.fieldsValidation.pass}
                inputRef={this.state.registerPassword}
                autoComplete="off"
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                  this.updatePassStatus(e.target.value);
                  this.removeValidation("pass")
                }}
                FormHelperTextProps={{ error: this.state.fieldsValidation.pass !== null }}
                isVisible={this.state.isPasswordVisible}
                onVisibilityChange={(val) => { this.setState({ isPasswordVisible: val }) }}
              />
            </HtmlTooltip>
            <br />
            <span className={classes.textsForm}>Confirmar nova senha</span>
            <VisibilityPasswordTextField
              className={classes.inputField}
              size="small"
              variant="outlined"
              placeholder="Confirme sua nova senha"
              required
              fullWidth
              error={this.state.fieldsValidation.confirmPass}
              helperText={this.state.fieldsValidation.confirmPass}
              inputRef={this.state.registerPasswordRepeat}
              autoComplete="off"
              onChange={(e) => {
                this.setState({ passwordConfirmation: e.target.value });
                this.removeValidation("confirmPass")
              }}
              FormHelperTextProps={{ error: true }}
              isVisible={this.state.isConfirmPasswordVisible}
              onVisibilityChange={(val) => { this.setState({ isConfirmPasswordVisible: val }) }}
            />

            <div className={classes.containerButton}>
              <Button

                variant="contained"
                className={classes.styleButton}
                onClick={() => { if (this.validateForm()) { this.resetPassword() } }}

              >
                Redefinir
                <ArrowRightIcon className={classes.arrowIcon} />
              </Button>
            </div>
            <br />
            {this.state.passWordUsedMsg &&
              <div className={classes.message} style={{ fontSize: "16px", color: "#A00" }}>
                {this.state.passWordUsedMsg}
              </div>
            }
            {this.state.message &&
              <div className={classes.message} style={{ fontSize: "16px", color: "#202020" }}>
                {this.state.message} Você já pode fechar a janela e retornar para o aplicativo Meu Bolso em Dia.
              </div>}
            <span style={{ fontSize: "16px", color: "#f00", marginLeft: "4px" }}>{this.state.messageErro}</span>
            <div className={classes.borderGreen}></div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}
export default withStyles(styles)(AppResetPassword);
