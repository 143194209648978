import React from 'react';
import '../../../backoffice.scss';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
const Globals = require("../../../Globals.json");

export default function Worksheets() {

  return (
    <ModuleListing
      title="Planilhas e Listas"
      buttonLabel="Adicionar novo item"
      typeModuleId={39}
      fullUrl={Globals.paths.backofficeEditWorksheets}
      urlReplacePattern=":worksheetId"
      showOrder />
  )

}

