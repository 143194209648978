import { useControlled } from '@material-ui/core';
import React, { Component } from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import api from '../../../../../services/api';
import "./FileUploader.scss";

const getRnd = () => {
    return Math.floor(Math.random() * 999999999999).toString(16);
}
export class FileUploader extends Component {

    state = {
        type: this.props.mediaType,
        fileName: null,
        fileUrl: null,
        message: '',
        changed: false,
        isUploading: false,
        uploadPercent: 0,
        fileUploadName: 'file-upload-' + getRnd(),
        fileDragName: 'file-drag-' + getRnd(),
        formName: 'file-upload-form-' + getRnd(),
    }

    // File Upload
    //
    init = () => {

        console.log("Upload Initialised");

        let fileSelect = document.getElementById(this.state.fileUploadName),
            fileDrag = document.getElementById(this.state.fileDragName);

        fileSelect.addEventListener('change', this.fileSelectHandler, false);

        // File Drop
        fileDrag.addEventListener('dragover', this.fileDragHover, false);
        fileDrag.addEventListener('dragleave', this.fileDragHover, false);
        fileDrag.addEventListener('drop', this.fileSelectHandler, false);
    }

    fileDragHover = (e) => {
        let fileDrag = document.getElementById(this.state.fileDragName);
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    fileSelectHandler = (e) => {

        this.output();

        // Fetch FileList object
        let files = e.target.files || e.dataTransfer.files;

        // Cancel event and hover styling
        this.fileDragHover(e);
        // Process all File objects
            for (let i = 0, f; f = files[i]; i++) {
                this.parseFile(f);
                this.uploadFile(f);
            }
    }

    // Output
    output = (msg) => {
        // Response
        this.setState({ message: msg });
    }

    parseFile = (file) => {
        this.setState({
            fileUrl: URL.createObjectURL(file),
            fileName: file.name
        });
    }

    updateFileProgress = (e) => {
        if (e.lengthComputable) {
            console.log("progress...", e.loaded, e.total);
            this.setState({ uploadPercent: e.loaded });
        };
    }

    uploadFile = (file) => {
        let fileSizeLimit = this.props.maxFileSize ?? 15; // In MB+
        // Check if file is less than x MB
        if (file.size > (fileSizeLimit * 1024 * 1024)) {
            this.output(`Arquivo excede o limite de ${fileSizeLimit}MB`)
            document.getElementById(this.state.formName).reset();
            this.updateFileConfig({});
            return;
        };

        const formData = new FormData();
        formData.append(this.state.type, file);

        if (this.props.folder) {
            formData.append("folder", this.props.folder);
        };

        this.setState({ isUploading: true });

        const url = this.props.url;

          api.post(url, formData, {
             headers: {
                 "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
             }
         }).then(
             response => {
 
                 const itens = {
                     url: response?.data?.url,
                     fileName: file.name    
                 }
                 this.updateFileConfig(itens);
 
             }
         ).catch(() => {
             console.log(formData)
             this.output(`Ocorreu um erro no upload do arquivo`)
         }
         ).finally(() => {
             this.setState({ isUploading: false });
         });

        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fileUrl !== this.props.fileUrl) {
            if (this.props.fileUrl) {
                this.setState({ fileUrl: this.props.fileUrl });
            }
        };
    }

    componentDidMount = () => {

        this.setState({
            fileUrl: this.props.fileUrl,
            fileName: this.props.fileName
        });

        if (window.File && window.FileList && window.FileReader) {
            // Check for the letious File API support.
            this.init();

        } else {
            document.getElementById(this.state.fileDragName).style.display = 'none';
        };
    }

    updateFileConfig = (fileConfig) => {
        this.setState(fileConfig);
        if (this.props.onChange) this.props.onChange(fileConfig);
    }

    render() {

        return (
            <div className="uploader">
                <form id={this.state.formName} >
                    <input id={this.state.fileUploadName} type="file" name="fileUpload" accept="" />

                    <label htmlFor={this.state.fileUploadName} id={this.state.fileDragName}>

                        {(this.state.fileUrl && !this.state.message) &&
                            <>

                                <AssignmentTurnedInIcon style={{ fontSize: 40 }} />
                                <div className="file-image-subtitle">Clique para selecionar ou solte um arquivo aqui.</div>
                            </>
                        }

                        {(!this.state.fileUrl && !this.state.message) &&
                            <div id="start">
                                <i className="fa fa-download" aria-hidden="true"></i>
                                <div>Clique para selecionar <br />ou solte um arquivo aqui.</div>
                                <span className="btn btn-primary">Selecionar...</span>
                            </div>
                        }

                        <div id="response">

                            {this.state.message &&
                                <>
                                    <AssignmentLateIcon style={{ fontSize: 40 }} />
                                    <div id="messages" style={{ color: "#900", marginBottom: '10px' }}>{this.state.message}</div>
                                    <div className="file-image-subtitle">Clique para selecionar ou solte um arquivo aqui.</div>
                                </>
                            }
                        </div>

                    </label>

                    {(this.state.fileUrl && !this.state.message) && <a style={{ display: 'block', textAlign: 'center' }} href={this.state.fileUrl}>{this.state.fileName}</a>}

                </form>
            </div>

        )
    }
}

export default FileUploader;