import React from "react";
import App from "./App";
import * as Sentry from '@sentry/react';
import { reduxSetup } from 'redux-easy';
import './redux/reducers';

Sentry.init({ dsn: "https://7e1e0f98bfd14046978a123042c69a5c@o328906.ingest.sentry.io/5381805" });

const initialState = {
  user: {
    profile: {
      user_avatar: '',
    }
  },
  courses: { selectedCourse: {} },
  tour: { show: false },
  isfb: 0,
  objectives: {
    steps: [],
  }
};

// The specified component is rendered in the element with
// id "root" unless the "target" option is specified.
reduxSetup({ component: <App />, initialState });

