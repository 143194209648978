import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Globals from '../../../../../Globals.json';
import api from '../../../../../services/api';
import Button from '../../../button/Button';
import '../../../../../backoffice.scss';

export default function ModuleListing({
  title,
  buttonLabel,
  typeModuleId,
  fullUrl,
  urlReplacePattern,
  hideDescription = false,
  hideThumbnail = false,
  showOrder = false,
  cellNameLabel = 'Nome',
  cellDescriptionLabel= 'Descrição',
  filterPlaceHolder = 'Digite filtro por nome ou descrição',
  customList = []
}) {
  let timeoutID = useRef();
  let filterText = useRef('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fullList, setFullList] = useState([]);
  const [filterField, setFilterField] = useState('');
  const [filter, setFilter] = useState('');
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(false);


  const loadData = useCallback(async () => {
    typeModuleId && await loadModules();
  }, [typeModuleId]);

  const loadModules = async () => {
    setIsLoading(true);
    const postData = {
      type_module_id: typeModuleId,
      type_module_status_ids: [0, 1, 2],
    };
    const response = await api.post(Globals.api.getModules, postData);
    setFullList(response?.data);
    setIsLoading(false);
  }

  const prepareFilter = (val) => {
    filterText.current = val;
    setFilterField(val);
    clearTimeout(timeoutID.current);
    timeoutID.current = setTimeout(() => setFilter(val), 500);
  };

  const getFilteredItems = () => {
    if (customList.length) {
      return customList.filter(item => filterFunc(item))
    }
    return fullList.filter((item) => filterFunc(item));
  };

  const filterFunc = (item, _) => {
    let ret =
      (item.name ?? '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      (item.desc ?? '').toLowerCase().indexOf(filter.toLowerCase()) > -1;
    ret =
      ret &&
      ((item.type_module_status_id === 1 && showActive) ||
        (item.type_module_status_id === 0 && showInactive));
    return ret;
  };

  const editItem = (item) => {
    history.push(fullUrl.replace(urlReplacePattern, item.id));
  };

  const newItem = () => {
    history.push(fullUrl.replace(urlReplacePattern, 'new'));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (isLoading) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid className="backoffice-content" style={{ marginBottom: '70px' }}>
      <Grid className="box">
        <div className="table_user_contentTexts">
          <span className="table_user_textList">{title}</span>
        </div>

        <div className="filter-container">
          Filtro
          <div className="filter-elements">
            <input
              className="filter-element"
              placeholder={filterPlaceHolder}
              value={filterField}
              style={{ width: '400px' }}
              onChange={(e) => prepareFilter(e.target.value)}
            />
            <FormControlLabel
              className="filter-element"
              control={
                <Switch
                  checked={showActive}
                  onChange={(e) => {
                    setShowActive(e.target.checked);
                    setShowInactive((!e.target.checked && !showInactive) || showInactive);
                  }}
                />
              }
              label="Exibir Ativos"
            />
            <FormControlLabel
              className="filter-element"
              control={
                <Switch
                  checked={showInactive}
                  onChange={(e) => {
                    setShowInactive(e.target.checked);
                    setShowActive((!e.target.checked && !showActive) || showActive);
                  }}
                />
              }
              label="Exibir Inativos"
            />
          </div>
        </div>

        <div className="button-right-container">
          <Button
            icon="none"
            textAlign="center"
            type={'darkBlue'}
            height="40px"
            onClick={() => newItem()}
          >
            {buttonLabel}
          </Button>
        </div>

        <TableContainer>
          {getFilteredItems().length === 0 && (
            <div className="table_user_showResult" style={{ padding: '50px' }}>
              <span>Não há nenhum item para o filtro informado.</span>
            </div>
          )}

          {getFilteredItems().length > 0 && (
            <Table className="table-list-items">
              <TableHead>
                <TableRow>
                  <TableCell width="40%">{cellNameLabel}</TableCell>
                  {!hideDescription && <TableCell width={showOrder ? '50%' : '60%'}>{cellDescriptionLabel}</TableCell>}
                  {showOrder && <TableCell width="10%">Ordem</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {getFilteredItems().map((item, index) => {
                    return (
                      <TableRow
                        onClick={() => editItem(item)}
                        className="tb-data-row"
                        style={{ opacity: item.type_module_status_id === 1 ? 1 : 0.35 }}
                        hover
                        key={index}
                      >
                        <TableCell width="40%" className="tb-data-cell" align="left">
                          <div className="listing-thumb-name">
                            {!hideThumbnail && (
                              <img alt="" className="tb-data-cell-thumb" src={item.thumbnail} />
                            )}
                            <div>{item.name}</div>
                          </div>
                        </TableCell>
                        {!hideDescription && (
                          <TableCell width={showOrder ? '50%' : '60%'} className="tb-data-cell" align="left">
                            {item.description}
                          </TableCell>
                        )}
                        {showOrder && (
                          <TableCell width="10%" className="tb-data-cell" align="left">
                            {item.data.order ? item.data.order : '-'}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
}

ModuleListing.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  typeModuleId: PropTypes.number.isRequired,
  fullUrl: PropTypes.string.isRequired,
  urlReplacePattern: PropTypes.string.isRequired,
  hideDescription: PropTypes.bool,
  hideThumbnail: PropTypes.bool,
};
