import { Dialog, DialogContent } from '@material-ui/core';
import React, { Component } from 'react'
import './styles/deleteUserModal.scss'

import Close from '../../../../assets/user/excluir_usuario.svg'

import Button from '../../button/Button'

import api from '../../../../services/api';
import Globals from '../../../../Globals.json';

export class InactivateContent extends Component {
  state = {
    success: false,
  }

  handleDeleteUser  = async () => {
    console.log('iniciando..')
    const postData =
    {
      "type_module_status_id": 2
    }
    await api.patch(Globals.api.inactivateContent.replace(':Id', this.props.id), postData).then(response => {
      console.log(response.data)
      this.setState({ openAlert: true })
      // this.loadData()
      this.setState({ success: true });
      setTimeout(() => {
        return this.setState({ openAlert: false })
      }, 5000)
    }).catch(err => {
      console.log(err)
      this.setState({ openAlertError: true })
      this.setState({ failed: true });
      setTimeout(() => {
        return this.setState({ openAlertError: false })
      }, 5000)
  })
}


  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={"xs"}
        className="modal"
      >
        <DialogContent className="modal_delete_user_container">

          <div className="modal_delete_user_header">
            <button type="button" className="close"
              onClick={this.props.onClose}>&times;
             </button>
          </div>

          <div className="modal_delete_user_close">
            <img src={Close} alt="Excluir" />
          </div>

          <section>
            <span>{this.state.success === false ?
              `Você tem certeza que deseja inativar o conteúdo?` :
              "Conteúdo inativado com sucesso!"}
            </span>
            <p>{this.state.success === false ?
              "Está ação vai ser registrada em nosso sistema." :
              "Foi registrado em nosso sitema que o conteúdo foi inativado."
            }
            </p>
          </section>

          {this.state.success === false ?
            <Button icon='none' type="danger" onClick={() => this.handleDeleteUser()} >CONFIRMAR</Button> :
            <Button to={Globals.paths.backofficeArticleListBlog} icon='none' type="danger">VOLTAR</Button>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

export default  InactivateContent;