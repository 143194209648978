import React from 'react';

import { withStyles, CircularProgress } from '@material-ui/core';
import { TurnedInNot } from '@material-ui/icons';

import api from '../../../services/api';

//Componentes
import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../button/Button';
import InitialModalQuiz from './InitialModalQuiz';
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';
import './styles/quiz.scss';

const Globals = require("../../../Globals.json");

const styles = theme => ({
  button_icon: {
    margin: "5px",
    padding: "3px",
    width: "30px",
    height: "30px",
    background: "rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    border: "none",

    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)",
    }
  },

  text_paragraph: {
    width: "260px",
    height: "80px",
    fontSize: "17px",
    paddingTop: "10px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",

    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      width: "200px",
      height: "75px",
      WebkitLineClamp: "4",
    },
  },

  card_recomendados: {
    backgroundColor: "#FFF",
    width: "300px",
    height: "300px",
    marginLeft: "50px",

    [theme.breakpoints.down('xs')]: {
      width: "230px",
      height: "250px",
    },
  },

  background_img: {
    width: "300px",
    height: "50%",

    [theme.breakpoints.down('xs')]: {
      width: "230px",
    },
  },

  border_back: {
    position: "relative",
    float: "right",
    marginTop: "-30px",
    width: "150px",
    borderBottom: "5px solid #FF6E5A",
    color: "#000",

    [theme.breakpoints.down('xs')]: {
      width: "125px",
    },
  },

  box_back: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "15px",
    color: "#202020",
    textTransform: "uppercase",
    float: "right",
    backgroundColor: "#FF6E5A",
    width: "100px",
    paddingTop: "10px",

    [theme.breakpoints.down('xs')]: {
      width: "90px",
      fontSize: "10px",
      heigth: "20px",
      marginTop: "5px",
      paddingTop: "5px",
    },
  },

  container_description: {
    marginTop: "-10px",
    paddingRight: "10px",
    paddingLeft: "20px",
    height: "80px",
    width: "100%",
  },


  positionBtn: {
    width: "130px",
    paddingLeft: "20px",
    paddingTop: "10px",
    textAlign: "center",

    [theme.breakpoints.down('xs')]: {
      paddingTop: "0px",
      width: "100px",
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    overflow: 'auto',
  }

});

class Quiz extends React.Component {
  state = {
    open: false,
    openFinalModal: false,
    isLoading: false,
    quiz: [],
    length: 0,
    id: 0,
    results: [],
    questions: []
  }

  async loadData () {
    this.setState({ isLoading: true });
    try {
      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";
      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      // console.log("List Recomendados:", response.data);
      this.setState({ quiz: response.data.slice(0, 3), isLoading: false });
      this.setState({ length: response.data.length });
      console.log('quiz', response.data)
    } catch (error) {
      if (error.response) {
        this.setState({ alertError: error?.response?.status })
      }
      else {
        this.setState({ alertError: error.message })
      }
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  addFavorites = (id) => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: id,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        this.loadData(true)
      }).catch(error => {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  removeFavorites = (id) => {
    const postData = {
      type_action_id: 26,
      data_modules_detail_id: id,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        this.loadData(true)
        console.log(response.data)
      }).catch(error => {
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  openFinalModal = () => {
    this.setState({ open: false, openFinalModal: true });
  }

  closeFinalModal = () => {
    this.setState({ openFinalModal: false })
  }

  againQuiz = (id) => {
    this.setState({ id: id, open: true, openFinalModal: false });
  }

  componentDidMount () {
    this.loadData();
  }

  getCoins = (rewards) => {

    // console.log( "GET_COINS:", rewards );
    if (!rewards) return null;
    const coins = rewards.filter((i) => i.asset_id === 1);
    if (coins.length > 0) {
      return coins[0];
    }
    else {
      return null;
    }

  }

  getCoins = (rewards) => {

    // console.log( "GET_COINS:", rewards );
    if (!rewards) return null;
    const coins = rewards.filter((i) => i.asset_id === 1);
    if (coins.length > 0) {
      return coins[0];
    }
    else {
      return null;
    }

  }

  render () {

    const { classes } = this.props;

    return (
      <>
        {this.state.isLoading ?
          <>
            <CircularProgress />
            <AlertErrors error={this.state.alertError} />
          </> :
          <>
            {this.state.quiz.map((item, index) => (

              <div key={index} className={classes.card_recomendados}>
                <div className={classes.background_img} style={{ backgroundImage: `url(${item.thumbnail})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                  {/* <div className={classes.button_save}>
                    <button className={classes.button_icon}
                      onClick={() => { item.is_favorite !== null ? this.removeFavorites(item.id) : this.addFavorites(item.id) }}>
                      {item.is_favorite !== null ?
                        <TurnedInNot style={{ color: "#F2A824" }} /> : <TurnedInNot style={{ color: "#FFF" }} />
                      }
                    </button>
                  </div> */}
                </div>

                {getCoinsFromRewards(item.data.rewards) > 0 &&
                  <div className={classes.border_back}>
                    <div className={classes.box_back}>
                      {getCoinsTextFromRewards(item.data.rewards)}
                    </div>
                  </div>
                }

                <div className={classes.container_description}>
                  <p className={classes.text_paragraph}>
                    {item.name}
                  </p>
                </div>

                <div className={classes.positionBtn}>
                  {/* <Button icon="none" onClick={() => this.setState({ id: index, open: true })}> */}
                  <Button icon="none" to={Globals.paths.quizTest.replace(':quizId', item.id)}>
                    Descobrir
                  </Button>
                </div>
              </div>

            ))}

            {/* Modal para realização do Quiz */}

            {/* {this.state.open &&
              <InitialModalQuiz dados={this.state.questions} onClose={() => this.setState({ open: false })} open={this.state.open}
                endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} id={this.state.id} onCloseQuiz={this.openFinalModal} />
            } */}
            {this.state.open &&
              <InitialModalQuiz dados={this.state.questions} onClose={() => this.setState({ open: false })} open={this.state.open}
                endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} id={this.state.id} onCloseQuiz={this.openFinalModal} />
            }

            {/* {this.state.openFinalModal &&
              <Modal
                open={this.state.openFinalModal}
                onClose={this.closeFinalModal}
                aria-labelledby="modal"
                aria-describedby="modal"
                className={classes.modal}
              >
                <FinalModal dados={this.state.results} closeFinalModal={this.closeFinalModal} indexId={this.state.id} againQuiz={() => this.againQuiz(this.state.id)} />
              </Modal>
            } */}

          </>
        }
      </>

    )
  }

}

export default withStyles(styles)(Quiz);
