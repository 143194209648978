import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const OurNumbers = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 33% 43%, #fff 44.27%, #e5e5e5 100%);
  padding-top: 130px;
  
  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 120px;
  }

  .inner-wrapper-container{
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .inner-wrapper {
      align-items: center;
      display: flex;
      max-width: 1440px;
      margin: auto;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 10px;
      padding-top: 10px;

      @media(min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-left: 10px;
        padding-right: 10px;
      }


      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        flex-direction: column;
      }

      @media(
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        flex-direction: column; 
      }
    
      .row-our-numbers {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          flex-direction: column;
        }
      }

      .flex-our-numbers-column {
        flex-direction: column;
      }

      .b-rad-10px{
        border-radius: 10px;
      }

      .col-40pct {
        display: flex;
        width: 40%;
        
        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
            width: 100%;
        }
      }

      .col-50pct {
        display: flex;
        width: 50%;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          width: 100%;
        }
      }

      .col-50pct-force {
        width: 50% !important;
      }

      .col-50pct-force-min-500px {
        @media(min-width: 500px) {
          width: 50% !important;
        }
      }

      .col-60pct {
        display: flex;
        width: 60%;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          width: 100%;
        }
      }

      .col-100pct {
        display: flex;
        width: 100%;
      }

      .display-none-seletivo {
        @media(max-width: 440px) {
          height: 0px !important;
          min-height: 0px !important;
          display: none;
        }

        @media(min-width: 961px) 
        and (max-width: 1090px){
          height: 0px !important;
          min-height: 0px !important;
          display: none;
        }
      }

      .display-none-minus-500px {
        @media(max-width: 500px) {
          height: 0px !important;
          min-height: 0px !important;
          display: none;
        }
      }

      .h-min-160px {
        min-height: 160px;
      }

      .h-min-280px {
        min-height: 280px;
      }

      .h-240px {
        height: 240px;
      }

      .h-280px {
        height: 280px;
      }

      .h-min-280px-auto {
        height: auto;
        min-height: 280px;
      }

      .h-300px {
        height: 300px;
      }

      .h-340px {
        height: 340px;
      }

      .h-380px {
        height: 380px;
      }

      .h-400px {
        height: 400px;
      }

      .h-420px {
        height: 420px;
      }

      .h-700px-small-devices-300px-big-devices {
        min-height: 300px;
        
        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          max-height: 700px;
        }
      }

      .h-min-400px-auto {
        height: auto;
        min-height: 400px;
      }

      .h-800px {
        height: 800px;
      }

      .h-100pct {
        height: 100%;
      }

      .h-60pct {
        height: 60%;
      }

      .h-40pct {
        height: 40%;
      }

      .bg-cell-color-1 {
          background-color: #EDEDED;
      }

      .bg-cell-color-2 {
        background-color: #ffffff;
      }

      .font-color-1 {
        color: #595959;
      }

      .block-title-style {
        color: #005E50;
        font-weight: 700;
      }

      .w-100pct {
        width: 100%;
      }

      .w-80px {
        width: 80px;
      }

      .w-200px {
        width: 200px;
      }

      .pad-15px {
        padding: 15px;
      }

      .pad-bottom-10px {
        padding-bottom: 10px;
      }

      .mapa-demografico{
        @media(min-width: ${({ theme }) => `625px`}) {
          height: 95%;
        }
      }

      .pos_relative {
        position: relative
      }
      
      .pos_absolute {
        position: absolute!important
      }

      .vert-center {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          position: relative;
        }
      }

      .m-left-50px {
        margin-left: 50px;
      }

      .disable-m-left-small-devices {
        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin-left: 0px;
        }
      }

      .reverse-flex-small-devices { 
        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          flex-direction: column-reverse;
        }
      }

      .responsive-image{
        width: 100%;
        height: auto;
      }

      h3 {
        font-size: 36px;
        font-family: 'elza';
        font-weight: 700;
        line-height: 40px;
        color: #005E50;
  
        &.thin {
          font-family: 'elza';
          font-size: 32px;
          font-weight: 500;
  
          @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
            font-size: 26px;
            line-height: 30px;
          } 
  
          strong {
            display: block;
            font-family: 'elza';
            font-weight: 700;
            font-size: 36px;
  
            @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
              font-size: 28px;
            } 
          }

          &.no-top-margin {
            margin-top: 0px;
          } 
        }
      }

      .place-c-center{
        place-content: center
      }

      .t-align-right {
        text-align: right;
      }

      .usuarios-totais{
        text-align: center;
        font-size: 6em;
        font-weight: 800;
        margin: 0px
      }

      .usuarios-totais-interno{
        font-size: 2em;
        font-weight: 800;
        margin: 0px
      }

      .usuarios-totais-interno-moda{
        font-size: 1.5em;
        font-weight: 800;
        margin: 0px
      }

      .subtitulo-tipos-usuarios{
        color: #595959;
      }

      .flex-direction-row-force{
        flex-direction: row;
      }

      .flex-direction-column-force{
        flex-direction: column;
      }

      .flex-direction-row-swap-440px{
        flex-direction: row;
        
        @media(max-width: 440px) {
          flex-direction: column;
          gap: 0px;
        }
      }

      .vert-line {
        border-left: 1px solid grey;
        height: 100%;
      }
      
      .usuarios-totais-porcentagem-table{
        display: table;
        margin-top: 15px;
        margin-bottom: 15px;

        .usuarios-totais-porcentagem-cell{
          display: table-cell;
          vertical-align: middle;
          background-color: #05CCAE;
          padding-top: 3px;
          border-radius: 15px;
          width: 55px;
          text-align: center;
          font-size: 1em;
          font-weight: 500;
        }
      }

      .just-cont-sb{
        justify-content: space-between;
      }

      .just-cont-fend{
        justify-content: flex-end;
      }

      .moda-indice-table{
        display: table;

      }
      .moda-indice-row{
        display: table-row;
      }

      .moda-indice-cell{
        display: table-cell;
        vertical-align: middle;
        padding-right: 10px;
        padding-bottom: 10px;
      }

      .right-0px {
        right:0px;
      }

      .cell-rounded {
        background-color: #595959;
        padding: 10px;
        padding-top: 14px;
        border-radius: 15px;
        text-align: center;
        color: #fff;
      }

      .font-size-2em {
        font-size: 2em;
      }

      .pad-top-4px {
        padding-top: 4px;
      }

      .top-30px-neg {
        top: -30px;
      }

      .usuarios-totais-provas-interno {
        font-size: 2.8em;
        font-weight: 800;
        margin: 0px

        &.cursos-realizados{
          display: flex;
          height: 130px;
          align-items: center;
          font-size: 3.5em;
        }
      }


      .circulo-1 {
        height: 100px;
        width: 100px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-2 {
        height: 88px;
        width: 88px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-3 {
        height: 76px;
        width: 76px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-4 {
        height: 64px;
        width: 64px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-5 {
        height: 52px;
        width: 52px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-6 {
        height: 40px;
        width: 40px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-7 {
        height: 28px;
        width: 28px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .circulo-pequeno {
        height: 28px;
        width: 28px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }

      .d-flex {
        display: flex;
      }

      .circles-big-content {
        align-items: flex-end;
        justify-content: space-around;
        gap: 10px;
      }

      .circles-small-content {
        align-items: center;
        justify-content: center;
        gap: 40px;
      }

      .circles-small-content-legends {
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media(max-width: 690px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 0px;
        }

        @media(min-width: 961px) 
        and (max-width: 1090px){
          align-items: flex-start;
          flex-direction: column;
          gap: 0px;
        }
      }

      .gap-64px {
        gap: 64px;
      }

      .gap-10px {
        gap: 10px;
      }

      .circles-small-content-small-devices {
        @media(max-width: 500px) {
          flex-direction: row;
          justify-content: center;

          & .circles-small-content {
            gap: 10px;
          }
        }
      }

      .circles-container {
        @media(max-width: 500px) {
          flex-direction: column;
        }
      }

      .map-container {
        flex-direction: column;
      }

      .d-flex-align-end {
        display: flex;
        align-items: flex-end;
      }

      .zoom-btns {
        max-width: 50px;
        
        & button {
          height: 24px;
          width: 24px;
          background-color: #fff;
          border-width: 1px;
          border-color: #05ccae;
          color: #005e50;
        }

        & button:hover {
          background-color: #eee;
        }
      }

      .map-content-legends {
        align-items: center;
        justify-content: center;
        gap: 20px;
      }

      .map-totais-content-legends{
        font-size: 1.0em;
        font-weight: 800;
        margin: 0px
      }

      .map-content-legends-cell{
        display: table-cell;
        vertical-align: middle;
        padding-right: 5px;
        padding-bottom: 5px;
      }

      .text-uppercase{
        text-transform: uppercase;
      }

      .b-color-rank-1 {
        background-color: #005E50
      }

      .b-color-rank-2 {
        background-color: #05CCAE
      }

      .b-color-rank-3 {
        background-color: #DAF439
      }

      .b-color-rank-4 {
        background-color: #595959
      }

      .b-color-rank-5 {
        background-color: #202020
      }

      .b-color-rank-6 {
        background-color: #B0B0B0
      }

      .b-color-rank-7 {
        background-color: #FF6E5A
      }

      .custom-tooltip-persona{
        background-color: #FFFFFF;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        border: 1px solid grey;
      }
    }
  }

`);
