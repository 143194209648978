import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export const Container = withTheme(styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100100;

  .content {
    > * {
      box-sizing: border-box;
    }

    position: absolute;
    bottom: 0;
    background-color: #fff;
    width: 100%;

    .innerWrapper {
      display: flex;
      align-items: flex-start;
      margin: auto;
      max-width: 1368px;
      padding: 30px;

      /* MOBILE */
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        flex-direction: column;
      }

      p {
        margin: 0;
        padding: 0;
        margin-right: 50px;
        font-size: 16px;

        /* MOBILE */
        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          font-size: 12px;
          text-align: justify;
          margin-right: 0;

          a {
            font-size: 12px;
          }
        }

        a {
          margin: 0;
          padding: 0;
          color: ${({ theme }) => `${theme.palette.primary.main}`};

          &:hover {
            color: ${({ theme }) => `${theme.palette.secondary.main}`};
          }
        }
      }

      button {
        border: 0;
        cursor: pointer;
        background-color: #005E50;
        color: #fff;
        min-width: 200px;
        font-size: 18px;
        padding: 15px 10px;
        border-radius: 3px;
        text-transform: uppercase;

        &:hover {
          background-color: #595959;
        }

        /* MOBILE */
        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          width: 100%;
          margin-top: 30px;
          font-size: 14px;
        }
      }
    }
  }
`);
