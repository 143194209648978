import React from 'react';
//import './styles/courserRecoResponsive.scss';
import './styles/courseListResp.scss';

import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';

import Button from '../button/Button';
import Pagination from "../../components/pagination/Pagination";
import SwipeableViews from "react-swipeable-views";

import { TurnedInNot } from '@material-ui/icons';
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const Globals = require("../../../Globals.json");

class CourseRecommended extends React.Component {
  state = {
    isLoading: false,
    courses: [],
    gridCols: 3,
    alertError: '',
    index: 0,
  }

  async loadData () {
    try {
      this.setState({ isLoading: true });

      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      console.log("Payload:", postData)

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";

      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      this.setState({ courses: response?.data.slice(0, 3), isLoading: false });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }


  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  componentDidMount () {
    this.loadData();
  }

  goToCourse = (courseId) => {
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", courseId));
  }

  render () {

    const { index } = this.state;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ?
          <Grid>
            <Box pt={0.5}>
              <Skeleton variant="rect" width={"100%"} height={170} />
            </Box>
          </Grid> :
          <div>
            <div className="course_resp_container">
              <div className="course_resp_root">
                <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
                  {this.state.courses.map((item, index) => (
                    <div className="course_resp_root2" key={index}>
                      <div className="course_resp_itemBox">
                        <div className="course_resp_toolImage" style={{ backgroundImage: `url(${item.thumbnail})` }}>
                          <div className="course_resp_toolContainerText">
                            <div className="course_resp_containerTop">
                              <div></div>
                              {getCoinsFromRewards(item.data.rewards) > 0 &&
                                <div className="course_resp_card" >
                                  {getCoinsTextFromRewards(item.data.rewards)}
                                </div>
                              }
                            </div>
                            <div className="course_resp_texts">
                              <span className="course_resp_toolTitle">{item.name}</span>
                              <span className="course_resp_toolText">{item.description}</span>
                            </div>

                            <div className="course_resp_courseButtonContainer">
                              <Button onClick={() => this.goToCourse(item.id)} type="invert">
                                {item.user_module_data.finishedCourseAt ? 'Ver novamente' :
                                  item.user_module_data.startedAt ? "Continuar" : "Começar"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </SwipeableViews>

                <Pagination
                  dots={this.state.courses.length}
                  index={index}
                  onChangeIndex={this.handleChangeIndex}
                />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
export default CourseRecommended;