import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Snackbar,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  IconButton,
  Divider,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router';
import '../../../../backoffice.scss';
import './tenant.scss';
import Dialogs from '../../../../services/dialogs';
import { Visibility, VisibilityOff, Add, Close } from '@material-ui/icons';

import api from "../../../../services/api";
import { Link } from 'react-router-dom';
import Button from '../../button/Button';
import TenantTableModal from './TenantTableModal/TenantTableModal';
import TenantParamsModal from './TenantParamsModal/TenantParamsModal';

const Globals = require("../../../../Globals.json");

const TenantDetails = () => {
  const params = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [id, setId] = useState('');

  // dialog
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [editMode, setEditMode] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCredential, setIsLoadingCredential] = useState(false);


  const [showCredentials, setShowCredentials] = useState(false);
  const [showClientId, setShowClientId] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);

  const [changed, setChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 2,
    borderRadius: 4,
  };

  const [form, setForm] = useState({
    name: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['required']
    },
    description: {
      value: '',
      error: false,
      errorMessage: '',
      validations: []
    },
    logoUrl: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['required', 'isUrl']
    },
    relationEndpoint: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['isUrl', 'required']
    },
    incentiveEndpoint: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['isUrl', 'required']
    },
    authenticationEndpoint: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['isUrl', 'required']
    },
    active: {
      value: true,
      error: false,
      errorMessage: '',

    },
    userCanHaveRelationship: {
      value: true,
      error: false,
      errorMessage: '',
    },
    apiClientId: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['required']
    },
    apiClientSecret: {
      value: '',
      error: false,
      errorMessage: '',
      validations: ['required']
    },
    relationScope: {
      value: '',
      error: false,
      errorMessage: '',
    },
    incentiveScope: {
      value: '',
      error: false,
      errorMessage: '',
    },
  })

  const [relationCustomParams, setRelationCustomParams] = useState([]);
  const [authenticationCustomParams, setAuthenticationCustomParams] = useState([]);
  const [incentiveCustomParams, setIncentiveCustomParams] = useState([]);

  const [paramsModalEditingType, setParamsModalEditingType] = useState('')
  const [paramsModalEditingData, setParamsModalEditingData] = useState([])

  const [apiCredentials, setApiCredentials] = useState({
    clientId: '',
    clientSecret: ''
  })

  const [showPassword, setShowPassword] = useState(false)

  const updateModalEditingParams = (data) => {

    switch (paramsModalEditingType) {
      case 'relation':
        setRelationCustomParams(data)
        break;
      case 'authentication':
        setAuthenticationCustomParams(data);
        break;
      case 'incentive':
        setIncentiveCustomParams(data);
        break;
      default:
    }

  }

  const handleOpenModal = (e, type) => {
    e.preventDefault()
    setParamsModalEditingType(type)
    switch (type) {
      case 'relation':
        setParamsModalEditingData(relationCustomParams)
        break;
      case 'authentication':
        setParamsModalEditingData(authenticationCustomParams);
        break;
      case 'incentive':
        setParamsModalEditingData(incentiveCustomParams);
        break;
      default:
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeValue = (event, fieldName) => {
    setChanged(true)
    const values = { ...form };
    values[fieldName].value = event.target.value;
    setForm(values)
  }
  const handleChangeValueToggle = (event, fieldName) => {
    setChanged(true)
    const values = { ...form };
    values[fieldName].value = event.target.checked;
    setForm(values)
  }

  const checkFieldErrors = (fieldName) => {
    const values = { ...form }
    if (values[fieldName].validations?.includes('required')) {
      if (values[fieldName].value === null || values[fieldName].value === undefined || values[fieldName].value?.trim() === '') {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Campo obrigatório.'
        setForm(values)
        return true
      }
    }

    if (values[fieldName].validations?.includes('isUrl')) {

      const pattern = new RegExp('^((http|https):\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locater

      const valid = pattern.test(values[fieldName].value);

      let doubleSlashsCounter = 0
      if (values[fieldName].value) {
        for (let i = 0; i < values[fieldName].value.length - 1; i++) {

          if (values[fieldName].value[i] === '/' && values[fieldName].value[i + 1] === '/') {
            doubleSlashsCounter++
          }
        }
      }

      if (values[fieldName].value && (!valid || doubleSlashsCounter > 1)) {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Url inválida.'
        setForm(values)
        return true
      }
    }
    if (values[fieldName].validations?.includes('isValidEndpoint')) {

      const pattern = new RegExp('^((\\/))?' + // start /
        '(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // path

      const valid = pattern.test(values[fieldName].value);  //valid

      let hasDoubleSlashs = false
      if (values[fieldName].value) {
        for (let i = 0; i < values[fieldName].value.length - 1; i++) {
          if (values[fieldName].value[i] === '/' && values[fieldName].value[i + 1] === '/') {
            hasDoubleSlashs = true
          }
        }
      }


      if (values[fieldName].value && (!valid || hasDoubleSlashs)) {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Endpoint inválido.'
        setForm(values)
        return true
      }
    }
    return false
  }

  const resetAllFieldsErrors = () => {
    const values = { ...form }
    Object.keys(values).forEach((key) => {
      resetFieldErrors(key)
    })
  }

  const checkAllFieldsErrors = () => {
    let hasErrors = false
    const values = { ...form }
    Object.keys(values).forEach((key) => {
      if (checkFieldErrors(key)) {
        hasErrors = true
      }
    })
    return hasErrors
  }
  const resetFieldErrors = (fieldName) => {
    const values = { ...form }
    values[fieldName].error = false
    values[fieldName].errorMessage = ''
    setForm(values)
  }

  const removeLastSlash = (url) => {
    let newUrl = url
    if (newUrl) {
      while (newUrl[newUrl.length - 1] === '/') {
        newUrl = newUrl.slice(0, -1);
      }
    }
    return newUrl
  }
  const removeUselessSlashsFromBegin = (url) => {
    let newUrl = url
    while (newUrl[0] === '/' && newUrl[1] === '/') {
      newUrl = newUrl.substring(1);
    }
    return newUrl
  }
  const addSlashToBegin = (url) => {
    let newUrl = url
    if (newUrl[0] !== '/' && newUrl[0]) {
      newUrl = '/' + newUrl;
    }
    return newUrl
  }

  const validadeUrlBase = (e, fieldName) => {
    let newValue = e.target.value?.trim()
    newValue = removeLastSlash(newValue)
    const values = { ...form };
    values[fieldName].value = newValue
    setForm(values)
    checkFieldErrors(fieldName)
  }

  const validadeUrlEndpoint = (e, fieldName) => {
    let newValue = e.target.value?.trim()
    newValue = removeLastSlash(newValue)
    newValue = removeUselessSlashsFromBegin(newValue)
    newValue = addSlashToBegin(newValue)
    const values = { ...form };
    values[fieldName].value = newValue
    setForm(values)
    checkFieldErrors(fieldName)
  }

  const validadeStringField = (e, fieldName) => {
    checkFieldErrors(fieldName)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loadData = async (id) => {
    setIsLoading(true);
    try {
      await api.get(Globals.api.backofficeTenant.replace(':tenantId', id))
        .then((res) => {
          if (res.status === 200) {
            setData(res.data)
            populateForm(res.data)
          } else {
            history.push(Globals.paths.getBackofficeTenantDetail.replace(':tenantId', 'novo'))
          }
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false);
  }


  const loadCredentials = async (e) => {
    e.preventDefault()
    setIsLoadingCredential(true);
    try {
      await api.post(Globals.api.backofficeTenantSecret, { api_tenant_id: id })
        .then((res) => {
          if (res.status === 200) {

            const values = { ...apiCredentials }
            values.clientId = res.data.client_id
            values.clientSecret = res.data.client_secret
            setApiCredentials(values)
            setShowCredentials(true)
          } else {

          }
        })
    } catch (error) {

    }
    setIsLoadingCredential(false);
  }
  const generateNewCredentials = async () => {
    setIsLoadingCredential(true);
    try {
      await api.post(Globals.api.backofficeTenantGenerateNewKeys, { api_tenant_id: id })
        .then((res) => {
          if (res.status === 200) {
            const values = { ...apiCredentials }
            values.clientId = res.data.client_id
            values.clientSecret = res.data.client_secret
            setApiCredentials(values)
            setShowCredentials(true)
          } else {

          }
        })
    } catch (error) {

    }
    setIsLoadingCredential(false);
  }

  const populateForm = (data) => {
    const values = { ...form };
    values.name.value = data.name;
    values.userCanHaveRelationship.value = data.user_can_relate;
    values.active.value = data.active;
    values.logoUrl.value = data.logo_url;
    values.description.value = data.description;
    values.relationEndpoint.value = data.relation_endpoint;
    values.incentiveEndpoint.value = data.incentive_endpoint;
    values.relationScope.value = data.relation_scope;
    values.incentiveScope.value = data.incentive_scope;
    values.authenticationEndpoint.value = data.authentication_endpoint;
    values.apiClientId.value = data.api_client_id;
    values.apiClientSecret.value = data.api_client_secret;
    setRelationCustomParams(data.relation_custom_params);
    setAuthenticationCustomParams(data.authentication_custom_params);
    setIncentiveCustomParams(data.incentive_custom_params);
    setForm(values);
    setId(data.id);
  }

  useEffect(() => {
    if (history.location.state?.editMode) {
      setEditMode(true)
    }
    if (params.tenantId !== 'novo') {
      loadData(params.tenantId)
    } else {
      setEditMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerateNewKeysConfirmation = (e) => {
    e.preventDefault()
    Dialogs.show(
      <div>ATENÇÃO</div>,
      'Você tem certeza que deseja gerar novas credenciais? As credenciais em uso deixarão de funcionar.',
      [
        {
          label: 'Cancelar',
        },

        {
          label: "Sim",
          onClick: () => generateNewCredentials(),
        },
      ]
    )
  }

  const success = () => {
    Dialogs.show(
      <div>SUCESSO</div>,
      'Sua instituição financeira foi salva com sucesso',
      [
        {
          label: "OK",
          // onClick: () => history.push(Globals.paths.backofficeTenants),
        },
      ]
    )
  }
  const checkCredentialsFieldsErrors = () => {
    let hasErrors = false
    const fieldsToCheck = ['authenticationEndpoint', 'apiClientId', 'apiClientSecret']
    fieldsToCheck.forEach((field) => {
      if (checkFieldErrors(field)) {
        hasErrors = true
      }
    })
    return hasErrors
  }

  const handleCheckCredentials = (e) => {
    e.preventDefault()

    resetAllFieldsErrors()

    const hasErrors = checkCredentialsFieldsErrors()
    if (hasErrors) {
      return
    }

    checkCredentials()
  }

  const checkCredentials = async () => {
    setIsLoading(true);
    const postData = {
      authentication_endpoint: form.authenticationEndpoint.value,
      api_client_id: form.apiClientId.value,
      api_client_secret: form.apiClientSecret.value,
      relation_scope: form.relationScope.value,
      incentive_scope: form.incentiveScope.value,
      relation_custom_params: relationCustomParams,
      authentication_custom_params: authenticationCustomParams,
      incentive_custom_params: incentiveCustomParams,
    }
    try {

      await api.post(Globals.api.backofficeTenantCheckCredentials, postData)
        .then((res) => {
          setSeverity('success')
          setAlertMessage(typeof (res.data?.msg) === 'string' ? res.data.msg : 'Credenciais válidas.')
          handleOpenMessage()
        })

    } catch (error) {
      setSeverity('error')
      setAlertMessage(typeof (error?.response?.data?.msg?.error?.message) === 'string' ? (<>
        Credenciais inválidas: <br />
        {error?.response?.data?.msg?.error?.message}
      </>) : 'Credenciais inválidas.')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }

  };

  const saveData = async () => {
    setIsLoading(true);
    const postData = {
      name: form.name.value,
      user_can_relate: form.userCanHaveRelationship.value,
      active: form.active.value,
      logo_url: form.logoUrl.value,
      description: form.description.value,
      relation_endpoint: form.relationEndpoint.value,
      incentive_endpoint: form.incentiveEndpoint.value,
      relation_scope: form.relationScope.value,
      incentive_scope: form.incentiveScope.value,
      authentication_endpoint: form.authenticationEndpoint.value,
      api_client_id: form.apiClientId.value,
      api_client_secret: form.apiClientSecret.value,
      relation_custom_params: relationCustomParams,
      authentication_custom_params: authenticationCustomParams,
      incentive_custom_params: incentiveCustomParams,
    }
    try {
      if (id) {
        await api.put(Globals.api.backofficeTenant.replace(':tenantId', id), postData)
          .then((res) => {
            populateForm(res.data)
            setData(res.data)
            setChanged(false)
            success()
          })
      } else {
        await api.post(Globals.api.backofficeTenants, postData)
          .then((res) => {
            populateForm(res.data.tenant)
            setData(res.data.tenant)
            setChanged(false)
            success()
          })
      }
    } catch (error) {
      setSeverity('error')
      setAlertMessage(typeof (error?.response?.data) === 'string' ? error.response.data : 'Não foi possível salvar a instituição financeira.')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }

  };

  const handleEnableEditMode = () => {
    setEditMode(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (checkAllFieldsErrors()) {
      return
    }
    saveData()
  }

  const handleBack = (event) => {
    event.preventDefault();

    if (editMode && id && changed) {
      const values = { ...form };

      const newData = {
        id: id,
        name: values.name.value,
        user_can_relate: values.userCanHaveRelationship.value,
        active: values.active.value,
        logo_url: values.logoUrl.value,
        description: values.description.value,
        relation_endpoint: values.relationEndpoint.value,
        incentive_endpoint: values.incentiveEndpoint.value,
        incentive_scope: values.incentiveScope.value,
        relation_scope: values.relationScope.value,
        authentication_endpoint: values.authenticationEndpoint.value,
        api_client_id: values.apiClientId.value,
        api_client_secret: values.apiClientSecret.value,
        relation_custom_params: relationCustomParams,
        authentication_custom_params: authenticationCustomParams,
        incentive_custom_params: incentiveCustomParams,
      }

      const { name, user_can_relate, active, logo_url, description, relation_endpoint, relation_scope, incentive_endpoint, incentive_scope, authentication_endpoint, api_client_id, api_client_secret, relation_custom_params, authentication_custom_params, incentive_custom_params } = data;
      const oldData = {
        id: data.id,
        name,
        user_can_relate,
        active,
        logo_url,
        description,
        relation_endpoint,
        incentive_endpoint,
        incentive_scope,
        relation_scope,
        authentication_endpoint,
        api_client_id,
        api_client_secret,
        relation_custom_params,
        authentication_custom_params,
        incentive_custom_params
      }

      if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações na sua instituição financeira, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeTenants),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeTenants)
      }

    } else {
      history.push(Globals.paths.backofficeTenants)
    }
  }


  return (
    <div className="tenant-datails">
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        id="tenant-params-modal"
        maxWidth="md"
        fullWidth


      >
        <Grid container justifyContent='space-between' className='header-modal'>
          <h2>Parâmetros</h2>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
          >
            <Close style={{ color: '#fff' }} />
          </IconButton>
        </Grid>

        <DialogContent>
          <TenantParamsModal
            handleSetState={updateModalEditingParams}
            data={paramsModalEditingData}
          />

          {/* <TenantTableModal data={mockTable} /> */}
        </DialogContent>

        <DialogActions className='action-modal'>
          <Button
            icon="none"
            textAlign="center"
            height="40px"
            onClick={handleCloseModal}
          >
            {'fechar'}
          </Button>

        </DialogActions>
      </Dialog>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode && data.id ? `Editando: ${data.name}` : editMode ? 'Nova instituição financeira' : data.name ? data.name : 'Nova instituição financeira'}</span>

            <Button
              disabled={editMode}
              type={editMode ? 'disabled' : 'full-solid'}
              icon="none"
              textAlign="center"
              height="40px"
              onClick={handleEnableEditMode}
            >
              {'Editar'}
            </Button>

          </div>

          <form className="form-matrix">

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={form.name.error}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Nome *</FormLabel>


              <OutlinedInput
                disabled={!editMode}
                margin="dense"
                value={form.name.value}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Nome"

                autoComplete="off"
                type="text"
                onChange={(e) => handleChangeValue(e, 'name')}
                onBlur={(e) => validadeStringField(e, 'name')}
                onFocus={() => resetFieldErrors('name')}

              />
              <FormHelperText>{form.name.errorMessage}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={form.description.error}
              variant="standard"
              fullWidth


            >
              <FormLabel component="legend">Descrição</FormLabel>
              <OutlinedInput
                disabled={!editMode}
                margin="dense"
                multiline
                minRows={3}
                maxRows={5}
                value={form.description.value}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Descrição"
                autoComplete="off"
                type="text"
                onChange={(e) => handleChangeValue(e, 'description')}
                onBlur={(e) => validadeStringField(e, 'description')}
              />
              <FormHelperText>{form.description.errorMessage}</FormHelperText>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.apiClientId.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">API client ID *</FormLabel>

                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    autoComplete="new-password"

                    value={form.apiClientId.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="API client ID"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'apiClientId')}
                    onBlur={() => checkFieldErrors('apiClientId')}
                    onFocus={() => resetFieldErrors('apiClientId')}
                  />
                  <FormHelperText>{form.apiClientId.errorMessage}</FormHelperText>
                </FormControl>

              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.apiClientSecret.error}
                  variant="standard"
                  fullWidth >
                  <FormLabel component="legend">API client secret *</FormLabel>
                  <OutlinedInput
                    disabled={!editMode}

                    type={showPassword ? 'text' : 'password'}
                    value={form.apiClientSecret.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="Senha"
                    autoComplete="new-password"
                    margin="dense"
                    onChange={(e) => handleChangeValue(e, 'apiClientSecret')}
                    onBlur={(e) => validadeStringField(e, 'apiClientSecret')}
                    onFocus={() => resetFieldErrors('apiClientSecret')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                  <FormHelperText>{form.apiClientSecret.errorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.logoUrl.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">URL do logotipo *</FormLabel>


                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.logoUrl.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="https://www.exemplo.com.br/imagem.png"
                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'logoUrl')}
                    onBlur={(e) => validadeStringField(e, 'logoUrl')}
                    onFocus={() => resetFieldErrors('logoUrl')}
                  />
                  <FormHelperText>{form.logoUrl.errorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='column'>
              <Grid item xs={12} class="url-fields">
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.relationEndpoint.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">URL de relacionamento *</FormLabel>


                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.relationEndpoint.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="https://www.exemplo.com.br/validate"

                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'relationEndpoint')}
                    onBlur={(e) => validadeStringField(e, 'relationEndpoint')}
                    onFocus={() => resetFieldErrors('relationEndpoint')}
                  />
                  <FormHelperText>{form.relationEndpoint.errorMessage}</FormHelperText>
                </FormControl>

                <Button className='badge-button'
                  disabled={!editMode}
                  icon={<Add />}
                  textAlign="center"
                  type={!editMode ? 'disabled' : 'full-solid'}
                  height="40px"
                  onClick={(e) => handleOpenModal(e, 'relation')}
                >
                  <div className='badge'>
                    {relationCustomParams.length}
                  </div>
                  {' Parâmetros'}
                </Button>
                

              </Grid>
              <Grid item xs={12} class="url-fields">
                <FormControl

                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.incentiveEndpoint.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">URL de resgate *</FormLabel>


                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.incentiveEndpoint.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="https://www.exemplo.com.br/reedem"
                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'incentiveEndpoint')}
                    onBlur={(e) => validadeStringField(e, 'incentiveEndpoint')}
                    onFocus={() => resetFieldErrors('incentiveEndpoint')}
                  />
                  <FormHelperText>{form.incentiveEndpoint.errorMessage}</FormHelperText>
                </FormControl>
                <Button className='badge-button'
                  disabled={!editMode}
                  icon={<Add />}
                  textAlign="center"
                  type={!editMode ? 'disabled' : 'full-solid'}
                  height="40px"
                  onClick={(e) => handleOpenModal(e, 'incentive')}
                >
                   <div className='badge'>
                    {incentiveCustomParams.length}
                  </div>
                  {' Parâmetros'}
                </Button>

              </Grid>
              <Grid item xs={12} class="url-fields">
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.authenticationEndpoint.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">URL de autenticação *</FormLabel>


                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.authenticationEndpoint.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="https://www.exemplo.com.br/authenticate"

                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'authenticationEndpoint')}
                    onBlur={(e) => validadeStringField(e, 'authenticationEndpoint')}
                    onFocus={() => resetFieldErrors('authenticationEndpoint')}
                  />
                  <FormHelperText>{form.authenticationEndpoint.errorMessage}</FormHelperText>
                </FormControl>
                <Button className='badge-button'
                  disabled={!editMode}
                  icon={<Add />}
                  textAlign="center"
                  type={!editMode ? 'disabled' : 'full-solid'}
                  height="40px"
                  onClick={(e) => handleOpenModal(e, 'authentication')}
                >
                   <div className='badge'>
                    {authenticationCustomParams.length}
                  </div>
                  {' Parâmetros'}
                </Button>

              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.relationScope.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">Escopo de relacionamento (opcional)</FormLabel>

                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.relationScope.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="EscopoA EscopoB EscopoC"

                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'relationScope')}
                    onBlur={(e) => validadeStringField(e, 'relationScope')}
                    onFocus={() => resetFieldErrors('relationScope')}
                  />
                  <FormHelperText>{form.relationScope.errorMessage}</FormHelperText>
                </FormControl>

              </Grid>
              <Grid item xs={6}>
                <FormControl

                  sx={{ m: 3 }}
                  component="fieldset"
                  error={form.incentiveScope.error}
                  variant="standard"
                  fullWidth
                >
                  <FormLabel component="legend">Escopo de resgate (opcional)</FormLabel>


                  <OutlinedInput
                    disabled={!editMode}
                    margin="dense"
                    value={form.incentiveScope.value}
                    className="inputField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="EscopoA EscopoB EscopoC"
                    autoComplete="off"
                    type="text"
                    onChange={(e) => handleChangeValue(e, 'incentiveScope')}
                    onBlur={(e) => validadeStringField(e, 'incentiveScope')}
                    onFocus={() => resetFieldErrors('incentiveScope')}
                  />
                  <FormHelperText>{form.incentiveScope.errorMessage}</FormHelperText>
                </FormControl>

              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!editMode}
                          checked={form.userCanHaveRelationship.value}
                          onChange={(e) => handleChangeValueToggle(e, 'userCanHaveRelationship')}
                        />
                      }
                      label={'Usuário pode se relacionar'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <RadioGroup
                    row
                    aria-label="quiz"
                    name="quiz"
                  >
                    <FormControlLabel
                      control={

                        <Switch
                          disabled={!editMode}
                          checked={form.active.value}
                          onChange={(e) => handleChangeValueToggle(e, 'active')}
                        />
                      }
                      label={'Instituição ativa'}
                    />
                  </RadioGroup>

                </FormControl>
              </Grid>

              <Grid item xs={4} className="button-container-right">
                {isLoading ? (
                  <div className='center-progress fixed-width'>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <Button
                      icon="none"
                      textAlign="center"
                      type={'full-solid'}
                      height="40px"
                      onClick={handleCheckCredentials}
                    >
                      {'Verificar Credenciais da API'}
                    </Button>
                  </div>
                )}
              </Grid>

            </Grid>
            {id &&
              <>
                <Divider className='divider' />

                <Grid container alignItems='center' justifyContent='center'>
                  Credenciais
                </Grid>
                <Grid container>


                  {showCredentials ?
                    <>
                      <Grid item xs={12} sm={6} md={8} >
                        <FormControl
                          sx={{ m: 3 }}
                          component="fieldset"
                          variant="standard"
                          fullWidth >
                          <FormLabel component="legend">Client ID</FormLabel>
                          <OutlinedInput
                            readOnly
                            type={showClientId ? 'text' : 'password'}
                            value={apiCredentials.clientId}
                            size="small"
                            variant="outlined"
                            fullWidth
                            className="just-view-input"
                            autoComplete="new-password"
                            margin="dense"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowClientId(!showClientId)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showClientId ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          sx={{ m: 3 }}
                          component="fieldset"
                          variant="standard"
                          fullWidth >
                          <FormLabel component="legend">Client secret</FormLabel>
                          <OutlinedInput
                            readOnly
                            type={showClientSecret ? 'text' : 'password'}
                            value={apiCredentials.clientSecret}
                            size="small"
                            variant="outlined"
                            fullWidth
                            className="just-view-input"
                            autoComplete="new-password"
                            margin="dense"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowClientSecret(!showClientSecret)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showClientSecret ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }

                          />
                        </FormControl>

                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className={'reset-button-container'} >
                        <div >

                          {isLoadingCredential ? (
                            <div className='center-progress fixed-width'>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              <Button
                                disabled={!editMode}
                                icon="none"
                                textAlign="center"
                                height="40px"
                                onClick={handleGenerateNewKeysConfirmation}
                                type={!editMode ? 'disabled' : 'full-solid'}
                              >
                                {'Gerar novas credencias de API'}
                              </Button>

                            </>
                          )}

                        </div>
                      </Grid>
                    </>
                    :
                    (<>
                      {isLoadingCredential ? (
                        <div className='center-progress'>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Grid container justifyContent='center' alignItems='center' className='btn-container-show-credentials'>
                          <Button
                            icon="none"
                            textAlign="center"
                            height="40px"
                            onClick={loadCredentials}
                            type={'full-solid'}
                          >
                            {'Mostrar credenciais de API'}
                          </Button>

                        </Grid>
                      )}
                    </>)

                  }

                </Grid>
              </>
            }

            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    disabled={!editMode}
                    icon="none"
                    textAlign="center"
                    type={!editMode ? 'disabled' : 'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid >
    </div>
  )
}

export default TenantDetails;
