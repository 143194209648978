import React, { useEffect, useState } from 'react';
import Tour from 'reactour';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom'

function AppTourMobile({ onClose, isOpened, userLevel }) {
  let steps;
  const tourConfig = [{
      selector: '.home-saude-financeira',
      content: `Tudo começa com esta tarefa: calcular o seu Índice de Saúde Financeira. Ela é muito importante! Você pode continuar explorando o site e depois voltar a ela. O Índice de Saúde Financeira é o diagnóstico da sua situação atual e é através dele que conseguiremos entender qual é o melhor plano de estudos e as melhores ações para melhorar sua vida financeira.`,
    },
    {
      selector: '.iconsMainMenu',
      content: `Este é o Menu Principal. Nele você encontra os links que vão fazer você chegar rapidamente às áreas que desejar ao longo da sua jornada.`,
    },
    {
      selector: '.home-missoes',
      content: `Elas trazem desafios, missões e até games que vão te ajudar a desenvolver novos hábitos para colocar em prática na sua rotina e melhorar sua saúde financeira.`,
    },
    {
      selector: '.mobile-menu-cursos',
      content: `Aqui você encontra os cursos que estiver fazendo e os que estão disponíveis para o seu perfil.`,
    },
    {
      selector: '.mobile-menu-ferramentas',
      content: `As Ferramentas são funcionalidades simples de usar que vão te ajudar a registrar seus ganhos e gastos e acompanhar e organizar suas finanças!`,
    },
    {
      selector: '.saldo-moedas',
      content: `Todo esse processo te rende benefícios! Além de ganhar conhecimento e experiência, os conteúdos, missões, desafios e outras experiências dentro da plataforma te rendem pontos. Você aprende na prática como se organizar, economizar e até trocar seus pontos por benefícios.`,
    },
    {
      selector: '.icon-header-tutorial',
      content: ({ close }) => (
        <div>
          <p>
          Pronto, agora você já está localizado! Sempre que quiser, pode consultar este tutorial novamente.
          </p>
          <Button onClick={() => close('finalizar')} type="solid" variant="contained" color="primary">
            Finalizar
          </Button>
        </div>
      ),
    },
  ];

  if (userLevel === 1) {
    steps = [
      {
        selector: '.home-journey-locked',
        content: 'Aqui começa a sua experiência!  Para conhecer o Seu Espaço e navegar pela plataforma é necessário cumprir suas quatro primeiras missões após o seu primeiro acesso. Veja quais são e inicie a sua jornada!',
      },
      ...tourConfig,
    ]
  } else {
    steps = [
      {
        selector: '.home-objetivo',
        content: 'Neste espaço você encontra algumas recomendações e o que precisa fazer para atingir seus objetivos. Ele é o seu guia de atividades na plataforma. Ou seja, as coisas que você precisa fazer, baseadas no seu perfil, que irão contribuir para o seu desenvolvimento econômico e a melhora do seu Índice.',
      },
      ...tourConfig,
    ]
  }

  return (
    <Tour
      onRequestClose={onClose}
      steps={steps}
      isOpen={isOpened}
      maskClassName="mask"
      className="helper"
      rounded={5}
      accentColor="#05CCAE"
      closeWithMask={false}
    />
  );
}

export default AppTourMobile;
