import * as React from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  IconButton
} from '@material-ui/core';

import './styles.scss'
import { Edit, Delete } from '@material-ui/icons';


export default function TenantTableModal({ data, handleDelete, handleEdit }) {


  const rows = data?.map((item, index) => {
    const { key, type } = item;

    let typename = '';

    switch (type) {
      case 'param':
        typename = 'Parâmetro por querystring'
        break;
      case 'header':
        typename = 'Parâmetro por cabeçalho'
        break;
      default:
        typename = '-'
    }

    return {
      index,
      key,
      type: typename
    }
  })


  return (
    <TableContainer id='table-tenant-modal'>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow >
            <TableCell align="left">Chave</TableCell>
            <TableCell align="left">Tipo</TableCell>
            <TableCell align="right">Ações </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ?
            <>
              {rows.map((row, index) => (
                <TableRow key={row.index}>

                  <TableCell align="left">{row.key}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <>
                      <IconButton

                        onClick={() => handleEdit(index)}
                      >
                        <Edit style={{ color: '#004178' }} />
                      </IconButton> <IconButton

                        onClick={() => handleDelete(index)}
                      >
                        <Delete style={{ color: '#f44336' }} />
                      </IconButton>
                    </>


                  </TableCell>
                </TableRow>
              ))}
            </>
            :
            <TableRow >
              <TableCell colspan={3} className="no-items">
                {'Não há parâmetros registrados'}
              </TableCell>
            </TableRow>

          }

        </TableBody>
      </Table>
    </TableContainer>
  );
}