import React from 'react';
import Button from '../Button';
import * as Styled from './PrivacyPolicy.styles';
import Globals from '../../../../Globals.json';
import { Alert, Skeleton } from '@material-ui/lab';

function PrivacyPolicy({ logHomeAction, enumLogHomeAction }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Styled.PrivacyPolicy>

      <div className='inner-wrapper-container'>
        <div className='inner-wrapper'>
          <div className='font-size-xmd'>

            <div className='center-text font-weight-bold'>
              <p>
                TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS
              </p>
            </div>

            <p className='justify-text'>
              Ao aceitar este Termo de Consentimento para Tratamento de Dados Pessoais (“Termo”), você autoriza a
              <span className='font-weight-bold'> FEDERAÇÃO BRASILEIRA DE BANCOS - FEBRABAN</span> a usar seus dados pessoais,
              fornecidos por você, para os fins aqui descritos, observada a Política de Privacidade.
            </p>

            <p className='justify-text'>
              <ol className='font-weight-bold lista-termos'>
                <li>Dados que serão usados</li>
                <ol className='lista-termos-interno font-weight-regular'>
                  <li>Dados cadastrais, como nome completo, telefone, data de nascimento, CPF, e-mail, gênero, escolaridade, estado civil, número de dependentes no domicílio, grau de instrução, ocupação atual;</li>
                  <li>Dados financeiros, como renda mensal e acesso e uso de produtos financeiros;</li>
                  <li>Dados sobre raça ou etnia (opcional).</li>
                </ol>

                <li>Uso dos dados</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Com este Termo, você autoriza a FEBRABAN a usar seus dados fornecidos, inclusive dados pessoais, para a finalidade única e exclusiva de educação financeira, contempladas todas as frentes de tecnologia do programa “Meu Bolso em Dia”, promovido pela FEBRABAN, consistente no Blog, na Plataforma, no Site I-SFB e no Aplicativo MBeD, que pode ser baixado das principais lojas de aplicativos.
                  </p>
                  <p>
                    A FEBRABAN poderá usar seus dados para acompanhamento de seu índice de saúde financeira, assim como para indicação e disponibilização de cursos para sua educação financeira.
                  </p>
                  <p>
                    Caso você tenha autorizado, podemos compartilhar seu índice de saúde financeira e o resultado de seu desempenho na plataforma de Educação Financeira com as instituições financeiras associadas à FEBRABAN (“Bancos”) para acompanhamento e medição dos resultados da educação financeira, após a etapa formativa, permitindo que os resultados sejam usados por você, se for o caso, para demonstrar sua saúde financeira.
                  </p>
                  <p>
                    A FEBRABAN poderá solicitar o seu consentimento específico para realizar o compartilhamento de dados com os Bancos com os quais você tenha relacionamento ou não, conforme sua preferência, para possibilitar que você use os pontos acumulados na sua educação financeira para obtenção de benefícios junto aos Bancos.
                  </p>
                  <p>
                    Se você consentir, também podemos compartilhar seu índice de saúde financeira e a marcação sobre qual conteúdo você acessou na plataforma de Educação Financeira com os bureaus de crédito, para possível melhoramento de seu score de crédito, a depender do seu índice de saúde financeira.
                  </p>
                </div>

                <li>Direito de revogação deste Termo e informações sobre a sua revogação</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Você poderá revogar este Termo, a qualquer momento, mediante solicitação à FEBRABAN por meio do site da FEBRABAN (<a href="www.febraban.org.br" target="_blank">www.febraban.org.br</a>) ou chave privacidade@febraban.org.br.
                  </p>
                  <p>
                    Pelo site, você deve acessar a área do “Fale Conosco”, na área superior do site. Na Sessão “Fale Conosco”, você deve selecionar a opção “informação”, depois disso, “FEBRABAN” e, por fim, “privacidade”. Nesse caminho, você terá acesso a Política de Privacidade ou poderá selecionar o direito que você quer exercer, indicando que deseja revogar o Termo
                  </p>
                  <p>
                    Caso você tenha autorizado, podemos compartilhar seu índice de saúde financeira e o resultado de seu desempenho na plataforma de Educação Financeira com as instituições financeiras associadas à FEBRABAN (“Bancos”) para acompanhamento e medição dos resultados da educação financeira, após a etapa formativa, permitindo que os resultados sejam usados por você, se for o caso, para demonstrar sua saúde financeira.
                  </p>
                  <p>
                    Com a revogação do seu consentimento, a FEBRABAN não poderá mais usar ou compartilhar seus dados pessoais, porém, caso você tenha autorizado o compartilhamento de seus dados pessoais com os bancos ou com os bureaus de crédito, você deve entrar em contato diretamente com eles para que o tratamento de seus dados pessoais, pelos bancos e pelos bureaus, seja interrompido.
                  </p>
                </div>

                <li>Política de privacidade</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Seus direitos, as responsabilidades da FEBRABAN e demais aspectos relacionados ao tratamento e à privacidade de seus dados pessoais constam de nossa Política de Privacidade.
                  </p>
                </div>
              </ol>
            </p>

            <br />

            <div className='center-text font-weight-bold'>
              <p>
                POLÍTICA DE PRIVACIDADE – ECOSSISTEMA MEU BOLSO EM DIA
              </p>
            </div>

            <div className='justify-text'>
              <p>
                Esta Política de Privacidade, proposta pela <span className='font-weight-bold'>FEDERAÇÃO BRASILEIRA DE BANCOS</span> (“FEBRABAN”),
                inscrita no CNPJ n.º 00.068.353/0001-23, objetiva dar transparência a respeito do uso dos seus dados pessoais, coletados no aplicativo,
                site e páginas relacionadas ao <span className='font-weight-bold'>Ecossistema Meu Bolso em Dia</span> (“Site”), como o Programa de Aceleração Meu Bolso em Dia e a Plataforma de Educação Financeira.
              </p>
              <p>
                Ainda, esta Política de Privacidade busca estabelecer os compromissos da FEBRABAN com relação ao tratamento e à privacidade e proteção dos seus dados pessoais.
              </p>
              <p className='font-weight-bold'>
                AO REALIZAR O CADASTRAMENTO NO SITE, INCLUSIVE NO APLICATIVO DISPONÍVEL NAS LOJAS DE APLICATIVOS, O USUÁRIO CONSENTE LIVREMENTE COM O USO DE SEUS DADOS PARA OS FINS PREVISTOS NESTA POLÍTICA. CASO NÃO ESTEJA DE ACORDO COM OS FINS AQUI PREVISTOS, O USUÁRIO DEVERÁ DESCONTINUAR SEU ACESSO AO SITE E NÃO INCLUIR SEUS DADOS PESSOAIS.
              </p>
              <p>
                Abaixo, você pode ver, de forma simplificada e objetiva, o resumo desta Política.
              </p>
              <p>
                Esta Política poderá ser atualizada, a qualquer tempo, pela FEBRABAN, sendo que, nesse caso, será disponibilizado aviso no Site para você
              </p>
            </div>

            <br />

            <div className='center-text font-weight-medium'>
              <p>
                QUADRO DE RESUMO
              </p>
            </div>

            <div className='overflow-x-auto'>
              <table id='quadro_resumo'>
                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Agente de tratamento
                    </p>
                  </td>
                  <td>
                    <p className='font-weight-medium'>
                      FEDERAÇÃO BRASILEIRA DE BANCOS
                    </p>
                    <p>
                      Avenida Brigadeiro Faria Lima, 4300 - 4º Andar - Itaim Bibi, São Paulo - SP, CEP: 04538-132.
                    </p>
                    <p>
                      CNPJ/M E sob nº 00.068.353/0001-23
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Papel no tratamento
                    </p>
                  </td>
                  <td>
                    <p>
                      Predominantemente controlador
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Natureza dos dados tratados
                    </p>
                  </td>
                  <td>
                    <p>
                      Dados pessoais fornecidos por você e/ou coletados automaticamente
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Sites
                    </p>
                  </td>
                  <td>
                    <p>
                      <ul className='lista-termos-interno-ul'>
                        <li>Aplicativo: Meu Bolso em Dia (disponível para download nas pincipais lojas de aplicativos, como a Apple Store e Google Play)</li>
                        <li>Índice: <a href="https://indice.febraban.org.br" target="_blank">https://indice.febraban.org.br/</a></li>
                        <li>Plataforma: <a href="https://plataforma.meubolsoemdia.com.br" target="_blank">https://plataforma.meubolsoemdia.com.br/</a></li>
                        <li>Sítio eletrônico: <a href="https://meubolsoemdia.com.br" target="_blank">https://meubolsoemdia.com.br/</a></li>
                      </ul>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Finalidades dos tratamentos
                    </p>
                  </td>
                  <td>
                    <p>
                      <ul className='lista-termos-interno-ul'>
                        <li>Autenticação e identificação do usuário;</li>
                        <li>Comunicação com o usuário;</li>
                        <li>A partir da inscrição no Site, para envio de e-mails informativos, e-mails marketing e mensagens via WhatsApp sobre eventos, conteúdos, cursos, notícias, informações gerais dos programas oferecidos pelas iniciativas do Site, da FEBRABAN e demais parceiros;</li>
                        <li>Para atendimento de suas solicitações;</li>
                        <li>Para melhorar sua experiência no Site;</li>
                        <li>Para acompanhamento o seu índice de saúde financeira e indicação e disponibilização de cursos para sua educação financeira;</li>
                        <li>Para acompanhamento e medição dos resultados da educação financeira;</li>
                        <li> Para possível melhoramento de seu score de crédito, a depender do seu índice de saúde financeira;</li>
                        <li>Para possibilitar que você acumule pontos e troque por benefícios disponibilizados no Site.</li>
                      </ul>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Compartilhamento
                    </p>
                  </td>
                  <td>
                    <p>
                      Somente quando necessário ou consentido, para fornecedores, instituições financeiras associadas à FEBRABAN (“Bancos”), instituições relacionadas ao Site, bureaus de crédito e órgãos judiciais e públicos.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Proteção de Dados administrativas
                    </p>
                  </td>
                  <td>
                    <p>
                      Medidas de segurança, técnicas eadequadas e atualizadas.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Seus direitos
                    </p>
                  </td>
                  <td>
                    <p>
                      Confirmação da existência de tratamento, acesso, correção, anonimização, eliminação, revogação do consentimento, etc.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className='font-weight-medium'>
                      Contato do Encarregado
                    </p>
                  </td>
                  <td>
                    <p>
                      <a href="mailto:privacidade@febraban.org.br">privacidade@febraban.org.br</a>
                    </p>
                  </td>
                </tr>

              </table>
            </div>

            <br />

            <p className='justify-text'>
              <ol className='font-weight-bold lista-termos'>
                <li>QUAIS DADOS UTILIZAMOS E PARA QUAIS FINALIDADES?</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    A FEBRABAN poderá coletar e utilizar dados pessoais conforme abaixo descrito:
                  </p>
                  <ul className='lista-termos-interno-ul'>
                    <li>Dados pessoais inseridos ativamente por você, mediante inscrição para acesso aos conteúdos oferecidos pelas iniciativas do Site, sendo, nesse caso, coletados: (i) seu nome completo, (ii) telefone, (iii) data de nascimento, (iv) CPF, (v) e-mail, (vi) gênero, (vii) escolaridade, (viii) estado civil, (ix) número de dependentes no domicílio, (x) grau de instrução, (xi) ocupação atual;</li>
                    <li>Dados financeiros, como renda mensal e acesso e uso de produtos financeiros;</li>
                    <li>Dados sobre raça ou etnia (estes últimos inseridos de forma opcional).</li>
                  </ul>

                  <p>
                    Esses dados são usados para que possamos disponibilizar informações sobre as iniciativas do Site, como a divulgação de eventos, conteúdos, cursos, bem como para envio de e-mails marketing, WhatsApp e comunicações informacionais sobre o Site.
                  </p>

                  <p>
                    Caso autorizado por você, a FEBRABAN poderá usar seus dados pessoais, para a finalidade de educação financeira. Para tanto, a FEBRABAN poderá usar seus dados pessoais para acompanhamento de seu índice de saúde financeira e indicação e disponibilização de cursos para sua educação financeira.Caso você tenha autorizado, podemos compartilhar seu índice de saúde financeira e o resultado de seu desempenho na plataforma de Educação Financeira com os Bancos para acompanhamento e medição dos resultados da educação financeira, após a etapa formativa, permitindo que os resultados sejam usados por você, se for o caso, para demonstrar sua saúde financeira.
                  </p>

                  <p>
                    Se você consentir, também podemos compartilhar seu índice de saúde financeira e a marcação sobre qual conteúdo você acessou na plataforma de Educação Financeira com os bureaus de crédito para reflexos em seu score de crédito. E também podemos compartilhar seus dados financeiros com os Bancos associados para possibilitar o acúmulo de pontos e troca por benefícios concedidos no Site.
                  </p>

                  <p>
                    Caso você tenha cadastrado seu nome, e-mail e telefone e não deseje mais receber e-mails informativos ou mensagens de Whatsapp, a qualquer momento, você poderá se descadastrar do mailing, clicando no link que estará ao final do e-mail informativo ou revogar seu consentimento enviando e-mail para <a href="mailto:privacidade@febraban.org.br">privacidade@febraban.org.br</a>.
                  </p>

                  <p>
                    Nesse caso, usamos os seus dados pessoais inseridos unicamente para responder e atender sua solicitação. Ainda, poderão ser coletados automaticamente, quando da utilização do Site e da rede, informações como, por exemplo, localização, data e hora da conexão.
                  </p>

                  <p>
                    Ainda, alguns dados são coletados por meio do uso de algumas tecnologias padrões, como cookies e recursos do Google Analytics. A utilização desses dados tem as seguintes finalidades:
                  </p>
                  <ul className='lista-termos-interno-ul'>
                    <li>avaliar as visitas ao Site e as fontes de tráfegos identificando quais páginas são mais visitadas;</li>
                    <li>entender como você interage com o conteúdo disponibilizado;</li>
                    <li>controle de acesso e sua identificação nas áreas restritas do Site;</li>
                    <li>melhorar a experiência de navegação e o conteúdo do Site.</li>
                  </ul>

                  <p>
                    Como esses dados são coletados por terceiros, aconselhamos que você entrem na página dos terceiros para informações atualizadas sobre a coleta e uso dos dados:
                  </p>

                  <p>
                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt-BR" target="_blank">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt-BR</a>
                  </p>

                  <p>
                    Para mais detalhes sobre os cookies, verifique o capítulo a seguir que trata especificamente sobre essa modalidade de coleta de dados pessoais e de uso.
                  </p>
                </div>

                <li>COOKIES</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Cookies são pequenos arquivos de texto gravados pelos web sites no computador ou dispositivo móvel. Servem para armazenar as preferências ou informações sobre a navegação.
                  </p>

                  <p>
                    Em geral, a FEBRABAN utiliza cookies para avaliar as visitas ao Site e as fontes de tráfegos identificando quais páginas são mais visitadas, entender como você interage com o conteúdo disponibilizado e, também, para melhorar a experiência de navegação e o conteúdo do Site. Nas áreas restritas do Site, os cookies são usados também para controle de acesso.
                  </p>

                  <p>
                    O Site utiliza dois tipos: "cookies de sessão" e "cookies persistentes".
                  </p>

                  <p>
                    Os cookies de sessão são temporários e desaparecem do dispositivo quando o navegador é fechado. Já os cookies persistentes permanecem no dispositivo até que sejam excluídos pelo navegador ou, manualmente, por você.
                  </p>

                  <p>
                    Detalhadamente, o Site, utiliza os cookies para os seguintes fins:
                  </p>
                  <ul className='lista-termos-interno-ul'>
                    <li>Cookies de Pesquisa, Análise e Desempenho: a finalidade deste tipo de cookie é ajudar a entender o desempenho, medir a audiência, verificar os seus hábitos de navegação, bem como a forma pela qual chegou às páginas web. Especificamente, o Site utiliza os cookies do <span className='font-style-italic'>Google Analytics;</span></li>
                    <li>Cookies de Propaganda: são usados para apresentar publicidade relevante do Site.</li>
                  </ul>

                  <p>
                    Por fim, a FEBRABAN ressalta que os "cookies" e os sinais web não armazenam informações pessoais sem que você as tenha fornecido e não coletam informações registradas em computador.
                  </p>

                  <p>
                    A maioria dos navegadores de internet é inicialmente configurada para aceitar cookies, no entanto, você pode alterar as configurações do navegador para bloquear ou notificar sobre a criação e armazenamento de cookies no equipamento. As informações sobre como efetuar esses procedimentos podem ser encontradas nas páginas de suporte dos navegadores (<span className='font-style-italic'>Mozilla/Firefox, Google Chrome, Microsoft Edge, Safari etc.</span>).
                  </p>

                  <p>
                    Como o gerenciamento de cookies depende da configuração de cada navegador, é possível que haja no mesmo equipamento diferentes cenários.
                  </p>

                  <p>
                    Caso você não queira que a FEBRABAN colete suas informações automaticamente por meio dos cookies, deverá desabilitar esse recurso no navegador de internet utilizado.
                  </p>

                  <p>
                    Importante registrar que, caso você desabilite os cookies do Site, certos serviços poderão não funcionar de maneira ideal.
                  </p>
                </div>

                <li>LINKS PARA OUTROS SITES EXTERNOS</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    O Site poderá conter links para outros sites, de páginas relacionadas ao Site ou empresas e associações parceiras, ou sites externos de terceiros,
                    bem como o Site poderá conter links para compartilhamento em mídias sociais e profissionais
                    (<span className='font-style-italic'>como LinkedIn, Facebook, Instagram e Twitter</span>, por exemplo). Esses sites
                    ou as mídias sociais/profissionais podem ter políticas de privacidade diferentes das aqui praticadas. Assim, as informações
                    passadas por você a esses sites quando em visita ou quando completar transações se submetem às políticas de privacidade e de
                    segurança próprias de obtenção e uso de dados, as quais constam de seus respectivos sites, sem que caiba qualquer responsabilidade à FEBRABAN.
                  </p>

                  <p>
                    Por isso, recomendamos que você sempre leia as normas de relacionamento e política de privacidade específicas dentro do próprio site do parceiro ou terceiro que você estiver acessando ou que colete suas informações.
                  </p>

                  <p className='font-weight-bold'>
                    A FEBRABAN NÃO SE RESPONSABILIZA PELA SEGURANÇA DAS INFORMAÇÕES DOS SEUS DADOS QUANDO ESTE ACESSAR SITES DE TERCEIROS. TAIS SITES PODEM POSSUIR SUAS PRÓPRIAS POLÍTICAS DE PRIVACIDADE QUANTO AO ARMAZENAMENTO E CONSERVAÇÃO DE INFORMAÇÕES PESSOAIS.
                  </p>
                </div>

                <li>COM QUEM COMPARTILHAMOS SEUS DADOS?</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    A FEBRABAN poderá compartilhar as informações coletadas por meio do Site, nas seguintes hipóteses:
                  </p>
                  <ol className='lista-termos-interno font-weight-regular'>
                    <li>Com as instituições parcerias da FEBRABAN para o desenvolvimento e manutenção do Site, para envio de e-mails informativos, bem como para a realização das finalidades de tratamento de seus dados pessoais, previstas nesta Política. São exemplos: prestadores de serviços de envio de e-mails marketing, prestadores de serviços de manutenção e suporte do Site, etc.;</li>
                    <li>No caso das informações coletadas pelo canal de contato com o Site, com as instituições parceiras;</li>
                    <li>Compartilhamento com os Bancos para possibilitar a melhor experiência ao navegar no Site e poder acumular pontos que darão direito a benefícios, será compartilhado seu índice de saúde financeira, o resultado de seu desempenho na plataforma de Educação Financeira e os seus dados financeiros;</li>
                    <li>No caso do seu índice de saúde financeira e a marcação sobre qual conteúdo você acessou na plataforma de Educação Financeira, com os bureaus de crédito;</li>
                    <li>Para proteção dos interesses da FEBRABAN em qualquer tipo de conflito, incluindo ações judiciais;</li>
                    <li>Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.</li>
                  </ol>
                </div>

                <li>COMO MANTEMOS SEUS DADOS SEGUROS?</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    A FEBRABAN se compromete a proteger todas as suas informações, no âmbito de seus limites técnicos e mediante a adoção de ferramentas de segurança.
                  </p>

                  <p>
                    Todas as suas informações serão armazenadas no banco de dados da FEBRABAN ou de terceiros que ela se relaciona em suas iniciativas, sendo que tais informações são integralmente protegidas nos termos da legislação vigente.
                  </p>

                  <p>
                    O Site poderá utilizar dispositivos como <span className='font-style-italic'>firewalls</span> para prover segurança durante o uso e poderá autorizar o acesso as suas informações pessoais apenas para seus profissionais ou terceiros contratados que necessariamente precisem delas para cumprir suas responsabilidades técnicas, estando estes, entretanto, sujeitos a obrigações contratuais de confidencialidade, podendo ser processados ou dispensados se deixarem de cumprir tais obrigações.
                  </p>
                </div>

                <li>RETENÇÃO DAS INFORMAÇÕES COLETADAS</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    As informações coletadas pela FEBRABAN por meio do Site serão excluídas de seus servidores quando deixarem de ser úteis para os fins para os quais foram coletadas, ou quando você solicitar a eliminação de seus dados pessoais. Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou regulatória, bem como para o exercício regular de direito da FEBRABAN, nos termos da legislação vigente.
                  </p>
                </div>

                <li>QUAIS SÃO SEUS DIREITOS E COMO VOCÊ FAZ PARA EXERCÊ-LOS?</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Em atendimento à legislação brasileira de proteção de dados pessoais, a Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais - LGPD) tem o compromisso de assegurar o exercício de seus direitos conforme disposto na LGPD e elencado abaixo:
                  </p>
                  <ol className='lista-termos-interno font-weight-regular'>
                    <li> a confirmação da existência de tratamento de dados pessoais;</li>
                    <li>o acesso aos dados pessoais;</li>
                    <li>a correção de dados incompletos, inexatos ou desatualizados;</li>
                    <li>a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o previsto na legislação;</li>
                    <li>a portabilidade de seus dados a outro fornecedor de serviço ou produto, mediante sua requisição expressa;</li>
                    <li>a eliminação dos dados tratados com o seu consentimento;</li>
                    <li>a obtenção de informações sobre as entidades públicas ou privadas com as quais a FEBRABAN compartilhou seus dados;</li>
                    <li>a informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa;</li>
                    <li>a revogação do consentimento.</li>
                  </ol>

                  <p>
                    Para o exercício de seus direitos, você deve enviar uma mensagem para o email <a href="mailto:privacidade@febraban.org.br">privacidade@febraban.org.br</a> e indicar qual direito que você quer exercer.
                  </p>

                  <p>
                    Importante salientar que, caso sua requisição seja de exclusão de dados pessoais, a FEBRABAN respeitará os prazos de armazenamento mínimos de informações de usuários de aplicações de Internet, determinado pela legislação brasileira, bem como o prazo para exercício dos direitos da FEBRABAN relacionados a demandas judiciais.
                  </p>
                </div>

                <li>QUAIS SÃO AS RESPONSABILIDADES DA FEBRABAN?</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    A FEBRABAN se compromete a utilizar seus dados em conformidade com a LGPD e com a presente Política de Privacidade.
                  </p>

                  <p>
                    A FEBRABAN envidará os melhores esforços no sentido de proteger os seus dados pessoais por meio da adoção de medidas de segurança.
                  </p>

                  <p>
                    É necessário, porém, considerar que em razão da vulnerabilidade da internet, a FEBRABAN NÃO SERÁ RESPONSÁVEL POR INVASÕES OU INTERCEPÇÕES ILEGAIS OU PELA VIOLAÇÃO DOS SISTEMAS E BASE DE DADOS POR PARTE DE PESSOAS NÃO AUTORIZADAS, TAMPOUCO SERÁ RESPONSÁVEL PELA INDEVIDA UTILIZAÇÃO DE INFORMAÇÕES OBTIDAS POR ESSES MEIOS.
                  </p>
                </div>

                <li>CONSIDERAÇÕES FINAIS</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Caso você cometa infração, quebra de qualquer regra de segurança ou prática de atividade contrárias ao bom uso do Site e do Aplicativo, da presente Política ou da legislação vigente, que tenha o intuito de prejudicar ou causar dano ao funcionamento do Site e do Aplicativo, a FEBRABAN, para proteção e segurança do seu ambiente virtual e dos demais usuários, poderá adotar procedimentos para identificar o usuário e coibir tais condutas, por meio medidas legais cabíveis, tanto na esfera penal como na cível, sem prejuízo de medidas técnicas necessárias para a segurança do Site e do Aplicativo.
                  </p>

                  <p>
                    A FEBRABAN não enviará qualquer tipo de correspondência física e não entrará em contato com você por telefone solicitando confirmações de dados.
                  </p>
                </div>

                <li>LEGISLAÇÃO E FORO</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a LGPD, independentemente das Leis de outros estados ou Países, sendo competente o foro do seu domicílio para dirimir qualquer dúvida decorrente desta Política.
                  </p>
                </div>

                <li>CONTATOS DO ENCARREGADO PELA PROTEÇÃO DE DADOS PESSOAIS</li>
                <div className='font-weight-regular bloco-termos-interno'>
                  <p>
                    CONTROLADOR: <spam className='font-weight-bold'>FEDERAÇÃO BRASILEIRA DE BANCOS</spam>
                  </p>

                  <p>
                    Avenida Brigadeiro Faria Lima, 4300 - 4º Andar - Itaim Bibi, São Paulo - SP, 04538-132
                  </p>

                  <p>
                    CNPJ/ME sob nº 00.068.353/0001-23
                  </p>

                  <p>
                    E-mail: <a href="mailto:privacidade@febraban.org.br">privacidade@febraban.org.br</a>
                  </p>
                </div>

              </ol>
            </p>

          </div>
        </div>
      </div>
    </Styled.PrivacyPolicy >
  );
}

export default PrivacyPolicy;
