import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './indexDetail.scss';

class IndexDetail extends Component {

    page = 'welcome';

    componentDidMount = () => {

    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render () {
        return (

            <>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="dialog-content index-detail-body" dividers>
                    <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Container className="content-holder">
                            <Grid container className="content-index-detail">
                                <Grid container item xs={12} className="p20">
                                    <img src="/images/i-sfb-logo.svg" alt="Logo I-SFB" />
                                </Grid>
                                <Grid container item xs={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                    <div>
                                        <p>O Índice que faz um diagnóstico da sua saúde e bem estar financeiros e aponta o que você precisa fazer para<br />melhorá-los.</p>
                                    </div>
                                    <Hidden smDown>
                                        <img src="/images/gauge-icon-lg.png" width="100%" alt="" />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <img src="/images/gauge-icon-lg.png" width="50%" alt="" />
                                    </Hidden>
                                </Grid>
                                <Grid container item xs={12} md={6} direction="column" justify="flex-start" alignItems="center" className="p20">
                                    <div>
                                        <h5 style={{ marginBottom: '14px' }}>Feito no Brasil para os brasileiros</h5>
                                        <p>O Índice é resultado de 1 ano de trabalho colaborativo de mais de 70 especialistas da <a target="_blank" rel="noopener noreferrer" href="https://portal.febraban.org.br/">FEBRABAN</a>,
                                            Banco Central, membros do sistema financeiro nacional e universidades.</p>
                                        <br />
                                        <p>Ele é uma ferramenta simples e confiável e disponível gratuitamente a todos os brasileiros.</p>
                                        <br />
                                        <h5>Ter um bom I-SFB é:</h5>
                                        <ul>
                                            <li>Ser capaz de cumprir suas obrigações financeiras;</li>
                                            <li>Ser capaz de tomar boas decisões financeiras;</li>
                                            <li>Sentir-se segura(o) quanto ao presente e ao futuro financeiro;</li>
                                            <li>Ter liberdade de fazer escolhas que permitam aproveitar a vida.</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </DialogContent >
                <DialogActions className="dialog-footer space-between">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.props.goBack(); }}
                        style={{ borderRadius: "0px", color: "white" }}
                    >
                        Voltar
                    </Button>
                    {
                        this.props.canStartIndex && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { this.props.startIndex(); }}
                                style={{ borderRadius: "0px", color: "white" }}
                            >
                                FAZER O TESTE AGORA
                            </Button>
                        )
                    }

                </DialogActions>
            </>
        )
    }
}

export default watch(withRouter(IndexDetail), { user: '' });