import React from 'react';
import MainText from '../MainText';
import * as Styled from './Partners.styles';

function Partners({ openRegisterDialog }) {
  return <Styled.Partners>
    <div className="inner-wrapper">
      <div>
        <MainText style={{ textAlign: 'center' }}>
          <h2>Parceiros</h2>
        </MainText>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <img src="./images/partners/abracs.png" alt="Abracs" title="Abracs" className="image-01" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/camara-municipal-belo-horizonte.png" alt="Câmara municipal de Belo Horizonte" title="Câmara municipal de Belo Horizonte" className="image-10" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/camara-municipal-joao-pessoa.png" alt="Câmara municipal de João Pessoa" title="Câmara municipal de João Pessoa" className="image-11" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/instituto-acao-pela-paz.png" alt="Instituto Ação pela Paz" title="Instituto Ação pela Paz" className="image-22" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/mutua.png" alt="Mutua" title="Mutua" className="image-14" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/paketa.png" alt="Paketá" title="Paketá" className="image-15" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-es.png" alt="Procon-ES" title="Procon-ES" className="image-23" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-pa.png" alt="Procon Paraíba" title="Procon Paraíba" className="image-16" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-pe.png" alt="Procon Pernambuco" title="Procon Pernambuco" className="image-24" />
        </div>        
        <div className="grid-item">
          <img src="./images/partners/procon-pr.png" alt="Procon-PR" title="Procon-PR" className="image-17" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-rj.png" alt="Procon-RJ" title="Procon-RJ" className="image-14" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-santa-maria.png" alt="Procon Santa Maria" title="Procon Santa Maria" className="image-21" />
        </div>
        <div className="grid-item">
          <img src="./images/partners/procon-sc.png" alt="Procon SC" title="Procon SC" className="image-25" />
        </div>
      </div>
    </div>
  </Styled.Partners>;
}

export default Partners;