import React from 'react';
import { ResponsiveContainer, FunnelChart, Funnel, LabelList } from 'recharts';
import './IndexFunnelChart.scss'

export default function IndexFunnelChart({ data }) {
  return (
    <>
      {!data ? <div className="no-data-container funnel-graph">Sem dados disponíveis</div> :
        <div className="funnel-graph">
          <div className="funnel-metrics">
            {data?.map((item, index) =>
              item.name && (
                <div key={index} className="metrics-arrow-funnel">
                  {item.name && (
                    <>
                      <div className={item.percentage ? ("base " + item.color) : ("base half-width " + item.color)}>
                        <span>{item.name}</span>
                        <span>{item.percentage.toLocaleString("pt-BR", { maximumFractionDigits: 2 })}{index !== 0 ? "%" : ""}</span>
                      </div>
                      {item.percentage && (
                        <div className={"arrow " + item.color}></div>
                      )}
                    </>
                  )}
                </div>
              )
            )}
          </div>
          <div className="funnel-content">
            <ResponsiveContainer width="80%" height="120%">
              <FunnelChart>
                <Funnel data={data} dataKey="barValue" isAnimationActive={false}>
                  <LabelList position="inside" fill="#fff" stroke="none" dataKey="value" fontSize={12} />
                </Funnel>
              </FunnelChart>
            </ResponsiveContainer>
          </div>
        </div>
      }
    </>
  )
}