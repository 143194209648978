import React, { useState, useEffect } from 'react';
import './styles.scss';

import { Link, useHistory } from 'react-router-dom';
import { isAdmin, isAuthenticated, logout } from "../../../../services/auth";
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, ClickAwayListener, Grow, MenuList, Paper, Popper, MenuItem, TextField } from '@material-ui/core';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Logo from '../../../../assets/logo/logo-blue.svg'
import api from '../../../../services/api';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Globals = require("../../../../Globals.json");


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hidden: {
    display: 'none',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    marginBottom: "-60px",
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function PersistentDrawerLeft({ children, text, user }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [avatarInitials, setAvatarInitials] = useState();
  const [menuAnchorElement, setMenuAnchorElement] = useState();
  const [user_avatar, setUser_avatar] = useState();
  const [isOnlyDashboardViewer, setIsOnlyDashboardViewer] = useState(false);
  let history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const loadData = async () => {
    try {
      const response = await api.get(Globals.api.userInfo);

      dispatchSet("user.profile", response?.data?.CadUserDetail[0].profile_image);
      if (response?.data?.CadUserDetail[0]) {
        const user_avatar = response?.data?.CadUserDetail[0].profile_image
        setUser_avatar(user_avatar)
      }
      const isAdm = await isAdmin();

      const isOnlyDashboardViewer = !isAdm;
      setIsOnlyDashboardViewer(isOnlyDashboardViewer);

      let fullName = response?.data?.username;
      if (fullName.indexOf(" ") === -1) fullName += " ";
      const initials = (fullName).match(/^(.).* (.)?/);

      const avatarInitials = initials[1] + (initials[2] ? initials[2] : "")
      setAvatarInitials(avatarInitials)

    } catch (error) {
      console.log(error);
    }
  }

  const handleAvatarClick = (event) => {
    setMenuAnchorElement(event.target);
  };

  const handleMenuKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuAnchorElement(null)
    }
  }


  const handleMenuLogout = () => {
    setMenuAnchorElement(null);
    logout();
    history.push(Globals.paths.root);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      loadData()
    }
  }, [])

  return (
    <div className={isAuthenticated() ? classes.root : classes.hidden}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <Link to={Globals.paths.backofficeHome}>
              <img src={Logo} alt="Meu bolso em dia" className='navigation_logo' />
            </Link>
          </Typography>

          <div className="nav_avatar">
            <Avatar onClick={(e) => handleAvatarClick(e)} className={`avatar ${user_avatar ? '' : 'initialAvatar'}`}>
              <div className="imagePerfil">
                {user_avatar ?
                  <img src={user_avatar} alt="Avatar" />
                  :
                  <>
                    <div style={{ paddingTop: "2px" }}>
                      {avatarInitials}
                    </div>
                  </>
                }
              </div>
            </Avatar>

            <Popper open={Boolean(menuAnchorElement)} anchorEl={menuAnchorElement} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper style={{ marinTop: "5px" }}>
                    <ClickAwayListener onClickAway={() => setMenuAnchorElement(null)}>
                      <MenuList autoFocusItem={Boolean(menuAnchorElement)} id="menu-list-grow" onKeyDown={() => handleMenuKeyDown()}>
                        <MenuItem onClick={() => handleMenuLogout()}>Sair</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="header">
            <Link to={Globals.paths.backofficeHome}>
              <img src={Logo} alt="Logo Meu bolso em dia" className="navigation_logo_header" />
            </Link>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className="navigation_positionLinks">

          {/* <Link to={Globals.paths.backofficeHome} className="navigation_link" >
            Home
          </Link> */}
          {/* <Link to={Globals.paths.backofficeCourses} className="navigation_link" >
            Cursos
          </Link> */}
          {/* <Link to={Globals.paths.backofficeCourseDetail.replace(":courseId", "cadastrarcurso")} className="navigation_link" >
            Cadastrar Curso
          </Link> */}
          {/* <Link to={Globals.paths.backofficeClasses} className="navigation_link" >
            Cadastrar Aula
          </Link>
          <Link to={Globals.paths.backofficeCreateContent.replace(":classId", "cadastrarconteudo")} className="navigation_link" >
            Cadastrar Conteúdo
          </Link> */}
          {/* <Link to={Globals.paths.backofficeUsers} className="navigation_link" >
            Usuários
          </Link>
          <Link to={Globals.paths.backofficeArticleListBlog} className="navigation_link" >
            Conteúdo do Blog
          </Link>
          <Link to={Globals.paths.backofficeUsersPerfil} className="navigation_link" >
            Gerenciamento de Perfis
          </Link>
          <Link to={Globals.paths.backofficeTypeRegister} className="navigation_link" >
            Conteúdo tipo de usuário
          </Link> */}
          {!isOnlyDashboardViewer &&
            <>
              <Accordion className="miss_list_accordion2">


                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Usuários</Typography>
                </AccordionSummary>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeUsers} className="navigation_link2" onClick={handleDrawerClose}>
                      Usuários
                    </Link>
                  </Typography>
                  {/* 
              <Typography paragraph>
                <Link to={Globals.paths.backofficeProfileRegister} className="navigation_link2" onClick={handleDrawerClose}>
                  Perfil de acesso
                </Link>
              </Typography>

              <Typography paragraph>
                <Link to={Globals.paths.backofficeTypeRegister} className="navigation_link2" onClick={handleDrawerClose}>
                  Tipo de usuário
                </Link>
              </Typography> 
              */}
                </AccordionDetails>

              </Accordion>
              <Accordion className="miss_list_accordion2">

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Conteúdo</Typography>
                </AccordionSummary>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeArticleListBlog} className="navigation_link2" onClick={handleDrawerClose}>
                      Artigos Importados
                    </Link>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeArticleHome} className="navigation_link2" onClick={handleDrawerClose}>
                      Artigos
                    </Link>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeCourses} className="navigation_link2" onClick={handleDrawerClose}>
                      Cursos
                    </Link>
                  </Typography>
                </AccordionDetails>
                {/* <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeDataDownload} className="navigation_link2" onClick={handleDrawerClose}>
                      Extração de Dados
                    </Link>
                  </Typography>
                </AccordionDetails> */}
                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeMiniQuiz} className="navigation_link2" onClick={handleDrawerClose}>
                      Mini Quizzes
                    </Link>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeQuizzes} className="navigation_link2" onClick={handleDrawerClose}>
                      Quizzes
                    </Link>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeWorksheets} className="navigation_link2" onClick={handleDrawerClose}>
                      Planilhas e Listas
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="miss_list_accordion2">

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Fotografia Financeira</Typography>
                </AccordionSummary>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeExpensesMatrix} className="navigation_link2" onClick={handleDrawerClose}>
                      Matrizes de Despesas
                    </Link>
                  </Typography>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Typography paragraph>
                    <Link to={Globals.paths.backofficeExpensesMatrixItems} className="navigation_link2" onClick={handleDrawerClose}>
                      Itens das Matrizes
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="miss_list_accordion2">

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Aplicativo</Typography>
                </AccordionSummary>

                <AccordionDetails className="miss_details_accordion2">
                  <Accordion className="miss_list_accordion3">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ marginLleft: '20px' }}
                    >
                      <Typography>Questões</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeQuestionMatrices} className="navigation_link2" onClick={handleDrawerClose}>
                          Grupo de questões
                        </Link>
                      </Typography>
                    </AccordionDetails>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeQuestionMatrixOptions} className="navigation_link2" onClick={handleDrawerClose}>
                          Questões
                        </Link>
                      </Typography>
                    </AccordionDetails>

                  </Accordion>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Accordion className="miss_list_accordion3">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ marginLleft: '20px' }}
                    >
                      <Typography>Conquistas</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeAchievementMatrices} className="navigation_link2" onClick={handleDrawerClose}>
                          Grupo de conquistas
                        </Link>
                      </Typography>
                    </AccordionDetails>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeAchievementMatrixOptions} className="navigation_link2" onClick={handleDrawerClose}>
                          Conquistas
                        </Link>
                      </Typography>
                    </AccordionDetails>

                  </Accordion>
                </AccordionDetails>

                <AccordionDetails className="miss_details_accordion2">
                  <Accordion className="miss_list_accordion3">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ marginLleft: '20px' }}
                    >
                      <Typography>Despesas</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeExpenseMatrices} className="navigation_link2" onClick={handleDrawerClose}>
                          Grupo de despesas
                        </Link>
                      </Typography>
                    </AccordionDetails>

                    <AccordionDetails>
                      <Typography paragraph>
                        <Link to={Globals.paths.backofficeExpenseMatrixOptions} className="navigation_link2" onClick={handleDrawerClose}>
                          Despesas
                        </Link>
                      </Typography>
                    </AccordionDetails>

                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <MenuItem className="menu-item" >
                <Typography >
                  <Link to={Globals.paths.backofficeTenants} className="navigation_link2" onClick={handleDrawerClose}>
                    Instituições
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem className="menu-item" >
            <Typography >
              <Link to={Globals.paths.backofficeIncentive} className="navigation_link2" onClick={handleDrawerClose}>
                Incentivos
              </Link>
            </Typography>
          </MenuItem>
            </>

          }
          <MenuItem className="menu-item" >
            <Typography >
              <Link to={Globals.paths.backofficeDashboard} className="navigation_link2" onClick={handleDrawerClose}>
                Dashboard
              </Link>
            </Typography>
          </MenuItem>                 
        </div>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}

export default watch((PersistentDrawerLeft), { user: '' });
