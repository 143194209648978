import React, { PureComponent, useState, useEffect } from 'react'

import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  Tooltip,
  LabelList,
  Legend,
  YAxis,
  Text
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, bgColor } = this.props;

    return (
      <svg>
        <foreignObject x={x} y={y - 20} width="35" height="20" >
          <div style={{ backgroundColor: bgColor, borderRadius: "2px", color: '#000', fontSize: '8px', opacity: '0.8', padding: '4px', textAlign: 'center' }}>
            {value.toLocaleString('pt-BR', { maximumFractionDigits: 2 })}%
          </div>
        </foreignObject>
      </svg>
    );
  }
};

class CustomizedLabelList extends PureComponent {
  render() {
    const { x, y, width, value } = this.props;
    return (
      <text
        x={x + width / 2}
        y={y}
        dy={-4}
        fontSize='10'
        fill={'#fff'}
        textAnchor="middle">{value.toLocaleString('pt-BR', { maximumFractionDigits: 2 })}</text>
    );
  }
};

export default function ComposedChartDashboard({
  data,
  barNameDatakey,
  lineName,
  lineDatakey,
  lineColor = '#fff',
  needBarLabel = false,
  YaxisLeft = null,
  YaxisRight = null,
  width = null,
  chartWidth = null,
  legend = true,
  toolTipName = null,
  chartHeight = 200,
  evolutivePeriod = null,
  evolutiveMonthSelected = null,
  evolutiveWeekSelected = null,
  chartMargin = {
    top: 2,
    right: 0,
    bottom: 20,
    left: 0
  },
  angle = 360
}) {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip-dash">
          <div>{toolTipName}: {label}</div>
          <div>{payload[0]?.name ?? ''}: {payload[0]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}</div>
          {payload[2] ?
            <>
              <div>{payload[1]?.name ?? ''}: {payload[1]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}</div>
              <div>{payload[2]?.name ?? ''}: {payload[2]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}%</div>
            </>
            :
            <div>{payload[1]?.name ?? ''}: {payload[1]?.value.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) ?? ''}%</div>
          }
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    let dyValue = angle !== 360 ? 16 : 6
    let angleF = angle
    let fontSizeF = '10';

    if (screenSize.width < 524) {
      fontSizeF = '8'
    }

    if (screenSize.width < 450) {
      angleF = -45;
      dyValue = 16
    }

    if (payload && payload.value) {
      return <Text
        x={x}
        y={y}
        dy={dyValue}
        width={75}
        fontSize={fontSizeF}
        angle={angleF}
        fill={'#fff'}
        textAnchor="middle"
        verticalAnchor="start">
        {payload.value}</Text>
    }
    else {
      return null
    }
  };

  const getWidth = () => {
    if (data?.length <= 6) {
      return 700;
    } else if (data?.length > 6 && data?.length <= 18) {
      return 1200;
    } else if (data?.length > 18 && data?.length <= 24) {
      return 1300;
    } else if (data?.length >= 25) {
      return 1400;
    }
  };

  const getEvolutiveInfo = (data) => {
    if (evolutivePeriod) {
      if (data.payload.periodToEvaluate === 'monthly') {
        evolutivePeriod('weekly');
        evolutiveMonthSelected(data.name)
      } else if (data.payload.periodToEvaluate === 'weekly') {
        evolutivePeriod('daily');
        evolutiveWeekSelected(data.name);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <ComposedChart
          width={chartWidth === 0 ? width : getWidth()}
          height={chartHeight}
          data={data}
          margin={chartMargin}
          maxBarSize={70}
        >
          <Tooltip content={<CustomTooltip />} shared={true} />
          {legend && <Legend verticalAlign="top" height={50} margin={{ top: 0, bottom: 2 }} />}

          <XAxis dataKey="name"
            scale="auto"
            tickLine={false}
            axisLine={false}
            tick={<CustomizedAxisTick />}
            interval={0} />

          {YaxisRight && <YAxis yAxisId='right'
            orientation="right"
            tick={{ fill: '#fff', fontSize: 10 }} />}

          {YaxisLeft && <YAxis yAxisId='left'
            tick={{ fill: '#fff', fontSize: 10 }} />}

          {barNameDatakey.map((item, index) =>
            <Bar isAnimationActive={false} key={index} yAxisId='left' name={item.name} dataKey={item.datakey} fill={item.fill} onClick={(data) => { getEvolutiveInfo(data) }}>
              {needBarLabel && <LabelList dataKey={item.datakey} content={<CustomizedLabelList />} position="insideRight" />}
            </Bar>
          )}

          <Line
            yAxisId='right'
            name={lineName}
            type="monotone"
            dataKey={lineDatakey}
            stroke={lineColor}
            isAnimationActive={false}
            dot={{ stroke: lineColor, fill: lineColor }}
            label={<CustomizedLabel bgColor={lineColor !== '#fff' ? lineColor : '#FDFDFD'} />} />
        </ComposedChart>
      }
    </>
  )
}