import { Breadcrumbs, Button, Grid, Hidden, Typography } from '@material-ui/core';
import { ArrowRight, CheckCircleOutline, Clear } from '@material-ui/icons';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import AlertErrors from '../../components/alertErrors/alerts';
import ButtonComponent from '../../components/button/Button';
import './certificationTest.scss';
import Globals from '../../../Globals.json';

class TestResult extends React.Component {
  state = {
    certificationTest: null,
    completedCertificate: false,
    courseName: '',
    courseDescription: '',
    courseThumbnail: '',
    courseData: {},
    ctName: '',
    ctDescription: '',
    ctThumbnail: '',
    ctData: {},
    loading: false,
    alertError: '',
    questions: [],
    selection: {},
    showQuestions: false,
    remakeTest: 0,
    hasCertificate: false,
  };

  async loadCourseData() {
    this.setState({ loading: true });

    try {
      const postData = {
        container_id: this.props.courseId,
        reward: [
          { asset_id: 5, amount: 5, notification: 1 },
          { asset_id: 7, amount: 10, notification: 1 },
        ],
      };
      const response = await api.post(Globals.api.getModules, postData);
      const { container, children } = response.data;
      const certTest = children.find((item) => item.type_module_id === Globals.types.certificationTest);

      if (!certTest) return;

      this.setState({
        loading: false,
        courseName: container.name,
        courseDescription: container.description,
        courseThumbnail: container.thumbnail,
        classes: children,
        questions: [],
        certificationTest: certTest,
        hasCertificate: children.find((item) => item.type_module_id === Globals.types.certificate),
      });

      if (!this.props.showResult && container.user_module_data.finishedCourseAt) {
        this.props.history.push(
          Globals.paths.certificationTestResult.replace(':courseId', this.props.courseId)
        );
      }

      this.loadCertificationTestData();
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async loadCertificationTestData() {
    this.setState({ loading: true });

    try {
      const postData = {
        container_id: this.state.certificationTest.id,
      };

      const response = await api.post(Globals.api.getModules, postData);

      const { container } = response.data;
      const { questions, minScore } = container.data;

      this.setState({
        loading: false,
        ctName: container.name,
        ctDescription: container.description,
        ctThumbnail: container.thumbnail,
        ctData: container.data,
        questions,
      });

      if (this.props.showResult) {
        this.setState({ selection: container.user_module_data.questionsAnswered || {} });
      }

      this.storeTestStart();
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  componentDidMount() {
    this.loadCourseData();
  }

  handleSelection(questionIndex, option) {
    if (this.state.remakeTest === 1 || this.state.completedCertificate) {
      return false;
    } else {
      const selection = { ...this.state.selection };
      selection[questionIndex] = option.id;
      this.setState({ selection });
    }
  }

  getSelection(index) {
    return this.state.selection[index] || null;
  }

  getOptionClass(index, optionItem) {
    if (!this.state.selection) return 'test_containerAnswer';

    if (this.props.showResult && this.state.selection[index] === optionItem.id) {
      if (optionItem.correct) return 'test_containerAnswerCorrect';
      return 'test_containerAnswerWrong';
    }
    if (
      (this.state.remakeTest === 1 && this.state.selection[index] === optionItem.id) ||
      (this.state.completedCertificate && this.state.selection[index] === optionItem.id)
    ) {
      if (optionItem.correct) return 'test_containerAnswerCorrect';
      return 'test_containerAnswerWrong';
    }
    return this.state.selection[index] === optionItem.id
      ? 'test_containerAnswerSelected'
      : 'test_containerAnswer';
  }

  getOptionIcon(index, optionItem) {
    if (this.state.selection[index] === optionItem.id)
      if (!this.props.showResult || optionItem.correct)
        return (
          <>
            <Hidden xsDown>
              {' '}
              <CheckCircleOutline fontSize="large" style={{ color: '#FFF' }} />{' '}
            </Hidden>
            <Hidden smUp>
              {' '}
              <CheckCircleOutline
                fontSize="large"
                style={{ color: '#FFF', height: '20px', widht: '20px' }}
              />{' '}
            </Hidden>
          </>
        );
    if (this.props.showResult && this.state.selection[index] === optionItem.id)
      return (
        <>
          <Hidden xsDown>
            {' '}
            <Clear fontSize="large" style={{ color: '#FFF' }} />{' '}
          </Hidden>
          <Hidden smUp>
            {' '}
            <Clear fontSize="large" style={{ color: '#FFF', height: '20px', widht: '20px' }} />{' '}
          </Hidden>
        </>
      );
    return <></>;
  }

  getOptionIconResult(index, optionItem) {
    if (optionItem.correct) {
      return (
        <>
          <Hidden xsDown>
            {' '}
            <CheckCircleOutline fontSize="large" style={{ color: '#FFF' }} />{' '}
          </Hidden>
          <Hidden smUp>
            {' '}
            <CheckCircleOutline
              fontSize="large"
              style={{ color: '#FFF', height: '20px', widht: '20px' }}
            />{' '}
          </Hidden>
        </>
      );
    } else {
      return (
        <>
          <Hidden xsDown>
            {' '}
            <Clear fontSize="large" style={{ color: '#FFF' }} />{' '}
          </Hidden>
          <Hidden smUp>
            {' '}
            <Clear fontSize="large" style={{ color: '#FFF', height: '20px', widht: '20px' }} />{' '}
          </Hidden>
        </>
      );
    }
  }

  allQuestionsAnswered() {
    return Object.keys(this.state.selection).length === this.state.questions.length;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.courseId !== prevProps.courseId ||
      this.props.showResult !== prevProps.showResult
    ) {
      this.loadCourseData();
    }
  }

  storeTestStart() {
    try {
      const postData = {
        data_modules_detail_id: this.state.certificationTest.id,
        type_action_id: 5,
      };
      api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  storeTestFinish() {
    try {
      const postData = {
        data_modules_detail_id: this.state.certificationTest.id,
        type_module_id: 8,
        type_action_id: 6,
        questions_answered: this.state.selection,
      };

      console.log(
        'this.state.certificationTest.id',
        this.state.certificationTest.id,
        this.state.selection,
        this.state.certificationTest
      );

      api
        .post(Globals.api.userActionEvent, postData)
        .then((response) => {
          if (!response?.data?.reachedTheRequiredGrade) {
            this.setState({ remakeTest: 1 });
          } else {
            this.setState({ completedCertificate: true, remakeTest: 3 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ selection: this.state.selection });
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  remakeTest() {
    this.setState({ selection: {}, remakeTest: 0 });
  }

  finishTest() {
    this.storeTestFinish();
  }

  showCertificate() {
    this.props.history.push(
      Globals.paths.certificateFinal.replace(':courseId', this.props.courseId)
    );
  }

  backToSummary() {
    this.props.history.push(
      Globals.paths.courseDetail.replace(':courseId', this.props.courseId)
    );
  }

  checkCertificateButton() {
    return this.state.hasCertificate ? (
      <ButtonComponent height={'45px'} className="darkBlue" onClick={() => this.showCertificate()}>
        Visualizar Certificado
      </ButtonComponent>
    ) : (
      <ButtonComponent icon="none" height={'45px'} className="darkBlue" onClick={() => this.backToSummary()}>
        Voltar
      </ButtonComponent>
    );
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="test_BreadCrumb">
          <Link color="inherit" to={Globals.paths.courses} className="test_BreadCrumbLink">
            Cursos
          </Link>
          <Link
            color="inherit"
            to={Globals.paths.courseDetail.replace(':courseId', this.props.courseId)}
            className="test_BreadCrumbLink"
          >
            {this.state.courseName}
          </Link>
          <Typography color="textPrimary" className="test_BreadCrumbLabel">
            {this.state.ctName}
          </Typography>
        </Breadcrumbs>

        <Helmet>
          <title>Prova | Meu bolso em dia</title>
        </Helmet>

        <Grid item container xs={12} direction="column" className="test_classContainer">
          <AlertErrors error={this.state.alertError} />

          <div className="test_titleBar"> {this.state.ctName}</div>
          <div className="test_container">
            <span className="test_subTitle"> {this.state.ctDescription}</span>
            {this.state.questions.map((item, index) => (
              <div key={index} className="test_cardQuestion">
                <span className="test_textQuestion"> {item.question} </span>

                {item.options.map((optionItem, optionIndex) => (
                  <div
                    key={optionIndex}
                    className={this.getOptionClass(index, optionItem)}
                    onClick={() => this.handleSelection(index, optionItem)}
                    style={{
                      pointerEvents: this.props.showResult ? 'none' : 'auto',
                      cursor: this.props.showResult ? 'default' : 'pointer',
                    }}
                  >
                    <span>{optionItem.text}</span>
                    {this.props.showResult ||
                    this.state.remakeTest === 1 ||
                    this.state.completedCertificate
                      ? this.getOptionIconResult(index, optionItem)
                      : this.getOptionIcon(index, optionItem)}
                  </div>
                ))}
              </div>
            ))}

            {!this.props.showResult && this.state.remakeTest === 0 && (
              <Button
                variant="outlined"
                disabled={!this.allQuestionsAnswered()}
                className="test_allButton"
                onClick={() => this.finishTest()}
              >
                Enviar <ArrowRight />
              </Button>
            )}

            {this.state.remakeTest === 1 && (
              <>
                <h2 style={{ color: 'red', marginTop: '-15px' }}>
                  Infelizmente você não atingiu a quantidade necessária para gerar o certificado!
                </h2>
                <div className="test_positionBtnRemake">
                  <div style={{ marginRight: '10px' }}>
                    <ButtonComponent
                      height={'45px'}
                      to={Globals.paths.courseDetail.replace(':courseId', this.props.courseId)}
                    >
                      Refazer curso
                    </ButtonComponent>
                  </div>
                </div>
              </>
            )}

            {(this.props.showResult || this.state.completedCertificate) &&
              this.checkCertificateButton()}
          </div>
        </Grid>
      </>
    );
  }
}
export default TestResult;
