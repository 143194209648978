import React from 'react';
import './styles.scss';

import NavigationAppBar from '../../AppBar/NavigationAppBar';
import { IconButton, TextField } from '@material-ui/core';
import Button from '../../../../components/button/Button';
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import InactivateContent from '../../components/InactivateContent';
import Dialogs from '../../../../../services/dialogs';

import api from "../../../../../services/api";
const Globals = require("../../../../../Globals.json");
class ArtcileBlogDetail extends React.Component {

  state = {
    openAlert: false,
    openAlertError: false,
    title: '',
    description: '',
    thumbnail: '',
    link: '',
    id: 0,
    idFbb: 0,
    influences: [],

    relevances: [],
    situacaoFinanceira: "",
    conhecimento: "",
    atitude: "",
    liberdade: "",
    autopercepcao: "",
    status: undefined,
    keywords: "",
  }

  handleChangeRadio(e, id) {
    if (id === 1) {
      this.setState({ situacaoFinanceira: e.target.value })
    }
    if (id === 2) {
      this.setState({ conhecimento: e.target.value })
    }
    if (id === 3) {
      this.setState({ atitude: e.target.value })
    }
    if (id === 4) {
      this.setState({ liberdade: e.target.value })
    }
    if (id === 5) {
      this.setState({ autopercepcao: e.target.value })
    }
  }

  loadRelevance = () => {
    api.get(Globals.api.getRelevanceTags)
      .then((response) => {
        this.setState({ relevances: response.data.data })
        // console.log('RELEVANCE::', this.state.relevances)
      }).catch(err => {
        console.log('rerrrr', err)
      })
  }

  handleBtn = async (id) => {
    if (id === 2) {
      this.setState({ open: true })
    } else {
      const postData =
      {
        "type_module_status_id": id
      }
      await api.patch(Globals.api.inactivateContent.replace(':Id', this.state.idFbb), postData).then(response => {
        console.log(response.data)
        this.setState({ openAlert: true })
        this.loadData()
        setTimeout(() => {
          return this.setState({ openAlert: false })
        }, 5000)
      }).catch(err => {
        console.log(err)
        this.setState({ openAlertError: true })
        setTimeout(() => {
          return this.setState({ openAlertError: false })
        }, 5000)
      })
    }
  }

  handleSave = async () => {
    if (!this.state.keywords || this.state.keywords.trim().length === 0) {
      Dialogs.show('Palavras-chave Obrigatórias', 'É necessário que uma ou mais palavras-chave sejam informadas.');
      return;
    }

    const postData = {
      "id": this.state.id,
      "title": this.state.title,
      "description_meta_tags": this.state.description,
      "image": this.state.thumbnail,
      "keywords": this.state.keywords,
      "tags": [
        {
          "type_relevance_tag_id": 1,
          "value": Number(this.state.situacaoFinanceira)
        },
        {
          "type_relevance_tag_id": 2,
          "value": Number(this.state.conhecimento)
        },
        {
          "type_relevance_tag_id": 3,
          "value": Number(this.state.atitude)
        },
        {
          "type_relevance_tag_id": 4,
          "value": Number(this.state.liberdade)
        },
        {
          "type_relevance_tag_id": 5,
          "value": Number(this.state.autopercepcao)
        }
      ]
    };

    console.log("SALVANDO DADOS...", postData);

    await api.post(Globals.api.sendCourseBlogDetail, postData).then(response => {
      console.log("DADOS SALVOS:", response.data)
      this.setState({ openAlert: true })
      this.props.history.push(Globals.paths.backofficeArticleListBlog)
      setTimeout(() => {
        return this.setState({ openAlert: false })
      }, 5000)
    }).catch(err => {
      console.log(err)
      this.setState({ openAlertError: true })
      setTimeout(() => {
        return this.setState({ openAlertError: false })
      }, 5000)
    })
  }

  async loadData() {
    const blogPostId = this.props.match.params.articleId
    await api.get(Globals.api.getCoursesBlogDetail.replace(":blogPostId", blogPostId))
      .then(response => {
        const { data_module_detail } = response?.data;

        console.log('Carregado...', response?.data)
        // console.log('Carregado2...', data_module_detail=== undefined)

        if (data_module_detail !== undefined) {
          this.setState({
            influences: data_module_detail.RefModulesDetailsRelevanceTag, idFbb: data_module_detail.id,
            status: data_module_detail.type_module_status_id
          })
        }
        else {
          this.setState({ influences: [] })
        }

        this.setState({
          title: response?.data?.title,
          description: response?.data?.description_meta_tags,
          thumbnail: response?.data?.image,
          link: response?.data?.url,
          id: blogPostId,
          keywords: response?.data?.data_module_detail?.data?.keywords
        })

        if (this.state.influences.length > 0) {
          this.state.influences.map((item, index) => (
            <>
              {
                item.type_relevance_tag_id === 1 ? this.setState({ situacaoFinanceira: item.value }) :
                  item.type_relevance_tag_id === 2 ? this.setState({ conhecimento: item.value }) :
                    item.type_relevance_tag_id === 3 ? this.setState({ atitude: item.value }) :
                      item.type_relevance_tag_id === 4 ? this.setState({ liberdade: item.value }) :
                        item.type_relevance_tag_id === 5 && this.setState({ autopercepcao: item.value })
              }
            </>
          ))
        }
      }).catch(err => {
        console.log(err)
        this.setState({ openAlertError: true })
        setTimeout(() => {
          return this.setState({ openAlertError: false })
        }, 5000)
      })
  }

  componentDidMount() {
    this.loadData()
    this.loadRelevance()
  }
  render() {

    return (
      <>
        <NavigationAppBar text="Editar Artigo BO" />
        <div className="containerEditArtcile">
          <div className="formEditArtcile">
            <InactivateContent
              open={this.state.open}
              id={this.state.idFbb}
              userId={this.props.match.params.userId}
              onClose={() => this.setState({ open: false })}
            />
            <div className="editHeaderFormArticle">
              <div className="displayNameArtcile">Artigo:
                <span>{this.state.title}</span>
              </div>
              <div className="displayNameArtcile">Status:
                <span>
                  {
                    this.state.status === 2 ? 'Não Publicado' :
                      this.state.status === 1 ? 'Publicado' :
                        this.state.status === 0 ? 'Publicado' : 'Não publicado'
                    // this.state.status === 0 ? 'Rascunho' : 'Não publicado'
                  }
                </span>
              </div>

            </div>

            <div className="editContentArtcile">
              <div className="contentArtcile">

                <div className="editSideItemImage">
                  Descrição
                  <div className="containerImgArtcile">

                    {this.state.thumbnail !== '' &&
                      <img src={`${this.state.thumbnail}`}
                        alt="Imagem do Artigo" />}

                    <div>{this.state.description}</div>
                  </div>
                </div>

                <div className="editSideItemLink">
                  Link do artigo
                    <a href={`${this.state.link}`}
                    rel="noopener noreferrer" target="_blank">{this.state.link}</a>
                </div>

                <TextField
                  className="editKeywords"
                  key="txtKeywords"
                  label="Palavras-chave"
                  value={this.state.keywords}
                  onChange={(e) => this.setState({ keywords: e.target.value })}
                />

                {/* <div className="titleContainerRadio">Influência do conteúdo</div> */}
                <div className="containerInfluenceArtcile">
                  <div className="titleInflu">Influência do conteúdo</div>
                  {this.state.relevances.map((item, index) => (
                    <div className={`${item.id === 1 ? "itemInfluenceArticle" : "itemInfluenceArticle2"}`} key={index}>
                      {index < 5 &&
                        <>
                          <div className={`${item.id === 1 ? "itemTitleArtcile" : "itemTitleArtcile2"}`}>
                            <span>{
                              item.id === 1 ? "Situação Financeira" :
                                item.id === 2 ? "Conhecimento" :
                                  item.id === 3 ? "Atitude" :
                                    item.id === 4 ? "Liberade" :
                                      item.id === 5 && "Autopercepção"
                            }</span>
                          </div>
                          <div className={`${item.id === 1 ? "radioItemArticle" : "radioItemArticle2"}`}>
                            {index === 0 && <div>Nenhuma</div>}
                            <input
                              onChange={e => this.handleChangeRadio(e, item.id)}
                              type="radio"
                              checked={
                                index === 0 && Number(this.state.situacaoFinanceira) === 0 ? true :
                                  index === 0 && Number(this.state.situacaoFinanceira) !== 0 ? false :
                                    index === 1 && Number(this.state.conhecimento) === 0 ? true :
                                      index === 1 && Number(this.state.conhecimento) !== 0 ? false :
                                        index === 2 && Number(this.state.atitude) === 0 ? true :
                                          index === 2 && Number(this.state.atitude) !== 0 ? false :
                                            index === 3 && Number(this.state.liberdade) === 0 ? true :
                                              index === 3 && Number(this.state.liberdade) !== 0 ? false :
                                                index === 4 && Number(this.state.autopercepcao) === 0 ? true :
                                                  index === 4 && Number(this.state.autopercepcao) !== 0 && false
                              }
                              name={`${"alternative" + item.id}`}
                              value={Number(0)}
                            />
                          </div>

                          <div className={`${item.id === 1 ? "radioItemArticle" : "radioItemArticle2"}`}>
                            {index === 0 && <div>Pouca</div>}
                            <input
                              onChange={e => this.handleChangeRadio(e, item.id)}
                              type="radio"
                              checked={
                                index === 0 && Number(this.state.situacaoFinanceira) === 25 ? true :
                                  index === 0 && Number(this.state.situacaoFinanceira) !== 25 ? false :
                                    index === 1 && Number(this.state.conhecimento) === 25 ? true :
                                      index === 1 && Number(this.state.conhecimento) !== 25 ? false :
                                        index === 2 && Number(this.state.atitude) === 25 ? true :
                                          index === 2 && Number(this.state.atitude) !== 25 ? false :
                                            index === 3 && Number(this.state.liberdade) === 25 ? true :
                                              index === 3 && Number(this.state.liberdade) !== 25 ? false :
                                                index === 4 && Number(this.state.autopercepcao) === 25 ? true :
                                                  index === 4 && Number(this.state.autopercepcao) !== 25 && false
                              }
                              name={`${"alternative" + item.id}`}
                              value={Number(25)}
                            />
                          </div>

                          <div className={`${item.id === 1 ? "radioItemArticle" : "radioItemArticle2"}`}>
                            {index === 0 && <div>Média</div>}
                            <input
                              onChange={e => this.handleChangeRadio(e, item.id)}
                              type="radio"
                              checked={
                                index === 0 && Number(this.state.situacaoFinanceira) === 50 ? true :
                                  index === 0 && Number(this.state.situacaoFinanceira) !== 50 ? false :
                                    index === 1 && Number(this.state.conhecimento) === 50 ? true :
                                      index === 1 && Number(this.state.conhecimento) !== 50 ? false :
                                        index === 2 && Number(this.state.atitude) === 50 ? true :
                                          index === 2 && Number(this.state.atitude) !== 50 ? false :
                                            index === 3 && Number(this.state.liberdade) === 50 ? true :
                                              index === 3 && Number(this.state.liberdade) !== 50 ? false :
                                                index === 4 && Number(this.state.autopercepcao) === 50 ? true :
                                                  index === 4 && Number(this.state.autopercepcao) !== 50 && false
                              }
                              name={`${"alternative" + item.id}`}
                              value={Number(50)}
                            />
                          </div>

                          <div className={`${item.id === 1 ? "radioItemArticle" : "radioItemArticle2"}`}>
                            {index === 0 && <div>Muita</div>}
                            <input
                              onChange={e => this.handleChangeRadio(e, item.id)}
                              type="radio"
                              checked={
                                index === 0 && Number(this.state.situacaoFinanceira) === 75 ? true :
                                  index === 0 && Number(this.state.situacaoFinanceira) !== 75 ? false :
                                    index === 1 && Number(this.state.conhecimento) === 75 ? true :
                                      index === 1 && Number(this.state.conhecimento) !== 75 ? false :
                                        index === 2 && Number(this.state.atitude) === 75 ? true :
                                          index === 2 && Number(this.state.atitude) !== 75 ? false :
                                            index === 3 && Number(this.state.liberdade) === 75 ? true :
                                              index === 3 && Number(this.state.liberdade) !== 75 ? false :
                                                index === 4 && Number(this.state.autopercepcao) === 75 ? true :
                                                  index === 4 && Number(this.state.autopercepcao) !== 75 && false
                              }
                              name={`${"alternative" + item.id}`}
                              value={Number(75)}
                            />
                          </div>

                          <div className={`${item.id === 1 ? "radioItemArticle" : "radioItemArticle2"}`}>
                            {index === 0 && <div>Totalmente</div>}
                            <input
                              onChange={e => this.handleChangeRadio(e, item.id)}
                              type="radio"
                              checked={
                                index === 0 && Number(this.state.situacaoFinanceira) === 100 ? true :
                                  index === 0 && Number(this.state.situacaoFinanceira) !== 100 ? false :
                                    index === 1 && Number(this.state.conhecimento) === 100 ? true :
                                      index === 1 && Number(this.state.conhecimento) !== 100 ? false :
                                        index === 2 && Number(this.state.atitude) === 100 ? true :
                                          index === 2 && Number(this.state.atitude) !== 100 ? false :
                                            index === 3 && Number(this.state.liberdade) === 100 ? true :
                                              index === 3 && Number(this.state.liberdade) !== 100 ? false :
                                                index === 4 && Number(this.state.autopercepcao) === 100 ? true :
                                                  index === 4 && Number(this.state.autopercepcao) !== 100 && false
                              }
                              name={`${"alternative" + item.id}`}
                              value={Number(100)}
                            />
                          </div>
                        </>
                      }
                    </div>
                  ))}
                </div>
              </div>

              <div className="editContainerBtns">

                <Button
                  icon="none"
                  width="180px"
                  type="ligthDark"
                  height="36px"
                  textAlign="center"
                  right="20px"
                  to={Globals.paths.backofficeArticleListBlog}>
                  voltar
                </Button>

                {this.state.influences.length > 0 && this.state.status !== 2 &&
                  <Button
                    icon="none"
                    width="180px"
                    height="36px"
                    type="orange"
                    textAlign="center"
                    onClick={() => this.handleBtn(2)}>
                    Despublicar
                  </Button>
                }

                {this.state.status === 2 &&
                  <Button
                    icon="none"
                    width="180px"
                    height="36px"
                    type="green"
                    textAlign="center"
                    onClick={() => this.handleBtn(1)}>
                    Publicar
                  </Button>
                }

                <Button
                  width="180px"
                  height="36px"
                  icon="none"
                  type="darkBlue"
                  textAlign="center"
                  onClick={() => this.handleSave()}>
                  {this.state.influences.length <= 0 ? 'publicar' : 'salvar'}
                </Button>

              </div>

            </div>


            {this.state.openAlert &&
              <Alert variant="filled" severity="success" className="alertEditUser"
                action={<IconButton aria-label="close" color="inherit" size="small"
                  onClick={() => { this.setState({ openAlert: false }) }}
                >
                </IconButton>
                }>
                {this.state.action === false ? "Operação realizada com sucesso" : "Operação realizada com sucesso"}
              </Alert>
            }

            {this.state.openAlertError &&
              <Alert variant="filled" severity="error" className="alertEditUser"
                action={<IconButton aria-label="close" color="inherit" size="small"
                  onClick={() => { this.setState({ openAlert: false }) }}
                >
                </IconButton>
                }>
                {this.state.action === false ? "Erro ao importar o conteúdo!" : "Erro ao importar o conteúdo!"}
              </Alert>
            }
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(ArtcileBlogDetail);
