import { Dialog, DialogContent } from '@material-ui/core';
import React, { Component } from 'react'
import './styles/mailResetUser.scss'

import Mail from '../../../../assets/icon/mail.svg';

import Button from '../../button/Button'

import api from '../../../../services/api';
import Globals from '../../../../Globals.json';

export class MailResetUser extends Component {
  state = {
    success: false,
  }

  resetPassword = () => {
    api.put(Globals.api.backofficeResetPassword + this.props.userId)
      .then(response => {
        console.log(response.data)
        this.setState({ success: true })
      }).catch(err => {
        console.log(err)
      })
  }

  closeDialog = () => {
    setTimeout(() => {
      this.setState({ success: false })
    }, 1000)
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        onClose={this.props.onClose}
        maxWidth={"xs"}
        className="modal"
      >
        <DialogContent className="modal_reset_user_container">

          <div className="modal_reset_user_header">
            <button type="button" className="close"
              onClick={this.props.onClose}>&times;
             </button>
          </div>

          {this.state.success === false ?
            <div className="modal_reset_noneIcon"/>:
            <div className="modal_reset_user_close">
              <img src={Mail} alt="Email" />
            </div>
          }

          <section>
            {this.state.success === false ?
              <span>
                Você tem certeza que quer RESETAR a senha desse usuário?
              </span> :
              <span style={{ color: '#202020', fontWeight: '700' }}>
                Senha Resetada
              </span>
            }
            <p>{this.state.success === false ?
              "Ao confirmar um e-mail será enviado para o usuário com um link temporário para cadastrar a nova senha de acesso." :
              "Um e-mail com o link temporário para cadastro da nova senha foi enviado ao e-mail do usuário."
            }
            </p>
          </section>

          {this.state.success === false ?
            <Button icon='none' type="primary" onClick={() => this.resetPassword()} >CONFIRMAR</Button> :
            <Button icon='none' type="primary" onClick={() => this.closeDialog()} >SAIR</Button>
          }
        </DialogContent>
      </Dialog >
    )
  }
}

export default MailResetUser;