
import React from 'react';
import './styles.scss';

import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { TurnedInNot } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Grid, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../button/Button';

import api from '../../../services/api';

const Globals = require("../../../Globals.json");

class ListFavorites extends React.Component {

  state = {
    isLoading: false,
    courses: [],
    articles: [],
    quiz: [],
    alertError: '',
  }

  removeCourseFavorites = async (id) => {
    
    try {
      const postData = {
        type_action_id: 26,
        data_modules_detail_id: id,
        
      };

      const res = await api.post(Globals.api.userActionEvent, postData)

      let loading = true;
      this.loadDataCourses(loading)
      console.log(res.data)

    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  removeArticleFavorites = async (id) => {
    
    try {
      const postData = {
        type_action_id: 26,
        data_modules_detail_id: id,
        
      };

      api.post(Globals.api.userActionEvent, postData)
        .then(response => {
          var loading = true;
          this.loadDataArticle(loading)
          console.log(response.data)
        })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }


  async loadDataCourses(loading) {
    if (loading) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

    const response = await api.post(Globals.api.getModules, { type_module_id: 2 });

    const courses = response.data;

    this.setState({ courses, isLoading: false });

  }

  async loadDataArticle(loading) {
    if (loading) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
    try {
      const response = await api.post(Globals.api.getModules, { type_module_id: 10 });

      this.setState({ articles: response?.data, isLoading: false });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount() {
    this.loadDataCourses()
    this.loadDataArticle()
  }


  goToCourse = (course) => {
    // console.log( "Gotocourse props:", this.props );
    dispatchSet('courses.selectedCourse', course);
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", course.id));
  }

  render() {

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ?
          <Grid>
            <Box pt={0.5} style={{ display: 'flex', flexDirection: "column" }}>
              <Skeleton variant="rect" width={'100%'} height={80} />
              <br />
              <Skeleton variant="rect" width={'100%'} height={80} />
              <br />
              <Skeleton variant="rect" width={'100%'} height={80} />
              <br />
            </Box>
          </Grid> :
          <div>
            <br />
            <Grid>
              {this.props.favorite === 1 ?
                <>

                  {this.state.courses.map((item, index) => (
                    <div key={index}>
                      {item.is_favorite !== null &&
                        <Grid container direction="row" className="courseItemContainer">

                          <div className="description">
                            <TurnedInNot />
                            <span styles={{ marginLeft: "30px" }} >{item.name}</span>
                          </div>

                          <div className="positionBtn" >
                            <Button minWidth={"110px"} right={"10px"} type={"danger"} onClick={() => this.removeCourseFavorites(item.id)}>EXCLUIR</Button>
                            <Button minWidth={"110px"} to={Globals.paths.courseDetail.replace(":courseId", item.id)} >VER MAIS</Button>
                          </div>
                        </Grid>
                      }
                    </div>
                  ))}
                </> : this.props.favorite === 2 ?
                  <>
                    {this.state.articles.map((item, index) => (
                      <div key={index}>
                        {item.is_favorite !== null &&
                          <Grid container direction="row" className="courseItemContainer">

                            <div className="description">
                              <TurnedInNot />
                              <span styles={{ marginLeft: "30px" }} >{item.name}</span>
                            </div>

                            <div className="positionBtn" >
                              <Button minWidth={"110px"} right={"10px"} type={"danger"} onClick={() => this.removeArticleFavorites(item.id)}>EXCLUIR</Button>
                              <Button minWidth={"110px"} to={Globals.paths.article.replace(":articleId", item.id)} >VER MAIS</Button>
                            </div>
                          </Grid>
                        }
                      </div>
                    ))}
                  </> : this.props.favorite === 3 &&
                  <>
                    {this.state.quiz.map((item, index) => (
                      <div key={index}>
                        {item.is_favorite !== null &&
                          <Grid container direction="row" className="courseItemContainer">

                            <div className="description">
                              <TurnedInNot />
                              <span styles={{ marginLeft: "30px" }} >{item.name}</span>
                            </div>

                            <div className="positionBtn" >
                              <Button minWidth={"110px"} right={"10px"} type={"danger"} onClick={() => this.removeArticleFavorites(item.id)}>EXCLUIR</Button>
                              <Button minWidth={"110px"} to={Globals.paths.article.replace(":articleId", item.id)} >VER MAIS</Button>
                            </div>
                          </Grid>
                        }
                      </div>
                    ))}
                  </>
              }
            </Grid>

          </div>
        }
      </>
    )
  }

}

export default ListFavorites;
