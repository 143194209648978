import React, { Component } from 'react';
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './welcome.scss';
import Globals from '../../../Globals.json';
import WelcomeContent from './welcome-content/WelcomeContent';
import RevalidateIndexWelcome from './revalidate-index-welcome/RevalidateIndexWelcome';
import IndexForm from './index-form/IndexForm';
import { enumTypeUserStatuses } from '../../../services/enums';
import IndexDetail from './index-detail/IndexDetail';
import ProfileIndex from './profile-index/ProfileIndex';
import PersonaList from './persona-list/PersonaList';
import PersonaDetail from './persona-detail/PersonaDetail';

class ProfileModal extends Component {

    state = {
        started: false,
        open: false,
        startIndex: 0,
        isLoading: true,
        disableBackdropClick: false,
        disableEscapeKeyDown: false,
        currentPage: '',
        //previousPage: '',
        canStartIndex: false,
        history: [],
        data: null,
    }

    startIndex = () => {
        const profile = this.props.user.profile;
        if (profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled || profile.canAnswerIndex || profile.revalidateIndex ) {
            this.setState({ currentPage: 'index-form' });
        }
    }

    componentDidMount = () => {

        if (this.props.open) {
            this.handleClickOpen();
        }

        this.init();
    }


    init = () => {

        this.setState({ isLoading: true });
        //this.setState({ currentPage: 'profile-index', canStartIndex: false });

        if (this.props.user && this.props.user.profile) {

            if (this.props.user.profile.revalidateIndex) {
                this.setState({ currentPage: 'revalidate-index', canStartIndex: true });
                this.setState({ isLoading: false });
                return
            }

            if (this.props.user.profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled) {
                this.setState({ currentPage: 'welcome', canStartIndex: true });
                this.setState({ isLoading: false });
                return
            }

            this.setState({ currentPage: 'profile-index' });
            this.setState({ isLoading: false });
            return
        }

    }

    handleClose = () => {
        this.props.closeWelcomeModal();
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    showContent = (config) => {
        const history = this.state.history;
        if (config.data) {
            this.setState({ currentPage: config.to, history, data: config.data }, () => {
                history.push(config.from);
            });
        }
        else {
            history.push(config.from);
            this.setState({ currentPage: config.to, history });
        }


    }

    goBack = (reset = false) => {
        if (reset) {
            this.setState({ currentPage: 'profile-index', history: [] });
        }
        else {
            const history = this.state.history;
            const last = history.pop();
            this.setState({ currentPage: last, history });
        }
    }

    setCloseConfig = (cantClose) => {
        this.setState({
            disableBackdropClick: cantClose,
            disableEscapeKeyDown: cantClose
        });
    }

    disableIndex = () => {
        this.setState({ canStartIndex: false });
    }


    render() {

        let html = (
            <Grid item xs={12} className="loading">
                <div className="loading">
                    <CircularProgress />
                </div>
            </Grid>
        );

        if (!this.state.isLoading) {

            switch (this.state.currentPage) {
                case 'index-form': {
                    html = <IndexForm setCloseConfig={this.setCloseConfig} goBack={this.goBack} disableIndex={this.disableIndex} handleClose={this.handleClose} revalidate={this.props.user.profile.revalidateIndex}></IndexForm>;
                    break;
                }
                case 'index-detail': {
                    html = <IndexDetail page={"index-detail"} goBack={this.goBack} canStartIndex={this.state.canStartIndex} handleClose={this.handleClose} showContent={this.showContent} startIndex={this.startIndex}></IndexDetail>;
                    break;
                }
                case 'profile-index': {
                    html = <ProfileIndex page={"profile-index"} handleClose={this.handleClose} showContent={this.showContent} startIndex={this.startIndex}></ProfileIndex>;
                    break;
                }
                case 'profile-list': {
                    html = <PersonaList page={"profile-list"} handleClose={this.handleClose} showContent={this.showContent} goBack={this.goBack}></PersonaList>;
                    break;
                }
                case 'persona-detail': {
                    html = <PersonaDetail page={"persona-detail"} handleClose={this.handleClose} data={this.state.data} goBack={this.goBack}></PersonaDetail>;
                    break;
                }
                case 'revalidate-index': {
                    html = <RevalidateIndexWelcome page={"revalidate-index"} handleClose={this.handleClose} showContent={this.showContent} startIndex={this.startIndex}></RevalidateIndexWelcome>;
                    break;
                }
                default: {
                    html = <WelcomeContent page={"welcome"} handleClose={this.handleClose} showContent={this.showContent} startIndex={this.startIndex}></WelcomeContent>;
                    break;
                }
            }
        } else {
            html = <CircularProgress />
        }

        return (
            <div>
                <Helmet>
                    <title>Bem vindo | Meu bolso em dia</title>
                </Helmet>
                <Dialog onClose={this.handleClose} disableBackdropClick={this.state.disableBackdropClick} disableEscapeKeyDown={this.state.disableEscapeKeyDown} maxWidth={'md'} className="welcome-dialog" open={this.state.open}>
                    {html}
                </Dialog>
            </div>
        )
    }
}


export default watch(withRouter(ProfileModal), { user: '' });