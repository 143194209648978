import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReactTooltip from "react-tooltip";
import './RegionalMap.scss';

export default function RegionalMap({ data }) {
  const markers = [
    { id: 1, markerOffsetX: 0, markerOffsetY: 20, name: 'Acre', coordinates: [-70.8120, -9.3] },
    { id: 2, markerOffsetX: 35, markerOffsetY: 5, name: 'Alagoas', coordinates: [-35.7820, -9.5] },
    { id: 3, markerOffsetX: 0, markerOffsetY: 20, name: 'Amazonas', coordinates: [-66.0, -4.4168] },
    { id: 4, markerOffsetX: 0, markerOffsetY: 20, name: 'Amapá', coordinates: [-52.3, 1.7441] },
    { id: 5, markerOffsetX: 0, markerOffsetY: 20, name: 'Bahia', coordinates: [-42.0, -11.5] },
    { id: 6, markerOffsetX: 0, markerOffsetY: 20, name: 'Ceará', coordinates: [-40.3206, -4.5] },
    { id: 7, markerOffsetX: 0, markerOffsetY: -10, name: 'Distrito Federal', coordinates: [-48.0, -15.7975] },
    { id: 8, markerOffsetX: 0, markerOffsetY: -10, name: 'Espírito Santo', coordinates: [-40.3089, -19.1834] },
    { id: 9, markerOffsetX: 0, markerOffsetY: 20, name: 'Goiás', coordinates: [-50.5, -16.0] },
    { id: 10, markerOffsetX: 0, markerOffsetY: -10, name: 'Maranhão', coordinates: [-46.0, -5.0] },
    { id: 11, markerOffsetX: 0, markerOffsetY: 20, name: 'Minas Gerais', coordinates: [-45.7908, -18.9302] },
    { id: 12, markerOffsetX: 0, markerOffsetY: 20, name: 'Mato Grosso do Sul', coordinates: [-54.5, -19.7722] },
    { id: 13, markerOffsetX: 0, markerOffsetY: 20, name: 'Mato Grosso', coordinates: [-56.5, -13.6819] },
    { id: 14, markerOffsetX: 0, markerOffsetY: 20, name: 'Pará', coordinates: [-54.0, -5.2071] },
    { id: 15, markerOffsetX: 40, markerOffsetY: 5, name: 'Paraíba', coordinates: [-35.7820, -7.0] },
    { id: 16, markerOffsetX: 0, markerOffsetY: 20, name: 'Pernambuco', coordinates: [-39.7820, -8.2400] },
    { id: 17, markerOffsetX: 0, markerOffsetY: -10, name: 'Piauí', coordinates: [-43.0, -8.0] },
    { id: 18, markerOffsetX: 0, markerOffsetY: 20, name: 'Paraná', coordinates: [-52.0, -24.251] },
    { id: 19, markerOffsetX: 0, markerOffsetY: 20, name: 'Rio de Janeiro', coordinates: [-43.1729, -22.3] },
    { id: 20, markerOffsetX: 20, markerOffsetY: -10, name: 'Rio Grande do Norte', coordinates: [-35.9541, -5.5] },
    { id: 21, markerOffsetX: 0, markerOffsetY: 20, name: 'Rondônia', coordinates: [-62.5, -11.5057] },
    { id: 22, markerOffsetX: 0, markerOffsetY: 20, name: 'Roraima', coordinates: [-61.5, 2.1316] },
    { id: 23, markerOffsetX: 0, markerOffsetY: 20, name: 'Rio Grande do Sul', coordinates: [-53.2090, -29.0368] },
    { id: 24, markerOffsetX: 0, markerOffsetY: 20, name: 'Santa Catarina', coordinates: [-51.3653, -26.9286] },
    { id: 25, markerOffsetX: 0, markerOffsetY: 20, name: 'Sergipe', coordinates: [-37.5, -10.6739] },
    { id: 26, markerOffsetX: 0, markerOffsetY: 20, name: 'São Paulo', coordinates: [-49.6396, -21.5558] },
    { id: 27, markerOffsetX: 0, markerOffsetY: 20, name: 'Tocantins', coordinates: [-48.0, -10.4099] }
  ]

  const [content, setContent] = React.useState("");
  const [totaisGeneroDemografico, setTotaisGeneroDemografico] = React.useState({
    Feminino: 0,
    Masculino: 0,
    "Prefiro não informar": 0,
    total: -1
  });

  function handleTotaisAcessoDemografico() {
    const totais = {};
    for (let item of markers) {
      const _index = data.findIndex(x => x.state === item.name);
      totais[`id_${item.id}`] = 0;
      if (data[_index]?.data) {
        for (let i in data[_index].data) {
          totais[`id_${item.id}`] += data[_index].data[i].value;
        }
      }
    }
    return totais;
  }


  function calcularTotaisAcessoGenero() {
    const totais_genero_demografico = {
      Feminino: 0,
      Masculino: 0,
      "Prefiro não informar": 0,
      total: 0
    }
    totais_genero_demografico.Feminino = 0;
    totais_genero_demografico.Masculino = 0;
    totais_genero_demografico["Prefiro não informar"] = 0;
    totais_genero_demografico.total = 0;

    for (let item of markers) {
      const _index = data.findIndex(x => x.state === item.name);
      if (data[_index]?.data) {
        for (let i in data[_index].data) {
          totais_genero_demografico[data[_index].data[i].name] += data[_index].data[i].value;
          totais_genero_demografico.total += data[_index].data[i].value;
        }
      }
    }
    setTotaisGeneroDemografico(totais_genero_demografico);
  }

  function setToolTipEstado(id, name = '') {
    const _index = data.findIndex(x => x.state === name)

    let statesData = "";
    if (data[_index]?.data) {
      data[_index].data.map(item => statesData += `<span style="color:${item.color}"><strong>${item.name}: ${item.value}</strong></span> <br/>`);
    }
    const custonTooltip = `<strong>${name}<strong><br/> ${statesData}`;

    setContent(custonTooltip);
  };

  const [zoom, setZoom] = useState(1);
  const [mapCenter, setMapCenter] = useState([-50, -28])

  const handleFilter = ({ constructor: { name } }) => {
    return name !== "WheelEvent";
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.5);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.5);
  };

  useEffect(() => {
    if (zoom <= 1) {
      setZoom(1);
      setMapCenter([-55, -28]);
    }
  }, [zoom]);

  useEffect(() => {
      calcularTotaisAcessoGenero()
  }, [totaisGeneroDemografico.total]);

  return (
    <div className='regional-map-container'>
      <div>Regional</div>
      <div className='zoom-btns'>
        <button onClick={handleZoomIn}> + </button>
        <button onClick={handleZoomOut}> - </button>
      </div>
      <ComposableMap
        viewBox="67 -10 713 370"
        projection={"geoMercator"}
        projectionConfig={{
          rotate: [-10.0, 1.0, 0],
          scale: 500,
        }}
        style={{
          width: "100%",
          height: "342px"
        }}
        data-tip="">
        <ZoomableGroup center={mapCenter} zoom={zoom} filterZoomEvent={handleFilter}>
          <Geographies geography='/brazil3-map.json'>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography key={geo.rsmKey}
                  geography={geo}
                  strokeWidth={0.5}
                  onMouseEnter={() => {
                    setToolTipEstado(`${geo.properties.id}`, `${geo.properties.name}`);
                  }}
                  onMouseLeave={() => {
                    setContent("");
                  }}
                  style={{
                    default: {
                      fill: "#05CCAE",
                      stroke: "#005E50"
                    },
                    hover: {
                      fill: "#595959",
                    },
                  }} />
              ))
            }
          </Geographies>
          {markers.map(({ id, name, coordinates, markerOffsetY, markerOffsetX }) => (
            <Marker key={name} coordinates={coordinates}>
              <circle r={5} fill="#F00" stroke="#fff" strokeWidth={2} pointer-events="none" />
              <text
                pointer-events="none"
                textAnchor="middle"
                y={markerOffsetY}
                x={markerOffsetX ?? 0}
                style={{ fill: "#fff" }}
              >
                {handleTotaisAcessoDemografico()?.[`id_${id}`] ?? 0}
              </text>
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip html={true} backgroundColor='#DAF439' textColor='#000'>{content}</ReactTooltip>

      <div className="h-100pct row-our-numbers flex-our-numbers-column map-content-legends flex-direction-row-force">
        <div className="moda-indice-table">
          <div className="moda-indice-row">
            <div className="map-content-legends-cell map-totais-content-legends t-align-right">
              {(totaisGeneroDemografico.Masculino).toLocaleString('pt-BR')}
            </div>
            <div className="map-content-legends-cell font-color-1">
              Masculino
            </div>
          </div>

          <div className="moda-indice-row">
            <div className="map-content-legends-cell map-totais-content-legends t-align-right">
              {(totaisGeneroDemografico.Feminino).toLocaleString('pt-BR')}
            </div>
            <div className="map-content-legends-cell font-color-1">
              Feminino
            </div>
          </div>
        </div>

        <div className="moda-indice-table">
          <div className="moda-indice-row">
            <div className="map-content-legends-cell map-totais-content-legends t-align-right">
              {(totaisGeneroDemografico['Prefiro não informar']).toLocaleString('pt-BR')}
            </div>
            <div className="map-content-legends-cell font-color-1">
              Prefiro não informar
            </div>
          </div>

          <div className="moda-indice-row">
            <div className="map-content-legends-cell map-totais-content-legends t-align-right">
              {(totaisGeneroDemografico.total).toLocaleString('pt-BR')}
            </div>
            <div className="map-content-legends-cell font-color-1">
              Total
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}