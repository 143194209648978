
import React from 'react';

import { withRouter } from 'react-router-dom';
import { login, isAuthenticated } from "../../../../services/auth"
import { withStyles, Grid, TextField } from '@material-ui/core';
import ButtonCircularProgress from "../../../../shared/components/ButtonCircularProgress";
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import Button from '@material-ui/core/Button';
import VisibilityPasswordTextField from '../../../../shared/components/VisibilityPasswordTextField';

import Logo from '../../../../assets/logo/logo-blue.svg';
import api from '../../../../services/api';
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "@material-ui/lab";

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    paddingBottom:"15px"
  },
  passReqText:
  {
    paddingLeft: "5px",
    whiteSpace: "nowrap",
  },
  form: {
    backgroundColor: "#fff",
    width: "451px",
    display: "flex",
    flexDirection: "column",
    padding: "35px",
    position: "relative",
    marginTop: "120px",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#595959",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#005E50",
  },
  headerEmail: {
    fontSize: "16px",
    color: "#05CCAE",
  },
  inputs: {
    marginTop: "8px",
    width: "60%",
    height: "40px",
    border: "1px solid #595959",
    paddingLeft: "5px",
    fontSize: "14px"
  },
  logo: {
    width: "285px",
    marginTop: "35px",
    marginBottom: "35px",
  },
  textsForm: {
    fontSize: "16px",
    color: "#202020",
    marginBottom: "8px",
  },
  containerButton: {

  },
  containerVerifiredButtons: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between'
  },
  borderGreen: {
    width: "20%",
    height: "10px",
    position: "absolute",
    backgroundColor: "#05CCAE",
    bottom: 0,
    right: 0,
  },
  header2:
  {
    backgroundColor: "#05CCAE",
    height: "120px",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0px 5vw 0px 5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  styleButton: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    border: "transparent 1px solid",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "color .3s ease-out",
    height: "40px",
    cursor: "pointer",
    borderRadius: "0px",
    backgroundColor: "#005E50",
    
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#595959"
    },

    "&.secondary": {
      color: "#005E50",
      backgroundColor: "#FFF",
      border: "1px solid #005E50",

      "&:hover": {
        color: "#595959",
        backgroundColor: "#FFF",
        border: "1px solid #595959",
      }
    }
  },
  arrowIcon: {
    width: "40px",
    height: "40px",
    padding: "0px",
    marginRight: "-5px",
  },
  textHeader: {
    fontSize: "20px",
    color: "#202020",
    fontWeight: "bold",
  },

  textsError: {
    fontSize: '12px',
    color: 'red',
  },
  captcha: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom:"15px"
  },
  captchaError: {
    margin: '0px 14px 0px 14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336',
  },
  inputContainer:{
    paddingBottom:'15px' 
  }
});

const Globals = require("../../../../Globals.json");

const RECAPTCHA = (process.env.REACT_APP_RECAPTCHA || 'false');
class ResetPassword extends React.Component {

  state = {
    password: "",
    email: "",
    message: "",
    status: "",
    code: "",
    isLoading: false,
    next: false,
    hasError: false,
    activeTwoFactorAuthentication: false,
    checkRecaptchaMsg: null,
    errorMsg: null,
    captchaRef: React.createRef(),
  };

  handleSignIn = async () => {
    const isRecaptchaActive = RECAPTCHA !== 'false';
    let token = '';

    if (this.state.email === '' || this.state.password === '') {
      return this.handleError("Preencha e-mail e senha para continuar!");
    }

    this.setState({ isLoading: true });

    if (isRecaptchaActive) {
      token = this.state.captchaRef.current.getValue();
      this.state.captchaRef.current.reset();
    }

    if (!token && isRecaptchaActive) {
      this.setState({ isLoading: false });
      return this.handleError("Recaptcha é obrigatório");
    }

    try {
      await api.post(Globals.api.login, {
        email: this.state.email,
        password: this.state.password,
        token: token,
      });

      this.setState({ activeTwoFactorAuthentication: true });

    } catch (err) {

      const data = err.response.data;
      !!data?.code && isRecaptchaActive ? this.handleError(data.message) : this.handleError(data[0].message);

    } finally {
      this.setState({ isLoading: false });
    }

  }

  handleSubmitFactorValidation = async e => {
    this.setState({ isLoading: true });

    if (!this.state.code) {
      this.setState({ isLoading: false });
      return this.handleError("Insira o código");
    }

    try {
      let user_ip = '127.0.0.1';
      try{
        const responseIpify = await fetch('https://api64.ipify.org?format=json');
        const dataIpify = await responseIpify.json();
        user_ip = dataIpify.ip;
      }
      catch{
        try {
          const responseGeolocation = await fetch('https://geolocation-db.com/json/');
          const dataGeolocation = await responseGeolocation.json();
          user_ip = dataGeolocation.IPv4;
        }
        catch {
          // se deu erro na redundância não faz nada
        }
    }
      const response = await api.post(Globals.api.authenticate,
        {
          email: this.state.email,
          password: this.state.password,
          code: this.state.code,
          ip_address: user_ip
        });

      login(response.data.data.token);
      this.loadData();
      this.props.history.push(Globals.paths.backofficeHome);
    }
    catch (err) {
      console.log(err.response)
      this.handleError(err.response.data.message);
      this.setState({ isLoading: false });
    }
  };

  loadData = async () => {
    if (isAuthenticated()) {
      const response = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", response.data);
    }
  }

  emailHideMask = (emailAddress) => {
    let splitted;
    splitted = emailAddress.split("@");
    return splitted[0][0] + "*****" + splitted[0][splitted[0].length - 1] + "@" + splitted[1];
  }

  componentDidMount = () => {
    if (isAuthenticated()) {
      this.props.history.push(Globals.paths.backofficeHome);
    }
  }

  handleError(message) {
    const threeSecs = 3000
    this.setState({
      hasError: true,
      status: message
    });

    return setTimeout(() => {
      this.setState({
        hasError: false,
        status: ''
      });
    }, threeSecs);

  }

  cancelAuthentication = () => {
    this.setState({ activeTwoFactorAuthentication: false, code: "", email: "", password: "" });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid item container className={classes.header2}>
          <img src={Logo} alt="Logo Meu Bolso em Dia" className={classes.logo} />
          <span className={classes.textHeader}>meubolsoemdia.com.br</span>
        </Grid>
        <div className={classes.container}>
          <section>
            <div className={classes.form}>
              {!this.state.activeTwoFactorAuthentication && (
                <>
                  <div className={classes.header}>
                    <span className={classes.headerTitle}>Login</span>
                    {this.state.hasError && (
                      <Alert severity="error">
                        {this.state.status}
                      </Alert>
                    )}
                  </div>

                  <div className={classes.inputContainer}>
                    <span className={classes.textsForm}>Usuário / E-mail</span>
                    <TextField
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      placeholder="Digite seu usuário / e-mail"
                      required
                      fullWidth
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>

                  <div className={classes.inputContainer}>
                    <span className={classes.textsForm}>Senha*</span>
                    <VisibilityPasswordTextField
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      placeholder="Digite sua senha"
                      required
                      fullWidth
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                      isVisible={this.state.isConfirmPasswordVisible}
                      onVisibilityChange={(val) => { this.setState({ isConfirmPasswordVisible: val }) }}
                    />
                  </div>

                  {RECAPTCHA !== 'false' && (
                    <div
                      className={classes.captcha}
                      style={{ transform: 'scale(1)', transformOrigin: '0 0' }}
                    >
                      <ReCAPTCHA
                        style={{ margin: '0 auto' }}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        ref={this.state.captchaRef}
                        hl="pt-BR"
                      />
                    </div>
                  )}

                  <div className={classes.containerButton}>
                    <Button
                      variant="contained"
                      className={classes.styleButton}
                      onClick={() => this.handleSignIn()}
                      disabled={this.state.isLoading}
                      type="button"
                    >
                      {this.state.isLoading ? <ButtonCircularProgress /> : 'Entrar'}
                    </Button>
                  </div>
                  <div className={classes.borderGreen}></div>
                </>
              )}

              {this.state.activeTwoFactorAuthentication && (
                <>
                  <div className={classes.header}>
                    <span className={classes.headerTitle}>Verificação de Identidade</span>
                    {this.state.hasError && (
                      <Alert severity="error">
                        {this.state.status}
                      </Alert>
                    )}
                  </div>

                  <p>Um email foi enviado para:  {this.emailHideMask(this.state.email)}.
                    Insira o código recebido abaixo para nos ajudar a identificar sua identidade.
                  </p>
                  <TextField
                    className={classes.inputField}
                    size="small"
                    variant="outlined"
                    placeholder="Códigos *"
                    required
                    fullWidth
                    onChange={(e) => {
                      this.setState({ code: e.target.value });
                    }}
                  />
                  <div className={classes.containerVerifiredButtons}>
                    <Button
                      variant="contained"
                      className={classes.styleButton + " secondary"}
                      style={{ maxWidth: '180px' }}
                      onClick={() => this.cancelAuthentication()}
                      type="button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.styleButton}
                      onClick={() => this.handleSubmitFactorValidation()}
                      style={{ maxWidth: '180px' }}
                      disabled={this.state.isLoading}
                      type="button"
                    >
                      {this.state.isLoading ? <ButtonCircularProgress /> : "Verificar"}
                    </Button>
                  </div>
                  <div className={classes.borderGreen}></div>
                </>
              )}
            </div>
          </section>
        </div>
      </React.Fragment >
    );
  }
};

export default withStyles(styles)(withRouter(ResetPassword));
