import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleOutline, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import './public-class-lesson.scss';

class PublicClassLesson extends React.Component {
  renderIcon() {
    const { current, done, started } = this.props;

    if (current) {
      return <RadioButtonChecked className="publicLesson_icon_item_checked" />;
    } else if (done) {
      return <CheckCircleOutline className="publicLesson_icon_item" />;
    } else if (started) {
      return <CheckCircleOutline className="publicLesson_icon_item" />;
    } else {
      return <RadioButtonUnchecked className="publicLesson_circleOff" />;
    }
  };

  render() {
    const { current, done, started, classData, courseCompleted } = this.props;

    return (
      <>
        <div className="publicLesson_containerItems">
          {courseCompleted ? (
            <>
              {this.renderIcon()}
              <span className="publicLesson_nameLesson"> {classData.name} </span>
            </>
          ) : (
            <>
              {(done || (started && !current)) && (
                <Link
                  onClick={this.props.onClick}
                  to="#"
                >
                  {this.renderIcon()}
                  <span className="publicLesson_nameLesson">{classData.name}</span>
                </Link>
              )}

              {!done && (!started || current) && (
                <>
                  {this.renderIcon()}
                  <span className="publicLesson_nameLesson"> {classData.name} </span>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  };
}

export default PublicClassLesson;
