import React, { useEffect, useState } from 'react';
import './conteudo.scss';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableRow,
  TableHead,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import api from '../../../../../services/api';
import { useParams } from 'react-router-dom';
import { enumQuestStatus, enumTypeModules } from '../../../../../services/enums';
import moment from 'moment';
const Globals = require('../../../../../Globals.json');

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: '#202020', fontWeight: '700' }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    height: 280,
    maxHeight: 280,
    position: 'relative',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({ username }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [getFilter, setGetFilter] = useState(enumTypeModules.course);
  const [perPage, setPerPage] = useState(1);
  const [inProgress, setInProgress] = useState('');
  const [finalized, setFinalized] = useState('');
  const [currentModuleLabel, setCurrentModuleLabel] = useState('');
  const [lastAccess, setlastAccess] = useState('');
  const [lastPage, setLastPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [headCells] = useState([
    { id: 'username', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Primeiro acesso' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Último acesso' },
  ]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { userId } = useParams();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (e) => {
    const pageIndex = pagination;
    let tot = total;
    console.log('roess', e.target.value, rowsPerPage, tot);

    if (e.target.value > total) {
      setRowsPerPage(total);
      getUsersPerPage(pageIndex, tot, getFilter);
    }
    if (e.target.value < total) {
      setRowsPerPage(e.target.value);
      getUsersPerPage(pageIndex, e.target.value, getFilter);
    }
  };

  const filterBy = (e) => {
    setGetFilter(e.target.value);
    setPagination(1);
    setPerPage(1);
    getUsersPerPage(1, rowsPerPage, e.target.value);
  };

  const getUsersPerPage = (page, rows, filter) => {
    setIsLoading(true);
    api
      .get(Globals.api.getContentUser + `/${userId}`, {
        params: {
          page: page,
          per_page: rows,
          direction: 'asc',
          type_module_id: filter,
        },
      })
      .then((response) => {
        let data = [];
        let total = 0;

        setFinalized(response?.data?.summary?.finalized);
        setInProgress(response?.data?.summary?.inProgress);
        setlastAccess(response?.data?.summary?.lastAccess);
        setCurrentModuleLabel(response?.data?.summary?.typeModuleName);

        data = response?.data?.data || [];
        total = data.length;

        /*
      if (filter === enumTypeModules.course) {
        console.log('aqui cursos', response.data.data.Curso)
        data = response.data.data.Curso || [];
        total = response.data.data.Curso ? response.data.data.Curso.length : 0;
      }
      if (filter === 'artigos') {
        data = response.data.data.Artigo || [];
        total = response.data.data.Artigo ? response.data.data.Artigo.length : 0;
      }
      if (filter === 'missoes') {
        data = response.data.data.Missão || [];
        total = response.data.data.Missão ? response.data.data.Missão.length : 0;
      }*/

        console.log('*', data);
        setRows(data);
        setTotal(total);
        setLastPage(response?.data?.lastPage);
        setPagination(page);
        setRowsPerPage(rows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const colors = {
    completed : '#005E50',
    ongoing: '#05CCAE',
    available: '#6E44B3',
  }

  const getStatus = (item) => {

    console.log(getFilter, item);

    if (getFilter == enumTypeModules.course) {
      if (item.finishedAt != null) {
        return <span style={{ color: colors.completed }}>Finalizado</span>;
      } else if (item.startedAt != null) {
        return <span style={{ color: colors.ongoing }}>Em andamento</span>;
      }
    }

    if (getFilter == enumTypeModules.article) {
      if (item.startedAt != null) {
        return <span style={{ color: colors.completed }}>Lido</span>;
      }
    }

    if (getFilter == enumTypeModules.quest) {
      if (item.status == enumQuestStatus.ongoing) {
        return <span style={{ color: colors.ongoing }}>Em andamento</span>;
      } else if (item.status == enumQuestStatus.completed) {
        return <span style={{ color: colors.completed }}>Concluída</span>;
      } else if (item.status == enumQuestStatus.available) {
        return <span style={{ color: colors.available }}>Disponível</span>;
      }
    }

    if (getFilter == enumTypeModules.quiz) {
      if (item.startedAt != null) {
        return <span style={{ color: colors.available }}>Iniciado</span>;
      } else if (item.finishedAt != null) {
        return <span style={{ color: colors.completed }}>Finalizado</span>;
      }
    }

    if (getFilter == enumTypeModules.miniQuiz) {
      if (item.finishedAt != null) {
        return <span style={{ color: colors.completed }}>Finalizado</span>;
      }
    }

    return <span>-</span>;
  };

  const getLabel1 = () => {
    if (getFilter == enumTypeModules.course) return 'Data de Início';
    if (getFilter == enumTypeModules.article) return 'Data de Leitura';
    if (getFilter == enumTypeModules.quest) return 'Data de Início';
    if (getFilter == enumTypeModules.quiz) return 'Data de Início';
    if (getFilter == enumTypeModules.miniQuiz) return 'Data da Resposta';
  };

  const getLabel2 = () => {
    if (getFilter == enumTypeModules.course) return 'Data de Término';
    if (getFilter == enumTypeModules.article) return '';
    if (getFilter == enumTypeModules.quest) return 'Data de Término';
    if (getFilter == enumTypeModules.quiz) return 'Data de Término';
    if (getFilter == enumTypeModules.miniQuiz) return '';
  };

  useEffect(() => {
    getUsersPerPage(pagination, rowsPerPage, enumTypeModules.course);
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      <div className="editHeaderForm">
        <div className="displayName">
          <strong>Navegação:</strong>
          <span>{username}</span>
        </div>
      </div>

      <div className="conteudoContainer">
        <div className="conteudoboxSide">
          <div>
            <div>
              <span className="largeNumber">
                {lastAccess ? lastAccess.substring(0, 10).split('-').reverse().join('/') : ''}
              </span>
              <span>Último Login</span>
            </div>
          </div>

          <div>
            <div>
              <span className="largeNumber">{finalized}</span>
              <span>                
                {!!currentModuleLabel && (
                    currentModuleLabel + (currentModuleLabel.toLowerCase().indexOf('quiz') > -1 ? '' : 's') + '  Concluídos'
                )}
              </span>
            </div>
          </div>

          {inProgress != null && (
            <div>
              <div>
                <span className="largeNumber">{inProgress}</span>
                <span>
                  {currentModuleLabel && (
                    currentModuleLabel + (currentModuleLabel.toLowerCase().indexOf('quiz') > -1 ? '' : 's') + ' Em andamento'
                  )}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="conteudoBox1">
          <div className="conteudoBoxSelect">
            Filtrar conteúdo
            <select value={getFilter} onChange={(e) => filterBy(e)} className="selectClass">
              <option value={enumTypeModules.course}>Cursos</option>
              <option value={enumTypeModules.article}>Artigos</option>
              <option value={enumTypeModules.quest}>Missões</option>
              <option value={enumTypeModules.quiz}>Quiz</option>
              <option value={enumTypeModules.miniQuiz}>Mini Quiz</option>
            </select>
          </div>

          {/* <div className="conteudoBoxText">Nivel de engajamento ao conteúdo: <span> x</span> </div> */}
        </div>
        <div className="conteudoBox2">
          <TableContainer>
            <Table className={classes.table} aria-label="enhanced table">
              {isLoading ? (
                <div className="user_navigation_loading">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Nome</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">{getLabel1()}</TableCell>
                      <TableCell align="center">{getLabel2()}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left" className="user_navigation_cell">
                              <span>{row.DataModulesDetails.name}</span>
                            </TableCell>

                            <TableCell align="center" className="user_navigation_cell">
                              {getStatus(row.data)}
                            </TableCell>

                            <TableCell align="center" className="user_navigation_cell">
                              <span>
                                {row.data.startedAt !== null &&
                                  moment(row.data.startedAt).format('DD/MM/YYYY')}
                              </span>
                            </TableCell>

                            <TableCell align="center" className="user_navigation_cell">
                              <span>
                                {row.data.finishedAt !== null &&
                                  moment(row.data.finishedAt).format('DD/MM/YYYY')}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </div>
        <div className="user_navigation_footer_conteudo">
          <span>
            Linhas por página:
            <FormControl>
              <Select value={rowsPerPage} onChange={(e) => handleChangeRowsPerPage(e)}>
                <MenuItem value={50}>
                  <em>5</em>
                </MenuItem>
                {[50,100,150,500].includes(rowsPerPage) ? (
                  ''
                ) : (
                  <MenuItem value={rowsPerPage}>{rowsPerPage}</MenuItem>
                )}
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
          </span>

          <div className="user_exibitionTable">
            <span>
              Exibindo 1 - {rowsPerPage} de {total}{' '}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
