import React from 'react';
import './toolFinances.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Typography, Grid, Hidden } from '@material-ui/core';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import ListtoolFinances from '../../components/tool/ListToolFinances';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import Button from '../../components/button/Button';

const Globals = require("../../../Globals.json");

class ToolFinances extends React.Component {

  state = {
    classTextBtn: 0,
    profileModalOpened: false,
  }

  closeWelcomeModal = () => {
    this.setState({ profileModalOpened: false });
  };

  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="tool_finances_breadCrumb">
          <Link color="inherit" to={Globals.paths.tools} className="tool_finances_breadCrumbLink">
            Ferramentas Financeiras
          </Link>
          <Typography color="textPrimary" className="tool_finances_breadCrumbLabel"></Typography>
        </Breadcrumbs>


        <Helmet>
          <title>Ferramentas | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          {/* sm={8} md={9} */}
          <Grid item xs={12} sm={8} md={9} className="classContainertools">

            <Grid item container className="toolsItem">
              {/* <Grid item xs={12} className="toolsTitleBar">
                Ferramentas Financeiras
              </Grid>
              <Grid item xs={6} >
                suas receitas
              </Grid>
              <Grid item xs={6} >
                suas despesas
                <Button
                  width="150px"
                  type="darkBlue"
                  textAlign="center"
                  onClick={() => this.setState({ profileModalOpened: true })}
                  >
                  Despesas
                </Button>
              </Grid> */}

              <Hidden xsDown>
                <Grid container className="containerTools">
      
                  <ListtoolFinances />
                </Grid>
              </Hidden>

              <Hidden smUp>
                <div className="containerTools">
                  <ListtoolFinances />
                </div>
              </Hidden>


            </Grid >
          </Grid>


          <Hidden xsDown>
            <Grid item sm={4} md={3}>
              {/* <FeedLine>
            <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                <Favorite />
              </ToolbarItem>
            </FeedLine> */}

              <ToolbarItem tooltip="
               O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
               e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
               vantagens exclusivas para os usuários."  title="Seu Nível" color="#05CCAE">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>
            </Grid>
          </Hidden>

        </Grid >
      </>

    )
  }

}

export default watch((ToolFinances), { user: '' });
