import { makeStyles, withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const MainContent = withTheme(styled('div')`
  display: flex;

  &:first-child {
    padding-top: 0;
  }

  .aside-image {
    height: 681px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 422px;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      display: none;
    }

    img {
      position: relative;
      top: -2px;
      width: 100%;
    }
  }

  .main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      background-color: ${({ theme }) => theme.palette.secondary.main};
      font-size: 13px;
      color: ${({ theme }) => theme.palette.common.darkBlack};
      padding: 35px 52px 30px 52px;
      margin: 0;
      text-transform: uppercase;
      font-weight: 700;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding: 30px;
        display: none;
      }
    }

    &__content {
      flex-grow: 1;
    }

    &__actions {
      padding: 33px 46px;
      text-align: right;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        text-align: center;
      }


      &__button {
        background: transparent;
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: 3px;
        color: ${({ theme }) => theme.palette.primary.main};
        cursor: pointer;
        font-size: 14px;
        margin-left: 15px;
        padding: 12px 20px 10px 20px;
        text-transform: uppercase;
        transition: all .3s ease-in-out;

        &:hover {          
          border-color: #595959;
          color: #595959;
        }

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          width: 45%;
          margin: 0 5px;
        }

        @media(max-width: 391px) {
          width: 100%;
          margin-top: 5px;
        }

        &--blue {
          background: ${({ theme }) => theme.palette.primary.main};
          color: white;

          &:hover {
            background-color: #595959;
            border-color: #595959;
            color: #FFF;
          }
        }
      }
    }

    .steps {
      padding: 64px;
      padding-bottom: 0;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding: 30px;
      }
      
      h2, h3 {
        margin: 0;
        padding: 0;
      }

      h2 {
        color: ${({ theme }) => theme.palette.primary.main};
        font-size: 14px;
      }
      
      h3 {
        color: ${({ theme }) => theme.palette.common.darkBlack};
        font-size: 24px;
        font-family: 'elza';
        margin-top: 3px;
        font-weight: 700;
      }

      > p {
        margin: 0;
        padding: 20px 0 50px;
      }
      
      &__actions {
        display: flex;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          flex-direction: column;
        }

        button {
          align-items: center;
          background-color: ${({ theme }) => theme.palette.primary.main};
          border: 0;
          border-radius: 3px;
          color: white;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          justify-content: center;
          margin-right: 24px;
          min-width: 145px;
          padding: 15px 35px;
          text-transform: uppercase;

          @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
            margin: 0;
            margin-bottom: 5px;
          }

          span {
            display: inline-block;
            margin-top: 5px;
          }
        }
      }
    }
  }
`);

export default makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
}));
