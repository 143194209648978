import React from 'react';
// import './backoffice.scss';

import { watch } from 'redux-easy/lib/redux-easy';

import ArtcileDetailBlog from '../components/backoffice/Article/ArticleBlogDetail/ArticleBlogDetail';
import ArtcileListBlog from '../components/backoffice/Article/ArtcileListBlog/ArtcileListBlog';
import ExpensesMatrixItems from '../components/backoffice/ExpensesMatrixItems/ExpensesMatrixItems';
import EditExpensesMatrixItems from '../components/backoffice/ExpensesMatrixItems/ExpensesMatrixItemsDetail';
import ExpensesMatrix from '../components/backoffice/ExpensesMatrix/ExpensesMatrix';
import EditExpensesMatrix from '../components/backoffice/ExpensesMatrix/ExpensesMatrixDetail';

import QuestionMatrix from '../components/backoffice/ApplicationComponents/QuestionModule/QuestionMatrix/QuestionMatrix';
import EditQuestionMatriz from '../components/backoffice/ApplicationComponents/QuestionModule/QuestionMatrix/EditQuestionMatrix';
import QuestionOptions from '../components/backoffice/ApplicationComponents/QuestionModule/QuestionOptions/QuestionOption';
import EditQuestionOption from '../components/backoffice/ApplicationComponents/QuestionModule/QuestionOptions/EditQuestionOption';

import AchievementMatrix from '../components/backoffice/ApplicationComponents/AchievementModule/AchievementMatrix/AchievementMatrix';
import EditAchievementMatrix from '../components/backoffice/ApplicationComponents/AchievementModule/AchievementMatrix/EditAchievementMatrix';
import AchievementOptions from '../components/backoffice/ApplicationComponents/AchievementModule/AchievementOptions/AchievementOption';
import EditAchievementOption from '../components/backoffice/ApplicationComponents/AchievementModule/AchievementOptions/EditAchievementOption';

import ExpenseMatrix from '../components/backoffice/ApplicationComponents/ExpenseModule/ExpenseMatrix/ExpenseMatrix';
import EditExpenseMatrix from '../components/backoffice/ApplicationComponents/ExpenseModule/ExpenseMatrix/EditExpenseMatrix';
import ExpenseOptions from '../components/backoffice/ApplicationComponents/ExpenseModule/ExpenseOptions/ExpenseOption';
import EditExpenseOption from '../components/backoffice/ApplicationComponents/ExpenseModule/ExpenseOptions/EditExpenseOption';

import ObjectivesMatrixItems from '../components/backoffice/ObjectivesMatrixItems/ObjectivesMatrixItems';
import EditObjectivesMatrixItems from '../components/backoffice/ObjectivesMatrixItems/ObjectivesMatrixItemDetail';
import Tenants from '../components/backoffice/Tenants/Tenants';
import TenantDetails from '../components/backoffice/Tenants/TenantDetails';
import ObjectivesMatrix from '../components/backoffice/ObjectivesMatrix/ObjectivesMatrix';
import EditObjectivesMatrix from '../components/backoffice/ObjectivesMatrix/ObjectivesMatrixDetail';
import EditUser from '../components/backoffice/Users/EditUsers/EditUsers';
import DashboardAnalytics from '../components/backoffice/DashboardAnalytics/DashboardAnalytics';
import DataDownload from '../components/backoffice/DataDownload/DataDownload';
import HomeBackoffice from '../components/backoffice/Home/Home';
import Login from '../components/backoffice/Login/Login';
import RegisterProfile from '../components/backoffice/RegisterBo/Profile/Profile';
import TypeProfile from '../components/backoffice/RegisterBo/TypeProfile/TypeProfile';
import Users from '../components/backoffice/Users/Users';
import EditRoles from '../components/backoffice/UserPerfil/EditRoles/EditRole';
import UserPerfil from '../components/backoffice/UserPerfil/userPerfil';

import NavigationAppBar from '../components/backoffice/AppBar/NavigationAppBar';

import { Grid } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import Courses from '../Pages/backoffice/Courses';
import Quizzes from '../Pages/backoffice/Quizzes';
import WorkSheets from '../Pages/backoffice/WorkSheets';
import MiniQuiz from '../Pages/backoffice/MiniQuiz';
import EditCourseDetails from '../Pages/backoffice/EditCourseDetails';
import EditClassDetails from '../Pages/backoffice/EditClassDetails';
import EditClassContentArticle from '../Pages/backoffice/EditClassContentArticle';
import EditClassContentVideo from '../Pages/backoffice/EditClassContentVideo';
import EditClassContentAudio from '../Pages/backoffice/EditClassContentAudio';
import EditClassContentStory from '../Pages/backoffice/EditClassContentStory';
import EditClassContentQuiz from '../Pages/backoffice/EditClassContentQuiz';
import EditCertificationTest from '../Pages/backoffice/EditCertificationTest';
import EditCertificate from '../Pages/backoffice/EditCertificate';
import Missions from '../Pages/backoffice/Missions';
import Articles from '../Pages/backoffice/Articles';
import EditArticle from '../Pages/backoffice/EditArticle';
import EditWorksheets from '../Pages/backoffice/EditWorkSheets/EditWorkSheets';
import EditQuiz from '../Pages/backoffice/EditQuiz';
import EditMiniQuiz from '../Pages/backoffice/EditMiniQuiz';
import Incentive from '../components/backoffice/Incentive/Incentive';

const Globals = require("../../Globals.json");

class BackofficeLayout extends React.Component {

  render() {
    return (
      <Grid item sm={12} xs={12}>
        <NavigationAppBar />

        <Switch>
          <Route exact path={Globals.paths.backofficeLogin}>
            <Login history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeHome}>
            <HomeBackoffice history={this.props.history} />
          </Route>

          {/* Listagens de Módulos */}
          <Route exact path={Globals.paths.backofficeCourses} component={Courses} />
          <Route exact path={Globals.paths.backofficeQuizzes} component={Quizzes} />
          <Route exact path={Globals.paths.backofficeMissions} component={Missions} />
          <Route exact path={Globals.paths.backofficeArticles} component={Articles} />
          <Route exact path={Globals.paths.backofficeMiniQuiz} component={MiniQuiz} />

          {/* Edição de Módulos */}
          <Route exact path={Globals.paths.backofficeEditCourse} component={EditCourseDetails} />
          <Route exact path={Globals.paths.backofficeEditClass} component={EditClassDetails} />
          <Route exact path={Globals.paths.backofficeEditClassContentArticle} component={EditClassContentArticle} />
          <Route exact path={Globals.paths.backofficeEditClassContentVideo} component={EditClassContentVideo} />
          <Route exact path={Globals.paths.backofficeEditClassContentAudio} component={EditClassContentAudio} />
          <Route exact path={Globals.paths.backofficeEditClassContentStory} component={EditClassContentStory} />
          <Route exact path={Globals.paths.backofficeEditClassContentQuiz} component={EditClassContentQuiz} />
          <Route exact path={Globals.paths.backofficeEditCertificationTest} component={EditCertificationTest} />
          <Route exact path={Globals.paths.backofficeEditCertificate} component={EditCertificate} />
          <Route exact path={Globals.paths.backofficeEditArticle} component={EditArticle} />
          <Route exact path={Globals.paths.backofficeEditWorksheets} component={EditWorksheets} />
          <Route exact path={Globals.paths.backofficeEditQuiz} component={EditQuiz} />
          <Route exact path={Globals.paths.backofficeEditMiniQuiz} component={EditMiniQuiz} />

          {/* Gestão de Usuário */}
          <Route exact path={Globals.paths.backofficeUsers}>
            <Users history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeUsersPerfil}>
            <UserPerfil history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeUsersEdit}>
            <EditUser history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeProfileRegister}>
            <RegisterProfile history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeTypeRegister}>
            <TypeProfile history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeRolesEdit}>
            <EditRoles history={this.props.history} />
          </Route>

          {/* Gestão de Posts de Blog */}
          <Route exact path={Globals.paths.backofficeArticleListBlog}>
            <ArtcileListBlog history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeArticleListEdit}>
            <ArtcileDetailBlog history={this.props.history} />
          </Route>
          {/* Dashboard */}
          <Route exact path={Globals.paths.backofficeDashboard}>
            <DashboardAnalytics history={this.props.history} />
          </Route>          
          {/* Fotografia financeira */}
          <Route exact path={Globals.paths.backofficeEditExpensesMatrix}>
            <EditExpensesMatrix history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeExpensesMatrix}>
            <ExpensesMatrix history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeEditExpensesMatrixItems}>
            <EditExpensesMatrixItems history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeExpensesMatrixItems}>
            <ExpensesMatrixItems history={this.props.history} />
          </Route>
          {/* Aplicativo */}
          <Route exact path={Globals.paths.backofficeEditObjectivesMatrix}>
            <EditObjectivesMatrix history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeObjectivesMatrix}>
            <ObjectivesMatrix history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeEditObjectivesMatrixItems}>
            <EditObjectivesMatrixItems history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeObjectivesMatrixItems}>
            <ObjectivesMatrixItems history={this.props.history} />
          </Route>
          {/* Tenants */}
          <Route exact path={Globals.paths.backofficeTenants}>
            <Tenants history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeTenantDetails}>
            <TenantDetails history={this.props.history} />
          </Route>
          {/* Extração de dados */}
          <Route exact path={Globals.paths.backofficeDataDownload}>
            <DataDownload history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeQuestionMatrices}>
            <QuestionMatrix history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeQuestionMatrixEdit}>
            <EditQuestionMatriz history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeQuestionMatrixOptions}>
            <QuestionOptions history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeQuestionMatrixOptionsEdit}>
            <EditQuestionOption history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeAchievementMatrices}>
            <AchievementMatrix history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeAchievementMatrixEdit}>
            <EditAchievementMatrix history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeAchievementMatrixOptions}>
            <AchievementOptions history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeAchievementMatrixOptionsEdit}>
            <EditAchievementOption history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeExpenseMatrices}>
            <ExpenseMatrix history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeExpenseMatrixEdit}>
            <EditExpenseMatrix history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeExpenseMatrixOptions}>
            <ExpenseOptions history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeExpenseMatrixOptionsEdit}>
            <EditExpenseOption history={this.props.history} />
          </Route>

          {/** Incentive */}
          <Route exact path={Globals.paths.backofficeIncentive}>
            <Incentive history={this.props.history} />
          </Route>

          {/*
          <Route exact path={Globals.paths.backofficeCourses}>
            <Courses history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeEditCourse}>
            <EditCourseDetails history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeClasses}>
            <CreateClass history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeLessonDetail}>
            <LessonDetail history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeCreateContent}>
            <CreateContent history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeMission}>
            <CreateMission history={this.props.history} />
          </Route>

          */}
          <Route exact path={Globals.paths.backofficeWorksheets} component={WorkSheets} />

        </Switch>

        <div className="backoffice_footer" />
      </Grid>
    )
  }
}

export default watch((BackofficeLayout), { user: '' });;

