import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles"
import { ReactComponent as MiniLogo } from '../../../../assets/logo/mini-logo.svg'

export const Debts = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 44.27%, #E5E5E5 100%);
  
  .inner-wrapper {
    align-items: center;
    display: flex;
    height: 1024px;
    margin: auto;
    max-width: 1440px;
    position: relative;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      flex-direction: column-reverse;
      height: auto;
      position: initial;
    }
    
    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      flex-direction: column-reverse;
      height: auto;
      padding-bottom: 60px;
      position: initial;
    }
  }

  .debt {
    position: relative;
    margin: 0;
    z-index: 0;

    &__image {
      height: auto;
      max-width: 780px;
      width: 55vw;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin-top: 40px;
        width: 90vw;
      }

      /* TABLET */
      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        margin-top: 0;
        width: 90vw;
      }
    }

    &__shield {
      position: absolute;

      &--small {
        width: 130px;
        top: 740px;
        left: 657px;
      }
      
      &--medium {
        width: 140px;
        top: 145px;
        left: 745px;
      }
      
      &--regular {
        width: 225px;
        top: 540px;
        left: -180px;
        z-index: -1;
      }
      
      &--outlines {
        width: 160px;
        top: 50px;
        left: 760px;
        z-index: -1;
      }

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        display: none;

        &--medium {
          display: block;
          width: 110px;
          top: 20px;
          left: initial;
          right: -38px;
        }
      }
    }
  }
`);

const renderLogo = (type) => {
  return {
    small: css`
      width: 47px;
      top: 200px;
      left: 40px;
    `,
    medium: css`
      width: 50px;
      top: 640px;
      left: 700px;
    `,
    big: css`
      width: 84px;
      top: 790px;
      left: 0;
    `,
  }[type];
}

export const Logo = withTheme(styled(MiniLogo)`
  position: absolute;
  ${({ variant }) => renderLogo(variant)}

  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: none;
  }
`);