import React, { Component } from 'react';
import { Container, DialogContent, Grid, Hidden, IconButton } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import './profileIndex.scss';
import SemiCircleProgress from '../../../../logged_out/components/progress/semi-circle/SemiCircleProgress';
import RectProgress from '../../../../logged_out/components/progress/rectangle/RectProgress';
import { Radar } from 'react-chartjs-2';
import Globals from '../../../../Globals.json';

class ProfileIndex extends Component {

    regionData = [
        {
            key: 'Região Centro-Oeste',
            indexScore: 58,
            financialSecurityScore: 56,
            financialKnowledgeScore: 55,
            financialBehaviorScore: 60,
            financialFreedomScore: 52,
        },
        {
            key: 'Região Nordeste',
            indexScore: 55,
            financialSecurityScore: 57,
            financialKnowledgeScore: 55,
            financialBehaviorScore: 59,
            financialFreedomScore: 52,
        },
        {
            key: 'Região Norte',
            indexScore: 57,
            financialSecurityScore: 53,
            financialKnowledgeScore: 58,
            financialBehaviorScore: 65,
            financialFreedomScore: 53,
        },
        {
            key: 'Região Sudeste',
            indexScore: 56,
            financialSecurityScore: 54,
            financialKnowledgeScore: 55,
            financialBehaviorScore: 61,
            financialFreedomScore: 50,
        },
        {
            key: 'Região Sul',
            indexScore: 61,
            financialSecurityScore: 61,
            financialKnowledgeScore: 54,
            financialBehaviorScore: 61,
            financialFreedomScore: 52,
        }
    ];

    constructor (props) {
        super(props);
        this.state = {
            average: 100,
            indexScore: 100,
            financialSecurityScore: 100,
            financialKnowledgeScore: 100,
            financialBehaviorScore: 100,
            financialFreedomScore: 100,
            averageFiltered: 100,
            financialSecurityScoreFiltered: 100,
            financialKnowledgeScoreFiltered: 100,
            financialBehaviorScoreFiltered: 100,
            financialFreedomScoreFiltered: 100,
            filterBy: 'region',
            hasRegion: false,
            personaData: {
                name: '',
                description: '',
                type: -1,
            }
        }
    }

    loadData = () => {
        const userProfile = this.props.user.profile;

        if (userProfile.personaData) {
            const indexData = userProfile.indexData
            const personaData = userProfile.personaData;

            this.setState({ personaData });

            if (userProfile.CadUserAddressesOne && userProfile.CadUserAddressesOne.cad_ibge_state_ibge_code) {
                const filtered = this.regionData.find(region => region.key === userProfile.CadUserAddressesOne.CadIbgeState.region);

                setTimeout(() => {
                    this.setState({
                        average: Globals.data.averageBRIndex,
                        averageFiltered: filtered.indexScore,
                        financialSecurityScoreFiltered: filtered.financialSecurityScore,
                        financialKnowledgeScoreFiltered: filtered.financialKnowledgeScore,
                        financialBehaviorScoreFiltered: filtered.financialBehaviorScore,
                        financialFreedomScoreFiltered: filtered.financialFreedomScore,
                        ...indexData,
                        hasRegion: true,
                    })
                }, 1000);
            }
            else {
                setTimeout(() => {
                    this.setState({
                        average: Globals.data.averageBRIndex,
                        ...indexData
                    })
                }, 1000);
            }


        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
            this.loadData();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    handleFilterBy (type) {

        let state = {};

        if (type === "region") {
            state = {
                averageFiltered: 38,
                financialSecurityScoreFiltered: 32,
                financialKnowledgeScoreFiltered: 10,
                financialBehaviorScoreFiltered: 43,
                financialFreedomScoreFiltered: 45,
                filterBy: type
            }
        }
        else {
            state = {
                averageFiltered: 25,
                financialSecurityScoreFiltered: 22,
                financialKnowledgeScoreFiltered: 5,
                financialBehaviorScoreFiltered: 33,
                financialFreedomScoreFiltered: 32,
                filterBy: type
            }
        }
        this.setState(state);
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    closeModalAndGoToProfile = () => {
        this.handleClose();
        this.props.history.push(Globals.paths.profile);
    }

    scrollToComparators = () => {
        document.getElementById('profile-index-body').scrollTo(0, document.getElementById('index-comparator').offsetTop - 120);
    }

    render () {

        const {
            financialSecurityScore,
            financialKnowledgeScore,
            financialBehaviorScore,
            financialFreedomScore,
            financialSecurityScoreFiltered,
            financialKnowledgeScoreFiltered,
            financialBehaviorScoreFiltered,
            financialFreedomScoreFiltered
        } = this.state;

        const options = {
            scale: {
                ticks: {
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    stepSize: 20
                }
            },
            legend: {
                position: 'bottom'
            }
        };
        const data = {
            labels: ['Segurança Financeira', 'Habilidade Financeira', 'Comportamento Financeiro', 'Liberdade financeira'],
            datasets: [
                {
                    label: 'Meu Resultado',
                    backgroundColor: '#DAF439',
                    borderColor: '#005E50',
                    pointBackgroundColor: '#DAF439',
                    pointBorderColor: '#005E50',
                    pointHoverBackgroundColor: '#DAF439',
                    pointHoverBorderColor: '#005E50',
                    data: [financialSecurityScore, financialKnowledgeScore, financialBehaviorScore, financialFreedomScore]
                },
                {
                    label: this.state.filterBy === 'region' ? 'Região' : 'Perfil',
                    backgroundColor: '#05CCAE',
                    borderColor: '#005E50',
                    pointBackgroundColor: '#05CCAE',
                    pointBorderColor: '#005E50',
                    pointHoverBackgroundColor: '#05CCAE',
                    pointHoverBorderColor: '#005E50',
                    data: [financialSecurityScoreFiltered, financialKnowledgeScoreFiltered, financialBehaviorScoreFiltered, financialFreedomScoreFiltered]
                }
            ]
        };

        const defs = [
            (
                <linearGradient key="gradientBR" id="gradientBR" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="50%" stopColor="#DAF439" />
                    <stop offset="100%" stopColor="#FF6E5A" />
                </linearGradient>
            ), (
                <linearGradient key="gradient" id="gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor="#05CCAE" />
                    <stop offset="100%" stopColor="#005E50" />
                </linearGradient>
            ),
            (
                <linearGradient key="gradientBRText" id="gradientBRText" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#005E50" />
                    <stop offset="100%" stopColor="#005E50" />
                </linearGradient>
            ),
            (
                <linearGradient key="gradientText" id="gradientText" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#05CCAE" />
                    <stop offset="100%" stopColor="#05CCAE" />
                </linearGradient>
            )
        ];

        return (

            <>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="dialog-content profile-index-body" id="profile-index-body" dividers>
                    <Container className="content-holder">
                        <Grid container className="profile-index-content">
                            <Grid container item xs={12} className="washed-gold-bg">
                                <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <h5>Meu resultado do Índice de Saúde Financeira:</h5>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid container item xs={12} md={6} justify="center" alignItems="center" className="p20">
                                    <Hidden smDown>
                                        {
                                            this.state.personaData.type > -1 &&
                                            <img src={`/images/personas/${this.state.personaData.type}.svg`} width="80%" alt={`imagem da persona ${this.state.personaData.name}`} />
                                        }
                                    </Hidden>
                                    <Hidden mdUp>
                                        {
                                            this.state.personaData.type > -1 &&
                                            <img src={`/images/personas/${this.state.personaData.type}.svg`} width="40%" alt={`imagem da persona ${this.state.personaData.name}`} />
                                        }
                                    </Hidden>
                                </Grid>
                                <Grid container item xs={12} md={6} justify="center" alignItems="center" className="p20">
                                    <div className="profile-content">
                                        <h4>Seu resultado é:</h4>
                                        <h1>{this.state.personaData.name}</h1>
                                        <div className="know-more-profiles-holder">
                                            <span role="button" onClick={() => this.props.showContent({ to: 'profile-list', from: this.props.page })}>conheça os outros perfis</span>
                                        </div>
                                        <p>
                                            {this.state.personaData.description}
                                        </p>
                                        <br />
                                        <span role="button" onClick={() => this.scrollToComparators()}>comparar meus resultados</span>
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <Grid container item xs={12} className="washed-gold-bg">
                                <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <h5>Seu resultado comparado a quem tem base financeira semelhante à sua é:</h5>
                                </Grid>
                            </Grid> */}
                            <Grid container>                                
                                <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="pt20">
                                    <div className="chart-container">
                                        <SemiCircleProgress cssClass={'outer'} diameter={400} defs={defs} strokeWidth={25} background={'url(#gradientBR)'} percentage={this.state.average}></SemiCircleProgress>
                                        <SemiCircleProgress cssClass={'inner'} diameter={300} strokeWidth={35} background={'url(#gradient)'} percentage={this.state.indexScore}></SemiCircleProgress>
                                        <div className="inner-description">
                                            <svg preserveAspectRatio="xMinYMax meet" height="200" width="400" viewBox="0 0 400 200">
                                                <text x="200" textAnchor="middle" y="165" fill="url(#gradientText)">{this.state.indexScore}</text>
                                            </svg>
                                        </div>
                                        <div className="outer-description">
                                            <svg preserveAspectRatio="xMinYMax meet" height="200" width="400" viewBox="0 0 400 200">
                                                <text x="200" textAnchor="middle" y="195" fill="url(#gradientBRText)">{this.state.average}</text>
                                            </svg>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                                    <div className="chart-legend your-result">
                                        <span className="legend-icon"></span>
                                        <span className="legend-text">Meu resultado</span>
                                    </div>
                                    <div className="chart-legend br-result">
                                        <span className="legend-icon"></span>
                                        <span className="legend-text">Média Brasileira</span>
                                    </div>
                                </Grid>
                                <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                                    <span role="button" onClick={() => this.props.showContent({ to: 'index-detail', from: this.props.page })}>conheça o Índice</span>
                                </Grid>
                                <Grid container className="p20">
                                    <Grid container item xs={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                        <h5 className="pb20" style={{ color: "#005E50", fontWeight: 500 }}>Índice de segurança financeira</h5>
                                        <RectProgress id={'1'} height={30} itemHeight={30} fillColor={'#05CCAE'} background={'#EDEDED'} labelColor={'#202020'} percentage={this.state.financialSecurityScore} showValueLabel={true}></RectProgress>
                                    </Grid>
                                    <Grid container item xs={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                        <h5 className="pb20" style={{ color: "#005E50", fontWeight: 500 }}>Índice de habilidade financeira</h5>
                                        <RectProgress id={'2'} height={30} itemHeight={30} fillColor={'#005E50'} background={'#EDEDED'} labelColor={'#202020'} percentage={this.state.financialKnowledgeScore} showValueLabel={true}></RectProgress>
                                    </Grid>
                                    <Grid container item xs={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                        <h5 className="pb20" style={{ color: "#005E50", fontWeight: 500 }}>Índice de comportamento financeiro</h5>
                                        <RectProgress id={'3'} height={30} itemHeight={30} fillColor={'#FF6E5A'} background={'#EDEDED'} labelColor={'#202020'} percentage={this.state.financialBehaviorScore} showValueLabel={true}></RectProgress>
                                    </Grid>
                                    <Grid container item xs={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                        <h5 className="pb20" style={{ color: "#005E50", fontWeight: 500 }}>Índice de liberdade financeira</h5>
                                        <RectProgress id={'4'} height={30} itemHeight={30} fillColor={'#DAF439'} background={'#EDEDED'} labelColor={'#202020'} percentage={this.state.financialFreedomScore} showValueLabel={true}></RectProgress>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className="washed-gold-bg">
                                <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <h5>Compare seu resultado com as pessoas de sua região:</h5>
                                </Grid>
                            </Grid>
                            {this.state.hasRegion ?
                                <Grid container id="index-comparator">
                                    {/* <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <Button onClick={() => this.handleFilterBy('region')} type={`${this.state.filterBy === 'region' ? 'full-solid' : 'primary'}`} icon="none">Região</Button>
                                    <Button onClick={() => this.handleFilterBy('profile')} type={`${this.state.filterBy === 'profile' ? 'full-solid' : 'primary'}`} icon="none">Perfil</Button>
                                </Grid> */}
                                    <Grid container item xs={12} direction="row" justify="center" alignItems="center" className="p20">
                                        <Grid container item xs={12} direction="column" justify="center" alignItems="center">
                                            <div className="chart-container comparator">
                                                <SemiCircleProgress cssClass={'outer'} diameter={300} defs={defs} strokeWidth={15} background={'url(#gradientBR)'} percentage={this.state.averageFiltered}></SemiCircleProgress>
                                                <SemiCircleProgress cssClass={'inner'} diameter={220} strokeWidth={25} background={'url(#gradient)'} percentage={this.state.indexScore}></SemiCircleProgress>
                                                <div className="inner-description">
                                                    <svg preserveAspectRatio="xMinYMax meet" height="150" width="300" viewBox="0 0 300 150">
                                                        <text x="150" textAnchor="middle" y="120" fill="url(#gradientText)">{this.state.indexScore}</text>
                                                    </svg>
                                                </div>
                                                <div className="outer-description">
                                                    <svg preserveAspectRatio="xMinYMax meet" height="150" width="300" viewBox="0 0 300 150">
                                                        <text x="150" textAnchor="middle" y="145" fill="url(#gradientBRText)">{this.state.averageFiltered}</text>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                                            <div className="chart-legend your-result">
                                                <span className="legend-icon"></span>
                                                <span className="legend-text">Meu resultado</span>
                                            </div>
                                            <div className="chart-legend br-result">
                                                <span className="legend-icon"></span>
                                                <span className="legend-text">Média {this.state.filterBy === 'region' ? 'Região' : 'Perfil'}</span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="p20">
                                        <Radar data={data} options={options} />
                                    </Grid>
                                </Grid>
                                :
                                <Grid container id="index-comparator">
                                    <Grid container item xs={12} direction="row" justify="center" alignItems="center" className="p20">
                                        <p>Você precisa <span role="button" onClick={this.closeModalAndGoToProfile}>completar o seu cadastro</span> para ver o comparativo.</p>
                                    </Grid>
                                </Grid>                      
                            }
                            {/* <Grid container item xs={12} className="washed-gold-bg">
                                <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <h5>Compartilhe seu resultado:</h5>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid container item xs={12} justify="center" alignItems="center" className="p20">
                                    <QRCode
                                        value={`https://pefpi.meubolsoemdia.com.br/relatorio/${this.props.user.id}`}
                                        size={180}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}
                                        renderAs={"svg"}
                                    />
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Container>
                </DialogContent >
            </>
        )
    }
}

export default watch(withRouter(ProfileIndex), { user: '' });