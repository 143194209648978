import React from 'react';
import './quiz.scss';

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Breadcrumbs, Grid, Hidden } from '@material-ui/core';

import Favorite from '../../components/sidebar/Favorite';
import FeedItem from '../../components/../components/feed/FeedItem';
import FeedLine from '../../components/../components/feed/FeedLine';
import QuizRecommended from '../../components/quiz/QuizRecommended';
import QuizTesteList from '../../components/quiz/QuizTesteList';
import QuizTesteListResponsive from '../../components/quiz/QuizTestListResponsive';
import QuizRecoResponsive from '../../components/quiz/QuizRecoResponsive';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

const Globals = require("../../../Globals.json");
class Quiz extends React.Component {
  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="quiz_breadCrumb">
          <Link color="inherit" to={Globals.paths.quiz} className="quiz_breadCrumbLink">
            Quiz
            </Link>
        </Breadcrumbs>

        <Helmet>
          <title>Quiz | Meu bolso em dia</title>
        </Helmet>

        <Grid container>

          <Grid item xs={12} sm={8} md={9} className="quizClassContainer">

            <Hidden smUp>
              <FeedLine>
                <FeedItem title="Quiz: recomendados para você" overflowHidden={true} >
                  <QuizRecoResponsive history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} />
                </FeedItem>
              </FeedLine>
            </Hidden>

            <Hidden xsDown>
              <Grid item className="quizItem">
                <Grid item xs={12} className="quizTitleBar">
                  Quiz: recomendados para você
                </Grid>
              </Grid >

              <div className="quizContainerRecomendados">
                <QuizRecommended history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }}
                />
                <div className="quizRightSpacing">.</div>
              </div>
              <br />
            </Hidden>


            <Grid container >

              <Hidden smUp>
                <FeedLine>
                  <FeedItem title="Veja mais">
                    <QuizTesteListResponsive history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} />
                  </FeedItem>
                </FeedLine>
              </Hidden>

              <Hidden xsDown>
                <FeedItem title="Veja mais" >
                  <QuizTesteList history={this.props.history} endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} />
                </FeedItem>
              </Hidden>

            </Grid>

          </Grid>


          <Hidden xsDown>
            <Grid item sm={4} md={3} direction="column">
              <FeedLine >
                <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                  <Favorite />
                </ToolbarItem>
              </FeedLine>
            </Grid>
          </Hidden>

        </Grid >
      </>
    )
  }
}
export default Quiz;