import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { dispatch, dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import IconCourse from '../../assets/icon/cursos_e_conteudos.svg';
import { ReactComponent as ContactIcon } from '../../assets/icon/header-icons/icon-contact.svg';
import { ReactComponent as FavoritesIcon } from '../../assets/icon/header-icons/icon-favorites.svg';
import { ReactComponent as SearchIcon } from '../../assets/icon/header-icons/icon-search.svg';
import { ReactComponent as TutorialIcon } from '../../assets/icon/header-icons/icon-tutorial.svg';
import IconFerramentas from '../../assets/icon/ferramentas.svg';
import IconHome from '../../assets/icon/home.svg';
import IconProgresso from '../../assets/icon/progresso.svg';
import IconMissoes from '../../assets/icon/missoes.svg';
import IconQuizV2 from '../../assets/icon/quiz.svg';
import IconIncentive from '../../assets/icon/incentivo.svg';
import Rocket from '../../assets/icon/rocket.png';
import Logo from '../../assets/logo/logo-blue.svg';
import LogoXs from '../../assets/logo/mini-logo.svg';
import LogoXsWithBorder from '../../assets/logo/mini-logo-with-border.svg';
import Globals from '../../Globals.json';
import api from '../../services/api';
import { logout } from '../../services/auth';
import { enumTypeUserStatuses } from '../../services/enums';
import { getNextIndexAssessmentDateText } from '../../services/util';
import Achievement from '../components/achievement/Achievement';
import ConquestNv from '../components/conquest/Conquest_nv';
import ContactForm from '../components/ContactForm';
import MenuTop from '../components/menu/MenuTop';
import ChangePasswordDialog from '../components/profile/ChangePassword';
import Socket from '../components/socket/Socket';
import Article from '../Pages/article/Article';
import AvatarPage from '../Pages/avatar/Avatar';
import CaclIndice from '../Pages/calcIndice/CalcIndice';
import Certificate from '../Pages/certificate/Certificate';
import CertificationTest from '../Pages/certificationTest/CertificationTest';
import ClassDetail from '../Pages/classDetail/ClassDetail';
import ClosedShopping from '../Pages/closedShopping/closedShopping';
import Courses from '../Pages/course/Courses';
import CourseDetail from '../Pages/courseDetail/CourseDetail';
import Favorite from '../Pages/favorite/Favorite';
import Help from '../Pages/help/Help';
import Home from '../Pages/home/Home';
import Inbox from '../Pages/inbox/Inbox';
import Mission from '../Pages/mission/Mission';
import MissionCustom from '../Pages/missionCustom/MissionCustom';
import OpenShopping from '../Pages/openShopping/openShopping';
import Incentive from '../Pages/incentive/Incentive';
import Profile from '../Pages/profile/Profile';
import Progresso from '../Pages/progresso/Progresso';
import Quiz from '../Pages/quiz/Quiz';
import QuizTest from '../Pages/quizTest/QuizTest';
import ToolFinances from '../Pages/toolFinances/ToolFinances';
import Photograph from '../Pages/photograph/Photograph';
import DreamSimulator from '../Pages/dreamSimulator/DreamSimulator';
import Worksheets from '../Pages/worksheets/Worksheets';
import AlcoolGasolina from '../Pages/alcoolGasolina/AlcoolGasolina';
import ToolPlanning from '../Pages/toolPlanning/ToolPlanning';
import TrailDetail from '../Pages/trailDetail/TrailDetail';
import Dialogs from '../../services/dialogs';
import Drawer from '@material-ui/core/Drawer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import './mainLayout.scss';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: '#202020',
    maxWidth: 220,
    fontSize: '14px',
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class MainLayout extends React.Component {
  state = {
    isLoading: false,
    userId: '',
    firstName: '',
    avatarInitials: '',
    greeting: 'Bom dia',
    menuAnchorElement: null,
    value: 'perfil',
    anchorEl: false,
    notifications: [],
    notificationsOpen: false,
    alertNotifications: false,
    changePassword: false,
    amountNotification: [],
    user_avatar: '',
    openAlert: false,
    coins: 0,
    isfb: -1,
    userProfile: {},
    level: 0,
    searchInput: '',
    showSearch: false,
    search: [],
    conquest: false,
    conquestNv: false,
    newQuest: true,
    nameTropy: '',
    descriptionTropy: '',
    thubmailTropy: '',
    conquestLevel: 0,
    conquestUnblock: [],
    searchLength: 0,
    users: [
      { id: 116, name: 'Perdi o emprego e agora?', type_module_id: 2, container_id: 34 },
      { id: 116, name: 'E agora?', type_module_id: 2, container_id: 34 },
    ],
    input: '',
    searchTerm: '',
    showAllCustomMission: false,
    showCustomMission: false,
    nextIndexAssessmentDateText: '',
    isContactFormOpened: false,
    openDraw: false,
    passwordExpired: false,
  };

  handleTour() {
    const { location, history } = this.props;

    if (location.pathname === '/home') {
      dispatchSet('tour.show', true);
    } else {
      Dialogs.show(
        <div>Atenção</div>,
        'Você será direcionado para nossa página principal para que possamos realizar o tour em nossa plataforma',
        [
          {
            label: 'Cancelar',
          },
          {
            label: 'Continuar',
            onClick: () => {
              history.push({
                pathname: '/home',
                state: {
                  showTutorial: true,
                },
              });
            },
          },
        ]
      );
    }
  }

  handleNotificationClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, notificationsOpen: true });
  };

  handleNotificationClose = () => {
    this.setState({ notificationsOpen: false, anchorEl: null });
  };

  handleAvatarClick = (event) => {
    this.setState({ menuAnchorElement: event.target });
  };

  handleMenuClose = (e) => {
    if (['imagePerfil', 'imagePerfilImage'].indexOf(e.target.className) < 0) {
      this.setState({ menuAnchorElement: null });
    }
  };

  handleMenuLogout = () => {
    this.setState({ menuAnchorElement: null });
    logout();
    this.props.history.replace(Globals.paths.root);
  };

  handleMenuKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ menuAnchorElement: null });
    }
  };

  async loadData() {
    this.setState({
      isLoading: true,
      nextIndexAssessmentDateText: await getNextIndexAssessmentDateText(),
    });

    const response = await api.get(Globals.api.selfInfo);
    const firstName = response.data.username.match(/.*?(?=$| )/)[0];
    let fullName = response.data.username;

    if (fullName.indexOf(' ') === -1) fullName += ' ';

    const initials = fullName.match(/^(.).* (.)?/);

    dispatchSet('user.profile', response.data);

    let coins = response.data.progress.total_moedas || 0;
    this.setState({
      isLoading: false,
      userId: response.data.id,
      firstName,
      avatarInitials: initials[1] + (initials[2] ? initials[2] : ''),
      coins: coins,
      userProfile: response.data,
      user_avatar: response.data.user_avatar,
      level: response.data.progress.level,
      passwordExpired: response.data.force_password_change
    });
  }

  async loadIndiceData() {
    const response = await api.post(Globals.api.getModules, { container_id: 58 });
    if (response?.data?.container.user_module_data.isfb) {
      this.setState({ isfb: response.data.container.user_module_data.isfb });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  loadSearch = async (search) => {
    await api
      .post('/api/v1/search/search_modules', {
        pesquisa: search,
      })
      .then((response) => {
        this.setState({
          search: response.data.resultado,
          searchLength: response.data.total_resultados,
        });
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.loadData();
    this.loadIndiceData();
    let greetings = 'Bom dia';
    const hour = new Date().getHours();

    if (hour >= 12) greetings = 'Boa tarde';
    if (hour >= 18) greetings = 'Boa noite';

    this.setState({ greeting: greetings });
    this.fetchNotifications();
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.user) !== JSON.stringify(this.props.user)) {
      this.setState({
        coins: this.props.user.profile.progress.total_moedas,
        level: this.props.user.profile.progress.level,
      });
    }
  }

  async reloadData() {
    this.loadData();
    this.loadIndiceData();
    this.fetchNotifications();
  }

  openMockTrophy = () => {
    this.setState({
      conquest: true,
      thubmailTropy: 'https://pefmbddiag.blob.core.windows.net/cdn-pi/prata.png',
      nameTropy: 'Agora eu sei!',
      descriptionTropy: 'Completar o tutorial inicial da plataforma',
    });
  };

  updateWonTrophy = (e) => {
    this.setState({
      conquest: true,
      nameTropy: e.name,
      descriptionTropy: e.description,
      thubmailTropy: e.thumbnail,
    });
  };

  allCustomMission = (e) => {
    this.setState({ showAllCustomMission: true });
  };

  customMission = (e) => {
    this.setState({ showCustomMission: true });
  };

  newQuest = () => {
    this.props.enqueueSnackbar('Você tem uma nova misssão disponível!', {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  coinsChanged = () => {
    dispatch('updateUserProfile');
  };

  missionDone = () => {
    if (
      this.props.user &&
      this.props.user.profile &&
      this.props.user.profile.type_users_status_id !== enumTypeUserStatuses.forwardToBlog
    ) {
      this.props.enqueueSnackbar(
        'Você concluiu uma missão! Vá para a area de missões para resgatar os prêmios',
        { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } }
      );
    }
  };

  newQuestline = () => {
    this.props.enqueueSnackbar('Você possui novas misssões disponíveis!', {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  updateLevelChanged = (e) => {
    this.props.enqueueSnackbar('Você alcançou o nível ' + e.level, {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    dispatch('updateUserProfile');
  };

  async fetchNotifications(e = null) {
    var array = [];
    const response = await api.get(Globals.api.getNotifications);

    this.setState({ notifications: response.data });

    this.state.notifications.map((item, index) => (
      <>
        {item.type_notifications_status_id === 2 && (
          <>
            {(array = [...array, index])}
            {this.setState({ openAlert: true, amountNotification: array })}
          </>
        )}
        {setTimeout(() => {
          return this.setState({ openAlert: false });
        }, 5000)}
      </>
    ));
  }

  async markNotificationAsRead(idNotification) {
    await api.post(Globals.api.readNotifications, { ref_user_notification_id: idNotification });
    this.fetchNotifications();
  }

  notificationClick(item) {
    this.handleNotificationClose();
    this.markNotificationAsRead(item.id);
  }

  openModalChangePassword = () => {
    this.setState({ changePassword: true });
  };

  closeModalchangePassword = () => {
    this.setState({ changePassword: false });
  };

  handleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch, input: '' });
  };
  handleSearch2 = (module, id) => {
    this.setState({ showSearch: !this.state.showSearch, input: '' });
    this.props.history.push(`/${module}/${id}`);
  };

  results = () => {
    return !this.state.searchTerm
      ? this.state.people
      : this.state.people.filter((person) =>
        person.toLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())
      );
  };

  onChangeHandler(e) {
    e.persist();
    let search = e.target.value;
    this.setState({ input: search });
    this.loadSearch(search);
  }

  handleContactForm = (isOpened) => {
    this.setState({
      isContactFormOpened: isOpened,
    });
  }

  onCloseChangePasswordDialog =()=>{
    this.setState({changePassword: false, passwordExpired: false})
  }

  render() {
    const { value, search, isContactFormOpened } = this.state;
    const arraySearch = search.slice(0, 10);
    const list = arraySearch.map((search, index) => (
      <li key={index} className={this.state.input === search && 'filterActive'}>
        <Link className="searchLink" onClick={() => this.handleSearch2(search.module, search.id)}>
          {search.nome_conteudo}
        </Link>
      </li>
    ));

    return (
      <>
        <ContactForm isOpened={isContactFormOpened} onClose={() => this.handleContactForm(false)} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ overflow: 'hidden' }}
        >
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className="mainHeader"
          >
            <Grid container className="topBar">
              <Hidden smUp>
                <Link to={Globals.paths.home}>
                  <img src={LogoXs} alt="Logo Meu Bolso em Dia" className="logoXs" />
                </Link>
              </Hidden>
              <Box className="headerTools">
                <Hidden xsDown>
                  <Link to={Globals.paths.home}>
                    <img src={Logo} alt="Logo Meu Bolso em Dia" className="logo" />
                  </Link>
                </Hidden>
              </Box>
              <Box>
                <Achievement
                  thubmailTropy={this.state.thubmailTropy}
                  nameTropy={this.state.nameTropy}
                  descriptionTropy={this.state.descriptionTropy}
                  open={this.state.conquest}
                  onClose={() => this.setState({ conquest: false })}
                />
                <ConquestNv
                  level={this.state.conquestLevel}
                  unblock={this.state.conquestUnblock}
                  open={this.state.conquestNv}
                  onClose={() => this.setState({ conquestNv: false })}
                />
                <div className="iconsFlex">
                  {this.state.showSearch ? (
                    <>
                      <TextField
                        placeholder="Buscar..."
                        margin="normal"
                        variant="outlined"
                        value={this.state.input}
                        onChange={(e) => this.onChangeHandler(e)}
                        className="inputRoot"
                        InputProps={{
                          type: 'text',
                          className: 'mainTextField',
                          startAdornment: (
                            <button
                              onClick={() => this.setState({ showSearch: false })}
                              type="button"
                              className="main_closedBtn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                              </svg>
                            </button>
                          ),
                        }}
                      />
                      {this.state.input !== '' && (
                        <div className="searchContent">
                          {list.length === 0 ? (
                            <span className="notFoundMsg">
                              Nenhum resultado foi encontrado para: <span>{this.state.input}</span>{' '}
                            </span>
                          ) : (
                            <span>{list}</span>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <HtmlTooltip title="Busca">
                      <SearchIcon className="iconsHeader" onClick={() => this.handleSearch()} />
                    </HtmlTooltip>
                  )}

                  <HtmlTooltip title="Fale Conosco">
                    <ContactIcon
                      className="iconsHeader"
                      onClick={() => this.handleContactForm(true)}
                    />
                  </HtmlTooltip>

                  <HtmlTooltip title="Tutorial">
                    <TutorialIcon
                      className="iconsHeader icon-header-tutorial"
                      onClick={() => this.handleTour()}
                    />
                  </HtmlTooltip>

                  <Link
                    to={Globals.paths.favorite}
                    style={{
                      background: 'none',
                      border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <HtmlTooltip title="Favoritos">
                      <FavoritesIcon className="iconsHeader" />
                    </HtmlTooltip>
                  </Link>

                  <Socket
                    channel={'notifications'}
                    user={this.props.user}
                    event={'levelUp'}
                    onUpdate={(e) => this.updateLevelChanged(e)}
                  />

                  <Socket
                    channel={'notifications'}
                    user={this.props.user}
                    event={'coinsChanged'}
                    onUpdate={(e) => this.coinsChanged(e)}
                  />

                  <Socket
                    channel={'notifications'}
                    user={this.props.user}
                    event={'questDone'}
                    onUpdate={(e) => this.missionDone(e)}
                  />

                  <Socket
                    channel={'notifications'}
                    user={this.props.user}
                    event={'newQuestLine'}
                    onUpdate={(e) => this.newQuestline()}
                  />

                  <Socket
                    channel={'notifications'}
                    user={this.props.user}
                    event={'wonTrophy'}
                    onUpdate={(e) => this.updateWonTrophy(e)}
                  />

                  <Avatar
                    onClick={this.handleAvatarClick}
                    className={`avatar ${this.props.user.profile.user_avatar ? '' : 'initialAvatar'
                      }`}
                  >
                    {this.props.user.profile.user_avatar !== undefined &&
                      this.props.user.profile.user_avatar !== null ? (
                      <div className="imagePerfil">
                        <img
                          className="imagePerfilImage"
                          src={this.props.user.profile.user_avatar}
                          alt="Avatar"
                        />
                      </div>
                    ) : this.props.user.profile.user_avatar === undefined &&
                      this.state.user_avatar !== null &&
                      this.state.user_avatar !== undefined ? (
                      <div className="imagePerfil">
                        <img
                          className="imagePerfilImage"
                          src={this.state.user_avatar}
                          alt="Avatar"
                        />
                      </div>
                    ) : (
                      <div className="imagePerfil">
                        <div style={{ paddingTop: "2px" }}>
                          {this.state.avatarInitials}
                        </div>
                      </div>
                    )}
                  </Avatar>
                </div>

                {this.state.showAllCustomMission && (
                  <Alert
                    color="info"
                    icon={
                      <img
                        src={Rocket}
                        alt="Imagem de foguete"
                        style={{ padding: '0px', width: '30px', height: '30px', marginTop: '6px' }}
                      />
                    }
                    className="rocketAlert"
                    action={
                      <IconButton
                        aria-label="close"
                        color="#000"
                        size="small"
                        onClick={() => {
                          this.setState({ showAllCustomMission: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <div style={{ color: '#004178', fontWeight: 'bold' }}>
                      Você tem uma nova missão Personalizada.
                      <Link
                        to={Globals.paths.missions.replace(':type?', '')}
                        style={{ textDecoration: 'none', color: '#05CCAE', fontWeight: 'bold' }}
                      >
                        <div onClick={() => this.setState({ showAllCustomMission: false })}>
                          Veja aqui.
                        </div>
                      </Link>
                    </div>
                  </Alert>
                )}

                {this.state.showCustomMission && (
                  <Alert
                    color="info"
                    icon=" "
                    className="rocketAlert"
                    action={
                      <IconButton
                        aria-label="close"
                        color="#000"
                        size="small"
                        onClick={() => {
                          this.setState({ showCustomMission: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <div style={{ color: '#004178', fontWeight: 'bold', width: '250px' }}>
                      Você tem missões que precisam ser continuadas, atualizadas ou revisadas,
                      consulte o painel de missões para dar continuidade aos seus objetivos.
                      <Link
                        to={Globals.paths.missions.replace(':type?', '')}
                        style={{ textDecoration: 'none', color: '#05CCAE', fontWeight: 'bold' }}
                      >
                        <div onClick={() => this.setState({ showCustomMission: false })}>
                          Veja aqui.
                        </div>
                      </Link>
                    </div>
                  </Alert>
                )}

                <Popper
                  open={this.state.menuAnchorElement !== null}
                  anchorEl={this.state.menuAnchorElement}
                  role={'button'}
                  transition
                  disablePortal
                  style={{ position: 'relative', zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper style={{ marinTop: '5px' }}>
                        <ClickAwayListener onClickAway={this.handleMenuClose}>
                          <MenuList
                            autoFocusItem={Boolean(this.state.menuAnchorElement)}
                            id="menu-list-grow"
                            onKeyDown={this.handleMenuKeyDown}
                          >
                            <Link
                              to={Globals.paths.profile}
                              style={{ textDecoration: 'none', color: 'black' }}
                            >
                              {' '}
                              <MenuItem onClick={this.handleMenuClose}>Perfil</MenuItem>{' '}
                            </Link>
                            <MenuItem onClick={() => this.setState({ changePassword: true })}>
                              Trocar Senha
                            </MenuItem>
                            <MenuItem onClick={this.handleMenuLogout}>Sair</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Grid>
            <Grid container className="topMenu">
              <MenuTop handleContactForm={this.handleContactForm} lvl={this.state.level} coins={this.state.coins} isfb={this.state.isfb} />
            </Grid>
          </Grid>
          <Grid item className="mainContainer">
           
            {(this.state.changePassword || this.state.passwordExpired) && (
              <ChangePasswordDialog
                open={this.state.changePassword || this.state.passwordExpired} 
                onClose={this.onCloseChangePasswordDialog}
                passwordExpiredInstructions = {this.state.passwordExpired}
              />
            )}
            <h1
              className="greeting"
              style={{ visibility: this.state.firstName.length > 0 ? 'visible' : 'hidden' }}
            >
              <span role="img" aria-label="Acenando">
                👋
              </span>{' '}
              {this.state.greeting},{' '}
              {this.state.firstName.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
              })}
            </h1>

            <div className={`contentPoints saldo-moedas`}>
              <label className="fontPoints">
                Saldo:{' '}
                <HtmlTooltip
                  aria-label="moedas"
                  title="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma."
                >
                  <span className="greenText">
                    {this.state.coins} ponto{this.state.coins === 1 ? '' : 's'}{' '}
                  </span>
                </HtmlTooltip>
                {this.state.isfb > -1 && (
                  <>
                    / Índice de Saúde Financeira:{' '}
                    <HtmlTooltip aria-label="indice" title={this.state.nextIndexAssessmentDateText}>
                      <span className="greenText">{this.state.isfb} </span>
                    </HtmlTooltip>
                  </>
                )}
                / Nível:{' '}
                <HtmlTooltip
                  aria-label="nivel"
                  title="O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado
                e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e
                vantagens exclusivas para os usuários."
                >
                  <span className="greenText"> {this.state.level}</span>
                </HtmlTooltip>
              </label>
            </div>
            <Switch>
              <Route exact path={Globals.paths.home}>
                <Home
                  history={this.props.history}
                  isfb={this.state.isfb}
                  coins={this.state.coins ? this.state.coins : 0}
                />
              </Route>

              <Route exact path={Globals.paths.calcIndice}>
                <CaclIndice history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.courses}>
                <Courses history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.courseDetail}>
                <CourseDetail
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                />
              </Route>

              <Route exact path={Globals.paths.article}>
                <Article
                  history={this.props.history}
                  articleId={this.props.match.params.articleId}
                />
              </Route>

              <Route exact path={Globals.paths.quiz}>
                <Quiz history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.class}>
                <ClassDetail
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                  classId={this.props.match.params.classId}
                />
              </Route>

              <Route exact path={Globals.paths.progresso}>
                <Progresso
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                  userProfile={this.state.userProfile}
                />
              </Route>

              <Route exact path={Globals.paths.trailDetail}>
                <TrailDetail
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                />
              </Route>

              <Route exact path={Globals.paths.certificateFinal}>
                <Certificate
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                />
              </Route>

              {/* Prova */}
              <Route exact path={Globals.paths.certificationTest}>
                <CertificationTest
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                />
              </Route>

              {/* Resultado da Prova */}
              <Route exact path={Globals.paths.certificationTestResult}>
                <CertificationTest
                  history={this.props.history}
                  courseId={this.props.match.params.courseId}
                  showResult
                />
              </Route>

              <Route exact path={Globals.paths.favorite}>
                <Favorite
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                />
              </Route>

              <Route exact path={Globals.paths.tools}>
                <ToolFinances
                  coins={this.state.coins ? this.state.coins : 0}
                  history={this.props.history}
                />
              </Route>

              <Route exact path={Globals.paths.photograph}>
                <Photograph
                  coins={this.state.coins ? this.state.coins : 0}
                  history={this.props.history}
                />
              </Route>

              <Route exact path={Globals.paths.inbox}>
                <Inbox />
              </Route>

              <Route exact path={Globals.paths.missionsWithId}>
                <Mission
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                />
              </Route>

              <Route exact path={Globals.paths.missions}>
                <Mission
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                />
              </Route>

              <Route exact path={Globals.paths.missionsCustom}>
                <MissionCustom history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.planning}>
                <ToolPlanning history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.help}>
                <Help history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.profile}>
                <Profile history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.incentive}>
                <Incentive history={this.props.history} />
              </Route>

              <Route exact path={Globals.paths.avatar}>
                <AvatarPage
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                  isfb={this.state.isfb}
                />
              </Route>

              <Route exact path={Globals.paths.openShopping}>
                <OpenShopping
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                  isfb={this.state.isfb}
                />
              </Route>

              <Route exact path={Globals.paths.closedShopping}>
                <ClosedShopping
                  history={this.props.history}
                  coins={this.state.coins ? this.state.coins : 0}
                  isfb={this.state.isfb}
                />
              </Route>

              <Route exact path={Globals.paths.quizTest}>
                <QuizTest history={this.props.history} quizId={this.props.match.params.quizId} />
              </Route>

              <Route exact path={Globals.paths.dreamSimulator}>
                <DreamSimulator
                  coins={this.state.coins ? this.state.coins : 0}
                  history={this.props.history}
                />
              </Route>

              <Route exact path={Globals.paths.alcoolGasolina}>
                <AlcoolGasolina
                  coins={this.state.coins ? this.state.coins : 0}
                  history={this.props.history}
                />
              </Route>

              <Route exact path={Globals.paths.worksheets}>
                <Worksheets
                  coins={this.state.coins ? this.state.coins : 0}
                  history={this.props.history}
                />
              </Route>

            </Switch>
          </Grid>

          <Drawer
            anchor="bottom"
            open={this.state.openDraw}
            onClose={() => this.setState({ openDraw: false })}
          >
            <List
              className="drawMainMenu"
            >

              <ListItem button key="home"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/home"
              >
                <ListItemIcon>
                  <img alt="Icone Seu Espaço" src={IconHome} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Seu Espaço' />
              </ListItem>

              <ListItem button key="cursos"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/cursos"
                disabled={this.state.level <= 1}
              >
                <ListItemIcon>
                  <img alt="Icone Cursos e Conteúdos" src={IconCourse} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Cursos e Conteúdos' />
              </ListItem>

              <ListItem button key="quiz"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/quiz"
                disabled={this.state.level <= 1}
              >
                <ListItemIcon>
                  <img alt="Icone Quiz" src={IconQuizV2} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Quiz' />
              </ListItem>

              <ListItem button key="ferramentas"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/ferramentas"
                disabled={this.state.level <= -1}
              >
                <ListItemIcon>
                  <img alt="Icone Ferramentas Financeiras" src={IconFerramentas} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Ferramentas Financeiras' />
              </ListItem>

              <ListItem button key="missoes"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/missoes/"
              >
                <ListItemIcon>
                  <img alt="Icone Missões" src={IconMissoes} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Missões' />
              </ListItem>

              <ListItem button key="progresso"
                onClick={() => { this.setState({ openDraw: false }) }}
                component={Link}
                to="/progresso"
                disabled={this.state.level <= 1}
              >
                <ListItemIcon>
                  <img alt="Icone Progresso" src={IconProgresso} className="iconsMenu" />
                </ListItemIcon>
                <ListItemText primary='Progresso' />
              </ListItem>
              {this.state.level >= 2 &&
                <ListItem button key="incentivo"
                  onClick={() => { this.setState({ openDraw: false }) }}
                  component={Link}
                  to="/incentivo"
                >
                  <ListItemIcon>
                    <img alt="Icone Incentivo" src={IconIncentive} className="iconsMenu" />
                  </ListItemIcon>
                  <ListItemText primary='Incentivo' />
                </ListItem>
              }


            </List>
          </Drawer>

          <BottomNavigation
            value={value}
            onChange={this.handleChange}
            className="tabNavigation"
            showLabels
          >
            <BottomNavigationAction
              className="ItemNavigation"
              component={Link}
              to="/home"
              value="home"
              icon={<img alt="Icone Home" src={IconHome} className="iconsMenu" />}
            />

            <BottomNavigationAction
              className="ItemNavigation mobile-menu-cursos"
              component={Link}
              to="/cursos"
              value="cursos"
              disabled={this.state.level <= 1}
              icon={<img alt="Icone Curso" src={IconCourse} className={`iconsMenu ${this.state.level <= 1 ? "disabled-menu-icon" : ""}`} />}
            />

            <BottomNavigationAction
              className="ItemNavigation"
              value="mainMenu"
              onClick={() => this.setState({ openDraw: true })}
              icon={<img alt="Icone Main Menu" src={LogoXsWithBorder} className="iconsMainMenu" />}
            />

            <BottomNavigationAction
              className="ItemNavigation mobile-menu-ferramentas"
              component={Link}
              to="/ferramentas"
              value="ferramentas"
              disabled={this.state.level <= -1}
              icon={<img alt="Icone ferramentas" src={IconFerramentas} className={`iconsMenu ${this.state.level <= -1 ? "disabled-menu-icon" : ""}`} />}
            />

            <BottomNavigationAction
              className="ItemNavigation"
              component={Link}
              to="/progresso"
              disabled={this.state.level <= 1}
              icon={<img alt="Icone Progresso" src={IconProgresso} className={`iconsMenu ${this.state.level <= 1 ? "disabled-menu-icon" : ""}`} />}
            />
          </BottomNavigation>
        </Grid>
      </>
    );
  }
}
export default withRouter(watch(withSnackbar(MainLayout), { user: '' }));
