import React from 'react';
import './styles/initialModalQuiz.scss';

import { withStyles, Modal, CircularProgress, Backdrop } from '@material-ui/core';
import { watch, dispatchSet } from 'redux-easy/lib/redux-easy';
import AlertErrors from '../../components/alertErrors/alerts';

import api from '../../../services/api';

//Componentes
import ListQuestions from './listQuestions';
import Button from '../button/Button';
import { logout } from '../../../services/auth';

const Globals = require("../../../Globals.json");

const styles = theme => ({
  container: {
    width: "80%",
    height: "600px",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    boxShadow: "4px 4px 10px #0003",
    minHeight: "200px",
    maxWidth: "1500px",
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "0",

    // "@media only screen and (max-width: 800px)": {
    [theme.breakpoints.down('xs')]: {
      height: "435px",
      flexDirection: "column",
    }
  },

  contentImg: {
    width: "50%",
    height: "100%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "246px",
    }
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRight: "5px solid #004178",

    [theme.breakpoints.down('xs')]: {
      height: "185px",
      objectFit: "initial",
      marginTop: "-28px",
      borderRight: "none",
      borderBottom: "5px solid #004178",
      marginBottom: "20px",
    },
  },

  contentText: {
    marginTop: "-10px",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "50%",
    marginLeft: "20px",

    [theme.breakpoints.down('xs')]: {
      marginLeft: "0px",
      marginTop: "-80px",
      padding: "3px",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    }
  },

  titleTestIndice: {
    fontSize: "20px",
    float: "right !important",
    marginTop: "-100px",
    fontWeight: "500",

    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      height: "20px",
      marginTop: "0px",
      width: "100%",
      textAlign: "center",
    }
  },

  contentTitleTest: {
    width: "400px",

    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "34px",
      textAlign: "center",
    }
  },

  titleTest: {
    fontWeight: "300",
    fontSize: "40px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
      heigth: "31px",
      overflow: "auto",
      textAlign: "center",

    }
  },

  contentDetails: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down('xs')]: {
      marinTop: "0px",
    }
  },

  textDetails: {
    fontSize: "14px",
    marginLeft: "3px",
    fontWeight: "500",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    }
  },

  icons: {
    width: "20px",
    height: "20px",
    padding: "0",

    "&:nth-child(3)": {
      marginLeft: "30px",
    },
    "&:nth-child(5)": {
      marginLeft: "30px",
    },

    [theme.breakpoints.down('xs')]: {
      "&:nth-child(3)": {
        marginLeft: "6px",
      },

      "&:nth-child(5)": {
        marginLeft: "0px",
      },
    }
  },
  contentBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",

    [theme.breakpoints.down('xs')]: {
      marginTop: "40px",
    }
  },

  description: {
    fontSize: "20px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
      textAlign: "center",
      height: "25px",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
    }
  },

  closeQuestion: {
    position: "absolute",
    right: "0",
    background: "none",
    border: "none",
    fontSize: "40px",
    cursor: "pointer",
    zIndex: "9999",
    color: "#fff",
    margin: "0px 2px 0px 0px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "28px"
    }
  },

  close: {
    position: "absolute",
    top: "-10px",
    right: "0",
    background: "none",
    border: "none",
    fontSize: "40px",
    cursor: "pointer",
    zIndex: "9999",
    color: "#fff",
    margin: "1px 6px 0px 0px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "28px",
    }
  },

  containerQuestions: {
    width: "80%",
    height: "700px",
    position: "relative",
    maxWidth: "1500px",

    [theme.breakpoints.down('xs')]: {
      marginTop: "50%",
      width: "90%",
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    overflow: 'auto',
  },
  header: {
    color: "#FFF",
    width: "100%",
    height: "100px",
    display: "flex",
    padding: "30px",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#004178",
    marginTop: "0px",

    [theme.breakpoints.down('xs')]: {
      marginTop: "80%",
      height: "50px",
    }
  },

  headerTestFim: {
    color: "#FFF",
    width: "100%",
    height: "100px",
    display: "flex",
    padding: "30px",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#004178",
    marginTop: "0px",

    [theme.breakpoints.down('xs')]: {
      height: "50px",
    },

    "@media only screen and (max-width: 850px)": {
      height: "75px"
    }
  },

  cardContent: {
    backgroundColor: "#FFF",
    height: "80%",
    marginTop: "-40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px 50px 30px 50px",

    [theme.breakpoints.down('xs')]: {
      // height: "445px",
      padding: "0px 20px 0px 20px"
    }
  },

  title: {
    fontSize: "20px",
    fontWeight: "bold",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    }
  },

  titleCard: {

    "@media only screen and (max-width: 850px)": {
      fontSize: "22px",
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
      textAlign: "center",
      marginTop: "28px"
    },
  },

  textPoints: {
    fontWeight: "500",
    fontSize: "64px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "32px",
    }
  },

  positionBtn: {
    marginTop: "45px",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    width: "80%",
    height: "80px",

    [theme.breakpoints.down('xs')]: {
      marginTop: "20px",
    }
  },

  containerArticle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  contentAsk: {
    width: "75%",
    height: "205px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "auto",
    marginTop: "20px",

    [theme.breakpoints.down('xs')]: {
      width: "95%",
      height: "180px",
      marginTop: "10px",
    }
  },

  finalMessage: {
    textAlign: "center",
    width: "90%",
    marginTop: "35px",

    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginTop: "35px",
    },

    "@media only screen and (max-width: 400px)": {
      marginTop: "50px"
    }
  },
  msg: {
    fontSize: "16px",

    "@media only screen and (max-width: 700px)": {
      fontSize: "15px",
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    }
  },
  materialRec: {
    fontSize: "14px",
    marginTop: "-15px",
  },
  item: {
    backgroundColor: "#fff",
    width: "49%",
    overflow: "auto",
    minHeight: "160px",
    minWidth: "355px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    // margin: " 0px 7px 0px 7px",

    "&:nth-child(1)": {
      marginRight: "15px",
    },

  },
  left: {
    // height: "100%",
    // width: "60%",
    // minWidth: "150px",
    width: '60%',
    minWidth: '150px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '171px',
  },
  right: {
    height: "100%",
    width: "40%",
    minWidth: "150px",
    paddingLeft: "10px",
    borderLeft: "4px solid #004178",
  },
  titleArticle: {
    color: "#202020",
    fontSize: "14px",
    marginTop: "22px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "11px",
    },
  },
  borderTitleArticle: {
    backgroundColor: "#05CCAE",
    width: "8%",
    height: "3px",
  },
  containerText: {
    width: "100%",
    height: "54%",
  },
  textArticle: {
    color: "#202020",
    display: '-webkit-box',
    marginTop: "-10px",
    overflow: 'hidden',
    fontSize: '18px',
    maxHeight: '85px',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    }
  },
  contentButton: {
    width: "100%",
    height: "16%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  imageCenter: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    borderRight: "6px solid #004178"
  },
});

// const Globals = require("../../../Globals.json");
class InitialModalQuiz extends React.Component {

  state = {
    open: true,
    modal: 0,
    quiz: [],
    loading: false,
    isLoading: false,
    thumbnail: "",
    name: "",
    id: "",
    description: "",
    indiceSaude: null,
    finalMessage: "",
    isfb: 0,
    articles: [],
    alertError: '',
  }

  async loadIndiceData () {
    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });

      if (response?.data?.container?.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadData () {

    this.setState({ isLoading: true });
    try {
      const postData = {
        "container_id": this.props.id,
      };

      const response = await api.post(Globals.api.getModules, postData);

      const { container } = response.data;
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', response.data);

      this.setState({
        isLoading: false,
        thumbnail: container.thumbnail,
        name: container.name,
        id: container.id,
        description: container.description,
        quizData: container.data
      });

    } catch (error) {
      this.setState({ alertError: 404 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  async getArticleRec (result) {

    this.setState({ loading: true });
    try {
      const postData = {
        type_action_id: 19,
        data_modules_detail_id: 58,
        isfb: result
      };
      const response = await api.post(Globals.api.userActionEvent, postData);

      console.log("Dados aqui: ", response.data[0].recomendation)
      this.setState({ loading: false, articles: response.data[0].recomendation })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  setResult = (result) => {
    this.getArticleRec(result);

    let finalMessage = "";

    if (result <= 400) {
      finalMessage = "Você não tem conseguido se organizar e manter suas contas em dia e a falta de controle das despesas e do seu dia a dia fazem com que você, provavelmente, tenha dificuldades para fazer planos financeiros para o futuro.";
    }
    else if (result > 400) {
      finalMessage = "Ainda não temos conteúdo.";
    }
    // else if (result <= 700) {
    //   finalMessage = "Parabéns, você se preocupa com seu dinheiro e tem boas práticas de controle e organização, mas ainda passa alguns apertos, certo? Você está no caminho certo, mas, a gente precisa trabalhar e fortalecer aspectos da sua organização e conhecimentos financeiros.";
    // }
    // else {
    //   finalMessage = "Parabéns, você controla as suas finanças e entende as dinânicas das suas receitas e despesas. Agora é a hora de entender quais são as possibilidades a sua disposição e como podemos tornar sua organização mais lucrativa e fácil de controlar.";
    // }

    this.setState({ finalMessage, isfb: result });

  }

  handleOpen = () => {
    this.setState({ open: true, modal: 1 });
  }

  handleClose = () => {
    this.setState({ open: false, modal: 0 })
  }

  sendData = () => {
    this.setState({ modal: 2 })
  }

  finished = () => {
    dispatchSet("user.profile.isfb", this.state.isfb);
    if (this.state.isfb <= 400) {
      window.location.href = "/home"
    }
    else if (this.state.isfb > 400) {
      logout();
      // this.props.onClose()
      window.setTimeout(() => window.location.href = "https://meubolsoemdia.com.br", 10);
    }
  }

  componentDidMount () {

    if (this.props.quizData) {
      // console.log( "QUIZDATA::", this.props.quizData );
      const container = this.props.quizData;
      this.setState({
        isLoading: false,
        thumbnail: container.thumbnail,
        name: container.name,
        id: container.id,
        description: container.description,
        quizData: container.blocks
      });
    }
    else {
      this.loadIndiceData();
      this.loadData();
    }

  }

  render () {

    const { classes } = this.props;
    console.log('props aqui', this.props)

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ?
          <Backdrop className={classes.backdrop} open={this.state.isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop> :
          <>
            <Modal
              open={this.props.open}
              aria-labelledby="Modal Quiz"
              aria-describedby="Modal Quiz"
              className={classes.modal}
            >
              {this.state.modal === 0 ?//0
                <div className={classes.container}>
                  <button type="button" className={classes.close} data-dismiss="modal"
                    onClick={this.props.onClose}>&times;</button>

                  <div className={classes.contentImg}>
                    <img src={this.state.thumbnail} alt={"img"} className={classes.image} />
                  </div>
                  <div className={classes.contentText}>

                    <span className={classes.titleTestIndice}></span>
                    <div className={classes.contentTitleTest}>
                      <h1 className={classes.titleTest}>{this.state.name}</h1>
                    </div>
                    <p className={classes.description}>
                      {this.state.description}
                    </p>
                    {/* <div className={classes.contentDetails}>
                      <AccessTime className={classes.icons} />
                      <span className={classes.textDetails}>7 Minutos</span>
                      <SignalCellularAlt className={classes.icons} />
                      <span className={classes.textDetails}>4 Questões</span>
                      <NotListedLocation className={classes.icons} />
                      <span className={classes.textDetails}>Fácil</span>
                    </div> */}
                    <div className={classes.contentBtn}>
                      <Button onClick={this.handleOpen} id={this.state.id}>
                        CALCULAR
                      {/* <ArrowRight className={classes.quizButtonIcon} /> */}
                      </Button>
                    </div>

                  </div>

                </div>

                : this.state.modal === 1 ? //1
                  <div className={classes.containerQuestions}>
                    <button type="button" className={classes.closeQuestion} data-dismiss="modal"
                      onClick={this.props.onClose}>&times;</button>
                    <ListQuestions
                      questions={this.state.quizData}
                      id={this.state.id}
                      onClose={this.handleClose}
                      sendData={() => this.sendData()}
                      setResult={this.setResult}
                    />
                  </div>

                  : this.state.modal === 2 && //2
                  <>
                    {this.state.isfb <= 401 ?
                      <div className={classes.containerQuestions}>
                        <button type="button" className={classes.closeQuestion} data-dismiss="modal"
                          onClick={this.props.onClose}>&times;</button>
                        <div className={classes.headerTestFim}>
                          <span className={classes.title}>ÍNDICE DE SAÚDE FINANCEIRA</span>
                        </div>
                        <div className={classes.cardContent}>
                          <div className={classes.finalMessage}>
                            <span className={classes.msg}>{this.state.finalMessage}</span>
                          </div>
                          <h1 className={classes.titleCard}>SEU ÍNDICE ATUAL É {this.state.isfb} PONTOS.</h1>
                          {/* <span className={classes.materialRec}>Veja o material que separamos para você:</span>

                          <div className={classes.containerArticle} >
                            <div className={classes.contentAsk}>
                              <>
                                {this.state.articles.map((item, index) => (
                                  <div key={index} className={classes.item}>

                                    <div className={classes.left} style={{ backgroundImage: `url(${item.thumbnail})` }} />

                                    <div className={classes.right}>

                                      <h3 className={classes.titleArticle}>
                                        <div className={classes.borderTitleArticle} />
                                    ARTIGO
                                  </h3>

                                      <div className={classes.containerText}>
                                        <span className={classes.textArticle}>{item.name}</span>
                                      </div>

                                      <div className={classes.contentButton}>
                                        <Button to={`/artigo/${item.id}`}
                                          width="130px" minWidth="40%" minHeight="30px" fontSize="12px">
                                          COMEÇAR
                                    </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            </div>
                          </div> */}

                          <div className={classes.positionBtn} >
                            <Button icon="none" textAlign={"center"} onClick={() => this.finished()}>FINALIZAR</Button>
                          </div>
                        </div>

                      </div> :

                      <div className="initial_modal_container">
                        <div className={`initial_modal_header ${this.state.isfb >= 400 ? 'danger' : 'primaryLight'}`} >
                          <div className="initial_modal_border">
                            <div className="initial_modal_emoji">{this.state.isfb >= 400 ? "😅" : "🎉"}</div>
                          </div>
                        </div>
                        <div className="initial_modal_content">
                          <span>Seu resultado no cálculo do Índice foi</span>
                          <h1>{this.state.isfb}</h1>

                          <p>
                            Olá, <strong>{this.props.user.profile.username}</strong>!
                          </p>

                          <p>
                            O seu resultado no Índice de Saúde Financeira está acima da média da população e, no momento, te qualifica para a segunda fase de inscritos para os serviços da plataforma.
                          </p>
                          <p>
                            Em breve, você receberá um e-mail para continuar vivendo essa experiência.
                          </p>
                          <p>
                            Por enquanto, acesse e aprimore os seus conhecimentos no portal <a href="https://meubolsoemdia.com.br/" target="_blank" rel="noopener noreferrer" >Meu Bolso em Dia</a>.
                            São ferramentas, insights, conteúdos exclusivos e serviços de educação financeira à sua disposição.
                          </p>
                          <p>
                            Até breve! 😉
                          </p>

                          <div className="intial_modal_psButton">
                            <Button icon="none" type="darkBlue" textAlign={"center"} onClick={() => this.finished()}>ACESSAR</Button>
                          </div>
                        </div>
                      </div>
                    }
                  </>
              }
            </Modal>
          </>
        }
      </>
    )
  }
}

export default withStyles(styles)(watch(InitialModalQuiz, { user: '' }));
