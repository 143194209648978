import React from 'react';
import NavigationAppBar from '../AppBar/NavigationAppBar';
import { watch, dispatchSet } from 'redux-easy/lib/redux-easy';
import { Link } from 'react-router-dom';
import UsersIcon from '../../../../assets/icon/admin/users.svg';
import ArticlesIcon from '../../../../assets/icon/admin/articles.svg';
import CoursesIcon from '../../../../assets/icon/admin/courses.svg';
import ImportedArticlesIcon from '../../../../assets/icon/admin/imported-articles.svg';
import MiniQuizIcon from '../../../../assets/icon/admin/mini-quiz.svg';
import DataExtractIcon from '../../../../assets/icon/admin/extracao-de-dados.svg';
import DashboardIcon from '../../../../assets/icon/admin/dashboard.svg';
import MatrixesIcon from '../../../../assets/icon/admin/matrizes.svg';
import ItemsIcon from '../../../../assets/icon/admin/itens.svg';
import QuizIcon from '../../../../assets/icon/admin/quiz.svg';
import InstitutionsIcon from '../../../../assets/icon/admin/instituicoes.svg';
import WorkSheetsIcon from '../../../../assets/icon/admin/planilhasElistinhas.svg';
import IncentiveIcon from '../../../../assets/icon/admin/incentivo-instituicao-financeira.svg';
import { CircularProgress } from '@material-ui/core';
import './home.scss';
import api from '../../../../services/api';
import { isAdmin } from '../../../../services/auth';
const Globals = require("../../../../Globals.json");

class Home extends React.Component {
  state = {
    username: '',
    isOnlyDashboardViewer: true,
    loading: true
  }

  async loadData() {
    this.setState({
      loading: true,
    });
    try {
      const response = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", response?.data);

      const isAdm = await isAdmin();

      const isOnlyDashboardViewer = !isAdm;

      this.setState({
        username: response?.data?.username,
        isOnlyDashboardViewer: isOnlyDashboardViewer,
      });
    } catch (err) {

    }
    finally {
      this.setState({
        loading: false,
      });
    }

  }

  componentDidMount() {
    this.loadData()
  }

  render() {

    return (
      <>
        <NavigationAppBar text="Home" />
        <div className="backoffice_header">
          <h2>Bem vindo(a), {this.state.username} </h2>
        </div>
        <div className="containerHomeBo">
          {this.state.loading ? (<CircularProgress className={'loading'} />) : (
            <>
              {!this.state.isOnlyDashboardViewer &&
                <>
                  <Link to={Globals.paths.backofficeUsers} className="itemHome">
                    <img alt="ícone usuários" src={UsersIcon} className="user-icon" />
                    <div className="itemTitle">Gestão de Usuários</div>
                    <div className="itemText">Visualizar, editar e adicionar informações.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeArticleListBlog} className="itemHome">
                    <img alt="ícone artigos importados" src={ImportedArticlesIcon} />
                    <div className="itemTitle">Artigos Importados</div>
                    <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeArticleHome} className="itemHome">
                    <img alt="ícone artigos" src={ArticlesIcon} />
                    <div className="itemTitle">Artigos</div>
                    <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeCourses} className="itemHome">
                    <img alt="ícone cursos" src={CoursesIcon} />
                    <div className="itemTitle">Cursos</div>
                    <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeDataDownload} className="itemHome">
                    <img alt="ícone cursos" src={DataExtractIcon} />
                    <div className="itemTitle">Extração de Dados - Comunicação</div>
                    <div className="itemText">Receba os dados por data.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeMiniQuiz} className="itemHome">
                    <img alt="ícone cursos" src={MiniQuizIcon} />
                    <div className="itemTitle">Mini Quizzes</div>
                    <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeQuizzes} className="itemHome">
                    <img alt="ícone cursos" src={QuizIcon} />
                    <div className="itemTitle">Quizzes</div>
                    <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeWorksheets} className="itemHome">
                    <img alt="ícone usuários" src={WorkSheetsIcon} className="worksheet-icon" />
                    <div className="itemTitle">Planilhas e Listas</div>
                    <div className="itemText">Vizualizar, editar e adicionar Planilhas ou Listas.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeExpensesMatrix} className="itemHome">
                    <img alt="ícone cursos" src={MatrixesIcon} />
                    <div className="itemTitle">Fotografia Financeira - Matrizes</div>
                    <div className="itemText">Vizualizar, editar e adicionar matrizes.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeExpensesMatrixItems} className="itemHome">
                    <img alt="ícone cursos" src={ItemsIcon} />
                    <div className="itemTitle">Fotografia Financeira - Itens</div>
                    <div className="itemText">Vizualizar, editar e adicionar itens das matrizes.</div>
                    <div className="borderGreen" />
                  </Link>
                  <Link to={Globals.paths.backofficeTenants} className="itemHome">
                    <img alt="ícone cursos" src={InstitutionsIcon} />
                    <div className="itemTitle">Instituições</div>
                    <div className="itemText">Vizualizar, editar e adicionar instituições financeiras.</div>
                    <div className="borderGreen" />
                  </Link>
                </>
              }
              <Link to={Globals.paths.backofficeDashboard} className="itemHome">
                <img alt="ícone dashboard" src={DashboardIcon} />
                <div className="itemTitle">Dashboard</div>
                <div className="itemText">Visualizar informações do Dashboard.</div>
                <div className="borderGreen" />
              </Link>              
              <Link to={Globals.paths.backofficeIncentive} className="itemHome">
                <img alt="ícone usuários" src={IncentiveIcon} />
                <div className="itemTitle">Incentivos</div>
                <div className="itemText">Visualizar os incentivos que as instituições financeiras oferecem.</div>
                <div className="borderGreen" />
              </Link>
            </>
          )}
        </div>
      </>
    )
  }
}

export default watch((Home), { user: '' });
