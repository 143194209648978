import React, { useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Grid,
  Box,
  Paper,
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  Select,
  MenuItem
} from '@material-ui/core';
import Button from '../../../button/Button';

import './styles.scss'
import TenantTableModal from '../TenantTableModal/TenantTableModal';

export default function TenantParamsModal({ handleSetState, data }) {
  
  const [editMode, setEditMode] = useState(false);
  const [index, setIndex] = useState(null);
  const [propsData, setPropsData] = useState(data);
  const [changed, setChanged] = useState(false);

  const [form, setForm] = useState({
    key: {
      value: '',
      error: false,
      errorMessage: ' ',
      validations: ['required']
    },
    value: {
      value: '',
      error: false,
      errorMessage: ' ',
      validations: ['required']
    },
    type: {
      value: '',
      error: false,
      errorMessage: ' ',
      validations: ['required']
    }

  })

  const handleChangeValue = (event, fieldName) => {
    setChanged(true);
    console.log(event.target);
    const values = { ...form };
    values[fieldName].value = event.target.value;
    setForm(values);
  }

  const validadeStringField = (e, fieldName) => {
    checkFieldErrors(fieldName);
  }

  const populateForm = (data) => {
    const values = { ...form };
    values.key.value = data.key;
    values.value.value = data.value;
    values.type.value = data.type;
    setForm(values);
  }

  const resetAllFields = () => {
    const values = { ...form }
    Object.keys(values).forEach((key) => {
      resetField(key);
    })
    setEditMode(false);
  }

  const checkFieldErrors = (fieldName) => {
    const values = { ...form }
    if (values[fieldName].validations?.includes('required')) {
      if (values[fieldName].value === null || values[fieldName].value === undefined || values[fieldName].value?.trim() === '') {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Campo obrigatório.'
        setForm(values)
        return true
      }
    }

    if (values[fieldName].validations?.includes('isUrl')) {

      const pattern = new RegExp('^((http|https):\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locater

      const valid = pattern.test(values[fieldName].value);

      let doubleSlashsCounter = 0
      if (values[fieldName].value) {
        for (let i = 0; i < values[fieldName].value.length - 1; i++) {

          if (values[fieldName].value[i] === '/' && values[fieldName].value[i + 1] === '/') {
            doubleSlashsCounter++
          }
        }
      }

      if (values[fieldName].value && (!valid || doubleSlashsCounter > 1)) {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Url inválida.'
        setForm(values)
        return true
      }
    }
    if (values[fieldName].validations?.includes('isValidEndpoint')) {

      const pattern = new RegExp('^((\\/))?' + // start /
        '(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // path

      const valid = pattern.test(values[fieldName].value);  //valid

      let hasDoubleSlashs = false
      if (values[fieldName].value) {
        for (let i = 0; i < values[fieldName].value.length - 1; i++) {
          if (values[fieldName].value[i] === '/' && values[fieldName].value[i + 1] === '/') {
            hasDoubleSlashs = true
          }
        }
      }


      if (values[fieldName].value && (!valid || hasDoubleSlashs)) {
        values[fieldName].error = true
        values[fieldName].errorMessage = 'Endpoint inválido.'
        setForm(values)
        return true
      }
    }
    return false
  }

  const resetFieldErrors = (fieldName) => {
    const values = { ...form }
    values[fieldName].error = false
    values[fieldName].errorMessage = ' '
    setForm(values)
  }

  const resetField = (fieldName) => {
    const values = { ...form }
    values[fieldName].error = false
    values[fieldName].errorMessage = ' '
    values[fieldName].value = ''
    setForm(values)
  }

  const checkAllFieldsErrors = () => {
    let hasErrors = false
    const values = { ...form }
    Object.keys(values).forEach((key) => {
      if (checkFieldErrors(key)) {
        hasErrors = true
      }
    })
    return hasErrors
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (checkAllFieldsErrors()) {
      return;
    }

    const newData = {
      key: form.key.value,
      value: form.value.value,
      type: form.type.value
    }
    const oldData = [...propsData];
    if (editMode) {
      oldData[index] = newData;
    } else {
      oldData.push(newData);
    }
    setPropsData(oldData);

    handleSetState(oldData);
    resetAllFields();
  }

  const deleteItem = (index) => {
    const oldData = [...propsData];
    oldData.splice(index, 1)
    setPropsData(oldData);
    handleSetState(oldData);
  }

  const editItem = (index) => {
    setEditMode(true)
    setIndex(index)
    populateForm(propsData[index])
  }


  return (
    <Grid container id={'tenant-params-modal'}>
      <Grid className='item-row' container >
        <FormControl
          sx={{ m: 3 }}
          component="fieldset"
          error={form.key.error}
          variant="standard"
          fullWidth
        >
          <FormLabel component="legend">Chave *</FormLabel>

          <OutlinedInput

            margin="dense"
            value={form.key.value}
            className="inputField"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Chave"

            autoComplete="off"
            type="text"
            onChange={(e) => handleChangeValue(e, 'key')}
            onBlur={(e) => validadeStringField(e, 'key')}
            onFocus={() => resetFieldErrors('key')}

          />
          <FormHelperText>{form.key.errorMessage}</FormHelperText>
        </FormControl>

      </Grid>
      <Grid className='item-row' container >
        <FormControl
          sx={{ m: 3 }}
          component="fieldset"
          error={form.value.error}
          variant="standard"
          fullWidth
        >
          <FormLabel component="legend">Valor *</FormLabel>

          <OutlinedInput

            margin="dense"
            value={form.value.value}
            className="inputField"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Valor"
            autoComplete="off"
            type="text"
            multiline
            maxRows={4}
            minRows={4}
            onChange={(e) => handleChangeValue(e, 'value')}
            onBlur={(e) => validadeStringField(e, 'value')}
            onFocus={() => resetFieldErrors('value')}

          />
          <FormHelperText>{form.value.errorMessage}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid direction='column' container >
        <Grid item xs={12} class="url-fields">
          <FormControl
            sx={{ m: 3 }}
            component="fieldset"
            error={form.type.error}
            variant="standard"
            fullWidth
          >
            <FormLabel component="legend">Tipo *</FormLabel>

            <Select
              margin="dense"
              value={form.type.value}
              className="inputField"
              size="small"
              variant="outlined"
              displayEmpty
              onChange={(e) => handleChangeValue(e, 'type')}
              onBlur={(e) => validadeStringField(e, 'type')}
              onFocus={() => resetFieldErrors('type')}
            >
              <MenuItem value={''}>
                <em>Selecione um tipo</em>
              </MenuItem>
              <MenuItem value={'header'}>Parâmetro por cabeçalho</MenuItem>
              <MenuItem value={'param'}>Parâmetro por querystring</MenuItem>
            </Select>

            <FormHelperText>{form.type.errorMessage}</FormHelperText>
          </FormControl>


        </Grid>
        <Grid item xs={12} class="save-button-container">
          <Button
            icon="none"
            textAlign="center"
            type={'full-solid'}
            height="40px"
            onClick={handleSave}
          >
            {editMode ? 'Editar' : 'Adicionar'}
          </Button>
        </Grid>


      </Grid>
      <TenantTableModal data={propsData} handleDelete={deleteItem} handleEdit={editItem} />

    </Grid>
  )
}