import React from 'react';
import './DreamSimulator.scss';
import CurrencyInput from 'react-currency-input';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Typography, Grid, Hidden, Select, MenuItem, FormControl, CircularProgress, Tooltip, withStyles, Paper, Box, Dialog, DialogContent, IconButton, Divider } from '@material-ui/core';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import IconeSimulador from '../../../../src/assets/image/icone-simulador-sonhos.png';


const Globals = require("../../../Globals.json");

class DreamSimulator extends React.Component {

  state = {
    sonho: 0,
    custo: 0,
    guardado: 0,
    falta: 0,
    guardarMensal: 0,
    rendimentoMensal: 0,
    prazo: 0,
    custoSonho: 0,
    tempo: 0,
    showResult: 0,

  }

  autoCompleteFalta = () => {
    this.setState({
      falta: this.state.custo - this.state.guardado
    });
  };

  handleValueChange = (event, maskedvalue, floatvalue) => {
    this.setState({
      falta: floatvalue
    })
  };

  formatNum = n => {
    const s = (n + '').replace(/[^0-9.]/g, '');
    const ret = s.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    return ret;
  };

  numberToCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-br',
      { style: 'currency', currency: 'BRL' })
  }

  isPositive = (value) => {
    const stringValue = value.toString()
    if (stringValue[0] === '-') {
      return false
    } else {
      return true
    }
  }

  calculate = () => {

    const custo = this.state.custo;
    const guardado = this.state.guardado;
    const guardarMensal = this.state.guardarMensal;
    const rendimentoMensal = this.state.rendimentoMensal
    const prazo = this.state.prazo

    const final = parseFloat(custo);
    let acum = parseFloat(guardado);
    const mensal = guardarMensal;
    const taxaRendimento = rendimentoMensal;


    if (isNaN(parseFloat(custo)) || parseFloat(custo) === 0) {
      alert('Por favor preencha corretamente o custo do seu sonho');
      return;
    }

    if (isNaN(parseFloat(guardado))) {
      alert('Por favor preencha corretamente o valor que você tem guardado');
      return;
    }

    if (isNaN(parseFloat(guardarMensal))) {
      alert(
        'Por favor preencha corretamente o valor que você consegue guardar mensalmente',
      );
      return;
    }

    if (
      isNaN(parseFloat(rendimentoMensal)) ||
      parseFloat(rendimentoMensal) === 0
    ) {
      alert(
        'Por favor preencha corretamente o rendimento mensal a ser considerado',
      );
      return;
    }

    if (isNaN(parseFloat(prazo)) || parseFloat(prazo) === 0) {
      alert('Por favor preencha corretamente o prazo');
      return;
    }

    this.setState({
      custoSonho: final,
    })

    let meses = 0;

    if (mensal > 0) {
      while (
        !isNaN(final) &&
        parseFloat(acum) < parseFloat(final) &&
        meses < 500
      ) {
        meses++;
        acum *= 1 + parseFloat(taxaRendimento) / 100;
        acum += parseFloat(mensal);
      }

      this.setState({
        tempo: meses,
      })

      if (meses <= parseFloat(prazo)) {
        this.setState({
          showResult: 1,
        })
      } else {
        this.setState({
          showResult: 2,
        })
      }
    }
  };



  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="tool_finances_breadCrumb">
          <Link color="inherit" to={Globals.paths.tools} className="tool_finances_breadCrumbLink">
            Ferramentas financeiras
          </Link>
          <Link color="inherit" to={Globals.paths.dreamSimulator} className="tool_finances_breadCrumbLink">
            Simulador de Sonhos
          </Link>
        </Breadcrumbs>
        <Helmet>
          <title>Ferramentas | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} sm={8} md={9} className="classContainertools">

            <Grid container className="toolsItem">
              <Box item xs={12} className="toolsTitle" >
                <h1>Simulador de sonhos</h1>
              </Box>


              <Box sx={{ paddingBottom: "16px" }} component="section" item className="info" xs={12}>

                <h2>Saiba quanto guardar por mês para realizar seus projetos de vida  </h2>

                <p>Tudo começa com um sonho! E, para tirá-lo do papel, é preciso ter os pés bem ficados no chão.
                  Isso é feito com um bom planejamento. Nosso simulador te ajuda a dar o primeiro passo. Com ele,
                  você vai entender quanto precisa guardar por mês para realizar seu projeto em determinado tempo.</p>

                <p>Vamos lá? Preencha os campos abaixo. Comece dando nome ao seu sonho – isso é importante porque te
                  ajuda a ter foco no que importa para você na hora de usar seu dinheiro. Completando as informações,
                  você irá descobrir quanto precisa poupar e entender se precisa rever o prazo de seu projeto. Defina um valor que você realmente consegue guardar todo mês,
                  mesmo que seja pouco, e coloque o dinheiro para render em uma aplicação. Há opções de investimentos para todos os bolsos!</p>

                <p>Finalizando o preenchimento, clique em “Ver resultado” e pronto! Se desejar, refaça as contas e reveja seu planejamento.</p>
              </Box>

              <Grid container justifyContent="center" className="form-container">
                <Grid
                  container
                  justifyContent="center"
                  direction='column'
                  item
                  className="dream_simulator_container"
                  xs={12}
                >

                  <Box
                    container
                    xs={12}
                    sx={{ textAlign: "center", paddingTop: "24px" }}
                  >
                    <img src={IconeSimulador} className="dream_simulator_img" />
                    <h2>Vamos calcular seu sonho?</h2>
                  </Box>

                  <Grid item
                    direction="column"
                    justifyContent="center"
                    alignItems="center">

                    <div className="form_input">
                      <label>Seu sonho</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escreva seu sonho aqui"
                        onChange={e => this.setState({
                          sonho: e.target.value,
                        })}
                      />
                    </div>

                    <div className="form_input">
                      <label>Quanto custa?</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        precision={2}
                        className="form-control"
                        placeholder="Valor"
                        value={this.state.custo}
                        onChangeEvent={(e, mv, fv) => this.setState({
                          custo: fv,
                        })}
                        onBlur={e => this.autoCompleteFalta()}
                      />
                    </div>

                    <div className="form_input">
                      <label>Quanto você já tem guardado?</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        placeholder="Valor"
                        value={this.state.guardado}
                        onChangeEvent={(e, mv, fv) => this.setState({ guardado: fv })}
                        onBlur={e => this.autoCompleteFalta()}
                      />
                    </div>

                    <div className="form_input">
                      <label>Quanto falta juntar?</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        disabled
                        decimalSeparator=","
                        thousandSeparator=","
                        precision={2}
                        className="form-control"
                        placeholder="Valor"
                        value={this.state.falta}
                      />
                    </div>

                    <div className="form_input">
                      <label>Quanto posso guardar por mês?</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        precision={2}
                        className="form-control"
                        placeholder="Valor"
                        value={this.state.guardarMensal}
                        onChangeEvent={(e, mv, fv) => this.setState({ guardarMensal: fv })}
                      />
                    </div>

                    <div className="form_input">
                      <label>Taxa de rendimento de sua aplicação atual?</label>
                      <CurrencyInput
                        suffix="%"
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        placeholder="%"
                        className="form-control"
                        value={this.state.rendimentoMensal}
                        onChangeEvent={(e, mv, fv) => this.setState({  rendimentoMensal: this.formatNum(fv) })}
                      />
                    </div>

                    <div className="form_input">
                      <label>Prazo para realizar meu sonho</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meses"
                        value={this.state.prazo}
                        onChange={e => this.setState({ prazo: this.formatNum(e.target.value) })}
                      />
                    </div>

                    <div className="form_input">
                      <button
                        className="btn btn-default btn-primary btn-block"
                        onClick={() => this.calculate()}
                      >
                        Ver resultado
                      </button>
                    </div>
                  </Grid>

                </Grid>

                {!!this.state.showResult && (<Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  direction='row'
                  className="result-dream"
                >
                  {this.state.showResult === 1 && (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                    >
                      <Box component='p'>Parabéns. Você está no caminho certo.</Box>

                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-evenly"
                      >
                        <Box py={2.5} px={5} mb={2} sx={{ width: '250px', background: '#ffff', boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 10px' }} component="div">
                          <p>
                            O seu sonho vale<br></br> <span>R$ {this.numberToCurrency(this.state.custoSonho)}</span> <br></br> + correções.
                          </p>
                        </Box>
                        <Box py={2.5} px={5} mb={2} sx={{ width: '250px', background: '#ffff', boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 10px' }} component="div">
                          <p>
                            É possível realizá-lo em <span>{this.state.tempo} meses</span>
                          </p>
                        </Box>
                      </Grid>

                    </Grid>
                  )}
                  {this.state.showResult === 2 && (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center">
                      <Box component="p">
                        Opa! Você precisa rever suas economias para chegar lá!
                      </Box>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-evenly"
                      >
                        <Box py={2.5} px={5} mb={2} sx={{ width: '250px', background: '#ffff', boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 10px' }} component="div">
                          <p>
                            O seu sonho vale<br></br> <span>{this.numberToCurrency(this.state.custoSonho)}</span> <br></br> + correções.
                          </p>
                        </Box>
                        <Box py={2.5} px={5} mb={2} sx={{ width: '250px', background: '#ffff', boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 10px' }} component="div">
                          <p>
                            Você levaria <span>{this.state.tempo} meses</span> para realizar seu sonho.
                          </p>
                        </Box>
                      </Grid>

                    </Grid>
                  )}
                  <Box component="p">
                    Agora que você já sabe quanto guardar para alcançar o seu
                    sonho. É só começar!
                  </Box>
                </Grid>)}

              </Grid>

            </Grid>
          </Grid >

          <Hidden xsDown>
            <Grid item sm={4} md={3}>

              <ToolbarItem tooltip="
               O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
               e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
               vantagens exclusivas para os usuários."  title="Seu Nível" color="#05CCAE">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>
            </Grid>
          </Hidden>

        </Grid>
      </>

    )
  }

}

export default watch((DreamSimulator), { user: '' });
