import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";
import { ReactComponent as MiniLogo } from '../../../../assets/logo/mini-logo.svg'

export const PublicTrail = withTheme(styled('section')`  
  padding-top: 90px;
  padding-bottom: 90px;
  background: radial-gradient(50% 50% at 33% 43%, #fff 44.27%, #e5e5e5 100%);

  .inner-wrapper {
    margin: auto;
    display: flex;
    max-width: 1440px;
    align-items: center;
    
    .featured {
      flex: 1;
      position: relative;

      &__image {
        width: 100%;
      }

      &__shield {
        z-index: 0;
        position: absolute;

        &--super-small {          
          top: 388px;
          left: 559px;
          width: 80px;
          filter: blur(5px);
        }

        &--small {          
          top: 578px;
          left: -42px;
          width: 117px;
        }

        &--regular {          
          top: 10px;
          left: 523px;
          width: 149px;
        }

        &--big {
          width: 300px;
          top: 725px;
          left: 1106px;
        }
      }

      @media(max-width: 1200px) {
        display: none;
      }
    }

    .main-text {      
      flex: 1;
      z-index: 1;
      padding-right: 53px;

      & h2 {
        margin: 0;
        color: #005E50;
        font-size: 36px;
        line-height: 40px;
        font-family: 'Caros Soft Light';
        margin-bottom: 30px;

        & strong {
          font-family: 'Caros Soft ExtraBold';
        }

        @media(max-width: 1340px) {
          padding-top: 30px;
        }

        @media(max-width: 1200px) {
          padding-top: 0;
        }

        @media(max-width: 600px) {
          font-size: 28px;
        }
      }

      & p {        
        margin: 0;
        color: #707070;
        font-size: 20px;
        font-family: 'Caros Soft Regular';
        margin-bottom: 30px;

        & strong {
          font-family: 'Caros Soft ExtraBold';
        }

        @media(max-width: 600px) {
          font-size: 18px;
        }
      }

      & .video-container {
        display: flex;
        flex-direction: row;        
        
        & .thumb {
          flex: 9;
          display: flex;
          justify-content: center;
          cursor: pointer;

          & img {
            width: 100%;
          }
        }

        & .stamps {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;
          
          & img {
            width: 90px;

            @media(max-width: 1440px) {
              width: 70px;
            }

            @media(max-width: 1200px) {
              width: 120px;
            }

            @media(max-width: 940px) {
              width: 90px;
            }

            @media(max-width: 760px) {
              width: 70px;
            }
          }

          @media(max-width: 1440px) {
            padding-left: 0;
            padding-top: 10px;
            flex-direction: row;
          }

          @media(max-width: 1200px) {
            flex-direction: column;
            justify-content: space-between;
            padding-left: 10px;
            padding-top: 0;
          }

          @media(max-width: 600px) {
            padding-left: 0;
            padding-top: 10px;
            flex-direction: row;
          }
        }

        @media(max-width: 1440px) {
          flex-direction: column;
        }

        @media(max-width: 1200px) {
          flex-direction: row;
        }

        @media(max-width: 600px) {
          flex-direction: column;
        }
      }

      @media(max-width: 1200px) {
        padding: 90px 53px;
        padding-top: 55px !important;
      }

      @media(max-width: 600px) {
        padding: 40px;
        padding-bottom: 20px;
      }
    }
  }

  @media(max-width: 1200px) {
    padding-bottom: 0;
  }
`);

export const Logo = withTheme(styled(MiniLogo)`
  position: absolute;

  ${({ variant }) => variant === 'left'
    ? css`      
      top: 723px;
      left: 20px;
      width: 68px;
      z-index: 0;

      @media(max-width: 1385px) {
        top: 693px;
        left: 20px;
      }

      @media(max-width: 1315px) {
        top: 490px;
        left: 20px;
      }

      @media(max-width: 1215px) {        
        top: 470px;
        left: 0px;
      }

      @media(max-width: 1200px) {
        display: none;
      }
    `
    : css`
      top: 620px;
      left: 600px;
      width: 65px;
      z-index: 0;

      @media(max-width: 1385px) {
        top: 590px;
        left: 580px;
      }

      @media(max-width: 1315px) {
        top: 560px;
        left: 550px;
      }

      @media(max-width: 1215px) {
        top: 505px;
        left: 500px;
      }

      @media(max-width: 1200px) {
        display: none;
      }
    `
  }
`);