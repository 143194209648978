/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as Styled from './Footer.styles';

const Globals = require('../../../../Globals.json');

function Footer({ openRegisterDialog }) {
  const currentYear = new Date().getFullYear();

  return (
    <Styled.Footer>
      <div className="inner-wrapper">
        <div className="rights-reserved">
          <Styled.LogoIcon />
          <Styled.LogoBacenIcon />
          <p className="rights-reserved__text">Meu Bolso em Dia - Todos os Direitos Reservados {currentYear}</p>
        </div>
        <nav>
          <a href="https://portal.febraban.org.br/" target="_blank" rel="noopener noreferrer">
            Conheça a Febraban
          </a>
          <a target="_blank" href="https://meubolsoemdia.com.br/">Meu Bolso em Dia</a>
          <a href="#" onClick={openRegisterDialog}>
            Cadastre-se
          </a>
          <a
            href={Globals.paths.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
          </a>
        </nav>
        <div className="social-media">
          <a
            href="https://www.instagram.com/meubolsoemdia/"
            className="social-media__icon social-media__icon--instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.facebook.com/meubolsoemdia"
            className="social-media__icon social-media__icon--facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://twitter.com/meubolsoemdia"
            className="social-media__icon social-media__icon--twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.youtube.com/user/meubolsoemdia"
            className="social-media__icon social-media__icon--youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        </div>
        <div className="rights-reserved--mobile">
          <p className="rights-reserved--mobile__text">Meu Bolso em Dia - Todos os Direitos Reservados {currentYear}</p>
        </div>
      </div>
    </Styled.Footer>
  );
}

export default Footer;
