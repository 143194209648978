import api from "./api";
import WebSocket from "./websocket";

const Globals = require("../Globals.json");

export const TOKEN_KEY = "@fbb-Token";

export const isAuthenticated = () => localStorage && localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage ? localStorage.getItem(TOKEN_KEY) : null;

export const login = token => {
  if (localStorage) {
    localStorage.setItem(TOKEN_KEY, token);
  }
};

export const logout = () => {
  if (localStorage) {
    localStorage.removeItem(TOKEN_KEY);
  }

  WebSocket.disconnect();
};

export const canAccessAdminArea = async () => {  
  
  const roles = ['Admin','Visualizador de Dashboard'];

  try {
    const res = await api.get(Globals.api.selfInfo);
    const userRoles = res.data.Roles;
    
    if (userRoles) {
      return !!userRoles.find(r => roles.indexOf(r.name ) > -1);
    }

    return false;
  }
  catch (error) {
    console.log(error);
  }
}

export const isAdmin = async () => {
  const permission = 'admin.access';

  try {
    const res = await api.get(Globals.api.selfInfo);
    const userRoles = res.data.Roles;
    if (userRoles) {
      return !!userRoles.find(r => r.Permissions.find(p => p.name === permission) != null);
    }

    return false;
  }
  catch (error) {
    console.log(error);
  }
}

export const getStatus = async () => {
  try {
    const res = await api.get(Globals.api.selfInfo);
    if (res.data && res.data.type_users_status_id) {
      return res.data.type_users_status_id;
    }
  } catch (error) {
    console.log(error);
  }

}

export const validateOrRemoveToken = async () => {
  try {
    const resp = await api.post(Globals.api.validateToken);
    const response = resp.data;
    if (!response.error) {
      return true;
    }
  } catch (error) {
    if (error.response && error.response['status']) {
      switch (error.response['status']) {
        case 401:
        case 403:
          logout();
          return false;
          break;
      }
    }

  }
}