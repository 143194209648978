import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const Journey = withTheme(styled('section')`
  background: #fff;

  .header {
    background-color: #05CCAE;
    padding: 34px;
    width: 100%;
    text-align: center;
    
    * {
      margin: 0;
    }
    
    h1 {
      color: #202020;
      font-family: 'elza';
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 5px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 6vw;
        line-height: initial;
      }
    }

    h2 {
      font-family: 'elza';
      color: #202020;
      font-size: 16px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 4vw;
      }
    }
  }

  .bottom-space {
    height: 150px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,229,229,1) 100%);

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      height: 0;
    }
  }
`);
