export function injectMetaPixelScript() {
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    const scriptCode = `
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '310753321726655');
        fbq('track', 'PageView');
    `;

    const noscriptCode = `
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=310753321726655&ev=PageView&noscript=1" />
    `;

    let hasTag = false;
    var scriptTags = document.getElementsByTagName("noscript");

    for (var i = 0; i < scriptTags.length; i++) {
        if (scriptTags[i].innerHTML.includes('https://www.facebook.com/tr?id=310753321726655&ev=PageView&noscript=1')) {
            hasTag = true;
        }
    }

    if (!hasTag) {
        script.innerHTML = scriptCode;
        document.getElementsByTagName('head')[0].appendChild(script);

        noscript.innerHTML = noscriptCode;
        document.getElementsByTagName('head')[0].appendChild(noscript);
    }
}

export function injectTiktokPixelScript() {
    const script = document.createElement('script');

    const scriptCode = `
        !function (w, d, t) {
            w.TiktokAnalyticsObject = t; var ttq = w[t] = w[t] || []; ttq.methods = ["page", "track", "identify", "instances", "debug", "on",
                "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"], ttq.setAndDefer = function (t, e) {
                    t[e] = function () {
                        t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                    }
                }; for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq,
                    ttq.methods[i]); ttq.instance = function (t) {
                        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]); return e
                    }, ttq.load = function (e, n) {
                        var i = "https://analytics.tiktok.com/i18n/pixel/events.js"; ttq._i = ttq._i || {},
                            ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {},
                            ttq._o[e] = n || {}; var o = document.createElement("script"); o.type = "text/javascript", o.async = !0,
                                o.src = i + "?sdkid=" + e + "&lib=" + t; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(o, a)
                    };

            ttq.load('CL98R4BC77U1T02D8KKG');
            ttq.page();
        }(window, document, 'ttq');
    `;

    let hasTag = false;
    var scriptTags = document.getElementsByTagName("script");

    for (var i = 0; i < scriptTags.length; i++) {
        if (scriptTags[i].innerHTML.includes("ttq.load('CL98R4BC77U1T02D8KKG')")) {
            hasTag = true;
        }
    }

    if (!hasTag) {
        script.innerHTML = scriptCode;
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}

export function injectTwitterTrackingScript() {
    const script = document.createElement('script');

    const scriptCode = `
        !function (e, t, n, s, u, a) {
            e.twq || (s = e.twq = function () {
                s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
            }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js',
                a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a))
        }(window, document, 'script');
        twq('config', 'ocgqs');    
    `;

    let hasTag = false;
    var scriptTags = document.getElementsByTagName("script");

    for (var i = 0; i < scriptTags.length; i++) {
        if (scriptTags[i].innerHTML.includes("twq('config', 'ocgqs')")) {
            hasTag = true;
        }
    }

    if (!hasTag) {
        script.innerHTML = scriptCode;
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}