import React from 'react';
import './worksheets.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Grid, Hidden, Divider, Box } from '@material-ui/core';
import api from '../../../services/api';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

const Globals = require("../../../Globals.json");

class Worksheets extends React.Component {

  state = {
    workSheets: [],
    lists: []
  };

  getClass(index) {
    const interval = 4;
    const msc = index - (Math.floor(index / interval)) * interval; //procurado o maximo subtrator comum 
    return msc + 1
  };

  async loadData() {
    const postData = {
      type_module_id: 39,
    };

    try {
      const response = await api.post(Globals.api.getModules, postData);

      const { data } = response;

      const workSheets = data.filter((workSheet => !workSheet.data.type));
      const lists = data.filter((list => list.data.type));

      this.setState({
        workSheets: workSheets,
        lists: lists
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="tool_finances_breadCrumb">
          <Link color="inherit" to={Globals.paths.tools} className="tool_finances_breadCrumbLink">
            Ferramentas financeiras
          </Link>
          <Link color="inherit" to={Globals.paths.worksheets} className="tool_finances_breadCrumbLink">
            Planilhas e listinhas grátis
          </Link>
        </Breadcrumbs>
        <Helmet>
          <title>Ferramentas | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} sm={8} md={9} className="classContainertools">

            <Grid container className="toolsItem">
              <Box item xs={12} className="toolsTitle" >
                <h1>Planilhas e Listinhas Grátis</h1>
              </Box>


              <Box sx={{ paddingBottom: "16px" }} component="section" item className="info" xs={12}>

                <h2>Planilhas de orçamento</h2>

                <p>Com as planilhas do Meu Bolso em Dia você organiza as suas finanças de um jeito rápido e sem complicação. As planilhas fazem
                  todos os cálculos para você! Basta inserir as entradas de dinheiro e as despesas para ter uma fotografia de como andam o seu orçamento.</p>

                <p>Você pode preparar a planilha anual, com a previsão da renda e dos pagamentos a serem feitos e ir apenas ajustando os valores efetivamente
                  pagos a cada mês. E, assim, se planejar para realizar seus sonhos e projetos de vida.</p>

                <p><b>Vamos lá? Escolha a planilha que mais se adequa à sua realidade e baixe agora.</b></p>
              </Box>

              <Grid container justifyContent='space-between' className="form-container">
                {this.state.workSheets.map((item, index) => (
                  <Box component="section" className='worksheet_item'>
                    <div>
                      <Box component="div" className={`worksheet_item_bg_${this.getClass(index)}`}>
                        <img src={item.thumbnail} />
                      </Box>

                      <Box className='worksheet_item_title'>
                        <strong>
                          {item.name}
                        </strong>
                      </Box>
                      <Box className='worksheet_item_describe'>
                        <p>
                          {item.description}
                        </p>
                      </Box>
                    </div>
                    <a href={item.data.fileUrl} className='worksheet_item_button'>
                      Download
                    </a>
                  </Box>
                ))}
              </Grid >

              <Divider />

              <Box sx={{ paddingBottom: "16px" }} component="section" item className="info" xs={12}>

                <h2>Listinha de Compras</h2>

                <p>Use nossas listinhas para organizar suas compras de supermercado, feira & sacolão e material escolar. Anotando as quantidades necessárias e os preços de cada item você evita surpresas na hora de passar no caixa – e ainda planeja melhor os gastos.
                  Aproveite também a lista de revisão do carro para programar uma boa manutenção do veículo e evitar gastos maiores no futuro.</p>

              </Box>

              <Grid container justifyContent='space-between' className="form-container">
                {this.state.lists.map((item, index) => (
                  <Box component="section" className='worksheet_item'>
                    <div>
                      <Box component="div" className={`worksheet_item_bg_${this.getClass(index)}`}>
                        <img src={item.thumbnail} />
                      </Box>

                      <Box className='worksheet_item_title'>
                        <strong>
                          {item.name}
                        </strong>
                      </Box>
                      <Box className='worksheet_item_describe'>
                        <p>
                          {item.description}
                        </p>
                      </Box>
                    </div>
                    <a href={item.data.fileUrl} className='worksheet_item_button'>
                      Download
                    </a>
                  </Box>
                ))}
              </Grid>

            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={4} md={3}>

              <ToolbarItem tooltip="
               O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
               e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
               vantagens exclusivas para os usuários."  title="Seu Nível" color="#05CCAE">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>

            </Grid>
          </Hidden>
        </Grid>
      </>
    )
  }
}

export default watch((Worksheets), { user: '' });

