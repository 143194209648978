import React from 'react';
import * as Styled from './PublicTrail.styles';

function PublicTrail({ openCourseDialog, logHomeAction }) {
  return (
    <Styled.PublicTrail>
      <div className="inner-wrapper">
        <figure className="featured">
          <img className="featured__image" src="./images/public-trail/featured.png" alt="Inicie sua jornada" />
        </figure>
        <div className="main-text">
          <h2><strong>Simples e descomplicado:</strong> seu primeiro curso de educação financeira começa aqui.</h2>
          <p>Com <strong>um clique</strong> você já sai aprendendo: o curso <strong>Mapeie sua vida financeira</strong> está disponível para você começar agora e sem enrolação sua jornada de educação financeira.</p>
          <div className="video-container">
            <span onClick={logHomeAction}>
              <div className="thumb" onClick={openCourseDialog}>
                <img src="./images/public-trail/video-thumb.png" alt="Curso: Mapeie sua vida financeira" />
              </div>
            </span>
            <div className="stamps">
              <div>
                <img src="./images/public-trail/stamp-01.svg" alt="Selo: Produzido pelos maiores educadores financeiros do país" />
              </div>
              <div>
                <img src="./images/public-trail/stamp-02.svg" alt="Selo: Aprovado pelo BACEN" />
              </div>
              <div>
                <img src="./images/public-trail/stamp-03.svg" alt="Selo: Gratuíto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.PublicTrail>
  );
}

export default PublicTrail;