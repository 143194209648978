import { Button, Grid } from '@material-ui/core';
import React from 'react'
import GoogleLogin from 'react-google-login';
import SocialButton from './SocialButton';
import { login } from '../../../services/auth';
import PropTypes from 'prop-types';
import FacebookIcon from '../../../assets/icon/facebook.svg';
import GoogleIcon from '../../../assets/icon/google.svg';
import api from '../../../services/api';
import './socialContainer.scss';


class SocialContainer extends React.Component {

    REACT_APP_FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID;
    REACT_APP_GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;

    handleSocialLogin = async (data) => {

        if (this.props.showLoading) {
            this.props.showLoading();
        }

        try {
            const response = await api.post('api/v1/auth/loginOrRegister/facebook', data);            
            login(response.data.data.token);

            if (this.props.onLoginSuccess) {
                this.props.onLoginSuccess();
            }

            if (this.props.hideLoading) {
                this.props.hideLoading();
            }

        } catch (error) {
            if (this.props.hideLoading) {
                this.props.hideLoading();
            }

            if (this.props.onFailure) {
                this.props.onFailure(error);
            }
        }
    }

    handleSocialLoginFailure = (error) => {
        if (this.props.onFailure) {
            this.props.onFailure(error);
        }
    }

    handleGoogleSocialLogin = async (data) => {
        if (this.props.showLoading) {
            this.props.showLoading();
        }
        try {            
            const response = await api.post('api/v1/auth/loginOrRegister/google', data);
            login(response.data.data.token);

            if (this.props.onLoginSuccess) {
                this.props.onLoginSuccess();
            }

            if (this.props.hideLoading) {
                this.props.hideLoading();
            }
        } catch (error) {
            if (this.props.hideLoading) {
                this.props.hideLoading();
            }

            if (this.props.onFailure) {
                this.props.onFailure(error);
            }
        }
    }

    render() {
        const { children, triggerLogin, ...props } = this.props

        return (
            <div className="social-container-holder">
                <Grid item className="socialContainer">
                    <GoogleLogin
                        clientId={this.REACT_APP_GOOGLE_ID}
                        onSuccess={this.handleGoogleSocialLogin}
                        onFailure={this.handleSocialLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <Button onClick={renderProps.onClick} className="socialButton" disabled={renderProps.disabled}>
                                <img src={GoogleIcon} className="socialIcon" />
                                Continue com Google
                            </Button>
                        )}
                    />
                </Grid>
                <Grid item className="socialContainer">
                    <SocialButton
                        provider='facebook'
                        appId={this.REACT_APP_FACEBOOK_ID}
                        onLoginSuccess={this.handleSocialLogin}
                        onLoginFailure={this.handleSocialLoginFailure}
                        className="socialButton"
                    >
                        <img src={FacebookIcon} className="socialIcon" />
                        Continue com Facebook
                    </SocialButton>
                </Grid>

                <Grid item className="explanation" style={{ textAlign: 'center' }}>
                    ou preencha os dados abaixo:
                </Grid>
            </div>
        );
    }
}

SocialContainer.propTypes = {
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
};

export default SocialContainer;