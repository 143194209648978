import React from 'react';
import MainText from '../MainText';
import * as Styled from './Initiative.styles';

function Initiative({ openRegisterDialog }) {
  return <Styled.Initiative>
    <div className="inner-wrapper">
      <img className="featured__shield featured__shield--big" src="./images/shield.png" alt="" />
      <img className="featured__shield featured__shield--medium" src="./images/shield.png" alt="" />
      <img className="featured__shield featured__shield--small" src="./images/shield-dark.png" alt="" />
      <div>
        <MainText style={{ textAlign: 'center' }}>
          <h2>Uma iniciativa</h2>
        </MainText>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <img src="./images/initiative/febraban.png" alt="Abracs" className="image-01" />
        </div>
        <div className="grid-item">
          <img src="./images/initiative/banco-central.png" alt="Banco de Brasília" className="image-02" />
        </div>
      </div>
    </div>
  </Styled.Initiative>;
}

export default Initiative;