
import React from 'react';
import { Grid, Collapse, IconButton, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale';

import './dataDownload.scss';

import NavigationAppBar from '../AppBar/NavigationAppBar';
import { withRouter } from 'react-router-dom';
import Button from '../../button/Button';

import api from "../../../../services/api";
const Globals = require("../../../../Globals.json");
class DataDownload extends React.Component {

  state = {
    selectedDate: new Date(),
    error: false,
    errorMessage: '',
    loading: false
  }

  formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd')
  }

  getFile = async () => {
    this.setState({
      loading: true,
    });
    const date = this.formatDate(this.state.selectedDate)
    try {
      const res = await api.post(Globals.api.requestFileLinkForExtractionData, { data_relatorio: date })
      console.log('api resssss:', res?.data)
      if (res?.data?.success === true) {
        console.log('entrou: ', `${process.env.REACT_APP_API}${Globals.api.downloadExtractionData.replace(':link_part', res.data.data.link_part)}`)

        window.location.href = `${process.env.REACT_APP_API}${Globals.api.downloadExtractionData.replace(':link_part', res.data.data.link_part)}`
      }
    }
    catch (err) {
      this.setState({
        error: true,
        errorMessage: err?.response?.data?.message
      });
      setTimeout(() => {
        this.setState({
          error: false,
          errorMessage: ''
        });
      }, 10000);
    }
    finally {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        <NavigationAppBar text={"Cadastrar missão"} />
        <Grid className="contentainer extraction" >
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Extração de dados - comunicação</span>
          </div>
          <Grid container direction="column" className="data-extraction-container"  >
            <Grid className="alert-bar">
              <Collapse in={this.state.error} className="collapse-error">
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ error: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >{this.state.errorMessage}</Alert>
              </Collapse>
            </Grid>
            <Grid item xs={8} className="date-picker" >
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  label="informe a data:"
                  margin="normal"
                  format="dd/MM/yyyy"
                  okLabel="Selecionar"
                  cancelLabel="Cancelar"
                  inputVariant="outlined"
                  size="small"
                  // disabled={!this.state.edit}
                  value={this.state.selectedDate}
                  onChange={(newValue) => this.setState({ selectedDate: newValue })}
                  KeyboardButtonProps={{
                    'aria-label': 'Alterar data',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8}>
              <Button
                icon="none"
                textAlign="center"
                type={'darkBlue'}
                height="40px"
                onClick={this.getFile}
                disabled={this.state.loading}
              >
                {this.state.loading ? (<CircularProgress />) : ('Baixar')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(DataDownload);
