import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function TabPanel(props) {
  const { children, value, index, justify = "center", cleared, alignItems = "center", padding, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && cleared ? (
        <>{children}</>
      ) : (
        <Grid item container justify={justify} alignItems={alignItems} style={{ padding: padding || '30px' }}>
          {children}
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  justify: PropTypes.string,
  alignItems: PropTypes.string
};

export default TabPanel;