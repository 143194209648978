import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const PrivacyPolicy = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 33% 43%, #fff 44.27%, #e5e5e5 100%);
  padding-top: 130px;
  
  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 120px;
  }

  .inner-wrapper-container{
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .inner-wrapper {
      max-width: 1440px;
      margin: auto;
      gap: 10px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;

      @media(min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-left: 53px;
        padding-right: 53px;
      }

      .font-weight-bold{
        font-weight: 700;
      }

      .font-weight-medium{
        font-weight: 500;
      }

      .font-weight-regular{
        font-weight: 400;
      }

      .font-size-xmd{
        font-size: 16px;
      }

      .center-text{
        text-align: center;
      }

      .justify-text{
        text-align: justify;
      }

      .font-style-italic{
        font-style: italic;
      }

      .lista-termos{
        padding-inline-start: 19px;
        >li{
          padding-inline-start: 10px;
        }
        >.bloco-termos-interno{
          padding-left: 10px;
        }
      }

      .lista-termos-interno{
        padding-inline-start: 10px;
        >li{
          margin-block-start: 1em;
          margin-block-end: 1em;
          list-style-type: none;
          counter-increment: step-counter
        }
        >li::before {
          display: inline-flex
          content: '(' counter(step-counter, lower-roman) ')';
          width: 35px;
        }
      }

      .lista-termos-interno-ul{
        padding-inline-start: 19px;
        >li{
          margin-block-start: 1em;
          margin-block-end: 1em;
          list-style-type: disc;
        }
      }

      #quadro_resumo{
        border-spacing: 0px;
        border-collapse: collapse
        padding: 0px;

        >tr >td{
          border: 1px solid;
          padding: 0px;
          vertical-align: top;
          padding-left: 10px;
          padding-right: 10px
        }
      }

      .overflow-x-auto{
        overflow-x:auto;
      }
    }

  }

`);
