import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router';
import '../../../../backoffice.scss';
import './expensesMatrix.scss';
import Dialogs from '../../../../services/dialogs';


import api from "../../../../services/api";
import { Link } from 'react-router-dom';
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");

const ExpensesMatrixDetail = () => {
  const params = useParams();
  const history = useHistory();


  const [data, setData] = useState({});
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [expenseType, setExpenseType] = useState(''); //1 fixa 2 variavel
  const [statusToggle, setStatusToggle] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [editMode, setEditMode] = useState(false);


  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');
  const [errorDescription, setErrorDescription] = useState(false);
  const [helperTextDescription, setHelperTextDescription] = useState('');
  const [errorExpenseType, setErrorExpenseType] = useState(false);
  const [helperTextExpenseType, setHelperTextExpenseType] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [helperTextStatus, setHelperTextStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);


  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loadData = async (id) => {
    setIsLoading(true);
    try {
      await api.get(Globals.api.typeExpensesMatricesDetail.replace(':matrixId', id))
        .then((res) => {
          if (res.status === 200) {
            setData(res.data)
            setName(res.data.name)
            setDescription(res.data.description)
            setExpenseType(res.data.type_expenses_id)
            res.data.type_status_id === 1 ? setStatusToggle(true) : setStatusToggle(false)
            setId(res.data.id)
            setTotalItems(res.data.__meta__.total_items)
          } else {
            history.push(Globals.paths.backofficeEditExpensesMatrix.replace(':matrixId', 'new'))
          }
        })
    } catch (error) {
      history.goBack()
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (params.matrixId !== 'new') {
      loadData(params.matrixId)
      setEditMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioChange = (event) => {
    setExpenseType(parseInt(event.target.value, 10));
    setHelperTextExpenseType('');
    setErrorExpenseType(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setHelperTextName('');
    setErrorName(false);

  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setHelperTextDescription('');
    setErrorDescription(false);
  };

  const handleToggleStatus = () => {
    statusToggle ? setStatusToggle(false) : setStatusToggle(true);
  };

  const success = () => {
    Dialogs.show(
      <div>SUCESSO</div>,
      'Sua matriz de despesa foi salva com sucesso',
      [
        {
          label: "OK",
          onClick: () => history.push(Globals.paths.backofficeExpensesMatrix),
        },
      ]
    )
  }

  const saveData = async (postData) => {
    setIsLoading(true);
    try {
      if (postData.id === parseInt(params.matrixId, 10)) {
        await api.put(`${Globals.api.typeExpensesMatrices}/${postData.id}`, postData)
          .then((res) => {
            success()
          })
      } else {
        await api.post(Globals.api.typeExpensesMatrices, postData)
          .then((res) => {
            success()
          })
      }
    } catch (error) {
      setSeverity('error')
      setAlertMessage(typeof (error?.response?.data) === 'string' ? error.response.data : 'Não foi possível excluir a Matriz de despesa')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorStatus(false);
    setHelperTextStatus('')
    let error = false;
    const statusValue = statusToggle ? 1 : 2;
    const postData = {
      id: id,
      name: name,
      description: description,
      type_expense_id: expenseType,
      type_status_id: statusValue,
    }

    if (expenseType !== 1 && expenseType !== 2) {
      setHelperTextExpenseType('Selecione uma opção.');
      setErrorExpenseType(true);
      error = true;
    }

    if (!postData.name || postData.name.trim().length === 0) {
      setHelperTextName('Informe um nome');
      setErrorName(true);
      error = true;
    }
    // if (!postData.description || postData.description.length <= 0) {
    //   setHelperTextDescription('Informe uma descrição');
    //   setErrorDescription(true);
    //   error = true;
    // }

    if (!error) {
      saveData(postData)
    }

  }
  const handleBack = (event) => {
    event.preventDefault();
    if (editMode) {

      const statusValue = statusToggle ? 1 : 2;
      const postData = {
        id: id,
        name: name,
        description: description,
        type_expense_id: expenseType,
        type_status_id: statusValue,
      }

      const oldData = {
        id: data.id,
        name: data.name,
        description: data.description,
        type_expense_id: data.type_expense_id,
        type_status_id: data.type_status_id,
      }
      if (JSON.stringify(postData) !== JSON.stringify(oldData)) {
        Dialogs.show(
          <div>ALTERAÇÕES</div>,
          'Você fez alterações na sua Matriz de despesas, deseja descartar as alterações?',
          [

            {
              label: "CANCELAR"
            },
            {
              label: "DESCARTAR",
              onClick: () => history.push(Globals.paths.backofficeExpensesMatrix),
            },
          ]
        )
      } else {
        history.push(Globals.paths.backofficeExpensesMatrix)
      }

    } else {
      history.push(Globals.paths.backofficeExpensesMatrix)
    }
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">{editMode ? data.name : 'Nova Matriz de Despesas'}</span>
          </div>

          <form className="form-matrix">

            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorName}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Nome</FormLabel>


              <TextField
                value={name}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Nome"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => handleNameChange(e)}
              />
              <FormHelperText>{helperTextName}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorDescription}
              variant="standard"
              fullWidth
            >
              <FormLabel component="legend">Descrição</FormLabel>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxrows={4}
                value={description}
                className="inputField"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Descrição"
                autoComplete="off"
                type="text"
                onChange={(e) => handleDescriptionChange(e)}
              />
              <FormHelperText>{helperTextDescription}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              sx={{ m: 3 }}
              component="fieldset"
              error={errorStatus}
              variant="standard"
            >
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                row
                aria-label="quiz"
                name="quiz"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={statusToggle}
                      onChange={handleToggleStatus}
                    />
                  }
                  label={'Ativa'}
                />
              </RadioGroup>
              <FormHelperText>{helperTextStatus}</FormHelperText>

            </FormControl>


            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              error={errorExpenseType}
              variant="standard"
            >
              <FormLabel component="legend">Tipo de despesa</FormLabel>
              <RadioGroup
                row
                aria-label="quiz"
                name="quiz"
                value={expenseType}
                onChange={handleRadioChange}
              >
                <FormControlLabel value={1} control={<Radio />} label="Despesa fixa" />
                <FormControlLabel value={2} control={<Radio />} label="Despesa variável" />
              </RadioGroup>
              <FormHelperText>{helperTextExpenseType}</FormHelperText>

            </FormControl>
            <div className="button-right-container">

              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    icon="none"
                    textAlign="center"
                    height="40px"
                    onClick={handleBack}
                  >
                    {'voltar'}
                  </Button>
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    onClick={handleSubmit}
                  >
                    {'salvar'}
                  </Button>
                </>
              )}

            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default ExpensesMatrixDetail;
