import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './CollapsibleTable.scss'

export default function CollapsibleTable({ data }) {
  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowExpand = (index) => {
    let temp = {};
    let newExpandedRows = [];
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(index);

    if (isRowExpanded) {
      temp[index] = false;
    } else {
      temp[index] = true;
    }

    if (isRowExpanded) {
      newExpandedRows = currentExpandedRows.filter(i => i !== index);
    } else {
      newExpandedRows = currentExpandedRows.concat(index);
    }

    setExpandedRows(newExpandedRows);
  };

  const getSubItems = (subItems) => {
    let result = [];

    subItems.map((subItem, subIndex) => {
      result.push(
        <tr key={subIndex + 'subitem' + subItem.name} className='inner-table'>
          <td className='no-border-r'></td>
          <td className='no-border-l padding-left-16 text-left'>{subItem.name}</td>
          <td>{subItem.visitors}</td>
          <td>{subItem.newVisitors}</td>
          <td>{subItem.sessions}</td>
          <td>{subItem.pageViews}</td>
          <td>
            <div className='bounce-rate'>
              {subItem.bounceRate}
              < FiberManualRecordIcon style={{ fontSize: '15px', color: subItem.bounceRateColor }} />
            </div>
          </td>
          <td>{subItem.averageSessionDuration}</td>
        </tr>
      );
    });

    return result;
  };

  const getOuterTable = (item, index) => {
    let result = [];

    if (!expandedRows.includes(index)) {
      result.push(
        <>
          <td>{item.visitors}</td>
          <td>{item.newVisitors}</td>
          <td>{item.sessions}</td>
          <td>{item.pageViews}</td>
          <td >
            <div className='bounce-rate'>
              {item.bounceRate}
              <FiberManualRecordIcon style={{ fontSize: '15px', color: item.bounceRateColor }} />
            </div>
          </td>
          <td>{item.averageSessionDuration}</td>
        </>
      )
    } else {
      result.push(
        <>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </>
      )
    }

    return result;
  };

  return (
    <>
      {!data || data.length < 1 ? <div className="no-data-container">Sem dados disponíveis</div> :
        <div className='tableFixHead collapsible-table'>
          <table className='table-dash'>
            <thead>
              <tr>
                <th colSpan="2">Fonte</th>
                <th>Visitantes</th>
                <th>Novos visitantes</th>
                <th>Sessões</th>
                <th>Visualizações de páginas</th>
                <th>Taxa de rejeição</th>
                <th>Tempo médio</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <>
                    <tr className='outer-table' key={index + 'item' + item.name}>
                      <td className='no-border-r'>
                        <IconButton aria-label="expand row" onClick={() => handleRowExpand(index)}>
                          {expandedRows.includes(index) ? <IndeterminateCheckBoxOutlinedIcon style={{ fontSize: '15px' }} /> : <AddBoxOutlinedIcon style={{ fontSize: '15px' }} />}
                        </IconButton>
                      </td>
                      <td className='no-border-l text-left'>{item.name}</td>
                      {getOuterTable(item, index)}
                    </tr>
                    {expandedRows.includes(index) && (getSubItems(item.details))}
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      }
    </>
  )
}