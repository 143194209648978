import React, { useState, useEffect } from 'react';
import Xarrow, { useXarrow } from "react-xarrows";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { formatNumber } from '../../../../../../services/util';
import './TreeMap.scss'

const useStyles = makeStyles((theme) => {
  return {
    select: {
      fontSize: '12px',
      '&:before': {
        borderColor: '#FF6E5A',
      },
      '&:after': {
        borderColor: '#FF6E5A',
      }
    }
  };
})

export default function TreeMap({ data, isLoading, filters, setFilters }) {
  const classes = useStyles();
  const updateXarrow = useXarrow();
  const [personaMaxValue, setPersonaMaxValue] = useState(0);
  const [filterTwoMaxValue, setFilterTwoMaxValue] = useState(0);
  const [filterThreeMaxValue, setFilterThreeMaxValue] = useState(0);
  const [selectedPersonaIndex, setSelectedPersonaIndex] = useState(filters.selectedTreePersonaIndex);
  const [selectedFilterTwoIndex, setSelectedFilterTwoIndex] = useState(filters.selectedTreeFilterTwoIndex);
  const [isFilterTwoSelected, setIsFilterTwoSelected] = useState(false);
  const [selectOptionsOfFilterTwo, setSelectOptionsOfFilterTwo] = useState(filters.filterTwo);
  const [selectOptionsOfFilterThree, setSelectOptionsOfFilterThree] = useState(filters.filterThree);

  const selectOptionList = [
    { value: 'income', name: 'Faixa de renda' },
    { value: 'gender', name: 'Gênero' },
    { value: 'region', name: 'Região' },
    { value: 'ageRange', name: 'Faixa etária' }
  ];

  const handleDropDownFilterTwo = (e) => {
    setSelectOptionsOfFilterTwo(e);
    setSelectedFilterTwoIndex(null);
    setIsFilterTwoSelected(false);

    setFilters(prevState => ({
      ...prevState,
      filterTwo: e,
      income: '',
      genderTreeFilter: '',
      regionTreeFilter: '',
      ageRange: '',
      selectedTreeFilterTwoIndex:''
    }));
  };

  const handleDropDownFilterThree = (e) => {
    setSelectOptionsOfFilterThree(e);

    setFilters(prevState => ({
      ...prevState,
      filterThree: e
    }));
  };

  const handlePersonaSelect = (index, filter) => {
    setSelectedPersonaIndex(index);

    setFilters(prevState => ({
      ...prevState,
      personaTreeFilter: filter,
      selectedTreePersonaIndex: index
    }));
  };

  const handleFilterTwoSelect = (index, filter) => {
    setIsFilterTwoSelected(true);
    setSelectedFilterTwoIndex(index);

    switch (selectOptionsOfFilterTwo) {
      case 'income':
        setFilters(prevState => ({
          ...prevState,
          income: filter,
          selectedTreeFilterTwoIndex: index
        }));

        break;
      case 'gender':
        setFilters(prevState => ({
          ...prevState,
          genderTreeFilter: filter,
          selectedTreeFilterTwoIndex: index
        }));

        break;
      case 'region':
        setFilters(prevState => ({
          ...prevState,
          regionTreeFilter: filter,
          selectedTreeFilterTwoIndex: index
        }));

        break;
      case 'ageRange':
        setFilters(prevState => ({
          ...prevState,
          ageRange: filter,
          selectedTreeFilterTwoIndex: index
        }));

        break;
      default:
        break;
    }
  }

  const setUpData = () => {

    if(filters.selectedTreeFilterTwoIndex !== ''){
      setIsFilterTwoSelected(true)
    }else{
      setIsFilterTwoSelected(false)
    }

    if (data.personaData) {
      setPersonaMaxValue(data.personaData[0]?.total ?? 0);
    }

    if (data.filterTwoOptions) {
      setFilterTwoMaxValue(data.filterTwoOptions[0]?.total ?? 0);
    };

    if (data.filterThreeOptions) {
      setFilterThreeMaxValue(data.filterThreeOptions[0]?.total ?? 0);
    }
  }

  useEffect(() => {
    
    if (!isLoading) {
      setUpData();
    }
  }, [isLoading]);

  return (
    <div onLoad={useXarrow()} className="treeWrapper">
      <div className='tree-header'>
        <div className='tree-header-col'></div>
        <div className='tree-header-col'>
          <Select className={classes.select}
            disabled
            displayEmpty
            value={0}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={0}>Persona</MenuItem>
          </Select>
          <FormHelperText>{data?.personaData && data?.personaData[selectedPersonaIndex]?.name}</FormHelperText>
        </div>
        <div className='tree-header-col'>
          <Select className={classes.select}
            value={selectOptionsOfFilterTwo}
            displayEmpty
            disabled={isLoading}
            onChange={(event) => { handleDropDownFilterTwo(event.target.value) }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {selectOptionList.map((item, index) => {
              if (item.value !== selectOptionsOfFilterThree) {
                return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
              }
            })}
          </Select>
          <FormHelperText>{data?.filterTwoOptions && data?.filterTwoOptions[selectedFilterTwoIndex]?.name}</FormHelperText>
        </div>
        <div className='tree-header-col'>
          <Select className={classes.select}
            value={selectOptionsOfFilterThree}
            displayEmpty
            disabled={isLoading}
            onChange={(event) => { handleDropDownFilterThree(event.target.value) }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {selectOptionList.map((item, index) => {
              if (item.value !== selectOptionsOfFilterTwo) {
                return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
              }
            })}
          </Select>
        </div>
      </div>
      {isLoading ? <div className='tree-loading'> <CircularProgress /> </div> :
        <div onscroll={updateXarrow} className='tree-body'>
          <div className='tree-columns qtd-column'>
            <div className='tree-progressbar-out' id={'col1-total'}>
              <div className='tree-progressbar-inner' style={{ width: '100%' }}></div>
            </div>
            <div className='tree-progressbar-label'>
              <span>Quantidade</span> {data?.totalUsers}
            </div>
          </div>
          <div className='tree-columns'>
            {data?.personaData?.map((item, index) =>
              <div className='tree-progresbar-container' key={index}  >
                <div className='tree-progressbar-out' id={'col2' + index} onClick={() => handlePersonaSelect(index, item.name)}>
                  <div className='tree-progressbar-inner' style={{ width: (item.total / personaMaxValue) * 100 + '%' }}></div>
                </div>
                <div className='tree-progressbar-label'>
                  <span>{item.name}</span> {formatNumber(item.total)}
                </div>
              </div>
            )}
          </div>
          <div className='tree-columns'>
            {data?.filterTwoOptions && data.filterTwoOptions.map((item, index) =>
              <div className='tree-progresbar-container' key={index}>
                <div className='tree-progressbar-out' id={'col3' + index} onClick={() => handleFilterTwoSelect(index, item.name)}>
                  <div className='tree-progressbar-inner' style={{ width: (item.total / filterTwoMaxValue) * 100 + '%' }}></div>
                </div>
                <div className='tree-progressbar-label'>
                  <span>{item.name}</span> {formatNumber(item.total)}
                </div>
              </div>
            )}
          </div>
          <div className='tree-columns'>
            {data?.filterThreeOptions && data.filterThreeOptions.map((item, index) =>
              <div className='tree-progresbar-container' key={index} >
                <div className='tree-progressbar-out' id={'col4' + index}>
                  <div className='tree-progressbar-inner' style={{ width: (item.total / filterThreeMaxValue) * 100 + '%' }}></div>
                </div>
                <div className='tree-progressbar-label'>
                  <span>{item.name}</span> {formatNumber(item.total)}
                </div>
              </div>
            )}
          </div>
          {data?.personaData?.map((item, i) =>
            <Xarrow showHead={false} strokeWidth={0.5} color={i === selectedPersonaIndex ? '#FF6E5A' : '#B0B0B0'}
              start="col1-total"
              end={'col2' + i}
            />
          )}
          {data?.filterTwoOptions && (filters?.personaTreeFilter || data?.personaData?.length === 1) && data.filterTwoOptions.map((item, i) =>
            <Xarrow showHead={false} strokeWidth={0.5} color={i === selectedFilterTwoIndex ? '#FF6E5A' : '#B0B0B0'}
              start={'col2' + selectedPersonaIndex}
              end={'col3' + i}
            />
          )}
          {data?.filterThreeOptions && (isFilterTwoSelected) && data.filterThreeOptions.map((item, i) =>
            <Xarrow showHead={false} strokeWidth={0.5} color={'#B0B0B0'}
              start={'col3' + selectedFilterTwoIndex}
              end={'col4' + i}
            />
          )}
        </div>
      }
    </div>
  );
}