import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './IndexCard.scss';

export default function IndexCard({ index }) {
  return (
    <>
      {!index ? <div className="no-data-container">Sem dados disponíveis</div> :
        <div className="index-card-container" style={{ backgroundColor: index.backgroundColor || "#005E50" }}>
          {index.hasOwnProperty("isGoingUp") && index.isGoingUp != null &&
            <div className="index-icon">
              {index.isGoingUp ? <ArrowDropUpIcon style={{ color: "#05CCAE" }} fontSize="large" />
                : <ArrowDropDownIcon fontSize="large" style={{ color: "#FF6E5A" }} />}
            </div>
          }
          <div className="index-name">{index.name}</div>
          <div className="index-content">
            <div className="index-value">{index.value}</div>
            {(index.tablet || index.desktop || index.mobile) && (
              <div className="index-devices">
                <div>
                  <span>{index.desktop || "--"}</span>
                  <span>Desktop</span>
                </div>
                <div>
                  <span>{index.tablet || "--"}</span>
                  <span>Tablet</span>
                </div>
                <div>
                  <span>{index.mobile || "--"}</span>
                  <span>Mobile</span>
                </div>
              </div>
            )}
            {index.percentage && (
              <div className="index-percentage">
                <>{index.percentage}</>
              </div>
            )}
          </div>
        </div>
      }
    </>
  )
}