import React, { useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  withStyles,
  Box
} from "@material-ui/core";
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import ReCAPTCHA from "react-google-recaptcha";

import FormDialog from "../../../shared/components/FormDialog";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import api from "../../../services/api";
import { isAuthenticated, login, getStatus } from "../../../services/auth";
import { enumTypeUserStatuses } from "../../../services/enums";
import { Alert } from "@material-ui/lab";
import SocialContainer from "../socialButton/SocialContainer";


let email = null;
let password = null;
let code = null;

const styles = (theme) => ({
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: "auto",
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    marginRight: 0,
  },
  message: {
    textAlign: "center",
    marginBottom: "5px",
  },
  captcha: {
    display: 'flex',
    paddingTop: '12px',
    width: '100%',
    justifyContent: 'center'
  },

  captchaError: {
    margin: '0px 14px 0px 14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
})

const Globals = require("../../../Globals.json");

function LoginDialog(props) {
  const {
    setStatus,
    message,
    history,
    classes,
    onClose,
    openChangePasswordDialog,
    status,
    emailVerified,
    // user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const loginEmail = useRef();
  const loginPassword = useRef();
  const verificationCode = useRef();
  const [fieldsValidation, setFieldsValidation] = useState({});
  const [isTwoFactorsAuthenticationEnabled, setTwoFactorsAuthentication] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const captchaRef = useRef(null);
  const [checkRecaptchaMsg, setcheckRecaptchaMsg] = useState(null);

  const RECAPTCHA = (process.env.REACT_APP_RECAPTCHA || 'false');

  const handleSignIn = async e => {
    let token = null;
    if (RECAPTCHA !== 'false') {
      token = captchaRef.current.getValue();
      captchaRef.current.reset();
    }

    setIsLoading(true);
    setcheckRecaptchaMsg(null);

    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else if (!token && RECAPTCHA !== 'false') {
      setcheckRecaptchaMsg("Campo obrigatório!");
      setIsLoading(false);
      setTimeout(() => {
        setcheckRecaptchaMsg(null);
      }, 3500)
    } else {
      try {
        await api.post(Globals.api.login, { email, password, token });
        setTwoFactorsAuthentication(true);
        setIsLoading(false);
      } catch (err) {
        const fieldsValidation = {};
        if (err.response.data?.code === 'Token' && RECAPTCHA) {
          setcheckRecaptchaMsg(err.response.data.message);
          setTimeout(() => {
            setcheckRecaptchaMsg(null);
          }, 3500);
        } else {
          try {
            err.response.data.forEach(error => {
              fieldsValidation[error.field] = error.message;
            });
          }
          catch (err) {
            setErrorMsg(true);
          }
        }

        setIsLoading(false);
        setFieldsValidation(fieldsValidation);
        setTimeout(() => {
          setFieldsValidation({});
          setErrorMsg(false);
        }, 3500)
      }
    }
  };

  const handleSubmitFactorValidation = async e => {
    setIsLoading(true)
    if (!code) {
      this.setState({ error: "Insira o código" });
    } else {
      try {
        let user_ip = '127.0.0.1';
        try {
          const responseIpify = await fetch('https://api64.ipify.org?format=json');
          const dataIpify = await responseIpify.json();
          user_ip = dataIpify.ip;
        }
        catch {
          try {
            const responseGeolocation = await fetch('https://geolocation-db.com/json/');
            const dataGeolocation = await responseGeolocation.json();
            user_ip = dataGeolocation.IPv4;
          }
          catch {
            // se deu erro na redundância não faz nada
          }
        }

        const response = await api.post(Globals.api.authenticate, { email, password, code, ip_address: user_ip });
        login(response.data.data.token);
        loadData()
      }
      catch (err) {
        const fieldsValidation = { code: null };
        fieldsValidation.code = err.response.data.message;
        setIsLoading(false);
        setFieldsValidation(fieldsValidation);
        setTimeout(() => {
          setFieldsValidation({})
        }, 3500)
      }

    }
  };

  const showLoading = () => {
    setIsLoading(true)
  }

  const hideLoading = () => {
    setIsLoading(false)
  }

  const loadData = async () => {

    if (isAuthenticated()) {


      const response = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", response.data);

      const status = response.data.type_users_status_id;

      switch (status) {
        case enumTypeUserStatuses.active: {
          history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.firstIndexMustBeFilled: {
          history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.forwardToBlog: {
          history.push(Globals.paths.forwardToBlog);
          break;
        }
        case enumTypeUserStatuses.notVerified: {
          history.push(Globals.paths.confirmEmail);
          break;
        }
        case enumTypeUserStatuses.validatingIndebtProfile: {
          history.push(Globals.paths.validatingIndebtProfile);
          break;
        }
        default: {
          break;
        }
      }

    }

    setIsLoading(false);
  }

  const cancelAuthentication = () => {
    setTwoFactorsAuthentication(false);
  }

  const emailHideMask = (emailAddress) => {
    let splitted;
    splitted = emailAddress.split("@");
    return splitted[0][0] + "*****" + splitted[0][splitted[0].length - 1] + "@" + splitted[1]

  }

  const displayLoginLayout = () => {
    const toDisplay =
      <FormDialog
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
        hideBackdrop
        headline="Login"
        content={
          <Fragment>
            <SocialContainer
              onLoginSuccess={loadData}
              showLoading={showLoading}
              hideLoading={hideLoading}
            />

            {emailVerified && (
              <Alert severity="info">Seu e-mail foi validado com sucesso!</Alert>
            )}
            <div className={classes.message}>{message}</div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário / E-mail"
              inputRef={loginEmail}
              autoFocus
              autoComplete="off"
              type="email"
              onChange={e => email = e.target.value}
              error={!!fieldsValidation.uid || !!fieldsValidation.password || !!fieldsValidation.email}
              helperText={fieldsValidation.uid || fieldsValidation.password || fieldsValidation.email}
              FormHelperTextProps={{ error: true }}
            />
            <VisibilityPasswordTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Senha"
              inputRef={loginPassword}
              autoComplete="off"
              onChange={e => password = e.target.value}
              error={!!fieldsValidation.uid || !!fieldsValidation.password}
              helperText={fieldsValidation.uid || fieldsValidation.password}
              FormHelperTextProps={{ error: true }}
              onVisibilityChange={setIsPasswordVisible}
              isVisible={isPasswordVisible}
            />
            {RECAPTCHA !== 'false' &&
              <div className={classes.captcha} style={{ transform: "scale(1)", transformOrigin: "0 0" }}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  hl='pt-BR' />
              </div>}

            {checkRecaptchaMsg &&
              <div className={classes.captchaError}>{checkRecaptchaMsg}</div>
            }
            {errorMsg &&
              <Alert severity="error">Algo deu errado! Verifique sua conexão e tente novamente mais tarde.</Alert>}
          </Fragment>

        }
        actions={
          <Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              size="large"
            >
              {isLoading ? <ButtonCircularProgress /> : "Entrar"}
            </Button>
            <Typography
              align="center"
              className={classNames(
                classes.forgotPassword,
                isLoading ? classes.disabledText : null
              )}
              color="primary"
              onClick={isLoading ? null : openChangePasswordDialog}
              tabIndex={0}
              role="button"
              onKeyDown={(event) => {
                // For screenreaders listen to space and enter events
                if (
                  (!isLoading && event.keyCode === 13) ||
                  event.keyCode === 32
                ) {
                  openChangePasswordDialog();
                }
              }}
            >
              Esqueceu a Senha?
            </Typography>

          </Fragment>
        }
      />
    return toDisplay
  }

  const displayTwoFactorsAuthenticationLayout = () => {
    const toDisplay =
      <FormDialog
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          handleSubmitFactorValidation();
        }}
        hideBackdrop
        headline="Verificação de Identidade"
        content={
          <Grid xs={12}>
            <Grid item>
              <p>Um email foi enviado para {emailHideMask(email)}.
                Insira o código recebido abaixo para nos ajudar a identificar sua identidade.
              </p>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Código"
              inputRef={verificationCode}
              autoFocus
              autoComplete="off"
              type="string"
              onChange={e => code = e.target.value}
              error={!!fieldsValidation.code}
              helperText={fieldsValidation.code}
              FormHelperTextProps={{ error: true }}
            />
          </Grid>
        }

        actions={
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Button
              onClick={cancelAuthentication}
              fullWidth
              variant="contained"
              type="green"
              size="large"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              size="large"
            >
              {isLoading ? <ButtonCircularProgress /> : "Verificar"}
            </Button>
          </Box>
        }
      />
    return toDisplay
  }

  return (
    <Fragment>
      {isTwoFactorsAuthenticationEnabled ? displayTwoFactorsAuthenticationLayout() : displayLoginLayout()}
    </Fragment>
  );
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  openChangePasswordDialog: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.string,
};

export default withRouter(withStyles(styles)(watch((LoginDialog), { user: '' })));
