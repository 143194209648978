import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDashboardEndDate, getDashboardStartDate } from '../../../../../services/util';
import BarChartVerticalDashboard from '../Components/Charts/BarChartVerticalDashboard';
import ComposedChartDashboard from '../Components/Charts/ComposedChartDashboard';
import PieChartDashboard from '../Components/Charts/PieChartDashboard';
import IndexFunnelChart from './IndexFunnelChart/IndexFunnelChart';
import CollapsibleTable from './CollapsibleTable/CollapsibleTable';
import EvolutionCharts from './EvolutionCharts/EvolutionCharts';
import { IconButton, makeStyles } from '@material-ui/core';
import IndexCard from '../Components/IndexCard/IndexCard';
import { ClearAll, Refresh } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { ResponsiveContainer } from 'recharts';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import './StatisticalIndicators.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const StatisticalIndicators = ({ data, isLoading, filters, setFilters, alert }) => {
  const classes = useStyles();
  const [device, setDevice] = useState('');
  const [origin, setOrigin] = useState('');
  const [source, setSource] = useState('');
  const [campaign, setCampaign] = useState('');
  const [region, setRegion] = useState('');
  const [state, setState] = useState('');

  const loadDropdown = (options) => {
    let optionsList = [];
    optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>);

    if (options) {
      options.map((item, index) => (
        optionsList.push(<option key={index} value={item}>{item}</option>)
      ))
    }

    return optionsList;
  };

  const handleFilterChange = (e, element) => {
    if (element === 'startDate' || element === 'endDate') {
      setFilters(prevState => ({ ...prevState, [element]: e }));
    } else {
      setFilters(prevState => ({ ...prevState, [element]: e.target.value }));
    }
  };

  const setUpFilters = () => {
    setDevice(filters.device);
    setOrigin(filters.origin);
    setSource(filters.source);
    setCampaign(filters.campaign);
    setRegion(filters.region);
    setState(filters.state);
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate(),
      device: '',
      origin: '',
      source: '',
      campaign: '',
      region: '',
      state: ''
    }));
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));
  };

  const showSkeleton = () => {
    return <>
      <div className="ps-message">**Setas analisam data atual vs anterior</div>
      <div className="dash-skeleton-container">
        <div className="grid-row-2">
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-1 min-width-190 min-height-130">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className="grid-row-4">
          <div className="grid-col-1 min-width-520 min-height-270">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-2 min-width-700 min-height-270">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className="grid-row-4">
          <div className="grid-col-1 min-width-410 min-height-320">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-2 min-width-570 min-height-320">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
          <div className="grid-col-3 min-width-700 min-height-320">
            <Skeleton variant="rect" width={"100%"} height={"100%"} />
          </div>
        </div>
      </div>
    </>;
  };

  const showContent = () => {
    return <>
      <div className="ps-message">**Setas analisam data atual vs anterior</div>
      <div className="dash-content-container">
        <div className="grid-row">
          {data?.indexData?.map((item, index) =>
            <div className="grid-col-1 min-width-190 min-height-130">
              <IndexCard key={'indexCard_' + index} index={item} />
            </div>
          )}
        </div>
        <div className="grid-row">
          <div className="grid-col-1 min-width-520 min-height-270">
            <IndexFunnelChart data={data?.funnelData} />
          </div>
          <div className="grid-col-2 min-width-700 min-height-270">
            <EvolutionCharts data={data?.evolutionData} />
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-1 min-width-410 min-height-320">
            <div className="vertical-bar-chart-container">
              <div className='title-container'>Origem de tráfico (visitantes)</div>
              <div className='vertical-bar-chart'>
                <ResponsiveContainer width={'99%'} height={'100%'}>
                  <BarChartVerticalDashboard data={data?.trafficOriginData}
                    ylabel={'name'}
                    yWidth={110}
                    showLegend={false}
                    chartWidth={'100%'}
                    chartMargin={{ left: 0, right: 35 }}
                    nameAndDataKey={[{ name: 'Origem de tráfico', datakey: 'value' }]} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-col-2 min-width-570 min-height-320">
            <div className='source-analysis-container'>
              <div className='title-container'>Análise por source</div>
              <div className='scroll-container'>
                <CollapsibleTable key="sourceAnalysisData" data={data?.sourceAnalysisData} />
              </div>
            </div>
          </div>
          <div className="grid-col-3 min-width-700 min-height-320">
            <div className='signup-charts-container'>
              <div className='title-container'>Cadastros</div>
              <div className='signup-chart-container'>
                <div className='signup-bar'>
                  <div className='title-container'>Faixa etárias</div>
                  <ResponsiveContainer width="99%" height={260}>
                    <ComposedChartDashboard data={data?.ageVsRegistrationData}
                      barNameDatakey={[{ name: 'Quantidade', datakey: 'value', fill: "#05CCAE" }]}
                      lineDatakey={'percentage'}
                      lineName={'Porcentagem'}
                      YaxisLeft={'Faixa Etária'}
                      YaxisRight={'percentage'}
                      chartWidth={0}
                      chartHeight={260}
                      legend={false}
                      toolTipName={'Faixa etária'}
                      chartMargin={{ top: 20, right: 0, bottom: 20, left: 0 }} />
                  </ResponsiveContainer>
                </div>
                <div className='signup-pie'>
                  <div className='title-container'>Gênero</div>
                  <PieChartDashboard data={data.genderVsRegistrationData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>}
    </>;
  };

  useEffect(() => {
    if (!isLoading) {
      setUpFilters();
    }

  }, [isLoading]);

  return (
    <div className="statistical-indicators">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container">
          <span className="filter-title">Dispositivo:</span>
          <select value={device} onChange={(e) => handleFilterChange(e, 'device')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.device)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Origem:</span>
          <select value={origin} onChange={(e) => handleFilterChange(e, 'origin')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.medium)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Fonte:</span>
          <select value={source} onChange={(e) => handleFilterChange(e, 'source')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.source)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Campanha:</span>
          <select value={campaign} onChange={(e) => handleFilterChange(e, 'campaign')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.campaign)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Região:</span>
          <select value={region} onChange={(e) => handleFilterChange(e, 'region')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.regions)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Estado:</span>
          <select value={state} onChange={(e) => handleFilterChange(e, 'state')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.states)
            }
          </select>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default StatisticalIndicators;