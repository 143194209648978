import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import api from '../../services/api';
import { isAuthenticated, login, logout } from '../../services/auth';
import { enumTypeUserStatuses } from '../../services/enums';
import ButtonCircularProgress from '../../shared/components/ButtonCircularProgress';
import VisibilityPasswordTextField from '../../shared/components/VisibilityPasswordTextField';
import './registerStepper1.scss';
import ChangePasswordDialog from './register_login/ChangePasswordDialog';
import SocialContainer from './socialButton/SocialContainer';

const styles = (theme) => ({
  container: {
    backgroundColor: '#fff',
    flexWrap: 'nowrap',
    padding: '30px',
  },
  left: {
    backgroundColor: '#666',
    padding: '10px',
  },
  right: {
    // backgroundColor: "#00f",
    padding: '10px',
    paddingLeft: '30px',
    paddingRight: '30px',
    overflow: 'auto',
    textAlign: 'center',
    '@media (max-width: 700px)': {
      padding: '7px',
    },
  },
  inputField: {
    borderRadius: '2px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5px',
    width: '85%',
    color: '#595959',
    '@media (max-width: 700px)': {
      width: '90%',
      textAlign: 'left',
    },
  },
  explanation: {
    fontSize: '14px',
    color: '#595959',
    textAlign: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '85%',
    margin: '0 auto',
    '@media (max-width: 700px)': {
      padding: '10px 15px',
      fontSize: '13px',
      width: '90%',
    },
  },
  passReq: {
    paddingLeft: '5px',
    fontSize: '13px',
  },
  passReqText: {
    paddingLeft: '5px',
    whiteSpace: 'nowrap',
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:enabled:hover': {
      color: theme.palette.primary.dark,
    },
    '&:enabled:focus': {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: 'auto',
    color: theme.palette.text.disabled,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#005E50',
    '&:hover': {
      color: '#05CCAE',
    }
  },
  popover: {
    padding: theme.spacing(2),
    maxWidth: '350px',
  },
  buttonActionWrapper: {
    marginTop: '20px',
  },
  buttonAction: {
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '0 20px',
    },
  }
});

const Globals = require('../../Globals.json');

const TextMaskCellphone = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

TextMaskCellphone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TextMaskCPF = (props) => {
  const { inputRef, ...other } = props;

  // implementa a interface `InputElement`
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      // logica para focar o componente renderizado de terceiros entra aquito focus
    },
    // ocultando o valor p.ex. react-stripe-elements
  }));

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

TextMaskCPF.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const HtmlTooltip2 = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#595959',
    marginLeft: '60px',
    paddingLeft: '10px',
    paddingRight: '15px',
    paddingBottom: '10px',
    boxShadow: '2px 5px 15px #00000055',
  },
  arrow: {
    height: '45px',
    color: '#eee',
  },
}))(Tooltip);

const HtmlTooltip = function HtmlTooltip(props, ref) {
  //  Distribua as propriedades para o elemento DOM subjacente.
  return (
    <HtmlTooltip2 {...props} ref={ref}>
      {props.children}
    </HtmlTooltip2>
  );
};

class RegisterStep1 extends React.Component {
  // HtmlTooltip = React.createRef();

  invalidCpfs = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '5555555555',
    '666666666666',
    '777777777777',
    '888888888888',
    '99999999999',
  ];

  history = this.props.history;

  state = {
    textMaskCellphone: '',
    textMaskCPF: '   .   .   -  ',
    status: '',
    phone: '',
    phoneFormatted: '',
    name: '',
    gender: '',
    email: '',
    confirmEmail: '',
    cpf: '',
    cpfFormatted: '',
    pass: '',
    passScore: 0,
    passReq_min6: false,
    passReq_upper: false,
    passReq_lower: false,
    passReq_number: false,
    passReq_specialChar: false,
    confirmPass: '',
    acceptTerms: false,
    acceptEmails: false,
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    isLoading: false,
    fieldsValidation: {},
    onClose: false,
    resetPassword: false,
    dataSent: false,
    validation: false,
    validationReqPass: false,
    alertError: '',
    cpfHelpOpen: false,
    anchorCpfHelpEl: null,
  };

  handleClickCpfHelp = (event) => {
    this.setState({ anchorCpfHelpEl: event.currentTarget, cpfHelpOpen: true });
  };

  handleCloseCpfHelp = () => {
    this.setState({ anchorCpfHelpEl: null, cpfHelpOpen: false });
  };

  componentDidMount = () => {
    let formLocalStorage = '';

    if (localStorage.getItem('initialForm')) {
      formLocalStorage = JSON.parse(localStorage.getItem('initialForm'));
    }

    this.setState({
      name: formLocalStorage.nome,
      email: formLocalStorage.email,
      phoneFormatted: formLocalStorage.telefone,
      phone: formLocalStorage.telefone,
    });

    /*setTimeout(() => {
      this.setState({ dataSent: true })
      this.props.changeClassImage(2);
    }, 2000);*/
  };

  validateCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;

    console.log('CPF::', this.invalidCpfs.indexOf(strCPF), strCPF);

    if (this.invalidCpfs.indexOf(strCPF) > -1) return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  validateEmail(mail) {
    return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  passMobile() {
    this.setState({ validationReqPass: true });
  }
  passMobile2() {
    this.setState({ validationReqPass: false });
  }

  validateForm() {
    let ret = true;

    const fieldsValidation = {};

    console.log(this.state.confirmEmail);

    if (this.state.name === undefined) {
      fieldsValidation.name = 'Por favor preencha seu nome';
      ret &= false;
    }

    if (this.state.phone) {
      if (this.state.phone.length < 10) {
        fieldsValidation.phone = 'O telefone informado não é válido';
        ret &= false;
      }
    }

    if (this.state.email === undefined) {
      fieldsValidation.email = 'Por favor preencha seu e-mail';
      ret &= false;
    } else if (!this.validateEmail(this.state.email)) {
      fieldsValidation.email = 'O e-mail informado não é válido';
      ret &= false;
    }

    if (this.state.email !== this.state.confirmEmail) {
      fieldsValidation.confirmEmail = 'A confirmação do e-mail está diferente do e-mail informado';
      ret &= false;
    }

    if (this.state.pass === undefined) {
      fieldsValidation.pass = 'Por favor digite uma senha';
      ret &= false;
    } else if (
      !this.state.passReq_min6 ||
      !this.state.passReq_upper ||
      !this.state.passReq_lower ||
      !this.state.passReq_number ||
      !this.state.passReq_specialChar
    ) {
      fieldsValidation.pass = 'Por favor, preencha todos os requisitos de senha';
      ret &= false;
    }

    if (this.state.pass !== this.state.confirmPass) {
      fieldsValidation.confirmPass = 'A confirmação da senha está diferente da senha informada';
      ret &= false;
    }

    if (!this.state.acceptTerms) {
      fieldsValidation.acceptTerms =
        'Você precisa aceitar o termo de consentimento e política de uso para criar sua conta';
      ret &= false;
    }

    this.setState({ fieldsValidation: fieldsValidation });

    console.log(ret, fieldsValidation);

    return ret;
  }

  handleChange = (name, name2) => (event) => {
    this.removeValidation(name2);
    this.setState({
      [name]: event.target.value,
      [name2]: event.target.value.replace(/[-.() ]/g, ''),
    });
    console.log('--> ', this.state[name2].length);
    for (let i = 0; i < this.state[name2].length; i++) {
      console.log(this.state[name2].charCodeAt(i));
    }
  };

  removeValidation(key) {
    const obj = { ...this.state.fieldsValidation };
    delete obj[key];
    this.setState({ fieldsValidation: obj });
  }


  gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-360294347/Jh4uCNn3x68ZEMvP5qsB',
        'event_callback': callback
    });
    return false;
  }

  postData = async function () {
    logout();

    this.setState({ isLoading: true });

    try {
      const data = {
        username: this.state.name,
        email: this.state.email,
        password: this.state.pass,
        password_confirmation: this.state.confirmPass,
        number: this.state.phone,
        optin_email: this.state.acceptEmails,
      };

      try {
        const response = await api.post(Globals.api.register, data);
        login(response.data.data.token);
        this.gtag_report_conversion();
        await this.userAuthenticated();
      } catch (error) {
        const fieldsValidation = {};

        error.response.data.forEach((error) => {
          fieldsValidation[error.field] = error.message;
        });

        this.setState({ fieldsValidation: fieldsValidation });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  clean(str) {
    return str.replace(/[()-.\D ]/g, '');
  }

  maskValue(input, mask) {
    let str = this.clean(input.value);
    let counter = 0;
    let ret = '';
    for (let i = 0; i < mask.length; i++) {
      if (counter >= str.length) break;

      let char = mask.charAt(i);
      if (char === '#') {
        ret += str.charAt(counter);
        counter++;
      } else {
        ret += char;
      }
    }

    return ret;
  }
  handleTerms() {
    window.open(Globals.paths.privacyPolicy);
  }

  scorePassword(pass) {
    var score = 0;
    if (!pass) return score;

    var arr = pass.split('');
    var map = arr.reduce(function (prev, cur) {
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {});

    // score += Math.min( 6, pass.length ) * 5;
    score += Math.min(5, Object.keys(map).length) * 6;
    score += /\d/.test(pass) ? 15 : 0;
    score += /[a-z]/.test(pass) ? 15 : 0;
    score += /[A-Z]/.test(pass) ? 15 : 0;
    score += /\W/.test(pass) ? 15 : 0;

    score = (score / 6) * Math.min(6, pass.length);

    return score;
  }

  updatePassStatus(pass) {
    this.setState({
      passReq_min6: pass.length >= 6,
      passReq_upper: /[A-Z]/.test(pass),
      passReq_lower: /[a-z]/.test(pass),
      passReq_number: /\d/.test(pass),
      passReq_specialChar: /\W/.test(pass),
    });
  }

  getPasswordHelperText() {
    let color = '#f00';
    let label = 'Fraca';

    if (this.state.passScore >= 60) {
      color = '#c70';
      label = 'Média';
    }

    if (this.state.passScore >= 80) {
      color = '#0c0';
      label = 'Forte';
    }

    return <span style={{ color: color }}>Força da senha: {label}</span>;
  }

  showLoading = () => {
    this.setState({ isLoading: true });
  };

  hideLoading = () => {
    this.setState({ isLoading: false });
  };

  userAuthenticated = async () => {
    if (isAuthenticated()) {
      this.showLoading();

      const response = await api.get(Globals.api.selfInfo);
      dispatchSet('user.profile', response.data);

      this.hideLoading();

      const status = response.data.type_users_status_id;

      switch (status) {
        case enumTypeUserStatuses.active: {
          this.history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.firstIndexMustBeFilled: {
          this.history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.forwardToBlog: {
          this.history.push(Globals.paths.forwardToBlog);
          break;
        }
        case enumTypeUserStatuses.notVerified: {
          this.history.push(Globals.paths.confirmEmail);
          break;
        }
        case enumTypeUserStatuses.validatingIndebtProfile: {
          this.history.push(Globals.paths.validatingIndebtProfile);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  render() {
    const { classes, setStatus, onClose } = this.props;

    return (
      <>
        <div className={classes.right + ' registerStepper1'}>
          {this.state.dataSent ? (
            <>
              <Grid item className={classes.explanation}>
                <h1 className="register-done-title">Tudo certo!</h1>
                <p>Enviamos um link de confirmação para o seu email. </p>
                <p>
                  Siga as instruções enquanto validamos o seu cadastro para que a sua experiência
                  conosco seja positiva e segura.{' '}
                </p>
                <p className="register-done-special-message">
                  Ah! Não esqueça de verificar a sua caixa de Spam{' '}
                  <span role="img" aria-label="">
                    😉
                  </span>
                </p>
              </Grid>
              <Button
                onClick={() => {
                  this.props.closeFunction();
                }}
                style={{
                  backgroundColor: '#005E50',
                  color: '#fff',
                  borderColor: '#005E50',
                  borderRadius: '0px',
                  position: 'absolute',
                  bottom: '30px',
                  right: '35px',
                }}
              >
                Ok
              </Button>
            </>
          ) : (
            <>
              <Grid item className={classes.explanation}>
                Cadastre seus dados no Meu Bolso em Dia, monte o seu perfil e escolha o conteúdo
                favorito para acessar onde e quando quiser.
              </Grid>
              <SocialContainer
                onLoginSuccess={this.userAuthenticated}
                showLoading={this.showLoading}
                hideLoading={this.hideLoading}
              />
              <TextField
                value={this.state.name}
                className={classes.inputField}
                size="small"
                variant="outlined"
                required
                fullWidth
                error={!!this.state.fieldsValidation.name}
                helperText={this.state.fieldsValidation.name}
                placeholder="Seu nome *"
                autoFocus
                autoComplete="off"
                type="text"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                  this.removeValidation('name');
                }}
                FormHelperTextProps={{ error: true }}
                onFocus={(e) => this.passMobile2()}
              />
              <TextField
                className={classes.inputField}
                size="small"
                variant="outlined"
                fullWidth
                error={!!this.state.fieldsValidation.phone}
                helperText={this.state.fieldsValidation.phone}
                placeholder="Seu telefone"
                autoComplete="off"
                type="text"
                value={this.state.phoneFormatted}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  this.setState({
                    phoneFormatted: this.maskValue(e.target, '(##) #####-####'),
                    phone: this.clean(e.target.value),
                  });
                  this.removeValidation('phone');
                }}
                FormHelperTextProps={{ error: true }}
                onFocus={(e) => this.passMobile2()}
              />
              <TextField
                value={this.state.email}
                className={classes.inputField}
                size="small"
                variant="outlined"
                required
                fullWidth
                error={!!this.state.fieldsValidation.email}
                helperText={this.state.fieldsValidation.email}
                placeholder="Seu e-mail *"
                autoComplete="off"
                type="email"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                  this.removeValidation('email');
                }}
                FormHelperTextProps={{ error: true }}
                onFocus={(e) => this.passMobile2()}
              />
              <TextField
                className={classes.inputField}
                size="small"
                variant="outlined"
                required
                fullWidth
                error={!!this.state.fieldsValidation.confirmEmail}
                helperText={this.state.fieldsValidation.confirmEmail}
                placeholder="Confirme seu e-mail *"
                autoComplete="off"
                type="email"
                onChange={(e) => {
                  this.setState({ confirmEmail: e.target.value });
                  this.removeValidation('confirmEmail');
                }}
                FormHelperTextProps={{ error: true }}
                onFocus={(e) => this.passMobile2()}
              />
              <HtmlTooltip
                placement="right"
                arrow={true}
                disableHoverListener
                title={
                  <>
                    <Hidden xsDown>
                      <Typography color="inherit">Requisitos da Senha</Typography>
                      <Grid container direction="column">
                        <Box
                          style={{ color: this.state.passReq_min6 ? '#090' : '#A00' }}
                          display="flex"
                          flexWrap="nowrap"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.passReq}
                        >
                          {this.state.passReq_min6 ? (
                            <Zoom in={true}>
                              <CheckCircleIcon fontSize="small" />
                            </Zoom>
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}{' '}
                          <Box className={classes.passReqText}>Mínimo de 6 caracteres</Box>
                        </Box>
                        <Box
                          style={{ color: this.state.passReq_upper ? '#090' : '#A00' }}
                          display="flex"
                          flexWrap="nowrap"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.passReq}
                        >
                          {this.state.passReq_upper ? (
                            <Zoom in={true}>
                              <CheckCircleIcon fontSize="small" />
                            </Zoom>
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}{' '}
                          <Box className={classes.passReqText}>Pelo menos 1 letra maiúscula</Box>
                        </Box>
                        <Box
                          style={{ color: this.state.passReq_lower ? '#090' : '#A00' }}
                          display="flex"
                          flexWrap="nowrap"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.passReq}
                        >
                          {this.state.passReq_lower ? (
                            <Zoom in={true}>
                              <CheckCircleIcon fontSize="small" />
                            </Zoom>
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}{' '}
                          <Box className={classes.passReqText}>Pelo menos 1 letra minúscula</Box>
                        </Box>
                        <Box
                          style={{ color: this.state.passReq_number ? '#090' : '#A00' }}
                          display="flex"
                          flexWrap="nowrap"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.passReq}
                        >
                          {this.state.passReq_number ? (
                            <Zoom in={true}>
                              <CheckCircleIcon fontSize="small" />
                            </Zoom>
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}{' '}
                          <Box className={classes.passReqText}>Pelo menos 1 número</Box>
                        </Box>
                        <Box
                          style={{ color: this.state.passReq_specialChar ? '#090' : '#A00' }}
                          display="flex"
                          flexWrap="nowrap"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.passReq}
                        >
                          {this.state.passReq_specialChar ? (
                            <Zoom in={true}>
                              <CheckCircleIcon fontSize="small" />
                            </Zoom>
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}{' '}
                          <Box className={classes.passReqText}>Pelo menos 1 caractere especial</Box>
                        </Box>
                      </Grid>
                    </Hidden>
                  </>
                }
              >
                <VisibilityPasswordTextField
                  className={classes.inputField}
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  error={!!this.state.fieldsValidation.pass}
                  helperText={this.state.fieldsValidation.pass}
                  label="Senha"
                  inputRef={this.state.registerPassword}
                  autoComplete="off"
                  onChange={(e) => {
                    this.setState({ pass: e.target.value });
                    this.updatePassStatus(e.target.value);
                    this.removeValidation('pass');
                  }}
                  onFocus={(e) => this.passMobile()}
                  FormHelperTextProps={{ error: this.state.fieldsValidation.pass !== null }}
                  isVisible={this.state.isPasswordVisible}
                  onVisibilityChange={(val) => {
                    this.setState({ isPasswordVisible: val });
                  }}
                />
              </HtmlTooltip>
              <Hidden smUp>
                {this.state.validationReqPass && (
                  <div style={{ margin: '10px 10px 0px 10px' }}>
                    <Box
                      style={{ fontSize: '12px', color: this.state.passReq_min6 ? '#090' : '#A00' }}
                      display="flex"
                      flexWrap="nowrap"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.passReq}
                    >
                      {this.state.passReq_min6 ? (
                        <Zoom in={true}>
                          <CheckCircleIcon fontSize="small" />
                        </Zoom>
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}{' '}
                      <Box className={classes.passReqText}>Mínimo de 6 caracteres</Box>
                    </Box>
                    <Box
                      style={{
                        fontSize: '12px',
                        color: this.state.passReq_upper ? '#090' : '#A00',
                      }}
                      display="flex"
                      flexWrap="nowrap"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.passReq}
                    >
                      {this.state.passReq_upper ? (
                        <Zoom in={true}>
                          <CheckCircleIcon fontSize="small" />
                        </Zoom>
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}{' '}
                      <Box className={classes.passReqText}>Pelo menos 1 letra maiúscula</Box>
                    </Box>
                    <Box
                      style={{
                        fontSize: '12px',
                        color: this.state.passReq_lower ? '#090' : '#A00',
                      }}
                      display="flex"
                      flexWrap="nowrap"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.passReq}
                    >
                      {this.state.passReq_lower ? (
                        <Zoom in={true}>
                          <CheckCircleIcon fontSize="small" />
                        </Zoom>
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}{' '}
                      <Box className={classes.passReqText}>Pelo menos 1 letra minúscula</Box>
                    </Box>
                    <Box
                      style={{
                        fontSize: '12px',
                        color: this.state.passReq_number ? '#090' : '#A00',
                      }}
                      display="flex"
                      flexWrap="nowrap"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.passReq}
                    >
                      {this.state.passReq_number ? (
                        <Zoom in={true}>
                          <CheckCircleIcon fontSize="small" />
                        </Zoom>
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}{' '}
                      <Box className={classes.passReqText}>Pelo menos 1 número</Box>
                    </Box>
                    <Box
                      style={{
                        fontSize: '12px',
                        color: this.state.passReq_specialChar ? '#090' : '#A00',
                      }}
                      display="flex"
                      flexWrap="nowrap"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.passReq}
                    >
                      {this.state.passReq_specialChar ? (
                        <Zoom in={true}>
                          <CheckCircleIcon fontSize="small" />
                        </Zoom>
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}{' '}
                      <Box className={classes.passReqText}>Pelo menos 1 caractere especial</Box>
                    </Box>
                  </div>
                )}
              </Hidden>
              <VisibilityPasswordTextField
                className={classes.inputField}
                size="small"
                variant="outlined"
                required
                fullWidth
                error={!!this.state.fieldsValidation.confirmPass}
                helperText={this.state.fieldsValidation.confirmPass}
                label="Confirme a senha"
                inputRef={this.state.registerPasswordRepeat}
                autoComplete="off"
                onChange={(e) => {
                  this.setState({ confirmPass: e.target.value });
                  this.removeValidation('confirmPass');
                }}
                onFocus={(e) => this.passMobile2()}
                FormHelperTextProps={{ error: true }}
                isVisible={this.state.isConfirmPasswordVisible}
                onVisibilityChange={(val) => {
                  this.setState({ isConfirmPasswordVisible: val });
                }}
              />
              <Grid item className={classes.explanation}>
                <p style={{ fontSize: '12px', color: '#595959', margin: 0 }}>
                  Os campos marcados com * são obrigatórios.
                </p>
              </Grid>
              <FormControlLabel
                className={classes.inputField}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      this.setState({ acceptTerms: e.target.checked });
                      this.removeValidation('acceptTerms');
                    }}
                  />
                }
                label={
                  <Typography variant="body2">
                    Eu aceito os
                    <span className={classes.link} onClick={() => this.handleTerms()}>
                      {' Termo de Consentimento e Política de Uso'}
                    </span>
                  </Typography>
                }
              />
              {this.state.fieldsValidation.acceptTerms && (
                <FormHelperText
                  error
                  style={{
                    display: 'block',
                    width: '80%',
                    margin: '0 auto',
                  }}
                >
                  {this.state.fieldsValidation.acceptTerms}
                </FormHelperText>
              )}
              <FormControlLabel
                className={classes.inputField}
                style={{ marginTop: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      this.setState({ acceptEmails: e.target.checked });
                    }}
                  />
                }
                label={
                  <Typography variant="body2" style={{ textAlign: 'left' }}>
                    Aceito receber informações e comunicações
                  </Typography>
                }
              />
              <Grid container direction="row" justify="flex-end" className={classes.buttonActionWrapper}>
                {this.state.resetPassword ? (
                  <ChangePasswordDialog onClose={onClose} setLoginStat us={setStatus} />
                ) : (
                  ''
                )}
                <Button
                  className={`${classes.buttonAction} g-rs1-continue`}
                  disabled={this.state.isLoading}
                  onClick={() => {
                    if (this.validateForm()) {
                      this.postData();
                    }
                  }}
                  style={{
                    backgroundColor: '#005E50',
                    color: '#fff',
                    borderColor: '#005E50',
                    minWidth: '140px',
                    borderRadius: '0px',
                    papddingTop: '3px'
                  }}
                >
                  {this.state.isLoading ? (
                    <ButtonCircularProgress color="white" />
                  ) : (
                    <>
                      Continuar
                      <ArrowRightIcon />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </div>
      </>
    );
  }
}

RegisterStep1.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  setLoginStatus: PropTypes.func,
};

export default withRouter(withStyles(styles)(RegisterStep1));
