import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../../shared/components/DialogTitleWithCloseIcon";
import PublicClassDetail from "./PublicClassDetail";
import './public-course-dialog.scss';

function PublicCourseDialog(props) {
  const { onClose, courseId, onRegister, logHomeAction } = props;

  return (
    <Dialog
      open
      onClose={onClose}
      fullscreen={'true'}
      fullWidth={true}
      maxWidth={'xl'}
      className={'public-course-dialog'}
    >
      <DialogTitleWithCloseIcon
        onClose={onClose}
      />
      <DialogContent className={'public-course-dialog-container'}>
        <PublicClassDetail courseId={courseId} onClose={onClose} onRegister={onRegister} logHomeAction={logHomeAction} />
      </DialogContent>
    </Dialog>
  );
}

PublicCourseDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PublicCourseDialog;