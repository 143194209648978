import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    FormControl,
    Snackbar,
    Select,
    InputLabel,
    MenuItem,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseRounded } from '@material-ui/icons';
import '../../../../backoffice.scss';
import './incentive.scss';
import TabPanel from '../../../../shared/components/TabPanel';
import PaginatedTable from '../components/PaginatedTable/PaginatedTable';
import Dialogs from '../../../../services/dialogs';

import api from "../../../../services/api";
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");

export default function Incentive() {
    const fieldsTable = [{ title: 'Instituição', fieldName: 'bank' }, { title: 'Incentivo', fieldName: 'insentiveCut', type: 'toolTip'}, { title: 'Descrição', fieldName: 'descriptionCut', type: 'toolTip' }, { title: 'Pontos necessários', fieldName: 'points' }, { title: 'Nível do usuário', fieldName: 'level' }]

    const [isLoading, setIsLoading] = useState(false);
    const [incentives, setIncentives] = useState([]);
    const [filterBy, setFilterBy] = useState('');
    const [selectedTenant, setSelectedTenant] = useState('');
    const [tenantsOptions, setTenantsOptions] = useState([])


    const getTenants = async (page = 1) => {

        try {
            await api.get(`${Globals.api.backofficeIncentiveSearchTenant}?page=${page}&per_page=${10}&search=${filterBy}&tenantId=${selectedTenant}`)
                .then((res) => {
                    setIncentives(res.data)
                })
        } catch (error) {
            console.log('erro get incentives')
        }

    }

    const loadDropdown = async () => {
        try {
            await api.get(`${Globals.api.backofficeIncentiveTenantList}`)
                .then((res) => {
                    setTenantsOptions(res.data);
                })
        } catch (error) {
            console.log('erro get incentives')
        }
    }

    const loadData = async () => {
        setIsLoading(true)
        await getTenants();
        setIsLoading(false);
    }

    const clear = () => {
        setFilterBy('');
        getTenants(1)
    }

    const handleFilterChange = (event) => {
        setSelectedTenant(event.target.value)
    }

    const setUpDropdowns = () => {
        let optionsList = [];

        optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>)

        if (tenantsOptions) {
            tenantsOptions.map((item, index) => (
                optionsList.push(<option key={index} value={item.id}>{item.name}</option>)
            ))
        }

        return optionsList
    }

    useEffect(() => {
        async function load() {
            await loadDropdown();
        }
        load();
        loadData();
    }, []);
    useEffect(() => {

        loadData()
    }, [selectedTenant]);


    const handleSearch = () => {
        loadData()
    }


    return (
        <div className='incentive-list'>
            <Grid className="backoffice-content">
                <Grid className="box">
                    <div className="table_user_contentTexts">
                        <span className="table_user_textList">Incentivos das instituições financeiras</span>
                    </div>
                    <Grid item container >
                        <div className="filter-container-incentive">
                            <div className='items'>
                                Buscar
                                <div className="searchbar">
                                    <div className="filter-elements">
                                        <input
                                            className="filter-element"
                                            placeholder="Digite um nome"
                                            value={filterBy}
                                            onChange={(e) => setFilterBy(e.target.value)}
                                        />
                                        <button
                                            className="filter-element-clear"
                                            onClick={() => clear()}
                                        >
                                            <CloseRounded />
                                        </button>
                                        <button
                                            className="filter-element-search"
                                            onClick={() => handleSearch()}
                                        >
                                            {'Buscar'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='items'>
                                Instituições financeiras
                                <select value={selectedTenant} onChange={(e) => handleFilterChange(e)}>
                                    {(isLoading) &&
                                        <option value='' disabled>Carregando...</option>
                                    }
                                    {!isLoading && setUpDropdowns()}
                                </select>
                            </div>
                        </div>



                        <PaginatedTable titleFields={fieldsTable} data={incentives} isLoading={isLoading} pagNavigation={getTenants} />

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
