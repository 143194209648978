
import React from 'react';
import './styles/courseListResp.scss';

import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { TurnedInNot } from '@material-ui/icons';
import { ReactComponent as FavoritesIcon } from '../../../assets/icon/header-icons/icon-favorite-stroke-neutre.svg';
import { Skeleton } from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';
import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';

import Button from '../button/Button';
import Pagination from "../../components/pagination/Pagination";
import SwipeableViews from "react-swipeable-views";
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const Globals = require("../../../Globals.json");

class CourseList extends React.Component {

  state = {
    isLoading: false,
    courses: [],
    categories: [],
    favorites: 0,
    gridCols: 3,
    activeCategory: null,
    alertError: '',
    index: 0,
  }
  course_resp_addFavorites = (id) => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: id,
      type_module_id: 2,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadDataAllCourses(loading)
      }).catch(error => {
        console.log(error)
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  removeFavorites = (id) => {
    const postData = {
      type_action_id: 26,
      data_modules_detail_id: id,
      type_module_id: 2,
    };

    api.post(Globals.api.userActionEvent, postData)
      .then(response => {
        var loading = true;
        this.loadDataAllCourses(loading)
      }).catch(error => {
        console.log(error)
        this.setState({ alertError: error?.response?.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  async loadDataAllCourses (loading) {
    try {
      if (loading) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: true });
      }

      let response = ''
      if(this.props.endpoint) {
        response = await api.post(this.props.endpoint, { module_type: 2 });
      } else {
        response = await api.post(Globals.api.getModules, { type_module_id: 2 });
      }

      const courses = this.props.sliceCourse ? response?.data.slice(0, 6) : response.data;

      const ongoingCourses = [];
      const categories = [];

      courses.forEach((item, index) => {

        if (item.user_module_data.hasOwnProperty("startedAt") && !item.user_module_data.hasOwnProperty("finishedCourseAt")) {
          ongoingCourses.push(item);
        }

        if (item.data.hasOwnProperty("categories") && Array.isArray(item.data.categories)) {
          item.data.categories.forEach(itemCat => {
            if (categories.indexOf(itemCat) === -1) {
              categories.push(itemCat);
            }
          })
        }

        this.setState({ isLoading: false })

      })
      var favorites = response?.data
      this.setState({ courses, categories, ongoingCourses, favorites: favorites });
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadDataAllCourses()
  }

  componentDidUpdate (oldProps) {

    if (this.props.courses !== oldProps.courses) {
      this.setState({ courses: this.props.courses });
    }

    if (this.props.categories !== oldProps.categories) {

      this.setState({ categories: this.props.categories });
    }
  }

  getFilteredCourses () {

    if (this.state.activeCategory === null) {
      return this.state.courses;
    }
    else {

      const filteredCourses = this.state.courses.filter(i => {
        if (i.data.categories && i.data.categories.indexOf(this.state.activeCategory) > -1) {
          return true;
        }
        else {
          return false;
        }
      })

      return filteredCourses;

    }
  }

  goToCourse = (course) => {
    // console.log( "Gotocourse props:", this.props );
    dispatchSet('courses.selectedCourse', course);
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", course.id));
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render () {

    const { index } = this.state;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ?
          <Grid>
            <Box pt={0.5}>
              <Skeleton variant="rect" width={"100%"} height={170} />
            </Box>
          </Grid> :
          <div>
            <div className="course_resp_container">
              <div className="course_resp_root">
                <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
                  {this.getFilteredCourses().map((item, index) => (
                    <div className="course_resp_root2" key={index}>
                      <div className="course_resp_itemBox">
                        <div className="course_resp_toolImage" style={{ backgroundImage: `url(${item.thumbnail})` }}>
                          <div className="course_resp_toolContainerText">
                            <div className="course_resp_containerTop">
                              <button className="course_resp_addFavorite"
                                onClick={() => { item.is_favorite !== null ? this.removeFavorites(item.id) : this.course_resp_addFavorites(item.id) }}>
                                {item.is_favorite !== null ?
                                  <FavoritesIcon style={{ stroke: "#05CCAE", width:"25px", marginTop:"-5px"}} /> : <FavoritesIcon style={{ stroke: "#FFF", width:"25px", marginTop:"-5px"}} />
                                }
                              </button>
                              {getCoinsFromRewards(item.data.rewards) > 0 &&
                                <div className="course_resp_card" >
                                  {getCoinsTextFromRewards(item.data.rewards)}
                                </div>
                              }
                            </div>
                            <div className="course_resp_texts">
                              <span className="course_resp_toolTitle">{item.name}</span>
                              <span className="course_resp_toolText">{item.description}</span>
                            </div>

                            <div className="course_resp_courseButtonContainer">
                              <Button onClick={() => this.goToCourse(item)} type="invert">
                                {item.user_module_data.finishedCourseAt ? 'Ver novamente' :
                                  item.user_module_data.startedAt ? "Continuar" : "Começar"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </SwipeableViews>

                <Pagination
                  dots={this.state.courses.length}
                  index={index}
                  onChangeIndex={this.handleChangeIndex}
                />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
export default CourseList;

