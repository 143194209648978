import React, { useEffect, useState } from 'react';
import { watch } from 'redux-easy/lib/redux-easy';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { getDashboardEndDate, getDashboardStartDate, getFilterEndDate, getFilterStartDate } from '../../../../services/util';
import StatisticalIndicators from './StatisticalIndicators/StatisticalIndicators';
import ProfileAnalysis from './ProfileAnalysis/ProfileAnalysis';
import CoursesAnalysis from './CoursesAnalysis/CoursesAnalysis';
import TabPanel from '../../../../shared/components/TabPanel';
import IncentivePlan from './IncentivePlan/IncentivePlan';
import Consolidated from './Consolidated/Consolidated';
import Engagement from './Engagement/Engagement';
import api from '../../../../services/api';
import './DashboardAnalytics.scss';

const Globals = require('../../../../Globals.json');

const DashboardAnalytics = () => {
  const tabNames = [
    'Indicadores estatísticos',
    'Análise de perfil',
    'Análise de cursos',
    'Plano de incentivo',
    'Engajamento',
    'Consolidado'
  ];

  const [alert, setAlert] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabsOpened, setTabsOpened] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessge] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [statisticalIndicatorsData, setStatisticalIndicatorsData] = useState({});
  const [isStatisticalIndicatorsDataLoading, setIsStatisticalIndicatorsDataLoading] = useState(false);
  const [statisticalIndicatorsFilters, setStatisticalIndicatorsFilters] = useState({
    startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), device: '', origin: '', source: '', campaign: '', region: '', state: ''
  });

  const [profileAnalysisData, setProfileAnalysisData] = useState({});
  const [isProfileAnalysisDataLoading, setIsProfileAnalysisDataLoading] = useState(false);
  const [profileAnalysisFilters, setProfileAnalysisFilters] = useState({
    startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), persona: '', gender: '', region: '', state: '', macroSegmentation: ''
  });

  const [profileAnalysisTreeData, setProfileAnalysisTreeData] = useState({});
  const [isProfileAnalysisTreeDataLoading, setIsProfileAnalysisTreeDataLoading] = useState(false);
  const [profileAnalysisTreeFilters, setProfileAnalysisTreeFilters] = useState({
    startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), persona: '', gender: '', region: '', state: '', macroSegmentation: '',
    ageRange: '', income: '', filterTwo: '', filterThree: '', genderTreeFilter: '', regionTreeFilter: '', personaTreeFilter: '',
    selectedTreePersonaIndex: '', selectedTreeFilterTwoIndex: '',
  });

  const [coursesAnalysisData, setCoursesAnalysisData] = useState({});
  const [isCoursesAnalysisDataLoading, setIsCoursesAnalysisDataLoading] = useState(false);
  const [coursesAnalysisFilters, setCoursesAnalysisFilters] = useState({ startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), region: '', state: '' });

  const [incentivePlanData, setIncentivePlanData] = useState({});
  const [isIncentivePlanDataLoading, setIsIncentivePlanDataLoading] = useState(false);
  const [incentivePlanFilters, setIncentivePlanFilters] = useState({ startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), bank: '', ageRange: '', gender: '', education: '', income: '' });

  const [engagementData, setEngagementData] = useState({});
  const [isEngagementDataLoading, setIsEngagementDataLoading] = useState(false);
  const [engagementFilters, setEngagementFilters] = useState({ startDate: getDashboardStartDate(), endDate: getDashboardEndDate(), persona: '', ageRange: '', gender: '', state: '', income: '' });

  const [consolidatedData, setConsolidatedData] = useState({});
  const [isConsolidatedDataLoading, setIsConsolidatedDataLoading] = useState(false);
  const [consolidatedFilters, setConsolidatedFilters] = useState({ startDate: getDashboardStartDate(), endDate: getDashboardEndDate() });

  const showDateAlert = () => {
    setAlert('A data final do perído não pode ser menor que a data inicial.');

    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const handleTabChange = (e, value) => {

    setCurrentTab(value);
    const wasTabAlreadyOpened = tabsOpened?.some(item => item === value);

    console.log('opened', tabsOpened)

    if (!wasTabAlreadyOpened) {
      setTabsOpened(array => [...array, value]);
      loadTabData(value);
    }
  };

  const loadTabData = async (value) => {
    switch (value) {
      case 0:
        await getStatisticalIndicatorsData();
        break;
      case 1:
        await getProfileAnalysisData();
        await getProfileAnalysisTreeData();
        break;
      case 2:
        await getCoursesAnalysisData();
        break;
      case 3:
        await getIncentivePlanData();
        break;
      case 4:
        await getEngagementData();
        break;
      case 5:
        await getConsolidatedData();
        break;
    }
  };

  const getStatisticalIndicatorsData = async () => {
    if (statisticalIndicatorsFilters.startDate > statisticalIndicatorsFilters.endDate) {
      showDateAlert();
    } else {
      setIsStatisticalIndicatorsDataLoading(true);
      const startDateFormated = getFilterStartDate(statisticalIndicatorsFilters.startDate);
      const endDateFormated = getFilterEndDate(statisticalIndicatorsFilters.endDate);

      let url = `${Globals.api.dashboardStatisticalIndicators}?startDate=${startDateFormated}&endDate=${endDateFormated}&device=${statisticalIndicatorsFilters.device}` +
        `&origin=${statisticalIndicatorsFilters.origin}&source=${statisticalIndicatorsFilters.source}&campaign=${statisticalIndicatorsFilters.campaign}` +
        `&region=${statisticalIndicatorsFilters.region}&state=${statisticalIndicatorsFilters.state}`;

      try {
        const response = await api.get(url);

        setStatisticalIndicatorsData(response.data);
        setIsStatisticalIndicatorsDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getProfileAnalysisData = async () => {
    if (profileAnalysisFilters.startDate > profileAnalysisFilters.endDate) {
      showDateAlert();
    } else {
      setIsProfileAnalysisDataLoading(true);
      const startDateFormated = getFilterStartDate(profileAnalysisFilters.startDate);
      const endDateFormated = getFilterEndDate(profileAnalysisFilters.endDate);

      let url = `${Globals.api.dashboardProfileAnalysis}?startDate=${startDateFormated}&endDate=${endDateFormated}&persona=${profileAnalysisFilters.persona}` +
        `&region=${profileAnalysisFilters.region}&state=${profileAnalysisFilters.state}&gender=${profileAnalysisFilters.gender}&macroSegmentation=${profileAnalysisFilters.macroSegmentation}`;

      try {
        const response = await api.get(url);

        setProfileAnalysisData(response.data);
        setIsProfileAnalysisDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getProfileAnalysisTreeData = async () => {
    if (profileAnalysisFilters.startDate > profileAnalysisFilters.endDate) {
      showDateAlert();
    } else {
      setIsProfileAnalysisTreeDataLoading(true);
      const startDateFormated = getFilterStartDate(profileAnalysisFilters.startDate);
      const endDateFormated = getFilterEndDate(profileAnalysisFilters.endDate);

      try {
        let url = `${Globals.api.dashboardProfileAnalysisTreeData}?startDate=${startDateFormated}&endDate=${endDateFormated}&persona=${profileAnalysisFilters.persona}` +
          `&region=${profileAnalysisFilters.region}&state=${profileAnalysisFilters.state}&gender=${profileAnalysisFilters.gender}&macroSegmentation=${profileAnalysisFilters.macroSegmentation}` +
          `&ageRange=${profileAnalysisTreeFilters.ageRange}&income=${profileAnalysisTreeFilters.income}&filterTwo=${profileAnalysisTreeFilters.filterTwo}` +
          `&filterThree=${profileAnalysisTreeFilters.filterThree}&genderTreeFilter=${profileAnalysisTreeFilters.genderTreeFilter}` +
          `&regionTreeFilter=${profileAnalysisTreeFilters.regionTreeFilter}&personaTreeFilter=${profileAnalysisTreeFilters.personaTreeFilter}`;

        const resp = await api.get(url);
        setProfileAnalysisTreeData(resp.data);
        setIsProfileAnalysisTreeDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getCoursesAnalysisData = async () => {
    if (coursesAnalysisFilters.startDate > coursesAnalysisFilters.endDate) {
      showDateAlert();
    } else {
      setIsCoursesAnalysisDataLoading(true);
      const startDateFormated = getFilterStartDate(coursesAnalysisFilters.startDate);
      const endDateFormated = getFilterEndDate(coursesAnalysisFilters.endDate);

      let url = `${Globals.api.dashboardCoursesAnalysis}?startDate=${startDateFormated}&endDate=${endDateFormated}&region=${coursesAnalysisFilters.region}&state=${coursesAnalysisFilters.state}`;

      try {
        const response = await api.get(url);

        setCoursesAnalysisData(response.data);
        setIsCoursesAnalysisDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getIncentivePlanData = async () => {
    if (incentivePlanFilters.startDate > incentivePlanFilters.endDate) {
      showDateAlert();
    } else {
      setIsIncentivePlanDataLoading(true);
      const startDateFormated = getFilterStartDate(incentivePlanFilters.startDate);
      const endDateFormated = getFilterEndDate(incentivePlanFilters.endDate);

      let url = `${Globals.api.dashboardIncentivePlan}?startDate=${startDateFormated}&endDate=${endDateFormated}&bank=${incentivePlanFilters.bank}` +
        `&ageRange=${incentivePlanFilters.ageRange}&gender=${incentivePlanFilters.gender}&education=${incentivePlanFilters.education}&income=${incentivePlanFilters.income}`;

      try {
        const response = await api.get(url);

        setIncentivePlanData(response.data);
        setIsIncentivePlanDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getEngagementData = async () => {
    if (engagementFilters.startDate > engagementFilters.endDate) {
      showDateAlert();
    } else {
      setIsEngagementDataLoading(true);
      const startDateFormated = getFilterStartDate(engagementFilters.startDate);
      const endDateFormated = getFilterEndDate(engagementFilters.endDate);

      let url = `${Globals.api.dashboardEngageament}?startDate=${startDateFormated}&endDate=${endDateFormated}&persona=${engagementFilters.persona}` +
        `&ageRange=${engagementFilters.ageRange}&gender=${engagementFilters.gender}&state=${engagementFilters.state}&income=${engagementFilters.income}`;

      try {
        const response = await api.get(url);

        setEngagementData(response.data);
        setIsEngagementDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const getConsolidatedData = async () => {
    if (consolidatedFilters.startDate > consolidatedFilters.endDate) {
      showDateAlert();
    } else {
      setIsConsolidatedDataLoading(true);
      const startDateFormated = getFilterStartDate(consolidatedFilters.startDate);
      const endDateFormated = getFilterEndDate(consolidatedFilters.endDate);

      let url = `${Globals.api.dashboardConsolidated}?startDate=${startDateFormated}&endDate=${endDateFormated}`;

      try {
        const response = await api.get(url);

        setConsolidatedData(response.data);
        setIsConsolidatedDataLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.message) {
          setErrorMessge(err?.response?.data?.error?.message);
        } else if (err?.response?.data) {
          setErrorMessge(err?.response?.data);
        } else {
          setErrorMessge(err?.message);
        }

        setOpenErrorDialog(true);
        console.log('error: ', err);
      }
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  useEffect(() => {
    if (isStatisticalIndicatorsDataLoading || isProfileAnalysisDataLoading ||
      isProfileAnalysisTreeDataLoading || isCoursesAnalysisDataLoading ||
      isIncentivePlanDataLoading || isEngagementDataLoading || isConsolidatedDataLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isStatisticalIndicatorsDataLoading,
    isProfileAnalysisDataLoading,
    isProfileAnalysisTreeDataLoading,
    isCoursesAnalysisDataLoading,
    isIncentivePlanDataLoading,
    isEngagementDataLoading,
    isConsolidatedDataLoading
  ]);

  useEffect(() => {
    loadTabData(currentTab);
  }, [
    statisticalIndicatorsFilters,
    coursesAnalysisFilters,
    incentivePlanFilters,
    engagementFilters,
    consolidatedFilters
  ]);

  useEffect(() => {
    if (currentTab === 1) {
      async function load() {
        await getProfileAnalysisData();
      }

      load();
    }
  }, [profileAnalysisFilters]);

  useEffect(() => {
    if (currentTab === 1) {
      async function load() {
        await getProfileAnalysisTreeData();
      }

      load();
    }
  }, [profileAnalysisTreeFilters]);

  return (
    <>
      <div className="dashboard-container">
        <div className="menu-container">
          <Tabs centered value={currentTab} onChange={handleTabChange}>
            {tabNames.map((item, index) => (
              <Tab label={item} value={index} disabled={isLoading} />
            ))}
          </Tabs>
        </div>
        <div className='responsive-menu-container'>
          {tabNames.map((item, index) => (
            <Button
              height="40px"
              icon='none'
              textAlign="center"
              type='darkBlue'
              disabled={isLoading}
              className={currentTab === index ? 'active' : ''}
              onClick={() => handleTabChange(null, index)}>{item}</Button>
          ))}
        </div>
        <div className="content-container">
          {errorMessage ? <div className='no-data'>Sem dados disponíveis.</div> :
            <>
              <TabPanel value={currentTab} index={0} padding={'0px'}>
                <StatisticalIndicators data={statisticalIndicatorsData}
                  isLoading={isStatisticalIndicatorsDataLoading}
                  filters={statisticalIndicatorsFilters}
                  setFilters={setStatisticalIndicatorsFilters}
                  alert={alert}></StatisticalIndicators>
              </TabPanel>
              <TabPanel value={currentTab} index={1} padding={'0px'}>
                <ProfileAnalysis data={profileAnalysisData}
                  isLoading={isProfileAnalysisDataLoading}
                  filters={profileAnalysisFilters}
                  setFilters={setProfileAnalysisFilters}
                  dataTree={profileAnalysisTreeData}
                  isTreeLoading={isProfileAnalysisTreeDataLoading}
                  treeFilters={profileAnalysisTreeFilters}
                  setTreeFilters={setProfileAnalysisTreeFilters}
                  alter={alert}></ProfileAnalysis>
              </TabPanel>
              <TabPanel value={currentTab} index={2} padding={'0px'}>
                <CoursesAnalysis data={coursesAnalysisData}
                  isLoading={isCoursesAnalysisDataLoading}
                  filters={coursesAnalysisFilters}
                  setFilters={setCoursesAnalysisFilters}
                  alert={alert}></CoursesAnalysis>
              </TabPanel>
              <TabPanel value={currentTab} index={3} padding={'0px'}>
                <IncentivePlan data={incentivePlanData}
                  isLoading={isIncentivePlanDataLoading}
                  filters={incentivePlanFilters}
                  setFilters={setIncentivePlanFilters}
                  alert={alert}></IncentivePlan>
              </TabPanel>
              <TabPanel value={currentTab} index={4} padding={'0px'}>
                <Engagement data={engagementData}
                  isLoading={isEngagementDataLoading}
                  filters={engagementFilters}
                  setFilters={setEngagementFilters}
                  alert={alert}></Engagement>
              </TabPanel>
              <TabPanel value={currentTab} index={5} padding={'0px'}>
                <Consolidated data={consolidatedData}
                  isLoading={isConsolidatedDataLoading}
                  filters={consolidatedFilters}
                  setFilters={setConsolidatedFilters}
                  alert={alert}></Consolidated>
              </TabPanel>
            </>
          }
        </div>
      </div>
      <Dialog
        open={openErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Erro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default watch(DashboardAnalytics, { user: '' });