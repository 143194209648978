import React from 'react';
import './alcoolGasolina.scss';
import CurrencyInput from 'react-currency-input';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import { Breadcrumbs, Typography, Grid, Hidden, Select, MenuItem, FormControl, CircularProgress, Tooltip, withStyles, Paper, Box, Dialog, DialogContent, IconButton, Divider } from '@material-ui/core';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import IconeCalculadora from '../../../../src/assets/image/icone-calculadora.png';


const Globals = require("../../../Globals.json");

class AlcoolGasolina extends React.Component {

  state = {
    alcool: 0,
    gasolina: 0,
    result: '',
  }



  calculate = () => {
    const pa = this.state.alcool;
    const pg = this.state.gasolina;

    if (isNaN(pa) || isNaN(pg) || pa === 0 || pg === 0) {
      alert('Por favor informa valores válidos para a conta.');
      return;
    }

    const res = pa / pg;

    this.setState({
      result: (res.toFixed(2) >= 0.7 ? 'Gasolina' : 'Álcool')
    })
  };



  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="tool_finances_breadCrumb">
          <Link color="inherit" to={Globals.paths.tools} className="tool_finances_breadCrumbLink">
            Ferramentas financeiras
          </Link>
          <Link color="inherit" to={Globals.paths.alcoolGasolina} className="tool_finances_breadCrumbLink">
            Calculadora Álcool x Gasolina
          </Link>
        </Breadcrumbs>
        <Helmet>
          <title>Ferramentas | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} sm={8} md={9} className="classContainertools">

            <Grid container className="toolsItem">
              <Box item xs={12} className="toolsTitle" >
                <h1>Calculadora Álcool x Gasolina</h1>
              </Box>


              <Box sx={{ paddingBottom: "16px" }} component="section" item className="info" xs={12}>

                <h2>Quando vale a pena usar um combustível ou outro?</h2>

                <p>A principal diferença entre os dois combustíveis está na proporção entre o preço e o desempenho de cada um.
                  Esta calculadora do Meu Bolso em Dia compara o preço do litro do álcool e da gasolina e mostra qual é o combustível mais vantajoso,
                  financeiramente, no momento..</p>

                <p>O etanol vale a pena quando custar <b>até 70%</b> do valor da gasolina</p>

                <p> <b>Preencha os campos abaixo com os preços dos combustíveis</b> que você encontra hoje nos postos e clique no botão “calcular”
                  para saber qual é a melhor opção.</p>
              </Box>

              <Grid container justifyContent="center" className="form-container">
                <Grid
                  container
                  justifyContent="center"
                  direction='column'
                  item
                  className="alcool_gasolina_container"
                  xs={12}
                >

                  <Box
                    container
                    xs={12}
                    sx={{ textAlign: "center", paddingTop: "24px" }}
                  >
                    <img src={IconeCalculadora} className="alcool_gasolina_img" />
                    <h2>Qual a melhor opção?</h2>
                  </Box>

                  <Grid item
                    direction="column"
                    justifyContent="center"
                    alignItems="center">

                    <div className="form_input">
                      <label>Álcool (preço por litro)</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        precision={2}
                        className="form-control"
                        placeholder="Valor"
                        value={this.state.alcool}
                        onChangeEvent={(e, mv, fv) => this.setState({
                          alcool: fv,
                        })}
                      />
                    </div>

                    <div className="form_input">
                      <label>Gasolina (preço por litro)</label>
                      <CurrencyInput
                        prefix="R$ "
                        ref={null}
                        decimalSeparator=","
                        thousandSeparator=","
                        placeholder="Valor"
                        value={this.state.gasolina}
                        onChangeEvent={(e, mv, fv) => this.setState({ gasolina: fv })}
                      />
                    </div>

                    <div className="form_input">
                      <button
                        className="btn btn-default btn-primary btn-block"
                        onClick={() => this.calculate()}
                      >
                        Calcular
                      </button>
                    </div>
                  </Grid>
                </Grid>
                {!!this.state.result && (

                  <>
                    <Grid xs={12} className="result">
                      <Box component='p'>Vale mais a pena usar {this.state.result}!</Box>
                    </Grid>

                    <Box component="div" sx={{ border: "1px solid #202020", py: "16px", px: "40px", marginTop: "24px" }} >
                      <p><strong>Entenda como o cálculo é feito</strong></p>

                      <p>Dividimos o valor do litro do álcool pelo da gasolina.</p>
                      <p>Quando o resultado é menor que 0,7, a recomendação é abastecer com álcool. Se maior, a recomendação é escolher a gasolina. </p>
                      <p>Exemplo: se o álcool custa R$ 3,29 e a gasolina R$ 4,92, o resultado da divisão do primeiro pelo segundo é 0,67, menor que 0,7. Portanto, é mais vantajoso abastecer com álcool.</p>
                    </Box>
                  </>)}

              </Grid >
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={4} md={3}>

              <ToolbarItem tooltip="
               O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
               e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
               vantagens exclusivas para os usuários."  title="Seu Nível" color="#05CCAE">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>
            </Grid>
          </Hidden>



        </Grid>
      </>
    )

  }
}

export default watch((AlcoolGasolina), { user: '' });

