import React, { useEffect, useState } from 'react';
import {
  Grid,
  Snackbar

} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseRounded } from '@material-ui/icons';
import Dialogs from '../../../../services/dialogs';
import '../../../../backoffice.scss';
import './objectivesMatrixItems.scss';
import PaginatedTable from '../components/PaginatedTable/PaginatedTable';

import api from "../../../../services/api";
import { useHistory } from 'react-router-dom';
import Button from '../../button/Button';

const Globals = require("../../../../Globals.json");


const ObjectivesMatrixItems = () => {

  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [seachQuery, setSeachQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const search = () => {
    setSeachQuery(`filter_by=${filterBy}`);
  }
  const handleOpenMessage = () => {
    setOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loadData = async (page = 1) => {
    setIsLoading(true);
    try {
      await api.get(`${Globals.api.typeObjectivesMatricesItems}?per_page=10&page=${page}&${seachQuery}`)
        .then((res) => {
          setData(res.data)
        })
    } catch (error) {
      console.log('erro loaddata')
    }
    setIsLoading(false);
  }
  const clear = () => {
    setFilterBy('')
    setSeachQuery('')
  }

  const navigateTo = (path) => {
    history.push(path);
  }


  const handleDeleteMatrixItem = (path) => {
    Dialogs.show(
      <div>EXCLUIR</div>,
      'Tem certeza que gostaria de EXCLUIR o item da matriz?',
      [
        {
          label: 'Cancelar',
        },
        {
          label: "EXCLUIR",
          onClick: () => deleteMatrixItem(path),
        },
      ]
    )
  }
  const deleteMatrixItem = async (path) => {
    setIsLoading(true)
    try {
      await api.delete(path)
        .then(() => {
          setSeverity('success')
          setAlertMessage('O item da Matriz foi excluida com sucesso')
          handleOpenMessage()
          loadData()
        })

    } catch (error) {
      
      setSeverity('error')
      setAlertMessage(typeof (error?.response?.data) === 'string' ? error.response.data : 'Não foi possível excluir o Item da Matriz')
      handleOpenMessage()
    } finally {
      setIsLoading(false)
    }

  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seachQuery]);

  const fieldsTable = [{ title: 'Nome', fieldName: 'name' }, { title: 'Matriz vinculada', fieldName: 'TypeObjectivesMatrix.name' }, { title: 'Data de criação', fieldName: 'created_at', type: 'date' }]
  const actions = [{ name: 'Editar', onClick: navigateTo, path: Globals.paths.backofficeEditObjectivesMatrixItems.replace(':objectiveItemId', '') }, { name: 'Excluir', onClick: handleDeleteMatrixItem, path: Globals.api.typeObjectivesMatricesItemsDetail.replace(':objectiveItemId', '') }];

  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseMessage} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid className="backoffice-content">
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Itens das Matrizes de objetivos</span>
          </div>
          <Grid item container >

            <div className="filter-container-financial-photography">
              Buscar
              <div className="searchbar">
                <div className="filter-elements">
                  <input
                    className="filter-element"
                    placeholder="Digite um nome"
                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                  />

                  <button
                    className="filter-element-clear"
                    onClick={() => clear()}
                  >
                    <CloseRounded />
                  </button>

                  <button
                    className="filter-element-search"
                    onClick={() => search()}
                  >
                    {'Buscar'}
                  </button>
                </div>

                <div className="button-right-add">
                  <Button
                    icon="none"
                    textAlign="center"
                    type={'full-solid'}
                    height="40px"
                    to={Globals.paths.backofficeEditObjectivesMatrixItems.replace(':objectiveItemId', 'new')}
                  >
                    {'Novo item de objetivo'}
                  </Button>
                </div>
              </div>

            </div>

            <PaginatedTable
              titleFields={fieldsTable} data={data} isLoading={isLoading} pagNavigation={loadData} actions={actions}/>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ObjectivesMatrixItems;

