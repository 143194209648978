
import React, { Component } from 'react';

import AdjustIcon from '@material-ui/icons/Adjust';
import Button from '../../components/button/Button';
import { Grid, Hidden } from '@material-ui/core';
import { Helmet } from "react-helmet";
import moment from 'moment';
import Trash from '../../../assets/icon/trash_full.png'
import SubLeft from '../../../assets/icon/sub_left.png'
import { watch } from 'redux-easy/lib/redux-easy';

import AlertErrors from '../../components/alertErrors/alerts';
// import HelperError from '../../../helpers/functions';
import './inbox.scss';

import api from '../../../services/api';

const Globals = require("../../../Globals.json");

class Inbox extends Component {
  state = {
    message: [],
    messageShow: false,
    messageId: false,
    messageData: false,
    messageThumbnail: '',
    messageTitle: '',
    messageContent: '',
    messageLida: false,
    messagewarning: false,
    displayInbox: true,
    messageText: '',
    showMessageSuporte: false,
    showMessageSuporteMobile: false,
    contentMessageSupporte: 'Olá #name#, tudo bom? Antes de enviar uma mensagem, por favor, escolha abaixo um dos assunstos desejados para que sua questão chegue ao setor correto e você receba um retorno mais rapidamente.',
    messageSuportOptions: '',
    messaOk: true,
    childrens: [],
    thread: [],
    parentId: '',
    touUserId: '',
    parentIdSup: '',
    usernameTo: '',
    usernameFrom: '',
    alertError: '',
    nameAvatar: ''
  }

  async handleClick(id, message, title, createdAt, image, toUser) {
    this.setState({ parentId: '', toUserId: '' })
    console.log('Abrir mensage aqui...', id,)
    console.log('mandou aqui..', this.state.childrens)
    this.messageLida(id)
    try {
      const response = await api.get(Globals.api.messageLog + `/${id}`);
      console.log('***', response.data)
      this.setState({
        messageTitle: title,
        messageData: createdAt,
        messageContent: message,
        messageShow: true,
        showMessageSuporte: false,
        childrens: response.data.childrens,
        thread: response.data.thread,
        parentId: id,
        toUserId: toUser
      })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleInbox() {
    this.setState({ displayInbox: !this.state.displayInbox })
  }

  async handleClickMobile(id, message, title, createdAt, image, toUser) {
    console.log('Abrir mensage Mobile...', id,)
    this.setState({ parentId: '', toUserId: '', username: '' })
    try {
      const response = await api.get(Globals.api.messageLog + `/${id}`);
      console.log('****', response.data)
      this.handleInbox()
      // // this.messageLida(id)
      this.setState({
        messageTitle: title,
        messageData: createdAt,
        messageContent: message,
        displayInbox: false,
        childrens: response.data.childrens,
        thread: response.data.thread,
        usernameFrom: response.data.thread.from_user.username,
        usernameTo: response.data.thread.to_user.username,
        parentId: id,
        toUserId: toUser
      })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  messageLida = async (id) => {
    const data = { message_status_id: 3 }
    await api.put(Globals.api.messageLog + `/${id}`, data).then(response => {
      this.loadMessages()
    }).catch(error => {
      this.setState({ alertError: error?.response?.status })
      this.loadMessages()
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    })
  }

  async loadMessages() {
    try {
      const response = await api.get(Globals.api.messageLog);
      console.log('response', response.data)
      if (this.props.user.profile.id === 344) {
        this.setState({ message: response.data.recebidas, nameAvatar: '' })
      }
      else {
        this.setState({ message: response.data.enviadas })
        this.setState({ nameAvatar: 'SU' })
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handTalkSupport() {
    console.log('Falar com o suporte')
    this.setState({ showMessageSuporte: true, showMessage: true })
  }

  handTalkSupportMobile() {
    this.setState({ showMessageSuporteMobile: true, displayInbox: false })
  }

  async handleMarkAllRead() {
    console.log('Marcando tudo lido...')
    let data;
    if (this.props.user.profile.id === 344) {
      data = { "recebidas": true }
    }
    else {
      data = { "enviadas": true }
    }
    console.log('data??', data)
    try {
      const response = await api.post(Globals.api.setDeals, data);
      this.loadMessages()
      console.log('Marcou tudo como lido?: ', response.data)
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  async handleDeleteAll() {
    console.log('Deletando todas mensagens...')
    let data;
    if (this.props.user.profile.id === 344) {
      data = { "recebidas": true }
    }
    else {
      data = { "enviadas": true }
    }
    try {
      const response = await api.post(Globals.api.deleteAll, data);
      console.log('Deletou todas mensagens? ', response.data)
      this.loadMessages()
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async handleDeleteMsg(id) {
    console.log('deletando mensagem...', id)
    try{
      await api.delete(Globals.api.messageLog + `/${id}`);
      this.loadMessages()
    }catch(error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async handleOtionSuport(id) {
    let optionText;
    if (id === 1) {
      optionText = 'Cursos e Conteudos'
      this.setState({ messageSuportOptions: 'Cursos e Conteudos' })
    } else if (id === 2) {
      optionText = 'Meu Progresso'
      this.setState({ messageSuportOptions: 'Meu Progresso' })
    } else if (id === 3) {
      optionText = 'Suporte Técnico'
      this.setState({ messageSuportOptions: 'Suporte Técnico' })
    } else if (id === 4) {
      optionText = 'Encontrei um erro'
      this.setState({ messageSuportOptions: 'Encontrei um erro' })
    } else if (id === 5) {
      optionText = 'Outros'
      this.setState({ messageSuportOptions: 'Outros' })
    }
    const data = {
      "to_user_id": 344,
      "message_status_id": 2,
      "title": "Aprenda a organizar suas finanças",
      "image": this.props.user.profile.user_avatar,
      "message": `Você escolheu ${optionText}. Como podemos te ajudar em relação a esse assunto?`
    }
    try {
      const response = await api.post(Globals.api.messageLog, data);
      console.log('response support first', response.data.id)
      this.setState({ parentIdSup: response.data.id })
      this.loadMessages()
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async handleSendMessage() {
    console.log('mandou aqui..')
    const data = {
      "to_user_id": this.state.toUserId,
      "message_status_id": 2,
      "title": this.state.messageText,
      "image": this.props.user.profile.user_avatar,
      "message": this.state.messageText,
      "parent_id": this.state.parentId
    }
    try {
      const response = await api.post(Globals.api.messageLog, data);
      console.log('response message,', response.data)
      this.loadMessages();
      this.setState({ messageText: '' })
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async handleSendMessageSuporte() {

    const data = {
      "to_user_id": 344,
      "message_status_id": 2,
      "title": this.state.messageText,
      "image": this.props.user.profile.user_avatar,
      "message": this.state.messageText,
      "parent_id": this.state.parentIdSup
    }
    try {
      const response = await api.post(Globals.api.messageLog, data);
      console.log('response message,', response.data)
      this.setState({ messageText: '' })
      this.loadMessages();
    } catch (error) {
      this.setState({ alertError: error?.response?.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount() {
    this.loadMessages();

    moment.locale('pt-br')
    moment.updateLocale('pt', {
      months: [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ]
    });
  }

  render() {

    return (
      <>
        <Grid container >
          <Helmet>
            <title>Mensagens | Meu bolso em dia</title>
          </Helmet>

          <Hidden xsDown>
            <Grid item xs={12} sm={12} md={12} className="containerInbox">
              <AlertErrors error={this.state.alertError} />
              <Grid item className="containerGreen">
                <span>Caixa de entrada</span>
                {this.props.user.profile.id !== 344 &&
                  <Button icon="none" width="170px" height="35px" type="invert" textAlign="center" onClick={() => this.handTalkSupport()}>fale com o suporte</Button>
                }
              </Grid >
              <div className="container_principal">
                <div className="inboxBoxLeft">
                  <div className="inboxHeaderBoxLeft">
                    <div className="inboxContainerBtn">
                      <Button icon="none" width="160px" type="solid" textAlign="center" onClick={() => this.handleMarkAllRead()}>marcar como lido</Button>
                      {this.props.user.profile.id !== 344 &&
                        <Button icon="none" width="160px" type="orange" textAlign="center" onClick={() => this.handleDeleteAll()}>excluir todas</Button>
                      }
                    </div>
                    <div className="inboxHr"></div>
                  </div>
                  <div className="inboxPosts">
                    {this.state.message !== undefined ?
                      this.state.message.map((item, index) => (
                        <div key={index}>
                          {item.warnig ?
                            <div className="inboxContainerMsg">
                              <div className="inboxMsg warning" onClick={() => this.handleClick(item.id, item.message, item.title, item.created_at, item.image, item.to_user_id)}>
                                <div className="containerIconsMessage">
                                  <div className="inboxMsgTitle">
                                    {item.image ?
                                      <img src={item.image} alt="Avatar" />
                                      : <div className="inboxMsgNameAvatar">
                                        {this.state.nameAvatar || item.from_user.username.substr(0, 2)}
                                      </div>}
                                    {item.title}
                                  </div>
                                </div>
                                <div className="inboxMsgDate">{moment(item.created_at).format('DD[ de ] MMMM YYYY')}</div>
                              </div>
                              <div className="inboxMsgSide warning">
                                <div onClick={() => this.handleDeleteMsg(item.id)}>
                                  {this.props.user.profile.id !== 344 &&
                                    <img src={Trash} alt="Delete" />
                                  }
                                </div>
                              </div>
                            </div>
                            :
                            <div className="inboxContainerMsg">
                              <div className={`inboxMsg ${item.status.type}`} onClick={() => this.handleClick(item.id, item.message, item.title, item.created_at, item.image, item.to_user_id)}>
                                <div className="containerIconsMessage">
                                  <div className="inboxMsgTitle">
                                    {item.image ?
                                      <img src={item.image} alt="Avatar" />
                                      : <div className="inboxMsgNameAvatar">
                                        {this.state.nameAvatar || item.from_user.username.substr(0, 2)}
                                      </div>}
                                    {item.title}
                                  </div>
                                </div>
                                <div className="inboxMsgDate">{moment(item.created_at).format('DD[ de ] MMMM YYYY')}</div>
                              </div>
                              <div className={`inboxMsgSide ${item.status.type}`}>
                                <div onClick={() => this.handleDeleteMsg(item.id)}>
                                  {this.props.user.profile.id !== 344 &&
                                    <img src={Trash} alt="Delete" />
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                      : <div className="default-message">
                        <h3>Você não possui mensagens</h3>
                      </div>
                    }
                  </div>
                </div>

                <div className="inboxBoxRight">
                  {this.state.showMessageSuporte ?
                    <>
                      <div className="inboxHeaderBoxRight">
                        <div className="inboxBoxRightTitle1">
                          <AdjustIcon style={{ marginRight: "10px" }} />
                          Aprenda a organizar suas finanças
                        </div>
                      </div>

                      <div className="inboxRightContent">
                        <div className="inboxText">
                          <div className="message">{this.state.contentMessageSupporte.replace("#name#", this.props.user.profile.username)}  <br /> <br />
                            <div onClick={() => this.handleOtionSuport(1)}>- Cursos e conteudo</div> <br />
                            <div onClick={() => this.handleOtionSuport(2)}>- Meu Progresso</div> <br />
                            <div onClick={() => this.handleOtionSuport(3)}>- Suporte técnico</div> <br />
                            <div onClick={() => this.handleOtionSuport(4)}>- Encontrei um erro</div> <br />
                            <div onClick={() => this.handleOtionSuport(5)}>- Outros</div> <br />
                          </div>
                          {this.state.messageSuportOptions !== '' &&
                            <div className="message received">
                              Você escolheu <b>{this.state.messageSuportOptions}</b>. <br />
                              Como podemos te ajudar em relação a esse assunto?
                          </div>}

                        </div>
                        <div className="inboxConaterSendMessage">
                          <textarea
                            value={this.state.messageText}
                            type='text'
                            className="perfilInputText2"
                            placeholder="Escrever mensagem"
                            onChange={(e) => this.setState({ messageText: e.target.value })}
                          />
                          <div className="containerBtnSendMessage">
                            <Button
                              icon="none"
                              width="160px"
                              type="darkBlue"
                              textAlign="center"
                              onClick={() => this.handleSendMessageSuporte()}>
                              enviar
                            </Button>
                          </div>
                        </div>
                      </div>
                    </> :
                    <>
                      {this.state.messageShow ?
                        <>
                          <div className="inboxHeaderBoxRight">
                            <div className="inboxBoxRightTitle1">
                              {this.state.thread.image ?
                                <img src={this.state.thread.image} alt="Avatar" /> :
                                <div className="inboxBoxRightAvatarname">
                                  {this.props.user.profile.id !== 344 ?
                                    this.state.thread.to_user.username.substr(0, 2) : this.state.thread.from_user.username.substr(0, 2)}
                                </div>
                              }
                              {this.props.user.profile.id !== 344 ? this.state.thread.to_user.username : this.state.thread.from_user.username}

                            </div>
                          </div>

                          <div className="inboxRightContent">
                            <div className="inboxText">
                              <div className="message received">
                                {this.state.thread.message}
                              </div>
                              {this.state.childrens.map((item, index) => (
                                item.from_user_id === this.props.user.profile.id ?
                                  <div key={index} className="message">
                                    {item.message}
                                  </div> :
                                  <div key={index} className="message received">
                                    {item.message}
                                  </div>
                              ))}
                            </div>
                            <div className="inboxConaterSendMessage">
                              <textarea
                                value={this.state.messageText}
                                type='text'
                                className="perfilInputText2"
                                placeholder="Escrever mensagem"
                                onChange={(e) => this.setState({ messageText: e.target.value })}
                              />
                              <div className="containerBtnSendMessage">

                                <Button
                                  icon="none"
                                  width="160px"
                                  type="darkBlue"
                                  textAlign="center"
                                  onClick={() => this.handleSendMessage()}>
                                  enviar
                                </Button>
                              </div>
                            </div>
                          </div>
                        </> :
                        <div className="default-message">
                          <h2>Selecione uma mensagem</h2>
                        </div>
                      }
                    </>}
                </div>
              </div>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <div className="containerInboxMobile">

              <Grid item className="containerGreenMobile">
                <div className="teste">
                  <div>Caixa de entrada</div>
                  {this.props.user.profile.id !== 344 &&
                    <Button icon="none" width="140px" height="35px" type="invert" textAlign="center" onClick={() => this.setState({ displayInbox: false, showMessageSuporteMobile: true })}>fale com o suporte</Button>
                  }
                </div>
              </Grid >

              <div className="containerPrincipalMobile">
                {this.state.displayInbox ?
                  <>
                    <div className="inboxMobile">
                      <div className="inboxHeaderMobile">
                        <div className="inboxContainerBtnMobile">
                          <Button icon="none" width="125px" type="solid" textAlign="center" onClick={() => this.handleMarkAllRead()}>marcar como lido</Button>
                          {this.props.user.profile.id !== 344 &&
                            <Button icon="none" width="125px" type="orange" textAlign="center" onClick={() => this.handleDeleteAll()}>excluir todas</Button>
                          }
                        </div>
                        <div className="inboxHrMobile"></div>
                      </div>

                      <div className="inboxPostsMobile">
                        {this.state.message !== undefined ?
                          this.state.message.map((item, index) => (
                            <div key={index}>
                              {item.warnig ?
                                <div className="inboxContainerMsgMobile">
                                  <div className="inboxMsgMobile warning" onClick={() => this.handleClickMobile(item.id, item.message, item.title, item.created_at, item.image, item.to_user_id)}>
                                    <div className="containerIconsMessageMobile">
                                      <div className="inboxMsgTitleMobile">
                                        {item.image ?
                                          <img src={item.image} alt="Avatar" /> :
                                          <div className="inboxMsgNameAvatarMobile"> {this.state.nameAvatar ||
                                            item.from_user.username.substr(0, 2)}
                                          </div>}
                                        {item.title}
                                      </div>
                                    </div>
                                    <div className="inboxMsgDateMobile">{moment(item.created_at).format('DD[ de ] MMMM YYYY')}</div>
                                  </div>
                                  <div className="inboxMsgSideMobile warning">
                                    <div onClick={() => this.handleDeleteMsg(item.id)}>
                                      {this.props.user.profile.id !== 344 &&
                                        <img src={Trash} alt="Delete" style={{ margin: '10px' }} />
                                      }
                                    </div>
                                  </div>
                                </div> :

                                <div className="inboxContainerMsgMobile">
                                  <div className={`inboxMsgMobile ${item.status.type}`} onClick={() => this.handleClickMobile(item.id, item.message, item.title, item.created_at, item.image, item.to_user_id)}>
                                    <div className="containerIconsMessageMobile">
                                      <div className="inboxMsgTitleMobile">

                                        {item.image ?
                                          <img src={item.image} alt="Avatar" /> :
                                          <div className="inboxMsgNameAvatarMobile"> {this.state.nameAvatar ||
                                            item.from_user.username.substr(0, 2)}
                                          </div>}
                                        {item.title}
                                      </div>
                                    </div>
                                    <div className="inboxMsgDateMobile">{moment(item.created_at).format('DD[ de ] MMMM YYYY')}</div>
                                  </div>
                                  <div className={`inboxMsgSideMobile ${item.status.type}`}>
                                    <div onClick={() => this.handleDeleteMsg(item.id)}>
                                      {this.props.user.profile.id !== 344 &&
                                        <img src={Trash} alt="Delete" style={{ margin: '10px' }} />
                                      }
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          )) :
                          <h3>Você não possui mensagens</h3>
                        }
                      </div>
                    </div>
                  </> :
                  <>
                    {this.state.showMessageSuporteMobile ?
                      <>
                        <div className="inboxBoxMessageMobile">
                          <div className="inboxHeaderBoxMobile">
                            <div className="inboxBoxMessageTitle1">
                              <div>
                                <AdjustIcon style={{ marginRight: "10px" }} />
                                Suporte febraban
                              </div>
                              <div onClick={() => this.setState({ displayInbox: true, showMessageSuporteMobile: false })}>
                                <img src={SubLeft} alt="Voltar" />
                              </div>
                            </div>
                            <div className="inboxBoxMessageTitle2">{this.state.messageData}</div>
                          </div>

                          <div className="inboxContentMobile">
                            <div className="inboxTextMobile">
                              <div className="message">{this.state.contentMessageSupporte.replace("#name#", this.props.user.profile.username)}  <br /> <br />
                                <div onClick={() => this.handleOtionSuport(1)}>- Cursos e conteudo</div> <br />
                                <div onClick={() => this.handleOtionSuport(2)}>- Meu Progresso</div> <br />
                                <div onClick={() => this.handleOtionSuport(3)}>- Suporte técnico</div> <br />
                                <div onClick={() => this.handleOtionSuport(4)}>- Encontrei um erro</div> <br />
                                <div onClick={() => this.handleOtionSuport(5)}>- Outros</div> <br />
                              </div>
                              {this.state.messageSuportOptions !== '' &&
                                <div className="message received">
                                  Você escolheu <b>{this.state.messageSuportOptions}</b>. <br />
                            Como podemos te ajudar em relação a esse assunto?
                          </div>}
                            </div>
                            <div className="inboxConaterSendMessageMobile">
                              <textarea
                                value={this.state.messageText}
                                type='text'
                                className="perfilInputText2Mobile"
                                placeholder="Escrever mensagem"
                                onChange={(e) => this.setState({ messageText: e.target.value })}
                              />
                              <div className="containerBtnSendMessageMobile">
                                <Button
                                  icon="none"
                                  width="80px"
                                  minWidth="80px"
                                  type="darkBlue"
                                  textAlign="center"
                                  onClick={() => this.handleSendMessageSuporte()}>
                                  enviar
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="inboxBoxMessageMobile">
                          <div className="inboxHeaderBoxMobile">
                            <div className="inboxBoxMessageTitle1">
                              <div className="Title1BoxMobile">
                                {this.state.thread.image ?
                                  <img src={this.state.thread.image} alt="Avatar" /> :
                                  <div className="Title1BoxMobileAvatarName">
                                    {this.props.user.profile.id !== 344 ?
                                      this.state.usernameTo.substr(0, 2) : this.state.usernameFrom.substr(0, 2)}
                                  </div>
                                }
                                {this.props.user.profile.id !== 344 ? this.state.usernameTo : this.state.usernameFrom}
                              </div>
                              <div onClick={() => this.handleInbox()}>
                                <img src={SubLeft} alt="Voltar" />
                              </div>
                            </div>
                            <div className="inboxBoxMessageTitle2">{this.state.messageData}</div>
                          </div>

                          <div className="inboxContentMobile">
                            <div className="inboxTextMobile">
                              <div className="message received">
                                {this.state.thread.message}
                              </div>
                              {this.state.childrens.map((item, index) => (
                                item.from_user_id === this.props.user.profile.id ?
                                  <div key={index} className="message">
                                    {item.message}
                                  </div> :
                                  <div key={index} className="message received">
                                    {item.message}
                                  </div>
                              ))}
                            </div>
                            <div className="inboxConaterSendMessageMobile">
                              <textarea
                                value={this.state.messageText}
                                type='text'
                                className="perfilInputText2Mobile"
                                placeholder="Escrever mensagem"
                                onChange={(e) => this.setState({ messageText: e.target.value })}
                              />
                              <div className="containerBtnSendMessageMobile">
                                <Button
                                  icon="none"
                                  width="80px"
                                  minWidth="80px"
                                  type="darkBlue"
                                  textAlign="center"
                                  onClick={() => this.handleSendMessage()}>
                                  enviar
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>}
                  </>
                }
              </div>
            </div>

          </Hidden>
        </Grid >
      </>
    )
  }
}
export default watch(Inbox, { user: '' });
