import React, { Component } from 'react';
import './profile.scss';

import { Helmet } from 'react-helmet';
import { dispatch, watch } from 'redux-easy/lib/redux-easy';
import {
  Grid,
  TextField,
  MenuItem,
  IconButton,
  CircularProgress,
  Hidden,
  FormControl,
  AppBar,
  Tabs,
  Tab,
  Switch,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions

} from '@material-ui/core';

import Dialogs from '../../../services/dialogs';

import api from '../../../services/api';

import Alert from '@material-ui/lab/Alert';
import MaskedInput from 'react-text-mask';

import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../../components/button/Button';
import Favorite from '../../components/sidebar/Favorite';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import HistoryPerfil from '../../components/profile/HistoryPerfil/HistoryPerfil';
import LastCertificate from '../../components/sidebar/LastCertificate';
import LastTrophy from '../../components/sidebar/LastTrophy';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

import { Link } from 'react-router-dom';
import { getNextIndexAssessmentDateText } from '../../../services/util';
import SmallProfileGauge from '../../components/profileGauge/SmallProfileGauge';
import { enumTypeUserStatuses } from '../../../services/enums';
import ProfileModal from '../profile-modal/ProfileModal';
import ProfileRemoveModal from '../profile-modal/ProfileRemoveModal';
import CitiesAutoComplete from './CitiesAutoComplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import TabPanel from '../../../shared/components/TabPanel';
import { FileCopy } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RelationshipModal from './RelationshipModal/RelationshipModal';




const Globals = require('../../../Globals.json');

class Profile extends Component {
  invalidCpfs = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '5555555555',
    '666666666666',
    '777777777777',
    '888888888888',
    '99999999999',
  ];

  state = {
    currentTab: 0,
    fieldsValidation: {},
    address: '',
    avatarInitials: '',
    cpf: '',
    email: '',
    userLevel: 0,
    type_gender_id: 0,
    birth_date: null,
    confirmEmail: '',
    edit: false,
    canEditEmail: false,
    canEditCpf: false,
    isLoading: true,
    loading: false,
    optinDataLoading: false,
    optinData: [],
    citiesLoading: false,
    cep: '',
    name: '',
    phone: '',
    smsPhone: '',
    wppPhone: '',
    status: '',
    alertError: '',
    selectedState: 0,
    states: [],
    selectedCity: {
      value: 0,
      label: '',
    },
    cities: [],
    openAlert: false,
    successMessage: '',
    nextIndexAssessmentDateText: '',
    firstIndexMustBeFilled: true,
    profileModalOpened: false,
    ProfileRemoveModalOpened: false,
    CadUserAddressesOne: {
      landmark: '',
      cad_ibge_county_ibge_code: '',
      cad_ibge_state_ibge_code: '',
      zip_code: '',
      CadIbgeCounty: {
        ibge_code: 0,
        county: '',
      },
      CadIbgeState: {
        ibge_code: 0,
        state: '',
      },
    },
    tenants: '',
    relationshipModalOpened: false,
    clickedTenant: null,
    isLoadingTenants: false,
    dialogData: {
      isOpened: false,
      title: '',
      message: '',
      buttons: [{}]
    },
    firstLoad: true,
  };

  getStateName = (selectedState) => {
    let name = '';

    if (selectedState) {
      const findedState = this.state.states.find((state) => {
        return state.value === selectedState;
      });

      if (findedState) {
        name = findedState.label;
      }
    }
    return name;
  };

  loadOptinData = async () => {
    if (this.props.user) {
      this.setState({ optinDataLoading: true });

      try {
        const optinData = (await api.get(Globals.api.my_media_types_optin)).data.medias_opt;
        this.setState({ optinDataLoading: false, optinData });
      } catch (error) {
        console.log('error loadOptinData');
        this.setState({ optinDataLoading: false });
      }
    }
  };

  loadTenants = async () => {
    this.setState({ isLoadingTenants: true });
    try {
      const tenants = (await api.get(Globals.api.getTenants)).data;
      this.setState({
        isLoadingTenants: false,
        tenants: tenants,
      });
    } catch (error) {

    }
    this.setState({ firstLoad: false });
  }

  async loadData() {
    if (this.props.user) {
      this.setState({ isLoading: true });

      const userProfile = this.props.user.profile;
      try {
        this.setState({
          firstIndexMustBeFilled:
            userProfile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled,
          nextIndexAssessmentDateText: await getNextIndexAssessmentDateText(),
          canEditEmail: userProfile.account_status === 'pending',
          canEditCpf: !userProfile.cpf_cnpj,
        });

        const states = (await api.get(Globals.api.states)).data;

        let fullCpf = userProfile.cpf_cnpj;
        let fullName = userProfile.username;

        let phone = userProfile.phone_number;
        let smsPhone = userProfile.smsPhone;
        let wppPhone = userProfile.wppPhone;

        let coins = userProfile.progress.total_moedas;

        const formattedCpf = !!fullCpf
          ? fullCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
          : '';

        if (fullName.indexOf(' ') === -1) fullName += ' ';
        const initials = fullName.match(/^(.).* (.)?/);

        const formattedPhone = !!phone ? (phone.replace ? phone : '').replace(/(\d{2})/, '$1 ') : '';
        smsPhone = !!smsPhone ? (smsPhone.replace ? smsPhone : '').replace(/(\d{2})/, '$1 ') : '';
        wppPhone = !!wppPhone ? (wppPhone.replace ? wppPhone : '').replace(/(\d{2})/, '$1 ') : '';

        const CadUserAddressesOne = this.state.CadUserAddressesOne;
        const selectedCity = this.state.selectedCity;
        let selectedState = this.state.selectedState;

        if (userProfile.CadUserAddressesOne) {
          CadUserAddressesOne.landmark = userProfile.CadUserAddressesOne.landmark;
          CadUserAddressesOne.cad_ibge_state_ibge_code =
            userProfile.CadUserAddressesOne.cad_ibge_state_ibge_code;
          CadUserAddressesOne.cad_ibge_county_ibge_code =
            userProfile.CadUserAddressesOne.cad_ibge_county_ibge_code;
          CadUserAddressesOne.zip_code = userProfile.CadUserAddressesOne.zip_code;

          if (userProfile.CadUserAddressesOne.CadIbgeCounty) {
            selectedCity.value = userProfile.CadUserAddressesOne.CadIbgeCounty.ibge_code;
            selectedCity.label = userProfile.CadUserAddressesOne.CadIbgeCounty.county;

            CadUserAddressesOne.CadIbgeCounty = userProfile.CadUserAddressesOne.CadIbgeCounty;
          }

          if (userProfile.CadUserAddressesOne.CadIbgeState) {
            CadUserAddressesOne.CadIbgeState = userProfile.CadUserAddressesOne.CadIbgeState;
            selectedState = CadUserAddressesOne.cad_ibge_state_ibge_code;
          }
        }

        this.setState({
          name: userProfile.username,
          phone: formattedPhone,
          smsPhone: smsPhone,
          wppPhone: wppPhone,
          email: userProfile.email,
          confirmEmail: userProfile.email,
          type_gender_id: userProfile.type_gender_id ?? 0,
          birth_date: userProfile.birth_date
            ? userProfile.birth_date.replace('T00:00:00.000Z', 'T03:00:00.000Z')
            : null,
          cpf: formattedCpf,
          status: userProfile.account_status,
          avatarInitials: initials[1] + (initials[2] ? initials[2] : ''),
          user_avatar: userProfile.user_avatar,
          coins: coins,
          states,
          isLoading: false,
          CadUserAddressesOne,
          selectedState,
          selectedCity,
          userLevel: this.props.user.profile.progress.level,

        });
      } catch (error) {
        console.log('error loaddata')
      }

    } else {
      dispatch('updateUserProfile');
    }
  }

  async componentDidMount() {
    await this.loadTenants()
    await this.loadData();
    await this.loadOptinData();
    if (this.props.history.location.state && this.props.history.location.state.fromIncentive) {
      this.handleChangeTab(null, 2)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user == null) {
      this.loadData();
      this.loadOptinData();
      this.loadTenants()
    }
  }

  closeWelcomeModal = () => {
    this.setState({ profileModalOpened: false });
  };

  closeProfileRemoveModal = () => {
    this.setState({ ProfileRemoveModalOpened: false });
  };

  closeRelationshipModal = () => {
    this.setState({ relationshipModalOpened: false });
  };

  showSuccessAlert = (message) => {
    this.setState({ openAlert: true, successMessage: message });
    setTimeout(() => {
      return this.setState({ openAlert: false, successMessage: '' });
    }, 4500);
  };

  sendVerificationEmail = async () => {
    try {
      this.setState({ loading: true });

      await api.post(Globals.api.sendVerificationEmail);
      this.showSuccessAlert('E-mail de verificação enviado com sucesso.');
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleOptinChange = async (item, value) => {
    let optinData = this.state.optinData;
    const index = optinData.indexOf(item);
    if (index > -1) {
      optinData[index].optIn = value;
      await api.post(Globals.api.toggle_media_opt, { type_media_id: item.typeMediaId });
      this.setState(optinData);
    }
  };
  handleToggleTenant = async (tenantId) => {
    this.loadTenants();

  };
  handleClickedTenant = (tenant) => {
    // return
    this.setState({
      relationshipModalOpened: true,
      clickedTenant: tenant
    });


  };

  sendData = () => {
    this.setState({ loading: true });

    const zipCode =
      this.state.CadUserAddressesOne && this.state.CadUserAddressesOne.zip_code
        ? this.state.CadUserAddressesOne.zip_code.replace('-', '')
        : '';

    let postData = {
      username: this.state.name,
      zip_code: zipCode,
      country: 'Brasil',
      landmark: this.state.CadUserAddressesOne.landmark,
      cad_ibge_county_ibge_code: this.state.selectedCity?.value,
      cad_ibge_state_ibge_code: this.state.selectedState,
      phone_number: this.cleanPhone(this.state.phone),
      smsPhone: this.cleanPhone(this.state.smsPhone),
      wppPhone: this.cleanPhone(this.state.wppPhone),
      type_gender_id: this.state.type_gender_id,
      birth_date: this.state.birth_date,
    };

    if (this.state.canEditEmail) {
      postData.email = this.state.email;
    }

    if (this.state.canEditCpf) {
      postData.cpf_cnpj = this.state.cpf;
    }

    api
      .put(Globals.api.changePerfil, postData)
      .then((response) => {
        this.setState({ loading: false });
        dispatch('updateUserProfile');
        //this.loadData()
        this.setState({ edit: false });
        this.showSuccessAlert('Dados alterados com sucesso!');
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error?.response?.data) {
          let fieldsValidation = {};

          error.response.data.forEach((err) => {
            fieldsValidation[err.field] = err.message;
          });

          this.setState({ fieldsValidation });
        } else {
          this.setState({ alertError: error?.response?.status });
          setTimeout(() => {
            this.setState({ alertError: '' });
          }, 3000);
        }
      });
  };

  validateCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;

    if (this.invalidCpfs.indexOf(strCPF) > -1) return false;

    if (strCPF.indexOf('.') > -1 || strCPF.indexOf('-') > -1) {
      strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '');
    }

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  validateEmail(mail) {
    return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  validateForm() {
    let ret = true;

    const fieldsValidation = {};

    if (!this.state.name) {
      fieldsValidation.name = 'Por favor preencha seu nome';
      ret &= false;
    }

    if (this.state.canEditEmail) {
      if (this.state.email === undefined) {
        fieldsValidation.email = 'Por favor preencha seu e-mail';
        ret &= false;
      } else if (!this.validateEmail(this.state.email)) {
        fieldsValidation.email = 'O e-mail informado não é válido';
        ret &= false;
      }

      if (this.state.email !== this.state.confirmEmail) {
        fieldsValidation.confirmEmail =
          'A confirmação do e-mail está diferente do e-mail informado';
        ret &= false;
      }
    }

    if (!this.state.type_gender_id) {
      fieldsValidation.type_gender_id = 'Por favor preencha seu genero';
      ret &= false;
    }

    if (!this.state.birth_date) {
      fieldsValidation.birth_date = 'Por favor preencha sua data de nascimento';
      ret &= false;
    }

    let cleaned = this.cleanPhone(this.state.smsPhone);

    if (cleaned) {
      if (cleaned.length <= 10) {
        fieldsValidation.smsPhone = 'Por favor preencha seu telefone para SMS corretamente';
        ret &= false;
      }
    }

    cleaned = this.cleanPhone(this.state.wppPhone);

    if (cleaned) {
      if (cleaned.length <= 10) {
        fieldsValidation.wppPhone = 'Por favor preencha seu telefone para WhatsApp corretamente';
        ret &= false;
      }
    }

    if (!this.state.phone) {
      fieldsValidation.phone_number = 'Por favor preencha seu telefone';
      ret &= false;
    }

    if (this.state.canEditCpf) {
      if (!this.validateCPF(this.state.cpf ?? '')) {
        fieldsValidation.cpf_cnpj = 'O CPF informado é inválido';
        ret &= false;
      }
    }

    if (!this.state.selectedState) {
      fieldsValidation.cad_ibge_state_ibge_code = 'Por favor selecione seu estado';
      ret &= false;
    }

    if (!this.state.selectedCity.value) {
      fieldsValidation.cad_ibge_county_ibge_code = 'Por favor selecione sua cidade';
      ret &= false;
    }

    if (!this.state.CadUserAddressesOne.zip_code) {
      fieldsValidation.zip_code = 'Por favor preencha seu cep';
      ret &= false;
    }

    if (!this.state.CadUserAddressesOne.landmark) {
      fieldsValidation.landmark = 'Por favor preencha seu endereço';
      ret &= false;
    }

    this.setState({ fieldsValidation });

    return ret;
  }

  clearValidation = () => {
    const fieldsValidation = this.state.fieldsValidation;
    for (let key in fieldsValidation) {
      fieldsValidation[key] = null;
    }
  };

  cancel = () => {
    this.loadTenants();
    this.loadData();
    this.clearValidation();
    this.setState({ edit: false });
  };

  // handleValidate = (event, state) => {
  //   var pass = event.target.value
  //   var test = /^[a-z - A-Z]{0,60}$/.test(pass)
  //   if (test) {
  //     if (state === "address") {
  //       this.setState({ address: event.target.value })
  //     } if (state === "name") {
  //       this.setState({ name: event.target.value })
  //     }
  //   }
  // }

  handleStateChange = (e) => {
    this.setState({ selectedState: e.target.value });
    this.clearItemValidation('cad_ibge_state_ibge_code');
  };

  setCity = (city) => {
    this.setState({ selectedCity: city });
    this.clearItemValidation('cad_ibge_county_ibge_code');
  };

  handleGenderChange = (e) => {
    this.setState({ type_gender_id: e.target.value });
    this.clearItemValidation('type_gender_id');
  };

  handleBirthDateChange = (date) => {
    this.setState({ birth_date: date });
    this.clearItemValidation('birth_date');
  };

  handleCpf = (e, key) => {
    this.setState({ cpf: e.target.value });
    this.clearItemValidation(key);
  };

  handlePhone = (e, key) => {
    this.setState({ phone: e.target.value });
    this.clearItemValidation(key);
  };

  handleSmsPhone = (e, key) => {
    this.setState({ smsPhone: e.target.value });
    this.clearItemValidation(key);
  };

  handleWppPhone = (e, key) => {
    this.setState({ wppPhone: e.target.value });
    this.clearItemValidation(key);
  };

  handleEmail = (e, key) => {
    this.setState({ email: e.target.value });
    this.clearItemValidation(key);
  };

  handleConfirmEmail = (e, key) => {
    this.setState({ confirmEmail: e.target.value });
    this.clearItemValidation(key);
  };

  handleName = (e, key) => {
    this.setState({ name: e.target.value });
    this.clearItemValidation(key);
  };

  handleCep = (e, key) => {
    this.setState({
      CadUserAddressesOne: { ...this.state.CadUserAddressesOne, zip_code: e.target.value },
    });
    this.clearItemValidation(key);
  };

  handleLandmark = (e, key) => {
    this.setState({
      CadUserAddressesOne: { ...this.state.CadUserAddressesOne, landmark: e.target.value },
    });
    this.clearItemValidation(key);
  };

  clearItemValidation = (key) => {
    if (this.state.fieldsValidation && this.state.fieldsValidation[key]) {
      this.setState({ fieldsValidation: { ...this.fieldsValidation, key: null } });
    }
  };

  cleanPhone(value) {
    return value.replace(/ /g, '').replace(/_/g, '').replace(/\(/g, '').replace(/\)/g, '');
  }

  handleChangeTab = (e, value) => {

    if (value === 2) {
      if (!this.props.user.profile.birth_date || !this.props.user.profile.cpf_cnpj) {
        this.showErrorBirthDate()
        return
      }
    }

    this.setState({ currentTab: value });
  };

  showErrorBirthDate = () => {
    const dialogData = {
      isOpened: true,
      title: 'Cadastro incompleto',
      message: 'Complete o seu cadastro para liberar as opções de relacionamento.',
      buttons: [
        {
          label: 'OK',
          icon: 'none',
          onClick: () => this.setState({ dialogData: { isOpened: false }, currentTab: 0 })
        }

      ]
    }
    this.setState({ dialogData: dialogData })
  }

  handleClickCopyPhoneTo = (to) => {
    if (to === 'smsPhone') {
      this.setState({ smsPhone: this.state.phone });
    } else {
      this.setState({ wppPhone: this.state.phone });
    }
  };

  generateTabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  InputMaskCellPhone = (props) => {
    const { inputRef, ...other } = props;

    let cellPhoneMaskFormat = function (value) {
      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    };

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={cellPhoneMaskFormat}
        placeholderChar={'_'}
        guide
        showMask
      />
    );
  };

  InputMaskPhone = (props) => {
    const { inputRef, ...other } = props;

    let phoneMaskFormat = (value) => {
      const clean = this.cleanPhone(value);

      if (clean.length <= 10) {
        return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
      }

      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    };

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={phoneMaskFormat}
        placeholderChar={'_'}
        guide
        showMask
      />
    );
  };

  InputMaskCep(props) {
    const { inputRef, ...other } = props;

    const cepMaskFormat = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={cepMaskFormat}
        placeholderChar={'_'}
        guide
        showMask
      />
    );
  }

  InputMaskCPF(props) {
    const { inputRef, ...other } = props;
    const cepMaskFormat = [
      /[0-9]/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={cepMaskFormat}
        placeholderChar={'_'}
        guide
        showMask
      />
    );
  }

  render() {
    const quantity = this.props.user.profile.total_certificado;
    const quantityTrophies = this.props.user.profile.total_trofeis;

    const fieldsValidation = this.state.fieldsValidation;

    return (
      <div className="profile-div-container">

        <Dialog
          className="error-dialog-profile"
          open={this.state.dialogData.isOpened}
          onClose={() => console.log('')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.dialogData.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialogData.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {(this.state.dialogData.buttons ?? []).map((item, index) => (
              <Button
                icon={item.icon}
                key={index}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  Dialogs.close();
                }}
                color={item.color || 'primary'}
              >
                {item.label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>


        <Grid container>
          <AlertErrors error={this.state.alertError} />
          <Helmet>
            <title>Perfil | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className="profileClassContainer">
            <Grid container>
              <div className="perfilContainer">
                <div className="perfilHeader" />
                <div className="perfilAvatar">
                  {this.props.user.profile.user_avatar !== undefined &&
                    this.props.user.profile.user_avatar !== null ? (
                    <span className="avatarPerfil">
                      <img src={this.props.user.profile.user_avatar} alt="Avatar" />
                      <section>
                        <Link to={Globals.paths.avatar}>Trocar Avatar</Link>
                      </section>
                    </span>
                  ) : this.props.user.profile.user_avatar === undefined &&
                    this.state.user_avatar !== null &&
                    this.state.user_avatar !== undefined ? (
                    <span className="avatarPerfil">
                      <img src={this.state.user_avatar} alt="Avatar" />
                      <section>
                        <Link to={Globals.paths.avatar}>Trocar Avatar</Link>
                      </section>
                    </span>
                  ) : (
                    <span className="avatarPerfil">
                      <div style={{ paddingTop: "8px" }}>
                        {this.state.avatarInitials}
                      </div>
                      <section>
                        <Link to={Globals.paths.avatar}>Trocar Avatar</Link>
                      </section>
                    </span>
                  )}
                </div>
              </div>
              <Grid className="tab-container" style={{ paddingTop: "0" }} container>
                <Grid item container justify="center" alignItems="center">
                  <AppBar position="static" className="center" color="primary">
                    <Tabs
                      value={this.state.currentTab}
                      onChange={this.handleChangeTab}
                      aria-label="Configurações de usuário"
                      centered
                    >
                      <Tab label="Dados de Perfil" {...this.generateTabProps(0)} />
                      <Tab label="Comunicação" {...this.generateTabProps(1)} />
                      <Tab label="Relacionamento"  {...this.generateTabProps(2)} disabled={this.state.isLoading} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={this.state.currentTab} index={0}>
                    <div className="perfilForm">
                      {this.state.isLoading ? (
                        <Grid container item xs={12} justify="center" alignItems="center">
                          <CircularProgress size={40} />
                        </Grid>
                      ) : (
                        <>
                          {this.state.openAlert && (
                            <Alert
                              severity="success"
                              onClose={() => {
                                this.setState({ openAlert: false });
                              }}
                            >
                              {this.state.successMessage}
                            </Alert>
                          )}

                          {this.state.canEditEmail && (
                            <p>
                              Você ainda não validou seu e-mail.{' '}
                              <span
                                role="button"
                                onClick={this.sendVerificationEmail}
                                className="primary"
                              >
                                Clique aqui
                              </span>{' '}
                              para reenviar um novo e-mail de validação
                            </p>
                          )}
                          <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <Grid container>
                              <Grid container item xs={12} className="tab-description">
                                Este espaço é para você conferir se seus dados pessoais estão
                                corretos. <br />
                                Caso tenha alguma mudança, não esqueça de informa-la para manter seu
                                perfil atualizado.
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                justify={this.state.edit ? 'space-between' : 'flex-end'}
                                className={'mt20 mb20'}
                              >
                                {this.state.edit ? (
                                  <>
                                    <Button
                                      icon="none"
                                      type="green"
                                      textAlign={'center'}
                                      onClick={this.cancel}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      type={'full-solid'}
                                      textAlign={'center'}
                                      onClick={() => {
                                        if (this.validateForm()) {
                                          this.sendData();
                                        }
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <CircularProgress size={20} style={{ color: 'white' }} />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </>
                                ) : (
                                  <div className="two-buttons">
                                    <Button
                                      icon="none"
                                      to={Globals.paths.home}
                                      textAlign={'center'}
                                      onClick={this.cancel}
                                    >
                                      Voltar
                                    </Button>
                                    <Button
                                      type={'full-solid'}
                                      textAlign={'center'}
                                      onClick={() => this.setState({ edit: true })}
                                    >
                                      Alterar Dados
                                    </Button>
                                  </div>
                                )}
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                className="default-subtitle"
                                style={{ margin: '10px 0' }}
                              >
                                Dados Pessoais:
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="Nome *"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  name="username"
                                  error={!!fieldsValidation.username}
                                  helperText={fieldsValidation.username}
                                  disabled={!this.state.edit}
                                  value={this.state.name}
                                  onChange={(e) => this.handleName(e, 'username')}
                                />
                              </Grid>
                              <Grid container item xs={12}>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                >
                                  <TextField
                                    value={this.state.type_gender_id}
                                    onChange={this.handleGenderChange}
                                    variant="outlined"
                                    size="small"
                                    label="Gênero *"
                                    select
                                    disabled={!this.state.edit}
                                    name="type_gender_id"
                                    error={!!fieldsValidation.type_gender_id}
                                    helperText={fieldsValidation.type_gender_id}
                                  >
                                    <MenuItem value={0} disabled>
                                      <em>Selecione um gênero</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Masculino</MenuItem>
                                    <MenuItem value={2}>Feminino</MenuItem>
                                    <MenuItem value={3}>Prefiro não informar</MenuItem>
                                  </TextField>
                                </FormControl>
                              </Grid>
                              <Grid container item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                  <KeyboardDatePicker
                                    label="Data de Nascimento *"
                                    fullWidth
                                    margin="normal"
                                    format="dd/MM/yyyy"
                                    okLabel="Selecionar"
                                    cancelLabel="Cancelar"
                                    inputVariant="outlined"
                                    size="small"
                                    disabled={!this.state.edit}
                                    value={this.state.birth_date}
                                    onChange={this.handleBirthDateChange}
                                    error={!!fieldsValidation.birth_date}
                                    helperText={fieldsValidation.birth_date}
                                    KeyboardButtonProps={{
                                      'aria-label': 'Alterar data',
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="CPF *"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  disabled={!this.state.canEditCpf || !this.state.edit}
                                  value={this.state.cpf}
                                  onChange={(e) => this.handleCpf(e, 'cpf_cnpj')}
                                  error={!!fieldsValidation.cpf_cnpj}
                                  helperText={fieldsValidation.cpf_cnpj}
                                  InputProps={{
                                    inputComponent: this.InputMaskCPF,
                                  }}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                className="default-subtitle"
                                style={{ margin: '10px 0' }}
                              >
                                Dados para Contato:
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="E-mail *"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  disabled={!this.state.canEditEmail || !this.state.edit}
                                  value={this.state.email}
                                  error={!!fieldsValidation.email}
                                  helperText={fieldsValidation.email}
                                  onChange={(e) => this.handleEmail(e, 'email')}
                                />
                              </Grid>
                              {this.state.canEditEmail && (
                                <Grid container item xs={12}>
                                  <TextField
                                    label="Confirme seu E-mail *"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.confirmEmail}
                                    disabled={!this.state.edit}
                                    error={!!fieldsValidation.confirmEmail}
                                    helperText={fieldsValidation.confirmEmail}
                                    onChange={(e) => this.handleConfirmEmail(e, 'confirmEmail')}
                                  />
                                </Grid>
                              )}
                              <Grid container item xs={12}>
                                <TextField
                                  label="Telefone Principal *"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  disabled={!this.state.edit}
                                  value={this.state.phone}
                                  onChange={(e) => this.handlePhone(e, 'phone_number')}
                                  name="phone_number"
                                  error={!!fieldsValidation.phone_number}
                                  helperText={fieldsValidation.phone_number}
                                  InputProps={{
                                    inputComponent: this.InputMaskPhone,
                                  }}
                                />
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="Celular para SMS"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  disabled={!this.state.edit}
                                  value={this.state.smsPhone}
                                  onChange={(e) => this.handleSmsPhone(e, 'smsPhone')}
                                  name="smsPhone"
                                  error={!!fieldsValidation.smsPhone}
                                  helperText={fieldsValidation.smsPhone}
                                  InputProps={{
                                    inputComponent: this.InputMaskCellPhone,
                                    endAdornment: !!this.state.edit && (
                                      <InputAdornment position="end">
                                        <Tooltip title="Copiar telefone principal" placement="top">
                                          <IconButton style={{ padding: 0 }}
                                            aria-label="Copiar telefone principal"
                                            onClick={() => {
                                              this.handleClickCopyPhoneTo('smsPhone');
                                            }}
                                            onMouseDown={() => {
                                              this.handleClickCopyPhoneTo('smsPhone');
                                            }}
                                          >
                                            <FileCopy />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="Celular para WhatsApp"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  disabled={!this.state.edit}
                                  value={this.state.wppPhone}
                                  onChange={(e) => this.handleWppPhone(e, 'wppPhone')}
                                  name="wppPhone"
                                  error={!!fieldsValidation.wppPhone}
                                  helperText={fieldsValidation.wppPhone}
                                  InputProps={{
                                    inputComponent: this.InputMaskCellPhone,
                                    endAdornment: !!this.state.edit && (
                                      <InputAdornment position="end">
                                        <Tooltip title="Copiar telefone principal" placement="top">
                                          <IconButton style={{ padding: 0 }}
                                            aria-label="Copiar telefone principal"
                                            onClick={() => {
                                              this.handleClickCopyPhoneTo('wppPhone');
                                            }}
                                            onMouseDown={() => {
                                              this.handleClickCopyPhoneTo('wppPhone');
                                            }}
                                          >
                                            <FileCopy />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                className="default-subtitle"
                                style={{ margin: '10px 0' }}
                              >
                                Endereço:
                              </Grid>
                              <Grid container item xs={12}>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                >
                                  <TextField
                                    value={this.state.selectedState}
                                    onChange={this.handleStateChange}
                                    variant="outlined"
                                    size="small"
                                    label="Estado *"
                                    select
                                    disabled={!this.state.edit}
                                    name="cad_ibge_state_ibge_code"
                                    error={!!fieldsValidation.cad_ibge_state_ibge_code}
                                    helperText={fieldsValidation.cad_ibge_state_ibge_code}
                                  >
                                    <MenuItem value={0} disabled>
                                      <em>Selecione um estado</em>
                                    </MenuItem>
                                    {this.state.states.map((state) => (
                                      <MenuItem
                                        key={'state-option-' + state.value}
                                        value={state.value}
                                      >
                                        {state.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>
                              </Grid>
                              <Grid container item xs={12}>
                                <FormControl fullWidth margin="normal">
                                  <CitiesAutoComplete
                                    disabled={!this.state.edit}
                                    value={this.state.selectedCity}
                                    setCity={this.setCity}
                                    stateId={this.state.selectedState}
                                    name="cad_ibge_county_ibge_code"
                                    error={!!fieldsValidation.cad_ibge_county_ibge_code}
                                    helperText={fieldsValidation.cad_ibge_county_ibge_code}
                                  ></CitiesAutoComplete>
                                </FormControl>
                              </Grid>
                              <Grid container item xs={12}>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                >
                                  <TextField
                                    label="CEP *"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={this.state.CadUserAddressesOne.zip_code}
                                    disabled={!this.state.edit}
                                    onChange={(e) => this.handleCep(e, 'zip_code')}
                                    InputProps={{
                                      inputComponent: this.InputMaskCep,
                                    }}
                                    name="zip_code"
                                    error={!!fieldsValidation.zip_code}
                                    helperText={fieldsValidation.zip_code}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid container item xs={12}>
                                <TextField
                                  label="Endereço *"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  value={this.state.CadUserAddressesOne.landmark || ''}
                                  disabled={!this.state.edit}
                                  onChange={(e) => this.handleLandmark(e, 'landmark')}
                                  name="landmark"
                                  error={!!fieldsValidation.landmark}
                                  helperText={fieldsValidation.landmark}
                                />
                              </Grid>
                              <Grid container item xs={12}>
                                <p style={{ fontSize: '12px', color: '#8a8a8a' }}>
                                  Os campos marcados com * são obrigatórios.
                                </p>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                justify={this.state.edit ? 'space-between' : 'flex-end'}
                                className={'mt20'}
                              >
                                {this.state.edit ? (
                                  <>
                                    <Button
                                      icon="none"
                                      type="green"
                                      textAlign={'center'}
                                      onClick={this.cancel}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      type={'full-solid'}
                                      textAlign={'center'}
                                      onClick={() => {
                                        if (this.validateForm()) {
                                          this.sendData();
                                        }
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <CircularProgress size={20}
                                          style={{ color: 'white' }} />
                                      ) : (
                                        'Salvar'
                                      )}
                                    </Button>
                                  </>
                                ) : (
                                  <div className="two-buttons flex-buttons-col-h-min-800">
                                    <Button
                                      icon="none"
                                      to={Globals.paths.home}
                                      textAlign={'center'}
                                      onClick={this.cancel}
                                    >
                                      Voltar
                                    </Button>
                                    <Button
                                      type={'full-solid'}
                                      textAlign={'center'}
                                      onClick={() => this.setState({ edit: true })}
                                    >
                                      Alterar Dados
                                    </Button>
                                    <Button
                                      icon='none'
                                      type={'danger'}
                                      textAlign={'center'}
                                      onClick={() => this.setState({ ProfileRemoveModalOpened: true })}
                                    >
                                      Remover Cadastro
                                    </Button>
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </form>
                        </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel
                    value={this.state.currentTab}
                    index={1}
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    {this.state.optinDataLoading ? (
                      <Grid container item xs={12} justify="center" alignItems="center">
                        <CircularProgress size={40} />
                      </Grid>
                    ) : (
                      <>
                        <Grid container>
                          <Grid container item xs={12} className="tab-description">
                            O Meu Bolso em Dia deseja te manter informado sobre as novidades da
                            plataforma. Para ficar atualizado e receber as informações e
                            comunicados, escolha a opção que mais se adequa ao seu perfil.
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className="default-subtitle"
                            style={{ margin: '25px 0' }}
                          >
                            Aceito receber informações e comunicações por:
                          </Grid>
                          <Grid container className="optins-container">
                            {this.state.optinData.map((item) => (
                              <Grid key={item} container item xs={12} className="optin-item">
                                <span className="optin-item-label">{item.typeMedia}:</span>
                                <Switch
                                  checked={item.optIn}
                                  onChange={(e, value) => {
                                    this.handleOptinChange(item, value);
                                  }}
                                  name="checkedA"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={this.state.currentTab} index={2}>
                    {this.state.birth_date ? (
                      <>
                        {this.state.isLoadingTenants && this.state.firstLoad ? (
                          <Grid container item xs={12} justify="center" alignItems="center">
                            <CircularProgress className="loading-container" size={40} />
                          </Grid>
                        ) : (
                          <>
                            <Grid container className="relationship-container">

                              <Grid container className="connected-container">
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  className="default-title"
                                >
                                  Instituições com relacionamento ativo
                                </Grid>
                                <Grid container item xs={12} className="tab-description">
                                  Veja abaixo a lista de todas as instituições relacionadas
                                </Grid>
                                <Grid container className="cards-container">
                                  {this.state.tenants && this.state.tenants.length > 0 ? (
                                    this.state.tenants.map(tenant => (
                                      <>
                                        {!!tenant.validated &&
                                          <Card key={tenant.id}
                                            className="card-container"
                                            onClick={() => this.handleClickedTenant(tenant)}
                                          >
                                            <CardContent>
                                              <div className={!!tenant.active ? 'green-circle' : 'gray-circle'}
                                              />
                                              <img src={tenant.logo_url} alt={tenant.name} />
                                              <div className="bank-name">{tenant.name}</div>
                                              <div className={!!tenant.active ? 'status' : 'status gray'}>{!!tenant.active ? 'conectado' : 'inativo'}</div>

                                            </CardContent>
                                            <div className={!!tenant.active ? 'green-bar' : 'gray-bar'} />
                                          </Card>
                                        }
                                      </>
                                    ))
                                  ) : ('')}

                                </Grid>
                              </Grid>
                              <Grid container className="toconnect-container">
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  className="default-title"
                                >
                                  Instituições
                                </Grid>
                                <Grid container item xs={12} className="tab-description">
                                  Veja abaixo a lista de todas as instituições relacionadas
                                </Grid>
                                <Grid container className="cards-container">

                                  {this.state.tenants && this.state.tenants.length > 0 ? (
                                    this.state.tenants.map(tenant => (
                                      <>
                                        {!tenant.validated &&
                                          <Card key={tenant.tenant_id} className="card-container"
                                            onClick={() => this.handleClickedTenant(tenant)}>
                                            <CardContent>
                                              <img src={tenant.logo_url} alt={tenant.name} />
                                              <div className="bank-name">{tenant.name}</div>
                                              <div className="status green">
                                                Conectar
                                              </div>

                                            </CardContent>
                                            <div className="gray-bar" />
                                          </Card>
                                        }
                                      </>
                                    ))
                                  ) : ('')}

                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid container className="relationship-container">

                          <Grid container className="connected-container">
                            <Grid
                              container
                              item
                              xs={12}
                              className="default-title"
                            >
                              Cadastro incompleto
                            </Grid>
                            <Grid container item xs={12} className="tab-description">
                              Complete o seu cadastro para liberar as opções de relacionamento.
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}


                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>

            {this.state.userLevel >= 2 &&
              <Grid container style={{ marginTop: '32px', marginBottom: '70px' }}>
                <FeedLine>
                  <FeedItem expandHeight color="#05CCAE">
                    <HistoryPerfil />
                  </FeedItem>
                </FeedLine>
              </Grid>
            }
          </Grid>

          <Hidden xsDown>
            <Grid item container sm={4} md={3} direction="column">
              <div className="home-saude-financeira">
                <ToolbarItem
                  tooltip={this.state.nextIndexAssessmentDateText}
                  title="Índice de Saúde Financeira"
                  color="#05CCAE"
                >
                  {this.state.firstIndexMustBeFilled ? (
                    <div className="indiceContainer">
                      <Grid container>
                        <Grid
                          xs={12}
                          item
                          style={{ padding: '20px', textAlign: 'center', color: '#595959' }}
                        >
                          Você ainda não preencheu o seu Índice.
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <>
                      {
                        <div className="positionGauge">
                          <SmallProfileGauge id="home"></SmallProfileGauge>
                          <span
                            role="button"
                            onClick={() => this.setState({ profileModalOpened: true })}
                          >
                            mais informações
                          </span>
                        </div>
                      }
                    </>
                  )}
                </ToolbarItem>
              </div>

              <ToolbarItem
                title="Saldo de Pontos"
                color="#05CCAE"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de Pontos ao ser completada. Junte os pontos e os troque por benefícios e conteúdos exclusivos da Plataforma."
              >
                <PointsBalance coins={this.state.coins}></PointsBalance>
              </ToolbarItem>

              <ToolbarItem title="Meus favoritos" color="#DAF439" overflow="overflow">
                {/* <div style={{ marginLeft: "10px", width: "100%", overflow: "auto", maxHeight: "200px" }}> */}
                <Favorite />
                {/* </div> */}
              </ToolbarItem>

              <ToolbarItem title="Troféus" color="#05CCAE">
                <LastTrophy quantity={quantityTrophies} />
              </ToolbarItem>

              <ToolbarItem title="Certificados" color="#05CCAE">
                <LastCertificate quantity={quantity} />
              </ToolbarItem>
            </Grid>
          </Hidden>

          {this.state.profileModalOpened && (
            <ProfileModal
              closeWelcomeModal={this.closeWelcomeModal}
              open={this.state.profileModalOpened}
            ></ProfileModal>
          )}

          <ProfileRemoveModal
            closeProfileRemoveModal={this.closeProfileRemoveModal}
            open={this.state.ProfileRemoveModalOpened}
            onClose={() => this.setState({ ProfileRemoveModalOpened: false })}
          />

          {this.state.relationshipModalOpened && (
            <RelationshipModal
              loadDataTenants={() => this.loadTenants()}
              loadDataParent={() => this.loadData()}
              clickedTenant={this.state.clickedTenant}
              closeRelationshipModal={this.closeRelationshipModal}
              open={this.state.relationshipModalOpened}
            ></RelationshipModal>
          )}
        </Grid>
      </div>
    );
  }
}

export default watch(Profile, { user: '' });