import React, { useCallback, useEffect, useState } from 'react';
import { injectMetaPixelScript, injectTiktokPixelScript, injectTwitterTrackingScript } from './injections';
import * as Styled from './styles';

const Globals = require('../../../Globals.json');

function CookiesWarning() {
  const [showWarning, setShowWarning] = useState(false);
  const tagManagerArgs = { gtmId: 'GTM-MFQKPLB' }; // Contêiner principal para GA4

  const loadGtmScript = (gtmId) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
  };

  const injectScripts = useCallback(() => {
    setShowWarning(false);
    injectMetaPixelScript();
    injectTiktokPixelScript();
    injectTwitterTrackingScript();
    loadGtmScript(tagManagerArgs.gtmId);

    // Verifica e define a função gtag
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-273249496'); // Substitua com seu ID do GA4
    }
  }, [tagManagerArgs]);

  function agreeWithCookies() {
    window.localStorage.setItem('cookiesAgreement', true);
    injectScripts();
  }

  useEffect(() => {
    const userDef = window.localStorage.getItem('cookiesAgreement');

    if (!userDef) {
      setShowWarning(true);
    } else {
      injectScripts();
    }
  }, [injectScripts]);

  if (!showWarning) {
    return null;
  }

  return (
    <Styled.Container>
      <div className="content">
        <div className="innerWrapper">
          <p>
            O MEU BOLSO EM DIA utiliza cookies para auxiliar sua navegação,
            aprimorar o conteúdo do nosso site, direcionar conteúdos de
            marketing, realizar análises estatísticas e conectá-lo com mídias
            sociais, garantindo a melhor experiência possível. Para saber mais
            sobre cookies, acesse nossa Política de Privacidade.{' '}
            <a
              href={Globals.paths.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver mais.
            </a>
          </p>
          <button type="button" onClick={agreeWithCookies}>
            Ok
          </button>
        </div>
      </div>
    </Styled.Container>
  );
}

export default CookiesWarning;