import React, { Component } from 'react';

import { withStyles, Dialog, DialogContent, IconButton, Box, Grid, Typography, Tooltip, Zoom } from '@material-ui/core';
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import './styles/changePassword.scss';

import api from '../../../services/api';
import Alert from '@material-ui/lab/Alert';
import Button from '../button/Button';
import CloseIcon from '@material-ui/icons/Close';
import Festa from '../../../assets/icon/festa.png'
const Globals = require("../../../Globals.json");


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#595959',
    marginLeft: '60px',
    paddingLeft: "10px",
    paddingRight: "15px",
    paddingBottom: "10px",
    boxShadow: "2px 5px 15px #00000055",
  },
  arrow: {
    height: "45px",
    color: "#eee",
  },
}))(Tooltip);
class ChangePassword extends Component {

  state = {
    old_password: "",
    password: "",
    password_confirmation: "",
    setIsPasswordVisible: false,
    isOld_passwordVisible: false,
    isPasswordVisible: false,
    isPassword_confirmationVisible: false,
    openAlert: false,
    openAlertError: false,
    msg: '',

    message: "",
    messageErro: "",
    pass: '',
    passScore: 0,
    passReq_min6: false,
    passReq_upper: false,
    passReq_lower: false,
    passReq_number: false,
    passReq_specialChar: false,
    confirmPass: '',
    fieldsValidation: {},
    alertError: '',
  }

  removeValidation(key) {
    const obj = { ...this.state.fieldsValidation };
    delete obj[key];
    this.setState({ fieldsValidation: obj });
  }

  updatePassStatus(pass) {
    this.setState(
      {
        passReq_min6: pass.length >= 6,
        passReq_upper: /[A-Z]/.test(pass),
        passReq_lower: /[a-z]/.test(pass),
        passReq_number: /\d/.test(pass),
        passReq_specialChar: /\W/.test(pass),
      }
    )
  }

  validateForm() {

    let ret = true;

    const fieldsValidation = {};

    if (!this.state.old_password || this.state.old_password === "") {
      console.log('yell password is empty')
      fieldsValidation.oldPass = "Por favor digite uma senha";
      ret &= false;
    }

    if (!this.state.password || this.state.password === "") {
      fieldsValidation.pass = "Por favor digite uma senha";
      ret &= false;
    } else if (!this.state.passReq_min6 ||
      !this.state.passReq_upper ||
      !this.state.passReq_lower ||
      !this.state.passReq_number ||
      !this.state.passReq_specialChar) 
      {
      fieldsValidation.pass = "Por favor, preencha todos os requisitos de senha";
      ret &= false;
      }

    if (this.state.password !== this.state.password_confirmation) {
      fieldsValidation.confirmPass = "A confirmação da senha está diferente da senha informada";
      ret &= false;
    }

    this.setState({ fieldsValidation: fieldsValidation });

    return ret;

  }

  clearState = () => {
    this.setState({ old_password: "", password: "", password_confirmation: "" })
  }

  changePassword = async () => {

    try {

      await api.put(Globals.api.changePassword, {
        "old_password": this.state.old_password,
        "password": this.state.password,
        "password_confirmation": this.state.password_confirmation
      }).then(response => {
        this.setState({ message: 'Senha alterado com Sucesso!' })
        this.setState({ openAlert: true })
        this.setState({ openAlertError: false})
        this.clearState()
        setTimeout(() => {
          if(this.props.passwordExpiredInstructions){
            this.props.onClose()
          }
          return this.setState({ openAlert: false })

        }, 4000)
      })

    } catch (error) {
      this.setState({ openAlertError: true })
      this.setState({ openAlert: false })

      if (error?.response?.status === 422) {
        this.setState({messageErro: error?.response?.data?.message})
      } else if (error?.response?.status === 400) {
        this.setState({messageErro:'Senha atual inválida.'})
      } else {
        this.setState({messageErro:'Não foi possível alterar sua senha! Tente novamente em alguns minutos.'})
      }

      setTimeout(() => {
        this.setState({messageErro:''})
        return this.setState({ openAlertError: false })
      }, 8000)
    }
  }

  render() {

    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          onBackdropClick={false}
          maxWidth="xs"
          className="modal"
          disableBackdropClick
          disableEscapeKeyDown
        >

          <DialogContent>
            <div className="header">
            {!this.props.passwordExpiredInstructions && (
              <button type="button" className="close"
              onClick={()=>this.props.onClose()}>&times;
            </button>
            )}
              <h2>Alterar Senha</h2>
            </div>
            {!this.props.passwordExpiredInstructions && <br></br>}
            {this.props.passwordExpiredInstructions && (
              <div>
                <span>Sua senha expirou e precisa ser alterada.</span>
              </div>
            )}
            {this.props.passwordExpiredInstructions && <br></br>}


            <span className="textsForm">Senha atual</span>
            <VisibilityPasswordTextField
              id={"old_password"}
              className="inputField"
              size="small"
              variant="outlined"
              required
              fullWidth
              placeholder="Insira a sua senha atual"
              autoComplete="off"
              type="password"
              value={this.state.old_password}
              inputProps={{ maxLength: 15 }}
              onChange={(e) => {
                this.setState({ old_password: e.target.value });
                this.removeValidation("oldPass")
              }}
              onVisibilityChange={() => this.setState({ isOld_passwordVisible: !this.state.isOld_passwordVisible })}
              isVisible={this.state.isOld_passwordVisible}
              error={this.state.fieldsValidation.oldPass}
              helperText={this.state.fieldsValidation.oldPass}
              FormHelperTextProps={{ error: true }}
            />
            <div className="passwordInstructionsLabel">
              <span className="textsForm">Senha nova</span>
              <span className="textsFormSmall">Digite ao menos 6 caracteres, sendo: 1 letra maiúscula, 1 letra minúscula, 1 caractere especial e 1 número</span>
            </div>
            <HtmlTooltip placement="right" arrow={true}
              disableHoverListener
              title={
                <>
                  <Typography color="inherit">Requisitos da Senha</Typography>
                  <Grid container direction="column">
                    <Box style={{ color: this.state.passReq_min6 ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className="passReq">
                      {this.state.passReq_min6 ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className="passReqText">Mínimo de 6 caracteres</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_upper ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className="passReqText">
                      {this.state.passReq_upper ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className="passReqText">Pelo menos 1 letra maiúscula</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_lower ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className="passReqText">
                      {this.state.passReq_lower ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className="passReqText">Pelo menos 1 letra minúscula</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_number ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className="passReqText">
                      {this.state.passReq_number ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className="passReqText">Pelo menos 1 número</Box>
                    </Box>
                    <Box style={{ color: this.state.passReq_specialChar ? "#090" : "#A00" }} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className="passReqText">
                      {this.state.passReq_specialChar ? <Zoom in={true}><CheckCircleIcon fontSize="small" /></Zoom> : <CancelIcon fontSize="small" />} <Box className="passReqText">Pelo menos 1 caractere especial</Box>
                    </Box>
                  </Grid>
                </>
              }
            >
              <VisibilityPasswordTextField
                id={"password"}
                className="inputField"
                size="small"
                variant="outlined"
                required
                fullWidth
                placeholder="Insira uma nova senha"
                autoComplete="off"
                type="password"
                value={this.state.password}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                  this.updatePassStatus(e.target.value);
                  this.removeValidation("pass")
                }}
                onVisibilityChange={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                isVisible={this.state.isPasswordVisible}
                error={this.state.fieldsValidation.pass}
                helperText={this.state.fieldsValidation.pass}
                FormHelperTextProps={{ error: true }}
                
              />
            </HtmlTooltip>
            <br />
            <span className="textsForm">Confirmar nova senha</span>
            <VisibilityPasswordTextField
              id={"password_confirmation"}
              className="inputField"
              size="small"
              variant="outlined"
              placeholder="Repita sua senha nova"
              required
              fullWidth
              error={this.state.fieldsValidation.confirmPass}
              helperText={this.state.fieldsValidation.confirmPass}
              autoComplete="off"
              onChange={(e) => {
                this.setState({ password_confirmation: e.target.value });
                this.removeValidation("confirmPass")
              }}
              FormHelperTextProps={{ error: true }}
              value={this.state.password_confirmation}
              onVisibilityChange={() => this.setState({ isPassword_confirmationVisible: !this.state.isPassword_confirmationVisible })}
              isVisible={this.state.isPassword_confirmationVisible}
              type="password"
              inputProps={{ maxLength: 15 }}
            />

            <div className="positionBtn" >
              <Button width="90px" textAlign="center" icon="none"
                onClick={() => { if (this.validateForm()) { this.changePassword() } }} >Salvar
              </Button>
            </div>
            <br></br>
            {this.state.openAlert ?
              <Alert icon={<img src={Festa} alt="Imagem de festa" style={{ padding: "0px", width: "20px" }} />}
                action={
                  <IconButton aria-label="close" color="inherit" size="small"
                    onClick={() => { this.setState({ openAlert: false }) }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                Senha alterada com sucesso!
              </Alert>

              : this.state.openAlertError &&

              <Alert severity="error"
                action={<IconButton aria-label="close" color="inherit" size="small"
                  onClick={() => { this.setState({ openAlertError: false }) }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                }> {this.state.messageErro} </Alert>
            } 
          </DialogContent>
        </Dialog>
      </>
    )
  }

}

export default ChangePassword;