import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import { formatNumber, getDashboardEndDate, getDashboardStartDate } from '../../../../../services/util';
import ProgressEvolutionCharts from './ProgressEvolutionCharts/ProgressEvolutionCharts';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EvolutionCharts from './EvolutionCharts/EvolutionCharts';
import CoursesByRegion from './CoursesByRegion/CoursesByRegion';
import IndexCard from '../Components/IndexCard/IndexCard';
import { ClearAll, Refresh } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import './Engagement.scss';

const useStyles = makeStyles(() => {
  return {
    dateInput: {
      height: '30px',
      fontSize: '14px',
      minWidth: '90px',
      width: '100%',
      backgroundColor: '#fff',

      '& .MuiInputBase-input': {
        padding: '0 3px',
      }
    }
  }
});

const Engagement = ({ data, isLoading, filters, setFilters, alert }) => {
  const classes = useStyles();
  const [persona, setPersona] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [gender, setGender] = useState('');
  const [state, setState] = useState('');
  const [income, setIncome] = useState('');
  const [mostReadArtiglesTotal, setMostReadArticlesTotal] = useState(0);
  const [mostReadArtiglesTotalPercentage, setMostReadArticlesTotalPercentage] = useState(0);
  const [evaluationsTotal, setEvaluationsTotal] = useState(0);
  const [evaluationsTotalPercentage, setEvaluationsTotalPercentage] = useState(0);
  const [evaluationsTotalAvg, setEvaluationsTotalAvg] = useState(0);
  const [normalizedMostReadArticles, setNormalizedMostReadArticles] = useState(null);
  const [normalizedTestsPerformed, setNormalizedTestsPerformed] = useState(null);

  const loadDropdown = (options) => {
    let optionsList = [];
    optionsList.push(<option key={'default'} value={''}>Selecionar todos</option>);

    if (options) {
      options.map((item, index) => (
        optionsList.push(<option key={index} value={item}>{item}</option>)
      ))
    }

    return optionsList;
  };

  const handleFilterChange = (e, element) => {
    if (element === 'startDate' || element === 'endDate') {
      setFilters(prevState => ({ ...prevState, [element]: e }));
    } else {
      setFilters(prevState => ({ ...prevState, [element]: e.target.value }));
    }
  };

  const setUpFilters = () => {
    setPersona(filters.persona);
    setAgeRange(filters.ageRange);
    setGender(filters.gender);
    setState(filters.state);
    setIncome(filters.income)
  };

  const refreshFilters = () => {
    setFilters(prevState => ({ ...prevState }));
  };

  const clearFilters = () => {
    setFilters(prevState => ({
      ...prevState,
      startDate: getDashboardStartDate(),
      endDate: getDashboardEndDate(),
      persona: '',
      ageRange: '',
      gender: '',
      state: '',
      income: ''
    }));
  };

  const dataSetUp = () => {
    if (data?.mostReadArticlesData) {
      const clone = data.mostReadArticlesData.slice();
      const totals = clone?.find(item => !item.name);
      const rollUpValue = (item) => !item.name;
      const rollUpIndex = clone?.findIndex(rollUpValue);
      clone.splice(rollUpIndex, 1);
      setMostReadArticlesTotal(totals?.total);
      setMostReadArticlesTotalPercentage(totals?.percentage);
      setNormalizedMostReadArticles(clone);
    }

    if (data?.testsPerformedData) {
      const clone = data.testsPerformedData.slice();
      const totals = clone?.find(item => !item.name);
      setEvaluationsTotal(totals?.total);
      setEvaluationsTotalAvg(totals?.averageScore);
      setEvaluationsTotalPercentage(totals?.percentage);
      const rollUpValue = (item) => !item.name;
      const rollUpIndex = clone?.findIndex(rollUpValue);
      clone.splice(rollUpIndex, 1);
      setNormalizedTestsPerformed(clone);
    }
  };

  const showSkeleton = () => {
    return <div className="dash-skeleton-container">
      <div className="grid-row-1">
        <div className="grid-col-1 index-grid-row">
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
            <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
              <Skeleton variant="rect" width={"100%"} height={"100%"} />
            </div>
          </div>
        </div>
        <div className="grid-col-3 min-width-1445">
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-1445 min-height-249">
              <div className="grid-row-1">
                <div className="grid-col-3 min-width-480 min-height-249">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} />
                </div>
                <div className="grid-col-2 min-width-320 min-height-249">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} />
                </div>
                <div className="grid-col-4 min-width-630 min-height-249">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-row-1">
            <div className="grid-col-1 min-width-1445 min-height-504">
              <div className="grid-row-1">
                <div className="grid-col-1 min-width-720 min-height-504">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} />
                </div>
                <div className="grid-col-1 min-width-640 min-height-504">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  const showContent = () => {
    return <>
      <div className="dash-content-container">
        <div className="grid-row">
          <div className="grid-col-1 index-grid-row">
            <div className="grid-row">
              {data?.indexData?.map((item, index) =>
                <div className="grid-col-1 min-width-190 max-width-190 index-card-min-height">
                  <IndexCard key={'indexCard_' + index} index={item} />
                </div>
              )}
            </div>
          </div>
          <div className="grid-col-3 min-width-1445">
            <div className="grid-row">
              <div className="grid-col-1 min-width-1445 min-height-249">
                <div className="grid-row">
                  <div className="grid-col-3 min-width-480 height-249">
                    <div className='dash-cards-container'>
                      <div>Artigos mais lidos</div>
                      <div className="tableFixHead" style={{ height: '100%' }}>
                        <table className='table-dash'>
                          <thead>
                            <tr>
                              <th>Nome do Conteúdo</th>
                              <th>Quantidade</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {normalizedMostReadArticles?.map((item, index) =>
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.total}</td>
                                <td style={{ backgroundColor: item.percentageBackgroundColor }}>{item.percentage}</td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td>{mostReadArtiglesTotal || "0"}</td>
                              <td>{mostReadArtiglesTotalPercentage || "0,00%"}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="grid-col-2 min-width-320 height-249">
                    <div className='dash-cards-container'>
                      <CoursesByRegion data={data?.coursesByRegionAndStateData} />
                    </div>
                  </div>
                  <div className="grid-col-4 min-width-630 height-249">
                    <div className="dash-cards-container" style={{ height: '100%' }}>
                      <div>Progresso de Curso por Persona</div>
                      <table className='table-dash'>
                        <thead>
                          <tr>
                            <th>Persona</th>
                            <th>0</th>
                            <th>Até 25</th>
                            <th>De 26 a 50</th>
                            <th>De 51 a 75</th>
                            <th>De 76 a 100</th>
                            <th className='table-total'>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.coursesProgressByPersonaData?.map((item, index) =>
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td style={{ backgroundColor: item.range01BackgroundColor }}>{formatNumber(item.range01)}</td>
                              <td style={{ backgroundColor: item.range02BackgroundColor }}>{formatNumber(item.range02)}</td>
                              <td style={{ backgroundColor: item.range03BackgroundColor }}>{formatNumber(item.range03)}</td>
                              <td style={{ backgroundColor: item.range04BackgroundColor }}>{formatNumber(item.range04)}</td>
                              <td style={{ backgroundColor: item.range05BackgroundColor }}>{formatNumber(item.range05)}</td>
                              <td className='table-total'>{formatNumber(item.total)}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-col-1 min-width-1445 min-height-504">
                <div className="grid-row">
                  <div className="grid-col-1 min-width-720 height-504">
                    <div className='dash-cards-container'>
                      <div>Avaliações realizadas</div>
                      <div className="tableFixHead" style={{ height: '100%' }}>
                        <table className='table-dash'>
                          <thead>
                            <tr>
                              <th>Nome do Conteúdo</th>
                              <th>Quantidade</th>
                              <th>%</th>
                              <th>Média Nota</th>
                            </tr>
                          </thead>
                          <tbody>
                            {normalizedTestsPerformed?.map((item, index) =>
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{formatNumber(item.total)}</td>
                                <td style={{ backgroundColor: item.percentageBackgroundColor }}>{item.percentage}</td>
                                <td>{formatNumber(item.averageScore)}</td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td>{formatNumber(evaluationsTotal)}</td>
                              <td>{formatNumber(evaluationsTotalPercentage)}</td>
                              <td>{formatNumber(evaluationsTotalAvg)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="grid-col-1 min-width-640 min-height-504">
                    <div className="grid-row">
                      <div className="grid-col-1 min-width-640 min-height-249">
                        <div className='dash-cards-container'>
                          <EvolutionCharts data={{ users: data?.usersEvolutionData, course: data?.coursesEvolutionData }} />
                        </div>
                      </div>
                    </div>
                    <div className="grid-row">
                      <div className="grid-col-1 min-width-640 min-height-249">
                        <div className='dash-cards-container'>
                          <ProgressEvolutionCharts data={data?.progressEvolutionData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert && <Alert variant="filled" severity="error">{alert}</Alert>}
    </>;
  };

  useEffect(() => {
    if (!isLoading) {
      dataSetUp();
      setUpFilters();
    }

  }, [isLoading]);

  return (
    <div className="engagement">
      <div className="filters-container">
        <div className="filter-container">
          <span className="filter-title">Período:</span>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.startDate}
                minDate={getDashboardStartDate()}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'startDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
              <DatePicker
                format="dd/MM/yyyy"
                disabled={isLoading}
                value={filters.endDate}
                maxDate={new Date()}
                minDate={filters.startDate}
                error={false}
                helperText={null}
                onChange={(newValue) => handleFilterChange(newValue, 'endDate')}
                cancelLabel="Cancelar"
                InputProps={{ className: classes.dateInput }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="filter-container">
          <span className="filter-title">Perfil:</span>
          <select value={persona} onChange={(e) => handleFilterChange(e, 'persona')}>
            {(isLoading) &&
              <option value='' disabled selected >Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.personas)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Idade:</span>
          <select value={ageRange} onChange={(e) => handleFilterChange(e, 'ageRange')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.ageRanges)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Gênero:</span>
          <select value={gender} onChange={(e) => handleFilterChange(e, 'gender')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.genders)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">UF:</span>
          <select value={state} onChange={(e) => handleFilterChange(e, 'state')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.states)
            }
          </select>
        </div>
        <div className="filter-container">
          <span className="filter-title">Renda:</span>
          <select value={income} onChange={(e) => handleFilterChange(e, 'income')}>
            {(isLoading) &&
              <option value='' disabled selected>Carregando...</option>
            }
            {!isLoading &&
              loadDropdown(data?.dropdownData?.incomes)
            }
          </select>
        </div>
        <div className="filter-container right">
          <IconButton onClick={() => clearFilters()} disabled={isLoading} title="Limpar filtros">
            <ClearAll />
          </IconButton>
          <IconButton onClick={() => refreshFilters()} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </div>
      </div>
      {isLoading ? showSkeleton() : showContent()}
    </div>
  );
}

export default Engagement;