import React from 'react';
import './styles/menuProfile.scss';

import ChangePasswordDialog from '../profile/ChangePassword';

import {
  withStyles,
  Grid,
  Box,
  Avatar,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Search, TurnedInNot } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { logout } from '../../../services/auth';
import { watch } from 'redux-easy/lib/redux-easy';
import { ReactComponent as ContactIcon } from '../../../assets/icon/header-icons/icon-contact.svg';
import { ReactComponent as FavoritesIcon } from '../../../assets/icon/header-icons/icon-favorites.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icon/header-icons/icon-search.svg';
import { ReactComponent as TutorialIcon } from '../../../assets/icon/header-icons/icon-tutorial.svg';
import { withRouter } from 'react-router-dom';

import AlertErrors from '../../components/alertErrors/alerts';

import api from '../../../services/api';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: '#202020',
    maxWidth: 220,
    fontSize: '14px',
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Globals = require('../../../Globals.json');

class menuProfile extends React.Component {
  state = {
    menuAnchorElement: null,
    value: 'perfil',
    anchorEl: false,
    changePassword: false,
    user_avatar: '',
    coins: 0,
    isfb: -1,
    level: 0,
    searchInput: '',
    showSearch: false,
    search: [],
    input: '',
    searchTerm: '',
    avatarInitials: '',
    alertError: '',

    // "Certificado enviado com sucesso!",
    // "Parabéns, você acaba de receber 300 pontos!",
    // "Novo Curso disponível na plataforma.",
    // "Parabéns, Curso de Finanças Pessoais concluído!",
  };
  handleTour() {
    // seta no redux tour true
    dispatchSet('tour.show', true);
  }

  handleAvatarClick = (event) => {
    console.log('evento', event);
    this.setState({ menuAnchorElement: event.target });
  };

  handleMenuClose = (e) => {
    console.log('evento c', e);
    if (
      [
        'imagePerfil',
        'imagePerfilImage',
        'MuiAvatar-root MuiAvatar-circle avatar initialAvatar MuiAvatar-colorDefault',
      ].indexOf(e.target.className) < 0
    ) {
      console.log('evento c2');
      this.setState({ menuAnchorElement: null });
    }
  };

  handleMenuLogout = () => {
    this.setState({ menuAnchorElement: null });
    logout();
    this.props.history.push(Globals.paths.root);
    
  };

  handleMenuKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ menuAnchorElement: null });
    }
  };
  async loadData() {
    try {
      const response = await api.get(Globals.api.selfInfo);

      dispatchSet('user.profile', response?.data);

      let fullName = response?.data?.username;
      if (fullName.indexOf(' ') === -1) fullName += ' ';
      const initials = fullName.match(/^(.).* (.)?/);

      let coins = response?.data?.progress?.total_moedas;

      this.setState({
        coins: coins,
        user_avatar: response?.data?.user_avatar,
        level: response?.data?.progress?.level,
        avatarInitials: initials[1] + (initials[2] ? initials[2] : ''),
      });
    } catch (error) {
      // if (error?.response?.status !== undefined) {
      this.setState({ alertError: 404 });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
      // }
    }
  }

  async loadIndiceData() {
    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });

      if (response?.data?.container?.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  loadSearch = (search) => {
    try {
      api
        .post('/api/v1/search/search_modules', {
          pesquisa: search,
        })
        .then((response) => {
          this.setState({
            search: response.data.resultado,
            searchLength: response.data.total_resultados,
          });
        });
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  componentDidMount() {
    this.loadData();
    //this.loadIndiceData();
  }

  handleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  onChangeHandler(e) {
    const search = e.target.value;
    this.setState({ input: e.target.value });
    this.loadSearch(search);
  }

  render() {
    const { search } = this.state;
    const arraySearch = search.slice(0, 10);

    const list = arraySearch.map((search, index) => (
      <li key={index} className={this.state.input === search && 'filterActive'}>
        <Link className="searchLink" to={`${search.module}/${search.id}`}>
          {search.nome_conteudo}
        </Link>
      </li>
    ));

    return (
      <>
        <Grid
          container
          className="menu-profile"
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ overflow: 'hidden' }}
        >
          <Box>
            <div className="iconsFlex">
              {!this.state.showSearch && (
                <HtmlTooltip title="Busca">
                  <SearchIcon className="iconsHeader" onClick={() => this.handleSearch()} />
                </HtmlTooltip>
              )}

              <HtmlTooltip title="Fale Conosco">
                <ContactIcon className="iconsHeader" onClick={() => this.props.handleContactForm(true)} />
              </HtmlTooltip>

              <HtmlTooltip title="Tutorial">
                <Link to={Globals.paths.home} style={{marginTop:'5px'}}>
                  <TutorialIcon
                    className="iconsHeader icon-header-tutorial"
                    onClick={() => this.handleTour()}
                  />
                </Link>
              </HtmlTooltip>

              <Link
                to={Globals.paths.favorite}
                style={{
                  background: 'none',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <HtmlTooltip title="Favoritos">
                  <FavoritesIcon className="iconsHeader" />
                </HtmlTooltip>
              </Link>

              <Avatar
                onClick={this.handleAvatarClick}
                className={`avatar ${this.props.user.profile.user_avatar ? '' : 'initialAvatar'}`}
              >
                {this.props.user.profile.user_avatar !== undefined &&
                this.props.user.profile.user_avatar !== null ? (
                  <div className="imagePerfil">
                    <img
                      className="imagePerfil"
                      src={this.props.user.profile.user_avatar}
                      alt="Avatar"
                    />
                  </div>
                ) : this.props.user.profile.user_avatar === undefined &&
                  this.state.user_avatar !== null &&
                  this.state.user_avatar !== undefined ? (
                  <div className="imagePerfil">
                    <img className="imagePerfil" src={this.state.user_avatar} alt="Avatar" />
                  </div>
                ) : (
                  <>
                    <div style={{ paddingTop: "2px" }}>
                      {this.state.avatarInitials}
                    </div>
                  </>
                )}
              </Avatar>

              <div className="menuProfile_positionLevels">
                {/* <span>Nível {this.state.level}</span> */}
                <span>Nível {this.props.lvl}</span>
                <span>
                  {' '}
                  {this.props.coins} Ponto{this.props.coins === 1 ? '' : 's'}{' '}
                </span>
              </div>
            </div>

            <Popper
              open={Boolean(this.state.menuAnchorElement)}
              anchorEl={this.state.menuAnchorElement}
              role={'button'}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper style={{ marinTop: '5px', flexDirection: 'row' }}>
                    <ClickAwayListener onClickAway={this.handleMenuClose}>
                      <MenuList
                        className="menuProfile_menuList"
                        autoFocusItem={Boolean(this.state.menuAnchorElement)}
                        id="menu-list-grow"
                        onKeyDown={this.handleMenuKeyDown}
                      >
                        <Link
                          to={Globals.paths.profile}
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          {' '}
                          <MenuItem onClick={this.handleMenuClose}>Perfil</MenuItem>{' '}
                        </Link>
                        <MenuItem onClick={() => this.setState({ changePassword: true })}>
                          Trocar Senha
                        </MenuItem>
                        {/* <Link to={Globals.paths.inbox} style={{ textDecoration: "none", color: "black" }}> <MenuItem onClick={this.handleMenuClose}>Mensagens</MenuItem> </Link> */}
                        <MenuItem onClick={this.handleMenuLogout}>Sair</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            {this.state.changePassword && (
              <ChangePasswordDialog
                open={this.state.changePassword}
                onClose={() => this.setState({ changePassword: false })}
              />
            )}
          </Box>

          {this.state.search && (
            <>
              <TextField
                placeholder="Buscar..."
                margin="normal"
                variant="outlined"
                value={this.state.input}
                onChange={this.onChangeHandler.bind(this)}
                onBlur={this.loadSearch}
                InputProps={{
                  type: 'text',
                  className: `${
                    this.state.showSearch === true
                      ? 'menu_profile_TextField'
                      : 'menu_profile_TextFieldHidden'
                  }`,
                  startAdornment: (
                    <button
                      onClick={() => this.setState({ showSearch: false, input: '' })}
                      type="button"
                      className="main_closedBtn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                      </svg>
                    </button>
                  ),
                }}
              />
              {this.state.input !== '' && (
                <div className="menu_profile_list">
                  <span>{list}</span>
                  <hr />
                </div>
              )}
            </>
          )}
        </Grid>
        {this.state.alertError !== '' && (
          <AlertErrors side="sideMenu" error={this.state.alertError} />
        )}
      </>
    );
  }
}
export default watch(withRouter(menuProfile), { user: '' });
