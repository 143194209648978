import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const MainText = withTheme(styled('div')`
  position: relative;
  max-width: ${({ size }) => size ? `${size}px` : 'auto'};
  z-index: 0;

  @media(max-width: 600px) {
    max-width: initial;
    width: 100%;
    padding: 40px;
  }

  @media only screen and (
    min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
  ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    max-width: initial;
    width: 70vw;
  }
  
  h2 {
    color: #005E50;
    font-size: 36px;
    line-height: 40px;
    font-family: 'elza';
    font-weight: 700;
    margin: 0;
    margin-bottom: 30px;
    
    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      font-size: 28px;
      line-height: 30px;
    } 

    &.thin {
      font-family: 'elza';
      font-size: 32px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 26px;
      } 
      
      strong {
        font-family: 'elza';
        font-weight: 700;
        font-size: 36px;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          font-size: 28px;
        } 
      }
    }
  }

  p {
    font-family: 'elza';
    color: #595959;
    font-size: 20px;
    margin: 0;
    margin-bottom: 30px;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      font-size: 18px;
    }
  }
`);
