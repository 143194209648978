import React from 'react';
import Button from '../Button';
import * as Styled from './OurNumbers.styles';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, ComposedChart } from 'recharts';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import Globals from '../../../../Globals.json';
import { Alert, Skeleton } from '@material-ui/lab';

const dataPersonasGeneroDefault = require("./personas-genero.json");
const dataFaixaEtariaDefaut = require("./faixa-etaria.json");
const dataAcessoDemograficoDefault = require("./acesso-demografico.json");
const totaisAcessoDemograficoDefault = getTotaisAcessoDemografico(dataAcessoDemograficoDefault);
const dataRendaDefault = require("./faixa-renda.json");

const dataRankCursosDefault = {
  "rank_1": {
    "name": "Mapeie sua vida financeira",
    "total": 27444,
    "percentage": 47.546777546777
  },
  "rank_2": {
    "name": "O planejamento na prática, de um jeito que funcione para você",
    "total": 7109,
    "percentage": 12.316354816354
  },
  "rank_3": {
    "name": "Construindo o Caminho",
    "total": 6757,
    "percentage": 11.706514206514
  }
}

const dataCursosRalizadosDefault = {
  "total": 57720,
  "average_score": 92.1,
  "progress": 77.32,
  "total_tests": 9935
}

const markers = [
  { id: 1, markerOffsetX: 0, markerOffsetY: 20, name: 'Acre', coordinates: [-70.8120, -9.3] },
  { id: 2, markerOffsetX: 35, markerOffsetY: 5, name: 'Alagoas', coordinates: [-35.7820, -9.5] },
  { id: 3, markerOffsetX: 0, markerOffsetY: 20, name: 'Amazonas', coordinates: [-66.0, -4.4168] },
  { id: 4, markerOffsetX: 0, markerOffsetY: 20, name: 'Amapá', coordinates: [-52.3, 1.7441] },
  { id: 5, markerOffsetX: 0, markerOffsetY: 20, name: 'Bahia', coordinates: [-42.0, -11.5] },
  { id: 6, markerOffsetX: 0, markerOffsetY: 20, name: 'Ceará', coordinates: [-40.3206, -4.5] },
  { id: 7, markerOffsetX: 0, markerOffsetY: -10, name: 'Distrito Federal', coordinates: [-48.0, -15.7975] },
  { id: 8, markerOffsetX: 0, markerOffsetY: -10, name: 'Espírito Santo', coordinates: [-40.3089, -19.1834] },
  { id: 9, markerOffsetX: 0, markerOffsetY: 20, name: 'Goiás', coordinates: [-50.5, -16.0] },
  { id: 10, markerOffsetX: 0, markerOffsetY: -10, name: 'Maranhão', coordinates: [-46.0, -5.0] },
  { id: 11, markerOffsetX: 0, markerOffsetY: 20, name: 'Minas Gerais', coordinates: [-45.7908, -18.9302] },
  { id: 12, markerOffsetX: 0, markerOffsetY: 20, name: 'Mato Grosso do Sul', coordinates: [-54.5, -19.7722] },
  { id: 13, markerOffsetX: 0, markerOffsetY: 20, name: 'Mato Grosso', coordinates: [-56.5, -13.6819] },
  { id: 14, markerOffsetX: 0, markerOffsetY: 20, name: 'Pará', coordinates: [-54.0, -5.2071] },
  { id: 15, markerOffsetX: 40, markerOffsetY: 5, name: 'Paraíba', coordinates: [-35.7820, -7.0] },
  { id: 16, markerOffsetX: 0, markerOffsetY: 20, name: 'Pernambuco', coordinates: [-39.7820, -8.2400] },
  { id: 17, markerOffsetX: 0, markerOffsetY: -10, name: 'Piauí', coordinates: [-43.0, -8.0] },
  { id: 18, markerOffsetX: 0, markerOffsetY: 20, name: 'Paraná', coordinates: [-52.0, -24.251] },
  { id: 19, markerOffsetX: 0, markerOffsetY: 20, name: 'Rio de Janeiro', coordinates: [-43.1729, -22.3] },
  { id: 20, markerOffsetX: 20, markerOffsetY: -10, name: 'Rio Grande do Norte', coordinates: [-35.9541, -5.5] },
  { id: 21, markerOffsetX: 0, markerOffsetY: 20, name: 'Rondônia', coordinates: [-62.5, -11.5057] },
  { id: 22, markerOffsetX: 0, markerOffsetY: 20, name: 'Roraima', coordinates: [-61.5, 2.1316] },
  { id: 23, markerOffsetX: 0, markerOffsetY: 20, name: 'Rio Grande do Sul', coordinates: [-53.2090, -29.0368] },
  { id: 24, markerOffsetX: 0, markerOffsetY: 20, name: 'Santa Catarina', coordinates: [-51.3653, -26.9286] },
  { id: 25, markerOffsetX: 0, markerOffsetY: 20, name: 'Sergipe', coordinates: [-37.5, -10.6739] },
  { id: 26, markerOffsetX: 0, markerOffsetY: 20, name: 'São Paulo', coordinates: [-49.6396, -21.5558] },
  { id: 27, markerOffsetX: 0, markerOffsetY: 20, name: 'Tocantins', coordinates: [-48.0, -10.4099] }
]

const colorsPersonaChart = {
  visionario: '#FF6E5A',
  poupador: '#B0B0B0',
  organizado: '#202020',
  equilibrado: '#595959',
  malabarista: '#DAF439',
  enrolado: '#05CCAE',
  pressionado: '#005E50'
}

const dataPersonaFinanceiraDefault = {
  pressionado: {
    total: 55997,
    moda: 21.196885
  },
  enrolado: {
    total: 22988,
    moda: 42.569993
  },
  malabarista: {
    total: 9208,
    moda: 52.947545
  },
  organizado: {
    total: 5013,
    moda: 64.08458
  },
  equilibrado: {
    total: 3756,
    moda: 58.56443
  },
  poupador: {
    total: 4422,
    moda: 74.29127
  },
  visionario: {
    total: 1040,
    moda: 87.849038
  },
  todas_personas: {
    total: 102424,
    moda: 35.2867
  }
}

function getDataPersonasParaGrafico(dataPersonaFinanceira) {
  const dataParaGrafico = [];
  for (const property in dataPersonaFinanceira) {
    if (property === 'todas_personas') continue;

    const dados = {
      name: property,
      value: dataPersonaFinanceira[property].total
    }
    dataParaGrafico.push(dados);
  }

  return dataParaGrafico;
}

const dataPersonaChartDefault = getDataPersonasParaGrafico(dataPersonaFinanceiraDefault);

function getPorcentagemGruposPersonas(dataPersonaFinanceira) {
  return {
    endividados: ((dataPersonaFinanceira.pressionado.total +
      dataPersonaFinanceira.enrolado.total) /
      dataPersonaFinanceira.todas_personas.total),
    balanceados: ((
      dataPersonaFinanceira.malabarista.total +
      dataPersonaFinanceira.equilibrado.total +
      dataPersonaFinanceira.organizado.total) /
      dataPersonaFinanceira.todas_personas.total),
    investidores: ((dataPersonaFinanceira.visionario.total +
      dataPersonaFinanceira.poupador.total) /
      dataPersonaFinanceira.todas_personas.total)
  }
}

const porcentagemGruposPersonasDefault = getPorcentagemGruposPersonas(dataPersonaFinanceiraDefault);

function getTotaisAcessoDemografico(dataAcessoDemografico) {
  const totais = {
    masculino: 0,
    feminino: 0,
    nao_informado: 0,
    total: 0
  };

  for (let id = 1; id <= 27; id++) {
    const estado = dataAcessoDemografico?.[`id_${id}`] ?? null;
    totais[`id_${id}`] = 0

    if (estado) {
      let soma = (estado?.homens ?? 0) + (estado?.mulheres ?? 0) + (estado?.nao_informado ?? 0);
      totais[`id_${id}`] = soma;
      totais.masculino += estado?.homens ?? 0;
      totais.feminino += estado?.mulheres ?? 0;
      totais.nao_informado += estado?.nao_informado ?? 0;
      totais.total += soma;
    }
  }

  return totais;
}

const widthMapa = 600;
const heightMapa = 700;
const widthRendaX = 172;


const mapCenter = [-54.7, -17];

let lastOffsetRendaMargin = null;

function OurNumbers({ openRegisterDialog, logHomeAction, enumLogHomeAction }) {
  const [dataAcessoDemografico, setDataAcessoDemografico] = React.useState(dataAcessoDemograficoDefault);
  const [totaisAcessoDemografico, setTotaisAcessoDemografico] = React.useState(totaisAcessoDemograficoDefault);
  const [dataRenda, setDataRenda] = React.useState(dataRendaDefault);
  const [dataPersonasGenero, setDataPersonasGenero] = React.useState(dataPersonasGeneroDefault);
  const [dataFaixaEtaria, setDataFaixaEtaria] = React.useState(dataFaixaEtariaDefaut);
  const [dataRankCursos, setDataRankCursos] = React.useState(dataRankCursosDefault);
  const [dataCursosRalizados, setDataCursosRalizados] = React.useState(dataCursosRalizadosDefault);
  const [dataPersonaFinanceira, setDataPersonaFinanceira] = React.useState(dataPersonaFinanceiraDefault);
  const [dataPersonaChart, setDataPersonaChartDefault] = React.useState(dataPersonaChartDefault);
  const [porcentagemGruposPersonas, setPorcentagemGruposPersonas] = React.useState(porcentagemGruposPersonasDefault);
  const [dataTotalRegistrosPlataforma, setDataTotalRegistrosPlataforma] = React.useState({ total: 145494 });

  const [marginsFaixaRendaGraph, setMarginsFaixaRendaGraph] = React.useState({
    top: 20,
    right: 70,
    bottom: 0,
    left: 20,
  });

  const [ourNumberLoaded, setOurNumberLoaded] = React.useState(false);

  React.useEffect(async () => {
    setTotaisAcessoDemografico(getTotaisAcessoDemografico(dataAcessoDemografico));
    setDataPersonaChartDefault(getDataPersonasParaGrafico(dataPersonaFinanceira));
    setPorcentagemGruposPersonas(getPorcentagemGruposPersonas(dataPersonaFinanceira));

    if (ourNumberLoaded) return;

    const fetchApiOurNumbers = async () => {
      let res_ok = false;
      const response = await fetch(`${process.env.REACT_APP_API}${Globals.api.ourNumbersIndicators}`)
        .then((response) => {
          if (response.ok) res_ok = true;
          return response;
        })
        .catch((error) => {
          console.log(error);
        });

      const data = await response.json()
        .catch((error) => {
          console.log(error);
        });

      if (data && data?.dataRenda.length > 0) {
        setDataAcessoDemografico(data.dataAcessoDemografico);
        setDataRenda(data.dataRenda);
        setDataPersonasGenero(data.dataPersonasGenero);
        setDataFaixaEtaria(data.dataFaixaEtaria);
        setDataRankCursos(data.dataRankCursos);
        setDataCursosRalizados(data.dataCursosRalizados);
        setDataPersonaFinanceira(data.dataPersonaFinanceira);
        setDataTotalRegistrosPlataforma(data.dataTotalRegistrosPlataforma);
      }
    };
    await fetchApiOurNumbers();

    if (!ourNumberLoaded) setOurNumberLoaded(true);

  }, [ourNumberLoaded]);


  const CustomTooltipUsuariosPersona = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      const name = payload[0].name;
      const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
      const fillColor = payload[0].payload.fill;

      return (
        <div className="custom-tooltip-persona">
          <p className="label" style={{ color: `${fillColor}` }}>{`${capitalizedName}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabelRenda = (props) => {
    const {
      x, y, width, height, value, viewBox
    } = props;


    const offsetAreaUtil = (window.innerWidth >= 600) ? 50 : 30;
    const areaUtil = window.innerWidth - offsetAreaUtil - widthRendaX - 20;
    const offsetFontSize = window.innerWidth < 400;

    const fireOffset2 = areaUtil - viewBox.width > 87;
    const offset2 = fireOffset2 ? -2 : 2;

    const offsetMargin = window.innerWidth < 400;

    if (lastOffsetRendaMargin !== offsetMargin) {
      lastOffsetRendaMargin = offsetMargin;
      setMarginsFaixaRendaGraph({
        top: 20,
        right: offsetMargin ? 0 : 70,
        bottom: 0,
        left: 20,
      });
    }

    return (
      <text x={x + width - offset2} y={y + height - 8} fill={fireOffset2 ? "#000" : "#fff"} textAnchor={fireOffset2 ? "start" : "end"} fontSize={offsetFontSize ? '1.6em' : '2em'} fontWeight='700' position='insideRight'>
        {value.toLocaleString('pt-BR')}
      </text>
    );
  };

  const [content, setContent] = React.useState("");
  const [zoom, setZoom] = React.useState(1);

  const handleFilter = ({ constructor: { name } }) => {
    return name !== "WheelEvent";
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.125);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.125);
  };

  function setToolTipEstado(id, name = '') {
    const estado = dataAcessoDemografico?.[`id_${id}`] ?? null;
    let _tooltip = ``;

    if (estado) {
      _tooltip = `${name}<br/>Masculino: ${estado?.homens ?? 0}<br/>Feminino: ${estado?.mulheres ?? 0}<br/>Prefiro não informar: ${estado?.nao_informado ?? 0}`;
    }
    setContent(_tooltip);
  };

  return (
    <Styled.OurNumbers>

      <div className="inner-wrapper-container">
        <div className="inner-wrapper" >

          {!ourNumberLoaded && (
            <>
              <div className="row-our-numbers">
                <div className="col-40pct h-340px">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
                <div className="col-60pct h-340px">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-40pct h-340px">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
                <div className="col-60pct h-340px">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-100pct h-700px-small-devices-300px-big-devices">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-40pct">
                  <div className="row-our-numbers h-min-400px-auto flex-our-numbers-column">
                    <div className="h-60pct w-100pct ">
                      <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                    </div>
                    <div className="h-40pct w-100pct">
                      <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                    </div>
                  </div>
                </div>
                <div className="col-60pct b-rad-10px bg-cell-color-1 pad-15px map-container">
                  <div className="h-min-400px-auto w-100pct">
                    <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                  </div>
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-100pct h-420px">
                  <Skeleton variant="rect" width={"100%"} height={"100%"} className="b-rad-10px" />
                </div>
              </div>
            </>
          )}

          {ourNumberLoaded && (
            <>
              <div className="row-our-numbers">
                <div className="col-40pct">
                  <div className="w-100pct b-rad-10px bg-cell-color-1 pad-15px">
                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Usuários totais:</span>
                    </div>

                    <div className="row-our-numbers h-280px flex-our-numbers-column">

                      <div className="h-40pct w-100pct pos_relative">
                        <div className="vert-center w-100pct disable-m-left-small-devices">
                          <p className="usuarios-totais">
                            {(dataTotalRegistrosPlataforma.total).toLocaleString('pt-BR')}
                          </p>
                        </div>
                      </div>

                      <hr className="w-100pct"></hr>

                      <div className="h-60pct w-100pct pos_relative">
                        <div className="row-our-numbers w-100pct h-100pct flex-direction-row-force just-cont-sb">

                          <div className="col-50pct">
                            <div className="vert-center w-50pct disable-m-left-small-devices pos_absolute">
                              <div className="row-our-numbers flex-direction-row-force">
                                <div>
                                  <div className="subtitulo-tipos-usuarios">Endividados</div>
                                  <p className="usuarios-totais-interno">
                                    {(
                                      dataPersonaFinanceira.pressionado.total +
                                      dataPersonaFinanceira.enrolado.total
                                    ).toLocaleString('pt-BR')}
                                  </p>
                                </div>
                                <div className="usuarios-totais-porcentagem-table">
                                  <span className="usuarios-totais-porcentagem-cell">
                                    {(porcentagemGruposPersonas.endividados).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'percent' })}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="vert-line"></div>

                          <div className="col-50pct">
                            <div className="vert-center w-50pct disable-m-left-small-devices pos_absolute">

                              <div className="row-our-numbers flex-direction-row-force">
                                <div>
                                  <div className="subtitulo-tipos-usuarios">Balanceados</div>
                                  <p className="usuarios-totais-interno">
                                    {(
                                      dataPersonaFinanceira.malabarista.total +
                                      dataPersonaFinanceira.equilibrado.total +
                                      dataPersonaFinanceira.organizado.total
                                    ).toLocaleString('pt-BR')}
                                  </p>
                                </div>
                                <div className="usuarios-totais-porcentagem-table">
                                  <span className="usuarios-totais-porcentagem-cell">
                                    {(porcentagemGruposPersonas.balanceados).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'percent' })}
                                  </span>
                                </div>
                              </div>

                              <div className="row-our-numbers flex-direction-row-force">
                                <div>
                                  <div className="subtitulo-tipos-usuarios">Investidores</div>
                                  <p className="usuarios-totais-interno">
                                    {(
                                      dataPersonaFinanceira.poupador.total +
                                      dataPersonaFinanceira.visionario.total
                                    ).toLocaleString('pt-BR')}
                                  </p>
                                </div>
                                <div className="usuarios-totais-porcentagem-table">
                                  <span className="usuarios-totais-porcentagem-cell">
                                    {(porcentagemGruposPersonas.investidores).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'percent' })}
                                  </span>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="col-60pct">
                  <div className="w-100pct b-rad-10px bg-cell-color-1 pad-15px">
                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Usuários por persona financeira:</span>
                    </div>


                    <div className="row-our-numbers h-min-280px-auto flex-our-numbers-column">

                      <div className="w-100pct pos_relative just-cont-sb d-flex gap-10px circles-container">
                        <div className="circles-big-content col-50pct col-50pct-force-min-500px h-280px">

                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={100} height={100}>
                              <Pie
                                data={dataPersonaChart}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label=''
                                outerRadius={120}
                                fill="#8884d8"
                                dataKey="value"
                                stroke="none"
                                animationDuration={500}
                              >
                                {dataPersonaChart.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={colorsPersonaChart[entry.name]} />
                                ))}
                              </Pie>
                              <Tooltip content={<CustomTooltipUsuariosPersona />} />
                            </PieChart>
                          </ResponsiveContainer>


                        </div>

                        <div className="vert-line h-min-280px display-none-minus-500px"></div>

                        <div className="col-50pct col-50pct-force-min-500px place-c-center">


                          <div className="moda-indice-table">

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-1">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.pressionado.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Pressionados
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-2">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.enrolado.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Enrolados
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-3">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.malabarista.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Malabaristas
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-4">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.organizado.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Organizados
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-5">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.equilibrado.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Equilibrados
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-6">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.poupador.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Poupadores
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="circulo-pequeno b-color-rank-7">
                              </div>
                              <div className="moda-indice-cell usuarios-totais-interno-moda">
                                {(dataPersonaFinanceira.visionario.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="moda-indice-cell font-color-1">
                                Visionários
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-40pct">
                  <div className="w-100pct b-rad-10px bg-cell-color-1 pad-15px">
                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Média do Índice de Saúde Brasileira na Plataforma:</span>
                    </div>

                    <div className="row-our-numbers h-280px flex-our-numbers-column">

                      <div className="h-40pct w-100pct pos_relative bg-cell-color-1">
                        <div className="vert-center w-100pct disable-m-left-small-devices">
                          <p className="usuarios-totais">
                            {(dataPersonaFinanceira.todas_personas.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </p>
                        </div>
                      </div>



                      <div className="h-60pct w-100pct">
                        <div className="row-our-numbers w-100pct h-100pct flex-direction-row-force">

                          <div className="col-50pct col-50pct-force just-cont-fend">
                            <div className="w-50pct disable-m-left-small-devices right-0px">
                              <div className="moda-indice-table">

                                <div className="moda-indice-row">
                                  <div className="moda-indice-cell usuarios-totais-interno-moda">
                                    {(dataPersonaFinanceira.pressionado.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </div>
                                  <div className="moda-indice-cell font-color-1">
                                    Pressionados
                                  </div>
                                </div>

                                <div className="moda-indice-row">
                                  <div className="moda-indice-cell usuarios-totais-interno-moda">
                                    {(dataPersonaFinanceira.enrolado.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </div>
                                  <div className="moda-indice-cell font-color-1">
                                    Enrolados
                                  </div>
                                </div>

                                <div className="moda-indice-row">
                                  <div className="moda-indice-cell usuarios-totais-interno-moda">
                                    {(dataPersonaFinanceira.malabarista.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </div>
                                  <div className="moda-indice-cell font-color-1">
                                    Malabaristas
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="vert-line"></div>

                          <div className="col-50pct col-50pct-force">
                            <div className="col-50pct">
                              <div className="w-50pct disable-m-left-small-devices">
                                <div className="moda-indice-table">

                                  <div className="moda-indice-row">
                                    <div className="moda-indice-cell usuarios-totais-interno-moda">
                                      {(dataPersonaFinanceira.organizado.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                    <div className="moda-indice-cell font-color-1">
                                      Organizados
                                    </div>
                                  </div>

                                  <div className="moda-indice-row">
                                    <div className="moda-indice-cell usuarios-totais-interno-moda">
                                      {(dataPersonaFinanceira.equilibrado.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                    <div className="moda-indice-cell font-color-1">
                                      Equilibrados
                                    </div>
                                  </div>

                                  <div className="moda-indice-row">
                                    <div className="moda-indice-cell usuarios-totais-interno-moda">
                                      {(dataPersonaFinanceira.poupador.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                    <div className="moda-indice-cell font-color-1">
                                      Poupadores
                                    </div>
                                  </div>

                                  <div className="moda-indice-row">
                                    <div className="moda-indice-cell usuarios-totais-interno-moda">
                                      {(dataPersonaFinanceira.visionario.moda).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                    <div className="moda-indice-cell font-color-1">
                                      Visionários
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="col-60pct">
                  <div className="w-100pct b-rad-10px bg-cell-color-1 pad-15px pos_relative">
                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Personas por gênero</span>
                    </div>
                    <div className="h-280px w-100pct pos_relative bg-cell-color-2 b-rad-10px pad-15px">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={dataPersonasGenero}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 10,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" angle={-15} textAnchor="end" />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" wrapperStyle={{
                            paddingBottom: "10px"
                          }} />
                          <Bar dataKey="Feminino" stackId="gen" fill="#ed589d" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                          <Bar dataKey="Masculino" stackId="gen" fill="#6c63f0" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                          <Bar dataKey="Prefiro não informar" stackId="gen" fill="#edc600" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                          {/*}
                      <Bar dataKey="Não Informado" stackId="gen" fill="#edc600" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                      */}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-100pct">
                  <div className="h-700px-small-devices-300px-big-devices w-100pct b-rad-10px bg-cell-color-1 pad-15px">

                    <div className="row-our-numbers h-100pct reverse-flex-small-devices pos_relative">

                      <div className="col-40pct h-100pct pos_relative">
                        <div className="vert-center m-left-50px disable-m-left-small-devices">
                          <h3 className="thin no-top-margin">Mude sua vida financeira agora!</h3>
                          <p className="font-color-1">Aprenda a organizar seu dinheiro para ter uma vida mais tranquila. Educação financeira online e gratuita. Aproveita!</p>
                          <span onClick={logHomeAction.bind(this, { user_action: enumLogHomeAction.nossosNumerosConhecaPlataforma })}>
                            <Button onAction={openRegisterDialog}>Conheça a plataforma</Button>
                          </span>
                        </div>
                      </div>

                      <div className="col-60pct h-100pct pos_relative">
                        <figure>
                          <img className='responsive-image' src="./images/public-trail/conheca-plataforma.png" alt="Conheça a plataforma" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-40pct">
                  <div className="row-our-numbers h-min-400px-auto flex-our-numbers-column">
                    <div className="h-60pct w-100pct b-rad-10px bg-cell-color-1 pad-15px">
                      <div className="w-100pct b-rad-10px bg-cell-color-1">
                        <div className="w-100pct pad-bottom-10px">
                          <span className="block-title-style">+ O quê os brasileiros estão aprendendo:</span>
                        </div>

                        <div className="row-our-numbers flex-our-numbers-column">

                          <div className="h-60pct w-100pct pos_relative">
                            <p className="font-color-1">
                              A plataforma oferece cursos de educação e letramento financeiro para a população, estão são os cursos mais consumidos na ferramenta:
                            </p>
                            <div className="moda-indice-table w-100pct">

                              <div className="moda-indice-row">
                                <div className="moda-indice-cell usuarios-totais-interno-moda pad-top-4px font-size-2em w-80px">
                                  {dataRankCursos['rank_1'].percentage.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                                </div>
                                <div className="moda-indice-cell">
                                  <div className="cell-rounded w-100pct text-uppercase">{dataRankCursos['rank_1'].name}</div>
                                </div>
                              </div>

                              <div className="moda-indice-row">
                                <div className="moda-indice-cell usuarios-totais-interno-moda font-size-2em pad-top-4px w-80px">
                                  {dataRankCursos['rank_2'].percentage.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                                </div>
                                <div className="moda-indice-cell">
                                  <div className="cell-rounded w-100pct text-uppercase">{dataRankCursos['rank_2'].name}</div>
                                </div>
                              </div>

                              <div className="moda-indice-row">
                                <div className="moda-indice-cell usuarios-totais-interno-moda pad-top-4px font-size-2em w-80px">
                                  {dataRankCursos['rank_3'].percentage.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                                </div>
                                <div className="moda-indice-cell">
                                  <div className="cell-rounded w-100pct text-uppercase">{dataRankCursos['rank_3'].name}</div>
                                </div>
                              </div>

                            </div>
                          </div>

                          <hr className="w-100pct"></hr>

                          <div className="h-40pct w-100pct">
                            <div className="row-our-numbers w-100pct h-100pct flex-direction-row-force just-cont-sb">

                              <div className="col-50pct">
                                <div className="w-50pct disable-m-left-small-devices">
                                  <div className="row-our-numbers flex-direction-row-force">
                                    <div>
                                      <div className="subtitulo-tipos-usuarios">+Cursos realizados:</div>
                                      <p className="usuarios-totais-provas-interno cursos-realizados">{dataCursosRalizados.total.toLocaleString('pt-BR')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="vert-line h-min-160px"></div>

                              <div className="col-50pct">
                                <div className="w-50pct disable-m-left-small-devices">

                                  <div className="w-100pct">
                                    <div>
                                      <div className="subtitulo-tipos-usuarios">Avaliações realizadas:</div>
                                      <p className="usuarios-totais-provas-interno">{dataCursosRalizados.total_tests.toLocaleString('pt-BR')}</p>
                                    </div>
                                  </div>

                                  <div className="w-100pct">
                                    <div>
                                      <div className="subtitulo-tipos-usuarios">Nota média</div>
                                      <p className="usuarios-totais-provas-interno">{dataCursosRalizados.average_score.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 2 })}</p>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>

                        </div>

                      </div>



                    </div>
                    <div className="h-40pct w-100pct b-rad-10px bg-cell-color-1 pad-15px">
                      <div className="w-100pct pad-bottom-10px">
                        <span className="block-title-style">+ Faixa etária de usuários da plataforma:</span>
                      </div>
                      <div className="w-100pct pos_relative bg-cell-color-2 b-rad-10px pad-15px" style={{ height: 'calc(100% - 30px)' }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={dataFaixaEtaria}
                            margin={{
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 10,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-15} textAnchor="middle" />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" wrapperStyle={{
                              paddingBottom: "10px"
                            }} />
                            <Bar dataKey="Visionário" stackId="gen" fill="#FF6E5A" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                            <Bar dataKey="Poupador" stackId="gen" fill="#B0B0B0" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="Organizado" stackId="gen" fill="#202020" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="Equilibrado" stackId="gen" fill="#595959" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="Malabarista" stackId="gen" fill="#DAF439" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="Enrolado" stackId="gen" fill="#05CCAE" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="Pressionado" stackId="gen" fill="#005E50" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-60pct b-rad-10px bg-cell-color-1 pad-15px map-container">
                  <div className="h-min-400px-auto w-100pct">
                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Acesso demográfico da plataforma:</span>
                    </div>
                    <ComposableMap
                      className='mapa-demografico'
                      projection={"geoMercator"}
                      width={widthMapa}
                      height={heightMapa}
                      projectionConfig={{
                        scale: 800,
                      }}
                      style={{
                        width: "100%",
                        maxHeight: "719px"
                      }}
                      data-tip="">
                      <ZoomableGroup center={mapCenter} zoom={zoom} filterZoomEvent={handleFilter}>
                        <Geographies geography='/brazil3-map.json'>
                          {({ geographies }) =>
                            geographies.map((geo) => (
                              <Geography key={geo.rsmKey}
                                geography={geo}
                                data-tip={geo.properties.name}
                                strokeWidth={0.5}
                                onMouseEnter={() => {
                                  setToolTipEstado(`${geo.properties.id}`, `${geo.properties.name}`);
                                }}
                                onMouseLeave={() => {
                                  setContent("");
                                }}
                                style={{
                                  default: {
                                    fill: "#05CCAE",
                                    stroke: "#005E50"
                                  },
                                  hover: {
                                    fill: "#005E50",
                                  },
                                }} />
                            ))
                          }
                        </Geographies>
                        {markers.map(({ id, name, coordinates, markerOffsetY, markerOffsetX }) => (
                          <Marker key={name} coordinates={coordinates}>
                            <circle r={5} fill="#F00" stroke="#fff" strokeWidth={2} pointer-events="none" />
                            <text
                              pointer-events="none"
                              textAnchor="middle"
                              y={markerOffsetY}
                              x={markerOffsetX ?? 0}
                              style={{ fill: "#000" }}
                            >
                              {totaisAcessoDemografico?.[`id_${id}`] ?? 0}
                            </text>
                          </Marker>
                        ))}
                      </ZoomableGroup>
                    </ComposableMap>
                    <ReactTooltip html={true} backgroundColor='#DAF439' textColor='#000'>{content}</ReactTooltip>
                  </div>

                  <div className="d-flex-align-end">
                    <div className="col-100pct flex-direction-column-force">
                      <div className="w-100pct disable-m-left-small-devices">

                        <div className="h-100pct row-our-numbers flex-our-numbers-column map-content-legends flex-direction-row-force">

                          <div className="moda-indice-table">

                            <div className="moda-indice-row">
                              <div className="map-content-legends-cell map-totais-content-legends t-align-right">
                                {(totaisAcessoDemografico.masculino).toLocaleString('pt-BR')}
                              </div>
                              <div className="map-content-legends-cell font-color-1">
                                Masculino
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="map-content-legends-cell map-totais-content-legends t-align-right">
                                {(totaisAcessoDemografico.feminino).toLocaleString('pt-BR')}
                              </div>
                              <div className="map-content-legends-cell font-color-1">
                                Feminino
                              </div>
                            </div>
                          </div>

                          <div className="moda-indice-table">

                            <div className="moda-indice-row">
                              <div className="map-content-legends-cell map-totais-content-legends t-align-right">
                                {(totaisAcessoDemografico.nao_informado).toLocaleString('pt-BR')}
                              </div>
                              <div className="map-content-legends-cell font-color-1">
                                Prefiro não informar
                              </div>
                            </div>

                            <div className="moda-indice-row">
                              <div className="map-content-legends-cell map-totais-content-legends t-align-right">
                                {(totaisAcessoDemografico.total).toLocaleString('pt-BR')}
                              </div>
                              <div className="map-content-legends-cell font-color-1">
                                Total
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="zoom-btns">
                        <button onClick={handleZoomIn}> + </button>
                        <button onClick={handleZoomOut}> - </button>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

              <div className="row-our-numbers">
                <div className="col-100pct">
                  <div className="w-100pct b-rad-10px bg-cell-color-1 pad-15px h-420px">

                    <div className="w-100pct pad-bottom-10px">
                      <span className="block-title-style">+ Faixa de Renda:</span>
                    </div>

                    <div className="h-400px pos_relative top-30px-neg">

                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          layout="vertical"
                          width={500}
                          height={400}
                          data={dataRenda}
                          margin={marginsFaixaRendaGraph}
                        >
                          <CartesianGrid stroke="none" />
                          <XAxis type="number" axisLine={false} hide={true} />
                          <YAxis dataKey="faixa" type="category" scale="band" width={widthRendaX} textAnchor="end" axisLine={false} dx={-10} dy={27} tickLine={false} />
                          <Tooltip />
                          <Bar dataKey="Quantidade" barSize={35} fill="#595959" label={renderCustomizedLabelRenda} animationDuration={500} />
                        </ComposedChart>
                      </ResponsiveContainer>

                    </div>

                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Styled.OurNumbers>
  );
}

export default OurNumbers;
