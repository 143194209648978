import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import AreaChartDashboard from '../../Components/Charts/AreaChartDashboard';
import './ProgressEvolutionCharts.scss';


export default function ProgressEvolutionCharts({ data }) {
  const [evolutivePeriod, setEvolutivePeriod] = useState('monthly')
  const [progressData, setProgressData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [drillUp, setDrillUp] = useState(false);

  const loadData = async () => {
    let period;
    let groups;

    if (evolutivePeriod === 'monthly') {
      period = 'yearAndMonth';
      setDrillUp(false);
    } else if (evolutivePeriod === 'weekly') {
      period = 'week';
      setDrillUp(true);
    } else if (evolutivePeriod === 'daily') {
      period = 'date';
      setDrillUp(true);
    }

    groups = data?.reduce((groups, item) => {
      const group = (groups[item[period]] || []);
      if (evolutivePeriod === 'daily' && item.week === selectedWeek) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'weekly' && item.yearAndMonth === selectedMonth) {
        group.push(item);
        groups[item[period]] = group;
      }

      if (evolutivePeriod === 'monthly') {
        group.push(item);
        groups[item[period]] = group;
      }

      return groups;
    }, {});

    const dataFormated = await formatData(groups);
    setProgressData(dataFormated);
  };

  const formatData = async (groups) => {
    let result = [];

    if (groups && (evolutivePeriod !== 'daily')) {
      Object.keys(groups).map(item => {
        if (groups[item].length > 1) {
          let barColumn = groups[item].reduce((accumulator, currentValue) => {
            return {
              name: item,
              total: accumulator.total + currentValue.total,
              periodToEvaluate: evolutivePeriod,
              week: accumulator.week,
              day: currentValue.date
            };
          });

          barColumn['total'] = (barColumn.total / groups[item].length);
          result.push(barColumn);
        } else if (groups[item].length === 1) {
          const value = groups[item][0];

          result.push({
            name: item,
            total: value.total,
            periodToEvaluate: evolutivePeriod,
            week: value.week,
            day: value.date
          });
        }
      });
    };

    if (groups && (evolutivePeriod === 'daily')) {
      Object.keys(groups).map(item => {
        groups[item][0]['name'] = item;
        result.push(groups[item][0]);
      })
    }

    return result;
  };

  const handleDrillUp = () => {
    if (evolutivePeriod === 'daily') {
      setEvolutivePeriod('weekly');
      setSelectedPeriod(selectedWeek)
    } else if (evolutivePeriod === 'weekly') {
      setEvolutivePeriod('monthly');
    }
  };

  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();

  }, [evolutivePeriod, selectedPeriod, selectedMonth]);

  return (
    <div className='progress-evolution-chart-container-egmt'>
      <div className='header'>
        <div>
          Evolutivo de progresso Mensal / Semanal / Diário
        </div>
        <div className='btn-container'>
          {drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;-</button>}
        </div>
      </div>
      <div className='chart-container'>
        <ResponsiveContainer width={'99%'} height={'100%'}>
          <AreaChartDashboard data={progressData}
            dataKey={'total'}
            name={'Progresso'}
            fillColor={'#DAF439'}
            toolTipName={'Período'}
            chartHeight={190}
            legend={false}
            evolutivePeriod={setEvolutivePeriod}
            evolutiveWeekSelected={setSelectedWeek}
            evolutiveMonthSelected={setSelectedMonth}
            chartMargin={{ top: 20, right: 30, bottom: 20, left: 30 }}
            angle={-45} />
        </ResponsiveContainer>
      </div>
    </div>
  )
}
