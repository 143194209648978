import React, { useEffect, useState } from 'react';
import './styles.scss';

import {
  Grid, Table, TableBody, TableCell, TableContainer, CircularProgress,
  TableRow, TableHead, TableSortLabel, TextField, FormControl, Select,
  MenuItem, Paper
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../../../button/Button';

import api from "../../../../../services/api";
import { Link } from 'react-router-dom';
const Globals = require("../../../../../Globals.json");

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    // minHeight: 400,
    position: 'relative',
  },
  urlLink: {
    color: "#005E50",
    // "&:hover": {
    //   color: "#53AF98",
    // }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const [filterName, setFilterName] = useState('');
  const [filterURL, setFilterURL] = useState('');
  const [filterStatus, setFilterStatus] = useState(1);
  const [filterPublishStatus, setFilterPublishStatus] = useState('null');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');

  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getItemsPerPage(newPage, rowsPerPage)
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    getItemsPerPage(page, e.target.value)
  };

  const filterBy = () => {
    getItemsPerPage(1, rowsPerPage)
  }

  const getItemsPerPage = (page, perPage) => {
    setIsLoading(true)

    let q = "?page=" + page + "&per_page=" + perPage;
    if (filterName != '') q += "&busca=" + filterName;
    if (filterURL != '') q += "&busca_url=" + filterURL;
    if (filterDateFrom != '') q += "&initial_date=" + filterDateFrom;
    if (filterDateTo != '') q += "&final_date=" + filterDateTo;
    if (filterStatus != 'null') q += "&type_module_status_id=" + filterStatus;
    if (filterPublishStatus != 'null') q+= "&publish_status_id=" + filterPublishStatus;

    api.get(Globals.api.postsBlog + q, {
    }).then(response => {
      const data = response?.data?.data;
      const total = response?.data?.total;
      setRows(data)
      setTotal(total)
      setLastPage(response?.data?.lastPage)
      setPage(page)
      setRowsPerPage(perPage)
      setIsLoading(false)

    }).catch(error => {
      console.log(error)
    })
  }

  const clearFields = () => {
    setFilterName('');
    setFilterURL('');
    setFilterStatus('null');
    setFilterPublishStatus('null');
    setFilterDateFrom('');
    setFilterDateTo('');
  }

  useEffect(() => {
    getItemsPerPage(page, rowsPerPage)
  }, [])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid className="backoffice-content">
      <Grid className="content" style={{ marginBottom: '70px' }}>
        <Grid className="box">
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Lista de Artigos</span>
          </div>


          <div className="containerBlogBtns">

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <input
                placeholder="Buscar por nome"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <input
                placeholder="Busca por URL"
                value={filterURL}
                onChange={(e) => setFilterURL(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
                <option value="null">Todos</option>
                <option value={0}>Rascunho (Do Blog)</option>
                <option value={1}>Publicado (No Blog)</option>
                <option value={2}>Aprovação (No Blog)</option>
                <option value={3}>Reprovado (No Blog)</option>
                <option value={4}>Editando (No Blog)</option>
              </select>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <select value={filterPublishStatus} onChange={(e) => setFilterPublishStatus(e.target.value)}>
                <option value="null">Todos</option>
                <option value={1}>Publicado</option>
                <option value={2}>Não Publicado</option>
              </select>
            </Grid>

          </Grid>







            {/* <select value={searchStatus} style={{ width: "200px" }} onChange={(e) => setSearchStatus(e.target.value)} > */}
            {/* <select onChange={(e) => setFilterStatus(e.target.value)}>
              <option value=''>Status</option>
              <option value='1'>Publicado</option>
              <option value='2'>Não Publicado</option>
            </select> */}

          </div>
          <div className="containerBlogBtns">

          <Grid container spacing={3}>
            <Grid item  lg={6} sm={12}>
              <Grid container spacing={3}>
                <Grid item sm={5} xs={12}>
                  <input type="date"
                    placeholder="DD/MM/YYYY"
                    value={filterDateFrom}
                    onChange={(e) => setFilterDateFrom(e.target.value)}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <div align="center">Até</div>
                </Grid>
                <Grid item  sm={5} xs={12}>
                  <input type="date"
                    placeholder="DD/MM/YYYY"
                    value={filterDateTo}
                    onChange={(e) => setFilterDateTo(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item  lg={6} xs={12}>
              <div className="containerBlogBtns">
              <Button
                right="10px"
                icon="none"
                textAlign="center"
                height="40px"
                onClick={() => clearFields()}>Limpar Busca</Button>

              <Button
                icon="none"
                textAlign="center"
                type='darkBlue'
                height="40px"
                onClick={filterBy}>Buscar</Button>

            </div>
          </Grid>
          </Grid>


          </div>


          <TableContainer>

            <Table
              className={classes.table}
            >
              {isLoading ?

                <div className="user_table_loading">
                  <CircularProgress />
                </div>
                :
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Título</TableCell>
                      <TableCell align="left">URL</TableCell>
                      <TableCell align="center">Publicado</TableCell>
                      <TableCell align="center">Atualização</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ maxHeight: "40px", padding: 8 }} align="left">
                          <Link to={Globals.paths.backofficeArticleListEdit.replace(":articleId", row.id)} style={{ color: "#005E50", fontSize: 14 }} >
                            {row.title}
                          </Link>
                        </TableCell>
                        <TableCell style={{ maxHeight: "40px", padding: 8 }} align="left"><a style={{ color: "#005E50", fontSize: 14 }} href={`https://meubolsoemdia.com.br/Materias/${row.url}`} target="_blank">{row.url}</a></TableCell>
                        <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center">
                          {row.data_module_detail && row.data_module_detail.type_module_status_id == 1 ?
                            <div style={{ color: "#393", fontWeight: "bold" }}>Sim</div>
                            :
                            <div style={{ color: "#666" }}>Não</div>
                          }
                        </TableCell>
                        <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center">
                          {new Date(row.updated_at.replace(' ', 'T')).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              }
            </Table>
          </TableContainer>

          <div className="user_footer_table">
            Linhas por página:
              <FormControl style={{ display: 'contents' }}>
                <Select
                  value={rowsPerPage}
                  onChange={(e) => handleChangeRowsPerPage(e)}
                >
                  <MenuItem value={5}>
                    <em>5</em>
                  </MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            

            <Pagination count={lastPage} page={page} onChange={handleChangePage} />

            <div className="user_exibitionTable">
              <span>Exibindo {(page - 1) * rowsPerPage + 1} - {(page - 1) * rowsPerPage + rows.length} de {total} </span>
            </div>
          </div>
        </ Grid>
      </ Grid>
    </Grid>
  );
}
