import axios from 'axios';
import React, { Fragment } from 'react';
import moment from 'moment/min/moment-with-locales';
import { enumLogHomeAction } from '../../../../services/enums'

import {
  Grid,
  Hidden,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import ReactPlayer from 'react-player/vimeo';
import Globals from '../../../../Globals.json';
import PublicClassLesson from './PublicClassLesson';
import SubjectIcon from '@material-ui/icons/Subject';
import { GraphicEq, TrackChanges, Videocam } from '@material-ui/icons';
import ToolbarItem from '../../../../logged_in/components/toolbar/ToolbarItem';
import AlertErrors from '../../../../logged_in/components/alertErrors/alerts';
import EditorJsRenderer from '../../../../logged_in/components/EditorJSRenderer';
import Storie from '../../../../logged_in/components/stories/Stories';
import Button from '../../../../logged_in/components/button/Button';
import LoggedOutButton from '../Button';
import './public-class-detail.scss';

class PublicClassDetail extends React.Component {
  state = {
    loadingCourse: false,
    loadingClass: false,
    courseName: '',
    courseDescription: '',
    courseThumbnail: '',
    classId: '',
    className: '',
    classNameSecundary: '',
    classDescription: '',
    classThumbnail: '',
    classData: {},
    classes: [],
    contents: [],
    duration: 0,
    mediaPercentage: 0,
    selectedContent: {},
    controllerBtn: true,
    alertError: '',
    completed: false,
    showCopySuccess: false,
    logClassFinished: 0,
  };

  componentDidMount() {
    this.fullLoad();
  };

  async loadCourseData() {
    try {
      this.setState({ loadingCourse: true });

      const postData = {
        container_id: this.props.courseId,
      };
      const response = await axios.post(process.env.REACT_APP_API + Globals.api.getPublicModules, postData);
      const { container, children } = response.data;

      this.setState({
        loadingCourse: false,
        courseName: container.name,
        courseDescription: container.description,
        courseThumbnail: container.thumbnail,
        classes: children.filter((item) => item.type_module_id === 6)
      });

      if (this.state.classes.length > 0) {
        const classes = this.state.classes;
        classes[0].user_module_data = {};
        classes[0].user_module_data.startedAt = moment();
        this.setState({ classId: this.state.classes[0].id });
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  async loadClassData() {
    try {
      this.setState({
        loadingClass: true,
        selectedContent: {}
      });

      const postData = { container_id: this.state.classId };
      const response = await axios.post(process.env.REACT_APP_API + Globals.api.getPublicModules, postData);
      const { container, children } = response.data;
      const acceptedClassContentId = [13, 14, 15, 35];

      this.setState({
        loadingClass: false,
        className: container.name,
        classDescription: container.description,
        classThumbnail: container.thumbnail,
        classData: container.data,
        contents: children.filter((item) => acceptedClassContentId.includes(item.type_module_id)),
        classNameSecundary: ''
      });

      if (children.length > 0) {
        this.setState({
          selectedContent: children[0],
          classNameSecundary: children[0].name != this.state.className ? children[0].name : ''
        });
      }
    } catch (error) {
      this.setState({ alertError: error?.response?.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  async fullLoad() {
    await this.loadCourseData();
    await this.loadClassData();
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  onMediaTimeUpdate(e) {
    const media = Math.round(e.playedSeconds);
    var pct = (this.state.duration * 5) / 100;

    if (media >= pct) {
      this.setState({ mediaPercentage: media });
    }
  };

  scrollTop = () => {
    document.getElementById('lesson-code-name').scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  getCurIndex() {
    let curIndex = -1;

    for (let index = 0; index < this.state.classes.length; index++) {
      if (this.state.classes[index].id === parseInt(this.state.classId)) {
        curIndex = index;
      }
    }

    return curIndex;
  };

  goToClass(item) {
    this.setState({ classId: item.id }, () => {
      this.loadClassData();

      const aula_atual = this.getCurIndex() + 1;
      const extra_data = {}
      try {
        extra_data['container_id'] = this.state.classes[this.getCurIndex()].id;
      }
      catch (e) {
        //
      }
      this.logHomeAction({ user_action: 1100 + aula_atual, extra_data });
    });


  };

  showCopySuccess = () => {
    this.setState({ showCopySuccess: true });
  };

  getNextClass() {
    let curIndex = this.getCurIndex();
    const classes = this.state.classes;

    if (!this.state.classes[curIndex].user_module_data) {
      classes[curIndex].user_module_data = {};
    }

    classes[curIndex].user_module_data.finishedAt = moment();

    if (this.state.classes.length >= curIndex + 2) {
      return this.state.classes[curIndex + 1];
    }
  };

  finishClass() {
    const nextClass = this.getNextClass();

    if (this.state.logClassFinished == this.getCurIndex()) {
      const ultima_aula_concluida = this.getCurIndex() + 1;
      this.state.logClassFinished = ultima_aula_concluida;
      const extra_data = {}
      try {
        extra_data['container_id'] = this.state.classes[this.getCurIndex()].id;
      }
      catch (e) {
        //
      }
      this.logHomeAction({ user_action: 1000 + ultima_aula_concluida, extra_data });
    }

    if (nextClass) {
      this.setState({ classId: nextClass.id }, () => {
        this.loadClassData();
      });
    } else {
      this.setState({ completed: true, classId: '' });
    }
  };

  onRegister() {
    this.props.onRegister();
    this.props.onClose();
  };

  logHomeAction(data) {
    this.props.logHomeAction(data);
  }

  logHomeActionCopyContent() {
    try {
      const aula_atual = this.getCurIndex() + 1;
      const extra_data = {}
      try {
        extra_data['container_id'] = this.state.classes[this.getCurIndex()].id;
      }
      catch (e) {
        //
      }
      this.logHomeAction({ user_action: 1200 + aula_atual, extra_data });
    }
    catch (e) {
      console.log(e);
    }
  }

  renderContent = (content) => {
    if (content) {
      if (content.type_module_id === Globals.types.content_video) {
        return (
          <>
            <div className="class-detail-content-video">
              <ReactPlayer
                className="vimeo-player"
                controls={true}
                onProgress={(e) => this.onMediaTimeUpdate(e)}
                onDuration={this.handleDuration}
                url={content.description}
              />
            </div>
          </>
        );
      } else if (content.type_module_id === Globals.types.content_audio) {
        return (
          <video
            id="ctMedia"
            className="class-detail-video-sm-up"
            onTimeUpdate={(e) => this.onMediaTimeUpdate(e)}
            width="100%"
            height="440"
            controls="controls"
          >
            <source src={content.description} />
            Seu navegador não suporta este tipo de mídia.
          </video>
        );
      } else if (content.type_module_id === Globals.types.content_text) {
        let contentHtml = '';
        let objContent = {};

        try {
          objContent = JSON.parse(this.state.selectedContent.description);
        } catch (e) {
          objContent = null;
        }

        if (objContent && objContent.blocks) {
          return (
            <section>
              <EditorJsRenderer data={objContent} />
            </section>
          );
        } else {
          contentHtml = this.state.selectedContent.description.replace(
            /Arial/g,
            'Trebuchet MS !important'
          );
        }

        return (
          <div
            className="class-detail-content-text"
            dangerouslySetInnerHTML={{ __html: contentHtml }}
          />
        );
      } else if (content.type_module_id === Globals.types.content_story) {
        const story = {
          id: this.state.selectedContent.id,
          name: this.state.selectedContent.name,
          slides: this.state.selectedContent.data.slides,
        };

        return (
          <span onClick={this.logHomeActionCopyContent.bind(this)}>
            <Storie
              onCopy={this.showCopySuccess}
              key={`storie-${story.id}`}
              storie={story}
              showCopyButtons={true}
              inDialog={true}
            />
          </span>
        );
      }
    } else {
      return <></>;
    }
  };

  renderClassDetail = () => (
    <Grid item className="public-class-detail-item" id='lesson-code-name'>
      <span className="lesson-code">{this.state.courseName.toUpperCase()} - AULA {this.getCurIndex() + 1}</span>
      <Grid item xs={12} className="title-detail-bar">
        {this.state.className}
      </Grid>
      <Grid item xs={12} className="title-detail-bar-secondary">
        {this.state.classNameSecundary}
      </Grid>

      <Grid container item xs={12} className="public-class-content">
        {this.renderContent(this.state.selectedContent)}
      </Grid>
      <div className="button-bar-container-class">
        <div className="class-contents-button-container">
          {this.state.contents.map((item, index) => (
            <Fragment key={index}>
              {item !== this.state.selectedContent && (
                <div style={{ margin: '10px' }}>
                  <Button
                    icon={
                      item.type_module_id === 13 ? (
                        <Videocam style={{ marginLeft: '10px' }} />
                      ) : item.type_module_id === Globals.types.content_audio ? (
                        <GraphicEq style={{ marginLeft: '10px' }} />
                      ) : item.type_module_id === Globals.types.content_text ? (
                        <SubjectIcon style={{ marginLeft: '10px' }} />
                      ) : (
                        item.type_module_id === Globals.types.content_story && (
                          <TrackChanges style={{ marginLeft: '10px' }} />
                        )
                      )
                    }
                    type="darkBlue"
                    onClick={() => this.selectContent(item)}
                  >
                    {item && item.name}
                  </Button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
        <div style={{ width: '190px', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          {(this.state.mediaPercentage >= 0.05 ||
            this.state.selectedContent.type_module_id === Globals.types.content_text ||
            this.state.selectedContent.type_module_id === Globals.types.content_audio ||
            this.state.selectedContent.type_module_id === Globals.types.content_story) && (
              <Button
                type="darkBlue"
                className={
                  !!this.getNextClass()
                    ? 'g-c-finish-class-and-go-to-next'
                    : 'g-c-finish-class-and-course'
                }
                onClick={() => this.finishClass()}
                width="170px"
                style={{ marginRight: '0px' }}
              >
                Concluir Aula
              </Button>
            )}
        </div>
      </div>
    </Grid>
  );

  render() {
    if (this.state.loadingCourse) {
      return (
        <div className="public-course-loader-container">
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        {this.state.completed ? (
          <>
            <div className="result-container">
              <div className="main-text">
                <div className="main-text-container">
                  <h1><strong>Viu como é simples e descomplicado?</strong></h1>
                  <p>Educação financeira pode parecer uma coisa assustadora a princípio, mas o <strong>Meu Bolso em Dia</strong> está aqui para te ajudar e te acompanhar nessa jornada.</p>
                  <div className="actions">
                    <span onClick={this.logHomeAction.bind(this, { user_action: enumLogHomeAction.cursoPublicoQueroMeInscrever })}>
                      <LoggedOutButton onAction={() => this.onRegister()}>Quero me inscrever na plataforma!</LoggedOutButton>
                    </span>
                  </div>
                </div>
              </div>
              <div className="lessons">
                <div className="lessons-container">
                  {this.state.classes.map((item) => (
                    <PublicClassLesson
                      onClick={() => this.goToClass(item)}
                      key={item.id}
                      classData={item}
                      started={item.user_module_data.startedAt != null}
                      done={item.user_module_data.finishedAt != null}
                      current={item.id === this.state.classId}
                      courseCompleted={this.state.completed}
                    />
                  ))}
                </div>
              </div>
              <div className="courses">
                <p><strong>Próximas aulas</strong></p>
                <img src="./images/public-trail/course-thumb-01.png" alt="Curso: Mapeie sua vida financeira" onClick={() => this.onRegister()} />
                <img src="./images/public-trail/course-thumb-02.png" alt="Curso: Construindo o caminho" onClick={() => this.onRegister()} />
                <img src="./images/public-trail/course-thumb-03.png" alt="Curso: O planejamento na prática..." onClick={() => this.onRegister()} />
              </div>
            </div>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={9} className="public-class-detail-container">
                <AlertErrors error={this.state.alertError} />
                <Snackbar
                  open={this.state.showCopySuccess}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={2000}
                  onClose={() =>
                    setTimeout(() => {
                      this.setState({ showCopySuccess: false });
                    }, 2000)
                  }
                >
                  <Alert
                    onClose={() => {
                      this.setState({ showCopySuccess: false });
                    }}
                    severity="info"
                  >
                    Conteúdo copiado para sua área de transferência.
                  </Alert>
                </Snackbar>
                {this.state.loadingClass ? (
                  <div className="public-class-loader-container">
                    <CircularProgress />
                  </div>
                ) : (
                  this.renderClassDetail()
                )}
              </Grid>
              <Hidden smDown>
                <Grid item container sm={4} md={3}>
                  <ToolbarItem title="Próximas lições">
                    <div className="public-class-lessons-container">
                      {this.state.classes.map((item) => (
                        <PublicClassLesson
                          onClick={() => this.goToClass(item)}
                          key={item.id}
                          classData={item}
                          started={item.user_module_data.startedAt != null}
                          done={item.user_module_data.finishedAt != null}
                          current={item.id === this.state.classId}
                          courseCompleted={this.state.completed}
                        />
                      ))}
                    </div>
                  </ToolbarItem>
                </Grid>
              </Hidden>
            </Grid>
          </>
        )}
      </>
    )
  };
}

export default PublicClassDetail;