import React from 'react';

import { withStyles } from '@material-ui/core';
import api from '../../../services/api';

import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../button/Button';
import InitialModalQuiz from './InitialModalQuiz';

import SwipeableViews from "react-swipeable-views";
import Pagination from "../pagination/Pagination";
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';
import './styles/quiz.scss';

const Globals = require("../../../Globals.json");

const styles = theme => ({
  text_obj: {
    fontSize: "14px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },

  text_description: {
    fontSize: "12px",
    height: "65px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    "@media (max-width: 599px)": {
      overflow: "hidden",
    }
  },

  card_recomendados: {
    display: "flex",
    height: "170px",
    minWidth: "280px",
    maxWidth: "290px",
    overflowX: "hidden",
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      minWidth: "98%",
      marginRight: "2%",
    },
  },

  background_img: {
    height: "100%",
    width: "50%",
    objectFit: "cover",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRight: "4px solid #FF6E5A",
  },

  container_description: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "30%",
    padding: "2px 0px 5px 12px",
    "@media (max-width: 599px)": {
      justifyContent: "flex-start",
      display: '-webkit-box',
      maxWidth: '100%',
      webkitLineClamp: 4,
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
  },

  paddingBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "20%",
    textAlign: "center",
    "@media (max-width: 599px)": {
      justifyContent: "flex-start",
      marginLeft: "25px",
    }
  },

  contentCollumn: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  containerText: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "50%",
    padding: "2px 5px 5px 12px",
  },

});

class CourseRecommended extends React.Component {
  state = {
    open: false,
    openFinalModal: false,
    isLoading: false,
    quiz: [],
    length: 0,
    id: 0,
    results: [],
    questions: [],
    index: 0
  }

  async loadData () {
    this.setState({ isLoading: true });

    try {
      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";
      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      // console.log("List Recomendados:", response.data);
      this.setState({ quiz: response.data.slice(0, 3), isLoading: false });
      this.setState({ length: response.data.length });

    } catch (error) {
      this.setState({ alertError: 404 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  componentDidMount () {
    this.loadData();
  }


  handleChangeIndex = (index) => {
    this.setState({ index });
  };

  render () {

    const { classes } = this.props;
    const { index } = this.state;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
          {this.state.quiz.map((item, index) => (
            <>
              <div className={classes.card_recomendados} key={index}>

                {getCoinsFromRewards(item.data.rewards) > 0 &&
                  <div className="coin-container quiz">
                    <div className="coin" >
                      {getCoinsTextFromRewards(item.data.rewards)}
                    </div>
                  </div>
                }

                <img src={item.thumbnail} alt="Imagem do Quiz" className={classes.background_img} />

                <div className={classes.contentCollumn}>
                  <div className={classes.container_description}>
                    <span className={classes.text_obj}>
                      {item.name}
                    </span>
                  </div>

                  <div className={classes.containerText}>
                    <span className={classes.text_description}>
                      {item.description}
                    </span>
                  </div>

                  <div className={classes.paddingBtn}>
                    <Button icon="none" to={Globals.paths.quizTest.replace(':quizId', item.id)} minWidth="22%">
                      Descobrir
                  </Button>
                  </div>
                </div>

              </div>
            </>
          ))}
        </SwipeableViews>

        <Pagination
          dots={this.state.quiz.length}
          index={index}
          onChangeIndex={this.handleChangeIndex}
        />
      </>
    )
  }
}
export default withStyles(styles)(CourseRecommended);