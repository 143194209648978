import React, { useEffect, useState } from 'react';
import '../../../../../../backoffice.scss';
import ModuleListing from '../../../components/ModuleListing/ModuleListing';
import api from "../../../../../../services/api";

const Globals = require("../../../../../../Globals.json");

export default function QuestionOptions() {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData() {
    await api.get(Globals.app_api.discoveryQuestionOptions)
      .then((res) => {
        setOptions(parserToModuleListing(res.data.content.options_list))
      })
  }

  function parserToModuleListing(response) {
    return response.map((item) => {
      const isActive = item.is_active ? 1 : 0
      return {
        id: item.id,
        name: item.description,
        description:item.question.description,
        type_module_status_id: isActive,
        data: {
          order: item.display_order,
        }
      }
    })
  }

  return (
    <ModuleListing
      title="Questões"
      buttonLabel="Adicionar nova questão"
      cellNameLabel ="Descrição"
      cellDescriptionLabel= "Grupo"
      fullUrl={Globals.paths.backofficeQuestionMatrixOptionsEdit}
      urlReplacePattern=":option_id"
      customList={options}
      hideThumbnail
      showOrder />
  )

}
