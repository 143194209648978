import React, { useState, useEffect } from 'react';
import BarChartVerticalDashboard from '../../Components/Charts/BarChartVerticalDashboard';
import { ResponsiveContainer } from 'recharts';
import './CoursesByRegion.scss';

export default function CoursesByRegion({ data }) {
  const [regionSelected, setRegionSelected] = useState();
  const [dataToEvaluation, setDataToEvaluation] = useState('region');
  const [dataToDisplay, setDataToDisplay] = useState(data)
  const [drillUp, setDrillUp] = useState(false);

  const getData = () => {
    if (dataToEvaluation === 'region') {
      setDataToDisplay(data);
      setDrillUp(false);
    } else if (dataToEvaluation === 'state') {
      setDrillUp(true);
      const region = dataToDisplay.find(item => item.name === regionSelected);
      setDataToDisplay(region.items);
    }
  };

  const handleDrillUp = () => {
    if (dataToEvaluation === 'state') {
      setDataToEvaluation('region');
    }
  };

  useEffect(() => {
    getData();

  }, [dataToEvaluation]);

  return (
    <>
      <div className='header-courseEvaluation-engmt'>
        <div>Cursos por Região / Estado</div>
        {drillUp && <button type='button' onClick={() => handleDrillUp()}>&lt;-</button>}
      </div>
      <div className='body-courseEvaluation-engmt'>
        <ResponsiveContainer width={'99%'} height={'100%'}>
          <BarChartVerticalDashboard data={dataToDisplay}
            ylabel={'name'}
            nameAndDataKey={[{ name: 'Cursos', datakey: 'totalCourses', fillColor: '#05CCAE' },
            { name: 'Cursos concluídos', datakey: 'totalFinishedCourses', fillColor: '#d8d8d8' }]}
            yWidth={80}
            barSize={25}
            showLegend={true}
            chartWidth={'100%'}
            setRegionSelected={setRegionSelected}
            setDataToEvaliation={setDataToEvaluation}
            dataToEvaluation={dataToEvaluation}
            chartMargin={{ left: 10, right: 40 }}
          />
        </ResponsiveContainer>
      </div>
    </>
  )
}