import styled from 'styled-components';

export const Button = styled.button`
  background-color: #005E50;
  border: none;
  padding: 14px 25px 12px 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'elza';
  font-weight: 700;
  font-size: 14px;  
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #595959;
  }
`;